import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { filter, first, takeUntil } from 'rxjs/operators';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Language } from 'src/app/shared/Models/models';
import { State } from 'src/app/reducers';
import { setInterfaceLang } from 'src/app/shared/ngrx/shared.actions';
import {
  selectRegistrationSpinnerState,
  selectAffiliates,
  selectAffiliatesError,
  selectEmailVerification,
  selectReactivationDone,
  selectReactivationError,
  selectExistingEmailError,
} from 'src/app/auth/ngrx/auth.selectors';
import { reactivateTrial } from 'src/app/auth/ngrx/auth.actions';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { Subject } from 'rxjs';
import { fetchAffiliates, restoreAccount } from './../../ngrx/auth.actions';
import { AsyncPipe } from '@angular/common';
import { FooterComponent } from '../../../shared/Components/footer/footer.component';
import { RegistrationComponent } from '../../Components/registration/registration.component';
import { ReactivationComponent } from '../../Components/reactivation/reactivation.component';
import { MatButtonModule } from '@angular/material/button';
import { LanguageSelectorComponent } from '../../../shared/Components/language-selector/language-selector.component';
import { SideInfoComponent } from '../../../shared/Components/side-info/side-info.component';

@Component({
  selector: 'registration-container',
  templateUrl: './registration.container.html',
  styleUrls: ['./registration.container.scss'],
  imports: [
    SideInfoComponent,
    LanguageSelectorComponent,
    MatButtonModule,
    RouterLink,
    ReactivationComponent,
    RegistrationComponent,
    FooterComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class RegistrationContainer implements OnDestroy, OnInit {
  private ngrxStore = inject<Store<State>>(Store);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private translate = inject(TranslocoService);

  affiliates$ = this.ngrxStore.select(selectAffiliates);
  affiliatesError$ = this.ngrxStore.select(selectAffiliatesError);
  isEmailExist$ = this.ngrxStore.select(selectExistingEmailError);
  private destroyed$ = new Subject<void>();
  emailVerification$ = this.ngrxStore.select(selectEmailVerification);
  registerButtonState$ = this.ngrxStore.select(selectRegistrationSpinnerState);
  reactivationDone$ = this.ngrxStore.select(selectReactivationDone);
  reactivationError$ = this.ngrxStore.select(selectReactivationError);
  user$ = this.ngrxStore.select(selectUser);

  affiliate: string;
  affiliationTitle: string;
  affiliationLogo: string;
  language: { current: InterfaceLanguage } = { current: 'en' };
  reactivation = false;
  restore = false;
  restoreDone = false;
  token: string;
  uid: string;

  constructor() {
    this.translate.setActiveLang(Language(navigator.language));
    this.handleLang(this.translate.getActiveLang() as InterfaceLanguage);
    this.translate.langChanges$.subscribe((lang) => {
      this.language.current = lang as InterfaceLanguage;
    });
    this.route.params.subscribe((params: Params) => {
      this.affiliate = params?.affiliate;
      if (this.affiliate)
        this.ngrxStore.dispatch(fetchAffiliates({ code: this.affiliate }));
    });
    this.translate.getActiveLang() &&
      (this.language.current =
        this.translate.getActiveLang() as InterfaceLanguage);
    this.route.queryParams.subscribe((params) => {
      this.handleLang(params?.lang);
    });
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.token = params.get(`token`) || this.token;
      this.uid = params.get(`uid`) || this.uid;
    });
    this.route.data.pipe(first()).subscribe((params) => {
      this.reactivation = params?.reactivate;
      if (this.reactivation && this.token) {
        this.ngrxStore.dispatch(
          reactivateTrial({ uid: this.uid, token: this.token }),
        );
      }
      this.restore = params?.restore;
      if (this.restore && this.token) {
        this.ngrxStore.dispatch(
          restoreAccount({ uid: this.uid, token: this.token }),
        );
      }
    });
    this.affiliates$
      .pipe(
        filter((res) => !!res?.length),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => {
        this.affiliationTitle = res[0].name;
        this.affiliationLogo = res[0].logo_small;
      });
    this.affiliatesError$
      .pipe(
        filter((res) => !!res),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.handleWrongAffiliationCode());
  }

  handleCode = (code: string): void => {
    if (code) this.ngrxStore.dispatch(fetchAffiliates({ code }));
  };

  handleWrongAffiliationCode(): void {
    this.router.navigate([`registration`], {
      queryParamsHandling: 'preserve',
    });
  }

  handleLang = (lang: InterfaceLanguage): void => {
    if (!lang) return;
    const l = Language(lang);
    this.language = { current: l };
    this.translate.setActiveLang(l);
    this.ngrxStore.dispatch(setInterfaceLang({ lang: l }));
  };

  changeLanguage(): void {
    this.language = { current: this.language.current };
    this.translate.setActiveLang(this.language.current);
    this.ngrxStore.dispatch(setInterfaceLang({ lang: this.language.current }));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
