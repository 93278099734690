<div
  class="wrapper mat-elevation-z1"
  [class.collapsed]="!recipe().uncollapse"
  [class.deleting]="delete"
>
  <div class="name">
    <span
      class="text"
      [class.italic]="staticName.italic"
      (click)="collapsed.emit()"
      >{{ staticName.name }}</span
    >
    <div class="buttons icon-button-density-3">
      <button mat-icon-button [disabled]="delete" (click)="navigateToRecipe()">
        <mat-icon class="edit">input</mat-icon>
      </button>
      <button mat-icon-button [disabled]="delete" (click)="collapsed.emit()">
        <mat-icon class="edit">edit</mat-icon>
      </button>
      <button mat-icon-button [disabled]="delete" (click)="removeRecipe()">
        <mat-icon class="remove">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="recipe-content" [hidden]="!recipe().uncollapse">
    <form [formGroup]="recipeForm">
      <div class="name-servings">
        <mat-form-field>
          <mat-label>{{
            'write.blocks.dish-overview.recipes.name' | transloco
          }}</mat-label>
          <input
            formControlName="name"
            type="text"
            matInput
            (keydown.enter)="blurInput($event.target)"
          />
          @if (loadingBars.name) {
            <mat-progress-bar
              class="recipe-progress-bar"
              mode="indeterminate"
            ></mat-progress-bar>
          }
        </mat-form-field>
        <mat-form-field class="servings-field">
          <mat-label>{{
            'write.blocks.dish-overview.recipes.servings' | transloco
          }}</mat-label>
          <input
            formControlName="servings"
            min="1"
            type="number"
            lang="en-150"
            matInput
            (keydown.enter)="blurInput($event.target)"
          />
          @if (loadingBars.servings) {
            <mat-progress-bar
              class="recipe-progress-bar"
              mode="indeterminate"
            ></mat-progress-bar>
          }
        </mat-form-field>
      </div>
      <div class="ingredients-tab-wrapper">
        <ingredients-tab
          [ingredientsAuto]="ingredientsAuto()"
          [ingredientInfo]="ingredientInfo()"
          [recipe]="recipe()"
          [translations]="translations()"
          [lang]="lang()"
          (addIngredientToRecipe)="addIngredientToRecipe.emit($event)"
          (clearIngredientsAuto)="clearIngredientsAuto.emit()"
          (clearIngredientInfo)="clearIngredientInfo.emit()"
          (fetchIngredientsAuto)="fetchIngredientsAuto.emit($event)"
          (fetchIngredientsInfo)="fetchIngredientsInfo.emit($event)"
          (deleteDishRecipeIngredientEvent)="
            deleteDishRecipeIngredientEvent.emit($event)
          "
          (createNewIngredientEvent)="createNewIngredientEvent.emit($event)"
          (addMultipleIngredientsToRecipeEvent)="
            addMultipleIngredientsToRecipeEvent.emit($event)
          "
          (patchIngredientEvent)="patchIngredientEvent.emit($event)"
          (refetch)="refreshDish.emit()"
        >
        </ingredients-tab>
      </div>
    </form>
  </div>
</div>
