import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  HostBinding,
  OnDestroy,
  inject,
  output,
  input,
} from '@angular/core';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Menu } from 'src/app/shared/Models/menu';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { UserStatusPrivileges } from 'src/app/shared/Models/user';
import { showSnackbarMessage } from 'src/app/shared/ngrx/shared.actions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { NgTemplateOutlet } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'menu-steps',
  templateUrl: './menu-steps.component.html',
  styleUrls: ['./menu-steps.component.scss'],
  imports: [
    MatIconModule,
    RouterLink,
    MatTooltipModule,
    NgTemplateOutlet,
    MatMenuModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class MenuStepsComponent implements OnDestroy {
  private breakpointObserver = inject(BreakpointObserver);
  private store = inject<Store<State>>(Store);

  readonly currentStep = input<number>(undefined);
  readonly stage = input<
    {
      title: string;
    }[]
  >(undefined);
  readonly privileges = input<UserStatusPrivileges>(undefined);
  readonly userStatus = input<Partial<AccountStatusMessage>>(undefined);
  readonly newLanguages = input<
    {
      value: ContentLanguage;
      created: boolean;
    }[]
  >(undefined);
  readonly menu = input<Menu>(undefined);
  readonly id = input<number>(undefined);

  readonly navigateToTranslate = output<ContentLanguage>();
  readonly addTranslation = output<ContentLanguage>();
  readonly showTooltip = output<MatTooltip>();
  readonly goPrev = output();
  readonly goNext = output();

  @HostBinding('class.opened') get opened() {
    return this.openMobileSteps;
  }

  private destroyed$ = new Subject<void>();
  mobile = false;
  openMobileSteps = false;
  showMobileLangs = false;

  constructor() {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe((state: BreakpointState) => {
        this.openMobileSteps = false;
        this.mobile = state.matches;
      });
  }

  prev(event: MouseEvent): void {
    event.stopPropagation();
    this.goPrev.emit();
  }

  next(event: MouseEvent): void {
    event.stopPropagation();
    this.goNext.emit();
  }

  showSnack() {
    this.store.dispatch(
      showSnackbarMessage({ message: this.userStatus()?.message }),
    );
  }

  stepClicked(i: number, event?: MouseEvent): void {
    if (i !== 2)
      return void (this.showMobileLangs = this.openMobileSteps = false);
    event?.stopPropagation();
    this.showMobileLangs = !this.showMobileLangs;
  }

  chooseLang(
    lang: { value: ContentLanguage; created: boolean },
    event: MouseEvent,
  ): void {
    event.stopPropagation();
    this.showMobileLangs = this.openMobileSteps = false;
    lang.created && this.menu()
      ? this.navigateToTranslate.emit(lang.value)
      : this.addTranslation.emit(lang.value);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
