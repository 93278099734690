import { dashboardFeature } from './dashboard.reducer';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HelpScoutBeaconsService } from 'src/app/help-scout-beacons.service';
import { State } from 'src/app/reducers';
import { Course, Dashboard } from 'src/app/shared/Models/dashboard';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { UserOnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { handleHttpError } from 'src/app/shared/ngrx/shared.actions';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import * as UserSelectors from 'src/app/shared/user/ngrx/user.selectors';
import { EMPTY } from 'rxjs';
import {
  catchError,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { BouncedEmailModalComponent } from '../bounced-email-modal/bounced-email-modal.component';
import {
  fetchCourses,
  fetchDashboardData,
  fetchDishesCurrentMenu,
  fetchUserOnboardingTemplates,
  restrictLatestTemplates,
  setCourses,
  setCoursesLoading,
  setData,
  setDishesCurrentMenu,
  setDishesCurrentMenuLoading,
  setTemplateRestrictions,
  setUserOnboardingTemplates,
  showBouncedEmailModal,
  startLoadingDashboard,
} from './dashboard.actions';

@Injectable()
export class DashboardEffects {
  private actions$ = inject(Actions);
  private configService = inject(ConfigService);
  private genericsService = inject(GenericsService);
  private helpBeacons = inject(HelpScoutBeaconsService);
  private modal = inject(MatDialog);
  private router = inject(Router);
  private store = inject<Store<State>>(Store);

  fetchDashboardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDashboardData),
      tap(() => {
        this.store.dispatch(startLoadingDashboard({ is_loading: true }));
      }),
      switchMap(() =>
        this.genericsService.get<Dashboard>(this.configService.dashboard).pipe(
          mergeMap((dashboard: Dashboard) => [
            setData({ dashboard }),
            restrictLatestTemplates(),
            startLoadingDashboard({ is_loading: false }),
          ]),
          catchError((error) => {
            this.store.dispatch(startLoadingDashboard({ is_loading: false }));
            return [handleHttpError({ error })];
          }),
        ),
      ),
    ),
  );

  subscriptionPlanChangedDebit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restrictLatestTemplates),
      withLatestFrom(
        this.store.select(dashboardFeature.selectLatestTemplates),
        this.store.select(UserSelectors.selectUser),
      ),
      switchMap(([, latest_templates, user]) => {
        if (!latest_templates.length) {
          return [setTemplateRestrictions({ templates: [] })];
        }
        // user.status ? user.status.list_templates_used_this_month : []
        this.store.dispatch(
          setTemplateRestrictions({ templates: latest_templates }),
        );
        return EMPTY;
      }),
    ),
  );

  showBouncedEmailModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showBouncedEmailModal),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      switchMap(([, user]) => {
        const email = user.email;
        const dialog = this.modal.open(BouncedEmailModalComponent, {
          data: {
            email,
          },
          autoFocus: false,
          width: '100%',
          disableClose: true,
        });
        dialog
          .afterClosed()
          .pipe(take(1))
          .subscribe({
            next: (value) => {
              if (value) this.router.navigate(['settings', 'account']);
              else {
                this.helpBeacons.open();
              }
            },
          });
        return [];
      }),
    ),
  );

  fetchDishesCurrentMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchDishesCurrentMenu),
      tap(() =>
        this.store.dispatch(setDishesCurrentMenuLoading({ loading: true })),
      ),
      switchMap(({ url, params }) =>
        this.genericsService.get<MenuDish[]>(url, params).pipe(
          mergeMap((dishes: MenuDish[]) => [
            setDishesCurrentMenuLoading({ loading: false }),
            setDishesCurrentMenu({ dishes }),
          ]),
          catchError((error) => {
            this.store.dispatch(
              setDishesCurrentMenuLoading({ loading: false }),
            );
            return [handleHttpError({ error })];
          }),
        ),
      ),
    ),
  );

  fetchCourses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchCourses),
      tap(() => this.store.dispatch(setCoursesLoading({ loading: true }))),
      switchMap(({ params }) =>
        this.genericsService
          .get<Course[]>(this.configService.courses, params)
          .pipe(
            mergeMap((courses: Course[]) => [
              setCoursesLoading({ loading: false }),
              setCourses({ courses }),
            ]),
            catchError((error) => {
              this.store.dispatch(setCoursesLoading({ loading: false }));
              return [handleHttpError({ error })];
            }),
          ),
      ),
    ),
  );

  fetchUserOnboardingTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserOnboardingTemplates),
      switchMap(() =>
        this.genericsService
          .get<
            UserOnboardingTemplate[]
          >(this.configService.userOnboardingTemplates)
          .pipe(
            mergeMap((userOnboardingTemplates) => [
              setUserOnboardingTemplates({
                payload: userOnboardingTemplates,
              }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );
}
