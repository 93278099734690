<mat-accordion displayMode="flat">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>note</mat-icon>
      {{ 'style.sidebar.panels.primary.print.paper' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.dimensions' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <select-item
              class="element-narrow no-margin"
              [menu]="menu()"
              [field]="'paper_format'"
              [list]="styleConstants.dimensions"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
            @if (menu()?.style?.paper_format === 'custom') {
              <number-item
                field="paper_height"
                type="value"
                unit="mm"
                [max]="5000"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
              <number-item
                field="paper_width"
                type="value"
                unit="mm"
                [max]="5000"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.margins_paper' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            @for (field of ['top', 'left', 'bottom', 'right']; track field) {
              <number-item
                class="element-narrow"
                [field]="'paper_margins_' + field"
                type="value"
                unit="mm"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.header_footer' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [field]="'print_pagetop_each_page'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (menu()?.style?.print_logo) {
              <number-item
                class="logo-size"
                field="logo_height"
                unit="mm"
                type="value"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
            }
            <p class="field-info">
              {{ 'style.sidebar.numbers.logo_height_info.info' | transloco }}:
            </p>
            @if (menu()?.style?.print_logo) {
              <select-item
                [menu]="menu()"
                [field]="'print_logo_position'"
                [list]="styleConstants.printLogoPositionOptions"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
            @for (
              field of [
                'paper_header_height',
                'paper_header_margin',
                'paper_footer_height',
                'paper_footer_margin',
              ];
              track field
            ) {
              <number-item
                [field]="field"
                type="value"
                unit="mm"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>image</mat-icon>
      {{ 'style.sidebar.panels.primary.print.background' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.coverpage' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [field]="'print_coverpage'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (menu()?.style?.print_coverpage) {
              <toggle-item
                [field]="'print_logo_coverpage'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
              @if (menu()?.style?.print_logo_coverpage) {
                <number-item
                  class="element-narrow"
                  [field]="'coverpage_logo_height'"
                  type="value"
                  unit="mm"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></number-item>
              }
              @if (menu()?.style?.print_logo_coverpage) {
                <select-item
                  class="element-narrow"
                  [menu]="menu()"
                  [field]="'coverpage_logo_position'"
                  [list]="styleConstants.coverpageLogoPosition"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></select-item>
              }
              @if (menu()?.style?.print_coverpage) {
                <toggle-item
                  [field]="'coverpage_pagetop'"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
                <toggle-item
                  [field]="'print_coverpage_inline'"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
              }
              <p class="hint">
                {{ 'style.sidebar.hints.coverpage_pagetop' | transloco }}
              </p>
              <toggle-item
                [field]="'background_coverpage_show'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
              @if (menu()?.style?.background_coverpage_show) {
                <div class="bg-images">
                  <div class="flex-wrapper">
                    @if (backgroundImages()?.length) {
                      <span>
                        {{
                          'style.sidebar.uploads.bg-image.choose' | transloco
                        }}:
                      </span>
                    }
                    @if (showLocks()) {
                      <lock-item
                        [field]="'background_coverpage'"
                        [lockedFields]="lockedFields()"
                        (updateMenu)="updateMenu.emit($event)"
                      ></lock-item>
                    }
                  </div>
                  <div
                    class="bg-images-wrapper"
                    [mtTooltip]="
                      disabledFields().includes('background')
                        ? ('style.sidebar.restrictions.layout-field-locked'
                          | transloco)
                        : ''
                    "
                  >
                    @if (menu()?.style?.background_coverpage) {
                      <select-image
                        [disabled]="
                          backgroundCoverpageUpdating ||
                          disabledFields().includes('background_coverpage')
                        "
                        [imageUrl]="
                          menu()?.style?.background_coverpage_detail
                            ?.background_image_small
                        "
                        [selected]="true"
                        (imgSelected)="
                          backgroundCoverpageUpdating = true;
                          updateMenu.emit({
                            style: { background_coverpage: null },
                          })
                        "
                      ></select-image>
                    }
                    @for (
                      bgImage of backgroundImages();
                      track bgImage;
                      let last = $last
                    ) {
                      @if (
                        bgImage.id !== menu()?.style?.background_coverpage &&
                        !(hideLastImage && last)
                      ) {
                        <select-image
                          [disabled]="
                            backgroundCoverpageUpdating ||
                            disabledFields().includes('background_coverpage')
                          "
                          [imageUrl]="bgImage.background_image_small"
                          (imgSelected)="
                            backgroundCoverpageUpdating = true;
                            updateMenu.emit({
                              style: { background_coverpage: bgImage.id },
                            })
                          "
                        ></select-image>
                      }
                    }
                  </div>
                  @if (backgroundImages()?.length) {
                    <button
                      class="show-library"
                      mat-button
                      color="primary"
                      [disabled]="
                        disabledFields().includes('background_coverpage')
                      "
                      (click)="showAllBackgrounds.emit('background_coverpage')"
                    >
                      {{ 'shared.buttons.see-all' | transloco }}
                    </button>
                  }
                </div>
                <show-and-upload-image
                  [disabled]="disabledFields().includes('background_coverpage')"
                  [label]="'style.sidebar.uploads.bg-image.add' | transloco"
                  [showSpinner]="showImageSpinner"
                  (fileChoosen)="
                    backgroundCoverpageUpdating = true;
                    uploadImage($event, 'background_coverpage')
                  "
                  (deleteImage)="clearBackgroundImage()"
                ></show-and-upload-image>
                @if (menu()?.style?.background_coverpage) {
                  <toggle-item
                    [field]="'background_coverpage_first_page_only'"
                    [menu]="menu()"
                    [showLock]="showLocks()"
                    [lockedFields]="lockedFields()"
                    [disabledFields]="disabledFields()"
                    (updateMenu)="updateMenu.emit($event)"
                  ></toggle-item>
                }
              }
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.print.background-color' | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group color-field">
            <span class="expansion-panel-field">{{
              'style.sidebar.items.page' | transloco
            }}</span>
            <div
              class="element-wrapper"
              [class.color-disabled]="
                disabledFields().includes('colour_page_print')
              "
              [mtTooltip]="
                disabledFields().includes('colour_page_print')
                  ? ('style.sidebar.restrictions.layout-field-locked'
                    | transloco)
                  : ''
              "
            >
              <mt-color-picker
                class="mt-color-picker"
                [class.color-picker-disabled]="
                  disabledFields().includes('colour_page_print')
                "
                [color]="menu()?.style?.colour_page_print"
                [disabled]="disabledFields().includes('colour_page_print')"
                [showClear]="!disabledFields().includes('colour_page_print')"
                (colorChange)="colorChanged('colour_page_print', $event)"
                (clearColor)="clearColor('colour_page_print')"
              ></mt-color-picker>
              @if (showLocks()) {
                <lock-item
                  field="colour_page_print"
                  [lockedFields]="lockedFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></lock-item>
              }
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.background' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="bg-images element-group">
            <div class="flex-wrapper">
              @if (backgroundImages()?.length) {
                <span>
                  {{ 'style.sidebar.uploads.bg-image.choose' | transloco }}:
                </span>
              }
              @if (showLocks()) {
                <lock-item
                  [field]="'background'"
                  [lockedFields]="lockedFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></lock-item>
              }
            </div>
            <div
              class="bg-images-wrapper"
              [mtTooltip]="
                disabledFields().includes('background')
                  ? ('style.sidebar.restrictions.layout-field-locked'
                    | transloco)
                  : ''
              "
            >
              @if (menu()?.style?.background) {
                <select-image
                  [disabled]="
                    backgroundUpdating ||
                    disabledFields().includes('background')
                  "
                  [imageUrl]="
                    menu()?.style?.background_detail?.background_image_small
                  "
                  [selected]="true"
                  (imgSelected)="
                    backgroundUpdating = true;
                    updateMenu.emit({
                      style: { background: null },
                    })
                  "
                ></select-image>
              }
              @for (
                bgImage of backgroundImages();
                track bgImage;
                let last = $last
              ) {
                @if (
                  bgImage.id !== menu()?.style?.background &&
                  !(hideLastImage && last)
                ) {
                  <select-image
                    [disabled]="
                      backgroundUpdating ||
                      disabledFields().includes('background')
                    "
                    [imageUrl]="bgImage.background_image_small"
                    (imgSelected)="
                      backgroundUpdating = true;
                      updateMenu.emit({
                        style: { background: bgImage.id },
                      })
                    "
                  ></select-image>
                }
              }
            </div>
            @if (backgroundImages()?.length) {
              <button
                class="show-library"
                mat-button
                color="primary"
                [disabled]="disabledFields().includes('background')"
                (click)="showAllBackgrounds.emit('background')"
              >
                {{ 'shared.buttons.see-all' | transloco }}
              </button>
            }
          </div>
          <show-and-upload-image
            [disabled]="disabledFields().includes('background')"
            [label]="'style.sidebar.uploads.bg-image.add' | transloco"
            [showSpinner]="showImageSpinner"
            (fileChoosen)="
              backgroundUpdating = true; uploadImage($event, 'background')
            "
            (deleteImage)="clearBackgroundImage()"
          ></show-and-upload-image>
          @if (
            menu()?.style?.background && !menu()?.style?.background_coverpage
          ) {
            <div class="toggle-item">
              <toggle-item
                [field]="'background_coverpage_show'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            </div>
          }
          @if (
            menu()?.style?.background &&
            menu()?.style?.print_legend &&
            menu()?.style?.print_legend_page
          ) {
            <div class="toggle-item">
              <toggle-item
                [field]="'background_legend_show'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            </div>
          }
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>article</mat-icon>
      {{ 'style.sidebar.panels.primary.print.spacing' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          <mat-panel-title>
            {{
              'style.sidebar.panels.secondary.print.margins_text' | transloco
            }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          @for (
            field of ['pagetop', 'section', 'enddescription', 'detail'];
            track field
          ) {
            <div class="element-group">
              <span class="element-title"
                >{{
                  'style.sidebar.panels.subheaders.' + field | transloco
                }}:</span
              >
              <number-item
                class="element-narrow"
                [field]="'margin_' + field + '_top'"
                type="value"
                unit="mm"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
              @if (field !== 'detail' && field !== 'enddescription') {
                <number-item
                  [field]="'margin_' + field + '_bottom'"
                  type="value"
                  unit="mm"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></number-item>
              }
            </div>
          }
          <div class="element-group">
            <span class="element-title"
              >{{ 'style.sidebar.panels.subheaders.spacer' | transloco }}:</span
            >
            @for (field of ['section', 'item']; track field) {
              <number-item
                class="element-narrow"
                [field]="'margin_spacer_' + field"
                type="value"
                unit="mm"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
            }
          </div>
          <div class="element-group">
            <span class="element-title"
              >{{
                'style.sidebar.panels.subheaders.dish_bev_win' | transloco
              }}:</span
            >
            <number-item
              class="element-narrow"
              field="margin_text"
              type="value"
              unit="mm"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></number-item>
            <number-item
              field="margin_description"
              type="value"
              unit="mm"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></number-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>content_cut</mat-icon>
      {{ 'style.sidebar.panels.primary.print.folding_binding' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.folding' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <select-item
              class="element-narrow"
              [menu]="menu()"
              [field]="'print_folding'"
              [list]="styleConstants.foldingOptions"
              [showDefaultPlaceholder]="true"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
            @if (menu()?.style?.print_folding === 2) {
              <select-item
                class="element-narrow"
                [menu]="menu()"
                [field]="'print_trifold_type'"
                [list]="styleConstants.trifoldOptions"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
            @if (
              (menu()?.style?.print_folding === 2 &&
                menu()?.style?.print_trifold_type === 2) ||
              menu()?.style?.print_folding === 1 ||
              menu()?.style?.print_folding === 0
            ) {
              <toggle-item
                [field]="'print_reverse'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              >
              </toggle-item>
            }
            @if (menu()?.style?.print_folding === 1) {
              <toggle-item
                [field]="'print_backcover'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
            <number-item
              class="element-narrow"
              field="print_mirror"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></number-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.binding' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <number-item
              field="paper_binding_extramargin"
              type="value"
              unit="mm"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></number-item>
            <toggle-item
              [field]="'print_duplex'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            >
            </toggle-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>local_printshop</mat-icon>
      {{ 'style.sidebar.panels.primary.print.professional' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.bleed' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <number-item
              field="paper_bleed"
              type="value"
              unit="mm"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></number-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.print.crop' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [field]="'paper_crop'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            >
            </toggle-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
