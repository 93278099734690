<mat-dialog-content>
  @for (item of data.children; track item) {
    <div>
      <p class="description">
        {{ 'additives.affects.' + item.name | transloco }}:
      </p>
      <app-option-item
        class="options"
        type="additives"
        (triggerOption)="data.changed($event)"
        [lang]="data.lang"
        [option]="item"
        [items]="data.items"
      ></app-option-item>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions class="actions">
  <button
    mat-button
    color="primary"
    class="continue"
    (click)="dialog.close(); stopPropagation($event)"
  >
    {{ 'shared.buttons.continue' | transloco }}
  </button>
</mat-dialog-actions>
