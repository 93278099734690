import { Component, inject, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PreviewComponent } from 'src/app/shared/Components/pdf-preview/pdf-preview.component';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'fiche-technique',
  templateUrl: './fiche-technique.component.html',
  styleUrls: ['./fiche-technique.component.css'],
  imports: [MatIconModule, TranslocoPipe],
})
export class FicheTechniqueComponent {
  dialog = inject(MatDialog);
  private router = inject(Router);

  readonly dish = input<Dish>(undefined);
  readonly lang = input<ContentLanguage>(undefined);

  showPreview = () => {
    this.dialog.open(PreviewComponent, {
      autoFocus: false,
      width: '890px',
      maxWidth: '90vw',
      height: '95%',
      data: { url: this.dish().preview },
    });
  };

  navigateToDish() {
    this.router.navigate(['/dishes'], {
      queryParams: { search: this.dish()[this.lang()] },
    });
  }
}
