@if (symbolsData[option().name] && option()) {
  <mat-checkbox
    [color]="color()"
    [disabled]="disabled()"
    [checked]="isChecked()"
    (change)="onCheckboxChange($event)"
  >
    {{ symbolsData[option().name].name }}
    @if (symbolsData[option().name].pictogram) {
      <div
        class="pictogram"
        [innerHTML]="symbolsData[option().name].pictogram | safe: true"
      ></div>
    } @else if (
      symbolsData[option().name].app_symbol || symbolsData[option().name].symbol
    ) {
      <span
        class="{{ this.type() === 'additives' ? classForSymbols : '' }}"
        [class.allergen-symbol]="
          this.type() !== 'additives' && !!symbolsData[option().name].app_symbol
        "
        [class.allergen-symbol-clear]="
          symbolsData[option().name].legend_symbol === 4
        "
      >
        {{
          symbolsData[option().name].app_symbol ||
            symbolsData[option().name].symbol
        }}
      </span>
    }
  </mat-checkbox>
}
