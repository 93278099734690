import { Injectable } from '@angular/core';
import { API_CONFIG } from 'src/app/app.config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  additives = '/additives/';
  affiliates = '/affiliates/';
  allergens = '/allergens/';
  backgrounds = '/backgrounds/';
  collections = '/collections/';
  collectionsLocations = '/collections/location_collections/';
  confirmEmail = '/rest-auth/registration/verify-email/';
  courses = '/courses/';
  customDeclarations = '/custom_declarations/';
  dashboard = '/dashboard/';
  diets = '/diets/';
  dishes = '/dishes/';
  emailPreferences = '/email_preferences/';
  emailVerification = '/users/verify_email/';
  fonts = '/fonts/';
  ingredient_categories = '/ingredient_categories/';
  ingredients = '/ingredients/';
  integrations = '/integrations/';
  inventory = '/inventory/';
  inventoryIn = '/inventory_in/';
  inventoryOut = '/inventory_out/';
  invitations = '/invitations/';
  labels = '/labels/';
  layouts = '/templates/';
  locationGroups = '/location_groups/';
  locations = '/locations/';
  login = '/rest-auth/login/';
  logout = '/rest-auth/logout/';
  menus = '/menus/';
  menuArchives = '/tasks/';
  menuImport = '/menu_import/';
  modules = '/modules/';
  onboardingTemplates = '/onboarding_templates/';
  reactivateTrial = '/rest-auth/reactivate-trial/';
  recipes = '/recipes/';
  recipeCategories = '/recipe_categories/';
  restoreAccount = '/rest-auth/restore-account/';
  registration = '/rest-auth/registration/';
  resendEmailConfirmation =
    '/rest-auth/registration/resend_email_confirmation/';
  resetPassword = '/rest-auth/password/reset/';
  resetPasswordChange = '/rest-auth/password/change/';
  resetPasswordConfirm = '/rest-auth/password/reset/confirm/';
  requests = '/requests/';
  rules = '/rulesets/';
  search = '/search/';
  separators = '/separators/';
  spellcheckItem = '/spellchecks/';
  supplierItems = '/supplier_items/';
  styles = '/styles/';
  support = '/support/';
  suppliers = '/suppliers/';
  supplier_items = '/supplier_items/';
  selectMultipleTemplates = '/onboarding_templates/select_multiple/';
  types = '/types/';
  userCurrent = '/user/';
  users = '/users/';
  userModules = '/user_modules/';
  userOnboardingTemplates = '/user_onboarding_templates/';

  constructor() {
    Object.keys(this).forEach((property) => {
      this[property] = API_CONFIG.value + this[property];
    });
  }
}
