import { Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'sent-success',
  templateUrl: './sent-success.component.html',
  styleUrls: ['./sent-success.component.scss'],
  imports: [MatIconModule, TranslocoPipe],
})
export class SentSuccessComponent {}
