import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { Router } from '@angular/router';
import { State } from 'src/app/reducers';
import { ObservablePortal } from 'src/app/shared/Models/registration-steps';
import { User } from 'src/app/shared/Models/user';
import { interval, Observable, Subject } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { completeSetup } from 'src/app/auth/ngrx/auth.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-registration-step-three',
  templateUrl: './registration-step-three.component.html',
  styleUrls: [
    '../registration-step-one/registration-step-one.component.scss',
    './registration-step-three.component.scss',
  ],
  imports: [
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class RegistrationStepThreeComponent implements AfterViewInit, OnInit {
  private ngrxStore = inject<Store<State>>(Store);
  private viewContainerRef = inject(ViewContainerRef);
  private router = inject(Router);

  readonly user = input.required<User>();

  readonly buttonPortal = output<ObservablePortal>();
  readonly doneEvent = output();

  readonly buttonPortalContent = viewChild<TemplateRef<void>>('button');

  titleParams: { firstName: string };
  clickEventsStream = new Subject<true | undefined>();
  enableNextStep = false;
  enableNextStep$ = new Subject<boolean>();
  values$: Observable<number>;

  ngAfterViewInit(): void {
    this.buttonPortal.emit({
      portal: new TemplatePortal(
        this.buttonPortalContent(),
        this.viewContainerRef,
      ),
      eventStream: this.clickEventsStream.asObservable(),
    });
  }

  ngOnInit(): void {
    let delay = 100;
    const user = this.user();
    this.titleParams = { firstName: user.first_name };
    if (user && !user?.status?.completed_setup) {
      this.ngrxStore.dispatch(completeSetup());
    } else {
      delay = 50;
    }
    this.values$ = interval(delay).pipe(
      take(50),
      finalize(() => this.finalizeCounter(user?.status?.completed_setup)),
      takeUntil(this.enableNextStep$),
    );
    if (user?.status?.email_verified && user?.status?.completed_setup) {
      this.enableNextStep = true;
      this.enableNextStep$.next(this.enableNextStep);
      this.enableNextStep$.complete();
    }
  }

  finalizeCounter(redirect = false): void {
    this.enableNextStep = true;
    this.doneEvent.emit();
    if (redirect) {
      this.router.navigate(['/']);
    }
  }
}
