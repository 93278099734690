import { Component, output, input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss'],
  imports: [MatButtonModule, MatIconModule, TranslocoPipe],
})
export class NavigationButtonsComponent {
  readonly currentStep = input(1);
  readonly first = input(false);
  readonly disabled = input(false);

  readonly next = output<void>();
  readonly prev = output<void>();
}
