import {
  Component,
  DestroyRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Dish } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'article-number',
  templateUrl: './article-number.component.html',
  styleUrls: ['./article-number.component.css'],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressBarModule,
    TranslocoPipe,
  ],
})
export class ArticleNumberComponent implements OnChanges, OnInit {
  private destroyRef = inject(DestroyRef);

  readonly translations = input<object>({});
  readonly dish = input<MenuDish>(undefined);

  readonly lang = input<ContentLanguage>(undefined);
  readonly changeDish = output<{
    menuDish: MenuDish;
    data: DeepPartial<Dish> & {
      onFulfilled?: () => void;
    };
  }>();

  currentMenuDish: MenuDish;
  control = new FormControl('', { nonNullable: true });
  loading = false;

  ngOnChanges(changes: SimpleChanges): void {
    const dish = this.dish();
    if (
      'dish' in changes &&
      dish &&
      changes.dish.currentValue.id !== changes.dish.previousValue?.id
    ) {
      this.control.reset('', { emitEvent: false });
      this.control.setValue(dish.dish_detail.article_number, {
        emitEvent: false,
      });
    }
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(
        tap(() => {
          this.currentMenuDish = this.dish();
        }),
        distinctUntilChanged(),
        debounceTime(400),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => this.onValueChange(value));
  }

  onValueChange(value: typeof this.control.value): void {
    if (this.currentMenuDish.dish_detail.article_number === value) {
      return null;
    }
    // Set the loading state
    this.loading = true;

    // Selectively update the currentMenuDish with the new data
    const newDish = new Dish({
      ...this.currentMenuDish.dish_detail,
      article_number: value,
    });
    this.currentMenuDish = new MenuDish(this.currentMenuDish).setDish(newDish);

    // Emit the changeDish event
    this.changeDish.emit({
      menuDish: this.currentMenuDish,
      data: {
        article_number: value,
        onFulfilled: () => {
          this.loading = false;
        },
      },
    });
  }
}
