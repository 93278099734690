import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { MtTooltipTemplateComponent } from './mt-tooltip-template/mt-tooltip-template.component';
import { Subject } from 'rxjs';

@Directive({
  selector: '[mtTooltip]',
  exportAs: 'mtTooltip',
  standalone: true,
})
export class MtTooltipDirective implements OnInit, OnDestroy, OnChanges {
  private overlay = inject(Overlay);
  private element = inject(ElementRef);
  private overlayPosBuilder = inject(OverlayPositionBuilder);

  readonly text = input<string>(undefined, { alias: 'mtTooltip' });
  readonly horizontal = input<boolean>(undefined, {
    alias: 'mtTooltipHoriziontal',
  });
  readonly canClose = input<boolean>(undefined);
  readonly isHtml = input<boolean>(undefined);

  readonly closed = output();

  private destroyed$ = new Subject<void>();

  overlayRef: OverlayRef;
  portal: ComponentPortal<MtTooltipTemplateComponent>;
  portalRef: ComponentRef<MtTooltipTemplateComponent>;

  @HostListener(`mouseenter`, ['$event'])
  show(event?: MouseEvent): void {
    const text = this.text();
    if (!text) return undefined;
    event?.stopPropagation();
    this.portal =
      this.portal || new ComponentPortal(MtTooltipTemplateComponent);
    if (this.overlayRef.hasAttached()) {
      this.portalRef.instance.state = `show`;
    } else {
      this.portalRef = this.overlayRef.attach(this.portal);
      if (this.isHtml()) {
        this.portalRef.instance.html = text;
      } else {
        this.portalRef.instance.text = text;
      }
      this.portalRef.instance.animationEndCallback = () => {
        this.overlayRef.detach();
      };
      this.portalRef.instance.hide.subscribe(() => this.hide());
    }
    this.portalRef.instance.canClose = this.canClose();
  }

  @HostListener(`mouseleave`, ['$event'])
  hide(event?: MouseEvent): void {
    if (!this.portalRef || (this.canClose() && event)) return undefined;
    event?.stopPropagation();
    this.portalRef.instance.state = `hide`;
    this.closed.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const isHtmlChanges = changes['isHtml'];
    if (
      isHtmlChanges &&
      isHtmlChanges.currentValue !== isHtmlChanges.previousValue
    )
      this.init();
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    const posStrategyHorizonal = this.overlayPosBuilder
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
          panelClass: `mt-tooltip-after${this.isHtml() ? '-html' : ''}`,
          offsetX: 15,
        },
      ]);
    const posStrategy = this.overlayPosBuilder
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          panelClass: `mt-tooltip-bottom${this.isHtml() ? '-html' : ''}`,
          offsetY: 15,
        },
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          panelClass: `mt-tooltip-top${this.isHtml() ? '-html' : ''}`,
          offsetY: -15,
        },
      ]);
    this.overlayRef = this.overlay.create({
      positionStrategy: this.horizontal() ? posStrategyHorizonal : posStrategy,
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
