import { TranslocoService } from '@jsverse/transloco';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  catchError,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  clearLastMenu,
  removeUserOnboardingTemplateMenu,
} from 'src/app/dashboard/ngrx/dashboard.actions';
import { State } from 'src/app/reducers';
import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  Menu,
  MenusResults,
  ShareMenuDetail,
} from 'src/app/shared/Models/menu';
import {
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { fetchUser } from 'src/app/shared/user/ngrx/user.actions';
import * as MenuActions from './menus-overview.actions';
import { selectMenus } from './menus-overview.selectors';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';

@Injectable()
export class MenusOverviewEffects {
  private actions$ = inject(Actions);
  private configService = inject(ConfigService);
  private genericsService = inject(GenericsService);
  private router = inject(Router);
  private store = inject<Store<State>>(Store);
  private transloco = inject(TranslocoService);
  private utils = inject(UtilsService);

  getCurrentMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.fetchMenus),
      tap(() => {
        this.store.dispatch(MenuActions.setTableDataLoading({ loading: true }));
      }),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ query, url }, location]) =>
        this.genericsService
          .get<MenusResults>(
            url ? url : `${this.configService.menus}`,
            this.utils.createQuery(
              UtilsService.addLocationParam(location, query),
            ),
          )
          .pipe(
            mergeMap((menus: MenusResults) => [
              MenuActions.setMenus({ menus }),
              MenuActions.setTableDataLoading({ loading: false }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  patchMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.changeMenu),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ menu, data }, location]) =>
        this.genericsService
          .patch<
            DeepPartial<Menu>,
            Menu
          >(menu.url, data, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap((result) => [
              MenuActions.setUpdatedMenu({ menu: result }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  duplicateMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.duplicateMenu),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(
        ([
          { id, date, diets, rule, options, redirectStep, consumerType },
          location,
        ]) => {
          const newData = {};
          if (date) newData['date'] = date;
          if (diets && diets.length) newData['diets'] = diets;
          if (rule) newData['rule'] = rule;
          if (consumerType) newData['type'] = consumerType;
          for (const key in options) {
            newData[key] = options[key];
          }
          return this.genericsService
            .post<
              DeepPartial<Menu>,
              Menu
            >(`${this.configService.menus}${id}/duplicate/`, newData, UtilsService.addLocationParam(location))
            .pipe(
              mergeMap((menu) => {
                if (redirectStep)
                  this.router.navigate([`/menus`, menu.id, redirectStep]);
                return [fetchUser()];
              }),
              catchError((error) => [handleHttpError({ error })]),
            );
        },
      ),
    ),
  );

  deleteChildren$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.deleteChildren),
      withLatestFrom(
        this.store.select(sharedFeature.selectCurrentLocation),
        this.store.select(selectMenus),
      ),
      switchMap(([{ id, query }, location, menus]) =>
        this.genericsService
          .post<
            {},
            { message: string }
          >(`${this.configService.menus}${id}/delete_children/`, {}, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap(() => [
              MenuActions.fetchMenus({
                query,
                url: (menus.count - 1) % 10 === 0 ? menus.previous : null,
              }),
              clearLastMenu({ id }),
              fetchUser(),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  deleteMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.deleteMenu),
      withLatestFrom(
        this.store.select(sharedFeature.selectCurrentLocation),
        this.store.select(selectMenus),
      ),
      switchMap(([{ id, query }, location, menus]) =>
        this.genericsService
          .delete(
            `${this.configService.menus}${id}/`,
            UtilsService.addLocationParam(location, {}),
          )
          .pipe(
            mergeMap(() => [
              clearLastMenu({ id }),
              removeUserOnboardingTemplateMenu({ menuId: id }),
              MenuActions.fetchMenus({
                query,
                url: (menus.count - 1) % 10 === 0 ? menus.previous : null,
              }),
              fetchUser(),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  regenerateMenus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.regenerateMenus),
      tap(() => {
        this.store.dispatch(MenuActions.setTableDataLoading({ loading: true }));
      }),
      withLatestFrom(
        this.store.select(sharedFeature.selectCurrentLocation),
        this.store.select(selectMenus),
      ),
      switchMap(([{ menu, query }, location, menus]) =>
        this.genericsService
          .post<
            {},
            { message: string }
          >(`${menu.url}regenerate_all/`, {}, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap(() => [
              MenuActions.fetchMenus({
                query,
                url: (menus.count - 1) % 10 === 0 ? menus.previous : null,
              }),
              fetchUser(),
              MenuActions.setTableDataLoading({ loading: false }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  refreshSubMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.refreshSubMenu),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ menu }, location]) =>
        this.genericsService
          .post<
            {},
            { message: string }
          >(`${menu.url}regenerate/`, {}, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap(() => {
              const message = this.transloco.translate(
                'menus.overview.refresh_sub_menu.done',
              );
              return [showSnackbarMessage({ message })];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  shareMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.shareMenu),
      tap(() => {
        this.store.dispatch(MenuActions.setTableDataLoading({ loading: true }));
      }),
      withLatestFrom(
        this.store.select(sharedFeature.selectCurrentLocation),
        this.store.select(selectMenus),
      ),
      switchMap(([{ url, shareMenuData, query }, location, menus]) =>
        this.genericsService
          .post<
            ShareMenuDetail,
            { message: string }
          >(`${url}share/`, shareMenuData, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap(() => [
              MenuActions.fetchMenus({
                query,
                url: (menus.count - 1) % 10 === 0 ? menus.previous : null,
              }),
              MenuActions.setTableDataLoading({ loading: false }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );
}
