import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  output,
  input,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { BackgroundImage, Menu } from 'src/app/shared/Models/menu';
import { Fulfillable } from 'src/app/shared/Models/models';
import { MTFont } from 'src/app/shared/Models/mtfont';
import { Separator } from 'src/app/shared/Models/separator';
import { User } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StyleMobileTabComponent } from './style-mobile-tab/style-mobile-tab.component';
import { StylePrintTabComponent } from './style-print-tab/style-print-tab.component';
import { StyleOptionsTabComponent } from './style-general-tab/style-general-tab.component';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'app-style-sidebar',
  templateUrl: './style-sidebar.component.html',
  styleUrls: ['./style-sidebar.component.scss'],
  imports: [
    MatTabsModule,
    StyleOptionsTabComponent,
    StylePrintTabComponent,
    StyleMobileTabComponent,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class StyleSidebarComponent implements OnChanges, OnInit {
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly lockedFields = input<string[]>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly styleModified = input<boolean>(undefined);
  readonly options = input<Separator[]>(undefined);
  readonly courses = input<Separator[]>(undefined);
  readonly mtFonts = input<MTFont[]>(undefined);
  readonly bgImages = input<BackgroundImage[]>(undefined);
  readonly user = input<User>(undefined);

  readonly showAllBackgrounds = output<'background' | 'background_coverpage'>();
  readonly updateMenu = output<DeepPartial<Menu>>();
  readonly uploadBackgroundImage = output<
    Fulfillable<File> & {
      field: string;
    }
  >();
  readonly uploadLogo = output<Fulfillable<File>>();

  disabledFields: string[] = [];
  blockFieldsControl = new FormControl(false);
  showLocks: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    const menu = this.menu();
    if (
      'menu' in changes &&
      menu &&
      (changes.menu.firstChange || changes.menu.previousValue?.id !== menu.id)
    ) {
      this.blockFieldsControl.setValue(menu.block_locked_fields, {
        emitEvent: false,
      });
      this.disabledFields = menu.template_detail?.locked_fields;
      if ([0, 1].includes(this.user()?.role)) {
        this.showLocks =
          menu.style.locked_fields.filter(
            (f) => !['logo', 'background_image'].includes(f),
          ).length > 0;
      } else if (menu.block_locked_fields) {
        this.disabledFields = [
          ...this.disabledFields,
          ...menu.style.locked_fields,
        ];
      }
    }
  }

  ngOnInit() {
    this.blockFieldsControl.valueChanges.subscribe((checked) => {
      this.updateMenu.emit({
        block_locked_fields: checked,
      });
    });
  }

  clearLocks = () => {
    this.updateMenu.emit({
      style: {
        locked_fields: [],
      },
    });
  };

  updateShowLocks = ({ checked }) => {
    this.showLocks = checked;
  };
}
