import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewContainerRef,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';

@Component({
  selector: 'top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class TopNavigationComponent implements OnInit {
  private overlayService = inject(OverlayService);

  readonly title = input<string>(undefined);
  readonly toggle = output<void>();

  readonly container = viewChild(`container`, { read: ViewContainerRef });

  ngOnInit(): void {
    const container = this.container();
    this.overlayService.registerContainer(`change-menu-name`, container);
    this.overlayService.registerContainer(`view-as-location`, container);
    this.overlayService.registerContainer(`create-button-container`, container);
  }
}
