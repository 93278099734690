import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { Component, inject, output, input } from '@angular/core';
import { State } from 'src/app/reducers/index';
import { Spellcheck } from 'src/app/shared/Models/dish';
import { ClipboardService } from 'ngx-clipboard';
import { showSnackbarMessage } from 'src/app/shared/ngrx/shared.actions';
import { CopyDataLineComponent } from '../copy-data-line/copy-data-line.component';
import { MatIconModule } from '@angular/material/icon';
import { BidiModule } from '@angular/cdk/bidi';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'grammar-corrections',
  templateUrl: './grammar-corrections.component.html',
  styleUrls: ['./grammar-corrections.component.scss'],
  imports: [
    MatExpansionModule,
    MatButtonModule,
    BidiModule,
    MatIconModule,
    CopyDataLineComponent,
    TranslocoPipe,
  ],
})
export class GrammarCorrectionsComponent {
  private clipboardService = inject(ClipboardService);
  private ngrxStore = inject<Store<State>>(Store);
  private translate = inject(TranslocoService);

  readonly addBottomSpace = input(false);
  readonly spellcheck = input<Spellcheck>(undefined);

  readonly addWord = output<string>();
  readonly ignoreSpellcheck = output<void>();

  copyMessage = '';

  constructor() {
    this.translate
      .selectTranslate(`menus.complete.copy`)
      .subscribe((val) => (this.copyMessage = val));
  }

  copied(value: string): void {
    this.clipboardService.copyFromContent(value);
    this.ngrxStore.dispatch(showSnackbarMessage({ message: this.copyMessage }));
  }

  wrapText = (text: string, offset: number, length: number): string => {
    return `${text.substring(
      0,
      offset,
    )}<span class='grammar-highlight'>${text.substring(
      offset,
      offset + length,
    )}</span>${text.substring(offset + length)}`;
  };
}
