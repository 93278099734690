import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  output,
  input,
  DestroyRef,
  inject,
} from '@angular/core';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import {
  isRegistrationOption,
  RegistrationOption,
} from 'src/app/shared/Models/registration-steps';
import { UserCategory } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatInputModule } from '@angular/material/input';
import { StopPropagationDirective } from '../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-registration-step-option',
  templateUrl: './registration-step-option.component.html',
  styleUrls: ['./registration-step-option.component.scss'],
  imports: [
    MatIconModule,
    MatFormFieldModule,
    StopPropagationDirective,
    MatInputModule,
    ReactiveFormsModule,
    TranslocoPipe,
  ],
})
export class RegistrationStepOptionComponent implements OnChanges, OnInit {
  private destroyRef = inject(DestroyRef);

  readonly label = input<string>(undefined);
  readonly lang = input.required<InterfaceLanguage>();
  readonly isSafari = input<boolean>(undefined);
  readonly option = input.required<
    RegistrationOption | (OnboardingTemplate & { active: boolean })
  >();
  readonly other = input<boolean>(undefined);

  readonly chooseOption = output<boolean>();

  category: UserCategory;
  inputCtrl = new FormControl('');
  isOther = false;
  view: string;

  ngOnInit(): void {
    if (isRegistrationOption(this.option())) {
      this.inputCtrl.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((v: string) => {
          (this.option() as RegistrationOption).payload = v;
          this.chooseOption.emit(!!v);
        });
      this.isOther = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const optionData = this.option();
    const lang = this.lang();
    if ('lang' in changes && lang && 'is_default' in optionData) {
      this.view = optionData[lang];
      this.category = optionData.user_category?.[0];
    }
  }
}
