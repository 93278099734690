import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoPipe],
})
export class CustomerSupportComponent {
  readonly isTrialActive = input<boolean>(undefined);
  readonly isConsumerActive = input<boolean>(undefined);

  readonly showMessageOverlayEvent = output<boolean>();
}
