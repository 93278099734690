import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Categories } from 'src/app/shared/constants/categories';
import {
  ContentLanguage,
  InterfaceLanguage,
  rtlLangs,
} from 'src/app/shared/constants/languages';
import { Dish, Variant } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import { BackgroundImage, ItemType } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { Fulfillable } from 'src/app/shared/Models/models';
import {
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { Separator } from 'src/app/shared/Models/separator';
import { AiCreditsRemaining, User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { NutritionRatingComponent } from './nutrition-rating/nutrition-rating.component';
import { FicheTechniqueComponent } from './fiche-technique/fiche-technique.component';
import { ArticleNumberComponent } from './article-number/article-number.component';
import { SelectImageComponent } from '../../../../shared/select-image/select-image.component';
import { ShowAndUploadImageComponent } from '../../../../../shared/Components/show-and-upload-image/show-and-upload-image.component';
import { OriginFieldComponent } from '../../../../../shared/Components/origin-field/origin-field.component';
import { WineInformationComponent } from './wine-information/wine-information.component';
import { FrozenProductComponent } from './frozen-product/frozen-product.component';
import { DeclarationsComponent } from '../../../../../shared/Components/declarations/declarations.component';
import { MagicStickComponent } from '../../../../../shared/Components/magic-stick/magic-stick.component';
import { VariantsComponent } from '../../../../../shared/Components/variants/variants.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';

@Component({
  selector: 'item-tab',
  templateUrl: './item-tab.component.html',
  styleUrls: ['./item-tab.component.scss'],
  imports: [
    ReactiveFormsModule,
    MtTooltipDirective,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    VariantsComponent,
    MagicStickComponent,
    DeclarationsComponent,
    FrozenProductComponent,
    WineInformationComponent,
    OriginFieldComponent,
    ShowAndUploadImageComponent,
    SelectImageComponent,
    ArticleNumberComponent,
    FicheTechniqueComponent,
    NutritionRatingComponent,
    TranslocoPipe,
  ],
})
export class DishInformationComponent implements OnChanges, OnInit, OnDestroy {
  private changeDetector = inject(ChangeDetectorRef);
  private ngrxStore = inject<Store<State>>(Store);
  private utils = inject(UtilsService);

  readonly aiCreditsRemaining = input<AiCreditsRemaining>(undefined);
  readonly aiDescriptionLoading = input<boolean>(undefined);
  readonly aiAllergensLoading = input<boolean>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly interfaceLang = input<InterfaceLanguage>(undefined);
  readonly isTrial = input<boolean>(undefined);
  readonly userSettings = input<any>(undefined);
  readonly currencySymbol = input<string>(undefined);
  readonly dish = input<MenuDish>(undefined);
  readonly profileComplete = input<boolean>(undefined);
  readonly recipes = input<Recipe[]>(undefined);
  readonly translations = input<object>({});
  readonly backgroundImages = input<BackgroundImage[]>(undefined);

  readonly changeOption = output<{
    data: DeepPartial<Dish> | DeepPartial<Ingredient>;
    type: 'allergens' | 'additives' | 'labels';
  }>();
  readonly modalOpened = output<{
    item: Dish | Ingredient;
    type: 'allergens' | 'additives';
    contentLang: ContentLanguage;
  }>();
  readonly loadMoreDishes = output<string>();
  readonly changeField = output<{
    menuDish: MenuDish;
    data: DeepPartial<Dish | Separator> & {
      onFulfilled?: () => void;
    };
  }>();
  readonly synchroniseRecipeDeclarations = output<{
    dish: MenuDish;
    type: 'add' | 'all';
  }>();
  readonly uploadDishImage = output<Fulfillable<FormData>>();
  readonly uploadBackgroundImage = output<Fulfillable<File>>();
  readonly showAllBackgrounds = output();
  readonly patchSeparator = output();
  readonly fetchDishRecipes = output<string>();
  readonly addIngredientEvent = output<Recipe>();
  readonly deleteIngredientEvent = output<{
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }>();
  readonly searchIngredients = output<string>();
  readonly selectedIngredientEvent = output<{
    ingredient_id: number;
    recipe: Recipe;
  }>();
  readonly createIngredientEvent = output<{
    newIngredient: Partial<Recipe>;
    recipe: Recipe;
  }>();
  readonly updateRecipeIngredientEvent = output<{
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: () => void;
    };
  }>();
  readonly updateIngredientEvent = output<{
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
    };
  }>();
  readonly generateAiDescription = output<void>();
  readonly generateAiAllergens = output<void>();

  user$ = this.ngrxStore.select(selectUser);

  currentUserData: User;
  private destroyed$ = new Subject<void>();
  additivesView = false;
  allergensView = false;
  allergensViewSimilar = false;
  additivesViewSimilar = false;
  currentMenudish: MenuDish;
  initialVariantValue = null;
  itemTabForm = new FormGroup({
    description: new FormControl(''),
    description_secondary: new FormControl(''),
    alcohol: new FormControl<number | null>(null, [
      Validators.min(0),
      Validators.max(100),
    ]),
    price: new FormControl<number | null>(null),
    origin: new FormControl(''),
    produce_method: new FormControl(''),
    carbohydrate_units: new FormControl<number | null>(null, [
      Validators.min(0),
    ]),
    info_url: new FormControl('', [
      Validators.pattern(
        '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?\\??([\\w=&.]*)?',
      ),
    ]),
  });
  labelsView = false;
  loadingBars = {
    alcohol: false,
    description: false,
    description_secondary: false,
    image: false,
    price: false,
    origin: false,
    produce_method: false,
    info_url: false,
    carbohydrate_units: false,
  };
  mobile = false;
  rtl = false;
  showImageSpinner = false;
  showVariants = false;
  hideLastImage = false;

  constructor() {
    this.mobile = window.innerWidth < 768;
    this.user$.subscribe((v) => (this.currentUserData = v));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const lang = this.lang();
    if ('lang' in changes && lang) {
      this.rtl = rtlLangs.includes(lang);
    }
    if (
      'dish' in changes &&
      (!changes.dish.previousValue ||
        changes.dish.previousValue.id !== changes.dish.currentValue.id)
    ) {
      // if the form is dirty, submit the changes first
      if (this.itemTabForm.dirty) {
        this.onValueChanged(this.itemTabForm.value);
      }

      this.initialVariantValue = null;
      Object.keys(this.loadingBars).forEach(
        (key) => (this.loadingBars[key] = false),
      );
      const backgroundImages = this.backgroundImages();
      const dish = this.dish();
      if (
        ('backgroundImages' in changes || 'dish' in changes) &&
        backgroundImages &&
        dish
      ) {
        this.hideLastImage =
          !!dish?.user_details.background &&
          backgroundImages.length > 2 &&
          backgroundImages.filter(
            (img) => img.id === this.dish()?.user_details.background,
          ).length === 0;
      }
      if (dish) {
        this.showVariants =
          !!dish[dish?.separator ? 'separator_detail' : 'dish_detail'].variants
            ?.length;
      }
      if (dish && lang) {
        this.setFormOnChange();
      }
    } else if ('dish' in changes) {
      Object.keys(this.itemTabForm.controls).forEach((key: string) => {
        if (
          this.dish()?.dish_detail?.user_details?.[key] !==
            this.itemTabForm.controls[key].value &&
          !this.itemTabForm.controls[key].dirty
        ) {
          this.setFieldOnChange(key);
        }
      });
    }
  }

  ngOnInit(): void {
    this.itemTabForm.valueChanges
      .pipe(
        tap(() => (this.currentMenudish = this.dish())),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
        debounceTime(400),
        takeUntil(this.destroyed$),
      )
      .subscribe((data) => this.onValueChanged(data));
  }

  @HostListener('window:resize', ['$event'])
  onResize = (event) => {
    this.mobile = event.target.innerWidth < 768;
  };

  addVariant() {
    this.currentMenudish = this.dish();
    this.initialVariantValue = this.itemTabForm.controls.price.value;
    this.itemTabForm.controls.price.patchValue(null, { emitEvent: false });
    this.showVariants = true;
  }

  blurInput = (target: EventTarget) => (target as HTMLElement).blur();

  onValueChanged(data: typeof this.itemTabForm.value): void {
    if (!this.itemTabForm.valid) return undefined;
    const newData: DeepPartial<Dish | Separator> = {};
    const loading: string[] = [];

    // Helper function to update the user_details object inside the newData object
    const updateField = (key: string, value: typeof this.itemTabForm.value) => {
      let langKey: string = key;
      if (key === 'description' || key === 'description_secondary') {
        langKey = `${key}_${this.lang()}`;
      }
      if (
        this.currentMenudish[
          this.currentMenudish?.separator ? 'separator_detail' : 'dish_detail'
        ].user_details[langKey] === value
      )
        return;
      if (!newData['user_details']) newData['user_details'] = {};
      newData['user_details'][langKey] = value;
      loading.push(...this.setLoadingBar(key, true));
    };

    // Loop through the form fields and update the user_details object inside the newData object
    Object.keys(this.itemTabForm.controls)
      .filter((key) => key in data && this.itemTabForm.controls[key].dirty)
      .forEach((key: string) => {
        // Parse the price field
        if (key === 'price') data[key] = this.parseTextPrice(data[key]);
        // Update the field in the newData
        updateField(key, data[key]);
      });

    // Emit the changeField event if there are any changes
    if (Object.keys(newData).length) this.patchFields(newData, loading);
  }

  parseTextPrice = (price: number | string): number => {
    let parsedPrice = this.utils.validatePrice(price);
    if (parsedPrice < 0) parsedPrice = null;
    return parsedPrice;
  };

  patchFields(
    data: DeepPartial<Dish | Separator>,
    clearableLoaders: string[] = [],
  ): void {
    this.changeField.emit({
      menuDish: this.currentMenudish ?? this.dish(),
      data: {
        onFulfilled: () =>
          clearableLoaders.forEach((key) => (this.loadingBars[key] = false)),
        ...data,
      },
    });
  }

  selectImage(id: number): void {
    this.patchFields({
      user_details: { background: id, image: null },
    });
  }

  setFieldOnChange(field: string): void {
    const itemType = this.dish()?.separator
      ? 'separator_detail'
      : 'dish_detail';
    let objectField = field;
    if (field === 'description' || field === 'description_secondary') {
      objectField = `${field}_${this.lang()}`;
    }
    this.itemTabForm.patchValue(
      {
        [field]: this.dish()[itemType].user_details?.[objectField],
      },
      { emitEvent: false },
    );
  }

  setFormOnChange(): void {
    this.itemTabForm.reset({}, { emitEvent: false });
    Object.keys(this.itemTabForm.controls).forEach((key: string) =>
      this.setFieldOnChange(key),
    );
  }

  setLoadingBar(field: string, value: boolean): string[] {
    const loading = [];
    const loadingField = field.endsWith(this.lang())
      ? field.slice(0, -3)
      : field;
    if (this.currentMenudish.id === this.dish().id) {
      this.loadingBars[loadingField] = value;
      if (value) loading.push(loadingField);
    }
    return loading;
  }

  hasModule(code: string): boolean {
    return this.utils.hasModules(code);
  }

  hasModuleSetting(code: string, setting: string, value: any): boolean {
    return this.utils.hasModuleSetting(code, setting, value);
  }

  showRating(): boolean {
    const category = this.dish().dish_detail.category;
    return category === Categories.DISH || category === Categories.BEVERAGE;
  }

  substituteParam(text: string, paramKey: string, paramValue: any) {
    return text.replace(`{{${paramKey}}}`, paramValue);
  }

  synchroniseDeclarations(data: {
    dish: Dish | Ingredient;
    type: 'all' | 'add';
  }) {
    this.synchroniseRecipeDeclarations.emit({
      ...data,
      dish: this.dish(),
    });
  }

  uploadImage(file: File): void {
    if (!file) return undefined;
    const data = new FormData();
    data.append('user_details.image', file);
    this.showImageSpinner = true;
    this.uploadDishImage.emit({
      payload: data,
      onFulfilled: () => {
        this.showImageSpinner = false;
        this.changeDetector.markForCheck();
      },
    });
  }

  uploadBackground(file: File): void {
    this.showImageSpinner = true;
    this.uploadBackgroundImage.emit({
      payload: file,
      onFulfilled: () => {
        this.showImageSpinner = false;
        this.changeDetector.markForCheck();
      },
    });
    // Temporary clearing of the deprecated background image
    const dish = this.dish();
    if (dish?.user_details?.image) {
      this.changeField.emit({
        menuDish: dish,
        data: { user_details: { image: null } },
      });
    }
  }

  variantsChanged(data: { item: ItemType; variants: Partial<Variant>[] }) {
    this.changeField.emit({
      data: { variants: data.variants, user_details: { price: null } },
      menuDish: data.item as MenuDish,
    });
    if (!data.variants.length && this.dish().dish) this.showVariants = false;
  }

  variantsCleared(item: ItemType) {
    if (!this.dish().dish) return;
    this.showVariants = false;
    if (!(item as MenuDish).dish_detail.variants.length) return;
    this.changeField.emit({
      data: { variants: [], user_details: { price: null } },
      menuDish: item as MenuDish,
    });
  }

  showTrialBanner() {
    this.utils.showTrialBlockedBox();
  }

  ngOnDestroy() {
    if (this.itemTabForm.dirty) {
      this.onValueChanged(this.itemTabForm.value);
    }
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
