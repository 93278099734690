import { Component, inject, output, viewChild, input } from '@angular/core';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Rule } from 'src/app/shared/Models/ruleset';
import { SimpleType, Type } from 'src/app/shared/Models/type';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'locations-menu-context-item',
  styleUrls: ['./locations-menu-context-item.component.css'],
  templateUrl: './locations-menu-context-item.component.html',
  imports: [MatMenuModule, MatIconModule, MatTooltipModule, TranslocoPipe],
})
export class LocationsMenuContextItemComponent {
  private utils = inject(UtilsService);

  readonly lang = input<InterfaceLanguage>(undefined);
  readonly items = input<(Rule | Type)[]>(undefined);
  readonly privileges = input<any>(undefined);

  readonly createArchive = output<void>();
  readonly createMenu = output<number>();

  readonly childMenu = viewChild<MatMenu>('childMenu');

  getAlternativeLanguage = (item: Rule | SimpleType) =>
    this.utils.tryGetLabel(item, this.lang()).name;
}
