import { Component } from '@angular/core';
import { SUPPORT_EMAIL } from 'src/app/app.config';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'confirmed-email-error',
  templateUrl: './confirmed-email-error.component.html',
  styles: [
    `
      .confirm-error {
        text-align: center;
      }
      .title {
        font-size: 50px;
        font-weight: 300;
        padding: 30px 0;
      }
      .note {
        font-weight: 300;
      }
    `,
  ],
  imports: [TranslocoPipe],
})
export class ConfirmedEmailErrorComponent {
  supportEmail = SUPPORT_EMAIL.value;
}
