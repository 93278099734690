import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  output,
  viewChild,
  viewChildren,
  input,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import {
  MatChipInputEvent,
  MatChipOption,
  MatChipsModule,
} from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslocoPipe } from '@jsverse/transloco';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { StopPropagationDirective } from 'src/app/shared/Directives/stop-propagation/stop-propagation.directive';
import {
  PreIngredients,
  SimpleIngredient,
} from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';

interface IngredientChoice {
  id: number;
  display: string;
  remove: string;
  ingredient: SimpleIngredient;
}
interface IngredientTag {
  value: number;
  display: string;
  remove: string;
  italic: boolean;
  ingredient: SimpleRecipeIngredient;
}
@Component({
  selector: 'app-ingredients',
  templateUrl: './ingredients.component.html',
  styleUrls: ['./ingredients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatOptionModule,
    StopPropagationDirective,
    MatProgressBarModule,
    TranslocoPipe,
  ],
})
export class IngredientsComponent implements AfterViewInit, OnChanges, OnInit {
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly utils = inject(UtilsService);

  readonly ingredientsAuto = input.required<SimpleIngredient[]>();
  readonly lang = input.required<ContentLanguage>();
  readonly recipe = input<Recipe>(undefined);
  readonly translations = input<object>(undefined);

  readonly addIngredientToRecipe = output<{
    ingredient: SimpleIngredient;
    recipe: Recipe;
    onFulfilled: () => void;
  }>();
  readonly addMultipleIngredientsToRecipeEvent = output<{
    recipe: Recipe;
    data: PreIngredients;
  }>();
  readonly createNewIngredientEvent = output<{
    recipe: Recipe;
    data: Partial<Recipe>;
    onError?: () => void;
    params?: RecipeParams;
  }>();
  readonly clearIngredientsAuto = output<void>();
  readonly deleteDishRecipeIngredientEvent = output<{
    recipe: Recipe;
    recipeIngredient: SimpleRecipeIngredient;
  }>();
  readonly fetchIngredientsAuto = output<{
    search: string;
    recipeId: number;
  }>();
  readonly closeInfo = output<void>();
  readonly getInfo = output<string>();
  readonly refetch = output<void>();

  currentlyRemoving: number;
  filteredIngrs: IngredientChoice[] = [];
  ingrControl = new FormControl('');
  ingredientTags: IngredientTag[];
  loading = false;
  removable = true;
  selectable = true;
  separatorKeysCodes = [ENTER, COMMA, SEMICOLON, TAB];
  visible = true;

  readonly ingrInput = viewChild<ElementRef<HTMLInputElement>>('ingrInput');
  readonly chipRef = viewChildren(MatChipOption);

  ngOnChanges(changes: SimpleChanges): void {
    this.currentlyRemoving = undefined;
    const lang = this.lang();
    if (changes.recipe && lang) {
      this.getIngredients();
      this.loading = false;
    }
    const ingredientsAuto = this.ingredientsAuto();
    if ('ingredientsAuto' in changes && ingredientsAuto) {
      this.filteredIngrs = ingredientsAuto.map(
        (ingredient: SimpleIngredient) => ({
          id: ingredient.id,
          display: ingredient[lang],
          remove: ingredient.url,
          ingredient,
        }),
      );
    }
  }

  ngOnInit(): void {
    this.ingrControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((v) => typeof v === 'string' && v.length >= 3),
      )
      .subscribe((v: string) => {
        this.getAutocomplete(v);
      });
  }

  ngAfterViewInit(): void {
    if (this.lang()) this.getIngredients();
  }

  getIngredients(): void {
    const recipe = this.recipe();
    if (!recipe) return undefined;
    this.ingredientTags = recipe?.ingredients_list?.map((ingredient) => {
      const { name, italic } = this.utils.tryGetLabel(
        ingredient.ingredient_detail,
        this.lang(),
      );
      return {
        value: ingredient.id,
        display: name,
        remove: ingredient.url,
        italic: italic,
        ingredient,
      };
    });
  }

  getAutocomplete = (search: string): void => {
    this.fetchIngredientsAuto.emit({ search, recipeId: this.recipe().id });
  };

  remove({ ingredient }: { ingredient: SimpleRecipeIngredient }): void {
    if (this.currentlyRemoving === ingredient.id) return undefined;
    this.currentlyRemoving = ingredient.id;
    this.closeInfo.emit();
    this.deleteDishRecipeIngredientEvent.emit({
      recipe: this.recipe(),
      recipeIngredient: ingredient,
    });
    this.loading = true;
    this.refetch.emit();
  }

  add(data: MatChipInputEvent, autoTrigger?: MatAutocompleteTrigger): void {
    const name = data?.value || data?.chipInput?.inputElement?.value;
    if (!name) return undefined;
    this.closeInfo.emit();
    this.loading = true;
    this.createNewIngredientEvent.emit({
      recipe: this.recipe(),
      data: { [this.lang()]: name },
      onError: () => {
        this.loading = false;
        this.changeDetector.markForCheck();
      },
      params: { current_recipe: this.recipe().id },
    });
    this.clearInput(autoTrigger);
  }

  selected(data: MatChipOption, ingr: IngredientTag): void {
    if (ingr.ingredient.id === this.currentlyRemoving) return undefined;
    if (!data?.selected) {
      data.deselect();
      this.closeInfo.emit();
    } else {
      data.select();
      this.getInfo.emit(ingr.remove);
    }
    const chipRef = this.chipRef();
    if (chipRef) {
      chipRef
        .filter((chip) => chip.id !== data.id)
        .forEach((chip) => {
          chip.selected = false;
        });
    }
  }

  autocompleteSelected({
    option: { value },
  }: MatAutocompleteSelectedEvent): void {
    this.loading = true;
    this.addIngredientToRecipe.emit({
      ingredient: (value as IngredientChoice).ingredient,
      recipe: this.recipe(),
      onFulfilled: () => {
        this.loading = false;
        this.changeDetector.markForCheck();
      },
    });
    this.clearInput();
  }

  clearInput(autoTrigger?: MatAutocompleteTrigger): void {
    const ingrInput = this.ingrInput();
    if (ingrInput) {
      ingrInput.nativeElement.value = '';
      ingrInput.nativeElement?.blur();
    }
    this.refetch.emit();
    autoTrigger?.closePanel();
  }

  async paste(e: ClipboardEvent): Promise<void> {
    const value: string = await new Promise((resolve) => {
      e?.clipboardData?.items[0].getAsString((data: string) => {
        resolve(data);
      });
    });
    if (new RegExp(`[,|;]`).test(value)) {
      const listOfTags = value.split(new RegExp(`[,|;]`));
      if (!listOfTags && !listOfTags.length) return undefined;
      this.addMultipleIngredientsToRecipeEvent.emit({
        recipe: this.recipe(),
        data: {
          ingredients: listOfTags.map((name) => ({
            [this.lang()]: name.trim(),
          })),
        },
      });
    } else {
      this.createNewIngredientEvent.emit({
        recipe: this.recipe(),
        data: { [this.lang()]: value },
        onError: () => {
          this.loading = false;
          this.changeDetector.markForCheck();
        },
        params: { current_recipe: this.recipe().id },
      });
    }
    this.loading = true;
    (e.target as HTMLInputElement).value = '';
    this.refetch.emit();
  }
}
