import {
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  output,
} from '@angular/core';
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SpinnerComponent } from '../../spinner/spinner.component';

interface SimpleDialogData {
  title?: string;
  content?: string | string[];
  additionalContent?: Observable<string>;
  confirmMessage?: string;
  cancelMessage?: string;
  warning?: boolean;
  closed?: boolean;
  errorEvent?: EventEmitter<string>;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styles: [
    `
      ul {
        margin-top: 0;
      }
      .buttons {
        display: block;
        text-align: end;
      }
      button {
        margin-left: 5px;
      }
      .spacer {
        width: 377px;
        height: 100px;
      }
      .content {
        padding: 5px 0;
      }
    `,
  ],
  imports: [
    MatDialogTitle,
    SpinnerComponent,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    AsyncPipe,
  ],
})
export class ConfirmDialogComponent implements OnInit, OnChanges {
  private dialogRef =
    inject<MatDialogRef<ConfirmDialogComponent>>(MatDialogRef);
  protected data = inject<SimpleDialogData>(MAT_DIALOG_DATA);

  confirmed = false;
  loading = false;
  isList = false;
  errorMessage: string;

  readonly dialogConfirmed = output<boolean>();

  close() {
    this.dialogRef.close();
    this.dialogConfirmed.emit(false);
  }

  confirm() {
    if (this.data.closed || !this.data.additionalContent) {
      this.dialogRef.close(true);
      this.dialogConfirmed.emit(true);
    } else {
      this.dialogConfirmed.emit(true);
      this.confirmed = true;
      this.loading = true;
    }
  }

  ngOnInit() {
    const additionalContent = this.data.additionalContent;
    if (additionalContent) {
      additionalContent.subscribe((el) => {
        this.loading = false;
      });
    }
    const errorEvent = this.data.errorEvent;
    if (errorEvent) {
      errorEvent.subscribe((errorMsg) => {
        this.errorMessage = errorMsg;
        this.loading = false;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('content' in changes) {
      this.isList = Array.isArray(this.data.content);
    }
  }
}
