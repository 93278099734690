import { EventEmitter, Injectable, isDevMode, Output } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Categories } from 'src/app/shared/constants/categories';
import {
  Place,
  TypeOfDnD,
} from 'src/app/shared/Directives/dnd-sort/dnd-sort.directive';
import { ItemCategory } from 'src/app/shared/Models/dish';
import { MenuDishNode } from 'src/app/shared/Models/menu-dish-node';
import {
  MenuDish,
  MenuDishLevel,
  SimpleMenuDish,
} from 'src/app/shared/Models/menudish';
import * as _ from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

export interface DnDData {
  nodeToMove: MenuDishNode;
  targetNode: MenuDishNode;
  place: Place;
  typeOfDnD: TypeOfDnD;
}

const INIT_ALL_EXPANDED_MAX_LENGTH = 20;

@Injectable({
  providedIn: 'root',
})
export class TreeManagerService {
  @Output() previousLength = new EventEmitter<number>();
  @Output() refreshList = new EventEmitter();
  @Output() treeStructureChanged = new BehaviorSubject<{
    items: MenuDishNode[];
    updateList: boolean;
    afterDnD: boolean;
  }>({ items: [], updateList: false, afterDnD: false });
  @Output() updateList = new EventEmitter<boolean>();

  private storedItems: MenuDishNode[] = [];
  private lastNode: MenuDishNode;

  setSource({
    list,
    search,
  }: {
    list: (SimpleMenuDish | MenuDish)[];
    search: boolean;
  }): void {
    // set source only if none is set yet and search is not active
    if (this.storedItems?.length && !search) return;
    this.storedItems = this.buildTree(_.cloneDeep(list), search);
    this.previousLength.emit(this.storedItems.length);
    if (!search && list.length <= INIT_ALL_EXPANDED_MAX_LENGTH) {
      this.expandAll();
    } else {
      this.refreshList.emit();
    }
    this.updateVirtualScroll();
  }

  clearTree(): void {
    this.lastNode = null;
    this.storedItems = [];
    this.previousLength.emit(0);
    this.refreshList.emit();
    this.updateVirtualScroll();
  }

  findNode = (nodeIndex: number): MenuDishNode | undefined =>
    this.storedItems.find((md) => md.index === nodeIndex);

  findNodeIndex = (menudish: SimpleMenuDish | MenuDish): number =>
    this.storedItems.find((md) => md.dish.id === menudish.id)?.index;

  findNodeAndUpdateMenuDish(dish: SimpleMenuDish | MenuDish): boolean {
    const nodeDetail = this.storedItems.find(
      (node) => node?.dish?.id === dish.id,
    );
    if (!nodeDetail) return undefined;
    this.updateMenuDish(nodeDetail, dish);
    return true;
  }

  updateMenuDish(node: MenuDishNode, dish: SimpleMenuDish | MenuDish): boolean {
    if (!node) return false;
    const cloned = _.cloneDeep(node.dish);
    node.dish = Object.assign(cloned, dish);
    return true;
  }

  collapse(node: MenuDishNode): void {
    if (!(this.storedItems && _.isArray(this.storedItems))) return;
    this.previousLength.emit(this.storedItems.length);
    const expandedChildren = this.getExpandedChildren(node, [], true);
    const ids = [...expandedChildren].filter((item) => item !== undefined);
    this.storedItems = this.storedItems.filter((n: MenuDishNode) => {
      const result = !ids.includes(n.removeId);
      if (!result) n.dottedLineMultiplier = 0;
      return result;
    });
    node.shrinkLineRecursively(ids.length - 1, true);
    node.isExpanded = false;
    this.updateVirtualScroll();
    this.updateList.emit(true);
  }

  expand(index: number, isExpanded: boolean): void {
    const section: MenuDishNode = this.storedItems[index];
    if (!section || !(section.children || section.isExpandable()))
      return undefined;
    section.isExpanded = isExpanded;
    if (section.isExpanded) {
      this.previousLength.emit(this.storedItems.length);
      this.storedItems.splice(
        index + 1,
        0,
        ...(section.children ?? []).map((child: MenuDishNode) => {
          child.removeId = section.dish.id;
          return child;
        }),
      );
      section.stretchLineRecursively((section.children ?? []).length);
      section.isExpanded = true;
      this.updateVirtualScroll(true);
    }
  }

  collapseAll(): void {
    this.previousLength.emit(this.storedItems.length);
    this.storedItems.forEach((node) => (node.isExpanded = false));
    this.storedItems.forEach((node) => (node.dottedLineMultiplier = 1));
    this.storedItems = this.storedItems.filter((node) => node.dish.level === 1);
    this.updateVirtualScroll();
    this.updateList.emit(true);
  }

  expandAll(): void {
    const expandedItems: MenuDishNode[] = [];
    this.storedItems.forEach((node) => {
      this.recursivelyAddChildren(node, expandedItems);
    });
    this.previousLength.emit(this.storedItems.length);
    this.storedItems = expandedItems;
    this.updateVirtualScroll();
    this.updateList.emit();
  }

  isAnyExpanded(): boolean {
    return this.storedItems.some((node: MenuDishNode) => node.isExpanded);
  }

  dnd({ nodeToMove, targetNode, place, typeOfDnD }: DnDData): void {
    switch (place) {
      case Place.BOTTOM: {
        this.moveBottom(nodeToMove);
        break;
      }
      case Place.BELOW: {
        this.moveAfter(nodeToMove, targetNode, typeOfDnD);
        break;
      }
      case Place.ABOVE: {
        this.moveBefore(nodeToMove, targetNode, typeOfDnD);
        break;
      }
      case Place.INSIDE: {
        this.moveInside(nodeToMove, targetNode);
      }
    }
  }

  removeNode(node: MenuDishNode, ids?: number[]): void {
    // removing a Day or a Section
    if (node.hasChildren() && ids) {
      let count = 0;
      this.storedItems.forEach(
        (item: MenuDishNode) => ids.includes(item.dish.id) && count++,
      );
      this.storedItems.splice(node.index, count);
      if (node.parentNode) {
        node.parentNode.children = _.remove(
          node.parentNode.children,
          (elem) => elem.dish.id !== node.dish.id,
        );
        node.parentNode.shrinkLineRecursively(count);
      }
    } else {
      // removing a simple Dish or a Separator
      if (node.index === this.storedItems.length - 1) {
        this.storedItems.splice(this.storedItems.length - 1, 1);
        this.lastNode = this.storedItems[this.storedItems.length - 1];
      } else {
        this.storedItems.splice(node.index, 1);
      }
      if (node.parentNode) {
        node.parentNode.children = _.remove(
          node.parentNode.children,
          (elem) => elem.dish.id !== node.dish.id,
        );
        node.parentNode.shrinkLineRecursively(1);
      }
    }
    if (!this.storedItems.length) {
      this.lastNode = null;
    } else {
      this.recalculateRealIndices(
        node.index,
        this.storedItems.length,
        node.realIndex,
      );
    }
    this.afterDnD(`removeNode`, node, null);
  }

  insertNode(
    parentNode: MenuDishNode,
    menuDish: SimpleMenuDish | MenuDish,
  ): MenuDishNode {
    let expandedChildrenLength = parentNode.getChildrenLength(true);
    const realIndex = parentNode.getChildrenLength() + parentNode.realIndex + 1;
    const newNode = new MenuDishNode(
      null,
      _.cloneDeep(menuDish),
      realIndex,
      parentNode,
    );
    newNode.level = newNode.dish.level = ((parentNode.dish.level as 1 | 2 | 3) +
      1) as MenuDishLevel;
    if (![1, 2, 3, 4].includes(newNode.level)) {
      throw new Error(`Invalid level at insert node: ${newNode.level}`);
    }
    parentNode.children
      ? parentNode.children.push(newNode)
      : (parentNode.children = [newNode]);
    if (!parentNode.isExpanded) {
      this.expand(parentNode.index, true);
      expandedChildrenLength = parentNode.getChildrenLength(true);
    } else {
      newNode.removeId = parentNode.dish.id;
      this.storedItems.splice(
        parentNode.index + expandedChildrenLength + 1,
        0,
        newNode,
      );
      expandedChildrenLength++;
    }
    if (newNode.realIndex === this.storedItems.length - 1) {
      this.lastNode = newNode;
    }
    this.recalculateRealIndices(
      parentNode.index + expandedChildrenLength + 1,
      this.storedItems.length,
      realIndex + 1,
    );
    this.afterDnD(`insertItem`, parentNode, null);
    return newNode;
  }

  appendNode(
    menuDish: SimpleMenuDish | MenuDish,
    category: ItemCategory,
  ): MenuDishNode {
    if (
      category !== Categories.DAY &&
      this.lastNode?.dish.url &&
      (this.lastNode?.isDay() ||
        (this.lastNode?.isSection() && category !== Categories.SECTION))
    ) {
      this.recursivelyExpand(this.lastNode);
      return this.insertNode(this.lastNode, menuDish);
    }
    if (category === Categories.SECTION) {
      const parent = this.getLastFlatParent();
      if (parent && parent.isDay() && parent.dish.url) {
        return this.insertNode(parent, menuDish);
      }
    }
    const menuDishNode = new MenuDishNode(
      null,
      _.cloneDeep(menuDish),
      this.lastNode ? this.lastNode.realIndex + 1 : 0,
      null,
    );
    this.manipulateNodetoAppend(category, menuDishNode);
    this.storedItems.push(menuDishNode);
    this.lastNode = menuDishNode;
    this.afterDnD(`append-${category}`, null, null);
    return menuDishNode;
  }

  private buildTree(
    list: (SimpleMenuDish | MenuDish)[],
    search: boolean,
  ): MenuDishNode[] {
    return list
      .map((menuDish: SimpleMenuDish | MenuDish, index: number) => {
        if (!search && menuDish.level > 1) return null;
        if (search) menuDish.level = 1;
        const menuDishNode = new MenuDishNode(null, menuDish, index);
        this.lastNode = menuDishNode;
        if (!search && menuDishNode.isExpandable()) {
          menuDishNode.children = this.findChildrenAndSetLastNode(
            index,
            2,
            list,
            menuDishNode,
          );
        }
        return menuDishNode;
      })
      .filter((obj) => obj);
  }

  private updateVirtualScroll(updateList = false): void {
    this.treeStructureChanged.next({
      items: this.storedItems,
      updateList,
      afterDnD: true,
    });
  }

  private manipulateNodetoAppend(
    category: string,
    nodeToAppend: MenuDishNode,
  ): void {
    if (!this.lastNode) return;
    if (category === Categories.DAY || category === Categories.SECTION) return;
    this.recursivelyExpand(this.lastNode);
    if (this.lastNode.isExpandable() && this.lastNode.dish.url) {
      this.appendNodeInsideNode(nodeToAppend);
    } else {
      this.appendNodeToEnd(nodeToAppend);
    }
  }

  private appendNodeInsideNode(menuDishNode: MenuDishNode): MenuDishNode {
    menuDishNode.parentNode = this.lastNode;
    menuDishNode.realIndex =
      this.lastNode.realIndex + this.lastNode.getChildrenLength() + 1;
    menuDishNode.level = ((this.lastNode.level as 1 | 2 | 3) + 1) as 2 | 3 | 4;
    if (![1, 2, 3, 4].includes(menuDishNode.level)) {
      throw new Error(
        `TREE ERROR: Wrong level when appending node inside node: ${menuDishNode.level}`,
      );
    }
    this.lastNode.children = this.lastNode.children || [];
    this.lastNode.children.push(menuDishNode);

    menuDishNode.removeId = this.lastNode.dish.id;
    if (!this.lastNode.isExpandable() || this.lastNode.isExpanded)
      this.lastNode.stretchLineRecursively(1);
    return menuDishNode;
  }

  private appendNodeToEnd(menuDishNode: MenuDishNode): MenuDishNode {
    menuDishNode.parentNode = this.lastNode.parentNode;
    menuDishNode.realIndex = this.lastNode.realIndex + 1;
    menuDishNode.level = this.lastNode ? this.lastNode.level : 1;
    if (![1, 2, 3, 4].includes(menuDishNode.level)) {
      throw new Error(
        `TREE ERROR: Wrong level when appending node to end: ${menuDishNode.level}`,
      );
    }
    if (this.lastNode.parentNode) {
      this.lastNode.parentNode.children =
        this.lastNode.parentNode.children || [];
      this.lastNode.parentNode.children.push(menuDishNode);

      menuDishNode.removeId = this.lastNode.parentNode.dish.id;
      this.lastNode.parentNode.stretchLineRecursively(1);
    }
    return menuDishNode;
  }

  private moveNode(
    nodeToMove: MenuDishNode,
    targetNode: MenuDishNode,
    direction: 'before' | 'after',
    nodeManipulationCallback: (
      nodeToMove: MenuDishNode,
      targetNode: MenuDishNode,
    ) => void,
  ): void {
    const numberOfExpandedChildren = nodeToMove.getChildrenLength(true);
    const sourceAndChildren = this.storedItems.slice(
      nodeToMove.index,
      nodeToMove.index + numberOfExpandedChildren + 1,
    );

    nodeManipulationCallback(nodeToMove, targetNode);

    const insertionIndex =
      direction === 'after' ? targetNode.index + 1 : targetNode.index;
    this.storedItems.splice(insertionIndex, 0, ...sourceAndChildren);

    let startIndex: number;
    let endIndex: number;
    let startWith: number;

    // update indexes
    if (targetNode.index > nodeToMove.index) {
      this.storedItems.splice(nodeToMove.index, numberOfExpandedChildren + 1);
      startIndex = nodeToMove.index;
      endIndex = targetNode.index + 1;
      startWith = nodeToMove.realIndex;
    } else {
      this.storedItems.splice(
        nodeToMove.index + numberOfExpandedChildren + 1,
        numberOfExpandedChildren + 1,
      );
      startIndex = targetNode.index;
      endIndex = nodeToMove.index + numberOfExpandedChildren + 1;
      startWith = targetNode.realIndex;
    }
    nodeToMove.index = targetNode.index;
    targetNode.index += numberOfExpandedChildren + 1;

    // Update the parentNode's children
    if (nodeToMove.parentNode) {
      const parentNodeChildren = nodeToMove.parentNode.children;
      parentNodeChildren.splice(parentNodeChildren.indexOf(nodeToMove), 1);
      if (direction === 'after') {
        const targetNodeIndex =
          targetNode.parentNode?.findChildIndex(targetNode) || 0;
        targetNode.parentNode?.children.splice(
          targetNodeIndex + 1,
          0,
          nodeToMove,
        );
      } else {
        const targetNodeIndex =
          targetNode.parentNode?.findChildIndex(targetNode) || 0;
        targetNode.parentNode?.children.splice(targetNodeIndex, 0, nodeToMove);
      }
    }

    this.recalculateRealIndices(startIndex, endIndex, startWith);
    this.refreshRemoveId(nodeToMove, targetNode);
    this.afterDnD(`moveNode`, nodeToMove, targetNode);
  }

  private moveAfter(
    nodeToMove: MenuDishNode,
    targetNode: MenuDishNode,
    typeOfDnD: TypeOfDnD,
  ): void {
    const nodeManipulationCallback =
      this.getMoveNodeManipulationCallback(typeOfDnD);
    this.moveNode(nodeToMove, targetNode, 'after', nodeManipulationCallback);
  }

  private moveBefore(
    nodeToMove: MenuDishNode,
    targetNode: MenuDishNode,
    typeOfDnD: TypeOfDnD,
  ): void {
    const nodeManipulationCallback =
      this.getMoveNodeManipulationCallback(typeOfDnD);
    this.moveNode(nodeToMove, targetNode, 'before', nodeManipulationCallback);
  }

  private getMoveNodeManipulationCallback(typeOfDnD: TypeOfDnD) {
    return (nodeToMove: MenuDishNode, targetNode: MenuDishNode) => {
      const updateNodeToMove = () => {
        const levelDiff: -3 | -2 | -1 | 0 | 1 | 2 | 3 = (targetNode.level -
          nodeToMove.level) as -3 | -2 | -1 | 0 | 1 | 2 | 3;
        if (![-3, -2, -1, 0, 1, 2, 3].includes(levelDiff)) {
          throw new Error(
            `TREE ERROR: Wrong level difference when moving inside: ${levelDiff}`,
          );
        }
        nodeToMove.changeLevelRecursively(levelDiff);
        nodeToMove.parentNode?.removeChild(nodeToMove);
        nodeToMove.parentNode?.shrinkLineRecursively(
          nodeToMove.isExpanded ? nodeToMove.getChildrenLength(true) : 1,
        );
        nodeToMove.parentNode = null;
      };
      const updateParentNode = () => {
        nodeToMove.parentNode = targetNode.parentNode;
        const index = targetNode.parentNode.findChildIndex(targetNode);
        nodeToMove.parentNode.children.splice(index, 0, nodeToMove);
        nodeToMove.parentNode.stretchLineRecursively(1);
      };
      switch (typeOfDnD) {
        case TypeOfDnD.disToDis:
        case TypeOfDnD.disToSec:
        case TypeOfDnD.secToDis:
        case TypeOfDnD.secToDow:
        case TypeOfDnD.secToSec:
          updateNodeToMove();
          if (targetNode.parentNode) updateParentNode();
          break;
        case TypeOfDnD.disToDow:
          updateNodeToMove();
          break;
        default:
          break;
      }
    };
  }

  private refreshRemoveId = (
    nodeToMove: MenuDishNode,
    targetNode: MenuDishNode,
  ): void => {
    if (targetNode.parentNode && targetNode.parentNode.isExpanded) {
      nodeToMove.removeId = targetNode.parentNode.dish.id;
    } else {
      nodeToMove.removeId = null;
    }
  };

  private afterDnD(
    lastOperation: string,
    dragNode: MenuDishNode,
    dropNode: MenuDishNode,
  ): void {
    this.lastNode = this.storedItems.length
      ? this.storedItems[this.storedItems.length - 1].getLastDescendant()
      : null;

    // FIXME: remove the check validity function once we are confident the tree is stable
    this.checkValidity(lastOperation, dragNode, dropNode);
    this.updateVirtualScroll();
  }

  private checkValidity(
    lastOperation: string,
    dragNode: MenuDishNode,
    dropNode: MenuDishNode,
  ): void {
    const indices = [];
    this.storedItems.forEach((item) => {
      if (item.level === 1 && item.parentNode) {
        throw new Error(`TREE ERROR: Item with level 1 has parentNode`);
      }
      if (item.level <= 0) {
        throw new Error(`TREE ERROR: Item has level 0 or -1`);
      }
      (!item.parentNode || item.level === 1) && item.revealRealIndex(indices);
    });
    let prev = -1;
    let prevObj = null;
    try {
      indices.forEach((obj) => {
        const { realIndex, isDay, level } = obj;
        if (realIndex - 1 !== prev) {
          throw new Error(`TREE ERROR: Wrong indices`);
        }
        if (prevObj) {
          if (level - prevObj.level > 1) {
            throw new Error(`TREE ERROR: Difference between levels is too big`);
          }
          if (level > prevObj.level && !(prevObj.isSection || prevObj.isDay)) {
            throw new Error(`TREE ERROR: Dish object can't have children`);
          }
          if (level > 1 && isDay) {
            throw new Error(`TREE ERROR: Day can't be inside another element`);
          }
        } else if (level > 1) {
          throw new Error(`TREE ERROR: First item wrong level`);
        }
        prev = realIndex;
        prevObj = obj;
      });
    } catch (err) {
      if (!isDevMode()) {
        Sentry.withScope((scope) => {
          scope.setExtras({
            nodes: this.storedItems,
            lastOperation,
            dragNode,
            dropNode,
          });
          Sentry.captureException(err);
        });
      }
    }
  }

  private moveBottom(nodeToMove: MenuDishNode): void {
    const childrenLength = nodeToMove.getChildrenLength(true);
    const nodeAndChildren = this.storedItems.slice(
      nodeToMove.index,
      nodeToMove.index + childrenLength + 1,
    );

    if (nodeToMove.parentNode) {
      nodeToMove.parentNode.removeChild(nodeToMove);
      nodeToMove.parentNode.shrinkLineRecursively(childrenLength + 1);
      nodeToMove.parentNode = null;
      nodeToMove.removeId = null;
    }

    const levelDiff: -3 | -2 | -1 | 0 = (1 - nodeToMove.level) as
      | -3
      | -2
      | -1
      | 0;
    if (![-3, -2, -1, 0, 1, 2, 3].includes(levelDiff)) {
      throw new Error(
        `TREE ERROR: Wrong level difference when moving to bottom: ${levelDiff}`,
      );
    }
    nodeToMove.changeLevelRecursively(levelDiff);

    this.storedItems.splice(this.storedItems.length, 0, ...nodeAndChildren);
    this.storedItems.splice(nodeToMove.index, childrenLength + 1);

    const startIndex = nodeToMove.index;
    const endIndex = this.storedItems.length;
    const startWith = nodeToMove.realIndex;

    this.recalculateRealIndices(startIndex, endIndex, startWith);

    this.afterDnD(`moveBottom`, nodeToMove, null);
  }

  private moveInside(nodeToMove: MenuDishNode, targetNode: MenuDishNode): void {
    const expandedNumber = targetNode.getChildrenLength(true);
    const allChildren = targetNode.getChildrenLength();
    const nodeChildrenNumber = nodeToMove.getChildrenLength(true);
    const nodeAndChildren = this.storedItems.slice(
      nodeToMove.index,
      nodeToMove.index + nodeToMove.getChildrenLength(true) + 1,
    );

    if (nodeToMove.parentNode) {
      nodeToMove.parentNode.removeChild(nodeToMove);
      nodeToMove.parentNode.shrinkLineRecursively(1);
    }

    const levelDiff: -3 | -2 | -1 | 0 | 1 | 2 | 3 = (targetNode.level +
      1 -
      nodeToMove.level) as -3 | -2 | -1 | 0 | 1 | 2 | 3;
    if (![-3, -2, -1, 0, 1, 2, 3].includes(levelDiff)) {
      throw new Error(
        `TREE ERROR: Wrong level difference when moving inside: ${levelDiff}`,
      );
    }
    nodeToMove.changeLevelRecursively(levelDiff);
    nodeToMove.parentNode = targetNode;
    targetNode.children = [...(targetNode.children || []), nodeToMove];

    const expandMe = !targetNode.isExpanded;
    const newIndex = targetNode.index + expandedNumber + 1;

    if (targetNode.isExpanded) {
      nodeToMove.removeId = targetNode.dish.id;
      this.storedItems.splice(newIndex, 0, ...nodeAndChildren);
      targetNode.stretchLineRecursively(nodeChildrenNumber + 1);
    }

    let startIndex: number;
    let endIndex: number;
    let startWith: number;

    if (targetNode.index > nodeToMove.index) {
      this.storedItems.splice(nodeToMove.index, nodeChildrenNumber + 1);
      startIndex = nodeToMove.index;
      endIndex = targetNode.index - nodeChildrenNumber;
      startWith = nodeToMove.realIndex;
      targetNode.index -= nodeChildrenNumber + 1;
    } else {
      this.storedItems.splice(
        targetNode.isExpanded && newIndex < nodeToMove.index
          ? nodeToMove.index + nodeChildrenNumber + 1
          : nodeToMove.index,
        nodeChildrenNumber + 1,
      );
      startIndex =
        newIndex > nodeToMove.index
          ? nodeToMove.index
          : targetNode.index + expandedNumber;
      endIndex = nodeToMove.index + nodeChildrenNumber + 1;
      startWith =
        newIndex > nodeToMove.index
          ? nodeToMove.realIndex
          : targetNode.isExpanded
            ? targetNode.realIndex + allChildren
            : targetNode.realIndex;
    }

    if (newIndex !== nodeToMove.index) {
      this.recalculateRealIndices(startIndex, endIndex + 1, startWith);
    }
    this.afterDnD(`moveInside`, nodeToMove, targetNode);

    if (expandMe) {
      if (nodeToMove.isExpandable()) nodeToMove.isExpanded = false;
      this.expand(targetNode.index, true);
    }
  }

  private recalculateRealIndices(
    fromIndex: number,
    toIndex: number,
    startWithRealIndex: number,
  ): void {
    for (let i = fromIndex; i < toIndex; i++) {
      const node = this.storedItems[i];
      if (!node) break;
      if (node.hasChildren() && !node.isExpanded) {
        startWithRealIndex = node.setRealIndices(startWithRealIndex);
      } else if (node.hasChildren() && node.isExpanded) {
        const oldRealIndex = startWithRealIndex;
        startWithRealIndex = node.setRealIndices(startWithRealIndex);
        const diff = startWithRealIndex - oldRealIndex;
        const diffExpanded = diff - node.getChildrenLength(true);
        i += Math.max(diff - diffExpanded, 0);
      } else {
        node.realIndex = startWithRealIndex++;
      }
    }
  }

  private findChildrenAndSetLastNode(
    innerIndex: number,
    lookingForLevel: 2 | 3 | 4,
    fullArray: (SimpleMenuDish | MenuDish)[],
    parentNode: MenuDishNode,
  ): MenuDishNode[] {
    const children: MenuDishNode[] = [];
    for (let i = ++innerIndex; i < fullArray.length; i++) {
      const md = fullArray[i];
      if (md.level === lookingForLevel) {
        const menuDishNode = new MenuDishNode(null, md, i, parentNode);
        this.lastNode = menuDishNode;
        if (menuDishNode.isExpandable()) {
          menuDishNode.children = this.findChildrenAndSetLastNode(
            i,
            (lookingForLevel + 1) as 2 | 3 | 4,
            fullArray,
            menuDishNode,
          );
        }
        children.push(menuDishNode);
      }
      if (md.level < lookingForLevel) break;
    }
    return children;
  }

  private getLastFlatParent(): MenuDishNode | undefined {
    if (!this.lastNode) return undefined;
    return this.lastNode.getTopParent();
  }

  private recursivelyAddChildren(
    node: MenuDishNode,
    expandedItems: MenuDishNode[],
  ): void {
    expandedItems.push(node);
    if (node.hasChildren() && !node.isExpanded) {
      node.isExpanded = true;
      node.stretchLineRecursively(node.children.length);
      node.children.forEach((child: MenuDishNode, index: number) => {
        child.removeId = node.dish.id;
        this.recursivelyAddChildren(child, expandedItems);
      });
    }
  }

  private recursivelyExpand(node: MenuDishNode): void {
    if (!node || !node.parentNode) return;

    // Recursively expand ancestors first, so the index remains valid
    this.recursivelyExpand(node.parentNode);

    // Expand the current parent node if it's not already expanded
    if (!node.parentNode.isExpanded) {
      this.expand(node.parentNode.index, true);
    }
  }

  private getExpandedChildren(node: MenuDishNode, ids = [], collapse = false) {
    ids.push(node.dish.id);
    if (node.isSeparator() && collapse) {
      node.isExpanded = false;
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) =>
        this.getExpandedChildren(child, ids, collapse),
      );
    }
    return ids;
  }
}
