import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { OnboardingTemplate } from '../../../Models/onboarding_template';
import { MtTooltipDirective } from '../../../Directives/mt-tooltip/mt-tooltip.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'write-new-menu-item',
  templateUrl: './write-new-menu-item.component.html',
  styleUrls: ['./write-new-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatMenuModule, MtTooltipDirective],
})
export class WriteNewMenuItemComponent {
  readonly disabled = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly template = input<OnboardingTemplate>(undefined);
  readonly statusMessage = input<string>(undefined);
  readonly showDatepicker = output<OnboardingTemplate>();
}
