import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'regenerate-menu',
  templateUrl: './regenerate-menu.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./regenerate-menu.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, TranslocoPipe],
})
export class RegenerateMenuComponent {
  readonly regenerateContent = input<string>(undefined);
  readonly regenerateTitle = input<string>(undefined);
  readonly cancelAction = output<void>();
  readonly regenerateMenusActions = output<void>();
}
