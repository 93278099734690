<h2 class="title">
  {{ 'dashboard.courses.title' | transloco }}
  <span
    [class.disabled]="hideCourses() !== hideCoursesPanel"
    (click)="
      hideCourses() === hideCoursesPanel && toggleCourseList(hideCourses())
    "
    class="toggle-courses"
    >{{
      hideCoursesPanel
        ? ('dashboard.courses.show-courses' | transloco)
        : ('dashboard.courses.hide-courses' | transloco)
    }}
    @if (hideCourses() !== hideCoursesPanel) {
      <spinner class="inline-spinner" [config]="{ radius: 7 }"></spinner>
    }
  </span>
</h2>

@if (coursesLoading()) {
  <spinner></spinner>
}

@if (!hideCoursesPanel) {
  <div class="row">
    @for (course of courses(); track course) {
      <mat-card class="card">
        <a
          class="card-link"
          [attr.href]="course['url_' + lang()] | safe"
          target="_blank"
        >
          <div class="image-wrapper">
            <img
              width="269"
              height="167"
              [src]="course.image | safe"
              alt="Course preview image"
            />
            @if (course.duration || course.icon) {
              <span class="duration-icon">
                @if (course.icon) {
                  <mat-icon class="icon">{{ course.icon }}</mat-icon>
                }
                @if (course.duration) {
                  <span class="duration">{{ course.duration }}</span>
                }
              </span>
            }
          </div>
          <mat-card-header class="card-header">
            <mat-card-title>{{ course[lang()] }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p></p>
            <mat-divider></mat-divider>
          </mat-card-content>
          <mat-card-actions>
            <button
              mat-button
              class="button"
              color="primary"
              [attr.href]="course['url_' + lang()] | safe"
              target="_blank"
            >
              {{ 'shared.buttons.view' | transloco }}
            </button>
          </mat-card-actions>
        </a>
      </mat-card>
    }
  </div>
}
