import {
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { Dish, Spellcheck } from 'src/app/shared/Models/dish';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { Separator } from 'src/app/shared/Models/separator';
import { User } from 'src/app/shared/Models/user';
import { showSnackbarMessage } from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';

import { addWordToUserDictionary } from '../../write/ngrx/menu-write.actions';
import { SidePanelPlaceholderComponent } from '../../../../shared/Components/sidepanel/side-panel-placeholder/side-panel-placeholder.component';
import { SpinnerComponent } from '../../../../shared/Components/spinner/spinner.component';
import { TranslocoDirective, TranslocoPipe } from '@jsverse/transloco';
import { CopyDataLineComponent } from '../../../../shared/Components/copy-data-line/copy-data-line.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GrammarCorrectionsComponent } from '../../../../shared/Components/grammar-corrections/grammar-corrections.component';
import { ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SidePanelHeaderComponent } from '../../../../shared/Components/sidepanel/side-panel-header/side-panel-header.component';
import { GoBackComponent } from '../../../../shared/Components/sidepanel/go-back/go-back.component';

@Component({
  selector: 'app-translate-sidebar',
  templateUrl: './translate-sidebar.component.html',
  styleUrls: ['./translate-sidebar.component.scss'],
  imports: [
    GoBackComponent,
    SidePanelHeaderComponent,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    GrammarCorrectionsComponent,
    MatButtonModule,
    MatIconModule,
    CopyDataLineComponent,
    TranslocoDirective,
    SpinnerComponent,
    SidePanelPlaceholderComponent,
    TranslocoPipe,
  ],
})
export class TranslateSidebarComponent implements OnChanges {
  private ngrxStore = inject<Store<State>>(Store);
  private utils = inject(UtilsService);

  readonly baseLanguage = input<ContentLanguage>(undefined);
  readonly currentMenuDish = input<MenuDish>(undefined);
  readonly description = input<string>(undefined);
  readonly dishes = input<BaseNameExtended[]>(undefined);
  readonly dishTitle = input<string>(undefined);
  readonly isDescription = input(false);
  readonly isOther = input(false);
  readonly isLoading = input<boolean>(undefined);
  readonly isSecondary = input(false);
  readonly lang = input<ContentLanguage>(undefined);
  readonly numberOfItems = input<number>(undefined);
  readonly spellcheckItem = input<Spellcheck>(undefined);
  readonly tags = input<
    {
      value: string;
      selected: boolean;
    }[]
  >([]);
  readonly user = input<User>(undefined);

  readonly ignoreSpellcheck = output<void>();
  readonly changeItem = output<boolean>();
  readonly fetchMoreItems = output<void>();
  readonly similar = output<BaseNameExtended>();
  readonly openTranslations = output<MenuDish>();
  readonly updateSimilar = output<MenuDish>();
  readonly clearSelectedDish = output<void>();
  readonly fetchSpellcheck = output<number>();

  details: Separator | Dish;
  isSelectedAll = true;
  rest: number;
  rtl = false;
  showGrammar: boolean;
  showMore = false;
  showPlaceholder = false;
  translationDisabledControl = new FormControl(false);

  ngOnChanges(changes: SimpleChanges): void {
    const lang = this.lang();
    if ('lang' in changes && lang) {
      this.rtl = rtlLangs.includes(lang);
    }
    const numberOfItems = this.numberOfItems();
    const dishes = this.dishes();
    if (
      ('numberOfItems' in changes && numberOfItems) ||
      ('dishes' in changes && dishes)
    ) {
      this.rest = numberOfItems - (dishes?.length ?? 0);
    }
    const currentMenuDish = this.currentMenuDish();
    if ('currentMenuDish' in changes && currentMenuDish) {
      this.details =
        currentMenuDish.dish_detail || currentMenuDish.separator_detail;

      this.updateTranslationControl();

      const spellcheckId = this.details?.[`spellcheck_` + lang];
      this.showGrammar = !!spellcheckId;
      if (this.showGrammar) this.fetchSpellcheck.emit(spellcheckId);
    }
  }

  private updateTranslationControl(): void {
    let value = false;
    const isDescription = this.isDescription();
    const isOther = this.isOther();
    const isSecondary = this.isSecondary();
    if (isDescription && isOther && isSecondary) {
      value =
        this.currentMenuDish()?.user_details
          .translation_disabled_description_secondary;
    } else if (isDescription && isSecondary) {
      value =
        this.details?.user_details[
          'translation_disabled_description_secondary'
        ];
    } else if (isDescription && isOther) {
      value =
        this.currentMenuDish()?.user_details.translation_disabled_description;
    } else if (this.details && isDescription) {
      value = this.details?.user_details.translation_disabled_description;
    } else if (this.details) {
      value = this.details?.user_details.translation_disabled;
    }
    this.translationDisabledControl.setValue(value, { emitEvent: false });

    // Subscribe to value changes
    this.translationDisabledControl.valueChanges.subscribe((checked) => {
      this.changeItem.emit(checked);
    });
  }

  addWord(word: string): void {
    const currentMenuDish = this.currentMenuDish();
    if (!currentMenuDish) return;
    const onFulfilled = (md: MenuDish) => {
      this.utils.getTranslation(
        'menus.shared.grammar-added',
        (message: string) => {
          this.ngrxStore.dispatch(showSnackbarMessage({ message }));
        },
      );
    };
    const text = (currentMenuDish.dish_detail ||
      currentMenuDish.separator_detail)[this.lang()];
    this.ngrxStore.dispatch(
      addWordToUserDictionary({
        word,
        text,
        lang: this.lang(),
        menuDish: currentMenuDish,
        onFulfilled,
      }),
    );
  }

  getTitle = (): string =>
    this.tags()
      .filter((t) => t.selected)
      .map((t) => t.value)
      .join(' ');

  chooseSimilar(item: BaseNameExtended): void {
    this.similar.emit(item);
  }

  getTags = (): { value: string; selected: boolean }[] =>
    this.showMore ? this.tags() : this.tags().slice(0, 15);

  changeTag(tag: { value: string; selected: boolean }): void {
    const tags = this.tags();
    const deselected = tags.filter((v) => !v.selected);
    if (deselected.length === 0) {
      const tags = this.tags();
      tags.forEach((t) => (t.selected = false));
    }
    tag.selected = !tag.selected;

    if (!tag.selected || deselected.length === 0) {
      this.isSelectedAll = false;
    }
    if (deselected.length === this.tags().length - 1 && !tag.selected) {
      this.showPlaceholder = true;
    } else {
      this.showPlaceholder = false;
      this.updateSimilar.emit(this.currentMenuDish());
    }
  }

  selectAll(): void {
    const tags = this.tags();
    if (this.isSelectedAll) {
      tags.forEach((t) => (t.selected = false));
      this.showPlaceholder = true;
      this.isSelectedAll = false;
    } else {
      this.isSelectedAll = true;
      this.showPlaceholder = false;
      tags.forEach((t) => (t.selected = true));
    }
    this.updateSimilar.emit(this.currentMenuDish());
  }
}
