<form [formGroup]="authenticationForm" class="login-form simple-theme">
  <div class="field">
    <mat-icon class="icon">email</mat-icon>
    <mat-form-field class="label">
      <mat-label>{{ 'auth.login.email.placeholder' | transloco }}</mat-label>
      <input
        formControlName="email"
        type="email"
        matInput
        minlength="4"
        autocomplete="email"
        required
      />
    </mat-form-field>
  </div>
  <div class="field">
    <mat-icon class="icon">lock outline</mat-icon>
    <mat-form-field class="label">
      <mat-label>{{ 'auth.login.password.placeholder' | transloco }}</mat-label>
      <input
        formControlName="password"
        type="password"
        matInput
        minlength="4"
        autocomplete="current-password"
        required
      />
    </mat-form-field>
  </div>
  @if (showError()) {
    <div class="error">{{ error }}</div>
  }
  <button
    mat-flat-button
    type="submit"
    color="primary"
    class="button-spinner"
    [disabled]="spinnerState()"
    (click)="submitUserData()"
  >
    <span class="button-text">
      {{ 'auth.login.actions.submit' | transloco }}
      @if (spinnerState()) {
        <spinner class="inner-spinner" [config]="{ radius: 7 }"></spinner>
      }
    </span>
  </button>
  <div class="links">
    <a
      class="reset-link"
      routerLink="/reset"
      [queryParams]="{ lang: lang()?.current }"
    >
      {{ 'auth.shared.remind' | transloco }}
    </a>
    <a
      class="register-link"
      routerLink="/registration"
      [queryParams]="{ lang: lang()?.current }"
    >
      {{ 'auth.shared.register' | transloco }}
    </a>
  </div>
</form>
