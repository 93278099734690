<quill-editor
  class="editor"
  [attr.dir]="rtl ? 'rtl' : 'ltr'"
  [class.ql]="highlight"
  [placeholder]="placeholder()"
  [modules]="editorConfigDefault"
  (onEditorCreated)="onEditorCreated($event)"
  (onContentChanged)="onContentChanged($event)"
>
  <div quill-editor-toolbar [class.disabled]="disabled()" id="toolbar">
    <span class="ql-formats">
      <button
        id="quill-undo"
        class="undo tooltip"
        [title]="'write.quill.tooltip.undo' | transloco"
      >
        <svg viewbox="0 0 18 18">
          <polygon
            class="ql-fill ql-stroke"
            points="6 10 4 12 2 10 6 10"
          ></polygon>
          <path
            class="ql-stroke"
            d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
          ></path>
        </svg>
      </button>
      <button
        id="quill-redo"
        class="redo tooltip"
        [title]="'write.quill.tooltip.redo' | transloco"
      >
        <svg viewbox="0 0 18 18">
          <polygon
            class="ql-fill ql-stroke"
            points="12 10 14 12 16 10 12 10"
          ></polygon>
          <path
            class="ql-stroke"
            d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
          ></path>
        </svg>
      </button>
    </span>
    <span class="ql-formats">
      <button
        class="ql-bold tooltip"
        [title]="'write.quill.tooltip.bold' | transloco"
      ></button>
      <button
        class="ql-italic tooltip"
        [title]="'write.quill.tooltip.italic' | transloco"
      ></button>
      <button
        class="ql-underline tooltip"
        [title]="'write.quill.tooltip.underline' | transloco"
      ></button>
      <button
        class="ql-strike tooltip"
        [title]="'write.quill.tooltip.strike' | transloco"
      ></button>
    </span>
    <span class="ql-formats">
      <select
        class="ql-color tooltip"
        [title]="'write.quill.tooltip.text-color' | transloco"
      ></select>
      <select
        class="ql-background tooltip"
        [title]="'write.quill.tooltip.background-color' | transloco"
      ></select>
      <button
        class="ql-script tooltip"
        value="sub"
        [title]="'write.quill.tooltip.subscript' | transloco"
      ></button>
      <button
        class="ql-script tooltip"
        value="super"
        [title]="'write.quill.tooltip.superscript' | transloco"
      ></button>
    </span>
    <span class="ql-formats">
      <select
        title="Align tooltip"
        class="ql-align"
        [title]="'write.quill.tooltip.align-text' | transloco"
      ></select>
      <button
        class="ql-list tooltip"
        value="ordered"
        [title]="'write.quill.tooltip.ordered-list' | transloco"
      ></button>
      <button
        class="ql-list tooltip"
        value="bullet"
        [title]="'write.quill.tooltip.bullet-list' | transloco"
      ></button>
      <button
        class="ql-indent tooltip"
        value="-1"
        [title]="'write.quill.tooltip.add-indentation' | transloco"
      ></button>
      <button
        class="ql-indent tooltip"
        value="+1"
        [title]="'write.quill.tooltip.remove-indentation' | transloco"
      ></button>
    </span>
    <span class="ql-formats">
      <select
        class="ql-header tooltip"
        [title]="'write.quill.tooltip.header' | transloco"
      >
        <option value="1"></option>
        <option value="2"></option>
        <option value="3"></option>
        <option value="4"></option>
        <option value="0" selected></option>
      </select>
    </span>
    <span class="ql-formats">
      <button
        class="ql-blockquote tooltip"
        [title]="'write.quill.tooltip.blockquote' | transloco"
      ></button>
      <button
        class="ql-image tooltip"
        [title]="'write.quill.tooltip.image' | transloco"
      ></button>
      <button
        class="ql-divider tooltip"
        [title]="'write.quill.tooltip.break-page' | transloco"
      >
        <svg viewBox="0 0 18 18">
          <line x1="3.5" y1="9" x2="2.5" y2="9" class="ql-stroke"></line>
          <line x1="9.5" y1="9" x2="8.5" y2="9" class="ql-stroke"></line>
          <line x1="15.5" y1="9" x2="14.5" y2="9" class="ql-stroke"></line>
          <line x1="15" y1="14.5" x2="3" y2="14.5" class="ql-stroke fat"></line>
          <line x1="15" y1="3.5" x2="3" y2="3.5" class="ql-stroke fat"></line>
        </svg>
      </button>

      <select
        class="ql-allergen tooltip"
        [title]="'app.pages.allergens.title' | transloco"
      >
        @for (allergen of allergens | keyvalue; track allergen) {
          <option
            class="allergen-symbol"
            [title]="'allergens.elements.' + allergen.key | transloco"
            [value]="allergen.value"
          >
            {{ allergen.value }}
          </option>
        }
      </select>
      <select
        class="ql-allergen labels tooltip"
        [title]="'write.dishes.labels.title' | transloco"
      >
        @for (label of labels | keyvalue; track label) {
          <option
            class="allergen-symbol"
            [title]="'labels.elements.' + label.key | transloco"
            [value]="label.value"
          >
            {{ label.value }}
          </option>
        }
      </select>
    </span>
  </div>
</quill-editor>

<div class="editor-footer">
  <span class="saved-status mat-caption">
    @if (unsavedContent) {
      {{ 'write.quill.content-unsaved' | transloco }}
    } @else {
      {{ 'write.quill.content-saved' | transloco }}
    }
  </span>
  <button
    mat-button
    [disabled]="!unsavedContent"
    class="save-btn"
    color="primary"
    (click)="onSave()"
  >
    {{ 'shared.buttons.save' | transloco }}
  </button>
</div>

@if (isLoading) {
  <div class="shadow">
    <spinner class="spinner"></spinner>
  </div>
}
