import {
  Component,
  ElementRef,
  Input,
  inject,
  output,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'option-popover',
  templateUrl: './option-popover.component.html',
  styleUrls: ['./option-popover.component.css'],
  imports: [MatButtonModule, MatIconModule],
})
export class OptionPopoverComponent {
  private elementRef = inject(ElementRef);

  readonly element = input(undefined);
  readonly data = input(undefined);
  @Input()
  get visible() {
    return this.showPopover;
  }
  set visible(val) {
    this.showPopover = val;
    this.visibleChange.emit(this.showPopover);
  }

  readonly visibleChange = output<boolean>();
  showPopover = false;

  close(event: MouseEvent): void {
    event.stopPropagation();
    this.visible = false;
  }
}
