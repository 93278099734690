import {
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  input,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  ContentProgress,
  DaysProgress,
  Status,
  SubscriptionExtension,
} from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  calculateDaysInfo,
  calculateUsageInfo,
} from 'src/app/shared/user/status.utils';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'user-status-progress',
  templateUrl: './user-status-progress.component.html',
  styleUrls: ['./user-status-progress.component.scss'],
  imports: [
    MatProgressBarModule,
    MatButtonModule,
    MtTooltipDirective,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class UserStatusProgressComponent implements OnChanges {
  private utils = inject(UtilsService);

  readonly extra = input<SubscriptionExtension[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly status = input<Status>(undefined);

  aiAllergensInfo: ContentProgress;
  aiDishesInfo: ContentProgress;
  aiMenuAnalysisInfo: ContentProgress;
  aiRecipesInfo: ContentProgress;
  daysInfo: DaysProgress;
  menuInfo: ContentProgress;
  templateInfo: ContentProgress;

  ngOnChanges(changes: SimpleChanges) {
    const status = this.status();
    if (status && (changes.status || changes.extra)) {
      this.daysInfo = calculateDaysInfo(status);

      this.aiAllergensInfo = calculateUsageInfo(
        status,
        this.extra(),
        'aiAllergens',
      );
      this.aiDishesInfo = calculateUsageInfo(status, this.extra(), 'aiDishes');
      this.aiMenuAnalysisInfo = calculateUsageInfo(
        status,
        this.extra(),
        'aiMenuAnalysis',
      );
      this.aiRecipesInfo = calculateUsageInfo(
        status,
        this.extra(),
        'aiRecipes',
      );
      this.menuInfo = calculateUsageInfo(status, this.extra(), 'menus');
      this.templateInfo = calculateUsageInfo(status, this.extra(), 'templates');
    }
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  isFinite = (num: number) => Number.isFinite(num);
}
