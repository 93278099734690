import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe',
  standalone: true,
})
export class SafePipe implements PipeTransform {
  private sanitizer = inject(DomSanitizer);

  transform(data: string, isHtml?: boolean) {
    return isHtml
      ? this.sanitizer.bypassSecurityTrustHtml(data || '')
      : this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }
}
