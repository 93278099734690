@if (item()?.ai_generated) {
  <span class="ai-generated"></span>
}
<mat-form-field
  class="item-input"
  [class.opacity]="opacity()"
  [color]="
    control?.errors && (control.dirty || control.touched) ? 'warn' : 'primary'
  "
  floatLabel="always"
  subscriptSizing="dynamic"
>
  @if (showGrammar) {
    <grammar-check-button
      [value]="control?.value"
      (clicked)="setCurrent.emit($event)"
    ></grammar-check-button>
  }
  <mat-label>
    <span class="dish-name-placeholder"
      >{{
        translations()['dishes.' + item()?.category + '.placeholder'] ||
          ('dishes.' + [item()?.category] + '.placeholder' | transloco)
      }}
      @if (placeholderName) {
        : {{ placeholderName }}
      }
    </span>
    @if (item() && canShowDays && placeholderDays) {
      <span class="dish-name-days">{{ placeholderDays }}</span>
    }
    @if (isExtendedLang()) {
      ({{ lang() | uppercase }})
    }
  </mat-label>
  <input
    matInput
    #autoTrigger="matAutocompleteTrigger"
    #inputRef
    class="input"
    name="dish"
    type="text"
    [formControl]="control"
    [matAutocomplete]="auto"
    (focusin)="focusIn()"
    (focusout)="focusOut()"
    (keydown.enter)="inputRef.blur()"
  />
  <button
    matSuffix
    mat-icon-button
    class="confirm-button"
    (click)="inputRef.blur()"
  >
    <mat-icon>check</mat-icon>
  </button>
</mat-form-field>

<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="selectOption($event)"
>
  @for (option of same(); track option) {
    <mat-option
      title="{{ option[lang()] }}{{
        option.category === 'win' && option.vintage !== null
          ? ' - ' + option.vintage
          : ''
      }}{{
        option.category === 'win' && option.winery !== ''
          ? ', ' + option.winery
          : ''
      }}"
      [value]="option"
      (mousedown)="wtf($event)"
    >
      <span class="auto-wrapper">
        <span class="text"
          >{{ option[lang()]
          }}<span
            class="extra-text"
            [innerHtml]="
              option.category === 'win' && option.vintage !== null
                ? ' &middot; ' + option.vintage
                : '' + option.category === 'win' && option.winery !== ''
                  ? ' &middot; ' + option.winery
                  : ''
            "
          ></span>
        </span>
        @if (canShowDays) {
          <span class="days">{{ getDays(option) }}</span>
        }
      </span>
    </mat-option>
  }
</mat-autocomplete>

@if (control?.errors && (control.dirty || control.touched)) {
  <mat-error class="hints">
    @for (e of ['required', 'minlength', 'maxlength']; track e) {
      @if (control?.errors[e]) {
        <span
          >{{ 'dishes.' + item()?.category + '.validation.' + e | transloco }}.
        </span>
      }
    }
  </mat-error>
}
