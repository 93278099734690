import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  inject,
  input,
} from '@angular/core';
import { Router } from '@angular/router';
import { setStatusBarEmail } from 'src/app/shared/user/ngrx/user.actions';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { State } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoPipe],
})
export class StatusBarComponent implements OnChanges {
  private utils = inject(UtilsService);
  private router = inject(Router);
  private store = inject<Store<State>>(Store);

  readonly status = input<Partial<AccountStatusMessage>>(undefined);
  readonly user = input<User>(undefined);
  readonly isEmail = input<boolean>(undefined);
  readonly isMobile = input<boolean>(undefined);

  ngOnChanges(): void {
    const status = this.status();
    if (status) {
      this.store.dispatch(setStatusBarEmail({ data: !!status.email }));
    }
  }

  statusAction(link) {
    if (link === void 0) return undefined;
    if (link.route !== void 0)
      return this.router.navigate(link.route, { queryParams: link.query });
    this.utils.getNativeWindow().open(link, '_self');
    return true;
  }
}
