<h3>
  {{ 'menus.complete.contactless.title' | transloco }}
  <mat-icon
    #tooltip="matTooltip"
    class="info-icon"
    matTooltipClass="top-navbar-tooltip"
    [matTooltip]="'menus.complete.contactless.explanation' | transloco"
    (click)="tooltip.toggle()"
  >
    info</mat-icon
  >
</h3>
<div class="wrapper">
  <mat-card>
    <share-link
      [actionLabel]="'menus.complete.contactless.share_pdf' | transloco"
      [customUrl]="customUrl()"
      [menu]="menu()"
      [params]="{}"
      (copy)="copied()"
      (generateQRCode)="generateQRCode($event)"
    ></share-link>
  </mat-card>
  <mat-card>
    <share-link
      [actionLabel]="'menus.complete.contactless.share_mobile' | transloco"
      [customUrl]="customUrl()"
      [menu]="menu()"
      [params]="{ response: 'html' }"
      (copy)="copied()"
      (generateQRCode)="generateQRCode($event)"
    ></share-link>
  </mat-card>
</div>
