import { Component, input } from '@angular/core';
import { User } from 'src/app/shared/Models/user';

@Component({
  selector: 'static-header-msm',
  templateUrl: './static-header-msm.component.html',
  styleUrls: ['./static-header-msm.component.css'],
  standalone: true,
})
export class StaticHeaderMsmComponent {
  readonly user = input<User>(undefined);
}
