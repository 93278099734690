import { Component, OnInit, inject, output, input } from '@angular/core';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { API_CONFIG } from 'src/app/app.config';
import { State } from 'src/app/reducers';
import {
  ContentLanguage,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';
import {
  TaskType,
  DownloadReportDetails,
  Menu,
  MenuPreviewData,
} from 'src/app/shared/Models/menu';
import { Translation } from 'src/app/shared/Models/translation';
import { User } from 'src/app/shared/Models/user';
import {
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { FileService } from 'src/app/shared/Services/files/files.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import printJS from 'print-js';
import { AsyncPipe } from '@angular/common';
import { DownloadStatsComponent } from '../../../../shared/Components/download-stats/download-stats.component';
import { LinkedTemplateComponent } from './linked-template/linked-template.component';
import { SpinnerComponent } from '../../../../shared/Components/spinner/spinner.component';
import { MatCardModule } from '@angular/material/card';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';

@Component({
  selector: 'linked-templates',
  templateUrl: './linked-templates.component.html',
  styleUrls: ['./linked-templates.component.css'],
  imports: [
    MatCardModule,
    SpinnerComponent,
    LinkedTemplateComponent,
    DownloadStatsComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class LinkedTemplatesComponent implements OnInit {
  private fileService = inject(FileService);
  private ngrxStore = inject<Store<State>>(Store);
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);

  readonly lang = input<ContentLanguage>(undefined);
  readonly interfaceLang = input<InterfaceLanguage>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly translations = input<Translation[]>(undefined);
  readonly user = input<User>(undefined);

  readonly createArchive = output<{
    menu: Menu;
    task_type: TaskType;
  }>();
  readonly downloadReport = output<{
    data: DownloadReportDetails;
  }>();
  readonly preview = output<MenuPreviewData>();
  readonly downloadStats = output<{
    date_from: string;
    date_to: string;
  }>();

  allergens$ = this.ngrxStore.select(sharedFeature.selectAllergens);

  allergenLinkedTemplate = {
    url: 'https://api.menutech.com/templates/33/',
    id: 33,
    en: 'Allergen table',
    de: 'Allergen Tabelle',
    it: 'Tabella degli allergeni',
    fr: `Tableau d\'allergènes`,
    es: 'Tabla de alérgenos',
    code: 'auxiliary_templates/allergen_matrix',
    image:
      'https://api.menutech.com/media/templates/Allergens_HMehjUU_vXZyMXW_ijGx668_ibMzzhF_ZQymyCC_vFOSfUe_Y5324qY_r2sEm9i_mZQS_EoRlX2z.jpg',
    settings: {
      multilingual_required: false,
      number_languages: 1,
    },
  };
  additiveLinkedTemplate = {
    url: 'https://api.menutech.com/templates/206/',
    id: 206,
    de: 'Tabelle der Zusatzstoffe',
    en: 'Additives table',
    it: `Matrice d\'additivi`,
    fr: `Tableau d\'additives alimentaires`,
    es: 'Tabla de aditivos',
    code: 'auxiliary_templates/additive_matrix',
    image:
      'https://api.menutech.com/media/templates/additives_matrix_4FZ81Pp_Fat1W5h_fITtaSQ_21tlVmf_fs0Ew7Z_HTyz5yT.jpg',
    settings: {
      multilingual_required: false,
      number_languages: 1,
    },
  };
  hardcodedLinkedTemplates = [];

  copyMessage = '';

  constructor() {
    this.translate
      .selectTranslate(`menus.complete.copy`)
      .subscribe((val) => (this.copyMessage = val));
  }

  ngOnInit(): void {
    if (this.hasModule('all')) {
      this.hardcodedLinkedTemplates.push(this.allergenLinkedTemplate);
    }
    if (this.hasModule('add')) {
      this.hardcodedLinkedTemplates.push(this.additiveLinkedTemplate);
    }
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  hasModuleSetting = (code: string, setting: string, value: any): boolean =>
    this.utils.hasModuleSetting(code, setting, value);

  showPreview(data: MenuPreviewData, endpoint: string, custom = false): void {
    if (endpoint === '/dishes/reports/') {
      const reportDetail = {
        lang: this.lang(),
        menu: this.menu().id,
        report: 'cost_analysis',
      };
      this.downloadReport.emit({ data: reportDetail });
    } else {
      this.preview.emit({
        ...data,
        url: custom ? API_CONFIG.value + endpoint : this.menu()[endpoint],
      });
    }
  }

  printMenu(
    { params, onFulfilled }: { params: any; onFulfilled: () => void },
    endpoint: string,
    custom = false,
  ): void {
    const observableHandler = {
      next: (url) => {
        this.printDownloadedPdf(url);
        onFulfilled();
      },
      error: (error) => {
        this.ngrxStore.dispatch(handleHttpError({ error }));
        onFulfilled();
      },
    };
    if (endpoint === '/dishes/reports/') {
      const data = {
        lang: this.lang(),
        menu: this.menu().id,
        report: 'cost_analysis',
      };
      this.fileService
        .fetchRenderedTemplateFromPost(
          custom ? API_CONFIG.value + endpoint : this.menu()[endpoint],
          data,
        )
        .subscribe(observableHandler);
    } else {
      this.fileService
        .fetchRenderedTemplate(
          custom ? API_CONFIG.value + endpoint : this.menu()[endpoint],
          params,
        )
        .subscribe(observableHandler);
    }
  }

  printDownloadedPdf(url: string): void {
    const modalMessage = this.translate.translate('app.pdf-loading-overlay');
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage,
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  }

  copied(): void {
    this.ngrxStore.dispatch(showSnackbarMessage({ message: this.copyMessage }));
  }
}
