import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  output,
  input,
} from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Menu, MenuBackup, MenuPreviewData } from 'src/app/shared/Models/menu';
import { User } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BackupMenuComponent } from './backup-menu/backup-menu.component';
import { ContentBlockComponent } from '../../../../shared/Components/content-block/content-block.component';

@Component({
  selector: 'backup-menus',
  templateUrl: './backup-menus.component.html',
  styleUrls: ['./backup-menus.component.scss'],
  imports: [
    ContentBlockComponent,
    BackupMenuComponent,
    MatTooltipModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class BackupMenusComponent implements OnInit, OnChanges {
  readonly limit = input<number>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly user = input<User>(undefined);

  readonly create = output<void>();
  readonly delete = output<MenuBackup>();
  readonly preview = output<MenuPreviewData>();
  readonly restore = output<MenuBackup>();

  disabled: boolean;
  loading: boolean;

  ngOnInit(): void {
    this.setDisabled();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu) this.loading = false;
    this.setDisabled();
  }

  setDisabled(): void {
    const limit = this.limit();
    const menu = this.menu();
    this.disabled = limit && menu?.backups && limit === menu?.backups.length;
  }

  saveNewVersion(): void {
    this.loading = true;
    this.create.emit();
  }
}
