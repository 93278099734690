@if (status() && !complete()) {
  <div class="not-complete">
    <mat-card
      class="card mat-elevation-z3"
      [class.ribbon]="status()?.trial_period"
    >
      <mat-card-title class="card-title mat-h3">
        @if (!status().email_verified) {
          {{ 'dashboard.status_block.trial_activate' | transloco }}
        } @else {
          @if (!status().completed_profile) {
            {{ 'dashboard.status_block.trial_extra' | transloco }}
          } @else {
            {{ 'dashboard.status_block.trial_complete' | transloco }}
          }
        }
      </mat-card-title>
      @if (status()?.trial_period) {
        <span class="days-label mat-elevation-z2">
          <mat-icon class="icon">card_giftcard</mat-icon>
          @if (status()?.completed_profile) {
            {{ 'dashboard.status_block.5days_added' | transloco }}
          } @else {
            {{ 'dashboard.status_block.5days' | transloco }}
          }
        </span>
      }
      <span class="corner"></span>
      <div class="status first">
        @if (status().email_verified) {
          <p>
            @if (status()?.completed_profile && status()?.email_verified) {
              {{ 'dashboard.status_block.completed' | transloco }}
            } @else {
              {{ 'dashboard.status_block.complete' | transloco }}
            }
          </p>
        }
        <div class="state">
          <div class="icon">
            <mat-icon class="active">done</mat-icon>
          </div>
          <span class="active text">
            {{ 'dashboard.status_block.states.registered' | transloco }}
          </span>
        </div>
        <div class="state">
          <div class="icon">
            <mat-icon [class.active]="status().email_verified">done</mat-icon>
          </div>
          <span class="text" [ngClass]="{ active: status().email_verified }">
            @if (status().email_verified) {
              {{ 'dashboard.status_block.states.email_verified' | transloco }}
            } @else {
              <span
                [innerHTML]="
                  'dashboard.status_block.states.verify.confirm'
                    | transloco: { email: user().email }
                "
              ></span>
              <a class="resend-link" (click)="resendEmailConfirmation()"
                >&nbsp;{{
                  'dashboard.status_block.states.verify.here' | transloco
                }}&nbsp;</a
              >{{ 'dashboard.status_block.states.verify.resend' | transloco }}
            }
          </span>
        </div>
      </div>
      <div class="status">
        <div class="state">
          <button
            mat-button
            [ngClass]="{ 'mat-mdc-elevation-z4': !status()?.completed_profile }"
            class="trynow-button"
            [routerLink]="['/settings', 'profile']"
          >
            <div class="content-wrapper">
              <div class="icon">
                <mat-icon [class.active]="status()?.completed_profile"
                  >done</mat-icon
                >
              </div>
              <div
                class="label"
                [ngClass]="{ active: status().completed_profile }"
              >
                @if (
                  status()?.received_trialextension &&
                  !status()?.trial_reactivated_on
                ) {
                  {{
                    'dashboard.status_block.states.completed_profile'
                      | transloco
                  }}
                } @else {
                  <span class="business-step-label">{{
                    'dashboard.status_block.states.business_details' | transloco
                  }}</span>
                  <mat-icon class="arrow">arrow_right</mat-icon>
                }
              </div>
            </div>
          </button>
        </div>
        <div class="state">
          @if (status().email_verified && status().privileges.create_menu) {
            <button
              mat-button
              [ngClass]="{ 'mat-mdc-elevation-z4': !menusCreated() }"
              class="trynow-button"
              (click)="openMenu()"
            >
              <div class="content-wrapper">
                <div class="icon">
                  <mat-icon [class.active]="menusCreated()">done</mat-icon>
                </div>
                <div class="label" [ngClass]="{ active: menusCreated() }">
                  {{ 'dashboard.status_block.states.first_menu' | transloco }}
                </div>
                @if (!menusCreated()) {
                  <mat-icon class="arrow">arrow_right</mat-icon>
                }
              </div>
            </button>
          } @else {
            <div class="icon">
              <mat-icon [class.active]="menusCreated()">done</mat-icon>
            </div>
            <span class="text" [ngClass]="{ active: menusCreated() }">
              {{ 'dashboard.status_block.states.first_menu' | transloco }}
            </span>
          }
        </div>
      </div>
      <div class="onboarding-templates">
        @for (template of userOnboardingTemplates(); track template) {
          <user-onboarding-template
            #item
            class="user-onboarding-template"
            [disabled]="
              !(privileges$ | async)?.create_menu ||
              !(userStatus$ | async)?.ready ||
              ((limitTemplates$ | async) &&
                !(templatesUsed$ | async).includes(template.id))
            "
            [lang]="lang"
            [mtTooltip]="
              (userStatus$ | async)?.message || (menuStatus$ | async)?.message
            "
            [template]="template"
            (click)="!item.disabled() && openDatepicker(template)"
          ></user-onboarding-template>
        }
        <input
          class="hide"
          [matDatepicker]="datepicker"
          (dateChange)="dateSelected($event)"
        />
        <mat-datepicker touchUi #datepicker></mat-datepicker>
      </div>
      @if (steps()) {
        <div class="step">
          @if (
            (privileges$ | async)?.create_menu && status().completed_profile
          ) {
            <span
              [mtTooltip]="
                (userStatus$ | async)?.message || (menuStatus$ | async)?.message
              "
            >
              <button
                mat-flat-button
                color="primary"
                class="next-step"
                [disabled]="
                  !(privileges$ | async)?.create_menu ||
                  !(userStatus$ | async)?.ready
                "
                [matMenuTriggerFor]="
                  userOnboardingTemplates()?.length > 0
                    ? onboardingTemplatesMenu
                    : null
                "
                (click)="
                  userOnboardingTemplates()?.length === 0 ? openMenu() : null
                "
              >
                <span>
                  {{
                    steps().message
                      ? steps().message
                      : !status().completed_profile
                        ? ('user.profile.short' | transloco)
                        : !menusCreated()
                          ? ('dashboard.status_block.states.first_menu'
                            | transloco)
                          : (privileges$ | async)?.create_menu
                            ? ('dashboard.status_block.states.prepare_menus'
                              | transloco)
                            : ('user.subscription.short' | transloco)
                  }}
                </span>
                <mat-icon class="arrow">arrow_right</mat-icon>
              </button>
            </span>
          } @else {
            <button
              mat-flat-button
              color="primary"
              class="next-step"
              (click)="next()"
            >
              <span>
                {{
                  steps().message
                    ? steps().message
                    : !status().completed_profile
                      ? ('user.profile.short' | transloco)
                      : !menusCreated()
                        ? ('dashboard.status_block.states.first_menu'
                          | transloco)
                        : (privileges$ | async)?.create_menu
                          ? ('dashboard.status_block.states.prepare_menus'
                            | transloco)
                          : status().usage_limits.limit_menus_per_month &&
                              status().current_usage.menus_created_this_month &&
                              status().usage_limits.limit_menus_per_month -
                                status().current_usage
                                  .menus_created_this_month ===
                                0
                            ? ('dashboard.status_block.trial_limit' | transloco)
                            : ('user.subscription.short' | transloco)
                }}
              </span>
              <mat-icon class="arrow">arrow_right</mat-icon>
            </button>
          }
          <mat-menu #onboardingTemplatesMenu>
            @for (template of userOnboardingTemplates(); track template) {
              <button mat-menu-item (click)="openDatepicker(template)">
                {{ (template?.onboarding_template_detail)[lang] }}
              </button>
            }
          </mat-menu>
        </div>
      }
    </mat-card>
  </div>
}
