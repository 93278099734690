import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  inject,
  output,
  viewChild,
  contentChildren,
  input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TreeManagerService } from 'src/app/menus/tree-manager.service';
import { MenuDishNode } from 'src/app/shared/Models/menu-dish-node';
import { isArray, isNil, parseInt } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const DEFAULT_VISIBLE_CHILDREN = 6;
const ADDITIONAL_CHILDREN = 15; // when it's changed also change write.components.scss#.virtual-scroll max-height
const BOTTOM_OFFSET = 50;

interface Dimensions {
  itemCount: number;
  viewWidth: number;
  viewHeight: number;
  childWidth: number;
  childHeight: number;
  itemsPerRow: number;
  itemsPerCol: number;
  itemsPerRowByCalc: number;
}

@Component({
  selector: 'menutech-virtual-scroll',
  template: `
    <div class="total-padding" [style.height.px]="scrollHeight"></div>
    <div class="list-content" #content>
      <ng-content></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        overflow-y: auto;
        position: relative;
        -webkit-overflow-scrolling: touch;
      }
      .list-content {
        position: absolute;
        top: 11px;
        left: 0;
        width: 100%;
        height: calc(100% - 11px);
        padding: 0 11px;
        box-sizing: border-box;
      }
      .total-padding {
        visibility: hidden;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class MenutechVirtualScrollComponent<T = any>
  implements AfterContentInit, OnChanges, OnDestroy, OnInit
{
  private elementRef = inject(ElementRef);
  private dialog = inject(MatDialog);
  private renderer = inject(Renderer2);
  private treeManager = inject(TreeManagerService);

  private _topPadding: number;
  set topPadding(val: number) {
    this._topPadding = val;
    const contentElementRef = this.contentElementRef();
    if (contentElementRef) {
      this.renderer.setStyle(
        contentElementRef.nativeElement,
        `transform`,
        `translateY(${val}px)`,
      );
    }
  }
  get topPadding(): number {
    return this._topPadding;
  }
  scrollHeight: number;
  private destroyed$ = new Subject<void>();
  private dimensions: Dimensions;
  private element: HTMLElement;
  private parentElement: HTMLElement;
  private previousStart: number;
  private previousEnd: number;
  private previousLength: number;
  private startupLoop = true;
  private storedItems: MenuDishNode[];
  private parentBottomPadding: number;
  private parentTopPadding: number;
  private top: number;
  private ready = false;
  private rootElement: any;
  private isAtBottom = false;

  readonly childHeight = input<number>(undefined);
  readonly nodesUnderCreation = input<Set<MenuDishNode>>(undefined);
  readonly height = input<number>(undefined);

  readonly blurInput = output();
  readonly listReady = output<void>();
  readonly update = output<MenuDishNode[]>();

  readonly contentElementRef = viewChild<ElementRef>('content');

  readonly childrenRef = contentChildren<ElementRef>('virtualListChildElement');

  @HostBinding('style.height')
  heightStyle = '';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateTopOffset();
  }

  @HostListener('scroll')
  onScrollEvent() {
    this.refreshList();
  }

  onWindowScroll = (ev) => {
    if (this.nodesUnderCreation().size) {
      this.blurInput.emit();
      return undefined;
    }
    requestAnimationFrame(() => this.updateScroll());
  };

  constructor() {
    this.previousStart = -1;
    this.previousEnd = -1;
    this.startupLoop = true;
    this.element = this.elementRef.nativeElement;
    this.parentElement = this.element.parentElement;

    this.renderer.listen('window', 'scroll', this.onWindowScroll);
  }

  ngOnInit() {
    this.calculatePaddings();
    this.rootElement = document.getElementsByClassName('main-column')[0];
  }

  ngOnChanges(changes: SimpleChanges) {
    const { visibleChildren } = changes;
    if (
      !isNil(visibleChildren) &&
      (visibleChildren.currentValue !== visibleChildren.previousValue ||
        visibleChildren.firstChange)
    ) {
      this.calculateItems(false, true);
    }
  }

  ngAfterContentInit(): void {
    this.treeManager.treeStructureChanged
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ({
          items: tree,
          updateList,
          afterDnD,
        }: {
          items: MenuDishNode[];
          updateList: boolean;
          afterDnD: boolean;
        }) => {
          this.storedItems = tree;
          this.calculateItems(afterDnD, true);
          if (updateList) this.updateList();
        },
      );
    this.treeManager.previousLength
      .pipe(takeUntil(this.destroyed$))
      .subscribe((length) => (this.previousLength = length));
    this.treeManager.refreshList
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.refreshList();
      });
    this.treeManager.updateList
      .pipe(takeUntil(this.destroyed$))
      .subscribe((afterExpansion: boolean) => {
        this.updateList(false);
      });
  }

  updateList(forceUpdatePaddings = false) {
    this.refreshList(() => this.updateScroll(forceUpdatePaddings));
  }

  private updateScroll(forceUpdatePaddings = false) {
    if (this.isAnyDialogOpened()) return undefined;
    this.calculateTopOffset();
    if (document.documentElement.scrollTop > this.top || forceUpdatePaddings) {
      this.updatePaddings();
    } else {
      this.parentElement.style.paddingTop = this.parentTopPadding + 'px';
      const actualHeight =
        this.scrollHeight -
        window.innerHeight -
        ADDITIONAL_CHILDREN * this.childHeight();
      const bottom = Math.max(
        10,
        actualHeight -
          this.parentBottomPadding -
          (document.documentElement.scrollTop - this.top),
      );
      this.parentElement.style.paddingBottom = bottom + 'px';
      if (this.element.scroll) {
        this.element.scroll(0, 0);
      } else {
        this.element.scrollTop = 0;
      }
    }
  }

  updateAfterRecalculate() {
    requestAnimationFrame(() => this.updateScroll());
  }

  updatePaddings() {
    const actualHeight =
      this.scrollHeight -
      window.innerHeight -
      ADDITIONAL_CHILDREN * this.childHeight();
    const bottom = Math.max(
      0,
      actualHeight -
        this.parentBottomPadding -
        (document.documentElement.scrollTop - this.top),
    );

    this.parentElement.style.paddingBottom = bottom + 'px';
    const paddingTop = actualHeight - this.parentTopPadding - bottom;
    this.isAtBottom = paddingTop === this.element.scrollTop - 21;
    this.parentElement.style.paddingTop = paddingTop + 'px';
    if (this.element.scroll) {
      this.element.scroll(0, paddingTop + BOTTOM_OFFSET);
    } else {
      this.element.scrollTop = paddingTop + BOTTOM_OFFSET;
    }
  }

  private calculatePaddings() {
    this.parentBottomPadding = parseInt(
      window.getComputedStyle(this.parentElement).paddingBottom,
    );
    this.parentTopPadding = parseInt(
      window.getComputedStyle(this.parentElement).paddingTop,
    );
    setTimeout(() => {
      this.calculateTopOffset();
      this.parentElement.style.paddingBottom = `${
        this.scrollHeight - document.documentElement.scrollHeight
      }px`;
    }, 100);
  }

  private calculateTopOffset() {
    this.top =
      this.rootElement.offsetTop +
      50 +
      ADDITIONAL_CHILDREN * this.childHeight() * 0.5;
  }

  scrollDown() {
    requestAnimationFrame(() => {
      if (!this) return undefined;
      const dimensions = this.calculateDimensions();
      this.element.scrollTop =
        Math.floor(this.storedItems.length / dimensions.itemsPerRow) *
        dimensions.childHeight;
      this.refreshList();
    });
  }

  refreshList(updateScroll?: () => void) {
    this.calculateItems();
    requestAnimationFrame(() => updateScroll?.());
  }

  private calculateDimensions(): Dimensions {
    const el = this.element;
    const content = this.contentElementRef().nativeElement;

    const items = this.getItems();
    const itemCount = items.length;
    const viewWidth = el.clientWidth;
    const viewHeight = window.innerHeight;

    let contentDimensions: { width: number; height: number };
    const childHeightValue = this.childHeight();
    if (childHeightValue) {
      const firstChild: Element =
        this.childrenRef().length > 0
          ? this.childrenRef().at(0).nativeElement
          : content.children[0];

      if (firstChild instanceof HTMLElement) {
        contentDimensions = firstChild.getBoundingClientRect();
      } else {
        contentDimensions = {
          width: viewWidth,
          height: viewHeight,
        };
      }
    }

    const childWidth = contentDimensions.width;
    const childHeight = childHeightValue || contentDimensions.height || 1;

    let itemsPerRow = 1;
    const itemsPerRowByCalc = Math.max(1, Math.floor(viewWidth / childWidth));
    const itemsPerCol = Math.max(
      1,
      Math.floor(viewHeight / childHeight) + ADDITIONAL_CHILDREN,
    );
    const scrollTop = Math.max(0, el.scrollTop);
    if (
      itemsPerCol === 1 &&
      Math.floor((scrollTop / this.scrollHeight) * itemCount) +
        itemsPerRowByCalc >=
        itemCount
    ) {
      itemsPerRow = itemsPerRowByCalc;
    }

    return {
      itemCount,
      viewWidth,
      viewHeight,
      childWidth,
      childHeight,
      itemsPerRow,
      itemsPerCol,
      itemsPerRowByCalc,
    };
  }

  private getItems = (): MenuDishNode[] =>
    isArray(this.storedItems) ? this.storedItems : [];

  private isAnyDialogOpened = (): boolean =>
    !!(
      this.dialog &&
      this.dialog.openDialogs &&
      this.dialog.openDialogs.length
    );

  private canUpdate = (start: number, end: number): boolean =>
    start !== this.previousStart || end !== this.previousEnd;

  private recalculateDimensions(): Dimensions {
    let dimensions = this.calculateDimensions();
    if (this.setHeight(dimensions)) dimensions = this.calculateDimensions();
    this.scrollHeight =
      (dimensions.childHeight * dimensions.itemCount) / dimensions.itemsPerRow;
    if (this.element.scrollTop > this.scrollHeight) {
      this.element.scrollTop = this.scrollHeight;
    }
    return dimensions;
  }

  private getScrollValues(): [number, number, number] {
    const scrollTop = Math.max(0, this.element.scrollTop);
    const indexByScrollTop =
      ((scrollTop / this.scrollHeight) * this.dimensions.itemCount) /
      this.dimensions.itemsPerRow;

    const end = Math.min(
      this.dimensions.itemCount,
      Math.ceil(indexByScrollTop) * this.dimensions.itemsPerRow +
        this.dimensions.itemsPerRow * (this.dimensions.itemsPerCol + 1),
    );

    let maxStartEnd = end;
    const modEnd = end % this.dimensions.itemsPerRow;
    if (modEnd) {
      maxStartEnd = end + this.dimensions.itemsPerRow - modEnd;
    }
    const maxStart = Math.max(
      0,
      maxStartEnd - this.dimensions.itemsPerCol * this.dimensions.itemsPerRow,
    );
    const start = Math.min(
      maxStart,
      Math.floor(indexByScrollTop) * this.dimensions.itemsPerRow,
    );

    return [start, maxStart, end];
  }

  private calculateItems(afterDnD = false, recalculateDimensions = false) {
    if (!this) return undefined;

    this.dimensions =
      recalculateDimensions || !this.dimensions
        ? this.recalculateDimensions()
        : this.dimensions;
    const items = this.getItems();
    const [start, maxStart, end] = this.getScrollValues();

    const tempTopPadding =
      this.dimensions.childHeight *
      Math.ceil(start / this.dimensions.itemsPerRow);
    this.topPadding = Math.max(
      start === maxStart ? tempTopPadding - BOTTOM_OFFSET : tempTopPadding,
      0,
    );

    const modStart = Math.max(0, Number.isNaN(start) ? -1 : start);
    const modEnd = Math.min(items.length, Number.isNaN(end) ? -1 : end);
    if (
      afterDnD ||
      (this.canUpdate(modStart, modEnd) &&
        this.previousLength !== this.getItems().length)
    ) {
      const newItems = items.slice(modStart, modEnd).map((v, i) => {
        if (!v) return undefined;
        v['index'] = i + modStart;
        return v;
      });

      this.update.emit(newItems);
      if (!this.ready) {
        this.listReady.emit();
      }
      this.previousStart = modStart;
      this.previousEnd = modEnd;
    } else if (this.startupLoop === true) {
      this.startupLoop = false;
    }
  }

  private setHeight(dimensions?: Dimensions): boolean {
    const heightValue = this.height();
    if (heightValue) {
      if (heightValue > 0) {
        this.heightStyle = `${heightValue}px`;
        return true;
      } else {
        if (isNil(dimensions)) {
          if (!this) return undefined;
          dimensions = this.calculateDimensions();
        }
        if (
          this.getItems().length > 0 &&
          dimensions.childHeight &&
          dimensions.childHeight > 0
        ) {
          const count = DEFAULT_VISIBLE_CHILDREN;
          const height = dimensions.childHeight * count;
          const currentHeight = this.getHeight();
          if (height > 0 && currentHeight !== height) {
            this.heightStyle = `${height}px`;
          }
          return true;
        }
      }
    }
    this.heightStyle = '';
    return false;
  }

  private getHeight(): number {
    const value = parseInt(this.heightStyle);
    return !isNil(value) ? value : -1;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
