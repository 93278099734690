import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  output,
  input,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Diet } from 'src/app/shared/Models/diet';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'create-diet',
  templateUrl: './create-diet.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./create-diet.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class CreateDietComponent implements OnChanges {
  readonly diets = input<Diet[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly locationId = input<number>(undefined);
  readonly cancelAction = output<void>();
  readonly createNewDiet = output<number[]>();

  dietIds = new FormControl<number[]>([]);
  filteredDiets: Diet[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    const diets = this.diets();
    if ('diets' in changes && diets) {
      this.filteredDiets = diets.filter(
        (d) => d.location === this.locationId(),
      );
    }
  }
}
