import { Component, input } from '@angular/core';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.css'],
  imports: [MatCardModule, MatIconModule, SpinnerComponent],
})
export class ContentBlockComponent {
  readonly color = input('#009fed');
  readonly icon = input<string>(undefined);
  readonly title = input<string>(undefined);
  readonly mainTitle = input<string>(undefined);
  readonly secondTitle = input<string>(undefined);
  readonly hideTitle = input(false);

  readonly translations = input(false);
  readonly loading = input(false);
}
