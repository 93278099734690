import { Component, input } from '@angular/core';
import { FileUploadControl } from '@iplab/ngx-file-upload';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'file-upload-item',
  templateUrl: './file-upload-item.component.html',
  styleUrls: ['./file-upload-item.component.scss'],
  imports: [MatButtonModule, MatIconModule],
})
export class FileUploadItemComponent {
  readonly control = input<FileUploadControl>(undefined);
  readonly file = input<File>(undefined);
}
