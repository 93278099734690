import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import {
  NgModel,
  ReactiveFormsModule,
  FormsModule,
  FormControl,
} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { MENUWRITING_VIDEO } from 'src/app/app.config';
import {
  DnDData,
  TreeManagerService,
} from 'src/app/menus/tree-manager.service';
import { SidePanelControllerBase } from 'src/app/shared/Classes/side-panel-controller.base';
import { TwoColumnsDialogComponent } from 'src/app/shared/Components/declarations/two-columns-dialog/two-columns-dialog.component';
import { TwoColumnsModalConfig } from 'src/app/shared/Components/declarations/two-columns-dialog/two-columns-dialog.model';
import { ConfirmDialogComponent } from 'src/app/shared/Components/dialogs/confirm-dialog/confirm-dialog.component';
import { MenutechVirtualScrollComponent } from 'src/app/shared/Components/menutech-virtual-scroll/menutech-virtual-scroll.component';
import { QuillEditorComponent } from 'src/app/shared/Components/quill-editor/quill-editor.component';
import {
  Categories,
  CategoriesSettings,
} from 'src/app/shared/constants/categories';
import {
  blockExpressTranslationLangs as blockedLangs,
  ContentLanguage,
  InterfaceLanguage,
  langsExtended,
} from 'src/app/shared/constants/languages';
import {
  CondensedDish,
  Dish,
  DishCategory,
  ItemCategory,
  SeparatorCategory,
  SimpleDishAdditives,
  SimpleDishAllergens,
  Spellcheck,
} from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  BackgroundImage,
  CreatePresetFromMenu,
  Menu,
  MenuAnalysisType,
  MenuPreset,
  MenuPreviewData,
} from 'src/app/shared/Models/menu';
import { MenuDishNode } from 'src/app/shared/Models/menu-dish-node';
import {
  ChangeDishOptions,
  MenuDish,
  SimpleMenuDish,
} from 'src/app/shared/Models/menudish';
import { Fulfillable } from 'src/app/shared/Models/models';
import { Partner } from 'src/app/shared/Models/partners';
import {
  Recipe,
  RecipeIngredient,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { CondensedSeparator, Separator } from 'src/app/shared/Models/separator';
import { User } from 'src/app/shared/Models/user';
import {
  addIngredientToDishRecipe,
  addIngredientToRecipe,
  chooseRecipe,
  createNewIngredient,
  createRecipe,
  deleteDishRecipeIngredient,
  fetchRecipes,
  generateAiAllergens,
  generateAiDescription,
  generateAiRecipes,
  removeRecipe,
  updateIngredient,
  updateRecipe,
  updateRecipeIngredient,
} from 'src/app/shared/ngrx/dishes-menu/dishes-menu.actions';
import {
  clearSimilarDishes,
  fetchDishesAutocomplete,
  fetchIngredientsAutocomplete,
  fetchMoreDishes,
  fetchRecipesAutocomplete,
  fetchSeparatorAutocomplete,
  setAutocompleteSeparator,
  setDishesAutocomplete,
  showSnackbarMessage,
  uploadImage,
} from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { MenuService } from '../../menu.service';
import {
  checkMenuGrammar,
  completeMenuWriteTutorial,
  restoreFieldDefault,
  saveFieldDefault,
  uploadBackgroundImage,
} from '../ngrx/menu-edit.actions';
import { BackgroundsLibraryComponent } from '../style/backgrounds-library/backgrounds-library.component';
import { MenuActionsComponent } from './menu-actions/menu-actions.component';
import {
  addMenuDish,
  addMenuDishAtIndex,
  addOption,
  addWordToUserDictionary,
  applyPreset,
  bulkPriceChange,
  changeMenuDish,
  copyDeclaration,
  createDish,
  createMenuDish,
  createMenuDishWithAi,
  createSeparator,
  deleteMenuDish,
  deleteMenuDishes,
  duplicateMenuDish,
  fetchMenuDish,
  fetchMenuDishes,
  refreshMenuDish,
  removeMenuDish,
  sortMenuDishes,
  synchroniseRecipeDeclarations,
  updateDish,
  updateMenuDish,
  updateSeparator,
} from './ngrx/menu-write.actions';
import { selectIsSorting } from './ngrx/menu-write.selectors';
import { selectRemainingAiCredits } from 'src/app/shared/user/ngrx/user.selectors';
import { selectIsAiAnalysisLoading } from '../ngrx/menu-edit.selectors';
import { CopyDeepPipe } from '../../../shared/Pipes/copy-deep.pipe';
import { WriteSidebarComponent } from './write-sidebar/write-sidebar.component';
import { ReturnSidePanelMobileComponent } from '../../../shared/Components/return-side-panel-mobile/return-side-panel-mobile.component';
import { QuillEditorComponent as QuillEditorComponent_1 } from '../../../shared/Components/quill-editor/quill-editor.component';
import { SaveRestoreComponent } from './save-restore/save-restore.component';
import { MenuDetailsComponent } from './menu-details/menu-details.component';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { MatMenuModule } from '@angular/material/menu';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { NgClass, AsyncPipe, SlicePipe } from '@angular/common';
import { DndSortItemDirective } from '../../../shared/Directives/dnd-sort/dnd-sort-item.directive';
import { MenutechVirtualScrollComponent as MenutechVirtualScrollComponent_1 } from '../../../shared/Components/menutech-virtual-scroll/menutech-virtual-scroll.component';
import { DndSortDirective } from '../../../shared/Directives/dnd-sort/dnd-sort.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep } from 'lodash-es';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';
import { dishesMenuFeature } from 'src/app/shared/ngrx/dishes-menu/dishes-menu.state';

@Component({
  selector: 'write',
  templateUrl: './write.component.html',
  styleUrls: ['./write.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    StopPropagationDirective,
    MatIconModule,
    TourMatMenuModule,
    MatCardModule,
    DndSortDirective,
    MenutechVirtualScrollComponent_1,
    DndSortItemDirective,
    NgClass,
    MenuItemComponent,
    MatMenuModule,
    SpinnerComponent,
    MenuDetailsComponent,
    MatSlideToggleModule,
    SaveRestoreComponent,
    QuillEditorComponent_1,
    ReturnSidePanelMobileComponent,
    WriteSidebarComponent,
    MenuActionsComponent,
    AsyncPipe,
    SlicePipe,
    TranslocoPipe,
    CopyDeepPipe,
  ],
})
export class WriteComponent
  extends SidePanelControllerBase
  implements OnChanges, OnDestroy, OnInit, AfterViewInit
{
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private destroyRef = inject(DestroyRef);
  private dialog = inject(MatDialog);
  private tourService = inject(TourService);
  private menuService = inject(MenuService);
  private renderer = inject(Renderer2);
  private translate = inject(TranslocoService);
  private treeManager = inject(TreeManagerService);
  private utils = inject(UtilsService);

  aiAnalysisLoading$ = this.ngrxStore.select(selectIsAiAnalysisLoading);
  aiAllergensLoading$ = this.ngrxStore.select(
    dishesMenuFeature.selectAiAllergensLoading,
  );
  aiDescriptionLoading$ = this.ngrxStore.select(
    dishesMenuFeature.selectAiDescriptionLoading,
  );
  aiRecipesLoading$ = this.ngrxStore.select(
    dishesMenuFeature.selectAiRecipesLoading,
  );
  aiCreditsRemaining$ = this.ngrxStore.select(selectRemainingAiCredits);
  isSorting$ = this.ngrxStore.select(selectIsSorting);
  locationGroups$ = this.ngrxStore.select(
    sharedFeature.selectAllLocationsGroups,
  );
  locations$ = this.ngrxStore.select(sharedFeature.selectAllLocations);
  numberOfDishesAdditives$ = this.ngrxStore.select(
    sharedFeature.selectSimilarDishesAdditivesCount,
  );
  numberOfDishes$ = this.ngrxStore.select(
    sharedFeature.selectSimilarDishesAllergensCount,
  );
  recipes$ = this.ngrxStore.select(dishesMenuFeature.selectRecipes);
  similarDishesAdditives$ = this.ngrxStore.select(
    sharedFeature.selectSimilarDishesAdditives,
  );
  similarDishesAllergens$ = this.ngrxStore.select(
    sharedFeature.selectSimilarDishesAllergens,
  );
  dishesAutocomplete$ = this.ngrxStore.select(
    sharedFeature.selectDishesAutocomplete,
  );
  sectionsAutocomplete$ = this.ngrxStore.select(
    sharedFeature.selectSectionsAutocomplete,
  );

  readonly autoRecipes = input<Recipe[]>(undefined);
  readonly backgroundImages = input<BackgroundImage[]>(undefined);
  readonly isTrial = input<boolean>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly menuDishes = input<(SimpleMenuDish | MenuDish)[]>(undefined);
  readonly menuDishesLoading = input<boolean>(undefined);
  readonly menuDishesSearch = input<boolean>(undefined);
  readonly interfaceLang = input<InterfaceLanguage>(undefined);
  readonly partner = input<Partner>(undefined);
  readonly privileges = input<any>(undefined);
  readonly profileComplete = input<boolean>(undefined);
  readonly spellcheckItem = input<Spellcheck>(undefined);
  readonly user = input<User>(undefined);

  readonly deleteBackground = output<BackgroundImage>();
  readonly fetchSpellcheck = output<number>();
  readonly nextStep = output();
  readonly patchMenu = output<
    DeepPartial<Menu> & {
      forceUpdateDishes?: boolean;
    }
  >();
  readonly regenerateMenu = output<void>();
  readonly presetAction = output<{
    preset: CreatePresetFromMenu;
    id: number;
    existing: boolean;
  }>();
  readonly showMenuAnalysis = output<MenuAnalysisType>();
  readonly showPreview = output<MenuPreviewData>();
  readonly updateUser = output<DeepPartial<User>>();
  readonly dataExport = output<Menu>();
  readonly dataImport = output<Menu>();

  readonly bottomDrop = viewChild<ElementRef>('bottomDrop');
  readonly buttons = viewChild<ElementRef>('buttons');
  @ViewChild('menuActionComponent', { static: false })
  set menuActionComponent(component: MenuActionsComponent) {
    this.menuSettings = component?.menuSettings();
  }
  readonly quillEditorComponent =
    viewChild<QuillEditorComponent>('quillEditor');
  readonly virtualScroll = viewChild(MenutechVirtualScrollComponent);

  dialogRef: MatDialogRef<TwoColumnsDialogComponent>;
  editorUpdating = false;
  NODE_HEIGHT = 73;
  SELECTED_NODES_CACHE_SIZE = 10;
  lastSelectedNodes = new Map<number, MenuDishNode>();
  anyExpanded: boolean;
  blockExpressTranslationLangs = blockedLangs;
  categories = Categories;
  categoriesSettings = CategoriesSettings;
  currentElement: {
    data: MenuDish;
    show: boolean;
    node: MenuDishNode;
    nodeIdx: number;
  } = {
    data: null,
    show: false,
    node: null,
    nodeIdx: null,
  };
  delayedSaveDefault: () => void;
  private destroyed$ = new Subject<void>();
  enabledItems: ItemCategory[] = [];
  forbiddenDishes = [6000, 6001, 6002];
  forbiddenSections = [4000, 4001, 4002];
  isDragable: boolean;
  isFocused = false;
  isExtendedLang: boolean;
  isMobileView: boolean;
  isSafari = false;
  lang: ContentLanguage;
  lastIndexSection = -1;
  menudishChanged = false;
  menudishOverviewLang: string;
  menuwritingVideo = MENUWRITING_VIDEO;
  menuSettings: MatExpansionPanel;
  newNodeInFocus: boolean;
  nodesTemporaryFocused = new Set<MenuDishNode>();
  nodesUnderCreation = new Map<number, boolean>();
  nodesUnderEdit = new Map<number, boolean>();
  oldBaseLanguage: string;
  partentNodesChildUnderEdit = new Map<number, number>();
  previousMenudishID = null;
  searchIsActive = false;
  showTreeActions: boolean;
  sortAfterInsert = false;
  translations: any = {};
  treeStructure: MenuDishNode[];
  viewPortMenuDishes: MenuDishNode[] = [];
  searchDisabled = false;
  searchControl = new FormControl(
    {
      value: '',
      disabled: this.searchDisabled,
    },
    { nonNullable: true },
  );
  autostartTour = false;

  constructor() {
    super();
    this.isSafari = this.utils.isSafari();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.translate
      .selectTranslation(this.menudishOverviewLang)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((translations) => {
        this.translations = translations || this.translations;
      });
    this.treeManager.treeStructureChanged
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ items }) => this.setTreeStructure(items));
    this.subscribeToForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('lang' in changes && this.lang) {
      this.isExtendedLang = langsExtended.some((l) => l === this.lang);
    }
    const menu = this.menu();
    if ('menu' in changes && menu) {
      this.lang = menu.base_language;
      this.enabledItems = Object.values(this.categories).filter(
        (cat) => this.menu()[this.categoriesSettings[cat]],
      );
      if (menu.base_language && this.oldBaseLanguage !== menu.base_language) {
        this.oldBaseLanguage = menu.base_language;
        this.menudishOverviewLang = langsExtended.includes(menu.base_language)
          ? this.interfaceLang()
          : menu.base_language;
      }
    }
    const user = this.user();
    if (
      'user' in changes &&
      user &&
      changes.user.previousValue?.id !== user.id &&
      !user.tutorials.menu_write
    ) {
      this.autostartTour = true;
    }
    const menuDishes = this.menuDishes();
    if (this.sortAfterInsert && !this.nodesUnderCreation.size) {
      this.onSortChange(menuDishes);
    }
    if ('menuDishes' in changes && menuDishes) {
      this.isDragable = this.draggable(menuDishes);
      this.showTreeActions = menuDishes?.some((dish) => dish.level > 1);
      this.treeManager.setSource({
        list: menuDishes,
        search: this.menuDishesSearch(),
      });
      this.anyExpanded = this.treeManager.isAnyExpanded();
      if (this.searchControl?.value) {
        this.utils.runOutsideWithTimer(0, () => {
          const virtualScroll = this.virtualScroll();
          if (virtualScroll) virtualScroll.updatePaddings();
        });
      } else {
        this.utils.runOutsideWithTimer(50, () => this.refreshBottomDrop());
        this.isDragable = true;
      }
    }
  }

  ngAfterViewInit() {
    this.utils.runOutsideWithTimer(0, () => this.refreshBottomDrop());
    if (this.sidePanel) {
      this.sidePanel.mobileViewSubject
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value) => {
          value && this.resetCurrentElement();
          setTimeout(() => {
            this.isMobileView = value;
          });
        });
    }
  }

  bulkPriceChange(value: {
    percentage_increase?: number;
    rounding_base?: number;
  }) {
    this.ngrxStore.dispatch(bulkPriceChange(value));
  }

  refreshBottomDrop() {
    const buttons = this.buttons();
    const bottomDrop = this.bottomDrop();
    if (buttons && bottomDrop) {
      const { height } = buttons.nativeElement.getBoundingClientRect();
      bottomDrop.nativeElement.style.height = height > 75 ? '120px' : '65px';
    }
  }

  clearSearch() {
    this.searchControl.patchValue('');
  }

  uploadBackgroundImage(
    currentMenuDish: SimpleMenuDish | MenuDish,
    data: Fulfillable<File>,
    isMenuDish = false,
  ) {
    this.ngrxStore.dispatch(
      uploadBackgroundImage({
        data: data,
        imageType: 2,
        params: { current_menu: this.menu().id },
        callback: (imageId: number) => {
          this.addImage(imageId, isMenuDish, data, currentMenuDish);
        },
      }),
    );
  }

  addImage(
    imageId: number,
    isMenuDish: boolean,
    data: Fulfillable<File>,
    currentMenuDish: SimpleMenuDish | MenuDish,
  ): void {
    const imageDetail = { user_details: { background: imageId } };
    const onFulfilled = (md: MenuDish) => {
      this.updateMenuDishOnUpdate(currentMenuDish, md, data);
    };
    if (isMenuDish) {
      this.ngrxStore.dispatch(
        updateMenuDish({
          url: currentMenuDish.url,
          id: currentMenuDish.id,
          menuDish: imageDetail,
          onFulfilled,
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        updateSeparator({
          url: currentMenuDish.separator_detail.url,
          id: currentMenuDish.separator_detail.id,
          separator: imageDetail,
          menuDish: currentMenuDish,
          onFulfilled,
        }),
      );
    }
  }

  showAllBackgrounds({
    menudish,
    dish,
  }: {
    menudish: boolean;
    dish: MenuDish;
  }): void {
    this.dialog.open(BackgroundsLibraryComponent, {
      data: {
        backgroundImages: this.backgroundImages(),
        currentBackground: menudish
          ? dish.user_details.background
          : dish.separator_detail.user_details.background,
        deleteBackground: (image: BackgroundImage) =>
          this.deleteBackground.emit(image),
        select: (id: number | null) => {
          const data: DeepPartial<Dish | MenuDish> = {
            user_details: { background: id },
          };
          menudish
            ? this.changeMenuDishField({
                dish,
                data: data,
              })
            : this.changeItemField({
                menuDish: dish,
                data: data as DeepPartial<Dish>,
              });
        },
      },
    });
  }

  searchDishes(search: string): void {
    if (search && search.length >= 3) {
      this.searchIsActive = true;
      this.ngrxStore.dispatch(
        fetchMenuDishes({
          params: {
            [this.menu()?.base_language || this.lang]: search,
            condensed: true,
          },
        }),
      );
    } else if (!search) {
      this.searchIsActive = false;
      this.ngrxStore.dispatch(fetchMenuDishes({ params: { condensed: true } }));
    }
  }

  subscribeToForm() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((v) => this.searchDishes(v));
  }

  addWordToDictionary(word: string): void {
    const onFulfilled = (md: MenuDish) => {
      this.treeManager.updateMenuDish(this.currentElement.node, md);
      this.utils.getTranslation(
        'menus.shared.grammar-added',
        (message: string) => {
          this.ngrxStore.dispatch(showSnackbarMessage({ message }));
        },
      );
    };
    const text = (this.currentElement?.data?.dish_detail ||
      this.currentElement?.data?.separator_detail)?.[
      this.menu()?.base_language
    ];
    this.ngrxStore.dispatch(
      addWordToUserDictionary({
        word,
        text,
        lang: this.lang,
        menuDish: this.currentElement.data,
        onFulfilled,
      }),
    );
  }

  ignoreWord(): void {
    const currentMenuDish = this.currentElement.data;
    const onFulfilled = (md: MenuDish) => {
      this.updateMenuDishOnUpdate(currentMenuDish, md);
      this.utils.getTranslation(
        'menus.shared.grammar-ignored',
        (message: string) => {
          this.ngrxStore.dispatch(showSnackbarMessage({ message }));
        },
      );
    };
    if (currentMenuDish.dish_detail) {
      this.ngrxStore.dispatch(
        updateDish({
          url: currentMenuDish.dish_detail.url,
          id: currentMenuDish.dish_detail.id,
          dish: { spellcheck_ignore: true },
          menuDish: currentMenuDish,
          searchIsActive: this.searchIsActive,
          onFulfilled,
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        updateSeparator({
          url: currentMenuDish.separator_detail.url,
          id: currentMenuDish.separator_detail.id,
          separator: { spellcheck_ignore: true },
          menuDish: currentMenuDish,
          searchIsActive: this.searchIsActive,
          onFulfilled,
        }),
      );
    }
  }

  addRecipe({ dish, recipeId }: { dish: Dish; recipeId: number }) {
    const onFulfilled = ({ dish }: { dish: Dish; recipe: Recipe }) => {
      const newMenudish = new MenuDish(this.currentElement.data);
      newMenudish.dish_detail = dish;
      this.refreshDish(newMenudish);
    };
    this.ngrxStore.dispatch(
      chooseRecipe({
        url: dish.url,
        recipeId,
        onFulfilled,
        params: { current_menu: this.menu().id },
      }),
    );
  }

  addIngredient(recipe: Recipe): void {
    const newIngredient = new RecipeIngredient(null, true);
    this.ngrxStore.dispatch(
      addIngredientToDishRecipe({ recipe, ingredient: newIngredient }),
    );
  }

  deleteIngredient({
    deletingIngredient,
    recipe,
  }: {
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }): void {
    this.ngrxStore.dispatch(
      deleteDishRecipeIngredient({
        recipe,
        recipeIngredient: deletingIngredient,
      }),
    );
  }

  searchIngredients(term: string): void {
    const params = {
      [this.lang]: term,
      condensed: true,
    };
    this.ngrxStore.dispatch(fetchIngredientsAutocomplete({ params }));
  }

  selectedIngredient({
    ingredient_id,
    recipe,
  }: {
    ingredient_id: number;
    recipe: Recipe;
  }) {
    this.ngrxStore.dispatch(
      addIngredientToRecipe({ ingredientId: ingredient_id, recipe }),
    );
  }

  createIngredientEvent({
    newIngredient,
    recipe,
  }: {
    newIngredient: Partial<Recipe>;
    recipe: Recipe;
  }) {
    this.ngrxStore.dispatch(
      createNewIngredient({ recipe, data: newIngredient }),
    );
  }

  updateRecipeIngredientEvent(data: {
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: () => void;
    };
  }) {
    const onFulfilled = () => {
      data.updatedIngredient.onFulfilled?.();
    };
    this.ngrxStore.dispatch(
      updateRecipeIngredient({
        recipe_id: data.recipe.id,
        url: data.updatedIngredient.url,
        data: data.updatedIngredient.recipeIngredient,
        params: { condensed: true, current_menu: this.menu().id },
        onFulfilled,
      }),
    );
  }

  updateIngredientEvent(data: {
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
    };
  }): void {
    this.ngrxStore.dispatch(
      updateIngredient({
        url: data.updatedIngredient.ingredient.url,
        id: data.updatedIngredient.ingredient.id,
        data: data.updatedIngredient.ingredient,
        params: { current_menu: this.menu().id },
        recipe_id: data.recipe.id,
        recipe_ingredient: data.updatedIngredient.recipeIngredient,
      }),
    );
  }

  removeRecipe({ dish, recipeId }: { dish: Dish; recipeId: number }) {
    const onFulfilled = (dish: Dish) => {
      const newMenudish = new MenuDish(this.currentElement.data);
      newMenudish.dish_detail = dish;
      this.refreshDish(newMenudish);
    };
    this.ngrxStore.dispatch(
      removeRecipe({
        dish,
        data: { recipe: recipeId },
        onFulfilled,
        queryParams: { current_menu: this.menu().id },
      }),
    );
  }

  searchRecipe(params: Partial<RecipeParams>) {
    this.ngrxStore.dispatch(
      fetchRecipesAutocomplete({
        params: { ...params, current_menu: this.menu().id },
      }),
    );
  }

  patchRecipe({
    url,
    payload,
    onFulfilled,
  }: {
    url: string;
    payload: Partial<Recipe & { update_quantities: boolean }>;
    onFulfilled: () => void;
  }) {
    this.ngrxStore.dispatch(
      updateRecipe({
        url,
        data: payload,
        params: { condensed: true, current_menu: this.menu().id },
        onFulfilled,
      }),
    );
  }

  createRecipe({ data, url }: { data: Partial<Recipe>; url: string }) {
    const onFulfilled = ({ dish }: { dish: Dish; recipe: Recipe }) => {
      const newMenudish = new MenuDish(this.currentElement.data);
      newMenudish.dish_detail = dish;
      this.refreshDish(newMenudish);
    };
    this.ngrxStore.dispatch(
      createRecipe({
        url,
        data,
        onFulfilled,
        params: { current_menu: this.menu().id },
      }),
    );
  }

  fetchDishRecipes(url: string) {
    this.ngrxStore.dispatch(
      fetchRecipes({ url, queryParams: { current_menu: this.menu().id } }),
    );
  }

  trackMenudishesFn(index: number, item: MenuDishNode) {
    return (
      index +
      item.level +
      item.dish.id +
      (item.dish.dish ?? 0) +
      (item.dish.separator ?? 0) +
      (item.children?.length ?? 0) +
      (item.dottedLineMultiplier ?? 0) +
      (item.isExpanded ? 0.5 : 0) +
      (item.deleted ? 0.05 : 0) +
      (!item.dish.url ? 0.1 : 0) +
      (item.childInCreation ? 0.01 : 0) +
      item.realIndex
    );
  }

  changeDraggable(menudishWrapper: HTMLElement, state: boolean): void {
    menudishWrapper.draggable = state;
    menudishWrapper.dataset.focused = (!state).toString();
    this.isFocused = !state;
  }

  choosePreset(preset: MenuPreset): void {
    if (this.menuDishes()?.length) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          title: this.translations[
            `write.blocks.sidebar.preset.dialog.title`
          ]?.replace('{{name}}', preset[this.menu().base_language]) as string,
          content: this.translations[
            `write.blocks.sidebar.preset.dialog.message`
          ] as string,
          confirmMessage: this.translations[
            `default_buttons.confirm_`
          ] as string,
          cancelMessage: this.translations[`default_buttons.cancel`] as string,
        },
      });
      dialogRef.componentInstance.dialogConfirmed.subscribe((v) => {
        if (v) this.ngrxStore.dispatch(applyPreset({ preset: preset.id }));
      });
    } else {
      this.ngrxStore.dispatch(applyPreset({ preset: preset.id }));
    }
  }

  refreshDish(menuDish: SimpleMenuDish | MenuDish) {
    const onFulfilled = (md: MenuDish) => {
      if (!this.menudishChanged && md.id && this.previousMenudishID === md.id) {
        this.currentElement.data = new MenuDish(md);
      }
    };
    this.ngrxStore.dispatch(
      refreshMenuDish({ url: menuDish.url, onFulfilled }),
    );
  }

  hasModules(code: string): boolean {
    return this.utils.hasModules(code);
  }

  updateVirtualScroll(index?: number) {
    this.utils.runOutsideWithTimer(50, () => {
      const virtualScroll = this.virtualScroll();
      if (!virtualScroll) return undefined;
      virtualScroll.refreshList();
      if (
        this.menuDishes().length - 2 <= index ||
        index === this.lastIndexSection
      ) {
        virtualScroll.scrollDown();
        this.lastIndexSection = index;
      } else if (index === undefined) {
        this.lastIndexSection = -1;
        virtualScroll.scrollDown();
      } else {
        virtualScroll.updateAfterRecalculate();
        this.lastIndexSection = -1;
      }
    });
  }

  expandAll() {
    this.treeManager.expandAll();
    this.anyExpanded = true;
  }

  collapseAll() {
    this.treeManager.collapseAll();
    this.anyExpanded = false;
  }

  triggerInputBlur() {
    if (this.nodesTemporaryFocused.size) this.utils.blurInput.next(true);
  }

  updateViewPortMenuDishes(dishes: MenuDishNode[]) {
    if (this.currentElement?.node) this.utils.blurInput.next(true);
    requestAnimationFrame(() => (this.viewPortMenuDishes = dishes));
    if (this.anyExpanded === undefined)
      this.anyExpanded = this.treeManager.isAnyExpanded();
  }

  setTreeStructure(dishes: MenuDishNode[]) {
    setTimeout(() => (this.treeStructure = dishes), 0);
  }

  toggleSection(node: MenuDishNode) {
    node.isExpanded = !node.isExpanded;
    node.isExpanded
      ? this.treeManager.expand(node.index, node.isExpanded)
      : this.treeManager.collapse(node);
    this.anyExpanded = node.isExpanded ?? this.treeManager.isAnyExpanded();
  }

  showModal({
    item,
    type,
  }: {
    item: Dish | Ingredient;
    type: 'allergens' | 'additives';
  }) {
    const config: TwoColumnsModalConfig = {
      type,
      numberOfDishes: this.numberOfDishes$,
      numberOfDishesAdditives: this.numberOfDishesAdditives$,
      similarDishes: this.similarDishesAllergens$,
      similarDishesAdditives: this.similarDishesAdditives$,
      lang: this.interfaceLang(),
      contentLang: this.lang,
      dish: item as Dish,
      addOption: this.addOption,
      copyDeclarations: this.copyDeclarations,
    };

    this.dialogRef = this.dialog.open(TwoColumnsDialogComponent, {
      data: config,
      autoFocus: false,
      width: '900px',
      maxWidth: '95vw',
    });

    this.dialogRef.afterClosed().subscribe((v) => {
      this.blurButtons('.options-section .icon-button');
    });

    this.dialogRef.componentInstance.loadMoreDishes.subscribe((v) =>
      this.fetchNextDishes(v),
    );
  }

  blurButtons(selector: string) {
    const elements = Array.from(document.querySelectorAll(selector));
    elements.forEach((el: any) => {
      el.classList.remove('cdk-focused');
      el.classList.remove('cdk-program-focused');
    });
  }

  selectAutocompleteOption({
    item,
    node,
    isDish,
  }: {
    item: CondensedDish | CondensedSeparator;
    node: MenuDishNode;
    isDish: boolean;
  }) {
    const isNewNode = !node.dish.url;
    const prevDishId = node.dish.id;
    const previousNode = cloneDeep(node);
    const newMenudish = new MenuDish(node.dish);
    isDish
      ? newMenudish.setDish(item as Dish)
      : newMenudish.setSeparator(item as Separator);
    if (!isNewNode && this.searchIsActive) {
      delete newMenudish['level'];
      delete newMenudish['order'];
    }
    this.setNodeProcessing(node, isNewNode);

    const onFulfilled = (result: MenuDish) => {
      this.treeManager.updateMenuDish(node, result);
      if (
        isNewNode &&
        node.parentNode &&
        result.separator_detail?.category === Categories.SECTION
      ) {
        node.parentNode.clearMaxChildLevel();
      }
      if (
        !this.sidePanel.isMobile &&
        this.nodesUnderCreation.size + this.nodesUnderEdit.size <= 1
      ) {
        this.setCurrentElement(undefined, node);
      }
      this.removeNodeProcessing(node, prevDishId, isNewNode);
    };
    const onError = () => {
      this.removeNodeProcessing(node, prevDishId, isNewNode);
      this.treeManager.updateMenuDish(node, previousNode.dish);
      this.isDragable = true;
    };
    const data = {
      [isDish ? 'dish' : 'separator']: item.id,
    };
    if (isNewNode) {
      this.ngrxStore.dispatch(
        createMenuDish({
          menuDish: newMenudish,
          sendSortRequest: !this.searchIsActive,
          onFulfilled,
          onError,
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        updateMenuDish({
          url: newMenudish.url,
          id: newMenudish.id,
          menuDish: data,
          onFulfilled,
          onError,
        }),
      );
    }
  }

  appendLine = (
    menudish: SimpleMenuDish | MenuDish,
    category: ItemCategory,
  ) => {
    const newNode = this.treeManager.appendNode(menudish, category);
    this.ngrxStore.dispatch(
      addMenuDish({ menuDish: new MenuDish(newNode.dish) }),
    );
  };

  insertLine = (
    node: MenuDishNode,
    menudish: SimpleMenuDish | MenuDish,
  ): MenuDishNode => {
    const newNode = this.treeManager.insertNode(node, menudish);
    const isInsertAtEnd = newNode.realIndex === this.menuDishes().length;
    if (isInsertAtEnd) {
      this.ngrxStore.dispatch(
        addMenuDish({ menuDish: new MenuDish(newNode.dish) }),
      );
    } else {
      this.ngrxStore.dispatch(
        addMenuDishAtIndex({
          menuDish: new MenuDish(newNode.dish),
          index: newNode.realIndex,
        }),
      );
    }
    return newNode;
  };

  addNewLine = (
    category?: ItemCategory,
    index?: number,
    node?: MenuDishNode,
  ) => {
    if (node && !node.dish.url) return undefined;
    // course and option separators are immediately created
    if (category === Categories.COURSE || category === Categories.OPTION) {
      return this.addAndCreateSeparator(category, index, node);
    }
    if (category === 'ai_dis') {
    }
    this.resetCurrentElement();
    const newMenuDish = new MenuDish(null, true);
    newMenuDish.setCategory(category);
    this.sortAfterInsert = false;
    if (index !== undefined) {
      this.insertLine(node, newMenuDish);
    } else {
      this.appendLine(newMenuDish, category);
    }
    this.updateVirtualScroll(
      index !== undefined ? node.realIndex + 1 : undefined,
    );
  };

  createMenuDishSeparator = (category: SeparatorCategory): MenuDish => {
    const styleField =
      category === Categories.COURSE ? 'course_separator' : 'option_separator';
    const newMenuDish = new MenuDish(null, true).setSeparatorId(
      this.menu().style[styleField],
      category,
    );
    Object.assign(
      newMenuDish.separator_detail,
      this.menu().style[`${styleField}_detail`],
    );
    return newMenuDish;
  };

  addAndCreateAiItem = (
    category: 'ai_dis',
    section: string,
    index?: number,
    node?: MenuDishNode,
  ) => {
    if (this.isTrial() && !this.profileComplete()) {
      this.utils.showTrialBlockedBox();
      return;
    }
    if (node && !node.dish.url) return undefined;
    this.resetCurrentElement();
    const isInsert = index !== undefined && index !== null;

    const newMenuDish = new MenuDish(null, true);
    const prevDishId = newMenuDish.id;
    const newNode = isInsert
      ? this.treeManager.insertNode(node, newMenuDish)
      : this.treeManager.appendNode(newMenuDish, category);
    newMenuDish.order = newNode.realIndex;
    newMenuDish.level = newNode.level;

    this.updateVirtualScroll(isInsert ? newNode.realIndex : undefined);
    this.setNodeProcessing(newNode, true);

    const onFulfilled = (md: MenuDish) => {
      this.removeNodeProcessing(newNode, prevDishId, true);
      this.treeManager.updateMenuDish(newNode, md);
      if (isInsert) {
        this.ngrxStore.dispatch(
          addMenuDishAtIndex({ menuDish: md, index: newNode.realIndex }),
        );
      } else {
        this.ngrxStore.dispatch(addMenuDish({ menuDish: md }));
      }
    };
    const onError = () => {
      this.removeNodeProcessing(newNode, prevDishId, true);
      this.deleteMenuDish(newNode);
      this.isDragable = true;
    };
    this.ngrxStore.dispatch(
      createMenuDishWithAi({
        menuDish: newMenuDish,
        section,
        sendSortRequest: isInsert,
        onFulfilled,
        onError,
      }),
    );
  };

  addAndCreateSeparator = (
    category: Categories.COURSE | Categories.OPTION,
    index?: number,
    node?: MenuDishNode,
  ) => {
    if (node && !node.dish.url) return undefined;
    this.resetCurrentElement();
    const isInsert = index !== undefined && index !== null;
    const newMenuDish = this.createMenuDishSeparator(category);
    const prevDishId = newMenuDish.id;
    const newNode = isInsert
      ? this.treeManager.insertNode(node, newMenuDish)
      : this.treeManager.appendNode(newMenuDish, category);
    newMenuDish.order = newNode.realIndex;
    newMenuDish.level = newNode.level;

    this.updateVirtualScroll(isInsert ? newNode.realIndex : undefined);
    this.setNodeProcessing(newNode, true);

    const onFulfilled = (md: MenuDish) => {
      this.removeNodeProcessing(newNode, prevDishId, true);
      this.treeManager.updateMenuDish(newNode, md);
      if (isInsert) {
        this.ngrxStore.dispatch(
          addMenuDishAtIndex({ menuDish: md, index: newNode.realIndex }),
        );
      } else {
        this.ngrxStore.dispatch(addMenuDish({ menuDish: md }));
      }
    };
    const onError = () => {
      this.removeNodeProcessing(newNode, prevDishId, true);
      this.deleteMenuDish(newNode);
      this.isDragable = true;
    };
    this.ngrxStore.dispatch(
      createMenuDish({
        menuDish: newMenuDish,
        sendSortRequest: isInsert,
        onFulfilled,
        onError,
      }),
    );
  };

  duplicateSection(node: MenuDishNode): void {
    if (node.level === 1)
      this.ngrxStore.dispatch(duplicateMenuDish({ url: node.dish.url }));
  }

  treeDnD(data: DnDData): void {
    this.treeManager.dnd(data);
  }

  clearSame = (name) => {
    if (name === 'dish') {
      this.ngrxStore.dispatch(setDishesAutocomplete({ payload: null }));
    } else {
      this.ngrxStore.dispatch(setAutocompleteSeparator({ payload: [] }));
    }
  };

  changeLocation({ value }) {
    this.patchMenu.emit({ location: value, forceUpdateDishes: true });
    this.resetCurrentElement();
  }

  changeLocationGroup({ value }) {
    this.patchMenu.emit({ location_group: value });
  }

  changeMenuSettings = (data: DeepPartial<Menu>) => this.patchMenu.emit(data);

  setCoverpageState(state: MatSlideToggleChange) {
    this.patchMenu.emit({ style: { print_coverpage: state.checked } });
  }

  changeMenuDish = (data: Fulfillable<MenuDish>, node?: MenuDishNode) => {
    node = node || this.currentElement.node;
    if (!node) return undefined;
    this.isDragable = false;
    const onFulfilled = (menuDish: MenuDish) => {
      data.onFulfilled?.();
      if (
        !this.menudishChanged &&
        menuDish.id &&
        this.previousMenudishID === menuDish.id
      ) {
        this.currentElement.data = new MenuDish(menuDish);
        this.treeManager.updateMenuDish(
          node || this.currentElement.node,
          menuDish,
        );
        this.sortAfterInsert = true;
      } else {
        this.treeManager.findNodeAndUpdateMenuDish(menuDish);
      }
      this.menudishChanged = false;
    };
    this.ngrxStore.dispatch(
      updateMenuDish({
        url: data.payload.url,
        id: data.payload.id,
        menuDish: data.payload,
        onFulfilled,
      }),
    );
  };

  updateMenuDishOnUpdate(
    previousDish: SimpleMenuDish | MenuDish,
    menuDish: SimpleMenuDish | MenuDish,
    data?: any,
  ) {
    if (data?.onFulfilled) data.onFulfilled();
    this.menudishChanged = false;

    const nodeIndex = this.treeManager.findNodeIndex(previousDish);
    if (nodeIndex === null) return undefined;
    this.treeManager.updateMenuDish(
      this.treeManager.findNode(nodeIndex),
      menuDish,
    );
    if (
      this.currentElement.data &&
      ((!this.menudishChanged &&
        menuDish?.id &&
        this.previousMenudishID === menuDish?.id) ||
        this.currentElement.data.id === menuDish?.id)
    ) {
      this.currentElement.data = new MenuDish(menuDish);
    }
  }

  changeItemField = ({
    menuDish,
    data,
  }: {
    menuDish: SimpleMenuDish | MenuDish;
    data: DeepPartial<Dish | Separator> & { onFulfilled?: () => void };
  }) => {
    this.nodesUnderEdit.set(menuDish.id, true);
    if (this.searchIsActive) {
      delete menuDish.level;
      delete menuDish.order;
    }
    const { onFulfilled: itemFulfilled, ...item } = data;
    const onFulfilled = (md: MenuDish) => {
      itemFulfilled?.();
      this.nodesUnderEdit.delete(menuDish.id);
      this.updateMenuDishOnUpdate(menuDish, md, data);
    };
    if (menuDish.dish_detail) {
      this.ngrxStore.dispatch(
        updateDish({
          url: menuDish.dish_detail.url,
          id: menuDish.dish_detail.id,
          dish: item as DeepPartial<Dish>,
          menuDish,
          searchIsActive: this.searchIsActive,
          onFulfilled,
        }),
      );
    } else {
      this.ngrxStore.dispatch(
        updateSeparator({
          url: menuDish.separator_detail.url,
          id: menuDish.separator_detail.id,
          separator: item as DeepPartial<Separator>,
          menuDish,
          searchIsActive: this.searchIsActive,
          onFulfilled,
        }),
      );
    }
  };

  changeMenuDishField = ({
    dish,
    data,
  }: {
    dish: SimpleMenuDish | MenuDish;
    data: DeepPartial<MenuDish> & { onFulfilled?: () => void };
  }) => {
    this.isDragable = false;
    if (this.searchIsActive) {
      delete dish.level;
      delete dish.order;
    }
    const { onFulfilled: menuDishFulfilled, ...menuDish } = data;
    const onFulfilled = (md: MenuDish) => {
      menuDishFulfilled?.();
      this.updateMenuDishOnUpdate(dish, md, data);
      this.nodesUnderEdit.delete(dish.id);
    };
    this.nodesUnderEdit.set(dish.id, true);
    this.ngrxStore.dispatch(
      updateMenuDish({
        url: dish.url,
        id: dish.id,
        menuDish,
        onFulfilled,
      }),
    );
  };

  getExpansionLineHeight(node: MenuDishNode): string {
    const multiplier = node.hasChildren()
      ? node.children[node.children.length - 1].index - node.index
      : node.dottedLineMultiplier;
    return `${multiplier * this.NODE_HEIGHT - 8}px`;
  }

  getSame = ({
    value,
    category,
  }: {
    value: string;
    category: DishCategory;
  }) => {
    this.ngrxStore.dispatch(
      fetchDishesAutocomplete({
        params: {
          current_menu: this.menu().id,
          [this.lang]: value,
          category: category,
        },
      }),
    );
  };

  getSameSection = (name: string) => {
    this.ngrxStore.dispatch(
      fetchSeparatorAutocomplete({
        params: {
          current_menu: this.menu().id,
          [this.lang]: name,
        },
      }),
    );
  };

  draggable = (dishes) => dishes && !dishes.find((dish) => !dish.id);

  deleteMenuDish(node: MenuDishNode) {
    const parentNode = node.parentNode;
    const wasSection =
      node.dish.separator_detail?.category === Categories.SECTION;
    node.deleted = true;
    if (node.hasChildren()) {
      const ids = node.flattenIDs();
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        autoFocus: false,
        data: {
          title: (
            this.translations[`write.blocks.main.delete.title`] as string
          )?.replace(
            '{name}',
            (node.dish.dish_detail || node.dish.separator_detail)[
              this.menu().base_language
            ],
          ),
          content: (
            this.translations[`write.blocks.main.delete.text`] as string
          )?.replace('{length}', (ids.length - 1).toString()),
          confirmMessage: this.translations[`default_buttons.ok`] as string,
          cancelMessage: this.translations[`default_buttons.cancel`] as string,
        },
      });
      dialogRef.backdropClick().subscribe(() => (node.deleted = false));
      dialogRef.componentInstance.dialogConfirmed.subscribe((v) => {
        if (v) {
          this.isDragable = false;
          this.ngrxStore.dispatch(
            deleteMenuDishes({
              ids: ids.filter((id) => id),
              onFulfilled: () => {
                this.resetCurrentElement();
                this.treeManager.removeNode(node, ids);
              },
            }),
          );
        } else {
          node.deleted = false;
        }
      });
      dialogRef
        .afterClosed()
        .subscribe(() => this.blurButtons('.items .delete:last-child'));
    } else {
      this.isDragable = false;
      if (node.dish?.url) {
        this.ngrxStore.dispatch(
          deleteMenuDish({
            menudish: node.dish,
            onFulfilled: () => {
              this.resetCurrentElement();
              this.treeManager.removeNode(node);
            },
            onError: () => {
              node.deleted = false;
            },
          }),
        );
      } else {
        this.treeManager.removeNode(node);
        this.ngrxStore.dispatch(removeMenuDish({ id: node.dish.id }));
      }
    }
    if (wasSection && parentNode) parentNode.clearMaxChildLevel();
    if (parentNode && !parentNode.children?.length)
      this.toggleSection(parentNode);
  }

  onSortChange(
    newDishes: (SimpleMenuDish | MenuDish)[],
    sendRequest = true,
    updateScroll = false,
  ) {
    if (this.searchIsActive) return undefined;
    if (!this.searchDisabled || this.sortAfterInsert) {
      this.sortAfterInsert = false;
    }
    this.searchDisabled = true;
    if (!sendRequest) return undefined;
    this.ngrxStore.dispatch(
      sortMenuDishes({
        menuDishes: newDishes,
        onFulfilled: () => (this.searchDisabled = false),
      }),
    );
    if (updateScroll) this.virtualScroll().updateList(true);
  }

  addOption = ({
    data,
    type,
  }: {
    data: DeepPartial<Dish> | DeepPartial<Ingredient>;
    type: 'allergens' | 'additives' | 'labels';
  }) => {
    const onFulfilled = (md: MenuDish) => {
      this.updateMenuDishOnUpdate(this.currentElement.data, md);
      this.nodesUnderEdit.delete(this.currentElement.data.id);
    };
    this.nodesUnderEdit.set(this.currentElement.data.id, true);
    this.ngrxStore.dispatch(
      addOption({
        dish: data as Dish,
        declaration: type,
        menuDish: this.currentElement.data,
        onFulfilled,
      }),
    );
  };

  changeNumbering({ model }: NgModel, node: MenuDishNode): void {
    const payload = { numbering: model };
    if (model && model < 0) payload.numbering = 0;
    if (model && (model > 9999 || model.toString().length > 5))
      payload.numbering = 9999;

    const onFulfilled = (md: MenuDish) => {
      const cloned = new MenuDish(md);
      const isUpdated = this.treeManager.updateMenuDish(node, cloned);
      if (!isUpdated) {
        node.dish = cloned;
        this.deleteMenuDish(node);
      }
    };
    this.ngrxStore.dispatch(
      updateMenuDish({
        url: node.dish.url,
        id: node.dish.id,
        menuDish: payload,
        onFulfilled,
      }),
    );
  }

  removeNodeProcessing(node: MenuDishNode, id: number, newNode = false) {
    newNode
      ? this.nodesUnderCreation.delete(id ?? node.dish.id)
      : this.nodesUnderEdit.delete(id ?? node.dish.id);
    if (node.parentNode) this.setChildInCreation(node.parentNode, false);
    if (node.children?.length) this.setChildInCreation(node, false);
  }

  setNodeProcessing(node: MenuDishNode, newNode: boolean): void {
    newNode
      ? this.nodesUnderCreation.set(node.dish.id, true)
      : this.nodesUnderEdit.set(node.dish.id, true);
    if (node.parentNode) this.setChildInCreation(node.parentNode, true);
    if (node.children?.length) this.setChildInCreation(node, true);
  }

  setChildInCreation(node: MenuDishNode, state: boolean): void {
    const incrDecrValue: number = state ? 1 : -1;
    this.partentNodesChildUnderEdit.set(
      node.index,
      (this.partentNodesChildUnderEdit.get(node.index) ?? 0) + incrDecrValue,
    );
    const lastRemaining = this.partentNodesChildUnderEdit.get(node.index) <= 0;
    if (state || lastRemaining) {
      node.childInCreation = state;
      if (lastRemaining) this.partentNodesChildUnderEdit.delete(node.index);
      if (node.parentNode) this.setChildInCreation(node.parentNode, state);
    }
  }

  changeDish = (
    node: MenuDishNode,
    data: Fulfillable<MenuDish>,
    options?: ChangeDishOptions,
  ) => {
    const payload = data.payload;
    const prevDishId = payload.id;
    const previousNode = cloneDeep(node);
    node = node || this.lastSelectedNodes.get(payload.id);
    if (!node) return console.error(`node is not defined`);
    if (payload.deleted) return undefined;
    this.previousMenudishID = null;
    const isNewNode = !payload.url;
    this.setNodeProcessing(node, isNewNode);
    if (this.searchIsActive) {
      delete payload['level'];
      delete payload['order'];
    }

    const onFulfilled = (menuDish: MenuDish) => {
      data.onFulfilled?.();
      if (!menuDish) return undefined;
      if (node.deleted) {
        node.dish = new MenuDish(menuDish);
        return this.deleteMenuDish(node);
      }
      const isUpdated = this.treeManager.updateMenuDish(node, menuDish);
      if (!isUpdated) {
        node.dish = new MenuDish(menuDish);
        return this.deleteMenuDish(node);
      }
      if (
        !this.sidePanel.isMobile &&
        this.nodesUnderCreation.size + this.nodesUnderEdit.size <= 1
      ) {
        this.setCurrentElement(undefined, node);
      }
      if (
        isNewNode &&
        node.parentNode &&
        menuDish.separator_detail?.category === Categories.SECTION
      ) {
        node.parentNode.clearMaxChildLevel();
      }

      if (!this.menudishChanged) {
        if (node.dish.id === menuDish.id) {
          this.currentElement.data = new MenuDish(menuDish);
        }
        this.menudishChanged = false;
      }
      this.removeNodeProcessing(node, prevDishId, isNewNode);
    };
    const onError = () => {
      data.onFulfilled?.();
      this.removeNodeProcessing(node, prevDishId, isNewNode);
      this.treeManager.updateMenuDish(node, previousNode.dish);
      this.isDragable = true;
    };

    if (isNewNode) {
      if (payload.dish_detail) {
        this.ngrxStore.dispatch(
          createDish({
            dish: payload.dish_detail,
            menuDish: payload,
            sendSortRequest: !this.searchIsActive,
            onFulfilled,
            onError,
          }),
        );
      } else {
        this.ngrxStore.dispatch(
          createSeparator({
            separator: payload.separator_detail,
            menuDish: payload,
            sendSortRequest: !this.searchIsActive,
            onFulfilled,
            onError,
          }),
        );
      }
    } else {
      payload.url = node.dish.url;
      const params = {};
      if (options?.edit) params['edit'] = true;
      if (payload.dish_detail) {
        const data = options?.onlyName
          ? {
              [this.lang]: payload.dish_detail[this.lang],
              winery: payload.dish_detail.winery,
              vintage: payload.dish_detail.vintage,
              article_number: payload.dish_detail.article_number,
            }
          : payload.dish_detail;
        this.ngrxStore.dispatch(
          updateDish({
            url: node.dish.dish_detail.url,
            id: node.dish.dish_detail.id,
            dish: data,
            menuDish: payload,
            searchIsActive: this.searchIsActive,
            params,
            onFulfilled,
            onError,
          }),
        );
      } else {
        const data = options?.onlyName
          ? {
              [this.lang]: payload.separator_detail[this.lang],
            }
          : payload.separator_detail;
        this.ngrxStore.dispatch(
          updateSeparator({
            url: node.dish.separator_detail.url,
            id: node.dish.separator_detail.id,
            separator: data,
            menuDish: payload,
            searchIsActive: this.searchIsActive,
            params,
            onFulfilled,
            onError,
          }),
        );
      }
    }
  };

  uploadDishImage = (data: Fulfillable<FormData>) => {
    const menudish = this.currentElement.data;
    const detail =
      menudish.dish_detail || menudish.separator_detail || menudish;
    this.ngrxStore.dispatch(
      uploadImage({
        callback: (res) => {
          data?.onFulfilled();
          if (menudish.separator) {
            menudish.separator_detail = res as Separator;
          } else {
            menudish.dish_detail = res as Dish;
          }
          this.treeManager.updateMenuDish(this.currentElement.node, menudish);
        },
        url: detail.url,
        image: data.payload,
      }),
    );
  };

  copyDeclarations = ({
    source,
    type,
  }: {
    source: SimpleDishAllergens | SimpleDishAdditives;
    type: 'add' | 'all';
  }) => {
    const onFulfilled = (md: MenuDish) => {
      if (this.dialogRef?.componentInstance)
        this.dialogRef.componentInstance.data.dish = md.dish_detail;
      this.updateMenuDishOnUpdate(this.currentElement.data, md);
    };
    this.ngrxStore.dispatch(
      copyDeclaration({
        target: this.currentElement.data,
        source,
        declaration: type,
        onFulfilled,
      }),
    );
  };

  synchroniseRecipeDeclarations = ({
    dish,
    type,
  }: {
    dish: MenuDish;
    type: 'add' | 'all';
  }) => {
    const onFulfilled = (md: MenuDish) => {
      this.updateMenuDishOnUpdate(dish, md);
    };
    this.ngrxStore.dispatch(
      synchroniseRecipeDeclarations({
        menuDish: dish,
        declaration: type,
        onFulfilled,
      }),
    );
  };

  setCurrentElement(event: Event | undefined, node: MenuDishNode) {
    event?.stopPropagation();
    if (this.lastSelectedNodes.size > this.SELECTED_NODES_CACHE_SIZE)
      this.lastSelectedNodes.delete(this.lastSelectedNodes.keys().next().value);
    this.lastSelectedNodes.set(node.dish.id, node);
    this.menudishChanged = !!this.currentElement.show;
    if (
      this.currentElement.data &&
      this.currentElement.data.id === node.dish.id &&
      ((this.currentElement.data.dish &&
        this.currentElement.data.dish === node.dish.dish) ||
        (this.currentElement.data.separator &&
          this.currentElement.data.separator === node.dish.separator))
    ) {
      this.currentElement.show = !this.currentElement.show;
      return undefined;
    }
    // If the full MenuDish is provided, set the current element directly, else fetch the MenuDish
    if ((node.dish as MenuDish).user_details) {
      this.currentElement = {
        data: new MenuDish(node.dish),
        show: true,
        node,
        nodeIdx: node.index,
      };
    } else {
      this.currentElement = {
        data: null,
        show: true,
        node,
        nodeIdx: node?.index,
      };
      this.ngrxStore.dispatch(
        fetchMenuDish({
          url: node.dish.url,
          onFulfilled: (item) => {
            this.currentElement = {
              data: new MenuDish(item),
              show: true,
              node,
              nodeIdx: node?.index,
            };
          },
        }),
      );
    }
    this.previousMenudishID = node.dish.id;
    this.showSidePanel();
  }

  toggleCurrentElement(event: Event, node: MenuDishNode) {
    if (this.currentElement?.node?.dish.id === node.dish.id) {
      this.resetCurrentElement();
    } else {
      this.setCurrentElement(event, node);
    }
  }

  hideSidePanel(): void {
    this.resetCurrentElement();
    super.hideSidePanel();
  }

  fetchNextDishes(typeOfDish = 'allergens') {
    this.ngrxStore.dispatch(fetchMoreDishes({ typeOfDish }));
  }

  saveDefault(data: { target: string; field?: string }) {
    this.ngrxStore.dispatch(saveFieldDefault({ url: this.menu().url, data }));
  }

  restoreDefault(target: string | any, field?: string) {
    if (typeof target !== 'string') {
      field = target.field;
      target = target.target;
    }
    const data = { target, field };
    this.ngrxStore.dispatch(
      restoreFieldDefault({ url: this.menu().url, data }),
    );
  }

  getChildNodeClass(level) {
    return { [`child-node-${level}`]: level > 1 };
  }

  isForbidden(dish: SimpleMenuDish | MenuDish): boolean {
    if (!dish) return true;
    const isDish = !!dish.dish_detail;
    const detail = dish.dish_detail || dish.separator_detail;
    return isDish
      ? this.forbiddenDishes.some((id) => id === detail.id)
      : this.forbiddenSections.some((id) => id === detail.id);
  }

  changeDate({ value }: { value: Date }): void {
    if (!value) return;
    this.patchMenu.emit({
      date: this.dateAdapter.format(value, 'yyyy-MM-dd'),
      forceUpdateDishes: this.menu().add_days,
    });
    this.resetCurrentElement();
  }

  resetCurrentElement() {
    this.previousMenudishID = null;
    this.currentElement = {
      data: null,
      show: false,
      node: null,
      nodeIdx: null,
    };
  }

  grammarCheck(): void {
    this.ngrxStore.dispatch(
      checkMenuGrammar({
        url: this.menu().url,
        language: this.menu().base_language,
        step: 1,
      }),
    );
  }

  onQuillChangedTap(): void {
    this.editorUpdating = true;
  }

  onQuillEditorChanged(data: string): void {
    this.menuService.processMenuOnQuillChanged(
      this.menu(),
      this.lang,
      data,
      (res) => this.patchMenu.emit({ user_details: res }),
    );
    setTimeout(() => (this.editorUpdating = false), 1000);
  }

  openRegenerateDialg() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      data: {
        title: this.translations[
          `write.blocks.sidebar.regenerate.dialog.title`
        ] as string,
        content: this.translations[
          `write.blocks.sidebar.regenerate.dialog.message`
        ] as string,
        confirmMessage: this.translations[`default_buttons.confirm_`] as string,
        cancelMessage: this.translations[`default_buttons.cancel`] as string,
      },
    });
    dialogRef.componentInstance.dialogConfirmed.subscribe((v) => {
      if (v) this.regenerateMenu.emit();
    });
  }

  lineFocused(node: MenuDishNode, state: boolean): void {
    if (!node.dish.url && state) {
      this.nodesTemporaryFocused.add(node);
    } else if (this.nodesTemporaryFocused.has(node) && !state) {
      this.nodesTemporaryFocused.delete(node);
    }
    node.focused = state;
    if (
      !this.sidePanel.isMobile &&
      node.dish &&
      !this.isForbidden(node.dish) &&
      (node.dish.dish || node.dish.separator) &&
      node.dish.url &&
      (!this.currentElement?.show ||
        this.currentElement.node.dish.id !== node.dish.id)
    ) {
      this.setCurrentElement(undefined, node);
    }
  }

  previewMenu(): void {
    const menu = this.menu();
    this.showPreview.emit({
      url: this.menu().preview,
      baseLanguage: this.menu().base_language,
      langs: this.menu().translations_list.map((lang) => ({
        lang,
        activated: lang === this.menu().base_language,
        order: lang === this.menu().base_language ? 0 : null,
      })),
      numberLanguages: this.menu().template_detail.number_languages,
      htmlPreview:
        menu?.style?.print_reverse === false &&
        menu?.style?.print_mirror < 1 &&
        menu?.style?.print_folding === null,
    });
  }

  generateAiRecipes(dish: Dish): void {
    const onFulfilled = ({ dish }: { dish: Dish; recipes: Recipe[] }) => {
      const newMenudish = new MenuDish(this.currentElement.data);
      newMenudish.dish_detail = dish;
      this.refreshDish(newMenudish);
    };
    this.ngrxStore.dispatch(
      generateAiRecipes({
        url: dish.url,
        lang: this.menu().base_language,
        location: this.menu().location,
        onFulfilled,
      }),
    );
  }

  generateAiAllergens(dish: Dish): void {
    const onFulfilled = (d: Dish) => {
      const newMenudish = new MenuDish(this.currentElement.data);
      newMenudish.dish_detail = d;
      this.ngrxStore.dispatch(
        changeMenuDish({
          id: newMenudish.id,
          menuDish: newMenudish,
        }),
      );
      this.currentElement.data = newMenudish;
    };
    this.ngrxStore.dispatch(
      generateAiAllergens({
        url: dish.url,
        lang: this.menu().base_language,
        onFulfilled,
      }),
    );
  }

  generateAiDescription(dish: Dish): void {
    const onFulfilled = (d: Dish) => {
      const newMenudish = new MenuDish(this.currentElement.data);
      newMenudish.dish_detail = d;
      this.ngrxStore.dispatch(
        changeMenuDish({
          id: newMenudish.id,
          menuDish: newMenudish,
        }),
      );
      this.currentElement.data = newMenudish;
    };
    this.ngrxStore.dispatch(
      generateAiDescription({
        url: dish.url,
        lang: this.menu().base_language,
        onFulfilled,
      }),
    );
  }

  initTour(): void {
    this.autostartTour = false;
    this.tourService.end();
    if (this.isMobileView) this.hideSidePanel();
    if (this.searchIsActive) {
      this.searchIsActive = false;
      this.ngrxStore.dispatch(fetchMenuDishes({ params: { condensed: true } }));
    }

    // setup tour steps
    const steps = [
      'first-dish',
      'add-line',
      'menu-settings',
      'menu-fields',
      'menu-details',
    ];
    const specialOptions = {
      1: {
        delayBeforeStepShow: 300,
      },
    };
    const tourSteps: IMdStepOption[] = [];
    steps.forEach((step, index) => {
      tourSteps.push({
        anchorId: step,
        content: this.translate.translate(
          `tour.menu-write.${step}.description`,
        ),
        title: this.translate.translate(`tour.menu-write.${step}.title`),
        enableBackdrop: true,
        isAsync: true,
        prevBtnTitle: this.translate.translate('tour.navigation.prev'),
        nextBtnTitle: this.translate.translate('tour.navigation.next'),
        endBtnTitle: this.translate.translate('tour.navigation.end'),
        delayBeforeStepShow: 100,
        ...(specialOptions[index] || {}),
      });
    });

    // skip the first step if there are no items on the menu
    const menuDishes = this.menuDishes();
    if (menuDishes && !menuDishes.length) {
      this.tourService.initialize(tourSteps.slice(1));
    } else {
      this.tourService.initialize(tourSteps);
    }

    // start the tour
    this.tourService.start();

    // subscribe to events
    this.tourService.stepHide$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(({ direction, step }) => {
        // ensure menu actions are visible
        if (direction === 0) {
          this.resetCurrentElement();
        }
        // show/hide side panel on mobile view
        if (
          this.isMobileView &&
          ((step.anchorId === 'add-line' && direction === 0) ||
            (step.anchorId === 'menu-details' && direction === 1))
        ) {
          this.showSidePanel();
        } else if (
          this.isMobileView &&
          step.anchorId === 'menu-fields' &&
          direction === 0
        ) {
          this.hideSidePanel();
        }
      });
    // mark the tutorial as completed when the user ends the tour
    this.tourService.end$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.ngrxStore.dispatch(completeMenuWriteTutorial());
        this.updateUser.emit({
          tutorials: {
            menu_write: true,
          },
        });
      });
  }

  ngOnDestroy() {
    if (this.searchIsActive) this.searchDishes('');
    super.ngOnDestroy();
    this.destroyed$.next();
    this.destroyed$.complete();
    this.ngrxStore.dispatch(clearSimilarDishes());
    this.tourService.end();
    this.renderer.removeClass(document.body, 'node-under-creation');
  }
}
