import { sharedFeature } from 'src/app/shared/ngrx/shared.state';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SidePanelControllerBase } from 'src/app/shared/Classes/side-panel-controller.base';
import { ItemNameDialogComponent } from 'src/app/shared/Components/dialogs/item-name-dialog/item-name-dialog.component';
import { PreviewComponent } from 'src/app/shared/Components/pdf-preview/pdf-preview.component';
import {
  ContentLanguage,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { LangButton } from 'src/app/shared/Models/langButton';
import {
  BackgroundImage,
  Menu,
  PatchStyleNameData,
  Style,
} from 'src/app/shared/Models/menu';
import { Fulfillable } from 'src/app/shared/Models/models';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { User } from 'src/app/shared/Models/user';
import { uploadImage } from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { from, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  exhaustMap,
  filter,
  scan,
  takeUntil,
} from 'rxjs/operators';

import { uploadBackgroundImage } from '../ngrx/menu-edit.actions';
import { selectStyleModified } from '../ngrx/menu-edit.selectors';
import { BackgroundsLibraryComponent } from './backgrounds-library/backgrounds-library.component';
import {
  applyStyle,
  changeStyle,
  deleteStyle,
  patchStyle,
  saveStyle,
  setLayout,
  switchStyleTab,
  updateStyle,
  uploadLogoImage,
} from './ngrx/menu-style.actions';
import {
  selectEventStyles,
  selectLayoutsCategories,
  selectPublicLayouts,
  selectPublicStyles,
  selectSeparatorCourses,
  selectSeparatorOptions,
  selectSpecialLayouts,
  selectStyleCategories,
  selectStyleSpinner,
  selectSwitchLayoutTab,
  selectSwitchStyleTab,
  selectUserLayouts,
  selectUserStyles,
} from './ngrx/menu-style.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe, SlicePipe } from '@angular/common';
import { StyleSidebarComponent } from './style-sidebar/style-sidebar.component';
import { ReturnSidePanelMobileComponent } from '../../../shared/Components/return-side-panel-mobile/return-side-panel-mobile.component';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { SelectLayoutsComponent } from './select-layouts/select-layouts.component';
import { UpdateRuleComponent } from './update-rule/update-rule.component';
import { SelectStylesComponent } from './select-styles/select-styles.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mt-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
  imports: [
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    PreviewComponent,
    MatProgressSpinnerModule,
    SelectStylesComponent,
    UpdateRuleComponent,
    SelectLayoutsComponent,
    SelectTemplateComponent,
    ReturnSidePanelMobileComponent,
    StyleSidebarComponent,
    AsyncPipe,
    SlicePipe,
    TranslocoPipe,
  ],
})
export class StyleComponent
  extends SidePanelControllerBase
  implements AfterViewInit, OnChanges, OnInit, OnDestroy
{
  private dialog = inject(MatDialog);
  private utils = inject(UtilsService);

  readonly backgroundImages = input<BackgroundImage[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly lockedFields = input<string[]>(undefined);
  readonly menu = input.required<Menu>();
  readonly isTrial = input<boolean>(undefined);
  readonly profileComplete = input<boolean>(undefined);

  readonly nextStep = output<void>();
  readonly previousStep = output<void>();
  readonly linkMenuToTemplate = output<number>();
  readonly updateMenu = output<DeepPartial<Menu>>();
  readonly createNewTemplateDialog = output<void>();
  readonly unlink = output<void>();
  readonly updateStyleAndLayout = output<void>();
  readonly editTemplateDialog = output<{
    templateDetail: OnboardingTemplate;
  }>();
  readonly deleteBackground = output<BackgroundImage>();
  readonly deleteTemplate = output<{
    url: string;
    id: number;
  }>();
  readonly updateTemplateDetail = output<Partial<OnboardingTemplate>>();

  readonly preview = viewChild<PreviewComponent>('preview');

  updatePreviewEmitter = new EventEmitter<void>();

  currentUserData: User;
  private destroyed$ = new Subject<void>();
  isMobileView: boolean;
  langs: LangButton[] = [];

  courses$ = this.ngrxStore.select(selectSeparatorCourses);
  options$ = this.ngrxStore.select(selectSeparatorOptions);
  mtFonts$ = this.ngrxStore.select(sharedFeature.selectFonts);
  user$ = this.ngrxStore.select(selectUser);

  categories$ = this.ngrxStore.select(selectLayoutsCategories);
  eventLayouts$ = this.ngrxStore.select(selectSpecialLayouts);
  layoutTab$: Observable<number>;
  publicLayouts$ = this.ngrxStore.select(selectPublicLayouts);
  userLayouts$ = this.ngrxStore.select(selectUserLayouts);

  categoriesStyles$ = this.ngrxStore.select(selectStyleCategories);
  eventStyles$ = this.ngrxStore.select(selectEventStyles);
  publicStyles$ = this.ngrxStore.select(selectPublicStyles);
  showStyleSpinner$ = this.ngrxStore.select(selectStyleSpinner);
  styleModified$ = this.ngrxStore.select(selectStyleModified);
  styleTab$: Observable<number>;
  userStyles$ = this.ngrxStore.select(selectUserStyles);

  templates$ = this.ngrxStore.select(sharedFeature.selectCurrentTemplates);

  constructor() {
    super();
    this.user$.subscribe((v) => (this.currentUserData = v));
    from(this.updatePreviewEmitter)
      .pipe(
        scan((acc, curr) => (acc += 1), 1),
        exhaustMap((value) =>
          of(null).pipe(debounceTime(value === 1 ? 100 : 3000)),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.refreshPdf(true));
  }

  ngAfterViewInit(): void {
    if (this.sidePanel) this.sidePanel.topOffsetMobile = 0;
  }

  ngOnInit() {
    super.ngOnInit();
    this.utils.refreshPdfView
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.updatePreviewEmitter.emit());
    this.sidePanel.mobileViewSubject
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => (this.isMobileView = value));
    this.layoutTab$ = this.ngrxStore.select(selectSwitchLayoutTab).pipe(
      filter((index) => index !== null),
      takeUntil(this.destroyed$),
    );
    this.styleTab$ = this.ngrxStore.select(selectSwitchStyleTab).pipe(
      filter((index) => index !== null),
      takeUntil(this.destroyed$),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const menu = this.menu();
    if ('menu' in changes && menu) {
      this.langs = menu?.translations_list?.map((lang: ContentLanguage) => {
        return {
          lang,
          activated: lang === this.menu().base_language,
          order: lang === this.menu().base_language ? 0 : null,
        };
      });
      this.updatePreviewEmitter.emit();
    }
  }

  refreshPdf(holdPage = false) {
    const preview = this.preview();
    if (preview) preview.refresh(holdPage);
  }

  selectLayout(id: number): void {
    this.ngrxStore.dispatch(setLayout({ layoutId: id }));
  }

  showAllBackgrounds(field: 'background' | 'background_coverpage'): void {
    this.dialog.open(BackgroundsLibraryComponent, {
      autoFocus: false,
      data: {
        backgroundImages: this.backgroundImages(),
        currentBackground: this.menu()?.style?.[field],
        deleteBackground: (image: BackgroundImage) =>
          this.deleteBackground.emit(image),
        select: (id: number | null) =>
          this.updateMenu.emit({ style: { [field]: id } }),
      },
    });
  }

  uploadBackgroundImage(data: Fulfillable<File> & { field: string }) {
    this.ngrxStore.dispatch(
      uploadBackgroundImage({
        data,
        imageType: 1,
        params: { current_menu: this.menu().id },
        callback: (imageId: number) => {
          this.updateMenu.emit({ style: { [data.field]: imageId } });
        },
      }),
    );
  }

  uploadLogo(data: Fulfillable<File>): void {
    this.ngrxStore.dispatch(
      uploadLogoImage({
        data: data,
        url: this.menu().url,
        field: 'logo',
        lockedFields: this.menu().style?.locked_fields ?? [],
      }),
    );
  }

  createStyle(): void {
    this.ngrxStore.dispatch(saveStyle({ currentMenu: this.menu()?.id }));
  }

  deleteStyle({ url, id }: Style): void {
    this.ngrxStore.dispatch(
      deleteStyle({
        url: url,
        id: id,
        params: { current_menu: this.menu().id },
      }),
    );
  }

  openNameDialog(style: Style) {
    const subject = new Subject<Style>();
    this.dialog.open(ItemNameDialogComponent, {
      data: {
        adding: true,
        image: 'image',
        hideProgress: true,
        item: style,
        lang: this.lang(),
        onlyInterfaceLangs: true,
        title: 'style.choose-style.edit',
        currentItem: subject,
        clearImage: () => this.patchStyle({ style, value: { image: null } }),
        close: (value: Partial<Style>) => this.patchStyle({ style, value }),
        uploadImage: (data: Fulfillable<File>) => {
          const templateData = new FormData();
          templateData.append('image', data.payload);
          this.ngrxStore.dispatch(
            uploadImage({
              actions: [[changeStyle, 'style']],
              callback: (res: Style) => {
                subject.next(res);
                this.ngrxStore.dispatch(changeStyle({ newStyle: res }));
              },
              image: templateData,
              url: style.url,
              params: { current_menu: this.menu().id },
            }),
          );
        },
      },
      width: '600px',
      autoFocus: false,
    });
  }

  patchStyle({
    style,
    value,
  }: {
    style: Style;
    value: PatchStyleNameData & { image?: string };
  }) {
    this.ngrxStore.dispatch(
      patchStyle({
        url: style.url,
        data: value,
        params: { current_menu: this.menu().id },
      }),
    );
  }

  selectStyle(style: Style): void {
    this.ngrxStore.dispatch(
      applyStyle({
        showSpinner: true,
        style: style.id,
      }),
    );
  }

  tabChange(index: number): void {
    this.ngrxStore.dispatch(switchStyleTab({ index }));
  }

  updateStyle(): void {
    this.ngrxStore.dispatch(
      updateStyle({
        payload: {},
        params: { current_menu: this.menu().id },
      }),
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
