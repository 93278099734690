<div class="container">
  <hr />
  @if (isConsumerActive()) {
    {{ 'dashboard.customer_support.active-customer-click' | transloco }}
    <span class="here">{{
      'dashboard.customer_support.here' | transloco
    }}</span>
    {{ 'dashboard.customer_support.to' | transloco }}
    <span class="contact-team" (click)="showMessageOverlayEvent.emit(true)">{{
      'dashboard.customer_support.contact-support-team' | transloco
    }}</span>
  } @else {
    @if (isTrialActive()) {
      {{ 'dashboard.customer_support.trial-not-expired-click' | transloco }}
      <span (click)="showMessageOverlayEvent.emit(true)" class="here">{{
        'dashboard.customer_support.here' | transloco
      }}</span>
      {{ 'dashboard.customer_support.to' | transloco }}
      <span
        class="contact-team"
        (click)="showMessageOverlayEvent.emit(false)"
        >{{
          'dashboard.customer_support.import-existing-menu' | transloco
        }}</span
      >
      {{ 'dashboard.customer_support.or' | transloco }}
      {{ 'dashboard.customer_support.to' | transloco }}
      <span class="contact-team" (click)="showMessageOverlayEvent.emit(true)">{{
        'dashboard.customer_support.schedule-demo' | transloco
      }}</span>
      {{ 'dashboard.customer_support.with-Menutech-expert' | transloco }}
    } @else {
      {{ 'dashboard.customer_support.trial-expired-click' | transloco }}
      <span (click)="showMessageOverlayEvent.emit(true)" class="here">{{
        'dashboard.customer_support.here' | transloco
      }}</span>
      {{ 'dashboard.customer_support.to' | transloco }}
      <span
        class="contact-team"
        (click)="showMessageOverlayEvent.emit(false)"
        >{{
          'dashboard.customer_support.contact-support-team' | transloco
        }}</span
      >
    }
  }
</div>
