<h3 class="mat-h3">
  {{ 'menus.overview.refresh_sub_menu.dialog.title' | transloco }}
</h3>
<div
  [innerHTML]="'menus.overview.refresh_sub_menu.dialog.message' | transloco"
></div>

<div class="actions">
  <button mat-button class="cancel" (click)="cancelAction.emit()">
    {{ 'menus.overview.duplicate.dialog.actions.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="done"
    (click)="refreshSubMenuActions.emit()"
  >
    {{ 'menus.overview.duplicate.dialog.actions.confirm' | transloco }}
  </button>
</div>
