import { Component, inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-menu-analysis',
  templateUrl: './menu-analysis.component.html',
  styleUrls: ['./menu-analysis.component.scss'],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    TranslocoPipe,
  ],
})
export class MenuAnalysisComponent {
  dialogRef = inject<MatDialogRef<MenuAnalysisComponent>>(MatDialogRef);
  data = inject(MAT_DIALOG_DATA);
}
