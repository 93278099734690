import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import {
  ContentLanguage,
  InterfaceLanguage,
  rtlLangs,
} from 'src/app/shared/constants/languages';
import {
  Dish,
  SimpleDishAdditives,
  SimpleDishAllergens,
  Spellcheck,
} from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import { BackgroundImage } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { Fulfillable } from 'src/app/shared/Models/models';
import {
  Recipe,
  RecipeIngredient,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { Separator } from 'src/app/shared/Models/separator';
import { AiCreditsRemaining, User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { take } from 'rxjs/operators';
import { GrammarCorrectionsComponent } from '../../../../shared/Components/grammar-corrections/grammar-corrections.component';
import { OtherTabComponent } from './other-tab/other-tab.component';
import { RecipesTabComponent } from '../../../../shared/Components/recipes-tab/recipes-tab.component';
import { DishInformationComponent } from './item-tab/item-tab.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SidePanelHeaderComponent } from '../../../../shared/Components/sidepanel/side-panel-header/side-panel-header.component';
import { GoBackComponent } from '../../../../shared/Components/sidepanel/go-back/go-back.component';
import { SpinnerComponent } from 'src/app/shared/Components/spinner/spinner.component';

@Component({
  selector: 'write-sidebar',
  templateUrl: './write-sidebar.component.html',
  styleUrls: ['./write-sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    GoBackComponent,
    SidePanelHeaderComponent,
    MatTabsModule,
    DishInformationComponent,
    RecipesTabComponent,
    OtherTabComponent,
    GrammarCorrectionsComponent,
    SpinnerComponent,
    TranslocoPipe,
  ],
})
export class WriteSidebarComponent implements OnChanges {
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);

  readonly aiCreditsRemaining = input<AiCreditsRemaining>(undefined);
  readonly aiAllergensLoading = input<boolean>(undefined);
  readonly aiDescriptionLoading = input<boolean>(undefined);
  readonly aiRecipesLoading = input<boolean>(undefined);
  readonly autoRecipes = input<Recipe[]>(undefined);
  readonly currentLocation = input.required<number | null>();
  readonly recipes = input<Recipe[]>(undefined);
  readonly user = input<User>(undefined);
  readonly dish = input<MenuDish>(undefined);
  readonly refreshedDish = input<MenuDish>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly userSettings = input<any>(undefined);
  readonly currencySymbol = input<string>(undefined);
  readonly similarDishes = input<SimpleDishAllergens[]>(undefined);
  readonly similarDishesAdditives = input<SimpleDishAdditives[]>(undefined);
  readonly numberOfDishes = input<number>(undefined);
  readonly numberOfDishesAdditives = input<number>(undefined);
  readonly interfaceLang = input<InterfaceLanguage>(undefined);
  readonly backgroundImages = input<BackgroundImage[]>(undefined);
  readonly spellcheckItem = input<Spellcheck>(undefined);
  readonly profileComplete = input<boolean>(undefined);
  readonly isTrial = input<boolean>(undefined);

  readonly addWord = output<string>();
  readonly ignoreWord = output<void>();
  readonly uploadDishImage = output<Fulfillable<FormData>>();
  readonly otherChanged = output<Fulfillable<MenuDish>>();
  readonly optionChanged = output<{
    data: DeepPartial<Dish> | DeepPartial<Ingredient>;
    type: 'allergens' | 'additives' | 'labels';
  }>();
  readonly modalOpened = output<{
    item: Dish | Ingredient;
    type: 'allergens' | 'additives';
    contentLang: ContentLanguage;
  }>();
  readonly loadMoreDishes = output<string>();
  readonly refreshDish = output<MenuDish>();
  readonly fetchDishRecipes = output<string>();
  readonly createRecipe = output<{
    data: Partial<Recipe>;
    url: string;
  }>();
  readonly patchRecipe = output<{
    url: string;
    payload: Partial<Recipe>;
    onFulfilled: () => void;
  }>();
  readonly searchRecipe = output<Partial<RecipeParams>>();
  readonly addRecipe = output<{
    dish: Dish;
    recipeId: number;
  }>();
  readonly removeRecipe = output<any>();
  readonly changeItemField = output<{
    menuDish: MenuDish;
    data: DeepPartial<Dish | Separator> & {
      onFulfilled?: () => void;
    };
  }>();
  readonly changeMenudishField = output<{
    dish: MenuDish;
    data: DeepPartial<MenuDish> & {
      onFulfilled?: () => void;
    };
  }>();
  readonly synchroniseRecipeDeclarations = output<{
    dish: MenuDish;
    type: 'all' | 'add';
  }>();
  readonly clearSelectedDish = output();
  readonly uploadBackgroundImage = output<Fulfillable<File>>();
  readonly uploadBackgroundImageOtherTab = output<Fulfillable<File>>();
  readonly showAllBackgrounds = output<{
    menudish: boolean;
    dish: MenuDish;
  }>();
  readonly addIngredientEvent = output<Recipe>();
  readonly deleteIngredientEvent = output<{
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }>();
  readonly searchIngredients = output<string>();
  readonly selectedIngredientEvent = output<{
    ingredient_id: number;
    recipe: Recipe;
  }>();
  readonly createIngredientEvent = output<{
    newIngredient: Partial<Recipe>;
    recipe: Recipe;
  }>();
  readonly updateRecipeIngredientEvent = output<{
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: () => void;
    };
  }>();
  readonly fetchSpellcheck = output<number>();
  readonly updateIngredientEvent = output<{
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
    };
  }>();
  readonly generateAiAllergens = output<Dish>();
  readonly generateAiDescription = output<Dish>();
  readonly generateAiRecipes = output<Dish>();

  allDishesAllergensNumber = 0;
  restAllergens = 0;
  allDishesAdditivesNumber = 0;
  restAdditives = 0;
  category = 'dish';
  private categories = {
    dis: 'dish',
    win: 'wine',
    sec: 'section',
    dow: 'day',
    bev: 'beverage',
  };
  translations: any = {};
  rtl = false;
  showGrammar = false;

  ngOnChanges(changes: SimpleChanges) {
    const lang = this.lang();
    if ('lang' in changes && lang) {
      this.rtl = rtlLangs.includes(lang);
    }
    this.allDishesAllergensNumber = this.numberOfDishes() - 1;
    const similarDishes = this.similarDishes();
    this.restAllergens =
      this.allDishesAllergensNumber -
      (similarDishes ? similarDishes.length : 0);
    this.allDishesAdditivesNumber = this.numberOfDishesAdditives() - 1;
    const similarDishesAdditives = this.similarDishesAdditives();
    this.restAdditives =
      this.allDishesAdditivesNumber -
      (similarDishesAdditives ? similarDishesAdditives.length : 0);
    const dish = this.dish();
    if ('dish' in changes && dish) {
      const details: Dish | Separator =
        dish.dish_detail || dish.separator_detail;
      this.category = this.categories[details.category];

      const spellcheckId = details?.[`spellcheck_` + lang];
      this.showGrammar = !!spellcheckId;
      if (this.showGrammar && changes.dish.previousValue?.id !== dish.id) {
        this.fetchSpellcheck.emit(spellcheckId);
      }
    }
    const interfaceLang = this.interfaceLang();
    if ('interfaceLang' in changes && interfaceLang) {
      this.translate
        .selectTranslation(interfaceLang)
        .pipe(take(1))
        .subscribe((translations) => (this.translations = translations));
    }
  }

  hasModule = (code): boolean => this.utils.hasModules(code);
}
