import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'delete-menu',
  templateUrl: './delete-menu.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./delete-menu.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, TranslocoPipe],
})
export class DeleteMenuComponent {
  readonly deleteMenuWarning = input<string>(undefined);
  readonly cancelAction = output<void>();
  readonly deleteSelectedMenu = output<void>();
}
