import { HttpParams } from '@angular/common/http';
import { ContentLanguage } from './constants/languages';
import { GeneralFormErrors } from './Models/authentication';
import { MenuDish } from './Models/menudish';

export function extractGeneralFormErrors(errors: object): GeneralFormErrors {
  return Object.entries(errors).reduce(
    (acc: GeneralFormErrors, curr: [string, string[] | string]) => {
      if (Array.isArray(curr[1])) {
        acc[curr[0]] = curr[1][0]; // error is an array, so we use first element of the array as error message
      } else {
        acc[curr[0]] = curr[1]; // error is a string, so we simply add it to the accumulated object
      }
      return acc;
    },
    {},
  );
}

export function flattenObject(
  obj: object | any[],
  name?: string,
): Record<string, any> {
  if (Array.isArray(obj)) {
    return obj.reduce((acc: Record<string, any>, curr: any, index: number) => {
      const newName = name ? `${name}_${index}` : index.toString();
      if (curr === obj) {
      } else if (Array.isArray(curr) || curr instanceof Object) {
        return {
          ...acc,
          ...flattenObject(curr, newName),
        };
      }
      acc[newName] = curr;
      return acc;
    }, {});
  } else if (obj instanceof Object) {
    return Object.entries(obj).reduce((acc, curr) => {
      const newName = name ? `${name}_${curr[0]}` : curr[0];
      if (curr[1] === obj) {
      } else if (Array.isArray(curr[1]) || curr[1] instanceof Object) {
        return {
          ...acc,
          ...flattenObject(curr[1], newName),
        };
      }
      if (name) {
        acc[`${name}_${curr[0]}`] = curr[1];
      } else {
        acc[curr[0]] = curr[1];
      }
      return acc;
    }, {});
  }
  return obj;
}

export function getISOLang(lang: ContentLanguage): string {
  switch (lang) {
    case 'de':
      return 'de-DE';
    case 'en':
      return 'en-US';
    case 'fr':
      return 'fr-FR';
    case 'it':
      return 'it-IT';
    case 'es':
      return 'es-ES';
    default:
      return 'en-US';
  }
}

export function getFullURL(url: string, params: object): string {
  let httpParams = new HttpParams();
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((val: string) => {
        if (httpParams.has(key)) {
          httpParams = httpParams.append(key, val);
        } else {
          httpParams = httpParams.set(key, val);
        }
      });
    } else {
      httpParams = httpParams.set(key, String(value));
    }
  }
  return params ? `${url}?${httpParams.toString()}` : url;
}

export function openGoogleTranslate(
  data: MenuDish,
  baseLang: ContentLanguage,
  lang: ContentLanguage,
  isDescription = false,
  isOther = false,
  isSecondary = false,
) {
  const dishType = ['dish_detail', 'separator_detail'];
  const field = data.dish_detail ? dishType[0] : dishType[1];
  const name = isOther
    ? data.user_details[
        `description_${isSecondary ? 'secondary_' : ''}${baseLang}`
      ]
    : data[field]
      ? isDescription
        ? data[field].user_details[
            `description_${isSecondary ? 'secondary_' : ''}${baseLang}`
          ]
        : data[field][baseLang]
      : data[baseLang];
  const link = `https://translate.google.com/#${baseLang}/${lang}/${name}`;
  window.open(link, 'MsgWindow', 'width=778,height=480');
}

export function processPictogram(pictogram: string[] | null): string {
  let pictogramSvg: string = null;
  if (pictogram?.length) {
    // Clean up the SVG content
    pictogramSvg = pictogram[0]
      .replace('<?xml version="1.0" encoding="UTF-8"?>', '')
      .trim();

    // Ensure the SVG has proper namespace
    if (!pictogramSvg.includes('xmlns=')) {
      pictogramSvg = pictogramSvg.replace(
        '<svg',
        '<svg xmlns="http://www.w3.org/2000/svg"',
      );
    }
  }
  return pictogramSvg;
}
