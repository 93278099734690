<div class="trial-express">
  <button
    mat-icon-button
    aria-label="close"
    class="trial-express-close"
    (click)="closePanel.emit()"
  >
    <mat-icon class="trial-express-icon">close</mat-icon>
  </button>
  <div class="trial-express-wrapper">
    <h3 class="trial-express-header">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="614.401px"
        height="614.404px"
        viewBox="545.2 -721.198 614.401 614.404"
        enable-background="new 545.2 -721.198 614.401 614.404"
        xml:space="preserve"
        class="express-filter-icon overlayed"
      >
        <g>
          <path
            d="M545.2-186.67c-0.002,44.112,35.756,79.874,79.868,79.876c21.201,0.001,41.533-8.428,56.516-23.428
  l342.528-342.464c10.003-9.992,10.012-26.201,0.021-36.204c-0.007-0.007-0.014-0.014-0.021-0.021l-76.8-76.8
  c-9.992-10.003-26.201-10.012-36.204-0.02c-0.007,0.006-0.014,0.013-0.021,0.02L568.624-243.182
  C553.566-228.24,545.128-207.884,545.2-186.67z M929.2-531.374l40.576,40.576L916.4-437.422l-40.576-40.576L929.2-531.374z
   M604.784-207.022l234.816-234.751l40.576,40.576L645.425-166.382c-11.223,11.223-29.418,11.223-40.641,0
  S593.562-195.8,604.784-207.022L604.784-207.022L604.784-207.022z"
          />
          <path
            d="M1134-541.998h-51.199c-14.139,0-25.601,11.461-25.601,25.6c0,14.138,11.462,25.6,25.601,25.6H1134
  c14.139,0,25.601-11.462,25.601-25.6C1159.601-530.537,1148.139-541.998,1134-541.998z"
          />
          <path
            d="M980.4-644.398v-51.2c0-14.138-11.462-25.6-25.6-25.6c-14.139,0-25.601,11.462-25.601,25.6v51.2
  c0,14.138,11.462,25.6,25.601,25.6C968.938-618.798,980.4-630.26,980.4-644.398z"
          />
          <path
            d="M1106.225-631.598c10.003-10.003,10.003-26.221,0-36.224s-26.221-10.003-36.225,0l0,0l-36.224,36.224
  c-10.003,10.003-10.003,26.221,0,36.224s26.221,10.003,36.224,0L1106.225-631.598z"
          />
          <path
            d="M857.713-587.886c14.138,0.011,25.608-11.441,25.619-25.58c0.006-6.802-2.695-13.326-7.508-18.132
  l-36.224-36.224c-10.003-10.003-26.222-10.003-36.224,0c-10.004,10.003-10.004,26.221,0,36.224l36.224,36.224
  C844.405-590.574,850.921-587.881,857.713-587.886z"
          />
          <path
            d="M1106.225-401.198L1070-437.422c-10.003-10.003-26.221-10.003-36.224,0s-10.003,26.221,0,36.224
  L1070-364.974c10.004,10.003,26.222,10.003,36.225,0S1116.228-391.195,1106.225-401.198z"
          />
        </g>
      </svg>
      {{ 'translations.express-trial.title' | transloco }}
    </h3>
    <p class="trial-express-content">
      {{
        'translations.express-trial.content'
          | transloco
            : {
                currency_symbol: (depFiltered ?? deps())?.currencySymbol,
                min_price: (depFiltered ?? deps())?.minPrice / 100,
              }
      }}
    </p>
  </div>
  <div class="trial-express-actions">
    <button
      class="trial-express-actions-cancel"
      mat-flat-button
      (click)="completeProfile.emit(); closePanel.emit()"
    >
      {{ 'default_buttons.complete_profile' | transloco }}
    </button>
    <button
      class="trial-express-actions-upgrade"
      mat-flat-button
      color="primary"
      (click)="actionClicked.emit(); closePanel.emit()"
    >
      <mat-icon class="icon-lock">lock_open</mat-icon
      >{{ 'translations.express-trial.upgrade' | transloco }}
    </button>
  </div>
</div>
