import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { NgModel, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { CondensedDish, Dish, DishCategory } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { MenuDishNode } from 'src/app/shared/Models/menu-dish-node';
import {
  ChangeDishOptions,
  MenuDish,
  SimpleMenuDish,
} from 'src/app/shared/Models/menudish';
import { Fulfillable } from 'src/app/shared/Models/models';
import { CondensedSeparator, Separator } from 'src/app/shared/Models/separator';
import { User } from 'src/app/shared/Models/user';

import { DishComponent } from './dish-item/dish-item.component';
import { SectionItemComponent } from './section-item/section-item.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SeparatorItemComponent } from './separator-item/separator-item.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    DishComponent,
    SeparatorItemComponent,
    SectionItemComponent,
    MatProgressBarModule,
    MatMenuModule,
    TourMatMenuModule,
    TranslocoPipe,
  ],
})
export class MenuItemComponent implements OnChanges {
  private changeDetector = inject(ChangeDetectorRef);

  readonly autocompleteDishes = input<CondensedDish[]>(undefined);
  readonly autocompleteSections = input<CondensedSeparator[]>(undefined);
  readonly searchIsActive = input<boolean>(undefined);
  readonly forbiddenDishes = input<number[]>(undefined);
  readonly forbiddenSections = input<number[]>(undefined);
  readonly inProgress = input(false);
  readonly insertItemMenu = input<MatMenu>(undefined);
  readonly isExpanded = input<boolean>(undefined);
  readonly isExtendedLang = input<boolean>(undefined);
  readonly isDragable = input<boolean>(undefined);
  readonly isSorting = input<boolean>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly node = input<MenuDishNode>(undefined);
  readonly selected = input<boolean>(undefined);
  readonly showNumbering = input<boolean>(undefined);
  readonly translations = input<any>(undefined);
  readonly user = input<User>(undefined);

  readonly autocomplete = output<{
    item: CondensedDish | CondensedSeparator;
    node: MenuDishNode;
    isDish: boolean;
  }>();
  readonly changeDish = output<{
    node: MenuDishNode;
    data: Fulfillable<MenuDish>;
    options: ChangeDishOptions;
  }>();
  readonly changeDraggable = output<boolean>();
  readonly changeNumbering = output<NgModel>();
  readonly clearAutocomplete = output<string>();
  readonly delete = output<void>();
  readonly getSame = output<{
    value: string;
    category: DishCategory;
  }>();
  readonly getSameSection = output<string>();
  readonly lineFocused = output<boolean>();
  readonly setCurrentElement = output<Event>();
  readonly toggleCurrentElement = output<Event>();
  readonly toggleSection = output<void>();

  expansionHeight: string;
  forbidden: boolean;
  rtl = false;

  ngOnChanges(changes: SimpleChanges): void {
    const lang = this.lang();
    if ('lang' in changes && lang) {
      this.rtl = rtlLangs.includes(lang);
    }
    const node = this.node();
    if (
      'node' in changes &&
      (node.dish?.dish_detail || node.dish?.separator_detail)
    ) {
      this.forbidden = this.isForbidden(node.dish);
      // FIXME: this field is not included anymore on a SimpleMenuDish
      if ((node.dish as MenuDish)?.user_details?.display_options === 1) {
        this.changeDetector.detectChanges();
      }
    }
  }

  changeDishItem({
    item,
    node,
    options,
  }: {
    item: DeepPartial<Dish>;
    node: MenuDishNode;
    options: ChangeDishOptions;
  }): void {
    const newMenuDish = new MenuDish(node.dish);
    newMenuDish.setDish(item as Dish);
    this.changeDish.emit({
      node,
      data: { payload: newMenuDish },
      options,
    });
  }

  changeSectionItem({
    item,
    node,
    options,
  }: {
    item: DeepPartial<Separator>;
    node: MenuDishNode;
    options: ChangeDishOptions;
  }): void {
    const newMenuDish = new MenuDish(node.dish);
    newMenuDish.setSeparator(item as Separator);
    this.changeDish.emit({
      node,
      data: { payload: newMenuDish },
      options,
    });
  }

  isForbidden(dish: SimpleMenuDish | MenuDish): boolean {
    const detail = dish.dish_detail || dish.separator_detail;
    if (detail.category === 'cou' || detail.category === 'opt') return true;
    return dish.dish_detail
      ? this.forbiddenDishes().some((id) => id === detail.id)
      : this.forbiddenSections().some((id) => id === detail.id);
  }

  toggleSectionCollapse(): void {
    this.toggleSection.emit();
  }
}
