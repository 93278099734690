import {
  Component,
  ElementRef,
  OnChanges,
  SimpleChanges,
  viewChild,
  input,
} from '@angular/core';

@Component({
  selector: 'app-html-preview',
  templateUrl: './html-preview.component.html',
  styleUrls: ['./html-preview.component.scss'],
  standalone: true,
})
export class HtmlPreviewComponent implements OnChanges {
  readonly htmlPreview = input('');
  readonly iframe = viewChild<ElementRef>('iframe');

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      const iframe = this.iframe();
      const htmlPreview = this.htmlPreview();
      if ('htmlPreview' in changes && htmlPreview && iframe) {
        // Remove the existing iframe from the DOM
        const parentElement: HTMLElement = iframe.nativeElement.parentElement;
        parentElement.removeChild(iframe.nativeElement);

        // Create a new iframe element
        const newIframe = document.createElement('iframe');
        newIframe.setAttribute('frameborder', '0');
        newIframe.setAttribute('width', '100%');
        newIframe.setAttribute('style', 'height: 70vh; min-height: 300px');
        newIframe.style.display = 'block';
        newIframe.style.boxShadow =
          '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)';
        iframe.nativeElement = newIframe; // Update the ViewChild reference

        // Add the new iframe to the DOM
        parentElement.appendChild(newIframe);

        // Write the new content to the new iframe
        const iframeDoc =
          newIframe.contentDocument || newIframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(htmlPreview);
        iframeDoc.close();
      }
    });
  }
}
