import { Component, input } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { BidiModule } from '@angular/cdk/bidi';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'origin-field',
  templateUrl: './origin-field.component.html',
  styleUrls: ['./origin-field.component.css'],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    BidiModule,
    MatSelectModule,
    MatOptionModule,
    StopPropagationDirective,
    TranslocoPipe,
  ],
})
export class OriginFieldComponent {
  readonly formGroup = input<UntypedFormGroup>(undefined);
  readonly loadingBars = input<any>(undefined);
  readonly translations = input<any>({});

  options = [
    null,
    'wild',
    'farmed',
    'free',
    'caged',
    'floor',
    'organ',
    'biodyn',
  ];
}
