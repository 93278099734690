import { Component, OnInit, inject, output, input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { LoginForm } from 'src/app/shared/Models/authentication';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css'],
  imports: [
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SpinnerComponent,
    RouterLink,
    TranslocoPipe,
  ],
})
export class AuthenticationComponent implements OnInit {
  private fb = inject(UntypedFormBuilder);
  private utils = inject(UtilsService);

  readonly lang = input<{
    current: string;
  }>(undefined);
  readonly showError = input<boolean>(undefined);
  readonly spinnerState = input<boolean>(undefined);
  readonly loginUser = output<LoginForm>();

  authenticationForm: UntypedFormGroup;
  error: string;

  constructor() {
    this.utils.getTranslation(
      'user.blocked.message',
      (msg) => (this.error = msg),
    );
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(): void {
    this.authenticationForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  submitUserData(): void {
    this.loginUser.emit(this.authenticationForm.value);
  }
}
