<mat-form-field class="form-field" [formGroup]="form()">
  @if (!select.value) {
    <mat-label>{{
      'write.blocks.dish-overview.information.' + field() + '.' + data()[0].text
        | transloco
    }}</mat-label>
  }
  <mat-select #select [formControlName]="field()">
    @for (item of data(); track item) {
      <mat-option stop-propagation [value]="item.value">
        {{
          'write.blocks.dish-overview.information.' + field() + '.' + item.text
            | transloco
        }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
