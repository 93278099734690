import { DeepPartial } from './../../Models/generics';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';
import {
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Categories } from 'src/app/shared/constants/categories';
import {
  ContentLanguage,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';
import { Additive, Allergen, Label } from 'src/app/shared/Models/declarations';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import {
  clearSimilarDishes,
  fetchSimilarDishes,
} from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { OptionsComponent } from './options/options.component';
import { OptionPopoverComponent } from './option-popover/option-popover.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { processPictogram } from '../../utils.functions';
import { SafePipe } from '../../Pipes/safe.pipe';

@Component({
  selector: 'declarations',
  templateUrl: './declarations.component.html',
  styleUrls: ['./declarations.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    OptionPopoverComponent,
    OptionsComponent,
    AsyncPipe,
    SafePipe,
    TranslocoPipe,
  ],
})
export class DeclarationsComponent implements OnChanges {
  private utils = inject(UtilsService);
  protected ngrxStore = inject<Store<State>>(Store);

  readonly item = input<Dish | Ingredient>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly contentLang = input<ContentLanguage>(undefined);
  readonly dishDeclarations = input(true);
  readonly hasRecipes = input<boolean>(undefined);
  readonly recipes = input<Recipe[]>(undefined);

  readonly changeOption = output<{
    data: DeepPartial<Dish> | DeepPartial<Ingredient>;
    type: 'allergens' | 'additives' | 'labels';
  }>();
  readonly fetchRecipes = output<void>();
  readonly showModal = output<{
    item: Dish | Ingredient;
    type: 'allergens' | 'additives';
    contentLang: ContentLanguage;
  }>();
  readonly synchroniseRecipeDeclarations = output<{
    dish: Dish | Ingredient;
    type: 'all' | 'add';
  }>();
  readonly searchIngredients = output<string>();
  readonly deleteIngredientEvent = output<{
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }>();
  readonly addIngredientEvent = output<Recipe>();
  readonly selectedIngredientEvent = output<{
    ingredient_id: number;
    recipe: Recipe;
  }>();
  readonly createIngredientEvent = output<{
    newIngredient: Partial<Recipe>;
    recipe: Recipe;
  }>();
  readonly updateRecipeIngredientEvent = output<{
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: () => void;
    };
  }>();
  readonly updateIngredientEvent = output<{
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
    };
  }>();

  numberSimilarAdditives$ = this.ngrxStore.select(
    sharedFeature.selectSimilarDishesAdditivesCount,
  );
  numberSimilarAllergens$ = this.ngrxStore.select(
    sharedFeature.selectSimilarDishesAllergensCount,
  );

  additives: Additive[] = [];
  additivesView = false;
  allergens: Allergen[] = [];
  allergensView = false;
  labels: Label[] = [];
  labelsView = false;
  moduleMap = {
    allergens: 'all',
    additives: 'add',
  };
  showDependants = false;

  ngOnChanges(changes: SimpleChanges) {
    const item = this.item();
    if ('item' in changes && item) {
      this.additives = item.additives_contained.map((additive) => {
        const { pictogram, ...rest } = additive;
        const result = new Additive({
          pictogram: processPictogram(pictogram),
          ...rest,
        });
        return result;
      });
      this.allergens = item.allergens_contained.map((allergen) => {
        const { pictogram, ...rest } = allergen;
        const result = new Allergen({
          pictogram: processPictogram(pictogram),
          ...rest,
        });
        return result;
      });
      this.labels = item.labels_contained.map((label) => {
        const { pictogram, ...rest } = label;
        const result = new Label({
          pictogram: processPictogram(pictogram),
          ...rest,
        });
        return result;
      });
      if (
        this.dishDeclarations() &&
        changes.item.previousValue?.id !== item.id
      ) {
        if (item.category !== Categories.WINE) {
          this.getSimilarItems(item as Dish);
        } else {
          this.clearSimilarItems();
        }
      }
    }
  }

  clearSimilarItems() {
    this.ngrxStore.dispatch(clearSimilarDishes());
  }

  getSimilarItems(item: Dish) {
    const contentLang = this.contentLang();
    const lookupLang = contentLang ? contentLang : this.lang();
    for (const decl of ['allergens', 'additives']) {
      if (this.item()[`${decl}_contained`] === undefined) return undefined;
      if (this.hasModules(this.moduleMap[decl])) {
        this.ngrxStore.dispatch(
          fetchSimilarDishes({
            name: item[lookupLang],
            lang: lookupLang,
            containsOption: decl,
            dish: item,
          }),
        );
      }
    }
  }

  hasModules = (code: string | string[]): boolean =>
    this.utils.hasModules(code);

  showAdditives() {
    this.additivesView = !this.additivesView;
  }

  showAllergens() {
    this.allergensView = !this.allergensView;
  }

  showLabels() {
    this.labelsView = !this.labelsView;
  }
}
