<form [formGroup]="variantsForm">
  @for (
    item of variantsForm.get('variants')['controls'];
    track item;
    let i = $index
  ) {
    <div
      class="variants-wrapper button-density-3 icon-button-density-2"
      formArrayName="variants"
    >
      <div class="variant" [formGroupName]="i">
        @if (overwritten()) {
          <span
            [mtTooltip]="
              'write.blocks.dish-overview.information.overwritten' | transloco
            "
            class="overwritten-info"
          ></span>
        }
        <div class="price-field simple">
          <mat-form-field class="price-input">
            <span matPrefix>{{ currencySymbol() }}&nbsp;</span>
            <mat-label>{{
              'write.blocks.dish-overview.information.prices.variant-price.price.placeholder'
                | transloco
            }}</mat-label>
            <input
              matInput
              autocomplete="off"
              class="price"
              formControlName="price"
              name="price"
              max="999999"
              maxlength="6"
              min="0"
              pattern="[0-9]*(\.|,){0,1}[0-9]+"
              (keydown.enter)="blurInput($event)"
            />
          </mat-form-field>
        </div>
        <div class="text-field simple" [attr.dir]="rtl ? 'rtl' : 'ltr'">
          <mat-form-field class="price-input">
            <mat-label>{{
              'write.blocks.dish-overview.information.prices.variant-price.text-field.placeholder'
                | transloco
            }}</mat-label>
            <input
              matInput
              autocomplete="off"
              name="text"
              maxlength="100"
              type="text"
              [formControlName]="lang()"
              (keydown.enter)="blurInput($event)"
            />
          </mat-form-field>
        </div>
        <button
          mat-icon-button
          class="remove-button"
          (click)="removeVariant(i)"
        >
          <mat-icon class="remove-icon">delete_forever</mat-icon>
        </button>
      </div>
      @if (showLoading) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </div>
  }
  <button mat-button class="add-variant-button blue" (click)="addVariant()">
    <mat-icon class="plus-icon">add_circle</mat-icon>
    {{
      'write.blocks.dish-overview.information.prices.actions.add-variant'
        | transloco
    }}
  </button>
</form>
