import { Component, inject, output, viewChild, input } from '@angular/core';
import {
  MatDatepicker,
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { Router, RouterLink } from '@angular/router';
import { Store } from '@ngrx/store';
import { resendEmailConfirmation } from 'src/app/auth/ngrx/auth.actions';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  AccountStatusMessage,
  ButtonActions,
} from 'src/app/shared/Models/models';
import { UserOnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { Status as UserStatus, User } from 'src/app/shared/Models/user';
import {
  EventBusService,
  SharedEventTypes,
} from 'src/app/shared/Services/event-bus/event-bus.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  selectLimitTemplates,
  selectUserPrivileges,
} from 'src/app/shared/user/ngrx/user.selectors';

import {
  selectMainStatus,
  selectMenuStatus,
  selectTemplateStatus,
  selectTemplatesUsed,
} from './../../user/ngrx/user.selectors';
import { TranslocoPipe } from '@jsverse/transloco';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { UserOnboardingTemplateComponent } from './user-onboarding-template/user-onboarding-template.component';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'status-block',
  templateUrl: './status-block.component.html',
  styleUrls: ['./status-block.component.scss'],
  imports: [
    MatCardModule,
    MatIconModule,
    NgClass,
    MatButtonModule,
    RouterLink,
    UserOnboardingTemplateComponent,
    MtTooltipDirective,
    MatDatepickerModule,
    MatMenuModule,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class StatusBlockComponent {
  private router = inject(Router);
  private utils = inject(UtilsService);
  private eventBus = inject(EventBusService);
  private ngrxStore = inject<Store<State>>(Store);

  readonly complete = input(undefined);
  readonly menusCreated = input(undefined);
  readonly status = input<UserStatus>(undefined);
  readonly steps = input<Partial<AccountStatusMessage>>(undefined);
  readonly user = input<User>(undefined);
  readonly userOnboardingTemplates = input<UserOnboardingTemplate[]>(undefined);

  readonly createMenuFromOnboardingTemplate = output<{
    date: Date;
    template: number;
  }>();

  currentTemplate: number;
  lang: InterfaceLanguage;
  latestOnboardingTemplate: UserOnboardingTemplate;

  templatesUsed$ = this.ngrxStore.select(selectTemplatesUsed);
  limitTemplates$ = this.ngrxStore.select(selectLimitTemplates);
  menuStatus$ = this.ngrxStore.select(selectMenuStatus);
  privileges$ = this.ngrxStore.select(selectUserPrivileges);
  templateStatus$ = this.ngrxStore.select(selectTemplateStatus);
  userStatus$ = this.ngrxStore.select(selectMainStatus);

  readonly matDatepicker = viewChild<MatDatepicker<Date>>(`datepicker`);
  readonly onboardingTemplatesTrigger = viewChild(MatMenuTrigger);

  constructor() {
    this.utils.getLang((lang: InterfaceLanguage) => (this.lang = lang));
  }

  openMenu(): void {
    this.eventBus.emit({ type: SharedEventTypes.TriggerCreateMenuButton });
  }

  next(): void {
    const steps = this.steps();
    if (steps?.link?.route) {
      this.router.navigate(steps.link.route, {
        queryParams: steps.link.query,
      });
      return undefined;
    }
    if (steps?.link?.function) return steps.link.function();
    if (steps.link) this.utils.getNativeWindow().open(steps.link, '_self');
    if (steps.buttonAction === ButtonActions.showTemplatesMenu) {
      this.onboardingTemplatesTrigger()?.openMenu();
    }
  }

  openDatepicker(template: UserOnboardingTemplate): void {
    if (template?.menu_detail) {
      this.router.navigate(['menus', template?.menu_detail?.id, 4]);
      return undefined;
    }
    this.latestOnboardingTemplate = template;
    if (template?.onboarding_template_detail?.ask_date) {
      this.matDatepicker().open();
    } else {
      this.dateSelected({ value: new Date() });
    }
  }

  dateSelected(date: MatDatepickerInputEvent<Date> | { value: Date }): void {
    this.createMenuFromOnboardingTemplate.emit({
      date: date?.value,
      template: this.latestOnboardingTemplate.onboarding_template_detail?.id,
    });
  }

  resendEmailConfirmation = (): void => {
    this.ngrxStore.dispatch(resendEmailConfirmation());
  };
}
