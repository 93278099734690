<h2
  mat-dialog-title
  class="modal-header"
  [innerHtml]="
    'write.blocks.modal.title-' + data.type
      | transloco
        : {
            dish: data.dish[data.contentLang || data.lang],
          }
  "
></h2>

<mat-dialog-content class="modal-wrapper">
  <app-similar-dishes
    stop-propagation
    stopPropClassName="cdk-overlay-container"
    [dish]="data.dish"
    [allDishesNumber]="
      data.type === 'allergens'
        ? (data.numberOfDishes | async)
        : (data.numberOfDishesAdditives | async)
    "
    [similarDishes]="
      data.type === 'allergens'
        ? (data.similarDishes | async)
        : (data.similarDishesAdditives | async)
    "
    [contentLang]="data.contentLang"
    [lang]="data.lang"
    [type]="data.type"
    (addOption)="data.addOption($event)"
    (copyDeclarations)="data.copyDeclarations($event)"
    (loadMoreDishes)="loadMoreDishes.emit($event)"
  >
  </app-similar-dishes>
</mat-dialog-content>

<mat-dialog-actions class="actions">
  <button mat-button mat-dialog-close class="cancel" color="primary">
    {{ 'shared.buttons.done' | transloco }}
  </button>
</mat-dialog-actions>
