import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import {
  Ingredient,
  PreIngredients,
  SimpleIngredient,
} from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { IngredientInfoComponent } from './ingredient-info/ingredient-info.component';
import { IngredientsComponent } from './ingredients/ingredients.component';

@Component({
  selector: 'ingredients-tab',
  templateUrl: './ingredients-tab.component.html',
  styleUrls: ['./ingredients-tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IngredientsComponent, IngredientInfoComponent],
})
export class IngredientsTabComponent {
  readonly translations = input<any>(undefined);
  readonly dish = input<Dish>(undefined);
  readonly ingredientsAuto = input<SimpleIngredient[]>(undefined);
  readonly ingredientInfo = input<RecipeIngredient>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly recipe = input<Recipe>(undefined);

  readonly addIngredientToRecipe = output<{
    ingredient: SimpleIngredient;
    recipe: Recipe;
    onFulfilled: () => void;
  }>();
  readonly addMultipleIngredientsToRecipeEvent = output<{
    recipe: Recipe;
    data: PreIngredients;
  }>();
  readonly createNewIngredientEvent = output<{
    recipe: Recipe;
    data: Partial<Recipe>;
    onError?: () => void;
    params?: RecipeParams;
  }>();
  readonly clearIngredientsAuto = output<void>();
  readonly clearIngredientInfo = output<void>();
  readonly deleteDishRecipeIngredientEvent = output<{
    recipe: Recipe;
    recipeIngredient: SimpleRecipeIngredient;
  }>();
  readonly fetchIngredientsAuto = output<{
    search: string;
    recipeId: number;
  }>();
  readonly fetchIngredientsInfo = output<{
    url: string;
    params: RecipeParams;
  }>();
  readonly patchIngredientEvent = output<{
    url: string;
    data: Partial<Ingredient> | Partial<RecipeIngredient>;
    params: RecipeParams;
  }>();
  readonly refetch = output<void>();

  getInfo(url: string): void {
    this.fetchIngredientsInfo.emit({
      url: url,
      params: { current_recipe: this.recipe().id },
    });
  }

  patchIngredient({ data, url }): void {
    this.patchIngredientEvent.emit({
      url,
      data,
      params: { current_recipe: this.recipe().id },
    });
  }
}
