import {
  Component,
  OnChanges,
  SimpleChanges,
  output,
  input,
} from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { Variant } from 'src/app/shared/Models/dish';
import { KeysPipe } from '../../../../shared/Pipes/keys.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface TranslatePriceVariant {
  dish: MenuDish;
  variants: Variant[];
}

@Component({
  selector: 'translate-price-variant',
  templateUrl: './translate-price-variant.component.html',
  styleUrls: [
    '../translations.component.scss',
    './translate-price-variant.component.css',
  ],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    TranslocoPipe,
    KeysPipe,
  ],
})
export class TranslatePriceVariantComponent implements OnChanges {
  readonly baseLang = input<string>(undefined);
  readonly dish = input<MenuDish>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly variant = input<Variant>(undefined);
  readonly changeName = output<Variant>();

  inputFocused: boolean;
  variantName: string;

  ngOnChanges(changes: SimpleChanges) {
    const variant = this.variant();
    if (
      ('variant' in changes || 'lang' in changes) &&
      variant &&
      (this.variantName === undefined || 'lang' in changes) &&
      !this.inputFocused
    ) {
      this.variantName = variant[this.lang()];
    }
  }

  changeTranslation() {
    const variant = this.variant();
    const lang = this.lang();
    if (variant[lang] === this.variantName) return undefined;
    const newVariant = {
      ...variant,
      [lang]: this.variantName,
    } as Variant;
    this.changeName.emit(newVariant);
  }
}
