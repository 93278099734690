import * as i0 from '@angular/core';
import { inject, NgZone, Renderer2, afterRenderEffect, Directive, input, booleanAttribute, EventEmitter, numberAttribute, Input, Output } from '@angular/core';
import { NG_SCROLLBAR } from 'ngx-scrollbar';
class ReachedDroppedBase {
  constructor() {
    this.zone = inject(NgZone);
    this.renderer = inject(Renderer2);
    this.scrollbar = inject(NG_SCROLLBAR, {
      self: true
    });
    /** An array that contains all trigger elements  **/
    this.triggerElements = [];
    /** An array that contains the chosen outputs */
    this.subscribedEvents = [];
    /** A mapper function to ease forwarding the intersected element to its proper output */
    this.eventActions = {
      top: {
        emit: () => this.scrollbar.isVerticallyScrollable() ? this.top.emit() : null
      },
      bottom: {
        emit: () => this.scrollbar.isVerticallyScrollable() ? this.bottom.emit() : null
      },
      start: {
        emit: () => this.scrollbar.isHorizontallyScrollable() ? this.start.emit() : null
      },
      end: {
        emit: () => this.scrollbar.isHorizontallyScrollable() ? this.end.emit() : null
      }
    };
    afterRenderEffect({
      earlyRead: onCleanUp => {
        if (!this.disabled() && this.scrollbar.viewport.initialized()) {
          this.activate();
        }
        onCleanUp(() => this.deactivate());
      }
    });
  }
  onAction(trigger) {
    if (trigger) {
      this.eventActions[trigger]?.emit();
    }
  }
  setCssVariable(property, value) {
    if (value) {
      this.scrollbar.nativeElement.style.setProperty(property, `${value}px`);
    }
  }
  activate() {
    this.zone.runOutsideAngular(() => {
      // Create the scrollbars element inside the viewport
      this.triggerElementsWrapper = this.renderer.createElement('div');
      this.renderer.addClass(this.triggerElementsWrapper, this.triggerElementsWrapperClass);
      this.renderer.appendChild(this.scrollbar.viewport.contentWrapperElement, this.triggerElementsWrapper);
      // Create a trigger element for each subscribed event
      this.subscribedEvents.forEach(event => {
        const triggerElement = this.renderer.createElement('div');
        this.renderer.addClass(triggerElement, this.triggerElementClass);
        this.renderer.setAttribute(triggerElement, 'trigger', event);
        this.renderer.appendChild(this.triggerElementsWrapper, triggerElement);
        this.triggerElements.push(triggerElement);
      });
      // The first time the observer is triggered as soon as the element is observed,
      // This flag is used to ignore this first trigger
      let intersectionObserverInit = false;
      this.intersectionObserver = new IntersectionObserver(entries => {
        if (intersectionObserverInit) {
          entries.forEach(entry => {
            if (this.isTriggered(entry)) {
              // Forward the detected trigger element only after the observer is initialized
              // Only observe the trigger elements when scrollable
              this.zone.run(() => this.onAction(entry.target.getAttribute('trigger')));
            }
          });
        } else {
          // Once the initial element is detected set a flag to true
          intersectionObserverInit = true;
        }
      }, {
        root: this.scrollbar.viewport.nativeElement
      });
      this.triggerElements.forEach(el => this.intersectionObserver.observe(el));
    });
  }
  deactivate() {
    this.intersectionObserver?.disconnect();
    this.triggerElementsWrapper?.remove();
    this.triggerElements = [];
  }
  ngOnInit() {
    if (this.top.observed) {
      this.subscribedEvents.push('top');
    }
    if (this.bottom.observed) {
      this.subscribedEvents.push('bottom');
    }
    if (this.start.observed) {
      this.subscribedEvents.push('start');
    }
    if (this.end.observed) {
      this.subscribedEvents.push('end');
    }
  }
  static {
    this.ɵfac = function ReachedDroppedBase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ReachedDroppedBase)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ReachedDroppedBase
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReachedDroppedBase, [{
    type: Directive
  }], () => [], null);
})();
class NgScrollReached extends ReachedDroppedBase {
  constructor() {
    super(...arguments);
    this.disabled = input(false, {
      alias: 'disableReached',
      transform: booleanAttribute
    });
    this.top = new EventEmitter();
    this.bottom = new EventEmitter();
    this.start = new EventEmitter();
    this.end = new EventEmitter();
    this.triggerElementsWrapperClass = 'ng-scroll-reached-wrapper';
    this.triggerElementClass = 'scroll-reached-trigger-element';
  }
  /** Reached offset value in px */
  set offsetSetter(value) {
    this.setCssVariable('--reached-offset', value);
  }
  set offsetTopSetter(value) {
    this.setCssVariable('--reached-offset-top', value);
  }
  set offsetBottomSetter(value) {
    this.setCssVariable('--reached-offset-bottom', value);
  }
  set offsetStartSetter(value) {
    this.setCssVariable('--reached-offset-start', value);
  }
  set offsetEndSetter(value) {
    this.setCssVariable('--reached-offset-end', value);
  }
  isTriggered(entry) {
    return entry.isIntersecting;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgScrollReached_BaseFactory;
      return function NgScrollReached_Factory(__ngFactoryType__) {
        return (ɵNgScrollReached_BaseFactory || (ɵNgScrollReached_BaseFactory = i0.ɵɵgetInheritedFactory(NgScrollReached)))(__ngFactoryType__ || NgScrollReached);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgScrollReached,
      selectors: [["ng-scrollbar", "reachedTop", ""], ["ng-scrollbar", "reachedBottom", ""], ["ng-scrollbar", "reachedStart", ""], ["ng-scrollbar", "reachedEnd", ""]],
      inputs: {
        offsetSetter: [2, "reachedOffset", "offsetSetter", numberAttribute],
        offsetTopSetter: [2, "reachedTopOffset", "offsetTopSetter", numberAttribute],
        offsetBottomSetter: [2, "reachedBottomOffset", "offsetBottomSetter", numberAttribute],
        offsetStartSetter: [2, "reachedStartOffset", "offsetStartSetter", numberAttribute],
        offsetEndSetter: [2, "reachedEndOffset", "offsetEndSetter", numberAttribute],
        disabled: [1, "disableReached", "disabled"]
      },
      outputs: {
        top: "reachedTop",
        bottom: "reachedBottom",
        start: "reachedStart",
        end: "reachedEnd"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgScrollReached, [{
    type: Directive,
    args: [{
      selector: 'ng-scrollbar[reachedTop], ng-scrollbar[reachedBottom], ng-scrollbar[reachedStart], ng-scrollbar[reachedEnd]',
      standalone: true
    }]
  }], null, {
    offsetSetter: [{
      type: Input,
      args: [{
        alias: 'reachedOffset',
        transform: numberAttribute
      }]
    }],
    offsetTopSetter: [{
      type: Input,
      args: [{
        alias: 'reachedTopOffset',
        transform: numberAttribute
      }]
    }],
    offsetBottomSetter: [{
      type: Input,
      args: [{
        alias: 'reachedBottomOffset',
        transform: numberAttribute
      }]
    }],
    offsetStartSetter: [{
      type: Input,
      args: [{
        alias: 'reachedStartOffset',
        transform: numberAttribute
      }]
    }],
    offsetEndSetter: [{
      type: Input,
      args: [{
        alias: 'reachedEndOffset',
        transform: numberAttribute
      }]
    }],
    top: [{
      type: Output,
      args: ['reachedTop']
    }],
    bottom: [{
      type: Output,
      args: ['reachedBottom']
    }],
    start: [{
      type: Output,
      args: ['reachedStart']
    }],
    end: [{
      type: Output,
      args: ['reachedEnd']
    }]
  });
})();
class NgScrollDropped extends ReachedDroppedBase {
  constructor() {
    super(...arguments);
    this.disabled = input(false, {
      alias: 'disableDropped',
      transform: booleanAttribute
    });
    this.top = new EventEmitter();
    this.bottom = new EventEmitter();
    this.start = new EventEmitter();
    this.end = new EventEmitter();
    this.triggerElementsWrapperClass = 'ng-scroll-dropped-wrapper';
    this.triggerElementClass = 'scroll-dropped-trigger-element';
  }
  /** Dropped offset value in px */
  set offsetSetter(value) {
    this.setCssVariable('--dropped-offset', value);
  }
  set offsetTopSetter(value) {
    this.setCssVariable('--dropped-offset-top', value);
  }
  set offsetBottomSetter(value) {
    this.setCssVariable('--dropped-offset-bottom', value);
  }
  set offsetStartSetter(value) {
    this.setCssVariable('--dropped-offset-start', value);
  }
  set offsetEndSetter(value) {
    this.setCssVariable('--dropped-offset-end', value);
  }
  isTriggered(entry) {
    return !entry.isIntersecting;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgScrollDropped_BaseFactory;
      return function NgScrollDropped_Factory(__ngFactoryType__) {
        return (ɵNgScrollDropped_BaseFactory || (ɵNgScrollDropped_BaseFactory = i0.ɵɵgetInheritedFactory(NgScrollDropped)))(__ngFactoryType__ || NgScrollDropped);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgScrollDropped,
      selectors: [["ng-scrollbar", "droppedTop", ""], ["ng-scrollbar", "droppedBottom", ""], ["ng-scrollbar", "droppedStart", ""], ["ng-scrollbar", "droppedEnd", ""]],
      inputs: {
        offsetSetter: [2, "droppedOffset", "offsetSetter", numberAttribute],
        offsetTopSetter: [2, "droppedTopOffset", "offsetTopSetter", numberAttribute],
        offsetBottomSetter: [2, "droppedBottomOffset", "offsetBottomSetter", numberAttribute],
        offsetStartSetter: [2, "droppedStartOffset", "offsetStartSetter", numberAttribute],
        offsetEndSetter: [2, "droppedEndOffset", "offsetEndSetter", numberAttribute],
        disabled: [1, "disableDropped", "disabled"]
      },
      outputs: {
        top: "droppedTop",
        bottom: "droppedBottom",
        start: "droppedStart",
        end: "droppedEnd"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgScrollDropped, [{
    type: Directive,
    args: [{
      selector: 'ng-scrollbar[droppedTop], ng-scrollbar[droppedBottom], ng-scrollbar[droppedStart], ng-scrollbar[droppedEnd]',
      standalone: true
    }]
  }], null, {
    offsetSetter: [{
      type: Input,
      args: [{
        alias: 'droppedOffset',
        transform: numberAttribute
      }]
    }],
    offsetTopSetter: [{
      type: Input,
      args: [{
        alias: 'droppedTopOffset',
        transform: numberAttribute
      }]
    }],
    offsetBottomSetter: [{
      type: Input,
      args: [{
        alias: 'droppedBottomOffset',
        transform: numberAttribute
      }]
    }],
    offsetStartSetter: [{
      type: Input,
      args: [{
        alias: 'droppedStartOffset',
        transform: numberAttribute
      }]
    }],
    offsetEndSetter: [{
      type: Input,
      args: [{
        alias: 'droppedEndOffset',
        transform: numberAttribute
      }]
    }],
    top: [{
      type: Output,
      args: ['droppedTop']
    }],
    bottom: [{
      type: Output,
      args: ['droppedBottom']
    }],
    start: [{
      type: Output,
      args: ['droppedStart']
    }],
    end: [{
      type: Output,
      args: ['droppedEnd']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgScrollDropped, NgScrollReached };
