@if (showForm && formFieldsView.length) {
  <win-form-with-errors [formGroup]="customForm">
    @for (field of formFieldsView; track field) {
      <div class="element-wrapper">
        @if (field?.type !== 'checkbox') {
          <span class="expansion-panel-field">{{ field?.key }}</span>
        }
        @if (field?.type !== 'checkbox') {
          <mat-form-field class="form-field" subscriptSizing="dynamic">
            <input
              #inputElement
              matInput
              class="field-input"
              min="0"
              [name]="field?.formControlName"
              [formControlName]="field?.formControlName"
              [placeholder]="'style.sidebar.placeholders.auto' | transloco"
              [type]="field?.type"
            />
            @if (showProgressBar()) {
              <mat-progress-bar
                class="progress-bar"
                mode="query"
              ></mat-progress-bar>
            }
            @if (control()?.errors) {
              <mat-error>
                @for (error of control()?.errors | keyvalue; track error) {
                  <span>
                    {{
                      error.key === 'error'
                        ? error.value
                        : (translationKey() + '.' + error.key | transloco)
                    }}
                  </span>
                }
              </mat-error>
            }
          </mat-form-field>
        } @else {
          <mat-checkbox
            color="primary"
            class="checkbox"
            [formControlName]="field?.formControlName"
            >{{ field?.key }}</mat-checkbox
          >
        }
      </div>
    }
  </win-form-with-errors>
}
