import { Component, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { confirmEmail } from 'src/app/auth/ngrx/auth.actions';
import { selectEmailVerifiedState } from 'src/app/auth/ngrx/auth.selectors';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { setInterfaceLang } from 'src/app/shared/ngrx/shared.actions';
import { AsyncPipe } from '@angular/common';
import { ConfirmedEmailErrorComponent } from '../../Components/confirmed-email-error/confirmed-email-error.component';
import { ConfirmedEmailComponent } from '../../Components/confirmed-email/confirmed-email.component';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { LanguageSelectorComponent } from '../../../shared/Components/language-selector/language-selector.component';

@Component({
  selector: 'confirmation',
  template: `
    <div class="confirm-page">
      <a routerLink="/"
        ><img class="title" src="assets/img/logo_dark.svg" alt="menutech logo"
      /></a>
      <language-selector
        class="lang"
        [model]="language"
        [simple]="true"
        changeValue="current"
        (changed)="changeLanguage()"
      ></language-selector>
      <div class="confirm-bar">
        @if ((emailVerified$ | async) === null) {
          <spinner></spinner>
        }
        @if ((emailVerified$ | async) === true) {
          <confirmed-email></confirmed-email>
        }
        @if ((emailVerified$ | async) === false) {
          <confirmed-email-error></confirmed-email-error>
        }
      </div>
    </div>
  `,
  styleUrls: ['./confirmation.container.css'],
  imports: [
    RouterLink,
    LanguageSelectorComponent,
    SpinnerComponent,
    ConfirmedEmailComponent,
    ConfirmedEmailErrorComponent,
    AsyncPipe,
  ],
})
export class ConfirmationContainer {
  private ngrxStore = inject<Store<State>>(Store);
  private route = inject(ActivatedRoute);
  private translate = inject(TranslocoService);

  language: { current: InterfaceLanguage } = { current: 'en' };
  showSpinner = true;

  emailVerified$ = this.ngrxStore.select(selectEmailVerifiedState);

  constructor() {
    const translate = this.translate;

    this.translate.langChanges$.subscribe(
      () =>
        (this.language.current =
          translate.getActiveLang() as InterfaceLanguage),
    );
    this.translate.getActiveLang() &&
      (this.language.current = translate.getActiveLang() as InterfaceLanguage);
    this.route.params
      .pipe(filter((params) => 'key' in params))
      .subscribe((params) =>
        this.ngrxStore.dispatch(confirmEmail({ key: params.key as string })),
      );
  }

  changeLanguage() {
    this.translate.setActiveLang(this.language.current);
    this.ngrxStore.dispatch(setInterfaceLang({ lang: this.language.current }));
  }
}
