import * as i0 from '@angular/core';
import { EventEmitter, forwardRef, Component, ChangeDetectionStrategy, Input, Output } from '@angular/core';
import * as i2 from '@angular/forms';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import * as i3 from '@angular/material/autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as i5 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import * as i6 from '@angular/material/form-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { startWith, debounceTime, tap, map } from 'rxjs/operators';
import * as i7 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i8 from '@angular/cdk/scrolling';
import { ScrollingModule } from '@angular/cdk/scrolling';
import * as i4 from '@angular/material/core';
const _c0 = a0 => ({
  "height": a0
});
function CountrySelectComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-icon", 3);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", "fi-" + (ctx_r1.formControl.value == null ? null : ctx_r1.formControl.value.alpha2));
  }
}
function CountrySelectComponent_mat_option_8_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 9);
  }
  if (rf & 2) {
    const country_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("ngClass", "fi-" + (country_r3 == null ? null : country_r3.alpha2));
  }
}
function CountrySelectComponent_mat_option_8_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "uppercase");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const country_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, country_r3.alpha2));
  }
}
function CountrySelectComponent_mat_option_8_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "uppercase");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const country_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, country_r3.alpha3));
  }
}
function CountrySelectComponent_mat_option_8_Conditional_5_span_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "uppercase");
    i0.ɵɵpipe(3, "uppercase");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const country_r3 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2(" (", i0.ɵɵpipeBind1(2, 2, country_r3.alpha2), " / ", i0.ɵɵpipeBind1(3, 4, country_r3.alpha3), ") ");
  }
}
function CountrySelectComponent_mat_option_8_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, CountrySelectComponent_mat_option_8_Conditional_5_span_2_Template, 4, 6, "span", 11);
  }
  if (rf & 2) {
    const country_r3 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(country_r3.translations[ctx_r1.lang]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.showCodes);
  }
}
function CountrySelectComponent_mat_option_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 7)(1, "div", 8);
    i0.ɵɵtemplate(2, CountrySelectComponent_mat_option_8_Conditional_2_Template, 1, 1, "span", 9)(3, CountrySelectComponent_mat_option_8_Conditional_3_Template, 3, 3, "span", 10)(4, CountrySelectComponent_mat_option_8_Conditional_4_Template, 3, 3, "span", 10)(5, CountrySelectComponent_mat_option_8_Conditional_5_Template, 3, 2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const country_r3 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("value", country_r3);
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(ctx_r1.showFlag === true ? 2 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.alpha2Only === true ? 3 : ctx_r1.alpha3Only === true ? 4 : 5);
  }
}
const countries = [{
  alpha2: 'af',
  alpha3: 'afg',
  translations: {
    de: 'Afghanistan',
    en: 'Afghanistan',
    fr: 'Afghanistan',
    it: 'Afghanistan',
    es: 'Afganistán',
    ar: 'أفغانستان',
    zh: '阿富汗',
    hi: 'अफगानिस्तान',
    bn: 'আফগানিস্তান',
    pt: 'Afeganistão',
    ru: 'Афганистан'
  }
}, {
  alpha2: 'ax',
  alpha3: 'ala',
  translations: {
    de: 'Ålandinseln',
    en: 'Åland Islands',
    fr: 'Îles Åland',
    it: 'Isole Åland',
    es: 'Islas Åland',
    ar: 'جزر أولاند',
    zh: '奥兰',
    hi: 'ऑलैण्ड द्वीपसमूह',
    bn: 'অলান্দ দ্বীপপুঞ্জ',
    pt: 'Ilhas Åland',
    ru: 'Аландские острова'
  }
}, {
  alpha2: 'al',
  alpha3: 'alb',
  translations: {
    de: 'Albanien',
    en: 'Albania',
    fr: 'Albanie',
    it: 'Albania',
    es: 'Albania',
    ar: 'ألبانيا',
    zh: '阿尔巴尼亚',
    hi: 'अल्बानिया',
    bn: 'আলবেনিয়া',
    pt: 'Albânia',
    ru: 'Албания'
  }
}, {
  alpha2: 'dz',
  alpha3: 'dza',
  translations: {
    de: 'Algerien',
    en: 'Algeria',
    fr: 'Algérie',
    it: 'Algeria',
    es: 'Argelia',
    ar: 'الجزائر',
    zh: '阿尔及利亚',
    hi: 'अल्जीरिया',
    bn: 'আলজেরিয়া',
    pt: 'Argélia',
    ru: 'Алжир'
  }
}, {
  alpha2: 'as',
  alpha3: 'asm',
  translations: {
    de: 'Amerikanisch-Samoa',
    en: 'American Samoa',
    fr: 'Samoa américaines',
    it: 'Samoa Americane',
    es: 'Samoa Americana',
    ar: 'ساموا الأمريكية',
    zh: '美属萨摩亚',
    hi: 'अमेरिकान सामोआ',
    bn: 'আমেরিকান সামোয়া',
    pt: 'Samoa Americana',
    ru: 'Американское Самоа'
  }
}, {
  alpha2: 'ad',
  alpha3: 'and',
  translations: {
    de: 'Andorra',
    en: 'Andorra',
    fr: 'Andorre',
    it: 'Andorra',
    es: 'Andorra',
    ar: 'أندورا',
    zh: '安道尔',
    hi: 'अंडोरा',
    bn: 'অ্যান্ডোরা',
    pt: 'Andorra',
    ru: 'Андорра'
  }
}, {
  alpha2: 'ao',
  alpha3: 'ago',
  translations: {
    de: 'Angola',
    en: 'Angola',
    fr: 'Angola',
    it: 'Angola',
    es: 'Angola',
    ar: 'أنغولا',
    zh: '安哥拉',
    hi: 'अंगोला',
    bn: 'অ্যাঙ্গোলা',
    pt: 'Angola',
    ru: 'Ангола'
  }
}, {
  alpha2: 'ai',
  alpha3: 'aia',
  translations: {
    de: 'Anguilla',
    en: 'Anguilla',
    fr: 'Anguilla',
    it: 'Anguilla',
    es: 'Anguila',
    ar: 'أنغويلا',
    zh: '安圭拉',
    hi: 'अंग्विला',
    bn: 'এ্যাঙ্গুইলা',
    pt: 'Anguila',
    ru: 'Ангилья'
  }
}, {
  alpha2: 'aq',
  alpha3: 'ata',
  translations: {
    de: 'Antarktis',
    en: 'Antarctica',
    fr: 'Antarctique',
    it: 'Antartide',
    es: 'Antártida',
    ar: 'القارة القطبية الجنوبية',
    zh: '南极洲',
    hi: 'अंटार्टिका',
    bn: 'অ্যান্টার্কটিকা',
    pt: 'Antártida',
    ru: 'Антарктида'
  }
}, {
  alpha2: 'ag',
  alpha3: 'atg',
  translations: {
    de: 'Antigua und Barbuda',
    en: 'Antigua and Barbuda',
    fr: 'Antigua-et-Barbuda',
    it: 'Antigua e Barbuda',
    es: 'Antigua y Barbuda',
    ar: 'أنتيغوا وباربودا',
    zh: '安提瓜和巴布达',
    hi: 'एंटीगुआ और बारबूडा',
    bn: 'অ্যান্টিগুয়া ও বার্বুডা',
    pt: 'Antígua e Barbuda',
    ru: 'Антигуа и Барбуда'
  }
}, {
  alpha2: 'ar',
  alpha3: 'arg',
  translations: {
    de: 'Argentinien',
    en: 'Argentina',
    fr: 'Argentine',
    it: 'Argentina',
    es: 'Argentina',
    ar: 'الأرجنتين',
    zh: '阿根廷',
    hi: 'अर्जेंटिना',
    bn: 'আর্জেন্টিনা',
    pt: 'Argentina',
    ru: 'Аргентина'
  }
}, {
  alpha2: 'am',
  alpha3: 'arm',
  translations: {
    de: 'Armenien',
    en: 'Armenia',
    fr: 'Arménie',
    it: 'Armenia',
    es: 'Armenia',
    ar: 'أرمينيا',
    zh: '亚美尼亚',
    hi: 'अर्मेनिया',
    bn: 'আর্মেনিয়া',
    pt: 'Arménia',
    ru: 'Армения'
  }
}, {
  alpha2: 'aw',
  alpha3: 'abw',
  translations: {
    de: 'Aruba',
    en: 'Aruba',
    fr: 'Aruba',
    it: 'Aruba',
    es: 'Aruba',
    ar: 'أروبا',
    zh: '阿鲁巴',
    hi: 'अरुबा',
    bn: 'আরুবা',
    pt: 'Aruba',
    ru: 'Аруба'
  }
}, {
  alpha2: 'au',
  alpha3: 'aus',
  translations: {
    de: 'Australien',
    en: 'Australia',
    fr: 'Australie',
    it: 'Australia',
    es: 'Australia',
    ar: 'أستراليا',
    zh: '澳大利亚',
    hi: 'आस्ट्रेलिया',
    bn: 'অস্ট্রেলিয়া',
    pt: 'Austrália',
    ru: 'Австралия'
  }
}, {
  alpha2: 'at',
  alpha3: 'aut',
  translations: {
    de: 'Österreich',
    en: 'Austria',
    fr: 'Autriche',
    it: 'Austria',
    es: 'Austria',
    ar: 'النمسا',
    zh: '奥地利',
    hi: 'आस्ट्रिया',
    bn: 'অস্ট্রিয়া',
    pt: 'Áustria',
    ru: 'Австрия'
  }
}, {
  alpha2: 'az',
  alpha3: 'aze',
  translations: {
    de: 'Aserbaidschan',
    en: 'Azerbaijan',
    fr: 'Azerbaïdjan',
    it: 'Azerbaigian',
    es: 'Azerbaiyán',
    ar: 'أذربيجان',
    zh: '阿塞拜疆',
    hi: 'अजरबेजान',
    bn: 'আজারবাইজান',
    pt: 'Azerbaijão',
    ru: 'Азербайджан'
  }
}, {
  alpha2: 'bs',
  alpha3: 'bhs',
  translations: {
    de: 'Bahamas',
    en: 'Bahamas',
    fr: 'Bahamas',
    it: 'Bahamas',
    es: 'Bahamas',
    ar: 'باهاماس',
    zh: '巴哈马',
    hi: 'बहामास',
    bn: 'বাহামা দ্বীপপুঞ্জ',
    pt: 'Bahamas',
    ru: 'Багамы'
  }
}, {
  alpha2: 'bh',
  alpha3: 'bhr',
  translations: {
    de: 'Bahrain',
    en: 'Bahrain',
    fr: 'Bahreïn',
    it: 'Bahrein',
    es: 'Baréin',
    ar: 'البحرين',
    zh: '巴林',
    hi: 'बहरीन',
    bn: 'বাহরাইন',
    pt: 'Bahrein',
    ru: 'Бахрейн'
  }
}, {
  alpha2: 'bd',
  alpha3: 'bgd',
  translations: {
    de: 'Bangladesch',
    en: 'Bangladesh',
    fr: 'Bangladesh',
    it: 'Bangladesh',
    es: 'Bangladés',
    ar: 'بنغلاديش',
    zh: '孟加拉国',
    hi: 'बांग्लादेश',
    bn: 'বাংলাদেশ',
    pt: 'Bangladesh',
    ru: 'Бангладеш'
  }
}, {
  alpha2: 'bb',
  alpha3: 'brb',
  translations: {
    de: 'Barbados',
    en: 'Barbados',
    fr: 'Barbade',
    it: 'Barbados',
    es: 'Barbados',
    ar: 'باربادوس',
    zh: '巴巴多斯',
    hi: 'बार्बाडोस',
    bn: 'বার্বাডোস',
    pt: 'Barbados',
    ru: 'Барбадос'
  }
}, {
  alpha2: 'by',
  alpha3: 'blr',
  translations: {
    de: 'Belarus',
    en: 'Belarus',
    fr: 'Bélarus',
    it: 'Bielorussia',
    es: 'Bielorrusia',
    ar: 'روسيا البيضاء',
    zh: '白俄罗斯',
    hi: 'बेलारुस',
    bn: 'বেলারুশ',
    pt: 'Bielorrússia',
    ru: 'Беларусь'
  }
}, {
  alpha2: 'be',
  alpha3: 'bel',
  translations: {
    de: 'Belgien',
    en: 'Belgium',
    fr: 'Belgique',
    it: 'Belgio',
    es: 'Bélgica',
    ar: 'بلجيكا',
    zh: '比利时',
    hi: 'बेल्जियम',
    bn: 'বেলজিয়াম',
    pt: 'Bélgica',
    ru: 'Бельгия'
  }
}, {
  alpha2: 'bz',
  alpha3: 'blz',
  translations: {
    de: 'Belize',
    en: 'Belize',
    fr: 'Belize',
    it: 'Belize',
    es: 'Belice',
    ar: 'بليز',
    zh: '伯利兹',
    hi: 'बेलिजे',
    bn: 'বেলিজ',
    pt: 'Belize',
    ru: 'Белиз'
  }
}, {
  alpha2: 'bj',
  alpha3: 'ben',
  translations: {
    de: 'Benin',
    en: 'Benin',
    fr: 'Bénin',
    it: 'Benin',
    es: 'Benín',
    ar: 'بنين',
    zh: '贝宁',
    hi: 'बानिन',
    bn: 'বেনিন',
    pt: 'Benim',
    ru: 'Бенин'
  }
}, {
  alpha2: 'bm',
  alpha3: 'bmu',
  translations: {
    de: 'Bermuda',
    en: 'Bermuda',
    fr: 'Bermudes',
    it: 'Bermuda',
    es: 'Bermudas',
    ar: 'برمودا',
    zh: '百慕大',
    hi: 'बर्मूडा',
    bn: 'বারমুডা',
    pt: 'Bermudas',
    ru: 'Бермуды'
  }
}, {
  alpha2: 'bt',
  alpha3: 'btn',
  translations: {
    de: 'Bhutan',
    en: 'Bhutan',
    fr: 'Bhoutan',
    it: 'Bhutan',
    es: 'Bután',
    ar: 'بوتان',
    zh: '不丹',
    hi: 'भूटान',
    bn: 'ভূটান',
    pt: 'Butão',
    ru: 'Бутан'
  }
}, {
  alpha2: 'bo',
  alpha3: 'bol',
  translations: {
    de: 'Bolivien',
    en: 'Bolivia',
    fr: 'Bolivie',
    it: 'Bolivia',
    es: 'Bolivia',
    ar: 'بوليفيا',
    zh: '玻利维亚',
    hi: 'बोलिविया',
    bn: 'বলিভিয়া, বহুজাতিক রাষ্ট্র',
    pt: 'Bolívia',
    ru: 'Боливия'
  }
}, {
  alpha2: 'bq',
  alpha3: 'bes',
  translations: {
    de: 'Bonaire, Sint Eustatius und Saba',
    en: 'Bonaire, Sint Eustatius and Saba',
    fr: 'Bonaire, Saint-Eustache et Saba',
    it: 'Bonaire, Sint Eustatius e Saba',
    es: 'Bonaire, San Eustaquio y Saba',
    ar: 'الجزر الكاريبية الهولندية',
    zh: '荷兰加勒比区',
    hi: 'कैरिबियन नीदरलैंड',
    bn: 'ক্যারিবীয় নেদারল্যান্ডস',
    pt: 'Países Baixos Caribenhos',
    ru: 'Бонэйр, Синт-Эстатиус и Саба'
  }
}, {
  alpha2: 'ba',
  alpha3: 'bih',
  translations: {
    de: 'Bosnien und Herzegowina',
    en: 'Bosnia and Herzegovina',
    fr: 'Bosnie-Herzégovine',
    it: 'Bosnia ed Erzegovina',
    es: 'Bosnia y Herzegovina',
    ar: 'البوسنة والهرسك',
    zh: '波黑',
    hi: 'बोस्निया हर्जेगोविना',
    bn: 'বসনিয়া ও হার্জেগোভিনা',
    pt: 'Bósnia-Herzegovina',
    ru: 'Босния и Герцеговина'
  }
}, {
  alpha2: 'bw',
  alpha3: 'bwa',
  translations: {
    de: 'Botsuana',
    en: 'Botswana',
    fr: 'Botswana',
    it: 'Botswana',
    es: 'Botsuana',
    ar: 'بوتسوانا',
    zh: '博茨瓦纳',
    hi: 'बोत्सवाना',
    bn: 'বতসোয়ানা',
    pt: 'Botsuana',
    ru: 'Ботсвана'
  }
}, {
  alpha2: 'bv',
  alpha3: 'bvt',
  translations: {
    de: 'Bouvetinsel',
    en: 'Bouvet Island',
    fr: 'Île Bouvet',
    it: 'Isola Bouvet',
    es: 'Isla Bouvet',
    ar: 'جزيرة بوفيه',
    zh: '布韦岛',
    hi: 'बाउवेट',
    bn: 'বোভেট দ্বীপ',
    pt: 'Ilha Bouvet',
    ru: 'Остров Буве'
  }
}, {
  alpha2: 'br',
  alpha3: 'bra',
  translations: {
    de: 'Brasilien',
    en: 'Brazil',
    fr: 'Brésil',
    it: 'Brasile',
    es: 'Brasil',
    ar: 'البرازيل',
    zh: '巴西',
    hi: 'ब्राजील',
    bn: 'ব্রাজিল',
    pt: 'Brasil',
    ru: 'Бразилия'
  }
}, {
  alpha2: 'io',
  alpha3: 'iot',
  translations: {
    de: 'Britisches Territorium im Indischen Ozean',
    en: 'British Indian Ocean Territory',
    fr: 'Territoire britannique de l’océan Indien',
    it: 'Territorio britannico dell’Oceano Indiano',
    es: 'Territorio Británico del Océano Índico',
    ar: 'إقليم المحيط الهندي البريطاني',
    zh: '英属印度洋领地',
    hi: 'ब्रितानी हिंद महासागरीय क्षेत्र',
    bn: 'ব্রিটিশ ভারত মহাসাগরীয় এলাকা',
    pt: 'Território Britânico do Oceano Índico',
    ru: 'Британская территория в Индийском океане'
  }
}, {
  alpha2: 'bn',
  alpha3: 'brn',
  translations: {
    de: 'Brunei Darussalam',
    en: 'Brunei Darussalam',
    fr: 'Brunéi Darussalam',
    it: 'Brunei Darussalam',
    es: 'Brunéi Darussalam',
    ar: 'بروناي',
    zh: '文莱',
    hi: 'ब्रुनेई',
    bn: 'ব্রুনাই দারুসালাম',
    pt: 'Brunei',
    ru: 'Бруней'
  }
}, {
  alpha2: 'bg',
  alpha3: 'bgr',
  translations: {
    de: 'Bulgarien',
    en: 'Bulgaria',
    fr: 'Bulgarie',
    it: 'Bulgaria',
    es: 'Bulgaria',
    ar: 'بلغاريا',
    zh: '保加利亚',
    hi: 'बल्गारिया',
    bn: 'বুলগেরিয়া',
    pt: 'Bulgária',
    ru: 'Болгария'
  }
}, {
  alpha2: 'bf',
  alpha3: 'bfa',
  translations: {
    de: 'Burkina Faso',
    en: 'Burkina Faso',
    fr: 'Burkina Faso',
    it: 'Burkina Faso',
    es: 'Burkina Faso',
    ar: 'بوركينا فاسو',
    zh: '布基纳法索',
    hi: 'बुर्किना फासो',
    bn: 'বুর্কিনা ফাসো',
    pt: 'Burkina Faso',
    ru: 'Буркина-Фасо'
  }
}, {
  alpha2: 'bi',
  alpha3: 'bdi',
  translations: {
    de: 'Burundi',
    en: 'Burundi',
    fr: 'Burundi',
    it: 'Burundi',
    es: 'Burundi',
    ar: 'بوروندي',
    zh: '布隆迪',
    hi: 'बुरुंडी',
    bn: 'বুরুন্ডি',
    pt: 'Burundi',
    ru: 'Бурунди'
  }
}, {
  alpha2: 'cv',
  alpha3: 'cpv',
  translations: {
    de: 'Cabo Verde',
    en: 'Cabo Verde',
    fr: 'Cabo Verde',
    it: 'Capo Verde',
    es: 'Cabo Verde',
    ar: 'الرأس الأخضر',
    zh: '佛得角',
    hi: 'केप वर्दे',
    bn: 'কেপ ভার্দ',
    pt: 'Cabo Verde',
    ru: 'Кабо-Верде'
  }
}, {
  alpha2: 'kh',
  alpha3: 'khm',
  translations: {
    de: 'Kambodscha',
    en: 'Cambodia',
    fr: 'Cambodge',
    it: 'Cambogia',
    es: 'Camboya',
    ar: 'كمبوديا',
    zh: '柬埔寨',
    hi: 'कंबोडिया',
    bn: 'কম্বোডিয়া',
    pt: 'Camboja',
    ru: 'Камбоджа'
  }
}, {
  alpha2: 'cm',
  alpha3: 'cmr',
  translations: {
    de: 'Kamerun',
    en: 'Cameroon',
    fr: 'Cameroun',
    it: 'Camerun',
    es: 'Camerún',
    ar: 'الكاميرون',
    zh: '喀麦隆',
    hi: 'कैमरून',
    bn: 'ক্যামেরুন',
    pt: 'Camarões',
    ru: 'Камерун'
  }
}, {
  alpha2: 'ca',
  alpha3: 'can',
  translations: {
    de: 'Kanada',
    en: 'Canada',
    fr: 'Canada',
    it: 'Canada',
    es: 'Canadá',
    ar: 'كندا',
    zh: '加拿大',
    hi: 'कनाडा',
    bn: 'কানাডা',
    pt: 'Canadá',
    ru: 'Канада'
  }
}, {
  alpha2: 'ky',
  alpha3: 'cym',
  translations: {
    de: 'Kaimaninseln',
    en: 'Cayman Islands',
    fr: 'Îles Caïmans',
    it: 'Isole Cayman',
    es: 'Islas Caimán',
    ar: 'جزر كايمان',
    zh: '开曼群岛',
    hi: 'केमैन आइसलैंड्स',
    bn: 'কেইম্যান দ্বীপপুঞ্জ',
    pt: 'Ilhas Caimão',
    ru: 'Острова Кайман'
  }
}, {
  alpha2: 'cf',
  alpha3: 'caf',
  translations: {
    de: 'Zentralafrikanische Republik',
    en: 'Central African Republic',
    fr: 'République centrafricaine',
    it: 'Repubblica Centrafricana',
    es: 'República Centroafricana',
    ar: 'جمهورية أفريقيا الوسطى',
    zh: '中非',
    hi: 'सेंट्रल अफ्रीका गणतंत्र',
    bn: 'মধ্য আফ্রিকান প্রজাতন্ত্র',
    pt: 'República Centro-Africana',
    ru: 'ЦАР'
  }
}, {
  alpha2: 'td',
  alpha3: 'tcd',
  translations: {
    de: 'Tschad',
    en: 'Chad',
    fr: 'Tchad',
    it: 'Ciad',
    es: 'Chad',
    ar: 'تشاد',
    zh: '乍得',
    hi: 'चाड',
    bn: 'চাদ',
    pt: 'Chade',
    ru: 'Чад'
  }
}, {
  alpha2: 'cl',
  alpha3: 'chl',
  translations: {
    de: 'Chile',
    en: 'Chile',
    fr: 'Chili',
    it: 'Cile',
    es: 'Chile',
    ar: 'تشيلي',
    zh: '智利',
    hi: 'चिली',
    bn: 'চিলি',
    pt: 'Chile',
    ru: 'Чили'
  }
}, {
  alpha2: 'cn',
  alpha3: 'chn',
  translations: {
    de: 'China',
    en: 'China',
    fr: 'Chine',
    it: 'Cina',
    es: 'China',
    ar: 'الصين',
    zh: '中国',
    hi: 'चीन',
    bn: 'গণচীন',
    pt: 'China',
    ru: 'КНР (Китайская Народная Республика)'
  }
}, {
  alpha2: 'cx',
  alpha3: 'cxr',
  translations: {
    de: 'Weihnachtsinsel',
    en: 'Christmas Island',
    fr: 'Île Christmas',
    it: 'Isola di Natale',
    es: 'Isla de Navidad',
    ar: 'جزيرة عيد الميلاد',
    zh: '圣诞岛',
    hi: 'क्रिसमस द्वीप',
    bn: 'ক্রিস্টমাস দ্বীপ',
    pt: 'Ilha de Natal',
    ru: 'Остров Рождества'
  }
}, {
  alpha2: 'cc',
  alpha3: 'cck',
  translations: {
    de: 'Kokosinseln',
    en: 'Cocos (Keeling) Islands',
    fr: 'Îles Cocos (Keeling)',
    it: 'Isole Cocos (Keeling)',
    es: 'Islas Cocos (Keeling)',
    ar: 'جزر كوكوس',
    zh: '科科斯（基林）群岛',
    hi: 'कोकोस (कीलिंग) द्वीप',
    bn: 'কোকোস (কিলিং) দ্বীপপুঞ্জ',
    pt: 'Ilhas Cocos (Keeling)',
    ru: 'Кокосовые острова'
  }
}, {
  alpha2: 'co',
  alpha3: 'col',
  translations: {
    de: 'Kolumbien',
    en: 'Colombia',
    fr: 'Colombie',
    it: 'Colombia',
    es: 'Colombia',
    ar: 'كولومبيا',
    zh: '哥伦比亚',
    hi: 'कोलंबिया',
    bn: 'কলম্বিয়া',
    pt: 'Colômbia',
    ru: 'Колумбия'
  }
}, {
  alpha2: 'km',
  alpha3: 'com',
  translations: {
    de: 'Union der Komoren',
    en: 'Comoros',
    fr: 'Comores',
    it: 'Comore',
    es: 'Comoras',
    ar: 'جزر القمر',
    zh: '科摩罗',
    hi: 'कोमोरोस',
    bn: 'কোমোরোস',
    pt: 'Comores',
    ru: 'Коморы'
  }
}, {
  alpha2: 'cd',
  alpha3: 'cod',
  translations: {
    de: 'Demokratische Republik Kongo',
    en: 'Congo, Democratic Republic of the',
    fr: 'Congo (République démocratique du)',
    it: 'Congo, Repubblica Democratica del',
    es: 'Congo, República Democrática del',
    ar: 'جمهورية الكونغو الديمقراطية',
    zh: '刚果（金）',
    hi: 'कांगो लोकतान्त्रिक गणराज्य',
    bn: 'গণতান্ত্রিক কঙ্গো প্রজাতন্ত্র',
    pt: 'República Popular do Congo',
    ru: 'Демократическая Республика Конго'
  }
}, {
  alpha2: 'cg',
  alpha3: 'cog',
  translations: {
    de: 'Republik Kongo',
    en: 'Congo',
    fr: 'Congo',
    it: 'Congo',
    es: 'Congo',
    ar: 'جمهورية الكونغو',
    zh: '刚果（布）',
    hi: 'कांगो',
    bn: 'কঙ্গো প্রজাতন্ত্র',
    pt: 'República Democrática do Congo',
    ru: 'Республика Конго'
  }
}, {
  alpha2: 'ck',
  alpha3: 'cok',
  translations: {
    de: 'Cookinseln',
    en: 'Cook Islands',
    fr: 'Îles Cook',
    it: 'Isole Cook',
    es: 'Islas Cook',
    ar: 'جزر كوك',
    zh: '库克群岛',
    hi: 'कुक द्वीप',
    bn: 'কুক দ্বীপপুঞ্জ',
    pt: 'Ilhas Cook',
    ru: 'Острова Кука'
  }
}, {
  alpha2: 'cr',
  alpha3: 'cri',
  translations: {
    de: 'Costa Rica',
    en: 'Costa Rica',
    fr: 'Costa Rica',
    it: 'Costa Rica',
    es: 'Costa Rica',
    ar: 'كوستاريكا',
    zh: '哥斯达黎加',
    hi: 'कोस्टा रिका',
    bn: 'কোস্টা রিকা',
    pt: 'Costa Rica',
    ru: 'Коста-Рика'
  }
}, {
  alpha2: 'ci',
  alpha3: 'civ',
  translations: {
    de: 'Côte d’Ivoire',
    en: 'Côte d’Ivoire',
    fr: 'Côte d’Ivoire',
    it: 'Costa d’Avorio',
    es: 'Costa de Marfil',
    ar: 'ساحل العاج',
    zh: '科特迪瓦',
    hi: 'आइवरी कोस्ट',
    bn: 'কোত দিভোয়ার',
    pt: 'Costa do Marfim',
    ru: 'Кот-д’Ивуар'
  }
}, {
  alpha2: 'hr',
  alpha3: 'hrv',
  translations: {
    de: 'Kroatien',
    en: 'Croatia',
    fr: 'Croatie',
    it: 'Croazia',
    es: 'Croacia',
    ar: 'كرواتيا',
    zh: '克罗地亚',
    hi: 'क्रोएशिया',
    bn: 'ক্রোয়েশিয়া',
    pt: 'Croácia',
    ru: 'Хорватия'
  }
}, {
  alpha2: 'cu',
  alpha3: 'cub',
  translations: {
    de: 'Kuba',
    en: 'Cuba',
    fr: 'Cuba',
    it: 'Cuba',
    es: 'Cuba',
    ar: 'كوبا',
    zh: '古巴',
    hi: 'क्यूबा',
    bn: 'কিউবা',
    pt: 'Cuba',
    ru: 'Куба'
  }
}, {
  alpha2: 'cw',
  alpha3: 'cuw',
  translations: {
    de: 'Curaçao',
    en: 'Curaçao',
    fr: 'Curaçao',
    it: 'Curaçao',
    es: 'Curazao',
    ar: 'كوراساو',
    zh: '库拉索',
    hi: 'कुराकाओ',
    bn: 'কিউরাসাও',
    pt: 'Curaçao',
    ru: 'Кюрасао'
  }
}, {
  alpha2: 'cy',
  alpha3: 'cyp',
  translations: {
    de: 'Zypern',
    en: 'Cyprus',
    fr: 'Chypre',
    it: 'Cipro',
    es: 'Chipre',
    ar: 'قبرص',
    zh: '塞浦路斯',
    hi: 'साइप्रस',
    bn: 'সাইপ্রাস',
    pt: 'Chipre',
    ru: 'Кипр'
  }
}, {
  alpha2: 'cz',
  alpha3: 'cze',
  translations: {
    de: 'Tschechien',
    en: 'Czechia',
    fr: 'Tchéquie',
    it: 'Cechia',
    es: 'Chequia',
    ar: 'جمهورية التشيك',
    zh: '捷克',
    hi: 'चेक',
    bn: 'চেক প্রজাতন্ত্র',
    pt: 'Chéquia',
    ru: 'Чехия'
  }
}, {
  alpha2: 'dk',
  alpha3: 'dnk',
  translations: {
    de: 'Dänemark',
    en: 'Denmark',
    fr: 'Danemark',
    it: 'Danimarca',
    es: 'Dinamarca',
    ar: 'الدنمارك',
    zh: '丹麦',
    hi: 'डैनमार्क',
    bn: 'ডেনমার্ক',
    pt: 'Dinamarca',
    ru: 'Дания'
  }
}, {
  alpha2: 'dj',
  alpha3: 'dji',
  translations: {
    de: 'Dschibuti',
    en: 'Djibouti',
    fr: 'Djibouti',
    it: 'Gibuti',
    es: 'Yibuti',
    ar: 'جيبوتي',
    zh: '吉布提',
    hi: 'जिबॉती',
    bn: 'জিবুতি',
    pt: 'Djibouti',
    ru: 'Джибути'
  }
}, {
  alpha2: 'dm',
  alpha3: 'dma',
  translations: {
    de: 'Dominica',
    en: 'Dominica',
    fr: 'Dominique',
    it: 'Dominica',
    es: 'Dominica',
    ar: 'دومينيكا',
    zh: '多米尼克',
    hi: 'डोमिनिक',
    bn: 'ডোমিনিকা',
    pt: 'Dominica',
    ru: 'Доминика'
  }
}, {
  alpha2: 'do',
  alpha3: 'dom',
  translations: {
    de: 'Dominikanische Republik',
    en: 'Dominican Republic',
    fr: 'République dominicaine',
    it: 'Repubblica Dominicana',
    es: 'República Dominicana',
    ar: 'جمهورية الدومينيكان',
    zh: '多米尼加',
    hi: 'डोमिनिक गणतंत्र',
    bn: 'ডোমিনিকান প্রজাতন্ত্র',
    pt: 'República Dominicana',
    ru: 'Доминиканская Республика'
  }
}, {
  alpha2: 'ec',
  alpha3: 'ecu',
  translations: {
    de: 'Ecuador',
    en: 'Ecuador',
    fr: 'Équateur',
    it: 'Ecuador',
    es: 'Ecuador',
    ar: 'الإكوادور',
    zh: '厄瓜多尔',
    hi: 'इक्वाडोर',
    bn: 'ইকুয়েডর',
    pt: 'Equador',
    ru: 'Эквадор'
  }
}, {
  alpha2: 'eg',
  alpha3: 'egy',
  translations: {
    de: 'Ägypten',
    en: 'Egypt',
    fr: 'Égypte',
    it: 'Egitto',
    es: 'Egipto',
    ar: 'مصر',
    zh: '埃及',
    hi: 'मिस्र',
    bn: 'মিশর',
    pt: 'Egito',
    ru: 'Египет'
  }
}, {
  alpha2: 'sv',
  alpha3: 'slv',
  translations: {
    de: 'El Salvador',
    en: 'El Salvador',
    fr: 'El Salvador',
    it: 'El Salvador',
    es: 'El Salvador',
    ar: 'السلفادور',
    zh: '萨尔瓦多',
    hi: 'सल्वाडोर',
    bn: 'এল সালভাদোর',
    pt: 'El Salvador',
    ru: 'Сальвадор'
  }
}, {
  alpha2: 'gq',
  alpha3: 'gnq',
  translations: {
    de: 'Äquatorialguinea',
    en: 'Equatorial Guinea',
    fr: 'Guinée équatoriale',
    it: 'Guinea Equatoriale',
    es: 'Guinea Ecuatorial',
    ar: 'غينيا الاستوائية',
    zh: '赤道几内亚',
    hi: 'एक्वेटोरियल गिनी',
    bn: 'বিষুবীয় গিনি',
    pt: 'Guiné Equatorial',
    ru: 'Экваториальная Гвинея'
  }
}, {
  alpha2: 'er',
  alpha3: 'eri',
  translations: {
    de: 'Eritrea',
    en: 'Eritrea',
    fr: 'Érythrée',
    it: 'Eritrea',
    es: 'Eritrea',
    ar: 'إريتريا',
    zh: '厄立特里亚',
    hi: 'एरिट्रेया',
    bn: 'ইরিত্রিয়া',
    pt: 'Eritreia',
    ru: 'Эритрея'
  }
}, {
  alpha2: 'ee',
  alpha3: 'est',
  translations: {
    de: 'Estland',
    en: 'Estonia',
    fr: 'Estonie',
    it: 'Estonia',
    es: 'Estonia',
    ar: 'إستونيا',
    zh: '爱沙尼亚',
    hi: 'एस्तोनिया',
    bn: 'এস্তোনিয়া',
    pt: 'Estónia',
    ru: 'Эстония'
  }
}, {
  alpha2: 'et',
  alpha3: 'eth',
  translations: {
    de: 'Äthiopien',
    en: 'Ethiopia',
    fr: 'Éthiopie',
    it: 'Etiopia',
    es: 'Etiopía',
    ar: 'إثيوبيا',
    zh: '埃塞俄比亚',
    hi: 'इथियोपिया',
    bn: 'ইথিওপিয়া',
    pt: 'Etiópia',
    ru: 'Эфиопия'
  }
}, {
  alpha2: 'fk',
  alpha3: 'flk',
  translations: {
    de: 'Falklandinseln',
    en: 'Falkland Islands (Malvinas)',
    fr: 'Îles Falkland (Malvinas)',
    it: 'Isole Falkland (Malvine)',
    es: 'Islas Malvinas',
    ar: 'جزر فوكلاند',
    zh: '福克兰群岛',
    hi: 'फाकलैंड द्वीप समूह (मालविनास)',
    bn: 'ফক্‌ল্যান্ড দ্বীপপুঞ্জ (মালভিনাস)',
    pt: 'Ilhas Malvinas',
    ru: 'Фолклендские острова'
  }
}, {
  alpha2: 'fo',
  alpha3: 'fro',
  translations: {
    de: 'Färöer',
    en: 'Faroe Islands',
    fr: 'Îles Féroé',
    it: 'Isole Fær Øer',
    es: 'Islas Feroe',
    ar: 'جزر فارو',
    zh: '法罗群岛',
    hi: 'फराओ द्वीप समूह',
    bn: 'ফারো দ্বীপপুঞ্জ',
    pt: 'Ilhas Faroé',
    ru: 'Фареры'
  }
}, {
  alpha2: 'fj',
  alpha3: 'fji',
  translations: {
    de: 'Fidschi',
    en: 'Fiji',
    fr: 'Fidji',
    it: 'Figi',
    es: 'Fiyi',
    ar: 'فيجي',
    zh: '斐济',
    hi: 'फिजी',
    bn: 'ফিজি',
    pt: 'Fiji',
    ru: 'Фиджи'
  }
}, {
  alpha2: 'fi',
  alpha3: 'fin',
  translations: {
    de: 'Finnland',
    en: 'Finland',
    fr: 'Finlande',
    it: 'Finlandia',
    es: 'Finlandia',
    ar: 'فنلندا',
    zh: '芬兰',
    hi: 'फिनलैंड',
    bn: 'ফিনল্যান্ড',
    pt: 'Finlândia',
    ru: 'Финляндия'
  }
}, {
  alpha2: 'fr',
  alpha3: 'fra',
  translations: {
    de: 'Frankreich',
    en: 'France',
    fr: 'France',
    it: 'Francia',
    es: 'Francia',
    ar: 'فرنسا',
    zh: '法国',
    hi: 'फ्रांस',
    bn: 'ফ্রান্স',
    pt: 'França',
    ru: 'Франция'
  }
}, {
  alpha2: 'gf',
  alpha3: 'guf',
  translations: {
    de: 'Französisch-Guayana',
    en: 'French Guiana',
    fr: 'Guyane française',
    it: 'Guyana francese',
    es: 'Guayana Francesa',
    ar: 'غويانا الفرنسية',
    zh: '法属圭亚那',
    hi: 'फ्रेंच गुआना',
    bn: 'ফরাসি গায়ানা',
    pt: 'Guiana Francesa',
    ru: 'Гвиана'
  }
}, {
  alpha2: 'pf',
  alpha3: 'pyf',
  translations: {
    de: 'Französisch-Polynesien',
    en: 'French Polynesia',
    fr: 'Polynésie française',
    it: 'Polinesia francese',
    es: 'Polinesia Francesa',
    ar: 'بولينزيا الفرنسية',
    zh: '法属波利尼西亚',
    hi: 'फ्रैंच गुआना',
    bn: 'ফরাসি পলিনেশিয়া',
    pt: 'Polinésia Francesa',
    ru: 'Французская Полинезия'
  }
}, {
  alpha2: 'tf',
  alpha3: 'atf',
  translations: {
    de: 'Französische Süd- und Antarktisgebiete',
    en: 'French Southern Territories',
    fr: 'Terres australes françaises',
    it: 'Territori Francesi del Sud',
    es: 'Territorios Australes Franceses',
    ar: 'أراض فرنسية جنوبية وأنتارتيكية',
    zh: '法属南部领地',
    hi: 'फ्रांसीसी दक्षिणी क्षेत्र',
    bn: 'ফরাসি সাউদার্ন টেরিটোরিজ',
    pt: 'Terras Austrais e Antárticas Francesas',
    ru: 'Французские Южные и Антарктические Территории'
  }
}, {
  alpha2: 'ga',
  alpha3: 'gab',
  translations: {
    de: 'Gabun',
    en: 'Gabon',
    fr: 'Gabon',
    it: 'Gabon',
    es: 'Gabón',
    ar: 'الغابون',
    zh: '加蓬',
    hi: 'गैबोन',
    bn: 'গাবন',
    pt: 'Gabão',
    ru: 'Габон'
  }
}, {
  alpha2: 'gm',
  alpha3: 'gmb',
  translations: {
    de: 'Gambia',
    en: 'Gambia',
    fr: 'Gambie',
    it: 'Gambia',
    es: 'Gambia',
    ar: 'غامبيا',
    zh: '冈比亚',
    hi: 'गाम्बिया',
    bn: 'গাম্বিয়া',
    pt: 'Gâmbia',
    ru: 'Гамбия'
  }
}, {
  alpha2: 'ge',
  alpha3: 'geo',
  translations: {
    de: 'Georgien',
    en: 'Georgia',
    fr: 'Géorgie',
    it: 'Georgia',
    es: 'Georgia',
    ar: 'جورجيا',
    zh: '格鲁吉亚',
    hi: 'जॉर्जिया',
    bn: 'জর্জিয়া',
    pt: 'Geórgia',
    ru: 'Грузия'
  }
}, {
  alpha2: 'de',
  alpha3: 'deu',
  translations: {
    de: 'Deutschland',
    en: 'Germany',
    fr: 'Allemagne',
    it: 'Germania',
    es: 'Alemania',
    ar: 'ألمانيا',
    zh: '德国',
    hi: 'जर्मनी',
    bn: 'জার্মানি',
    pt: 'Alemanha',
    ru: 'Германия'
  }
}, {
  alpha2: 'gh',
  alpha3: 'gha',
  translations: {
    de: 'Ghana',
    en: 'Ghana',
    fr: 'Ghana',
    it: 'Ghana',
    es: 'Ghana',
    ar: 'غانا',
    zh: '加纳',
    hi: 'घाना',
    bn: 'ঘানা',
    pt: 'Gana',
    ru: 'Гана'
  }
}, {
  alpha2: 'gi',
  alpha3: 'gib',
  translations: {
    de: 'Gibraltar',
    en: 'Gibraltar',
    fr: 'Gibraltar',
    it: 'Gibilterra',
    es: 'Gibraltar',
    ar: 'جبل طارق',
    zh: '直布罗陀',
    hi: 'जिब्राल्टर',
    bn: 'জিব্রাল্টার',
    pt: 'Gibraltar',
    ru: 'Гибралтар'
  }
}, {
  alpha2: 'gr',
  alpha3: 'grc',
  translations: {
    de: 'Griechenland',
    en: 'Greece',
    fr: 'Grèce',
    it: 'Grecia',
    es: 'Grecia',
    ar: 'اليونان',
    zh: '希腊',
    hi: 'यूनान',
    bn: 'গ্রিস',
    pt: 'Grécia',
    ru: 'Греция'
  }
}, {
  alpha2: 'gl',
  alpha3: 'grl',
  translations: {
    de: 'Grönland',
    en: 'Greenland',
    fr: 'Groenland',
    it: 'Groenlandia',
    es: 'Groenlandia',
    ar: 'جرينلاند',
    zh: '格陵兰',
    hi: 'ग्रीनलैंड',
    bn: 'গ্রিনল্যান্ড',
    pt: 'Gronelândia',
    ru: 'Гренландия'
  }
}, {
  alpha2: 'gd',
  alpha3: 'grd',
  translations: {
    de: 'Grenada',
    en: 'Grenada',
    fr: 'Grenade',
    it: 'Grenada',
    es: 'Granada',
    ar: 'غرينادا',
    zh: '格林纳达',
    hi: 'ग्रेनेडा',
    bn: 'গ্রানাডা',
    pt: 'Granada',
    ru: 'Гренада'
  }
}, {
  alpha2: 'gp',
  alpha3: 'glp',
  translations: {
    de: 'Guadeloupe',
    en: 'Guadeloupe',
    fr: 'Guadeloupe',
    it: 'Guadalupa',
    es: 'Guadalupe',
    ar: 'غوادلوب',
    zh: '瓜德罗普',
    hi: 'ग्वाडेलोप',
    bn: 'গুয়াদলুপ',
    pt: 'Guadalupe',
    ru: 'Гваделупа'
  }
}, {
  alpha2: 'gu',
  alpha3: 'gum',
  translations: {
    de: 'Guam',
    en: 'Guam',
    fr: 'Guam',
    it: 'Guam',
    es: 'Guam',
    ar: 'غوام',
    zh: '关岛',
    hi: 'ग्वाम',
    bn: 'গুয়াম',
    pt: 'Guam',
    ru: 'Гуам'
  }
}, {
  alpha2: 'gt',
  alpha3: 'gtm',
  translations: {
    de: 'Guatemala',
    en: 'Guatemala',
    fr: 'Guatemala',
    it: 'Guatemala',
    es: 'Guatemala',
    ar: 'غواتيمالا',
    zh: '危地马拉',
    hi: 'ग्वाटेमाला',
    bn: 'গুয়াতেমালা',
    pt: 'Guatemala',
    ru: 'Гватемала'
  }
}, {
  alpha2: 'gg',
  alpha3: 'ggy',
  translations: {
    de: 'Guernsey',
    en: 'Guernsey',
    fr: 'Guernesey',
    it: 'Guernsey',
    es: 'Guernsey',
    ar: 'غيرنزي',
    zh: '根西',
    hi: 'ग्वेर्नसे',
    bn: 'Guernsey',
    pt: 'Guernsey',
    ru: 'Гернси'
  }
}, {
  alpha2: 'gn',
  alpha3: 'gin',
  translations: {
    de: 'Guinea',
    en: 'Guinea',
    fr: 'Guinée',
    it: 'Guinea',
    es: 'Guinea',
    ar: 'غينيا',
    zh: '几内亚',
    hi: 'गिनी',
    bn: 'গিনি',
    pt: 'Guiné',
    ru: 'Гвинея'
  }
}, {
  alpha2: 'gw',
  alpha3: 'gnb',
  translations: {
    de: 'Guinea-Bissau',
    en: 'Guinea-Bissau',
    fr: 'Guinée-Bissau',
    it: 'Guinea-Bissau',
    es: 'Guinea-Bisáu',
    ar: 'غينيا بيساو',
    zh: '几内亚比绍',
    hi: 'गिनी-बिसाउ',
    bn: 'গিনি-বিসাউ',
    pt: 'Guiné-Bissau',
    ru: 'Гвинея-Бисау'
  }
}, {
  alpha2: 'gy',
  alpha3: 'guy',
  translations: {
    de: 'Guyana',
    en: 'Guyana',
    fr: 'Guyana',
    it: 'Guyana',
    es: 'Guyana',
    ar: 'غيانا',
    zh: '圭亚那',
    hi: 'गुआना',
    bn: 'গায়ানা',
    pt: 'Guiana',
    ru: 'Гайана'
  }
}, {
  alpha2: 'ht',
  alpha3: 'hti',
  translations: {
    de: 'Haiti',
    en: 'Haiti',
    fr: 'Haïti',
    it: 'Haiti',
    es: 'Haití',
    ar: 'هايتي',
    zh: '海地',
    hi: 'हैती',
    bn: 'হাইতি',
    pt: 'Haiti',
    ru: 'Гаити'
  }
}, {
  alpha2: 'hm',
  alpha3: 'hmd',
  translations: {
    de: 'Heard und McDonaldinseln',
    en: 'Heard Island and McDonald Islands',
    fr: 'Îles Heard et McDonald',
    it: 'Isole Heard e McDonald',
    es: 'Islas Heard y McDonald',
    ar: 'جزيرة هيرد وجزر ماكدونالد',
    zh: '赫德岛和麦克唐纳群岛',
    hi: 'हर्ड एंड मैकडोनाल्ड द्वीपसमूह',
    bn: 'হার্ড দ্বীপ এবং ম্যাকডোনাল্ড দ্বীপপুঞ্জ',
    pt: 'Ilha Heard e Ilhas McDonald',
    ru: 'Херд и Макдональд'
  }
}, {
  alpha2: 'va',
  alpha3: 'vat',
  translations: {
    de: 'Heiliger Stuhl (Vatikanstadt)',
    en: 'Holy See',
    fr: 'Saint-Siège',
    it: 'Santa Sede',
    es: 'Santa Sede',
    ar: 'الفاتيكان',
    zh: '梵蒂冈',
    hi: 'वेटिकन',
    bn: 'ভ্যাটিকান সিটি',
    pt: 'Santa Sé',
    ru: 'Ватикан'
  }
}, {
  alpha2: 'hn',
  alpha3: 'hnd',
  translations: {
    de: 'Honduras',
    en: 'Honduras',
    fr: 'Honduras',
    it: 'Honduras',
    es: 'Honduras',
    ar: 'هندوراس',
    zh: '洪都拉斯',
    hi: 'होंडुरास',
    bn: 'হন্ডুরাস',
    pt: 'Honduras',
    ru: 'Гондурас'
  }
}, {
  alpha2: 'hk',
  alpha3: 'hkg',
  translations: {
    de: 'Sonderverwaltungszone Hongkong',
    en: 'Hong Kong',
    fr: 'Hong Kong',
    it: 'Hong Kong',
    es: 'Hong Kong',
    ar: 'هونغ كونغ',
    zh: '香港',
    hi: 'हांग कांग',
    bn: 'হংকং',
    pt: 'Hong Kong',
    ru: 'Гонконг'
  }
}, {
  alpha2: 'hu',
  alpha3: 'hun',
  translations: {
    de: 'Ungarn',
    en: 'Hungary',
    fr: 'Hongrie',
    it: 'Ungheria',
    es: 'Hungría',
    ar: 'المجر',
    zh: '匈牙利',
    hi: 'हंगरी',
    bn: 'হাঙ্গেরি',
    pt: 'Hungria',
    ru: 'Венгрия'
  }
}, {
  alpha2: 'is',
  alpha3: 'isl',
  translations: {
    de: 'Island',
    en: 'Iceland',
    fr: 'Islande',
    it: 'Islanda',
    es: 'Islandia',
    ar: 'آيسلندا',
    zh: '冰岛',
    hi: 'आइसलैंड',
    bn: 'আইসল্যান্ড',
    pt: 'Islândia',
    ru: 'Исландия'
  }
}, {
  alpha2: 'in',
  alpha3: 'ind',
  translations: {
    de: 'Indien',
    en: 'India',
    fr: 'Inde',
    it: 'India',
    es: 'India',
    ar: 'الهند',
    zh: '印度',
    hi: 'भारत',
    bn: 'ভারত',
    pt: 'Índia',
    ru: 'Индия'
  }
}, {
  alpha2: 'id',
  alpha3: 'idn',
  translations: {
    de: 'Indonesien',
    en: 'Indonesia',
    fr: 'Indonésie',
    it: 'Indonesia',
    es: 'Indonesia',
    ar: 'إندونيسيا',
    zh: '印尼',
    hi: 'इंडोनेशिया',
    bn: 'ইন্দোনেশিয়া',
    pt: 'Indonésia',
    ru: 'Индонезия'
  }
}, {
  alpha2: 'ir',
  alpha3: 'irn',
  translations: {
    de: 'Iran',
    en: 'Iran (Islamic Republic of)',
    fr: 'Iran',
    it: 'Iran',
    es: 'Irán',
    ar: 'إيران',
    zh: '伊朗',
    hi: 'ईरान',
    bn: 'ইরান',
    pt: 'Irão',
    ru: 'Иран'
  }
}, {
  alpha2: 'iq',
  alpha3: 'irq',
  translations: {
    de: 'Irak',
    en: 'Iraq',
    fr: 'Iraq',
    it: 'Iraq',
    es: 'Irak',
    ar: 'العراق',
    zh: '伊拉克',
    hi: 'इराक',
    bn: 'ইরাক',
    pt: 'Iraque',
    ru: 'Ирак'
  }
}, {
  alpha2: 'ie',
  alpha3: 'irl',
  translations: {
    de: 'Irland',
    en: 'Ireland',
    fr: 'Irlande',
    it: 'Irlanda',
    es: 'Irlanda',
    ar: 'أيرلندا',
    zh: '爱尔兰',
    hi: 'आयरलैंड',
    bn: 'প্রজাতন্ত্রী আয়ারল্যান্ড',
    pt: 'Irlanda',
    ru: 'Ирландия'
  }
}, {
  alpha2: 'im',
  alpha3: 'imn',
  translations: {
    de: 'Isle of Man',
    en: 'Isle of Man',
    fr: 'Île de Man',
    it: 'Isola di Man',
    es: 'Isla de Man',
    ar: 'جزيرة مان',
    zh: '马恩岛',
    hi: 'आइसल ऑफ मैन',
    bn: 'আইল অব ম্যান',
    pt: 'Ilha de Man',
    ru: 'Остров Мэн'
  }
}, {
  alpha2: 'il',
  alpha3: 'isr',
  translations: {
    de: 'Israel',
    en: 'Israel',
    fr: 'Israël',
    it: 'Israele',
    es: 'Israel',
    ar: 'إسرائيل',
    zh: '以色列',
    hi: 'इजराइल',
    bn: 'ইসরায়েল',
    pt: 'Israel',
    ru: 'Израиль'
  }
}, {
  alpha2: 'it',
  alpha3: 'ita',
  translations: {
    de: 'Italien',
    en: 'Italy',
    fr: 'Italie',
    it: 'Italia',
    es: 'Italia',
    ar: 'إيطاليا',
    zh: '意大利',
    hi: 'इटली',
    bn: 'ইতালি',
    pt: 'Itália',
    ru: 'Италия'
  }
}, {
  alpha2: 'jm',
  alpha3: 'jam',
  translations: {
    de: 'Jamaika',
    en: 'Jamaica',
    fr: 'Jamaïque',
    it: 'Giamaica',
    es: 'Jamaica',
    ar: 'جامايكا',
    zh: '牙买加',
    hi: 'जमैका',
    bn: 'জামাইকা',
    pt: 'Jamaica',
    ru: 'Ямайка'
  }
}, {
  alpha2: 'jp',
  alpha3: 'jpn',
  translations: {
    de: 'Japan',
    en: 'Japan',
    fr: 'Japon',
    it: 'Giappone',
    es: 'Japón',
    ar: 'اليابان',
    zh: '日本',
    hi: 'जापान',
    bn: 'জাপান',
    pt: 'Japão',
    ru: 'Япония'
  }
}, {
  alpha2: 'je',
  alpha3: 'jey',
  translations: {
    de: 'Jersey',
    en: 'Jersey',
    fr: 'Jersey',
    it: 'Jersey',
    es: 'Jersey',
    ar: 'جيرزي',
    zh: '泽西',
    hi: 'जर्सी',
    bn: 'Jersey',
    pt: 'Jersey',
    ru: 'Джерси'
  }
}, {
  alpha2: 'jo',
  alpha3: 'jor',
  translations: {
    de: 'Jordanien',
    en: 'Jordan',
    fr: 'Jordanie',
    it: 'Giordania',
    es: 'Jordania',
    ar: 'الأردن',
    zh: '约旦',
    hi: 'जॉर्डन',
    bn: 'জর্দান',
    pt: 'Jordânia',
    ru: 'Иордания'
  }
}, {
  alpha2: 'kz',
  alpha3: 'kaz',
  translations: {
    de: 'Kasachstan',
    en: 'Kazakhstan',
    fr: 'Kazakhstan',
    it: 'Kazakistan',
    es: 'Kazajistán',
    ar: 'كازاخستان',
    zh: '哈萨克斯坦',
    hi: 'कज़ाखिस्तान',
    bn: 'কাজাখস্তান',
    pt: 'Cazaquistão',
    ru: 'Казахстан'
  }
}, {
  alpha2: 'ke',
  alpha3: 'ken',
  translations: {
    de: 'Kenia',
    en: 'Kenya',
    fr: 'Kenya',
    it: 'Kenya',
    es: 'Kenia',
    ar: 'كينيا',
    zh: '肯尼亚',
    hi: 'केन्या',
    bn: 'কেনিয়া',
    pt: 'Quénia',
    ru: 'Кения'
  }
}, {
  alpha2: 'ki',
  alpha3: 'kir',
  translations: {
    de: 'Kiribati',
    en: 'Kiribati',
    fr: 'Kiribati',
    it: 'Kiribati',
    es: 'Kiribati',
    ar: 'كيريباتي',
    zh: '基里巴斯',
    hi: 'किरिबिती',
    bn: 'কিরিবাস',
    pt: 'Quiribati',
    ru: 'Кирибати'
  }
}, {
  alpha2: 'kp',
  alpha3: 'prk',
  translations: {
    de: 'Nordkorea',
    en: 'Korea (Democratic People’s Republic of)',
    fr: 'Corée (République populaire démocratique de)',
    it: 'Corea (Repubblica Popolare Democratica di)',
    es: 'Corea (República Popular Democrática de)',
    ar: 'كوريا الشمالية',
    zh: '朝鲜',
    hi: 'उत्तर कोरिया',
    bn: 'কোরিয়া গণতান্ত্রিক গণপ্রজাতন্ত্রী',
    pt: 'Coreia do Norte',
    ru: 'КНДР (Корейская Народно-Демократическая Республика)'
  }
}, {
  alpha2: 'kr',
  alpha3: 'kor',
  translations: {
    de: 'Südkorea',
    en: 'Korea, Republic of',
    fr: 'Corée (République de)',
    it: 'Corea, Repubblica di',
    es: 'Corea, República de',
    ar: 'كوريا الجنوبية',
    zh: '韩国',
    hi: 'दक्षिण कोरिया',
    bn: 'কোরিয়া প্রজাতন্ত্র',
    pt: 'Coreia do Sul',
    ru: 'Республика Корея'
  }
}, {
  alpha2: 'kw',
  alpha3: 'kwt',
  translations: {
    de: 'Kuwait',
    en: 'Kuwait',
    fr: 'Koweït',
    it: 'Kuwait',
    es: 'Kuwait',
    ar: 'الكويت',
    zh: '科威特',
    hi: 'कुवैत',
    bn: 'কুয়েত',
    pt: 'Koweit',
    ru: 'Кувейт'
  }
}, {
  alpha2: 'kg',
  alpha3: 'kgz',
  translations: {
    de: 'Kirgisistan',
    en: 'Kyrgyzstan',
    fr: 'Kirghizistan',
    it: 'Kirghizistan',
    es: 'Kirguistán',
    ar: 'قيرغيزستان',
    zh: '吉尔吉斯斯坦',
    hi: 'किर्जिस्तान',
    bn: 'কির্গিজস্তান',
    pt: 'Quirguistão',
    ru: 'Киргизия'
  }
}, {
  alpha2: 'la',
  alpha3: 'lao',
  translations: {
    de: 'Laos',
    en: 'Lao People’s Democratic Republic',
    fr: 'Laos',
    it: 'Laos',
    es: 'Laos',
    ar: 'لاوس',
    zh: '老挝',
    hi: 'लाओस',
    bn: 'গণতান্ত্রিক গণপ্রজাতন্ত্রী লাওস',
    pt: 'Laos',
    ru: 'Лаос'
  }
}, {
  alpha2: 'lv',
  alpha3: 'lva',
  translations: {
    de: 'Lettland',
    en: 'Latvia',
    fr: 'Lettonie',
    it: 'Lettonia',
    es: 'Letonia',
    ar: 'لاتفيا',
    zh: '拉脱维亚',
    hi: 'लात्विया',
    bn: 'লাতভিয়া',
    pt: 'Letónia',
    ru: 'Латвия'
  }
}, {
  alpha2: 'lb',
  alpha3: 'lbn',
  translations: {
    de: 'Libanon',
    en: 'Lebanon',
    fr: 'Liban',
    it: 'Libano',
    es: 'Líbano',
    ar: 'لبنان',
    zh: '黎巴嫩',
    hi: 'लेबनान',
    bn: 'লেবানন',
    pt: 'Líbano',
    ru: 'Ливан'
  }
}, {
  alpha2: 'ls',
  alpha3: 'lso',
  translations: {
    de: 'Lesotho',
    en: 'Lesotho',
    fr: 'Lesotho',
    it: 'Lesotho',
    es: 'Lesoto',
    ar: 'ليسوتو',
    zh: '莱索托',
    hi: 'लेसोथो',
    bn: 'লেসোথো',
    pt: 'Lesoto',
    ru: 'Лесото'
  }
}, {
  alpha2: 'lr',
  alpha3: 'lbr',
  translations: {
    de: 'Liberia',
    en: 'Liberia',
    fr: 'Libéria',
    it: 'Liberia',
    es: 'Liberia',
    ar: 'ليبيريا',
    zh: '利比里亚',
    hi: 'लाइबेरिया',
    bn: 'লাইবেরিয়া',
    pt: 'Libéria',
    ru: 'Либерия'
  }
}, {
  alpha2: 'ly',
  alpha3: 'lby',
  translations: {
    de: 'Libyen',
    en: 'Libya',
    fr: 'Libye',
    it: 'Libia',
    es: 'Libia',
    ar: 'ليبيا',
    zh: '利比亚',
    hi: 'लीबिया',
    bn: 'লিবিয়া',
    pt: 'Líbia',
    ru: 'Ливия'
  }
}, {
  alpha2: 'li',
  alpha3: 'lie',
  translations: {
    de: 'Liechtenstein',
    en: 'Liechtenstein',
    fr: 'Liechtenstein',
    it: 'Liechtenstein',
    es: 'Liechtenstein',
    ar: 'ليختنشتاين',
    zh: '列支敦士登',
    hi: 'लिक्टेनिस्टीन',
    bn: 'লিশটেনস্টাইন',
    pt: 'Liechtenstein',
    ru: 'Лихтенштейн'
  }
}, {
  alpha2: 'lt',
  alpha3: 'ltu',
  translations: {
    de: 'Litauen',
    en: 'Lithuania',
    fr: 'Lituanie',
    it: 'Lituania',
    es: 'Lituania',
    ar: 'ليتوانيا',
    zh: '立陶宛',
    hi: 'लिथुआनिया',
    bn: 'লিথুয়ানিয়া',
    pt: 'Lituânia',
    ru: 'Литва'
  }
}, {
  alpha2: 'lu',
  alpha3: 'lux',
  translations: {
    de: 'Luxemburg',
    en: 'Luxembourg',
    fr: 'Luxembourg',
    it: 'Lussemburgo',
    es: 'Luxemburgo',
    ar: 'لوكسمبورغ',
    zh: '卢森堡',
    hi: 'लक्जमबर्ग',
    bn: 'লুক্সেমবুর্গ',
    pt: 'Luxemburgo',
    ru: 'Люксембург'
  }
}, {
  alpha2: 'mo',
  alpha3: 'mac',
  translations: {
    de: 'Sonderverwaltungszone Macao',
    en: 'Macao',
    fr: 'Macao',
    it: 'Macao',
    es: 'Macao',
    ar: 'ماكاو',
    zh: '澳门',
    hi: 'मकाओ',
    bn: 'মাকাও',
    pt: 'Macau',
    ru: 'Макао'
  }
}, {
  alpha2: 'mg',
  alpha3: 'mdg',
  translations: {
    de: 'Madagaskar',
    en: 'Madagascar',
    fr: 'Madagascar',
    it: 'Madagascar',
    es: 'Madagascar',
    ar: 'مدغشقر',
    zh: '马达加斯加',
    hi: 'मैडागास्कर',
    bn: 'মাদাগাস্কার',
    pt: 'Madagáscar',
    ru: 'Мадагаскар'
  }
}, {
  alpha2: 'mw',
  alpha3: 'mwi',
  translations: {
    de: 'Malawi',
    en: 'Malawi',
    fr: 'Malawi',
    it: 'Malawi',
    es: 'Malaui',
    ar: 'مالاوي',
    zh: '马拉维',
    hi: 'मालावी',
    bn: 'মালাউই',
    pt: 'Maláui',
    ru: 'Малави'
  }
}, {
  alpha2: 'my',
  alpha3: 'mys',
  translations: {
    de: 'Malaysia',
    en: 'Malaysia',
    fr: 'Malaisie',
    it: 'Malesia',
    es: 'Malasia',
    ar: 'ماليزيا',
    zh: '马来西亚',
    hi: 'मलयेशिया',
    bn: 'মালয়েশিয়া',
    pt: 'Malásia',
    ru: 'Малайзия'
  }
}, {
  alpha2: 'mv',
  alpha3: 'mdv',
  translations: {
    de: 'Malediven',
    en: 'Maldives',
    fr: 'Maldives',
    it: 'Maldive',
    es: 'Maldivas',
    ar: 'جزر المالديف',
    zh: '马尔代夫',
    hi: 'मालदीव',
    bn: 'মালদ্বীপ',
    pt: 'Maldivas',
    ru: 'Мальдивы'
  }
}, {
  alpha2: 'ml',
  alpha3: 'mli',
  translations: {
    de: 'Mali',
    en: 'Mali',
    fr: 'Mali',
    it: 'Mali',
    es: 'Malí',
    ar: 'مالي',
    zh: '马里',
    hi: 'माली',
    bn: 'মালি',
    pt: 'Mali',
    ru: 'Мали'
  }
}, {
  alpha2: 'mt',
  alpha3: 'mlt',
  translations: {
    de: 'Malta',
    en: 'Malta',
    fr: 'Malte',
    it: 'Malta',
    es: 'Malta',
    ar: 'مالطا',
    zh: '马耳他',
    hi: 'माल्टा',
    bn: 'মাল্টা',
    pt: 'Malta',
    ru: 'Мальта'
  }
}, {
  alpha2: 'mh',
  alpha3: 'mhl',
  translations: {
    de: 'Marshallinseln',
    en: 'Marshall Islands',
    fr: 'Îles Marshall',
    it: 'Isole Marshall',
    es: 'Islas Marshall',
    ar: 'جزر مارشال',
    zh: '马绍尔群岛',
    hi: 'मार्शल द्वीपसमूह',
    bn: 'মার্শাল দ্বীপপুঞ্জ',
    pt: 'Ilhas Marshall',
    ru: 'Маршалловы Острова'
  }
}, {
  alpha2: 'mq',
  alpha3: 'mtq',
  translations: {
    de: 'Martinique',
    en: 'Martinique',
    fr: 'Martinique',
    it: 'Martinica',
    es: 'Martinica',
    ar: 'مارتينيك',
    zh: '马提尼克',
    hi: 'मार्टिनिक',
    bn: 'মার্তিনিক',
    pt: 'Martinica',
    ru: 'Мартиника'
  }
}, {
  alpha2: 'mr',
  alpha3: 'mrt',
  translations: {
    de: 'Mauretanien',
    en: 'Mauritania',
    fr: 'Mauritanie',
    it: 'Mauritania',
    es: 'Mauritania',
    ar: 'موريتانيا',
    zh: '毛里塔尼亚',
    hi: 'मॉरितानिया',
    bn: 'মৌরিতানিয়া',
    pt: 'Mauritânia',
    ru: 'Мавритания'
  }
}, {
  alpha2: 'mu',
  alpha3: 'mus',
  translations: {
    de: 'Mauritius',
    en: 'Mauritius',
    fr: 'Maurice',
    it: 'Mauritius',
    es: 'Mauricio',
    ar: 'موريشيوس',
    zh: '毛里求斯',
    hi: 'मारीशस',
    bn: 'মরিশাস',
    pt: 'Maurícia',
    ru: 'Маврикий'
  }
}, {
  alpha2: 'yt',
  alpha3: 'myt',
  translations: {
    de: 'Mayotte',
    en: 'Mayotte',
    fr: 'Mayotte',
    it: 'Mayotte',
    es: 'Mayotte',
    ar: 'مايوت',
    zh: '马约特',
    hi: 'मायोते',
    bn: 'মায়োত',
    pt: 'Mayotte',
    ru: 'Майотта'
  }
}, {
  alpha2: 'mx',
  alpha3: 'mex',
  translations: {
    de: 'Mexiko',
    en: 'Mexico',
    fr: 'Mexique',
    it: 'Messico',
    es: 'México',
    ar: 'المكسيك',
    zh: '墨西哥',
    hi: 'मेक्सिको',
    bn: 'মেক্সিকো',
    pt: 'México',
    ru: 'Мексика'
  }
}, {
  alpha2: 'fm',
  alpha3: 'fsm',
  translations: {
    de: 'Mikronesien',
    en: 'Micronesia (Federated States of)',
    fr: 'Micronésie (États fédérés de)',
    it: 'Micronesia (Stati Federati di)',
    es: 'Micronesia (Estados Federados de)',
    ar: 'ولايات ميكرونيسيا المتحدة',
    zh: '密克罗尼西亚联邦',
    hi: 'माइक्रोनेशिया',
    bn: 'মাইক্রোনেশিয়া যুক্তরাজ্য',
    pt: 'Micronésia',
    ru: 'Микронезия'
  }
}, {
  alpha2: 'md',
  alpha3: 'mda',
  translations: {
    de: 'Republik Moldau',
    en: 'Moldova (Republic of)',
    fr: 'Moldavie (République de)',
    it: 'Moldavia (Repubblica di)',
    es: 'Moldavia (República de)',
    ar: 'مولدوفا',
    zh: '摩尔多瓦',
    hi: 'मोलदोवा',
    bn: 'মলদোভা প্রজাতন্ত্র',
    pt: 'Moldávia',
    ru: 'Молдавия'
  }
}, {
  alpha2: 'mc',
  alpha3: 'mco',
  translations: {
    de: 'Monaco',
    en: 'Monaco',
    fr: 'Monaco',
    it: 'Monaco',
    es: 'Mónaco',
    ar: 'موناكو',
    zh: '摩纳哥',
    hi: 'मोनाको',
    bn: 'মোনাকো',
    pt: 'Mónaco',
    ru: 'Монако'
  }
}, {
  alpha2: 'mn',
  alpha3: 'mng',
  translations: {
    de: 'Mongolei',
    en: 'Mongolia',
    fr: 'Mongolie',
    it: 'Mongolia',
    es: 'Mongolia',
    ar: 'منغوليا',
    zh: '蒙古',
    hi: 'मंगोलिया',
    bn: 'মঙ্গোলিয়া',
    pt: 'Mongólia',
    ru: 'Монголия'
  }
}, {
  alpha2: 'me',
  alpha3: 'mne',
  translations: {
    de: 'Montenegro',
    en: 'Montenegro',
    fr: 'Monténégro',
    it: 'Montenegro',
    es: 'Montenegro',
    ar: 'الجبل الأسود',
    zh: '黑山',
    hi: 'मोंटेनेग्रो',
    bn: 'মন্টিনিগ্রো',
    pt: 'Montenegro',
    ru: 'Черногория'
  }
}, {
  alpha2: 'ms',
  alpha3: 'msr',
  translations: {
    de: 'Montserrat',
    en: 'Montserrat',
    fr: 'Montserrat',
    it: 'Montserrat',
    es: 'Montserrat',
    ar: 'مونتسرات',
    zh: '蒙特塞拉特',
    hi: 'मोंटसेराट',
    bn: 'মন্টসেরাট',
    pt: 'Monserrate',
    ru: 'Монтсеррат'
  }
}, {
  alpha2: 'ma',
  alpha3: 'mar',
  translations: {
    de: 'Marokko',
    en: 'Morocco',
    fr: 'Maroc',
    it: 'Marocco',
    es: 'Marruecos',
    ar: 'المغرب',
    zh: '摩洛哥',
    hi: 'मोरक्को',
    bn: 'মরোক্কো',
    pt: 'Marrocos',
    ru: 'Марокко'
  }
}, {
  alpha2: 'mz',
  alpha3: 'moz',
  translations: {
    de: 'Mosambik',
    en: 'Mozambique',
    fr: 'Mozambique',
    it: 'Mozambico',
    es: 'Mozambique',
    ar: 'موزمبيق',
    zh: '莫桑比克',
    hi: 'मोजांबिक',
    bn: 'মোজাম্বিক',
    pt: 'Moçambique',
    ru: 'Мозамбик'
  }
}, {
  alpha2: 'mm',
  alpha3: 'mmr',
  translations: {
    de: 'Myanmar',
    en: 'Myanmar',
    fr: 'Myanmar',
    it: 'Myanmar',
    es: 'Myanmar',
    ar: 'بورما',
    zh: '缅甸',
    hi: 'म्यामांर (बर्मा)',
    bn: 'মায়ানমার',
    pt: 'Mianmar (Birmânia)',
    ru: 'Мьянма'
  }
}, {
  alpha2: 'na',
  alpha3: 'nam',
  translations: {
    de: 'Namibia',
    en: 'Namibia',
    fr: 'Namibie',
    it: 'Namibia',
    es: 'Namibia',
    ar: 'ناميبيا',
    zh: '纳米比亚',
    hi: 'नामीबिया',
    bn: 'নামিবিয়া',
    pt: 'Namíbia',
    ru: 'Намибия'
  }
}, {
  alpha2: 'nr',
  alpha3: 'nru',
  translations: {
    de: 'Nauru',
    en: 'Nauru',
    fr: 'Nauru',
    it: 'Nauru',
    es: 'Nauru',
    ar: 'ناورو',
    zh: '瑙鲁',
    hi: 'नाउरू',
    bn: 'নাউরু',
    pt: 'Nauru',
    ru: 'Науру'
  }
}, {
  alpha2: 'np',
  alpha3: 'npl',
  translations: {
    de: 'Nepal',
    en: 'Nepal',
    fr: 'Népal',
    it: 'Nepal',
    es: 'Nepal',
    ar: 'نيبال',
    zh: '尼泊尔',
    hi: 'नेपाल',
    bn: 'নেপাল',
    pt: 'Nepal',
    ru: 'Непал'
  }
}, {
  alpha2: 'nl',
  alpha3: 'nld',
  translations: {
    de: 'Niederlande',
    en: 'Netherlands',
    fr: 'Pays-Bas',
    it: 'Paesi Bassi',
    es: 'Países Bajos',
    ar: 'هولندا',
    zh: '荷兰',
    hi: 'नीदरलैंड्स',
    bn: 'নেদারল্যান্ডস',
    pt: 'Países Baixos',
    ru: 'Нидерланды'
  }
}, {
  alpha2: 'nc',
  alpha3: 'ncl',
  translations: {
    de: 'Neukaledonien',
    en: 'New Caledonia',
    fr: 'Nouvelle-Calédonie',
    it: 'Nuova Caledonia',
    es: 'Nueva Caledonia',
    ar: 'كاليدونيا الجديدة',
    zh: '新喀里多尼亚',
    hi: 'न्यू कैलेडोनिया',
    bn: 'নিউ ক্যালিডোনিয়া',
    pt: 'Nova Caledónia',
    ru: 'Новая Каледония'
  }
}, {
  alpha2: 'nz',
  alpha3: 'nzl',
  translations: {
    de: 'Neuseeland',
    en: 'New Zealand',
    fr: 'Nouvelle-Zélande',
    it: 'Nuova Zelanda',
    es: 'Nueva Zelanda',
    ar: 'نيوزيلندا',
    zh: '新西兰',
    hi: 'न्यूजीलैंड',
    bn: 'নিউজিল্যান্ড',
    pt: 'Nova Zelândia',
    ru: 'Новая Зеландия'
  }
}, {
  alpha2: 'ni',
  alpha3: 'nic',
  translations: {
    de: 'Nicaragua',
    en: 'Nicaragua',
    fr: 'Nicaragua',
    it: 'Nicaragua',
    es: 'Nicaragua',
    ar: 'نيكاراغوا',
    zh: '尼加拉瓜',
    hi: 'निकारागुआ',
    bn: 'নিকারাগুয়া',
    pt: 'Nicarágua',
    ru: 'Никарагуа'
  }
}, {
  alpha2: 'ne',
  alpha3: 'ner',
  translations: {
    de: 'Niger',
    en: 'Niger',
    fr: 'Niger',
    it: 'Niger',
    es: 'Níger',
    ar: 'النيجر',
    zh: '尼日尔',
    hi: 'नाइजर',
    bn: 'নাইজার',
    pt: 'Níger',
    ru: 'Нигер'
  }
}, {
  alpha2: 'ng',
  alpha3: 'nga',
  translations: {
    de: 'Nigeria',
    en: 'Nigeria',
    fr: 'Nigéria',
    it: 'Nigeria',
    es: 'Nigeria',
    ar: 'نيجيريا',
    zh: '尼日利亚',
    hi: 'नाइजीरिया',
    bn: 'নাইজেরিয়া',
    pt: 'Nigéria',
    ru: 'Нигерия'
  }
}, {
  alpha2: 'nu',
  alpha3: 'niu',
  translations: {
    de: 'Niue',
    en: 'Niue',
    fr: 'Niue',
    it: 'Niue',
    es: 'Niue',
    ar: 'نييوي',
    zh: '纽埃',
    hi: 'नियू',
    bn: 'নিউয়ে',
    pt: 'Niue',
    ru: 'Ниуэ'
  }
}, {
  alpha2: 'nf',
  alpha3: 'nfk',
  translations: {
    de: 'Norfolkinsel',
    en: 'Norfolk Island',
    fr: 'Île Norfolk',
    it: 'Isola Norfolk',
    es: 'Isla Norfolk',
    ar: 'جزيرة نورفولك',
    zh: '诺福克岛',
    hi: 'नोरफॉक द्वीप',
    bn: 'নরফোক দ্বীপ',
    pt: 'Ilha Norfolk',
    ru: 'Остров Норфолк'
  }
}, {
  alpha2: 'mk',
  alpha3: 'mkd',
  translations: {
    de: 'Nordmazedonien',
    en: 'North Macedonia',
    fr: 'Macédoine du Nord',
    it: 'Macedonia del Nord',
    es: 'Macedonia del Norte',
    ar: 'مقدونيا الشمالية',
    zh: '北马其顿',
    hi: 'मकदूनिया',
    bn: 'ম্যাসেডোনিয়ার প্রাক্তন যুগোস্লাভ প্রজাতন্ত্র',
    pt: 'Macedónia do Norte',
    ru: 'Северная Македония'
  }
}, {
  alpha2: 'mp',
  alpha3: 'mnp',
  translations: {
    de: 'Nördliche Marianen',
    en: 'Northern Mariana Islands',
    fr: 'Îles Mariannes du Nord',
    it: 'Isole Marianne Settentrionali',
    es: 'Islas Marianas del Norte',
    ar: 'جزر ماريانا الشمالية',
    zh: '北马里亚纳群岛',
    hi: 'उत्तर मैरिना द्वीपसमूह',
    bn: 'উত্তর মারিয়ানা দ্বীপপুঞ্জ',
    pt: 'Ilhas Marianas do Norte',
    ru: 'Северные Марианские Острова'
  }
}, {
  alpha2: 'no',
  alpha3: 'nor',
  translations: {
    de: 'Norwegen',
    en: 'Norway',
    fr: 'Norvège',
    it: 'Norvegia',
    es: 'Noruega',
    ar: 'النرويج',
    zh: '挪威',
    hi: 'नॉर्वे',
    bn: 'নরওয়ে',
    pt: 'Noruega',
    ru: 'Норвегия'
  }
}, {
  alpha2: 'om',
  alpha3: 'omn',
  translations: {
    de: 'Oman',
    en: 'Oman',
    fr: 'Oman',
    it: 'Oman',
    es: 'Omán',
    ar: 'عمان',
    zh: '阿曼',
    hi: 'ओमान',
    bn: 'ওমান',
    pt: 'Omã',
    ru: 'Оман'
  }
}, {
  alpha2: 'pk',
  alpha3: 'pak',
  translations: {
    de: 'Pakistan',
    en: 'Pakistan',
    fr: 'Pakistan',
    it: 'Pakistan',
    es: 'Pakistán',
    ar: 'باكستان',
    zh: '巴基斯坦',
    hi: 'पाकिस्तान',
    bn: 'পাকিস্তান',
    pt: 'Paquistão',
    ru: 'Пакистан'
  }
}, {
  alpha2: 'pw',
  alpha3: 'plw',
  translations: {
    de: 'Palau',
    en: 'Palau',
    fr: 'Palaos',
    it: 'Palau',
    es: 'Palaos',
    ar: 'بالاو',
    zh: '帕劳',
    hi: 'पलाउ',
    bn: 'পালাউ',
    pt: 'Palau',
    ru: 'Палау'
  }
}, {
  alpha2: 'ps',
  alpha3: 'pse',
  translations: {
    de: 'Palästina',
    en: 'Palestine, State of',
    fr: 'Palestine',
    it: 'Palestina',
    es: 'Palestina',
    ar: 'فلسطين',
    zh: '巴勒斯坦',
    hi: 'फिलिस्तीन राज्य',
    bn: 'ফিলিস্তিন রাষ্ট্র',
    pt: 'Territórios palestinos',
    ru: 'Государство Палестина'
  }
}, {
  alpha2: 'pa',
  alpha3: 'pan',
  translations: {
    de: 'Panama',
    en: 'Panama',
    fr: 'Panama',
    it: 'Panama',
    es: 'Panamá',
    ar: 'بنما',
    zh: '巴拿马',
    hi: 'पनामा',
    bn: 'পানামা',
    pt: 'Panamá',
    ru: 'Панама'
  }
}, {
  alpha2: 'pg',
  alpha3: 'png',
  translations: {
    de: 'Papua-Neuguinea',
    en: 'Papua New Guinea',
    fr: 'Papouasie-Nouvelle-Guinée',
    it: 'Papua Nuova Guinea',
    es: 'Papúa Nueva Guinea',
    ar: 'بابوا غينيا الجديدة',
    zh: '巴布亚新几内亚',
    hi: 'पापुआ न्यू गिनी',
    bn: 'পাপুয়া নিউগিনি',
    pt: 'Papua-Nova Guiné',
    ru: 'Папуа — Новая Гвинея'
  }
}, {
  alpha2: 'py',
  alpha3: 'pry',
  translations: {
    de: 'Paraguay',
    en: 'Paraguay',
    fr: 'Paraguay',
    it: 'Paraguay',
    es: 'Paraguay',
    ar: 'باراغواي',
    zh: '巴拉圭',
    hi: 'पराग्वे',
    bn: 'প্যারাগুয়ে',
    pt: 'Paraguai',
    ru: 'Парагвай'
  }
}, {
  alpha2: 'pe',
  alpha3: 'per',
  translations: {
    de: 'Peru',
    en: 'Peru',
    fr: 'Pérou',
    it: 'Perù',
    es: 'Perú',
    ar: 'بيرو',
    zh: '秘鲁',
    hi: 'पेरू',
    bn: 'পেরু',
    pt: 'Peru',
    ru: 'Перу'
  }
}, {
  alpha2: 'ph',
  alpha3: 'phl',
  translations: {
    de: 'Philippinen',
    en: 'Philippines',
    fr: 'Philippines',
    it: 'Filippine',
    es: 'Filipinas',
    ar: 'الفلبين',
    zh: '菲律宾',
    hi: 'फिलीपींस',
    bn: 'ফিলিপাইন',
    pt: 'Filipinas',
    ru: 'Филиппины'
  }
}, {
  alpha2: 'pn',
  alpha3: 'pcn',
  translations: {
    de: 'Pitcairninseln',
    en: 'Pitcairn',
    fr: 'Pitcairn',
    it: 'Pitcairn',
    es: 'Pitcairn',
    ar: 'جزر بيتكيرن',
    zh: '皮特凯恩群岛',
    hi: 'पिटकैर्न द्वीपसमूह',
    bn: 'পিটকেয়ার্ন',
    pt: 'Ilhas Pitcairn',
    ru: 'Острова Питкэрн'
  }
}, {
  alpha2: 'pl',
  alpha3: 'pol',
  translations: {
    de: 'Polen',
    en: 'Poland',
    fr: 'Pologne',
    it: 'Polonia',
    es: 'Polonia',
    ar: 'بولندا',
    zh: '波兰',
    hi: 'पोलैंड',
    bn: 'পোল্যান্ড',
    pt: 'Polónia',
    ru: 'Польша'
  }
}, {
  alpha2: 'pt',
  alpha3: 'prt',
  translations: {
    de: 'Portugal',
    en: 'Portugal',
    fr: 'Portugal',
    it: 'Portogallo',
    es: 'Portugal',
    ar: 'البرتغال',
    zh: '葡萄牙',
    hi: 'पुर्तगाल',
    bn: 'পর্তুগাল',
    pt: 'Portugal',
    ru: 'Португалия'
  }
}, {
  alpha2: 'pr',
  alpha3: 'pri',
  translations: {
    de: 'Puerto Rico',
    en: 'Puerto Rico',
    fr: 'Porto Rico',
    it: 'Porto Rico',
    es: 'Puerto Rico',
    ar: 'بورتوريكو',
    zh: '波多黎各',
    hi: 'पुएर्तो रिको',
    bn: 'পুয়ের্তো রিকো',
    pt: 'Porto Rico',
    ru: 'Пуэрто-Рико'
  }
}, {
  alpha2: 'qa',
  alpha3: 'qat',
  translations: {
    de: 'Katar',
    en: 'Qatar',
    fr: 'Qatar',
    it: 'Qatar',
    es: 'Catar',
    ar: 'قطر',
    zh: '卡塔尔',
    hi: 'क़तार',
    bn: 'কাতার',
    pt: 'Qatar',
    ru: 'Катар'
  }
}, {
  alpha2: 're',
  alpha3: 'reu',
  translations: {
    de: 'Réunion',
    en: 'Réunion',
    fr: 'La Réunion',
    it: 'Riunione',
    es: 'Reunión',
    ar: 'لا ريونيون',
    zh: '留尼汪',
    hi: 'रीयूनियन',
    bn: 'রেউনিওঁ',
    pt: 'Reunião',
    ru: 'Реюньон'
  }
}, {
  alpha2: 'ro',
  alpha3: 'rou',
  translations: {
    de: 'Rumänien',
    en: 'Romania',
    fr: 'Roumanie',
    it: 'Romania',
    es: 'Rumania',
    ar: 'رومانيا',
    zh: '罗马尼亚',
    hi: 'रोमानिया',
    bn: 'রোমানিয়া',
    pt: 'Roménia',
    ru: 'Румыния'
  }
}, {
  alpha2: 'ru',
  alpha3: 'rus',
  translations: {
    de: 'Russland',
    en: 'Russian Federation',
    fr: 'Russie',
    it: 'Federazione Russa',
    es: 'Federación de Rusia',
    ar: 'روسيا',
    zh: '俄罗斯',
    hi: 'रूस',
    bn: 'রাশিয়া',
    pt: 'Rússia',
    ru: 'Российская Федерация'
  }
}, {
  alpha2: 'rw',
  alpha3: 'rwa',
  translations: {
    de: 'Ruanda',
    en: 'Rwanda',
    fr: 'Rwanda',
    it: 'Ruanda',
    es: 'Ruanda',
    ar: 'رواندا',
    zh: '卢旺达',
    hi: 'रवांडा',
    bn: 'রুয়ান্ডা',
    pt: 'Ruanda',
    ru: 'Руанда'
  }
}, {
  alpha2: 'bl',
  alpha3: 'blm',
  translations: {
    de: 'Saint-Barthélemy',
    en: 'Saint Barthélemy',
    fr: 'Saint-Barthélemy',
    it: 'Saint-Barthélemy',
    es: 'San Bartolomé',
    ar: 'سان بارتيلمي',
    zh: '圣巴泰勒米',
    hi: 'सेंट_बार्थेलेमी',
    bn: 'সেন্ট বার্থলেমি',
    pt: 'São Bartolomeu',
    ru: 'Сен-Бартелеми'
  }
}, {
  alpha2: 'sh',
  alpha3: 'shn',
  translations: {
    de: 'St. Helena, Ascension und Tristan da Cunha',
    en: 'Saint Helena, Ascension and Tristan da Cunha',
    fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    it: 'Sant’Elena, Ascensione e Tristan da Cunha',
    es: 'Santa Elena, Ascensión y Tristán de Acuña',
    ar: 'سانت هيلينا وأسينشين وتريستان دا كونا',
    zh: '圣赫勒拿、阿森松和特里斯坦-达库尼亚',
    hi: 'सेंट हेलेना',
    bn: 'সেন্ট হেলেনা, অ্যাসেনশন ও ত্রিস্তান দা কুনহা',
    pt: 'Santa Helena',
    ru: 'Острова Святой Елены, Вознесения и Тристан-да-Кунья'
  }
}, {
  alpha2: 'kn',
  alpha3: 'kna',
  translations: {
    de: 'St. Kitts und Nevis',
    en: 'Saint Kitts and Nevis',
    fr: 'Saint-Kitts-et-Nevis',
    it: 'Saint Kitts e Nevis',
    es: 'San Cristóbal y Nieves',
    ar: 'سانت كيتس ونيفيس',
    zh: '圣基茨和尼维斯',
    hi: 'सेंट किट्स एंड नेविस',
    bn: 'সেন্ট কিট্‌স ও নেভিস',
    pt: 'São Cristóvão e Neves',
    ru: 'Сент-Китс и Невис'
  }
}, {
  alpha2: 'lc',
  alpha3: 'lca',
  translations: {
    de: 'St. Lucia',
    en: 'Saint Lucia',
    fr: 'Sainte-Lucie',
    it: 'Santa Lucia',
    es: 'Santa Lucía',
    ar: 'سانت لوسيا',
    zh: '圣卢西亚',
    hi: 'सेंट लुसिया',
    bn: 'সেন্ট লুসিয়া',
    pt: 'Santa Lúcia',
    ru: 'Сент-Люсия'
  }
}, {
  alpha2: 'mf',
  alpha3: 'maf',
  translations: {
    de: 'Saint-Martin (franz. Teil)',
    en: 'Saint Martin (French part)',
    fr: 'Saint-Martin (partie française)',
    it: 'Saint-Martin (parte francese)',
    es: 'San Martín (parte francesa)',
    ar: 'سانت مارتن (الجزء الفرنسي)',
    zh: '法属圣马丁',
    hi: 'सेंट मार्टिन की सामूहिकता',
    bn: 'সেন্ট মার্টিন (ফরাসি অংশ)',
    pt: 'São Martinho',
    ru: 'Сен-Мартен'
  }
}, {
  alpha2: 'pm',
  alpha3: 'spm',
  translations: {
    de: 'Saint-Pierre und Miquelon',
    en: 'Saint Pierre and Miquelon',
    fr: 'Saint-Pierre-et-Miquelon',
    it: 'Saint-Pierre e Miquelon',
    es: 'San Pedro y Miquelón',
    ar: 'سان بيير وميكلون',
    zh: '圣皮埃尔和密克隆',
    hi: 'सेंट पिएरे एंड मिक्वेलॉन',
    bn: 'সাঁ পিয়ের ও মিক‌লোঁ',
    pt: 'Saint Pierre e Miquelon',
    ru: 'Сен-Пьер и Микелон'
  }
}, {
  alpha2: 'vc',
  alpha3: 'vct',
  translations: {
    de: 'St. Vincent und die Grenadinen',
    en: 'Saint Vincent and the Grenadines',
    fr: 'Saint-Vincent-et-les Grenadines',
    it: 'Saint Vincent e Grenadine',
    es: 'San Vicente y las Granadinas',
    ar: 'سانت فينسنت والغرينادين',
    zh: '圣文森特和格林纳丁斯',
    hi: 'सेंट विंसेंट एंड द ग्रेनेंडाइन्स',
    bn: 'সেন্ট ভিনসেন্ট ও গ্রেনাডাইন দ্বীপপুঞ্জ',
    pt: 'São Vicente e Granadinas',
    ru: 'Сент-Винсент и Гренадины'
  }
}, {
  alpha2: 'ws',
  alpha3: 'wsm',
  translations: {
    de: 'Samoa',
    en: 'Samoa',
    fr: 'Samoa',
    it: 'Samoa',
    es: 'Samoa',
    ar: 'ساموا',
    zh: '萨摩亚',
    hi: 'पश्चिमी सामोआ',
    bn: 'সামোয়া',
    pt: 'Samoa',
    ru: 'Самоа'
  }
}, {
  alpha2: 'sm',
  alpha3: 'smr',
  translations: {
    de: 'San Marino',
    en: 'San Marino',
    fr: 'Saint-Marin',
    it: 'San Marino',
    es: 'San Marino',
    ar: 'سان مارينو',
    zh: '圣马力诺',
    hi: 'सैन मारिनो',
    bn: 'সান মারিনো',
    pt: 'San Marino',
    ru: 'Сан-Марино'
  }
}, {
  alpha2: 'st',
  alpha3: 'stp',
  translations: {
    de: 'São Tomé und Príncipe',
    en: 'Sao Tome and Principe',
    fr: 'Sao Tomé-et-Principe',
    it: 'São Tomé e Príncipe',
    es: 'Santo Tomé y Príncipe',
    ar: 'ساو تومي وبرينسيب',
    zh: '圣多美和普林西比',
    hi: 'साओ टॉम एंड प्रिंसिपी',
    bn: 'সাঁউ তুমি ও প্রিন্সিপি',
    pt: 'São Tomé e Príncipe',
    ru: 'Сан-Томе и Принсипи'
  }
}, {
  alpha2: 'sa',
  alpha3: 'sau',
  translations: {
    de: 'Saudi-Arabien',
    en: 'Saudi Arabia',
    fr: 'Arabie saoudite',
    it: 'Arabia Saudita',
    es: 'Arabia Saudita',
    ar: 'السعودية',
    zh: '沙特阿拉伯',
    hi: 'सऊदी अरब',
    bn: 'সৌদি আরব',
    pt: 'Arábia Saudita',
    ru: 'Саудовская Аравия'
  }
}, {
  alpha2: 'sn',
  alpha3: 'sen',
  translations: {
    de: 'Senegal',
    en: 'Senegal',
    fr: 'Sénégal',
    it: 'Senegal',
    es: 'Senegal',
    ar: 'السنغال',
    zh: '塞内加尔',
    hi: 'सेनेगल',
    bn: 'সেনেগাল',
    pt: 'Senegal',
    ru: 'Сенегал'
  }
}, {
  alpha2: 'rs',
  alpha3: 'srb',
  translations: {
    de: 'Serbien',
    en: 'Serbia',
    fr: 'Serbie',
    it: 'Serbia',
    es: 'Serbia',
    ar: 'صربيا',
    zh: '塞尔维亚',
    hi: 'सर्बिया',
    bn: 'সার্বিয়া',
    pt: 'Sérvia',
    ru: 'Сербия'
  }
}, {
  alpha2: 'sc',
  alpha3: 'syc',
  translations: {
    de: 'Seychellen',
    en: 'Seychelles',
    fr: 'Seychelles',
    it: 'Seychelles',
    es: 'Seychelles',
    ar: 'سيشل',
    zh: '塞舌尔',
    hi: 'सेशेल्स',
    bn: 'সেশেল',
    pt: 'Seychelles',
    ru: 'Сейшельские Острова'
  }
}, {
  alpha2: 'sl',
  alpha3: 'sle',
  translations: {
    de: 'Sierra Leone',
    en: 'Sierra Leone',
    fr: 'Sierra Leone',
    it: 'Sierra Leone',
    es: 'Sierra Leona',
    ar: 'سيراليون',
    zh: '塞拉利昂',
    hi: 'सियारा लिओन',
    bn: 'সিয়েরা লিওন',
    pt: 'Serra Leoa',
    ru: 'Сьерра-Леоне'
  }
}, {
  alpha2: 'sg',
  alpha3: 'sgp',
  translations: {
    de: 'Singapur',
    en: 'Singapore',
    fr: 'Singapour',
    it: 'Singapore',
    es: 'Singapur',
    ar: 'سنغافورة',
    zh: '新加坡',
    hi: 'सिंगापुर',
    bn: 'সিঙ্গাপুর',
    pt: 'Singapura',
    ru: 'Сингапур'
  }
}, {
  alpha2: 'sx',
  alpha3: 'sxm',
  translations: {
    de: 'Sint Maarten (niederl. Teil)',
    en: 'Sint Maarten (Dutch part)',
    fr: 'Saint-Martin (partie néerlandaise)',
    it: 'Sint Maarten (parte olandese)',
    es: 'Sint Maarten (parte neerlandesa)',
    ar: 'سانت مارتن (الجزء الهولندي)',
    zh: '荷属圣马丁',
    hi: 'सिण्ट मार्टेन',
    bn: 'সিন্ট মার্টেন (ওলন্দাজ অংশ)',
    pt: 'São Martinho',
    ru: 'Синт-Мартен'
  }
}, {
  alpha2: 'sk',
  alpha3: 'svk',
  translations: {
    de: 'Slowakei',
    en: 'Slovakia',
    fr: 'Slovaquie',
    it: 'Slovacchia',
    es: 'Eslovaquia',
    ar: 'سلوفاكيا',
    zh: '斯洛伐克',
    hi: 'स्लोवाकिया',
    bn: 'স্লোভাকিয়া',
    pt: 'Eslováquia',
    ru: 'Словакия'
  }
}, {
  alpha2: 'si',
  alpha3: 'svn',
  translations: {
    de: 'Slowenien',
    en: 'Slovenia',
    fr: 'Slovénie',
    it: 'Slovenia',
    es: 'Eslovenia',
    ar: 'سلوفينيا',
    zh: '斯洛文尼亚',
    hi: 'स्लोवानिया',
    bn: 'স্লোভেনিয়া',
    pt: 'Eslovénia',
    ru: 'Словения'
  }
}, {
  alpha2: 'sb',
  alpha3: 'slb',
  translations: {
    de: 'Salomonen',
    en: 'Solomon Islands',
    fr: 'Salomon (Îles)',
    it: 'Isole Salomone',
    es: 'Islas Salomón',
    ar: 'جزر سليمان',
    zh: '所罗门群岛',
    hi: 'सोलोमन द्वीपसमूह',
    bn: 'সলোমন দ্বীপপুঞ্জ',
    pt: 'Ilhas Salomão',
    ru: 'Соломоновы Острова'
  }
}, {
  alpha2: 'so',
  alpha3: 'som',
  translations: {
    de: 'Somalia',
    en: 'Somalia',
    fr: 'Somalie',
    it: 'Somalia',
    es: 'Somalia',
    ar: 'الصومال',
    zh: '索马里',
    hi: 'सोमालिया',
    bn: 'সোমালিয়া',
    pt: 'Somália',
    ru: 'Сомали'
  }
}, {
  alpha2: 'za',
  alpha3: 'zaf',
  translations: {
    de: 'Südafrika',
    en: 'South Africa',
    fr: 'Afrique du Sud',
    it: 'Sudafrica',
    es: 'Sudáfrica',
    ar: 'جنوب أفريقيا',
    zh: '南非',
    hi: 'दक्षिण अफ्रीका',
    bn: 'দক্ষিণ আফ্রিকা',
    pt: 'África do Sul',
    ru: 'ЮАР'
  }
}, {
  alpha2: 'gs',
  alpha3: 'sgs',
  translations: {
    de: 'Südgeorgien und die Südlichen Sandwichinseln',
    en: 'South Georgia and the South Sandwich Islands',
    fr: 'Géorgie du Sud et îles Sandwich du Sud',
    it: 'Georgia del Sud e Isole Sandwich Meridionali',
    es: 'Georgia del Sur e Islas Sandwich del Sur',
    ar: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    zh: '南乔治亚和南桑威奇群岛',
    hi: 'दक्षिण जॉर्जिया और दक्षिण सैंडविच द्वीप समूह',
    bn: 'দক্ষিণ জর্জিয়া ও দক্ষিণ স্যান্ডউইচ দ্বীপপুঞ্জ',
    pt: 'Geórgia do Sul e Ilhas Sandwich do Sul',
    ru: 'Южная Георгия и Южные Сандвичевы Острова'
  }
}, {
  alpha2: 'kr',
  alpha3: 'kor',
  translations: {
    de: 'Südkorea',
    en: 'Korea, Republic of',
    fr: 'Corée du Sud',
    it: 'Corea del Sud',
    es: 'Corea del Sur',
    ar: 'كوريا الجنوبية',
    zh: '韩国',
    hi: 'दक्षिण कोरिया',
    bn: 'কোরিয়া প্রজাতন্ত্র',
    pt: 'Coreia do Sul',
    ru: 'Республика Корея'
  }
}, {
  alpha2: 'ss',
  alpha3: 'ssd',
  translations: {
    de: 'Südsudan',
    en: 'South Sudan',
    fr: 'Soudan du Sud',
    it: 'Sud Sudan',
    es: 'Sudán del Sur',
    ar: 'جنوب السودان',
    zh: '南苏丹',
    hi: 'दक्षिण सूडान',
    bn: 'দক্ষিণ সুদান',
    pt: 'Sudão do Sul',
    ru: 'Южный Судан'
  }
}, {
  alpha2: 'es',
  alpha3: 'esp',
  translations: {
    de: 'Spanien',
    en: 'Spain',
    fr: 'Espagne',
    it: 'Spagna',
    es: 'España',
    ar: 'إسبانيا',
    zh: '西班牙',
    hi: 'स्पेन',
    bn: 'স্পেন',
    pt: 'Espanha',
    ru: 'Испания'
  }
}, {
  alpha2: 'lk',
  alpha3: 'lka',
  translations: {
    de: 'Sri Lanka',
    en: 'Sri Lanka',
    fr: 'Sri Lanka',
    it: 'Sri Lanka',
    es: 'Sri Lanka',
    ar: 'سريلانكا',
    zh: '斯里兰卡',
    hi: 'श्री लंका',
    bn: 'শ্রীলঙ্কা',
    pt: 'Sri Lanka',
    ru: 'Шри-Ланка'
  }
}, {
  alpha2: 'sd',
  alpha3: 'sdn',
  translations: {
    de: 'Sudan',
    en: 'Sudan',
    fr: 'Soudan',
    it: 'Sudan',
    es: 'Sudán',
    ar: 'السودان',
    zh: '苏丹',
    hi: 'सूडान',
    bn: 'সুদান',
    pt: 'Sudão',
    ru: 'Судан'
  }
}, {
  alpha2: 'sr',
  alpha3: 'sur',
  translations: {
    de: 'Suriname',
    en: 'Suriname',
    fr: 'Suriname',
    it: 'Suriname',
    es: 'Surinam',
    ar: 'سورينام',
    zh: '苏里南',
    hi: 'सूरीनाम',
    bn: 'সুরিনাম',
    pt: 'Suriname',
    ru: 'Суринам'
  }
}, {
  alpha2: 'sj',
  alpha3: 'sjm',
  translations: {
    de: 'Svalbard und Jan Mayen',
    en: 'Svalbard and Jan Mayen',
    fr: 'Svalbard et Jan Mayen',
    it: 'Svalbard e Jan Mayen',
    es: 'Svalbard y Jan Mayen',
    ar: 'سفالبارد ويان ماين',
    zh: '斯瓦尔巴和扬马延',
    hi: 'स्यालबार्ड (स्यालबार्ड एंड जन मावेम)',
    bn: 'স্বালবার্ড ও জান মায়েন',
    pt: 'Svalbard e Jan Mayen',
    ru: 'Шпицберген и Ян-Майен'
  }
}, {
  alpha2: 'se',
  alpha3: 'swe',
  translations: {
    de: 'Schweden',
    en: 'Sweden',
    fr: 'Suède',
    it: 'Svezia',
    es: 'Suecia',
    ar: 'السويد',
    zh: '瑞典',
    hi: 'स्वीडन',
    bn: 'সুইডেন',
    pt: 'Suécia',
    ru: 'Швеция'
  }
}, {
  alpha2: 'ch',
  alpha3: 'che',
  translations: {
    de: 'Schweiz',
    en: 'Switzerland',
    fr: 'Suisse',
    it: 'Svizzera',
    es: 'Suiza',
    ar: 'سويسرا',
    zh: '瑞士',
    hi: 'स्विट्जरलैंड',
    bn: 'সুইজারল্যান্ড',
    pt: 'Suíça',
    ru: 'Швейцария'
  }
}, {
  alpha2: 'sy',
  alpha3: 'syr',
  translations: {
    de: 'Syrien',
    en: 'Syrian Arab Republic',
    fr: 'Syrienne (République arabe)',
    it: 'Repubblica Araba Siriana',
    es: 'República Árabe Siria',
    ar: 'سوريا',
    zh: '叙利亚',
    hi: 'सीरिया',
    bn: 'আরব প্রজাতন্ত্র সিরিয়া',
    pt: 'Síria',
    ru: 'Сирия'
  }
}, {
  alpha2: 'tw',
  alpha3: 'twn',
  translations: {
    de: 'Taiwan',
    en: 'Taiwan',
    fr: 'Taïwan',
    it: 'Taiwan',
    es: 'Taiwán',
    ar: 'تايوان',
    zh: '中国台湾省',
    hi: 'ताइवान',
    bn: 'তাইওয়ান, চীনের প্রদেশ',
    pt: 'Taiwan',
    ru: 'Тайвань'
  }
}, {
  alpha2: 'tj',
  alpha3: 'tjk',
  translations: {
    de: 'Tadschikistan',
    en: 'Tajikistan',
    fr: 'Tadjikistan',
    it: 'Tagikistan',
    es: 'Tayikistán',
    ar: 'طاجيكستان',
    zh: '塔吉克斯坦',
    hi: 'तज़ाकिस्तान',
    bn: 'তাজিকিস্তান',
    pt: 'Tajiquistão',
    ru: 'Таджикистан'
  }
}, {
  alpha2: 'tz',
  alpha3: 'tza',
  translations: {
    de: 'Tansania',
    en: 'Tanzania, United Republic of',
    fr: 'Tanzanie',
    it: 'Tanzania',
    es: 'Tanzania',
    ar: 'تانزانيا',
    zh: '坦桑尼亚',
    hi: 'तंजानिया',
    bn: 'তানজানিয়া যুক্তপ্রজাতন্ত্র',
    pt: 'Tanzânia',
    ru: 'Танзания'
  }
}, {
  alpha2: 'th',
  alpha3: 'tha',
  translations: {
    de: 'Thailand',
    en: 'Thailand',
    fr: 'Thaïlande',
    it: 'Thailandia',
    es: 'Tailandia',
    ar: 'تايلاند',
    zh: '泰国',
    hi: 'थाईलैंड',
    bn: 'থাইল্যান্ড',
    pt: 'Tailândia',
    ru: 'Таиланд'
  }
}, {
  alpha2: 'tl',
  alpha3: 'tls',
  translations: {
    de: 'Timor-Leste',
    en: 'Timor-Leste',
    fr: 'Timor-Leste',
    it: 'Timor Est',
    es: 'Timor-Leste',
    ar: 'تيمور الشرقية',
    zh: '东帝汶',
    hi: 'पूर्वी तिमोर',
    bn: 'তিমোর্‌ ল্যেশ্ত্যি',
    pt: 'Timor-Leste',
    ru: 'Восточный Тимор'
  }
}, {
  alpha2: 'tg',
  alpha3: 'tgo',
  translations: {
    de: 'Togo',
    en: 'Togo',
    fr: 'Togo',
    it: 'Togo',
    es: 'Togo',
    ar: 'توغو',
    zh: '多哥',
    hi: 'टोगो',
    bn: 'টোগো',
    pt: 'Togo',
    ru: 'Того'
  }
}, {
  alpha2: 'tk',
  alpha3: 'tkl',
  translations: {
    de: 'Tokelau',
    en: 'Tokelau',
    fr: 'Tokelau',
    it: 'Tokelau',
    es: 'Tokelau',
    ar: 'توكيلاو',
    zh: '托克劳',
    hi: 'टोकेलू',
    bn: 'টোকেলাউ',
    pt: 'Tokelau',
    ru: 'Токелау'
  }
}, {
  alpha2: 'to',
  alpha3: 'ton',
  translations: {
    de: 'Tonga',
    en: 'Tonga',
    fr: 'Tonga',
    it: 'Tonga',
    es: 'Tonga',
    ar: 'تونغا',
    zh: '汤加',
    hi: 'टोंगा',
    bn: 'টোঙ্গা',
    pt: 'Tonga',
    ru: 'Тонга'
  }
}, {
  alpha2: 'tt',
  alpha3: 'tto',
  translations: {
    de: 'Trinidad und Tobago',
    en: 'Trinidad and Tobago',
    fr: 'Trinité-et-Tobago',
    it: 'Trinidad e Tobago',
    es: 'Trinidad y Tobago',
    ar: 'ترينيداد وتوباغو',
    zh: '特立尼达和多巴哥',
    hi: 'ट्रिनिडाड एंड टोबैगो',
    bn: 'ত্রিনিদাদ ও টোবাগো',
    pt: 'Trindade e Tobago',
    ru: 'Тринидад и Тобаго'
  }
}, {
  alpha2: 'tn',
  alpha3: 'tun',
  translations: {
    de: 'Tunesien',
    en: 'Tunisia',
    fr: 'Tunisie',
    it: 'Tunisia',
    es: 'Túnez',
    ar: 'تونس',
    zh: '突尼斯',
    hi: 'ट्यूनीशिया',
    bn: 'তিউনিসিয়া',
    pt: 'Tunísia',
    ru: 'Тунис'
  }
}, {
  alpha2: 'tr',
  alpha3: 'tur',
  translations: {
    de: 'Türkei',
    en: 'Türkiye',
    fr: 'Turquie',
    it: 'Turchia',
    es: 'Turquía',
    ar: 'تركيا',
    zh: '土耳其',
    hi: 'तुर्की',
    bn: 'তুরস্ক',
    pt: 'Turquia',
    ru: 'Турция'
  }
}, {
  alpha2: 'tm',
  alpha3: 'tkm',
  translations: {
    de: 'Turkmenistan',
    en: 'Turkmenistan',
    fr: 'Turkménistan',
    it: 'Turkmenistan',
    es: 'Turkmenistán',
    ar: 'تركمانستان',
    zh: '土库曼斯坦',
    hi: 'तुर्कमेनिस्तान',
    bn: 'তুর্কমেনিস্তান',
    pt: 'Turquemenistão',
    ru: 'Туркмения'
  }
}, {
  alpha2: 'tc',
  alpha3: 'tca',
  translations: {
    de: 'Turks- und Caicosinseln',
    en: 'Turks and Caicos Islands',
    fr: 'Îles Turques-et-Caïques',
    it: 'Isole Turks e Caicos',
    es: 'Islas Turcas y Caicos',
    ar: 'جزر توركس وكايكوس',
    zh: '特克斯和凯科斯群岛',
    hi: 'तुर्क एंड कैकोस द्वीपसमूह',
    bn: 'টার্কস্‌ ও কেইকোস দ্বীপপুঞ্জ',
    pt: 'Ilhas Turcas e Caicos',
    ru: 'Теркс и Кайкос'
  }
}, {
  alpha2: 'tv',
  alpha3: 'tuv',
  translations: {
    de: 'Tuvalu',
    en: 'Tuvalu',
    fr: 'Tuvalu',
    it: 'Tuvalu',
    es: 'Tuvalu',
    ar: 'توفالو',
    zh: '图瓦卢',
    hi: 'तुवालू',
    bn: 'টুভালু',
    pt: 'Tuvalu',
    ru: 'Тувалу'
  }
}, {
  alpha2: 'ug',
  alpha3: 'uga',
  translations: {
    de: 'Uganda',
    en: 'Uganda',
    fr: 'Ouganda',
    it: 'Uganda',
    es: 'Uganda',
    ar: 'أوغندا',
    zh: '乌干达',
    hi: 'उगांडा',
    bn: 'উগান্ডা',
    pt: 'Uganda',
    ru: 'Уганда'
  }
}, {
  alpha2: 'ua',
  alpha3: 'ukr',
  translations: {
    de: 'Ukraine',
    en: 'Ukraine',
    fr: 'Ukraine',
    it: 'Ucraina',
    es: 'Ucrania',
    ar: 'أوكرانيا',
    zh: '乌克兰',
    hi: 'उक्रेन',
    bn: 'ইউক্রেন',
    pt: 'Ucrânia',
    ru: 'Украина'
  }
}, {
  alpha2: 'ae',
  alpha3: 'are',
  translations: {
    de: 'Vereinigte Arabische Emirate',
    en: 'United Arab Emirates',
    fr: 'Émirats arabes unis',
    it: 'Emirati Arabi Uniti',
    es: 'Emiratos Árabes Unidos',
    ar: 'الإمارات العربية المتحدة',
    zh: '阿联酋',
    hi: 'संयुक्त अरब अमीरात',
    bn: 'সংযুক্ত আরব আমিরাত',
    pt: 'Emirados Árabes Unidos',
    ru: 'ОАЭ'
  }
}, {
  alpha2: 'gb',
  alpha3: 'gbr',
  translations: {
    de: 'Vereinigtes Königreich',
    en: 'United Kingdom',
    fr: 'Royaume-Uni',
    it: 'Regno Unito',
    es: 'Reino Unido',
    ar: 'المملكة المتحدة',
    zh: '英国',
    hi: 'ग्रेट ब्रिटेन',
    bn: 'যুক্তরাজ্য এবং উত্তর আয়ারল্যান্ড',
    pt: 'Reino Unido',
    ru: 'Великобритания'
  }
}, {
  alpha2: 'um',
  alpha3: 'umi',
  translations: {
    de: 'Amerikanische Überseeinseln',
    en: 'United States Minor Outlying Islands',
    fr: 'Îles mineures éloignées des États-Unis',
    it: 'Isole Minori Esterne degli Stati Uniti',
    es: 'Islas Ultramarinas Menores de Estados Unidos',
    ar: 'جزر الولايات المتحدة',
    zh: '美国本土外小岛屿',
    hi: 'यूएस माइनर आउटलाइंग द्वीपसमूह',
    bn: 'মার্কিন যুক্তরাষ্ট্রের ক্ষুদ্র পার্শ্ববর্তী দ্বীপপুঞ্জ',
    pt: 'Ilhas Distantes dos EUA',
    ru: 'Внешние малые острова (США)'
  }
}, {
  alpha2: 'us',
  alpha3: 'usa',
  translations: {
    de: 'Vereinigte Staaten von Amerika',
    en: 'United States of America',
    fr: 'États-Unis d’Amérique',
    it: 'Stati Uniti d’America',
    es: 'Estados Unidos de América',
    ar: 'الولايات المتحدة',
    zh: '美国',
    hi: 'यूएसए (संयुक्त राज्य अमेरिका)',
    bn: 'মার্কিন যুক্তরাষ্ট্র',
    pt: 'Estados Unidos',
    ru: 'США'
  }
}, {
  alpha2: 'uy',
  alpha3: 'ury',
  translations: {
    de: 'Uruguay',
    en: 'Uruguay',
    fr: 'Uruguay',
    it: 'Uruguay',
    es: 'Uruguay',
    ar: 'الأوروغواي',
    zh: '乌拉圭',
    hi: 'उरुग्वे',
    bn: 'উরুগুয়ে',
    pt: 'Uruguai',
    ru: 'Уругвай'
  }
}, {
  alpha2: 'uz',
  alpha3: 'uzb',
  translations: {
    de: 'Usbekistan',
    en: 'Uzbekistan',
    fr: 'Ouzbékistan',
    it: 'Uzbekistan',
    es: 'Uzbekistán',
    ar: 'أوزبكستان',
    zh: '乌兹别克斯坦',
    hi: 'उजबेकिस्तान',
    bn: 'উজবেকিস্তান',
    pt: 'Uzbequistão',
    ru: 'Узбекистан'
  }
}, {
  alpha2: 'vu',
  alpha3: 'vut',
  translations: {
    de: 'Vanuatu',
    en: 'Vanuatu',
    fr: 'Vanuatu',
    it: 'Vanuatu',
    es: 'Vanuatu',
    ar: 'فانواتو',
    zh: '瓦努阿图',
    hi: 'वनातू',
    bn: 'ভানুয়াটু',
    pt: 'Vanuatu',
    ru: 'Вануату'
  }
}, {
  alpha2: 've',
  alpha3: 'ven',
  translations: {
    de: 'Venezuela',
    en: 'Venezuela (Bolivarian Republic of)',
    fr: 'Venezuela',
    it: 'Venezuela',
    es: 'Venezuela',
    ar: 'فنزويلا',
    zh: '委内瑞拉',
    hi: 'वेनेजुएला',
    bn: 'ভেনেজুয়েলার বোলিভিয় প্রজাতন্ত্র',
    pt: 'Venezuela',
    ru: 'Венесуэла'
  }
}, {
  alpha2: 'vn',
  alpha3: 'vnm',
  translations: {
    de: 'Vietnam',
    en: 'Viet Nam',
    fr: 'Viet Nam',
    it: 'Vietnam',
    es: 'Vietnam',
    ar: 'فيتنام',
    zh: '越南',
    hi: 'विएतनाम',
    bn: 'ভিয়েত নাম',
    pt: 'Vietname',
    ru: 'Вьетнам'
  }
}, {
  alpha2: 'vg',
  alpha3: 'vgb',
  translations: {
    de: 'Britische Jungferninseln',
    en: 'Virgin Islands (British)',
    fr: 'Îles Vierges britanniques',
    it: 'Isole Vergini Britanniche',
    es: 'Islas Vírgenes Británicas',
    ar: 'جزر العذراء البريطانية',
    zh: '英属维尔京群岛',
    hi: 'ब्रितानी वर्जिन द्वीपसमूह',
    bn: 'ব্রিটিশ ভার্জিন দ্বীপপুঞ্জ',
    pt: 'Ilhas Virgens Britânicas',
    ru: 'Виргинские Острова (Великобритания)'
  }
}, {
  alpha2: 'vi',
  alpha3: 'vir',
  translations: {
    de: 'Amerikanische Jungferninseln',
    en: 'Virgin Islands (U.S.)',
    fr: 'Îles Vierges des États-Unis',
    it: 'Isole Vergini Americane',
    es: 'Islas Vírgenes de los Estados Unidos',
    ar: 'جزر العذراء الأمريكية',
    zh: '美属维尔京群岛',
    hi: 'अमेरिकी वर्जिन द्वीपसमूह',
    bn: 'মার্কিন ভার্জিন দ্বীপপুঞ্জ ',
    pt: 'Ilhas Virgens Americanas',
    ru: 'Виргинские Острова (США)'
  }
}, {
  alpha2: 'wf',
  alpha3: 'wlf',
  translations: {
    de: 'Wallis und Futuna',
    en: 'Wallis and Futuna',
    fr: 'Wallis-et-Futuna',
    it: 'Wallis e Futuna',
    es: 'Wallis y Futuna',
    ar: 'والس وفوتونا',
    zh: '瓦利斯和富图纳',
    hi: 'वालीज एंड फुटुना',
    bn: 'ওয়ালিম ও ফুটুনা দ্বীপপুঞ্জের',
    pt: 'Wallis e Futuna',
    ru: 'Уоллис и Футуна'
  }
}, {
  alpha2: 'eh',
  alpha3: 'esh',
  translations: {
    de: 'Westsahara',
    en: 'Western Sahara',
    fr: 'Sahara occidental',
    it: 'Sahara Occidentale',
    es: 'Sahara Occidental',
    ar: 'الصحراء الغربية',
    zh: '阿拉伯撒哈拉民主共和国',
    hi: 'पश्चिमी सहारा',
    bn: 'পশ্চিম সাহারা',
    pt: 'Saara Ocidental',
    ru: 'САДР'
  }
}, {
  alpha2: 'ye',
  alpha3: 'yem',
  translations: {
    de: 'Jemen',
    en: 'Yemen',
    fr: 'Yémen',
    it: 'Yemen',
    es: 'Yemen',
    ar: 'اليمن',
    zh: '也门',
    hi: 'यमन',
    bn: 'ইয়েমেন',
    pt: 'Iémen',
    ru: 'Йемен'
  }
}, {
  alpha2: 'zm',
  alpha3: 'zmb',
  translations: {
    de: 'Sambia',
    en: 'Zambia',
    fr: 'Zambie',
    it: 'Zambia',
    es: 'Zambia',
    ar: 'زامبيا',
    zh: '赞比亚',
    hi: 'जाम्बिया',
    bn: 'জাম্বিয়া',
    pt: 'Zâmbia',
    ru: 'Замбия'
  }
}, {
  alpha2: 'zw',
  alpha3: 'zwe',
  translations: {
    de: 'Simbabwe',
    en: 'Zimbabwe',
    fr: 'Zimbabwe',
    it: 'Zimbabwe',
    es: 'Zimbabwe',
    ar: 'زيمبابوي',
    zh: '津巴布韦',
    hi: 'जिंबावे',
    bn: 'জিম্বাবুয়ে',
    pt: 'Zimbábue',
    ru: 'Зимбабве'
  }
}];
class CountrySelectComponent {
  /**
   * Set initial default country
   * @example { alpha2: 'de', alpha3: 'deu', translations: { de: 'Deutschland', en: 'Germany', fr: 'Allemagne', es: 'Alemania', it: 'Germania' } }
   */
  defaultCountry = null;
  /**
   * Default language for displaying country names
   * Currently supported: 'en', 'de', 'fr', 'es', 'it', 'ar', 'zh', 'hi', 'bn', 'pt' and 'ru'
   * @default 'en'
   */
  _lang = 'en';
  set lang(value) {
    this._lang = value;
    this.updateLanguage();
  }
  get lang() {
    return this._lang;
  }
  /**
   * Search in all available languages or only in the selected language
   * @default false
   */
  searchAllLanguages = false;
  /**
   * Placeholder text for the input field
   * @default 'Search country'
   */
  placeholder = 'Search country';
  /**
   * Set a country programmatically
   */
  set selectedCountry(country) {
    this.formControl.setValue(country);
  }
  /**
   * Form control for the country select
   */
  formControl = new FormControl(null);
  /**
   * Debounce time for search input in milliseconds
   * @default 100
   */
  debounceTime = 100;
  /**
   * Appearance style of the form field
   * @default 'fill'
   */
  appearance = 'fill';
  /**
   * Angular Material color palette (primary, accent, warn)
   * @default 'primary'
   */
  color = 'primary';
  /**
   * Disables the component
   * @default false
   */
  disabled = false;
  /**
   * Marks the field as required
   * @default false
   */
  required = false;
  /**
   * Shows alpha2/alpha3 codes in the results
   * @default false
   */
  showCodes = false;
  /**
   * List of country alpha2 codes to include in the filter
   * @default []
   */
  includeCountries = [];
  /**
   * List of country alpha2 codes to exclude from the filter
   * @default []
   */
  excludeCountries = [];
  /**
   * Show only alpha2 codes in the results
   * @default false
   */
  alpha2Only = false;
  /**
   * Show only alpha3 codes in the results
   * @default false
   */
  alpha3Only = false;
  /**
   * Whether the flag should be displayed
   * @default true
   */
  showFlag = true;
  /**
   * Emits when a country is selected
   * @emits Country - Selected country object
   */
  countrySelected = new EventEmitter();
  /**
   * Emits when the input value changes
   * @emits string - Current search term
   */
  inputChanged = new EventEmitter();
  /**
   * Emits when the autocomplete panel is closed
   * @emits void
   */
  closed = new EventEmitter();
  filteredCountries$;
  countries = countries.slice();
  height;
  onChange = () => {};
  onTouched = () => {};
  ngOnInit() {
    this.setupFilter();
    this.filterCountryList();
    if (this.defaultCountry) {
      this.formControl.setValue(this.defaultCountry);
    }
  }
  writeValue(country) {
    if (country && !this.formControl.value || country?.alpha2 !== this.formControl.value?.alpha2) {
      this.formControl.setValue(country);
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  /**
   * Handle option selection
   */
  onOptionSelected(country) {
    this.formControl.setValue(country);
    this.countrySelected.emit(country);
  }
  /**
   * Displays the alpha2- or alpha3 code or country name in selected language
   * @public
   * @param country - Country object or null
   * @returns Display name string
   */
  displayFn(country) {
    if (this.alpha2Only) {
      return country?.alpha2?.toUpperCase() || '';
    } else if (this.alpha3Only) {
      return country?.alpha3?.toUpperCase() || '';
    } else {
      return country?.translations ? country?.translations[this.lang] : '';
    }
  }
  /**
   * Handles autocomplete panel close event
   * @public
   * @returns void
   */
  onAutocompleteClosed() {
    this.closed.emit();
  }
  /**
   * TrackBy function for country list optimization
   * @public
   * @param index - Array index
   * @param country - Country object
   * @returns Unique alpha2 code
   */
  trackByAlpha2(index, country) {
    return country.alpha2;
  }
  /**
   * Update the displayed language for countries
   */
  updateLanguage() {
    this.filteredCountries$ = this.formControl.valueChanges.pipe(startWith(this.formControl.value || ''), debounceTime(this.debounceTime), tap(value => {
      if (typeof value === 'string') {
        this.inputChanged.emit(value);
      }
    }), map(value => this.filterCountries(value)));
    if (this.formControl.value) {
      this.formControl.setValue(this.formControl.value);
    }
  }
  /**
   * Initializes the filter stream with debounce
   * @private
   * @returns void
   */
  setupFilter() {
    this.filteredCountries$ = this.formControl.valueChanges.pipe(startWith(this.defaultCountry ? this.defaultCountry : ''), debounceTime(this.debounceTime), tap(value => {
      if (typeof value === 'string') {
        this.inputChanged.emit(value);
      }
    }), map(value => this.filterCountries(value)));
  }
  /**
   * Filters the list of countries based on include and exclude lists
   * @private
   * @returns void
   */
  filterCountryList() {
    if (this.includeCountries.length > 0) {
      this.countries = this.countries.filter(country => this.includeCountries.includes(country.alpha2));
    }
    if (this.excludeCountries.length > 0) {
      this.countries = this.countries.filter(country => !this.excludeCountries.includes(country.alpha2));
    }
  }
  /**
   * Filters countries based on search value
   * @private
   * @param value - Search value (string or Country object)
   * @returns Filtered array of countries
   */
  filterCountries(value) {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value?.translations[this.lang]?.toLowerCase() || '';
    const filteredCountries = this.countries.filter(country => {
      const matchesCode = country.alpha2.toLowerCase().includes(filterValue) || country.alpha3.toLowerCase().includes(filterValue);
      const matchesTranslation = this.searchAllLanguages ? Object.values(country.translations).some(t => t.toLowerCase().includes(filterValue)) : country.translations[this.lang]?.toLowerCase().includes(filterValue);
      return matchesCode || matchesTranslation;
    });
    if (filteredCountries.length < 4) {
      this.height = filteredCountries.length * 50 + 'px';
    } else {
      this.height = '200px';
    }
    return filteredCountries;
  }
  static ɵfac = function CountrySelectComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CountrySelectComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: CountrySelectComponent,
    selectors: [["ng-country-select"]],
    inputs: {
      defaultCountry: "defaultCountry",
      lang: "lang",
      searchAllLanguages: "searchAllLanguages",
      placeholder: "placeholder",
      selectedCountry: "selectedCountry",
      formControl: "formControl",
      debounceTime: "debounceTime",
      appearance: "appearance",
      color: "color",
      disabled: "disabled",
      required: "required",
      showCodes: "showCodes",
      includeCountries: "includeCountries",
      excludeCountries: "excludeCountries",
      alpha2Only: "alpha2Only",
      alpha3Only: "alpha3Only",
      showFlag: "showFlag"
    },
    outputs: {
      countrySelected: "countrySelected",
      inputChanged: "inputChanged",
      closed: "closed"
    },
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectComponent),
      multi: true
    }])],
    decls: 10,
    vars: 17,
    consts: [["auto", "matAutocomplete"], [3, "appearance", "color"], ["matInput", "", 3, "formControl", "matAutocomplete", "disabled", "required"], ["matSuffix", "", 1, "flag-suffix", "fi", 3, "ngClass"], [3, "optionSelected", "closed", "displayWith"], [3, "itemSize", "ngStyle"], [3, "value", 4, "cdkVirtualFor", "cdkVirtualForOf", "cdkVirtualForTrackBy"], [3, "value"], [1, "country-option"], [1, "flag", "fi", 3, "ngClass"], [1, "name"], ["class", "codes", 4, "ngIf"], [1, "codes"]],
    template: function CountrySelectComponent_Template(rf, ctx) {
      if (rf & 1) {
        const _r1 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "mat-form-field", 1)(1, "mat-label");
        i0.ɵɵtext(2);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(3, "input", 2);
        i0.ɵɵtemplate(4, CountrySelectComponent_Conditional_4_Template, 1, 1, "mat-icon", 3);
        i0.ɵɵelementStart(5, "mat-autocomplete", 4, 0);
        i0.ɵɵlistener("optionSelected", function CountrySelectComponent_Template_mat_autocomplete_optionSelected_5_listener($event) {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onOptionSelected($event.option.value));
        })("closed", function CountrySelectComponent_Template_mat_autocomplete_closed_5_listener() {
          i0.ɵɵrestoreView(_r1);
          return i0.ɵɵresetView(ctx.onAutocompleteClosed());
        });
        i0.ɵɵelementStart(7, "cdk-virtual-scroll-viewport", 5);
        i0.ɵɵtemplate(8, CountrySelectComponent_mat_option_8_Template, 6, 3, "mat-option", 6);
        i0.ɵɵpipe(9, "async");
        i0.ɵɵelementEnd()()();
      }
      if (rf & 2) {
        const auto_r4 = i0.ɵɵreference(6);
        i0.ɵɵproperty("appearance", ctx.appearance)("color", ctx.color);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate(ctx.placeholder);
        i0.ɵɵadvance();
        i0.ɵɵproperty("formControl", ctx.formControl)("matAutocomplete", auto_r4)("disabled", ctx.disabled)("required", ctx.required);
        i0.ɵɵadvance();
        i0.ɵɵconditional(ctx.showFlag === true ? 4 : -1);
        i0.ɵɵadvance();
        i0.ɵɵproperty("displayWith", ctx.displayFn.bind(ctx));
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("itemSize", "25")("ngStyle", i0.ɵɵpureFunction1(15, _c0, ctx.height));
        i0.ɵɵadvance();
        i0.ɵɵproperty("cdkVirtualForOf", i0.ɵɵpipeBind1(9, 13, ctx.filteredCountries$))("cdkVirtualForTrackBy", ctx.trackByAlpha2);
      }
    },
    dependencies: [CommonModule, i1.NgClass, i1.NgIf, i1.NgStyle, i1.AsyncPipe, i1.UpperCasePipe, ReactiveFormsModule, i2.DefaultValueAccessor, i2.NgControlStatus, i2.RequiredValidator, i2.FormControlDirective, MatAutocompleteModule, i3.MatAutocomplete, i4.MatOption, i3.MatAutocompleteTrigger, MatInputModule, i5.MatInput, i6.MatFormField, i6.MatLabel, i6.MatSuffix, MatFormFieldModule, MatIconModule, i7.MatIcon, ScrollingModule, i8.CdkFixedSizeVirtualScroll, i8.CdkVirtualForOf, i8.CdkVirtualScrollViewport],
    styles: [".country-option[_ngcontent-%COMP%]{display:flex;align-items:center;gap:8px}.country-option[_ngcontent-%COMP%]   .flag[_ngcontent-%COMP%], .flag-suffix[_ngcontent-%COMP%]{font-size:1em;display:inline;margin-right:10px}.codes[_ngcontent-%COMP%]{margin-left:auto;font-size:.9em;color:#666}.codes[_ngcontent-%COMP%]   [_ngcontent-%COMP%]:empty{display:none}"],
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CountrySelectComponent, [{
    type: Component,
    args: [{
      selector: 'ng-country-select',
      standalone: true,
      imports: [CommonModule, ReactiveFormsModule, MatAutocompleteModule, MatInputModule, MatFormFieldModule, MatIconModule, ScrollingModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CountrySelectComponent),
        multi: true
      }],
      template: "<mat-form-field [appearance]=\"appearance\" [color]=\"color\">\n  <mat-label>{{ placeholder }}</mat-label>\n  <input matInput [formControl]=\"formControl\" [matAutocomplete]=\"auto\" [disabled]=\"disabled\" [required]=\"required\">\n  @if (showFlag === true) {\n  <mat-icon matSuffix class=\"flag-suffix fi\" [ngClass]=\"'fi-' + formControl.value?.alpha2\"></mat-icon>\n  }\n\n  <mat-autocomplete #auto=\"matAutocomplete\" [displayWith]=\"displayFn.bind(this)\"\n    (optionSelected)=\"onOptionSelected($event.option.value)\" (closed)=\"onAutocompleteClosed()\">\n    <cdk-virtual-scroll-viewport [itemSize]=\"'25'\" [ngStyle]=\"{'height': height}\">\n      <mat-option *cdkVirtualFor=\"let country of filteredCountries$ | async; trackBy: trackByAlpha2\" [value]=\"country\">\n        <div class=\"country-option\">\n          @if (showFlag === true) {\n          <span class=\"flag fi\" [ngClass]=\"'fi-' + country?.alpha2\"></span>\n          }\n          @if (alpha2Only === true) {\n          <span class=\"name\">{{ country.alpha2 | uppercase }}</span>\n          } @else if (alpha3Only === true) {\n          <span class=\"name\">{{ country.alpha3 | uppercase }}</span>\n          } @else {\n          <span class=\"name\">{{ country.translations[lang] }}</span>\n          <span *ngIf=\"showCodes\" class=\"codes\">\n            ({{ country.alpha2 | uppercase }} / {{ country.alpha3 | uppercase }})\n          </span>\n          }\n        </div>\n      </mat-option>\n    </cdk-virtual-scroll-viewport>\n  </mat-autocomplete>\n</mat-form-field>",
      styles: [".country-option{display:flex;align-items:center;gap:8px}.country-option .flag,.flag-suffix{font-size:1em;display:inline;margin-right:10px}.codes{margin-left:auto;font-size:.9em;color:#666}.codes :empty{display:none}\n"]
    }]
  }], null, {
    defaultCountry: [{
      type: Input
    }],
    lang: [{
      type: Input
    }],
    searchAllLanguages: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    selectedCountry: [{
      type: Input
    }],
    formControl: [{
      type: Input
    }],
    debounceTime: [{
      type: Input
    }],
    appearance: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    showCodes: [{
      type: Input
    }],
    includeCountries: [{
      type: Input
    }],
    excludeCountries: [{
      type: Input
    }],
    alpha2Only: [{
      type: Input
    }],
    alpha3Only: [{
      type: Input
    }],
    showFlag: [{
      type: Input
    }],
    countrySelected: [{
      type: Output
    }],
    inputChanged: [{
      type: Output
    }],
    closed: [{
      type: Output
    }]
  });
})();

/*
 * Public API Surface of country-select
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CountrySelectComponent };
