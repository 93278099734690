<completed-menu
  class="menu"
  [menu]="menu()"
  [privileges]="privileges()"
  [userStatus]="userStatus()"
  (preview)="showPreview.emit($event)"
></completed-menu>

<contactless
  class="contactless"
  [customUrl]="customUrl()"
  [menu]="menu()"
></contactless>

<div class="flex-wrapper">
  <div class="right-column">
    <h3 class="block-title">
      {{ 'shared.ordertaking.title' | transloco }}
    </h3>
    <content-block class="wrapper">
      @if (hasModule('order')) {
        <app-ordertaking
          class="ordertaking-settings"
          [item]="menu()"
          [lang]="lang()"
          [layouts]="orderTakingLayouts()"
          (enableOrdertaking)="enableOrdertaking.emit($event)"
          (patchMenu)="changeMenu($event)"
        ></app-ordertaking>
      } @else {
        <p>{{ 'menus.complete.ordertaking.advertisement' | transloco }}</p>
        <button mat-flat-button color="primary" (click)="openSupportDialog()">
          {{ 'menus.complete.ordertaking.support' | transloco }}
        </button>
      }
      @if (
        hasModule('order') &&
        menu().template_detail &&
        menu().template_detail.consumer_template
      ) {
        <linked-template
          class="template"
          [data]="menu()?.template_detail?.consumer_template"
          [image]="
            menu()?.template_detail?.consumer_template?.image ||
            'assets/img/reports/ordercards.svg'
          "
          [lang]="lang()"
          [showArchive]="true"
          [showPreview]="false"
          [showPrint]="false"
          [title]="'menus.complete.personalised_menus' | transloco"
          [translations]="menuTranslations()"
          [menu]="menu()"
          (createArchive)="archiveMenu.emit({ menu: menu(), task_type: 4 })"
        ></linked-template>
      }
    </content-block>

    <backup-menus
      class="backup"
      [lang]="lang()"
      [limit]="user().status?.usage_limits?.limit_backups_per_month"
      [menu]="menu()"
      [user]="user()"
      (create)="createMenuBackup.emit()"
      (delete)="deleteMenuBackup.emit($event)"
      (preview)="showPreview.emit($event)"
      (restore)="restoreMenuBackup.emit($event)"
    ></backup-menus>
  </div>
  <linked-templates
    class="linked-templates"
    [user]="user()"
    [menu]="menu()"
    [lang]="lang()"
    [interfaceLang]="lang()"
    [translations]="menuTranslations()"
    (createArchive)="archiveMenu.emit($event)"
    (preview)="showPreview.emit($event)"
    (downloadReport)="downloadReport.emit($event)"
    (downloadStats)="downloadStats.emit($event)"
  >
  </linked-templates>
</div>
