import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient, PreIngredients } from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { handleHttpError } from 'src/app/shared/ngrx/shared.actions';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { cloneDeep } from 'lodash-es';
import {
  catchError,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import * as DishesMenuActions from './dishes-menu.actions';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { incrementAiUsage } from 'src/app/shared/user/ngrx/user.actions';
import { sharedFeature } from '../shared.state';

@Injectable()
export class DishesMenuEffects {
  private actions$ = inject(Actions);
  private configService = inject(ConfigService);
  private genericsService = inject(GenericsService);
  private store = inject<Store<State>>(Store);
  private utils = inject(UtilsService);

  fetchRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.fetchRecipes),
      tap(() => {
        this.store.dispatch(DishesMenuActions.clearRecipes());
        this.store.dispatch(DishesMenuActions.setLoading({ loading: true }));
      }),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ url, queryParams }, location]) =>
        this.genericsService
          .get<
            Recipe[]
          >(url, UtilsService.addLocationParam(location, queryParams))
          .pipe(
            mergeMap((result) => [
              DishesMenuActions.setRecipes({ recipes: result }),
              DishesMenuActions.setLoading({ loading: false }),
            ]),
            catchError((error) => [
              DishesMenuActions.setLoading({ loading: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  removeRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.removeRecipe),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      mergeMap(([{ dish, data, onFulfilled, queryParams }, location]) =>
        this.genericsService
          .post<
            { recipe: number },
            Dish
          >(`${dish.url}remove_recipe/`, data, UtilsService.addLocationParam(location, queryParams))
          .pipe(
            mergeMap((res) => {
              onFulfilled?.(res);
              return [
                DishesMenuActions.updateRecipesAfterRemoval({
                  id: data.recipe,
                }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  createRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.createRecipe),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ url, data, onFulfilled, params }, location]) =>
        this.genericsService
          .post<
            Partial<Recipe>,
            { recipe: Recipe; dish: Dish }
          >(`${url}create_recipe/`, data, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((result) => {
              onFulfilled?.(result);
              return [DishesMenuActions.addRecipe({ recipe: result.recipe })];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  chooseRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.chooseRecipe),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ url, recipeId, onFulfilled, params }, location]) =>
        this.genericsService
          .post<
            { recipe: number },
            { recipe: Recipe; dish: Dish }
          >(`${url}add_recipe/`, { recipe: recipeId }, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((result) => {
              if (onFulfilled) onFulfilled(result);
              return [DishesMenuActions.addRecipe({ recipe: result.recipe })];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.updateRecipe),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ url, data, params, onFulfilled }, location]) =>
        this.genericsService
          .patch<
            Partial<Recipe>,
            Recipe
          >(url, data, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((result) => {
              onFulfilled?.(result);
              return [
                DishesMenuActions.setUpdatedRecipe({ recipe: result }),
                ...(data.update_quantities
                  ? [
                      DishesMenuActions.setIngredientInfo({ info: null }),
                      DishesMenuActions.replaceRecipeIngredients({
                        updatedRecipe: result,
                        ingredients: result.ingredients_list,
                      }),
                    ]
                  : []),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  addMultipleIngredientsToRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.addMultipleIngredientsToRecipe),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ recipe, data }, location]) =>
        this.genericsService
          .post<
            PreIngredients,
            SimpleRecipeIngredient[]
          >(`${this.configService.recipes}${recipe?.id}/ingredients/create_add_multiple/`, data, UtilsService.addLocationParam(location, {}))
          .pipe(
            mergeMap((recipeIngredient) => [
              DishesMenuActions.replaceRecipeIngredients({
                updatedRecipe: recipe,
                ingredients: recipeIngredient,
              }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  addIngredientToRecipe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.addIngredientToRecipe),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ ingredientId, recipe, onFulfilled }, location]) =>
        this.genericsService
          .post<
            { ingredient: number },
            RecipeIngredient
          >(recipe.ingredients, { ingredient: ingredientId }, UtilsService.addLocationParam(location, {}))
          .pipe(
            mergeMap((recipeIngredient) => {
              onFulfilled?.();
              return [
                DishesMenuActions.setIngredientToRecipe({
                  recipe,
                  ingredient: recipeIngredient,
                }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  deleteDishRecipeIngredient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.deleteDishRecipeIngredient),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ recipe, recipeIngredient }, location]) => {
        if (!recipeIngredient.id) {
          return [DishesMenuActions.removeEmptyIngredientFromDish({ recipe })];
        }
        return this.genericsService
          .delete(
            recipeIngredient.url,
            UtilsService.addLocationParam(location, null),
          )
          .pipe(
            mergeMap(() => [
              DishesMenuActions.removeDeletedDishRecipeIngredient({
                recipe,
                ingredientId: recipeIngredient.id,
              }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          );
      }),
    ),
  );

  createNewIngredient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.createNewIngredient),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ recipe, data, onError, params }, location]) =>
        this.genericsService
          .post<
            Partial<Recipe>,
            Ingredient
          >(this.configService.ingredients, data, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((newIngredient) =>
              this.genericsService
                .post<Partial<RecipeIngredient>, RecipeIngredient>(
                  recipe.ingredients,
                  {
                    ingredient: newIngredient.id,
                  },
                  UtilsService.addLocationParam(location),
                )
                .pipe(
                  mergeMap((ingredient: RecipeIngredient) => [
                    DishesMenuActions.setIngredientToRecipe({
                      recipe,
                      ingredient,
                    }),
                  ]),
                ),
            ),
            catchError((error) => {
              onError?.();
              return [handleHttpError({ error })];
            }),
          ),
      ),
    ),
  );

  fetchIngredientInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.fetchIngredientInfo),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ url, params }, location]) =>
        this.genericsService
          .get<RecipeIngredient>(
            url,
            UtilsService.addLocationParam(location, params),
          )
          .pipe(
            mergeMap((ingredient) => [
              DishesMenuActions.setIngredientInfo({ info: ingredient }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateIngredientInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.updateIngredientInfo),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ url, data, params, callBack }, location]) => {
        const queryParams = params ? params : {};
        data = this.utils.fixVat(data);
        return this.genericsService
          .patch<
            Partial<RecipeIngredient>,
            RecipeIngredient
          >(url, data, UtilsService.addLocationParam(location, queryParams))
          .pipe(
            mergeMap((ingredient) => {
              callBack?.();
              return [
                DishesMenuActions.setIngredientInfo({ info: ingredient }),
              ];
            }),
            catchError((error) => {
              callBack?.(error);
              return [handleHttpError({ error })];
            }),
          );
      }),
    ),
  );

  updateRecipeIngredient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.updateRecipeIngredient),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ url, recipe_id, data, onFulfilled, params }, location]) =>
        this.genericsService
          .patch<
            Partial<RecipeIngredient>,
            RecipeIngredient
          >(url, data, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((ingredient) => {
              onFulfilled?.();
              return [
                DishesMenuActions.updateIngredientOfRecipe({
                  recipe_id,
                  recipe_ingredient: ingredient,
                }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateIngredient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.updateIngredient),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(
        ([
          { url, id, data, recipe_id, recipe_ingredient, onFulfilled, params },
          location,
        ]) =>
          this.genericsService
            .patch<
              Partial<Ingredient>,
              Ingredient
            >(url, data, UtilsService.addLocationParam(location, params))
            .pipe(
              mergeMap((ingredient) => {
                onFulfilled?.();
                if (ingredient.id !== id) {
                  return [
                    DishesMenuActions.updateRecipeIngredient({
                      url: recipe_ingredient.url,
                      recipe_id: recipe_id,
                      data: { ingredient: ingredient.id },
                      onFulfilled,
                    }),
                  ];
                }
                const newRecipeIngredient = cloneDeep({
                  ...recipe_ingredient,
                  ingredient_detail: ingredient,
                });
                return [
                  DishesMenuActions.updateIngredientOfRecipe({
                    recipe_id: recipe_id,
                    recipe_ingredient: newRecipeIngredient,
                  }),
                ];
              }),
              catchError((error) => [handleHttpError({ error })]),
            ),
      ),
    ),
  );

  generateAiRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.generateAiRecipes),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      tap(() => {
        this.store.dispatch(
          DishesMenuActions.setAiRecipes({
            recipes: [],
          }),
        );
        this.store.dispatch(
          DishesMenuActions.setAiRecipesLoading({
            loading: true,
          }),
        );
      }),
      switchMap(([{ url, lang, location, onFulfilled }, currentLocation]) =>
        this.genericsService
          .post<
            { lang: ContentLanguage; auto_create?: boolean },
            { dish: Dish; recipes: Recipe[] }
          >(url + 'generate_recipe/', { lang, auto_create: true }, UtilsService.addLocationParam(location !== undefined ? location : currentLocation))
          .pipe(
            mergeMap((results) => {
              onFulfilled?.(results);
              return [
                ...results['recipes'].map((recipe) =>
                  DishesMenuActions.addRecipe({ recipe }),
                ),
                DishesMenuActions.setAiRecipesLoading({
                  loading: false,
                }),
                incrementAiUsage({ credit: 'recipes', amount: 1 }),
              ];
            }),
            catchError((error) => [
              DishesMenuActions.setAiRecipesLoading({
                loading: false,
              }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  generateAiAllergens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.generateAiAllergens),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      tap(() => {
        this.store.dispatch(
          DishesMenuActions.setAiAllergensLoading({
            loading: true,
          }),
        );
      }),
      switchMap(([{ url, lang, onFulfilled }, location]) =>
        this.genericsService
          .post<
            { lang: ContentLanguage; auto_create?: boolean },
            Dish
          >(url + 'generate_allergens/', { lang, auto_create: true }, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap((results) => {
              onFulfilled?.(results);
              return [
                DishesMenuActions.setAiAllergensLoading({
                  loading: false,
                }),
                incrementAiUsage({ credit: 'allergens', amount: 1 }),
              ];
            }),
            catchError((error) => [
              DishesMenuActions.setAiAllergensLoading({
                loading: false,
              }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  generateAiDescription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DishesMenuActions.generateAiDescription),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      tap(() => {
        this.store.dispatch(
          DishesMenuActions.setAiDescriptionLoading({
            loading: true,
          }),
        );
      }),
      switchMap(([{ url, lang, onFulfilled }, location]) =>
        this.genericsService
          .post<
            { lang: ContentLanguage; auto_create?: boolean },
            Dish
          >(url + 'generate_description/', { lang, auto_create: true }, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap((results) => {
              onFulfilled?.(results);
              return [
                DishesMenuActions.setAiDescriptionLoading({
                  loading: false,
                }),
                incrementAiUsage({ credit: 'dishes', amount: 1 }),
              ];
            }),
            catchError((error) => [
              DishesMenuActions.setAiDescriptionLoading({
                loading: false,
              }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );
}
