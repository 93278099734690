<div class="wrapper">
  @if (done) {
  <img
    [class.reveal]="revealDoneImage"
    class="side-image"
    src="../../../../assets/img/onboarding_step4.jpg"
    alt="side image"
  />
  } @else {
  <img
    [class.reveal]="revealImage"
    (load)="imgLoaded()"
    class="side-image"
    src="../../../../assets/img/onboarding_step1-3.jpg"
    alt="side image"
  />
  }

  <div class="content">
    @if (spinnerState$ | async) {
    <div class="spinner-wrapper">
      <spinner></spinner>
    </div>
    }
    <div class="content-wrapper">
      <img
        [class.reveal]="revealLogo"
        src="../../../../assets/img/logo_dark.svg"
        alt="logo"
        class="logo"
      />
      <div class="steps-wrapper" [class.reveal]="revealContent">
        @switch (currentStep) { @case (STEPS.FIRST) {
        <app-registration-step-one
          [lang]="lang"
          [user]="user$ | async"
          [isSafari]="isSafari"
          (buttonPortal)="setPortal($event)"
          class="step"
        >
        </app-registration-step-one>
        } @case (STEPS.SECOND) {
        <app-registration-step-two
          [user]="user$ | async"
          [lang]="lang"
          [isSafari]="isSafari"
          [options]="templates$ | async"
          (buttonPortal)="setPortal($event)"
          (fetchTemplates)="fetchTemplates()"
          (requestNewContent)="requestOption($event)"
          (submitTemplates)="submitTemplates($event)"
          class="step"
        >
        </app-registration-step-two>
        } @case (STEPS.THIRD) {
        <app-registration-step-three
          [user]="user"
          class="step"
          (doneEvent)="thirdStepDone()"
          (buttonPortal)="setPortal($event)"
        >
        </app-registration-step-three>
        } }
      </div>
    </div>
    <footer class="footer" [class.reveal]="revealContent">
      <ng-template [cdkPortalOutlet]="stepsButtonPortal"></ng-template>
    </footer>
  </div>
</div>
