import { Component, output, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss'],
  imports: [MatButtonModule, StopPropagationDirective, MatIconModule],
})
export class SelectImageComponent {
  readonly canDelete = input<boolean>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly imageUrl = input<string>(undefined);
  readonly label = input<string>(undefined);
  readonly selected = input<boolean>(undefined);

  readonly imgSelected = output();
  readonly delete = output();

  deleteButtonDisabled = false;
}
