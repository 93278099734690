import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Course, PopularDish } from 'src/app/shared/Models/dashboard';
import {
  OnboardingTemplate,
  UserOnboardingTemplate,
} from 'src/app/shared/Models/onboarding_template';
import * as DashboardActions from './dashboard.actions';
import { SimpleMenu } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';

export interface Dashboard {
  navigation: boolean;
  isLoading: boolean;
  latestTemplates: OnboardingTemplate[];
  mostPopularDish: PopularDish;
  menusByDate: SimpleMenu[];
  lastMenu: SimpleMenu;
  menusCreated: {
    this_month: number;
    all_time: number;
    this_week: number;
    this_year: number;
  };
  dishes: {
    list: MenuDish[];
    is_loading: boolean;
  };
  courses: {
    results: Course[];
    is_loading: boolean;
  };
  userOnboardingTemplates: UserOnboardingTemplate[];
}

const initialState: Dashboard = {
  navigation: false,
  isLoading: false,
  latestTemplates: [],
  mostPopularDish: null,
  lastMenu: null,
  menusByDate: [],
  menusCreated: null,
  dishes: {
    list: [],
    is_loading: false,
  },
  courses: {
    results: [],
    is_loading: false,
  },
  userOnboardingTemplates: [],
};

const reducer = createReducer(
  initialState,
  on(DashboardActions.setData, (state, { dashboard }) => {
    const {
      menus_created,
      menus_by_date,
      most_popular_dish,
      last_menu,
      ...rest
    } = dashboard;
    return {
      ...state,
      ...rest,
      menusByDate: menus_by_date,
      mostPopularDish: most_popular_dish,
      lastMenu: last_menu,
      menusCreated: menus_created,
    };
  }),
  on(DashboardActions.setTemplateRestrictions, (state, { templates }) => {
    return {
      ...state,
      latestTemplates: templates,
    };
  }),
  on(DashboardActions.startLoadingDashboard, (state, { is_loading }) => {
    return {
      ...state,
      isLoading: is_loading,
    };
  }),
  on(DashboardActions.clearData, () => {
    return {
      ...initialState,
    };
  }),
  on(DashboardActions.clearLastMenu, (state, { id }) => {
    if (state.lastMenu?.id === id) {
      return {
        ...state,
        lastMenu: null,
      };
    }
    return {
      ...state,
    };
  }),
  on(DashboardActions.setDishesCurrentMenuLoading, (state, { loading }) => {
    return {
      ...state,
      dishes: {
        ...state.dishes,
        isLoading: loading,
      },
    };
  }),
  on(DashboardActions.setDishesCurrentMenu, (state, { dishes }) => {
    return {
      ...state,
      dishes: {
        ...state.dishes,
        list: dishes,
      },
    };
  }),
  on(DashboardActions.setCoursesLoading, (state, { loading }) => {
    return {
      ...state,
      courses: {
        ...state.courses,
        isLoading: loading,
      },
    };
  }),
  on(DashboardActions.setCourses, (state, { courses }) => {
    return {
      ...state,
      courses: {
        ...state.courses,
        results: courses,
      },
    };
  }),
  on(DashboardActions.setUserOnboardingTemplates, (state, { payload }) => ({
    ...state,
    userOnboardingTemplates: payload,
  })),
  on(DashboardActions.setUserOnboardingTemplateUsed, (state, { id, menu }) => {
    let templates = state.userOnboardingTemplates?.slice() || [];
    templates = templates.map((tmp) => {
      if (tmp.id === id) {
        return new UserOnboardingTemplate({
          ...tmp,
          menu: menu.id,
          menu_detail: menu,
        });
      }
      return tmp;
    });
    return {
      ...state,
      userOnboardingTemplates: templates,
    };
  }),
  on(DashboardActions.removeUserOnboardingTemplateMenu, (state, { menuId }) => {
    return {
      ...state,
      userOnboardingTemplates: state.userOnboardingTemplates.map((template) => {
        if (template.menu === menuId) {
          template = cloneDeep(template);
          template.menu = null;
          template.menu_detail = null;
        }
        return template;
      }),
    };
  }),
);

export const dashboardFeature = createFeature({
  name: 'dashboard',
  reducer,
  extraSelectors: ({ selectCourses, selectDishes }) => ({
    selectCoursesLoading: createSelector(
      selectCourses,
      (courses) => courses.is_loading,
    ),
    selectCoursesItems: createSelector(
      selectCourses,
      (courses) => courses.results,
    ),
    selectDishesLoading: createSelector(
      selectDishes,
      (dishes) => dishes.is_loading,
    ),
    selectDishesItems: createSelector(selectDishes, (dishes) => dishes.list),
  }),
});
