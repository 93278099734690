import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  output,
  input,
} from '@angular/core';
import { Course } from 'src/app/shared/Models/dashboard';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { User } from 'src/app/shared/Models/user';
import { SafePipe } from '../../shared/Pipes/safe.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { SpinnerComponent } from '../../shared/Components/spinner/spinner.component';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SpinnerComponent,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    TranslocoPipe,
    SafePipe,
  ],
})
export class CoursesComponent implements OnChanges {
  readonly hideCourses = input<boolean>(undefined);
  readonly coursesLoading = input<boolean>(undefined);
  readonly courses = input<Course[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);

  readonly updateUser = output<DeepPartial<User>>();

  hideCoursesPanel = true;

  ngOnChanges(changes: SimpleChanges): void {
    if ('hideCourses' in changes) {
      this.hideCoursesPanel = this.hideCourses();
    }
  }

  toggleCourseList(value: boolean): void {
    this.hideCoursesPanel = !value;
    this.updateUser.emit({
      tutorials: {
        dashboard_hide_courses: !value,
      },
    });
  }
}
