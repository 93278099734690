<h3 class="block-title">{{ 'menus.complete.backup.title' | transloco }}</h3>
<content-block class="wrapper" [loading]="loading">
  @for (backup of menu()?.backups; track backup) {
    <backup-menu
      [backup]="backup"
      [blockDelete]="!user()?.status?.privileges?.delete_menu"
      [blockRestore]="!user()?.status?.privileges?.edit_menu"
      [lang]="lang()"
      [menu]="menu()"
      (delete)="delete.emit($event)"
      (preview)="preview.emit($event)"
      (restore)="restore.emit($event)"
    ></backup-menu>
  }
  <section class="create">
    @if (!menu()?.backups?.length) {
      <p class="create-content">
        {{ 'menus.complete.backup.create.content' | transloco }}
      </p>
    }
    <span
      class="button-wrapper"
      [matTooltip]="
        disabled
          ? ('menus.complete.backup.create.tooltip-limit' | transloco)
          : !user()?.status?.privileges?.edit_menu
            ? ('menus.complete.backup.create.tooltip-edit' | transloco)
            : ''
      "
      matTooltipClass="top-navbar-tooltip"
    >
      <button
        mat-button
        class="create-button"
        (click)="saveNewVersion()"
        [disabled]="
          !(menu() && menu().url) ||
          disabled ||
          !user()?.status?.privileges?.edit_menu
        "
      >
        {{ 'menus.complete.backup.create.save-button' | transloco }}
      </button>
    </span>
  </section>
  @if (limit()) {
    <footer class="limit">
      {{ menu()?.backups?.length }} / {{ limit() }}
      {{ 'menus.complete.backup.backups-used' | transloco }}
    </footer>
  }
</content-block>
