@if (isVisible) {
  <div class="picker">
    <compact-picker
      class="color-picker"
      [class.disabled]="disabled()"
      [color]="color()"
      [control]="colorControl"
    >
      <div class="buttons">
        <button
          mat-flat-button
          color="primary"
          type="button"
          class="btn btn-primary apply-button"
          (click)="discardClick($event)"
        >
          {{ 'shared.buttons.apply' | transloco }}
        </button>
      </div>
    </compact-picker>
    <div class="overlay" (mousedown)="discardClick($event)"></div>
  </div>
}
