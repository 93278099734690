@if (isLoading$ | async) {
  <spinner></spinner>
}
@for (recipe of recipes$ | async; track trackByName($index, recipe)) {
  <recipe-item
    [dish]="dish()"
    [ingredientsAuto]="ingredientsAuto$ | async"
    [ingredientInfo]="ingredientInfo$ | async"
    [lang]="lang()"
    [recipe]="recipe"
    [translations]="translations()"
    (addIngredientToRecipe)="addIngredientToRecipeEvent($event)"
    (clearIngredientsAuto)="clearIngredientsAuto()"
    (clearIngredientInfo)="clearIngredientInfo()"
    (collapsed)="collapsed(recipe)"
    (fetchIngredientsAuto)="fetchIngredientsAuto($event)"
    (fetchIngredientsInfo)="fetchIngredientsInfo($event)"
    (createNewIngredientEvent)="createNewIngredient($event)"
    (patchIngredientEvent)="patchIngredient($event)"
    (deleteDishRecipeIngredientEvent)="deleteDishRecipeIngredient($event)"
    (addMultipleIngredientsToRecipeEvent)="
      addMultipleIngredientsToRecipe($event)
    "
    (patchRecipe)="patchRecipe.emit($event)"
    (refreshDish)="setShouldRefresh(true)"
    (removeRecipeEvent)="removeSelectedRecipe($event)"
  >
  </recipe-item>
}

@if ((isLoading$ | async) === false) {
  <div class="recipe-actions">
    <h3 class="mat-h3">
      {{
        (translations() &&
          translations()['write.blocks.dish-overview.recipes.add']) ||
          ('write.blocks.dish-overview.recipes.add' | transloco)
      }}
    </h3>
    <div class="existing-wrapper">
      <mat-form-field class="add-existing">
        @if (translations()) {
          <mat-label>{{
            translations()['write.blocks.dish-overview.recipes.recipe_name'] ||
              ('write.blocks.dish-overview.recipes.recipe_name' | transloco)
          }}</mat-label>
        }
        <input
          #recipeInput
          matInput
          maxlength="250"
          type="text"
          [formControl]="recipeControl"
          [matAutocomplete]="auto"
          [placeholder]="
            'write.blocks.dish-overview.recipes.search' | transloco
          "
        />
        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </mat-form-field>
      @if (loading) {
        <mat-progress-bar
          class="progress"
          mode="indeterminate"
        ></mat-progress-bar>
      }
      <mat-autocomplete
        #auto="matAutocomplete"
        [displayWith]="displayWith"
        (optionSelected)="recipeSelected($event)"
      >
        @for (recipe of autoRecipes(); track recipe) {
          <mat-option stop-propagation [value]="recipe">
            {{ recipe[lang()] }}
            @if (currentLocation() && !recipe.location) {
              <span class="location"
                >({{
                  'write.blocks.dish-overview.recipes.shared-recipe'
                    | transloco
                }})</span
              >
            } @else if (!currentLocation() && recipe.location) {
              <span class="location"
                >({{
                  'write.blocks.dish-overview.recipes.location-recipe'
                    | transloco
                }})</span
              >
            }
          </mat-option>
        }
        @if (recipeName && recipeName !== '') {
          <mat-option
            stop-propagation
            class="add-option icon-button-density-3"
            [attr.title]="recipeName"
          >
            <mat-icon>add</mat-icon
            >{{
              (translations() &&
                translations()['write.blocks.dish-overview.recipes.create']) ||
                ('write.blocks.dish-overview.recipes.create' | transloco)
            }}
          </mat-option>
        }
      </mat-autocomplete>
    </div>
    @if ((recipes$ | async)?.length === 0) {
      <magic-stick
        class="menu-action"
        [ai]="true"
        [aiCredits]="1"
        [aiCreditsRemaining]="aiCreditsRemaining()?.recipes"
        [loading]="aiRecipesLoading()"
        [title]="
          'write.blocks.dish-overview.recipes.generate.title' | transloco
        "
        [description]="
          'write.blocks.dish-overview.recipes.generate.description' | transloco
        "
        [buttonMsg]="
          'write.blocks.dish-overview.recipes.generate.button' | transloco
        "
        [buttonLoadingMsg]="
          'write.blocks.dish-overview.recipes.generate.loading' | transloco
        "
        [tooltip]="
          'write.blocks.dish-overview.recipes.generate.tooltip' | transloco
        "
        (clicked)="
          isTrial() && !profileComplete()
            ? showTrialBanner()
            : generateAiRecipes.emit()
        "
      ></magic-stick>
    }
  </div>
}
