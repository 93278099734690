<div class="translations-actions">
  <h3>
    {{ 'menus.steps.translate.title' | transloco }}
    {{ 'shared.languages.' + lang() | transloco }}
  </h3>
  <form class="translations-search-form" #translationsForm="ngForm">
    <div class="menu-filter icon-button-density-3">
      <input
        #translationsInput
        name="transDish"
        ngControl="transDish"
        type="text"
        [ngModel]
        [class.safari]="isSafari"
        [placeholder]="
          (translations() && translations()['write.blocks.main.search']) ||
            'write.blocks.main.search' | transloco
        "
        (keydown.enter)="(false)"
      />
      @if (translationsInput.value) {
        <button
          class="search-clear"
          stop-propagation
          mat-icon-button
          aria-label="Clear"
          (click)="clearFilteredTranslations()"
        >
          <mat-icon class="blue">clear</mat-icon>
        </button>
      }
      <button
        class="filter-button"
        mat-icon-button
        aria-label="Search"
        (click)="translationsInput.focus()"
      >
        <mat-icon class="blue">search</mat-icon>
      </button>
    </div>
  </form>
</div>

<div class="translate-dish">
  @if (isMobileView) {
    <button
      mat-flat-button
      color="primary"
      class="add-entity-button further-actions-button"
      (click)="showMobilePanel()"
    >
      {{ 'dishes.translation.further-actions' | transloco }}
      <mat-icon>arrow_right</mat-icon>
    </button>
  }
  @if (lang()) {
    <content-block
      class="translations-list"
      color="primary"
      [title]="'dishes.translation.translate_to' | transloco"
      [secondTitle]="'shared.languages.' + lang() | transloco"
      [translations]="true"
      [hideTitle]="filter === 2 && misssingTranslationsCount === 0"
      [loading]="menuDishesLoading()"
    >
      @if (filter === 2 && misssingTranslationsCount === 0) {
        <div>
          <no-translations-screen
            [isOtherLang]="checkOtherLanguage()"
            [selectedQuote]="selectQuote()"
          >
          </no-translations-screen>
        </div>
      }
      @for (item of filteredDishes; track trackMenudishesFn($index, item)) {
        <div>
          @if (
            !(
              item?.separator_detail &&
              ['cou', 'opt'].includes(item?.separator_detail.category)
            )
          ) {
            <app-translation-item
              class="translation-item"
              [baseLanguage]="menu()?.base_language"
              [currentMenuDish]="lastDish"
              [data]="item?.dish_detail || item.separator_detail | copyDeep"
              [language]="lang()"
              [menudish]="item"
              [similarTranslations]="similarTranslations$ | async"
              [showRequired]="filter === 1"
              [showLoader]="menudishUnderEdit.get(item.id)"
              (changeOtherTranslation)="changeOtherTranslation($event)"
              (changeTranslation)="changeTranslation($event)"
              (getSame)="getSame($event, item)"
              (setElement)="setElement($event)"
              (similarTranslation)="similarTranslationDish(item)"
              (similarTranslationDesc)="similarTranslationDesc(item, $event)"
            >
            </app-translation-item>
          }
        </div>
      }
    </content-block>
  }

  <menu-details
    class="menu-details"
    [disabled]="menuDishesLoading()"
    [hideEmptyLang]="menu()?.base_language"
    [lang]="lang()"
    [menu]="menu()"
    [showVariants]="false"
    [title]="'translations.details.title' | transloco"
    (changeMenu)="patchMenu.emit($event)"
    (restoreDefault)="restoreDefault($event)"
    (saveDefault)="saveDefault($event)"
  ></menu-details>

  @if (
    showDishVariants ||
    showSectionVariants ||
    showMenudishVaraints ||
    showMenuVariants
  ) {
    <h3 class="block-title">{{ 'translations.variants.title' | transloco }}</h3>
    <mat-accordion [attr.dir]="rtl ? 'rtl' : 'ltr'">
      @if (showDishVariants) {
        <mat-expansion-panel class="variants" [disabled]="menuDishesLoading()">
          <mat-expansion-panel-header
            class="not-missing"
            [class.missing]="emptyDishVariants > 0"
          >
            @if (emptyDishVariants > 0) {
              <span class="badge">{{ emptyDishVariants }}</span>
            }
            {{ 'translations.variants-dishes' | transloco }}
          </mat-expansion-panel-header>
          @for (
            item of emptyVariants;
            track trackDishVariantsFn($index, item)
          ) {
            @for (
              variant of item.variants;
              track trackPriceVariantsFn($index, variant)
            ) {
              <translate-price-variant
                [baseLang]="menu()?.base_language"
                [variant]="variant"
                [dish]="item.dish"
                [lang]="lang()"
                (changeName)="
                  changeVariantTranslation($event, item.dish, false)
                "
              >
              </translate-price-variant>
            }
          }
        </mat-expansion-panel>
      }
      @if (showSectionVariants) {
        <mat-expansion-panel class="variants" [disabled]="menuDishesLoading()">
          <mat-expansion-panel-header
            class="not-missing"
            [class.missing]="emptySectionVariantsCount > 0"
          >
            @if (emptySectionVariantsCount > 0) {
              <span class="badge">{{ emptySectionVariantsCount }}</span>
            }
            {{ 'translations.variants-sections' | transloco }}
          </mat-expansion-panel-header>
          @for (
            item of emptySectionVariants;
            track trackDishVariantsFn($index, item)
          ) {
            @for (
              variant of item.variants;
              track trackPriceVariantsFn($index, variant)
            ) {
              <translate-price-variant
                [baseLang]="menu()?.base_language"
                [variant]="variant"
                [dish]="item.dish"
                [lang]="lang()"
                (changeName)="
                  changeVariantTranslation($event, item.dish, false)
                "
              >
              </translate-price-variant>
            }
          }
        </mat-expansion-panel>
      }
      @if (showMenudishVaraints) {
        <mat-expansion-panel class="variants" [disabled]="menuDishesLoading()">
          <mat-expansion-panel-header
            class="not-missing"
            [class.missing]="emptyMenudishVariantsCount > 0"
          >
            @if (emptyMenudishVariantsCount > 0) {
              <span class="badge">{{ emptyMenudishVariantsCount }}</span>
            }
            {{ 'translations.variants-menudishes' | transloco }}
          </mat-expansion-panel-header>
          @for (
            item of emptyMenudishVariants;
            track trackDishVariantsFn($index, item)
          ) {
            @for (
              variant of item.variants;
              track trackPriceVariantsFn($index, variant)
            ) {
              <translate-price-variant
                [baseLang]="menu()?.base_language"
                [variant]="variant"
                [dish]="item.dish"
                [lang]="lang()"
                (changeName)="changeVariantTranslation($event, item.dish, true)"
              >
              </translate-price-variant>
            }
          }
        </mat-expansion-panel>
      }
      @if (showMenuVariants) {
        <mat-expansion-panel class="variants" [disabled]="menuDishesLoading()">
          <mat-expansion-panel-header
            class="not-missing"
            [class.missing]="emptyMenuVariants > 0"
          >
            @if (emptyMenuVariants > 0) {
              <span class="badge">{{ emptyMenuVariants }}</span>
            }
            {{ 'translations.variants-menu' | transloco }}
          </mat-expansion-panel-header>
          @for (
            variant of menu()?.user_details?.variants;
            track variant;
            let i = $index
          ) {
            @if (variant[menu()?.base_language]) {
              <mat-form-field class="price-input">
                <mat-label>{{ variant[menu()?.base_language] }}</mat-label>
                <input
                  matInput
                  type="text"
                  maxlength="100"
                  autocomplete="off"
                  name="tecxt"
                  #textMenuModel="ngModel"
                  [ngModel]="variant[lang()]"
                  (focusout)="
                    changeMenuVariantTranslation(
                      menu()?.user_details?.variants,
                      textMenuModel.viewModel,
                      i
                    )
                  "
                />
              </mat-form-field>
            }
            @for (item of textMenuModel()?.errors | keys; track item) {
              <div class="input-error">
                {{
                  'write.blocks.dish-overview.information.prices.variant-price.price.errors.' +
                    item.key | transloco
                }}
              </div>
            }
          }
        </mat-expansion-panel>
      }
    </mat-accordion>
  }

  @if (showContent('coverpage') && menu()?.user_details) {
    <div class="coverpage-meta">
      <mat-slide-toggle
        #coverpageToggle
        class="add-details-header"
        color="primary"
        (change)="setCoverpageState($event)"
        [ngModel]="menu()?.style?.translate_coverpage"
      >
        {{ 'write.dishes.coverpage' | transloco }}
      </mat-slide-toggle>
      @if (menu()?.rule || menu()?.onboarding_template) {
        <save-restore
          [disabled]="menuDishesLoading() || editorUpdating"
          [field]="'coverpage_' + lang()"
          [menu]="menu()"
          (restoreDefault)="restoreDefault($event, 'coverpage_' + lang())"
          (saveDefault)="
            saveDefault({
              target: $event,
              field: 'coverpage_' + lang(),
            })
          "
        ></save-restore>
      }
    </div>
    <mat-card class="editor" [class.hide-editor]="!coverpageToggle.checked">
      <app-quill-editor
        class="editor-component"
        [disabled]="menuDishesLoading()"
        [placeholder]="'write.blocks.main.editor.placeholder' | transloco"
        [contentData]="menu()?.user_details['coverpage_' + lang()]"
        [contentType]="'menudetail'"
        [objectId]="menu()?.id"
        [lang]="lang()"
        (contentChangedTap)="onQuillChangedTap()"
        (contentChanged)="onQuillEditorChanged($event)"
      >
      </app-quill-editor>
    </mat-card>
  }
</div>

<ng-template #sidePanel>
  <div class="top-right-corner">
    @if (!isMobileView) {
      <button
        mat-flat-button
        class="add-entity-button light"
        (click)="previousStep.emit()"
      >
        <mat-icon aria-hidden="true" class="arrow-icon">arrow_back</mat-icon
        >{{ 'shared.buttons.back' | transloco }}
      </button>
    }
    @if (!isMobileView) {
      <button
        mat-flat-button
        color="primary"
        class="add-entity-button"
        (click)="nextStep.emit()"
      >
        {{ 'shared.buttons.next' | transloco
        }}<mat-icon aria-hidden="true" class="arrow-icon"
          >arrow_forward</mat-icon
        >
      </button>
    }
  </div>

  @if (isMobileView) {
    <return-side-panel-mobile
      [text]="'translations.sidebar.go-back' | transloco"
      (click)="hideSidePanel()"
    ></return-side-panel-mobile>
  }
  @if (selectedItem) {
    <app-translate-sidebar
      #similarTranslation
      [class.similar-translation-mobile]="isMobileView"
      [baseLanguage]="menu()?.base_language"
      [currentMenuDish]="lastDish"
      [description]="getDescription()"
      [dishes]="similarTranslations$ | async"
      [dishTitle]="
        menu() &&
        lastDish &&
        lastDish[lastDish?.dish_detail ? dishType[0] : dishType[1]][
          menu().base_language
        ]
      "
      [isDescription]="isDescription"
      [isOther]="isOther"
      [isSecondary]="isSecondary"
      [isLoading]="isLoading$ | async"
      [lang]="lang()"
      [numberOfItems]="similarTranslationsCount$ | async"
      [spellcheckItem]="spellcheckItem()"
      [tags]="wordTags"
      [user]="user$ | async"
      (changeItem)="changeTranslationDisabled(lastDish, $event)"
      (clearSelectedDish)="selectedItem = undefined"
      (ignoreSpellcheck)="ignoreSpellcheck()"
      (fetchMoreItems)="fetchMoreItems()"
      (fetchSpellcheck)="fetchSpellcheck.emit($event)"
      (openTranslations)="openTranslations($event)"
      (similar)="selectSimilar($event)"
      (updateSimilar)="updateSimilarTranslation($event)"
    >
    </app-translate-sidebar>
  } @else {
    <div class="sidebar-intro">
      {{ 'translations.sidebar.intro' | transloco }}
    </div>
    <app-translate-actions
      [blockExpressTranslationLangs]="blockExpressTranslationLangs"
      [blockGrammarCheckLangs]="blockGrammarCheckLangs"
      [filter]="filter"
      [filteredDishes]="filteredDishes"
      [isTrial]="isTrial()"
      [lang]="lang()"
      [menuDishes]="menuDishes()"
      [menuDishesLoading]="menuDishesLoading()"
      [misssingTranslationsCount]="misssingTranslationsCount"
      [profileComplete]="profileComplete()"
      [unverifiedTranslationsCount]="unverifiedTranslationsCount"
      (filterChanged)="filterChanged($event)"
      (expressTranslate)="expressTranslate()"
      (grammarCheck)="grammarCheck()"
      (searchAndFilter)="searchAndFilter($event)"
    ></app-translate-actions>
  }
</ng-template>
