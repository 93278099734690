import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  inject,
  viewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { API_CONFIG } from 'src/app/app.config';
import { State } from 'src/app/reducers';
import { ItemNameDialogComponent } from 'src/app/shared/Components/dialogs/item-name-dialog/item-name-dialog.component';
import {
  ContentLanguage,
  InterfaceLanguage,
  langs,
} from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  TaskType,
  BackgroundImage,
  DownloadReportDetails,
  Menu,
  MenuBackup,
  MenuPreviewData,
  PresetDetail,
  Template,
  MenuAnalysisType,
} from 'src/app/shared/Models/menu';
import { Fulfillable } from 'src/app/shared/Models/models';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { User, UserRole } from 'src/app/shared/Models/user';
import {
  createArchive,
  fetchDeclarations,
  fetchTemplatesMenu,
  setInitialMenuDataLoaded,
  setTemplatesLocation,
  showPreviewDialog,
  uploadImage,
} from 'src/app/shared/ngrx/shared.actions';
import { FileService } from 'src/app/shared/Services/files/files.service';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { patchUser } from 'src/app/shared/user/ngrx/user.actions';
import {
  selectCustomDomain,
  selectMainStatus,
  selectUser,
  selectUserPrivileges,
} from 'src/app/shared/user/ngrx/user.selectors';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import {
  clearCurrentMenu,
  createMenuBackup,
  createUpdatePreset,
  deleteBackgroundImage,
  deleteMenuBackup,
  exportMenuToExcel,
  fetchBackgroundImages,
  fetchMenuAnalysis,
  fetchSpellcheckItem,
  getCurrentMenu,
  modifyOrderTaking,
  patchMenu,
  restoreMenuBackup,
} from './ngrx/menu-edit.actions';
import {
  selectCurrentMenu,
  selectCurrentSpellcheckItem,
  selectStyleBackgroundImages,
  selectWriteBackgroundImages,
} from './ngrx/menu-edit.selectors';
import {
  createTemplateFromMenu,
  deleteTemplate,
  fetchLayouts,
  fetchSeparators,
  fetchStyles,
  unlinkTemplate,
  updateTemplateDetail,
  updateTemplateStyleAndLayout,
} from './style/ngrx/menu-style.actions';
import { TemplateDialogComponent } from './style/select-template/template-dialog/template-dialog.component';
import { createMenuTranslation } from './translate/ngrx/menu-translate.actions';
import { selectTranslationsList } from './translate/ngrx/menu-translate.selectors';
import { regenerateMenu } from './write/ngrx/menu-write.actions';
import {
  selectLoading,
  selectLoadingFull,
  selectMenuDishes,
  selectMenuDishesFull,
  selectSearch,
} from './write/ngrx/menu-write.selectors';
import { AsyncPipe } from '@angular/common';
import { MtTooltipDirective } from '../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReviewComponent } from './review/review.component';
import { TranslationsComponent } from './translate/translations.component';
import { StyleComponent } from './style/style.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WriteComponent } from './write/write.component';
import { MenuStepsComponent } from './menu-steps/menu-steps.component';
import { ImportMenuDialogComponent } from './write/menu-actions/import-menu-dialog/import-menu-dialog/import-menu-dialog.component';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';

@Component({
  selector: 'menu-edit',
  templateUrl: './menu-edit.container.html',
  styleUrls: ['./menu-edit.container.scss'],
  imports: [
    MatIconModule,
    MenuStepsComponent,
    WriteComponent,
    MatProgressSpinnerModule,
    StyleComponent,
    TranslationsComponent,
    ReviewComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MtTooltipDirective,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class MenuEditContainer implements AfterViewInit, OnDestroy, OnInit {
  private activeRoute = inject(ActivatedRoute);
  private dialog = inject(MatDialog);
  private fileService = inject(FileService);
  private ngrxStore = inject<Store<State>>(Store);
  private overlayService = inject(OverlayService);
  private router = inject(Router);
  private translation = inject(TranslocoService);
  private utils = inject(UtilsService);

  currentStep = 1;
  currentLockedFields: string[] = [];
  private destroyed$ = new Subject<void>();
  id: number;
  _lang: InterfaceLanguage;
  get lang(): InterfaceLanguage {
    return this._lang;
  }
  set lang(value: InterfaceLanguage) {
    this._lang = value;
  }
  language: ContentLanguage;
  languages = langs;
  currentMenu: Menu = null;
  menuName: string;
  newLanguages: { value: ContentLanguage; created: boolean }[] = [];
  previousMenuId: number;
  progressValue = 25;
  stage = [
    { title: 'Write' },
    { title: 'Style' },
    { title: 'Translate' },
    { title: 'Review' },
  ];
  hasRuleType = false;
  hasDiet = false;

  autoRecipes$ = this.ngrxStore.select(sharedFeature.selectRecipesAutocomplete);
  backgroundStyleImages$ = this.ngrxStore.select(selectStyleBackgroundImages);
  backgroundWriteImages$ = this.ngrxStore.select(selectWriteBackgroundImages);
  customUrl$ = this.ngrxStore.select(selectCustomDomain);
  initialLoad$ = this.ngrxStore.select(
    sharedFeature.selectInitialMenuDataLoaded,
  );
  menu$ = this.ngrxStore.select(selectCurrentMenu);
  menuDishes$ = this.ngrxStore.select(selectMenuDishes);
  menuDishesFull$ = this.ngrxStore.select(selectMenuDishesFull);
  menuDishesLoading$ = this.ngrxStore.select(selectLoading);
  menuDishesLoadingFull$ = this.ngrxStore.select(selectLoadingFull);
  menuDishesSearch$ = this.ngrxStore.select(selectSearch);
  menuTranslations$ = this.ngrxStore.select(selectTranslationsList);
  orderTakingLayouts$ = this.ngrxStore.select(
    sharedFeature.selectLayoutsOrdertaking,
  );
  partner$ = this.ngrxStore.select(sharedFeature.selectPartner);
  privileges$ = this.ngrxStore.select(selectUserPrivileges);
  spellcheckItem$ = this.ngrxStore.select(selectCurrentSpellcheckItem);
  user$ = this.ngrxStore.select(selectUser);
  userStatus$ = this.ngrxStore.select(selectMainStatus);

  readonly changeMenuRef = viewChild(`menuNameHeader`, { read: TemplateRef });

  constructor() {
    this.getTranslations();
    this.utils.getLang((lang) => (this.lang = lang));
    this.activeRoute.params.subscribe(this.handleId.bind(this));
    this.activeRoute.queryParams.subscribe((route) => {
      this.language = route.lang || this.translation.getActiveLang();
    });
  }

  ngAfterViewInit() {
    this.overlayService.insertTemplate(
      `change-menu-name`,
      this.changeMenuRef(),
    );
  }

  ngOnInit(): void {
    this.menu$
      .pipe(
        filter((menu) => !!menu),
        withLatestFrom(this.user$, this.initialLoad$),
        takeUntil(this.destroyed$),
      )
      .subscribe(([menu, user, loaded]) => {
        if (
          !loaded ||
          (!!user.organisation && menu.id !== this.currentMenu?.id)
        ) {
          this.fetchAuxiliaryData(this.id);
        }
        if (!!user.organisation && menu.id !== this.currentMenu?.id) {
          this.ngrxStore.dispatch(
            fetchDeclarations({ params: { current_location: menu.location } }),
          );
        }
        this.currentMenu = menu;
        this.hasRuleType = !!(menu?.type_detail || menu?.rule_detail);
        this.hasDiet = !!menu?.diets_list?.length;
        this.menuName = menu.name;
        this.newLanguages = this.handleTranslation(menu.translations_list);
        if (menu.id !== this.previousMenuId) {
          this.currentLockedFields = menu.style.locked_fields;
          if (
            this.hasModules('loca') &&
            [UserRole.OWNER, UserRole.ADMIN].includes(user.role)
          ) {
            this.ngrxStore.dispatch(
              fetchTemplatesMenu({ current_menu: menu.id }),
            );
          }
        }
        this.previousMenuId = menu.id;
      });
  }

  /**
   * Fetch menu-related data
   * in a single-user environment (no organisation) this only needs to be done once,
   * in a multi-user environment it is done each time a menu loads
   * (since menus may have different locations and thus different related data)
   * @param id the id of the menu to fetch data for
   */
  fetchAuxiliaryData(id: number): void {
    this.ngrxStore.dispatch(
      fetchBackgroundImages({ params: { current_menu: id } }),
    );
    this.ngrxStore.dispatch(
      fetchSeparators({ category: 'cou', current_menu: id }),
    );
    this.ngrxStore.dispatch(
      fetchSeparators({ category: 'opt', current_menu: id }),
    );
    const params = { condensed: true, current_menu: id };
    this.ngrxStore.dispatch(fetchStyles({ params }));
    this.ngrxStore.dispatch(fetchLayouts({ params }));
    this.ngrxStore.dispatch(setInitialMenuDataLoaded({ value: true }));
  }

  deleteMenuBackup(backup: MenuBackup): void {
    this.ngrxStore.dispatch(deleteMenuBackup({ backup }));
  }

  getTranslations(): void {
    const keys = this.stage.map((el, key) => `menus.steps.inline.${key}`);
    this.utils.getTranslation(keys, (res) => {
      keys.forEach((key: string, index: number) => {
        const splitKey = key.split('.');
        this.stage[parseInt(splitKey[3])].title = res[index];
      });
    });
  }

  updateTemplateDetail(data: Partial<OnboardingTemplate>) {
    const url = this.currentMenu.onboarding_template_detail.url;
    this.ngrxStore.dispatch(
      updateTemplateDetail({
        url,
        data,
        params: { current_menu: this.currentMenu.id },
      }),
    );
  }

  editTemplateDialog(data: { templateDetail: OnboardingTemplate }) {
    const url = this.currentMenu.onboarding_template_detail.url;
    const params = { current_menu: this.currentMenu.id };
    const subject = new Subject<OnboardingTemplate>();
    this.dialog.open(ItemNameDialogComponent, {
      data: {
        adding: false,
        currentItem: subject,
        image: 'image',
        hideProgress: true,
        item: data.templateDetail,
        lang: this.lang,
        onlyInterfaceLangs: true,
        title: 'style.choose-template.edit-template',
        clearImage: () => {
          this.ngrxStore.dispatch(
            updateTemplateDetail({ url, data: { image: null }, params }),
          );
        },
        close: (value: Partial<OnboardingTemplate>) => {
          this.ngrxStore.dispatch(
            updateTemplateDetail({ url, data: value, params }),
          );
        },
        uploadImage: (data: Fulfillable<File>) => {
          const templateData = new FormData();
          templateData.append('image', data.payload);
          this.ngrxStore.dispatch(
            uploadImage({
              callback: (res: OnboardingTemplate) => {
                subject.next(res);
              },
              image: templateData,
              url: this.currentMenu.onboarding_template_detail.url,
              params: { current_menu: this.currentMenu.id },
            }),
          );
        },
      },
      width: '600px',
      autoFocus: false,
    });
  }

  fetchSpellcheck(id: number): void {
    this.ngrxStore.dispatch(fetchSpellcheckItem({ spellcheck_id: id }));
  }

  hasModules = (code: string): boolean => this.utils.hasModules(code);

  linkMenuToTemplate(templateId: number) {
    this.ngrxStore.dispatch(
      patchMenu({
        payload: { onboarding_template: templateId },
        setAsCurrent: true,
      }),
    );
  }

  deleteBackground(image: BackgroundImage): void {
    this.ngrxStore.dispatch(
      deleteBackgroundImage({ image, menu_id: this.currentMenu.id }),
    );
  }

  deleteTemplate({ url, id }: { url: string; id: number }) {
    this.ngrxStore.dispatch(
      deleteTemplate({
        url,
        id,
        params: { current_menu: this.currentMenu.id },
      }),
    );
  }

  createNewTemplateDialog() {
    const createTemplate = (data: FormData | Partial<Template>) => {
      this.ngrxStore.dispatch(
        createTemplateFromMenu({
          template: data,
          menu_id: this.currentMenu.id,
        }),
      );
    };
    this.dialog.open(TemplateDialogComponent, {
      data: {
        adding: true,
        image: 'image',
        hideProgress: true,
        layoutName: this.currentMenu.template_detail[this.lang],
        item: null,
        lang: this.lang,
        title: 'style.choose-template.add-template',
        clearImage: () => {},
        done: (value: Partial<Template>) => {
          const data = _.omitBy(value, _.isEmpty);
          if (value.create_preset) {
            data.create_preset = value.create_preset;
          }
          createTemplate(data);
        },
        createTemplateWithImage: (data: FormData) => {
          createTemplate(data);
        },
      },
      width: '600px',
      autoFocus: false,
    });
  }

  unlink() {
    const unlinkDetail = { onboarding_template: null };
    this.ngrxStore.dispatch(
      unlinkTemplate({ template: unlinkDetail, id: this.currentMenu.id }),
    );
  }

  updateStyleAndLayout() {
    this.ngrxStore.dispatch(
      updateTemplateStyleAndLayout({ id: this.currentMenu.id }),
    );
  }

  addTranslation(lang: ContentLanguage) {
    this.ngrxStore.dispatch(createMenuTranslation({ lang }));
  }

  archiveMenu({ menu, task_type }: { menu: Menu; task_type: TaskType }) {
    this.ngrxStore.dispatch(createArchive({ menu, task_type }));
  }

  blurField(field: HTMLInputElement) {
    field.blur();
  }

  changeMenuName(newName: string): void {
    if (newName === this.menuName) return undefined;
    this.ngrxStore.dispatch(
      patchMenu({ payload: { name: newName }, setAsCurrent: true }),
    );
  }

  presetAction({ preset, id, existing }: PresetDetail): void {
    this.ngrxStore.dispatch(createUpdatePreset({ preset, id, existing }));
  }

  changeStep = (num: number) => {
    this.currentStep = num;
    this.progressValue = (this.currentStep * 100) / this.stage.length;
  };

  createMenuBackup(): void {
    this.ngrxStore.dispatch(createMenuBackup());
  }

  enableOrdertaking = (checked: boolean) => {
    this.ngrxStore.dispatch(
      modifyOrderTaking({ menu: this.currentMenu, state: checked }),
    );
  };

  handleId = (params: any) => {
    this.id = parseInt(params.id);
    this.ngrxStore.dispatch(getCurrentMenu({ id: this.id }));
    const step = params.step && parseInt(params.step, 10);
    this.changeStep(step);
  };

  handleTranslation = (
    translations: readonly string[],
  ): { value: ContentLanguage; created: boolean }[] => {
    return this.languages
      .filter((item) => item !== this.currentMenu.base_language)
      .map((item) => ({ value: item, created: translations.includes(item) }));
  };

  navigateToTranslate(lang: ContentLanguage) {
    this.router.navigate(
      [
        '/menus',
        this.currentMenu.id,
        this.currentMenu.base_language === lang ? 1 : 3,
      ],
      { queryParams: { lang } },
    );
  }

  prevStep(currentStep: number) {
    if (currentStep === 1) return undefined;
    let prevStep = currentStep - 1;
    if (currentStep === 4) prevStep = 2;

    if (currentStep > 0) {
      this.router.navigate(['/menus', this.id, prevStep]);
    }
  }

  nextStep(currentStep: number) {
    if (currentStep > this.stage.length) return undefined;
    const nextStep = currentStep === 2 ? 4 : currentStep + 1;
    if (currentStep < 4) {
      this.router.navigate(['/menus', this.id, nextStep]);
    } else {
      this.router.navigate(['/menus']);
    }
  }

  patchMenu(data: DeepPartial<Menu>, showSnack = false) {
    if (data?.style?.locked_fields) {
      this.currentLockedFields = data.style.locked_fields;
    }
    this.ngrxStore.dispatch(
      patchMenu({ payload: data, setAsCurrent: true, showSnack }),
    );
  }

  previousStep(currentStep: number) {
    if (currentStep < 0) return undefined;
    const previousStep = currentStep === 4 ? 2 : currentStep - 1;
    if (currentStep > 0) {
      this.router.navigate(['/menus', this.id, previousStep]);
    } else {
      this.router.navigate(['/menus']);
    }
  }

  regenerateMenu() {
    this.ngrxStore.dispatch(regenerateMenu());
  }

  restoreMenuBackup(backup: MenuBackup): void {
    this.ngrxStore.dispatch(restoreMenuBackup({ id: backup.id }));
  }

  showPreview = (data: MenuPreviewData): void => {
    this.ngrxStore.dispatch(showPreviewDialog(data));
  };

  showTooltip = (tooltip: MatTooltip) => setTimeout(() => tooltip.show(), 200);

  updateUser = (userDetail: DeepPartial<User>) =>
    this.ngrxStore.dispatch(
      patchUser({ user: userDetail, cid: UtilsService.GEN_CID }),
    );

  downloadReport(reportDetail: { data: DownloadReportDetails }) {
    this.fileService.downloadFile(
      `${API_CONFIG.value}/dishes/reports/`,
      {},
      reportDetail.data,
    );
  }

  downloadStats(data: { date_from: string; date_to: string }) {
    this.fileService.downloadFile(
      `${this.currentMenu.url}statistics/`,
      {},
      data,
    );
  }

  showMenuAnalysis(analysisType: MenuAnalysisType): void {
    this.ngrxStore.dispatch(
      fetchMenuAnalysis({
        url: this.currentMenu.url,
        lang: this.currentMenu.base_language,
        analysis_type: analysisType,
      }),
    );
  }

  dataExport(menu: Menu): void {
    this.ngrxStore.dispatch(exportMenuToExcel({ menu }));
  }

  dataImport(menu: Menu): void {
    this.dialog.open(ImportMenuDialogComponent, {
      data: { menu },
      width: '600px',
      autoFocus: false,
    });
  }

  ngOnDestroy() {
    this.overlayService.clear(`change-menu-name`);
    this.destroyed$.next();
    this.destroyed$.complete();
    this.ngrxStore.dispatch(clearCurrentMenu());
    this.ngrxStore.dispatch(setTemplatesLocation({ data: null }));
  }
}
