import { createAction, props } from '@ngrx/store';
import { Module, UserModule } from 'src/app/shared/Models/user';

export const initModuleRequests = createAction(
  `[Settings container / Modules tab] Fetch module requests`,
);

export const fetchModules = createAction(
  `[Settings container / Modules tab] Fetch modules`,
);

export const setModules = createAction(
  `[Global] Set modules`,
  props<{ modules: Module[] }>(),
);

export const selectModuleID = createAction(
  `[Settings API / Choose plan dialog] Add selected module's ID`,
  props<{ id: number }>(),
);

export const deselectModuleID = createAction(
  `[Settings API / Choose plan dialog] Remove deselected module's ID`,
  props<{ id: number }>(),
);

export const setIncludedModulesIDs = createAction(
  `[Settings API / Choose plan dialog] Set IDs of modules that are included with selected plan`,
  props<{ ids: number[] }>(),
);

export const setSelectedModulesIDs = createAction(
  `[Settings API / Choose plan dialog] Set IDs of selected modules (inside the choose plan dialog)`,
  props<{ ids: number[] }>(),
);

export const updateSelectedModulesIDS = createAction(
  `[Global] Update selected modules' IDs`,
  props<{ ids: number[] }>(),
);

export const userModuleToggleChanged = createAction(
  `[Settings container / Modules tab] User module's toggle was changed`,
  props<{ module: UserModule }>(),
);

export const disableUserModule = createAction(
  '[Settings container / Modules API] Disable user module',
  props<{ module: UserModule }>(),
);

export const enableUserModule = createAction(
  '[Settings container / Modules API] Enable user module',
  props<{ module: UserModule }>(),
);

export const userModuleSettingsChanged = createAction(
  `[Settings container / Modules tab] User module's settings were changed, trying to update user module`,
  props<{
    url: string;
    data: {
      settings?: Record<string, boolean>;
      options?: Record<string, any>;
    };
  }>(),
);

export const moduleTrialRequested = createAction(
  `[Settings container / Modules tab] Module's trial was requested`,
  props<{ module: UserModule }>(),
);

export const moduleTrialEnabled = createAction(
  '[Settins container / Modules API] Modules trial was enabled',
  props<{ module: UserModule }>(),
);

export const moduleRequested = createAction(
  '[Settings container / Modules tab] Module activated is requested',
  props<{ module: UserModule }>(),
);

export const moduleRequestsLoadedOrChanged = createAction(
  '[Settings container / Modules API] Module requests were loaded or changed',
  props<{ requests: number[] }>(),
);

export const moduleRequestSent = createAction(
  '[Settings container / Modules API] Module activation request was sent',
  props<{ module: UserModule }>(),
);
