import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { HELPDESK, LANDIN_CONFIG } from 'src/app/app.config';
import { HelpScoutBeaconsService } from 'src/app/help-scout-beacons.service';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Partner } from 'src/app/shared/Models/partners';
import { User } from 'src/app/shared/Models/user';
import { patchUser } from 'src/app/shared/user/ngrx/user.actions';
import { asyncScheduler, Subject } from 'rxjs';
import { filter, observeOn, takeUntil, tap } from 'rxjs/operators';

import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { UtilsService } from '../../Services/utils/utils.service';
import { SendMessageModalComponent } from './send-message-modal/send-message-modal.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'menutech-navbar',
  templateUrl: './menutech-navbar.component.html',
  styleUrls: ['./menutech-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatToolbarModule,
    RouterLink,
    MatButtonModule,
    MatTooltipModule,
    MtTooltipDirective,
    MatMenuModule,
    RouterLinkActive,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class MenutechNavbarComponent implements OnDestroy, AfterViewInit {
  private dialog = inject(MatDialog);
  private helpScoutBeacon = inject(HelpScoutBeaconsService);
  private ngrxStore = inject<Store<State>>(Store);
  private router = inject(Router);
  private utils = inject(UtilsService);

  private destroyed$ = new Subject<void>();

  readonly lang = input<InterfaceLanguage>(undefined);
  readonly partner = input<Partner>(undefined);
  readonly user = input<User>(undefined);

  readonly logout = output();

  readonly helpTooltip = viewChild('helpTooltip', { read: MtTooltipDirective });

  helpUrls = HELPDESK;
  url = LANDIN_CONFIG.value;

  readonly helpTooltipKey = 'shared.top-navbar.help';
  readonly trialHelpTooltipKey = 'shared.top-navbar.help-trial';
  currentTooltipKey = this.helpTooltipKey;
  canCloseHelpTooltip = false;

  constructor() {
    this.utils.sendLayoutRequest
      .pipe(takeUntil(this.destroyed$))
      .subscribe((subject: number) => this.sendMessage(subject));
  }

  ngAfterViewInit(): void {
    this.utils.showHelpTooltip
      .pipe(
        filter((v) => !!v),
        tap(() => {
          this.canCloseHelpTooltip = true;
          this.currentTooltipKey = this.trialHelpTooltipKey;
        }),
        observeOn(asyncScheduler),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.helpTooltip().show();
      });
  }

  helpTooltipClosed(): void {
    this.canCloseHelpTooltip = false;
    this.currentTooltipKey = this.helpTooltipKey;
    this.ngrxStore.dispatch(
      patchUser({
        user: { tutorials: { dashboard_firstvisit: true } },
        cid: UtilsService.GEN_CID,
      }),
    );
  }

  goToBilling(): void {
    this.router.navigate(['settings', 'billing'], {
      queryParams: { change: true },
    });
  }

  showMessageOverlay(): void {
    this.helpScoutBeacon.open();
    this.helpScoutBeacon.navigateSupport();
  }

  sendMessage(subject?: number): void {
    this.dialog.open(SendMessageModalComponent, {
      data: {
        subject,
        user: this.user(),
      },
      width: '750px',
      height: this.user().email.includes('@partners.menutech.com')
        ? '650px'
        : '610px',
      panelClass: 'send-message-panel',
      autoFocus: false,
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
