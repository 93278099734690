import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ComponentRef,
  Injectable,
  NgZone,
  OnDestroy,
  inject,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { StripeCardElement } from '@stripe/stripe-js';
import { selectCurrentMenu } from 'src/app/menus/menu-edit/ngrx/menu-edit.selectors';
import { State } from 'src/app/reducers';
import { TrialBlockBoxComponent } from 'src/app/shared/Components/trial-block-box/trial-block-box.component';
import { InterfaceLanguage, langs } from 'src/app/shared/constants/languages';
import {
  unitsImperial,
  unitsMetric,
  unitsUs,
} from 'src/app/shared/constants/units';
import { Allergen } from 'src/app/shared/Models/declarations';
import { LangButton } from 'src/app/shared/Models/langButton';
import { Menu, OverviewMenu, SimpleMenu } from 'src/app/shared/Models/menu';
import { User, UserModule } from 'src/app/shared/Models/user';
import { showSnackbarMessage } from 'src/app/shared/ngrx/shared.actions';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { getISOLang } from 'src/app/shared/utils.functions';
import { isNil, omitBy } from 'lodash-es';
import * as _ from 'lodash-es';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { sharedFeature } from '../../ngrx/shared.state';

@Injectable({
  providedIn: 'root',
})
export class UtilsService implements OnDestroy {
  private http = inject(HttpClient);
  private ngrxStore = inject<Store<State>>(Store);
  private ngZone = inject(NgZone);
  private overlayService = inject(OverlayService);
  private router = inject(Router);
  private translate = inject(TranslocoService);

  private static _cid = 0;

  user$ = this.ngrxStore.select(selectUser);
  allergens$ = this.ngrxStore.select(sharedFeature.selectAllergens);

  public showHelpTooltip = new BehaviorSubject(false);
  public navigationCollapsed = new BehaviorSubject(false);
  public sendLayoutRequest = new Subject<number>();
  public confirmBusinessDetailsEvent = new Subject<void>();
  public refreshPdfView = new Subject<void>();
  public feedbackSent = new Subject<void>();
  public reloadMenus = new Subject<void>();
  // used to hold the card from the checkout dialog, and from the change card dialog
  public card: StripeCardElement;

  private currentAllergens: Allergen[];
  private currentUser: User;
  private destroyed$ = new Subject<void>();
  private modules = null;
  private lastPath: string;
  private lastExists: any;
  private matSidenavContent: HTMLElement;
  private langNameExtended = {
    it: 'ita',
    fr: 'fra',
    de: 'deu',
    es: 'spa',
  };
  public blurInput = new BehaviorSubject(false);

  constructor() {
    this.allergens$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((v) => (this.currentAllergens = v));
    this.user$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((v) => (this.currentUser = v));
  }

  static addLocationParam(current_location: number, params = {}): any {
    return omitBy({ ...params, current_location }, isNil);
  }

  static createLanguageHeader = (lang: InterfaceLanguage) => {
    return new HttpHeaders().set('Accept-Language', getISOLang(lang));
  };

  static fileToImage(file: File): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const objUrl = URL.createObjectURL(file);
      img.src = objUrl;
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', (error) => reject(error));
    });
  }

  static parseCurrentPageIndex(previous: string | undefined): number {
    let index = 0;
    if (previous) {
      index = +previous.match(/page=(?<index>\d+)/)?.groups?.index;
      return Number.isNaN(index) ? 1 : index;
    }
    return index;
  }

  getCurrentLocation() {
    let location;
    this.ngrxStore
      .select(sharedFeature.selectCurrentLocation)
      .subscribe((currentLocation) => {
        location = currentLocation;
      });
    return location;
  }

  getCurrentMenu() {
    let currentMenu;
    this.ngrxStore.select(selectCurrentMenu).subscribe((menu) => {
      currentMenu = menu;
    });
    return currentMenu;
  }

  static get GEN_CID(): number {
    return ++this._cid;
  }

  showTrialBlockedBox(): void {
    const bottomBlockRef =
      this.overlayService.insertTemplate<TrialBlockBoxComponent>(
        `bottom-block`,
        TrialBlockBoxComponent,
        {
          currencySymbol: `euro`,
          close: () => {
            this.overlayService.clear(`bottom-block`);
          },
        },
      ) as ComponentRef<TrialBlockBoxComponent>;
    bottomBlockRef.instance.closePanel.subscribe(() =>
      this.overlayService.clear(`bottom-block`),
    );
    bottomBlockRef.instance.actionClicked.subscribe(() =>
      this.router.navigate(['settings', 'billing'], {
        queryParams: { change: true },
      }),
    );
    bottomBlockRef.instance.completeProfile.subscribe(() =>
      this.router.navigate(['settings', 'profile']),
    );
  }

  scrollContentTop(): void {
    if (!this.matSidenavContent) {
      this.matSidenavContent = document.querySelector(`mat-sidenav-content`);
    }
    this.matSidenavContent?.scrollTo(0, 0);
  }

  async checkIfExists(path: string): Promise<any> {
    if (path === this.lastPath) return this.lastExists;
    const file = await this.http.get(path).subscribe({
      error: () => console.error,
    });
    this.lastPath = path;
    this.lastExists = file;
    return file;
  }

  runOutsideWithTimer(timer: number, fn: () => void): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(fn, timer);
    });
  }

  fixVat(item: any): any {
    if (item.user_details) {
      item.user_details.cost_vat = item.user_details.cost_vat || '0.0';
    }
    return item;
  }

  fetchQRCode(imageURL: string): Observable<Blob> {
    return this.http.get(imageURL, {
      responseType: 'blob',
      withCredentials: true,
    });
  }

  uploadImage = <R>(url: string, data: FormData, params?: any) =>
    this.http.patch<R>(url, data, { params });

  tryGetLabel(obj: any, prefLang: string): { name: string; italic: boolean } {
    return obj[prefLang]
      ? { name: obj[prefLang], italic: false }
      : { name: obj[langs.find((lang) => obj[lang])], italic: true };
  }

  getModuleOptions(code: string): any {
    return this.modules?.find((m) => m.module_detail.code === code)?.options;
  }

  hasModules(codes: string | string[], all = false): boolean {
    const modules = this.userModules;
    if (!modules || !codes) return false;
    if (Array.isArray(codes)) {
      return codes.every((code) => this.hasModule(modules, code, all));
    } else {
      return this.hasModule(modules, codes, all);
    }
  }

  hasModuleSetting(
    code: string,
    setting: string,
    value: any,
    all = false,
  ): boolean {
    const modules = this.userModules;
    if (!modules || !code) return false;
    return modules.some(
      (m) =>
        m?.module_detail?.code === code &&
        (all || m?.active) &&
        m?.settings &&
        m?.settings[setting] &&
        m?.settings[setting] === value,
    );
  }

  private hasModule(modules: UserModule[], code: string, all = false): boolean {
    return modules.some(
      (m) => m?.module_detail?.code === code && (all || m?.active),
    );
  }

  removeModules() {
    localStorage.removeItem('modules');
    this.modules = null;
  }

  get userModules() {
    if (!this.modules)
      this.modules = JSON.parse(localStorage.getItem('modules'));
    return this.modules;
  }

  set userModules(modules) {
    localStorage.setItem('modules', JSON.stringify(modules));
    this.modules = modules;
  }

  getUnits(removeTsp = false): string[] {
    if (this.currentUser && this.currentUser.accountsettings) {
      const { units_imperial, units_us } = this.currentUser.accountsettings;
      return unitsMetric
        .concat(units_imperial ? unitsImperial.filter(this.filterTsp) : [])
        .concat(units_us ? unitsUs : [])
        .filter(removeTsp ? this.filterTsp : (v) => v);
    }
    return [];
  }

  filterTsp(unit: string): boolean {
    return !(unit.includes('tsp') || unit.includes('tbsp'));
  }

  getAllergenSymbol(code: string) {
    if (!this.currentAllergens) return undefined;
    const all = this.currentAllergens.find(
      (allergen) => allergen.code === code,
    );
    return all ? all.app_symbol : null;
  }

  createBtnMessage(btn: MatButton, tooltip: MatTooltip) {
    if (btn && btn.disabled && tooltip) {
      this.ngrxStore.dispatch(
        showSnackbarMessage({ message: tooltip.message }),
      );
    }
  }

  showSnackbarMessage(message: string, translate = false): void {
    if (translate) {
      this.translate
        .selectTranslate(message)
        .pipe(take(1))
        .subscribe((m) =>
          this.ngrxStore.dispatch(showSnackbarMessage({ message: m })),
        );
      return;
    }
    this.ngrxStore.dispatch(showSnackbarMessage({ message }));
  }

  validatePrice(model): number {
    if (model !== 0 && !model) return null;
    if (model.toString().includes(',')) {
      if (model.toString().includes('.')) return -1;
      model = parseFloat(model.toString().replace(',', '.'));
    }
    model = parseFloat(parseFloat(model).toFixed(2));
    if (model < 0) model = 0;
    else if (model > 99999 || model.toString().length > 9) model = 99999;
    return model;
  }

  getParams(
    langBtns: LangButton[],
    baseLang: string,
    data: any,
    ignoreTemplate = false,
    miscParams?: any,
  ): any {
    let newBaseLang = '';
    const newLangs = [...langBtns]
      .filter((v) => v.activated)
      .sort((a, b) => a.order - b.order)
      .map((lang: LangButton) => {
        if (lang.order === 0) {
          newBaseLang = lang.lang;
          return undefined;
        }
        return lang.lang;
      })
      .filter((v) => v);
    const params = {
      lang: newLangs,
    };
    if (baseLang && newBaseLang) {
      params['base_lang'] = newBaseLang;
    }
    if (!ignoreTemplate && data) params['template'] = data.id;
    return { ...params, ...miscParams };
  }

  constructUrlParamsLink(params): string {
    let paramsLink = '?';
    paramsLink += Object.keys(params)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return params[key].map((l) => `${key}=${l}`).join('&');
        }
        return `${key}=${params[key]}`;
      })
      .filter((v) => v)
      .join('&');
    return paramsLink.length > 1 ? paramsLink : '';
  }

  getSharedLink(
    langBtns: LangButton[],
    baseLang: string,
    data: any,
    shareLink: string,
    ignoreTemplate = false,
    miscParams?: any,
  ): string {
    const params = this.getParams(
      langBtns,
      baseLang,
      data,
      ignoreTemplate,
      miscParams,
    );
    const paramsLink = this.constructUrlParamsLink(params);
    return shareLink + paramsLink;
  }

  createQuery(query: object): HttpParams {
    if (!query) return new HttpParams();
    return Object.keys(query).reduce((res, val) => {
      if (query[val] === undefined) return res;
      if (Array.isArray(query[val])) {
        return query[val].reduce((result, el) => result.append(val, el), res);
      }
      return res.append(val, query[val]);
    }, new HttpParams());
  }

  // Test function, will be used in the next version of app
  // Allow to get translation every time it changes
  getTranslation(
    key: string | string[],
    handler: (arg) => any,
    vars = {},
    isObject = false,
  ) {
    this.translate.langChanges$
      .pipe(
        switchMap(() =>
          this.translate.selectTranslate(key, vars, undefined, isObject),
        ),
      )
      .subscribe((v) => handler(v));
    const observable = new ReplaySubject(1);
    this.translate
      .selectTranslate(key, vars, undefined, isObject)
      .pipe(
        map((translated) => {
          handler(translated);
          observable.next(translated);
        }),
      )
      .subscribe();
    return observable.asObservable().pipe(take(1));
  }

  getTranslationObject(key, handler: (arg) => any, vars = {}) {
    return this.getTranslation(key, handler, vars, true);
  }

  getStringQueryParams(params) {
    return Object.keys(params)
      .map((el) => `${el}=${decodeURIComponent(params[el])}`)
      .join('&');
  }

  appendQueryParamsToURL = (url: URL, params: object) =>
    Object.keys(params).forEach((el) =>
      Array.isArray(params[el])
        ? params[el].forEach((item) => url.searchParams.append(el, item))
        : url.searchParams.append(el, params[el]),
    );

  addQueryParamsToURL = (url: URL | string, params: object) => {
    const stringUrl = `${(url as URL).href || url}?
        ${Object.keys(params)
          .map((el) =>
            Array.isArray(params[el])
              ? params[el].map((item) => `${el}=${item}&`).join('')
              : `${el}=${params[el]}&`,
          )
          .join('')}`;
    return new URL(stringUrl.replace(/\n/, '').replace(/\s/g, ''));
  };

  setQueryParams = (root, params): URL => {
    if (!root) return null;
    if (!params) return root;
    if (root instanceof URL && root.searchParams) {
      this.appendQueryParamsToURL(root, params);
      return root;
    }
    return this.addQueryParamsToURL(root, params);
  };

  setLangQueryParams(root, params) {
    if (!params || !root) return root || null;
    Object.keys(params).forEach((el) => root.append('lang', el));
    return root;
  }

  getLang(handler) {
    this.translate.langChanges$.subscribe((lang) => {
      handler(lang);
    });
    handler(this.translate.getActiveLang());
  }

  getExtendedLangName(shortName: string): string {
    return this.langNameExtended[shortName];
  }

  getNode(data: any[]) {
    let index = 0;
    function makeNode(dataForNode) {
      const result = [];
      while (index < dataForNode.length) {
        index++;
        let node = {
          ...dataForNode[index - 1],
        };
        if (!dataForNode[index]) {
          return [...result, node];
        }
        if (dataForNode[index - 1].level > dataForNode[index].level) {
          return [...result, node];
        }
        if (dataForNode[index - 1].level < dataForNode[index].level) {
          node = {
            ...node,
            children: makeNode(dataForNode),
          };
        }
        result.push(node);
      }
      return result;
    }
    return makeNode(data);
  }

  getArray(data, rootLevel = 0) {
    return data.reduce((res, val, key) => {
      if (val.children && Array.isArray(val.children)) {
        const { children, ...valData } = val;
        return [...res, valData, ...this.getArray(val.children, rootLevel + 1)];
      }
      return [
        ...res,
        {
          ...val,
          level: rootLevel,
        },
      ];
    }, []);
  }

  getNativeWindow() {
    return window;
  }

  isChrome = () => navigator.userAgent.indexOf('Chrome') !== -1;

  isMozilla = () => navigator.userAgent.indexOf('Firefox') !== -1;

  checkSafari = () => navigator.userAgent.indexOf('Safari') !== -1;

  isSafari = () => !this.isChrome() && this.checkSafari() && !this.isMozilla();

  isIOS = () => navigator.userAgent.match(/(iPad|iPhone|iPod)/g);

  calcWidthOfString(value: string, fontSize?: string): number {
    const span = document.createElement(`span`);
    span.innerText = value;
    span.hidden = true;
    span.style.position = 'absolute';
    span.style.display = 'inline-block';
    if (fontSize) {
      span.style.fontSize = fontSize;
    }
    document.body.appendChild(span);
    const width = span.getBoundingClientRect().width;
    document.body.removeChild(span);
    return width;
  }

  // Generic requests functions
  patch = <T>(url: string, data: any, params?: any) =>
    this.http.patch<T>(url, data, { params });

  delete = (url: string, params?: any) => this.http.delete(url, { params });

  get = <T, P = any>(url: string, params?: P, responseType = 'json') =>
    this.http.get<T>(url, {
      params: params as any,
      responseType: responseType as any,
    });

  isArrayEqual = (x: any[], y: any[]) =>
    x.length === y.length && _.xorWith(x, y, _.isEqual).length === 0;

  menuToPreviewData(menu: SimpleMenu | OverviewMenu | Menu, params: any = {}) {
    return {
      url: menu.preview,
      baseLanguage: menu.base_language,
      langs: menu.translations_list.map((lang) => {
        return {
          lang,
          activated: lang === menu.base_language,
          order: lang === menu.base_language ? 0 : null,
        };
      }),
      numberLanguages: menu.template_detail.number_languages,
      multiRequired: menu.template_detail?.multilingual_required,
      params,
    };
  }

  blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  };

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
