import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { EmailPreferences } from 'src/app/shared/Models/user';
import { updateEmailPreferences } from './../../ngrx/auth.actions';
import { selectEmailPreferencesErrorState } from './../../ngrx/auth.selectors';
import { AsyncPipe } from '@angular/common';
import { EmailPreferencesComponent } from '../../Components/email-preferences/email-preferences.component';

@Component({
  selector: 'email-preferences-container',
  templateUrl: './email-preferences.container.html',
  styleUrls: ['./email-preferences.container.css'],
  imports: [EmailPreferencesComponent, AsyncPipe],
})
export class EmailPreferencesContainer {
  private ngrxStore = inject<Store<State>>(Store);
  private translateService = inject(TranslocoService);

  email: string;
  lang: InterfaceLanguage;
  token: string;

  error$ = this.ngrxStore.select(selectEmailPreferencesErrorState);

  constructor() {
    const route = inject(ActivatedRoute);

    route.paramMap.subscribe((map) => {
      this.email = map.get('email');
      this.token = map.get('token');
    });
    this.lang = this.translateService.getActiveLang() as InterfaceLanguage;
    this.translateService.langChanges$.subscribe(
      (lang) => (this.lang = lang as InterfaceLanguage),
    );
  }

  updateSettings(data: EmailPreferences) {
    this.ngrxStore.dispatch(
      updateEmailPreferences({
        data,
        email: this.email,
        token: this.token,
      }),
    );
  }
}
