<div class="wrapper">
  <div class="report-name">
    @if (image() && !highlight()) {
      <div class="image-container" [class.image-large]="imageLarge()">
        <img
          class="image"
          [src]="image()"
          [alt]="'menus.linked_templates.title' | transloco"
        />
      </div>
    }
    @if (highlight()) {
      <div class="all-image">
        <span class="allergen-symbol blue symbol">{{ getSymbols() }}</span>
      </div>
    }
    @if (analysisAllergen()) {
      <div class="all-image nocross">
        <span class="allergen-symbol blue symbol">AE</span>
      </div>
    }
    <span class="title">{{ title() }}</span>
  </div>

  @if (showLangs()) {
    <div class="langs-wrapper">
      <language-buttons
        [langs]="langs"
        [langsAllowed]="numberLanguages()"
        (langsChange)="updateLink($event)"
      >
      </language-buttons>
      @if (showAddMore()) {
        <div class="add-more">
          {{ 'menus.linked_templates.add_more' | transloco }}
        </div>
      }
    </div>
  }

  @if (showDate()) {
    <div class="highlights dates">
      <mat-form-field class="date" subscriptSizing="dynamic">
        <mat-label>{{
          'menus.linked_templates.start_date' | transloco
        }}</mat-label>
        <input
          matInput
          #inputStart="matDatepickerInput"
          (click)="pickerStart.open()"
          [matDatepicker]="pickerStart"
          [max]="maxDate"
          [min]="minDate"
        />
        @if (inputStart.value) {
          <button
            stop-propagation
            matSuffix
            mat-icon-button
            (click)="inputStart.value = null; endDate().min = null"
          >
            <mat-icon>clear</mat-icon>
          </button>
        }
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerStart"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="date" subscriptSizing="dynamic">
        <mat-label>{{
          'menus.linked_templates.end_date' | transloco
        }}</mat-label>
        <input
          matInput
          #inputEnd="matDatepickerInput"
          (click)="pickerEnd.open()"
          [matDatepicker]="pickerEnd"
          [max]="maxDate"
          [min]="minDate"
        />
        @if (inputEnd.value) {
          <button
            stop-propagation
            matSuffix
            mat-icon-button
            (click)="inputEnd.value = null; startDate().max = null"
          >
            <mat-icon>clear</mat-icon>
          </button>
        }
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerEnd"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>
    </div>
  }

  @if (highlight() && filteredAllergens) {
    <mat-form-field class="highlights" subscriptSizing="dynamic">
      <mat-label>{{
        'menus.linked_templates.highlights.placeholder' | transloco
      }}</mat-label>
      <mat-select [formControl]="highlights" multiple>
        <mat-select-trigger> {{ getHighlighted() }} </mat-select-trigger>
        @for (allergen of filteredAllergens; track allergen) {
          <mat-option class="allergen" [value]="allergen?.code">
            @if (
              !['nonveg', 'nonvega', 'nonlacf', 'nonglutf'].includes(
                allergen?.code
              )
            ) {
              <span
                transloco="menus.linked_templates.highlights.without"
                [translocoParams]="{ name: allergen && allergen[lang()] }"
              ></span>
            } @else {
              <span>{{ allergen[lang()] }}</span>
            }
            @if (isAllergenSelected(allergen?.code)) {
              <mat-icon class="allergen-checked">checked</mat-icon>
            }
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  <div
    class="buttons"
    [matTooltip]="
      getButtonState()
        ? ('menus.linked_templates.tooltip'
            | transloco: { number: restLangs }) +
          (restLangs > 1
            ? ('menus.complete.multilingual.plural' | transloco)
            : ('menus.complete.multilingual.singular' | transloco))
        : ''
    "
    matTooltipClass="top-navbar-tooltip"
    [matTooltipPosition]="'above'"
  >
    @if (showPreview()) {
      <button
        mat-button
        class="button"
        (click)="createPreview()"
        [disabled]="getButtonState()"
      >
        {{
          (isProfitabilityReport()
            ? 'menus.steps.complete.actions.download'
            : 'menus.steps.complete.actions.preview'
          ) | transloco
        }}
      </button>
    }
    @if (showPrint()) {
      <button
        mat-button
        class="button has-spinner"
        [disabled]="getButtonState() || printLoading"
        (click)="printMenu()"
      >
        @if (printLoading) {
          <mat-spinner class="spinner" diameter="20"></mat-spinner>
        }
        {{ 'menus.steps.complete.actions.print' | transloco }}
      </button>
    }
    @if (showArchive()) {
      <button
        mat-button
        class="button"
        (click)="createArchive.emit()"
        [disabled]="getButtonState()"
      >
        {{ 'menus.linked_templates.archive' | transloco }}
      </button>
    }
    @if (showShareLink()) {
      <button
        mat-button
        class="button"
        [disabled]="getButtonState()"
        ngxClipboard
        [cbContent]="shareLink"
        (cbOnSuccess)="copied.emit()"
      >
        {{ 'menus.linked_templates.copy' | transloco }}
      </button>
    }
  </div>
</div>
