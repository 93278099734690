import { Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  FileUploadControl,
  FileUploadModule,
  FileUploadValidators,
} from '@iplab/ngx-file-upload';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { Menu } from 'src/app/shared/Models/menu';
import {
  FileService,
  FileUploadApi,
} from 'src/app/shared/Services/files/files.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { fetchMenuDishes, setLoading } from '../../../ngrx/menu-write.actions';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-import-menu-dialog',
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    MatIconModule,
    FileUploadModule,
    TranslocoPipe,
    MatButtonModule,
  ],
  templateUrl: './import-menu-dialog.component.html',
  styleUrl: './import-menu-dialog.component.scss',
})
export class ImportMenuDialogComponent {
  data = inject<{
    menu: Menu;
  }>(MAT_DIALOG_DATA);
  private fileService = inject(FileService);
  private ngrxStore = inject(Store);
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);

  errorMessage: string;
  lang: InterfaceLanguage;
  public fileUploadControl = new FileUploadControl(
    {
      discardInvalid: true,
      multiple: false,
    },
    [
      FileUploadValidators.filesLimit(1),
      FileUploadValidators.accept([
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ]),
    ],
  );

  constructor() {
    this.translate.langChanges$.subscribe(this.handleLang);
    this.handleLang();
    this.utils.getTranslation(
      'shared.errors.unsupported-file',
      (el) => (this.errorMessage = el),
    );
  }

  dispatchFile(): void {
    const data = { menu: this.data.menu.id };
    this.ngrxStore.dispatch(setLoading({ loading: true }));
    this.fileService
      .uploadForm<Menu>(
        data,
        this.fileUploadControl.value,
        'attachment',
        FileUploadApi.menu,
        { current_menu: this.data.menu.id },
      )
      .subscribe({
        next: () => {
          this.ngrxStore.dispatch(setLoading({ loading: false }));
          this.ngrxStore.dispatch(
            fetchMenuDishes({ params: { condensed: true } }),
          );
          this.fileUploadControl.clear();
        },
        error: (error) => {
          this.ngrxStore.dispatch(setLoading({ loading: false }));
          this.ngrxStore.dispatch(handleHttpError({ error }));
          this.fileUploadControl.clear();
        },
      });
  }

  dispatchFileError(): void {
    this.fileUploadControl.clear();
    this.ngrxStore.dispatch(
      showSnackbarMessage({ message: this.errorMessage }),
    );
  }

  handleLang = () => {
    if (!this.translate) return undefined;
    this.lang = this.translate.getActiveLang() as InterfaceLanguage;
  };
}
