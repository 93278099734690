import {
  Component,
  output,
  input,
  linkedSignal,
  viewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslocoPipe } from '@jsverse/transloco';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { ColorPickerComponent } from './color-picker/color-picker.component';

@Component({
  selector: 'mt-color-picker',
  templateUrl: './mt-color-picker.component.html',
  styleUrls: ['./mt-color-picker.component.scss'],
  imports: [
    ColorPickerComponent,
    MtTooltipDirective,
    MatButtonModule,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class MtColorPickerComponent {
  readonly className = input<string>(undefined);
  readonly color = input.required<string>();
  readonly disabled = input<boolean>(undefined);
  readonly isLocked = input<boolean>(undefined);
  readonly showClear = input<boolean>(undefined);

  readonly colorChange = output<string>();
  readonly clearColor = output<void>();

  readonly colorPicker = viewChild<ColorPickerComponent>('colorPicker');

  currentColor = linkedSignal(this.color);

  setCurrentColor(event: MouseEvent): void {
    this.currentColor.set('#FFFFFF');
    setTimeout(() => {
      this.colorPicker().applyClick(event);
    }, 100);
  }
}
