import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit, output } from '@angular/core';
import { SafePipe } from '../../../Pipes/safe.pipe';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mt-tooltip-template',
  templateUrl: './mt-tooltip-template.component.html',
  styleUrls: ['./mt-tooltip-template.component.css'],
  animations: [
    trigger(`tooltip`, [
      state(`void`, style({ opacity: 0, transform: 'translateY(2px)' })),
      state(
        `show`,
        style({
          opacity: 1,
          transform: 'translateY(0)',
        }),
      ),
      state(
        `hide`,
        style({
          opacity: 0,
          transform: 'translateY(2px)',
        }),
      ),
      transition(`* => show`, animate(`100ms ease-out`)),
      transition(`show => *`, animate(`250ms ease-in`)),
    ]),
  ],
  imports: [MatIconModule, SafePipe],
})
export class MtTooltipTemplateComponent implements OnInit {
  public text: string;
  public html: string;
  public canClose: boolean;

  readonly hide = output<void>();

  state = `void`;
  animationEndCallback: () => void;

  ngOnInit(): void {
    this.state = `show`;
  }

  end(event: AnimationEvent): void {
    if (event.toState === `hide` && this.state === `hide`) {
      this.animationEndCallback?.();
    }
  }
}
