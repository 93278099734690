<mat-accordion displayMode="flat">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>dashboard</mat-icon>
      {{ 'style.sidebar.panels.primary.mobile.layout' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.columns' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <toggle-item
            [field]="'mobile_columns'"
            [menu]="menu()"
            [showLock]="showLocks()"
            [lockedFields]="lockedFields()"
            [disabledFields]="disabledFields()"
            (updateMenu)="updateMenu.emit($event)"
          ></toggle-item>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>tag</mat-icon>
      {{ 'style.sidebar.panels.primary.mobile.spacing' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.mobile.margins_text' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          @for (
            field of ['pagetop', 'section', 'enddescription', 'detail'];
            track field
          ) {
            <div class="element-group">
              <span class="element-title"
                >{{
                  'style.sidebar.panels.subheaders.' + field | transloco
                }}:</span
              >
              <number-item
                class="element-narrow"
                [field]="'margin_' + field + '_top_mobile'"
                type="value"
                unit="mm"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
              @if (field !== 'detail' && field !== 'enddescription') {
                <number-item
                  [field]="'margin_' + field + '_bottom_mobile'"
                  type="value"
                  unit="mm"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></number-item>
              }
            </div>
          }
          <div class="element-group">
            <span class="element-title"
              >{{ 'style.sidebar.panels.subheaders.spacer' | transloco }}:</span
            >
            @for (field of ['section', 'item']; track field) {
              <number-item
                class="element-narrow"
                [field]="'margin_spacer_' + field + '_mobile'"
                type="value"
                unit="mm"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
            }
          </div>
          <div class="element-group">
            <span class="element-title"
              >{{
                'style.sidebar.panels.subheaders.dish_bev_win' | transloco
              }}:</span
            >
            <number-item
              class="margin-text"
              field="margin_text_mobile"
              type="value"
              unit="mm"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></number-item>
            <number-item
              class="margin-text"
              field="margin_description_mobile"
              type="value"
              unit="mm"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></number-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>palette</mat-icon>
      {{ 'style.sidebar.panels.primary.mobile.colours' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.mobile.background' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group color-field">
            <span class="expansion-panel-field">{{
              'style.sidebar.items.page' | transloco
            }}</span>
            <div
              class="element-wrapper"
              [class.color-disabled]="
                disabledFields().includes('colour_page_mobile')
              "
              [mtTooltip]="
                disabledFields().includes('colour_page_mobile')
                  ? ('style.sidebar.restrictions.layout-field-locked'
                    | transloco)
                  : ''
              "
            >
              <mt-color-picker
                class="mt-color-picker"
                [class.color-picker-disabled]="
                  disabledFields().includes('colour_page_mobile')
                "
                [color]="menu()?.style && menu()?.style['colour_page_mobile']"
                [disabled]="disabledFields().includes('colour_page_mobile')"
                [showClear]="!disabledFields().includes('colour_page_mobile')"
                (colorChange)="colorChanged('colour_page_mobile', $event)"
                (clearColor)="clearColor('colour_page_mobile')"
              ></mt-color-picker>
              @if (showLocks()) {
                <lock-item
                  [field]="'colour_page_mobile'"
                  [lockedFields]="lockedFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></lock-item>
              }
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.mobile.header' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            @if (
              !['hotel', 'ent'].includes(user()?.status?.subscription_type)
            ) {
              <p>
                {{ 'style.sidebar.restrictions.whitelabelling' | transloco }}
              </p>
            }
            @for (
              field of ['header_background', 'header_panel', 'header_text'];
              track field
            ) {
              <div class="color-field no-clear">
                <span class="expansion-panel-field">{{
                  'style.sidebar.items.' + field | transloco
                }}</span>
                <div
                  [mtTooltip]="
                    !['hotel', 'ent'].includes(
                      user()?.status?.subscription_type
                    )
                      ? ('style.sidebar.restrictions.restricted' | transloco)
                      : disabledFields().includes('colour_' + field)
                        ? ('style.sidebar.restrictions.layout-field-locked'
                          | transloco)
                        : ''
                  "
                  class="element-wrapper"
                  [class.color-disabled]="
                    disabledFields().includes('colour_' + field)
                  "
                >
                  <mt-color-picker
                    [isLocked]="disabledFields().includes('colour_' + field)"
                    [disabled]="
                      !['hotel', 'ent'].includes(
                        user()?.status?.subscription_type
                      ) || disabledFields().includes('colour_' + field)
                    "
                    class="mt-color-picker"
                    [class.color-picker-disabled]="
                      disabledFields().includes('colour_' + field)
                    "
                    [color]="menu()?.style['colour_' + field]"
                    [showClear]="true"
                    (colorChange)="colorChanged('colour_' + field, $event)"
                    (clearColor)="clearColor('colour_' + field)"
                  ></mt-color-picker>
                  @if (showLocks()) {
                    <lock-item
                      [field]="'colour_' + field"
                      [lockedFields]="lockedFields()"
                      (updateMenu)="
                        ['hotel', 'ent'].includes(
                          user()?.status?.subscription_type
                        ) && updateMenu.emit($event)
                      "
                    ></lock-item>
                  }
                </div>
              </div>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>animation</mat-icon>
      {{ 'style.sidebar.panels.primary.mobile.interactivity' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.mobile.collapse' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [field]="'collapsible_sections'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            <toggle-item
              [field]="'collapsible_subsections'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.mobile.optional-information'
              | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <p class="field-info">
              {{ 'style.sidebar.hints.optional_information' | transloco }}
            </p>
            @for (field of optionalInformation; track field) {
              @if (menu()?.style['print_' + field]) {
                <toggle-item
                  [disabled]="!hasModule('recip')"
                  [field]="'default_show_' + field"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
              }
            }
            <hr />
            <p class="field-info">
              <br />
              {{ 'style.sidebar.hints.show_in_modal' | transloco }}
            </p>
            <select-item
              [menu]="menu()"
              [multiple]="true"
              [field]="'show_in_modal'"
              [list]="styleConstants.showInModalOptions"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              [placeholder]="
                'style.sidebar.selects.show_in_modal_options.none' | transloco
              "
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.mobile.product-sheets' | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [disabled]="!hasModule('recip')"
              [field]="'show_product_sheets'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            <p class="field-info">
              {{ 'style.sidebar.restrictions.product-sheets' | transloco }}
            </p>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
