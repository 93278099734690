import { Routes, UrlSegment } from '@angular/router';

import { AcceptInvitationContainer } from './auth/Containers/accept-invitation/accept-invitation.container';
import { ConfirmationContainer } from './auth/Containers/confirmation/confirmation.container';
import { EmailPreferencesContainer } from './auth/Containers/email-preferences/email-preferences.container';
import { LoginContainer } from './auth/Containers/login/login.container';
import { RegistrationStepsContainer } from './auth/Containers/registration-steps/registration-steps.container';
import { RegistrationContainer } from './auth/Containers/registration/registration.container';
import { ResetPasswordContainer } from './auth/Containers/reset-password/reset-password.container';
import { DashboardContainer } from './dashboard/dashboard-container/dashboard.container';
import { MainContainerComponent } from './main-container/main-container.component';
import { MenuEditContainer } from './menus/menu-edit/menu-edit.container';
import { MenusOverviewContainer } from './menus/menus-overview/menus-overview.container';
import { AuthErrorComponent } from './shared/Components/auth-error/auth-error.component';
import { ServerErrorComponent } from './shared/Components/server-error/server-error.component';
import { ErrorResolverService } from './shared/Services/error-resolver/error-resolver.service';
import { emailTokenGuard } from './shared/Services/guards/email-token.guard';
import { loginGuard } from './shared/Services/guards/login.guard';
import { menuPrivilegesGuard } from './shared/Services/guards/menu-privileges.guard';
import {
  registrationStepsCanDeactivateGuard,
  registrationStepsGuard,
} from './shared/Services/guards/registration-steps.guard';
import { unauthenticatedGuard } from './shared/Services/guards/unauthenticated.guard';
import { userResolver } from './shared/Services/resolvers/user.resolver';

function stepsPath(url: UrlSegment[]) {
  if (
    url.length === 2 &&
    url[0].path === 'registration' &&
    new RegExp(`\\d+`).test(url[1].path)
  ) {
    return {
      consumed: url,
      posParams: {
        step: url[1],
      },
    };
  }
  return null;
}

export const routes: Routes = [
  {
    path: '',
    component: MainContainerComponent,
    resolve: [userResolver],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        data: { title: 'dashboard' },
      },
      {
        path: 'dashboard',
        component: DashboardContainer,
        canActivate: [loginGuard],
        data: { title: 'dashboard' },
      },
      {
        path: 'menus',
        component: MenusOverviewContainer,
        canActivate: [loginGuard],
        data: { title: 'menus' },
      },
      {
        path: 'menus/:id/:step',
        component: MenuEditContainer,
        canActivate: [loginGuard, menuPrivilegesGuard],
        data: { title: '' },
      },
      {
        path: 'types',
        loadChildren: () =>
          import('./consumertypes/types.routes').then((m) => m.routes),
      },
      {
        path: 'diets',
        loadChildren: () =>
          import('./diets/diets.routes').then((m) => m.routes),
      },
      {
        path: 'dishes',
        loadChildren: () =>
          import('./dishes/dishes.routes').then((m) => m.routes),
      },
      {
        path: 'ingredients',
        loadChildren: () =>
          import('./ingredients/ingredients.routes').then((m) => m.routes),
      },
      {
        path: 'integrations',
        loadChildren: () =>
          import('./integrations/integrations.routes').then((m) => m.routes),
      },
      {
        path: 'locations',
        loadChildren: () =>
          import('./locations/locations.routes').then((m) => m.routes),
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('./procurement/procurement.routes').then((m) => m.routes),
      },
      {
        path: 'recipes',
        loadChildren: () =>
          import('./recipes/recipes.routes').then((m) => m.routes),
      },
      {
        path: 'rules',
        loadChildren: () =>
          import('./rules/rules.routes').then((m) => m.routes),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.routes').then((m) => m.routes),
      },
      {
        path: 'templates',
        loadChildren: () =>
          import('./templates/templates.routes').then((m) => m.routes),
      },
      {
        path: 'declarations',
        loadChildren: () =>
          import('./declarations/declarations.routes').then(
            (m) => m.declarationsRoutes,
          ),
      },
    ],
  },
  {
    path: '404',
    component: AuthErrorComponent,
    data: { title: '404' },
    canActivate: [ErrorResolverService],
  },
  {
    path: 'login',
    component: LoginContainer,
    canActivate: [unauthenticatedGuard],
    data: { title: 'login' },
  },
  {
    path: 'reset/:uid/:token',
    component: ResetPasswordContainer,
    canActivate: [unauthenticatedGuard],
    data: { title: 'reset-password' },
  },
  {
    path: 'reset',
    component: ResetPasswordContainer,
    canActivate: [unauthenticatedGuard],
    data: { title: 'reset-password' },
  },
  {
    path: 'registration',
    component: RegistrationContainer,
    canActivate: [unauthenticatedGuard],
    data: { title: 'registration' },
  },
  {
    matcher: stepsPath,
    component: RegistrationStepsContainer,
    resolve: { user: userResolver },
    canActivate: [registrationStepsGuard],
    canDeactivate: [registrationStepsCanDeactivateGuard],
    data: { title: 'registration-steps' },
  },
  {
    path: 'registration/:affiliate',
    component: RegistrationContainer,
    canActivate: [unauthenticatedGuard],
    data: { title: 'registration' },
  },
  {
    path: 'registration/reactivate/:uid/:token',
    component: RegistrationContainer,
    data: { title: `registration`, reactivate: true },
  },
  {
    path: 'registration/restore/:uid/:token',
    component: RegistrationContainer,
    data: { title: `registration`, restore: true },
  },
  {
    path: 'confirm-email/:key',
    component: ConfirmationContainer,
    data: { title: 'confirm-email' },
  },
  {
    path: 'email_preferences/:email/:token',
    component: EmailPreferencesContainer,
    canActivate: [emailTokenGuard],
    data: { title: 'email-pref' },
  },
  {
    path: 'accept-invitation/:key',
    component: AcceptInvitationContainer,
    data: { title: 'accept-inv' },
  },
  {
    path: 'server-error',
    component: ServerErrorComponent,
  },
  {
    path: '**',
    component: AuthErrorComponent,
    canActivate: [ErrorResolverService],
    data: { title: 'error' },
  },
];
