import {
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  MatDatepickerInput,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-download-stats',
  templateUrl: './download-stats.component.html',
  styleUrls: ['./download-stats.component.scss'],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
    StopPropagationDirective,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class DownloadStatsComponent implements OnChanges {
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter);

  readonly created = input<string>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly download = output<{
    date_from: string;
    date_to: string;
  }>();

  readonly startDate = viewChild<MatDatepickerInput<Date>>('inputStart');
  readonly endDate = viewChild<MatDatepickerInput<Date>>('inputEnd');

  minDate: Date;
  maxDate = new Date();

  ngOnChanges(changes: SimpleChanges): void {
    const created = this.created();
    if ('created' in changes && created) {
      this.minDate = new Date(created);
    }
  }

  downloadStats() {
    // add one day to endDate to include the whole day
    const editedDate = new Date(this.endDate().value);
    editedDate.setDate(editedDate.getDate() + 1);
    this.download.emit({
      date_from: this.dateAdapter.format(this.startDate().value, 'yyyy-MM-dd'),
      date_to: this.dateAdapter.format(editedDate, 'yyyy-MM-dd'),
    });
  }
}
