import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Dish } from 'src/app/shared/Models/dish';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';

@Component({
  selector: 'quick-overview-dishes',
  template: `
    {{
      (dish() && dish()[lang]) ||
        dish().en ||
        dish().it ||
        dish().fr ||
        dish().de
    }}
  `,
  styles: [
    `
      :host {
        padding: 7px;
        border-bottom: 2px solid rgb(242, 241, 242);
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class QuickOverviewDishesComponent {
  private translate = inject(TranslocoService);

  readonly dish = input<Dish>(undefined);
  lang: InterfaceLanguage;

  constructor() {
    this.translate.langChanges$.subscribe(this.handleLang);
    this.handleLang();
  }

  handleLang = () => {
    if (!this.translate) return undefined;
    this.lang = this.translate.getActiveLang() as InterfaceLanguage;
  };
}
