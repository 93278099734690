<div [formGroup]="form()">
  <mat-form-field>
    <mat-label>{{
      'write.blocks.dish-overview.information.date' | transloco
    }}</mat-label>
    <input
      matInput
      [name]="field()"
      [formControlName]="field()"
      [matDatepickerFilter]="dayFilter"
      [matDatepicker]="picker"
      (click)="picker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
