import {
  ChangeDetectionStrategy,
  Component,
  inject,
  output,
  input,
} from '@angular/core';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import {
  MatDatepickerInputEvent,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import {
  TranslocoService,
  TranslocoDirective,
  TranslocoPipe,
} from '@jsverse/transloco';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { compareAsc, compareDesc } from 'date-fns';
import { Allergen } from 'src/app/shared/Models/declarations';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { CopyMenuOptions, OverviewMenu } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'copy-with-options',
  templateUrl: './copy-with-options.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./copy-with-options.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    FormsModule,
    MatButtonModule,
    StopPropagationDirective,
    MatIconModule,
    MatSelectModule,
    MatOptionModule,
    TranslocoDirective,
    MatCheckboxModule,
    TranslocoPipe,
  ],
})
export class CopyWithOptionsComponent {
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private utils = inject(UtilsService);
  private translate = inject(TranslocoService);

  readonly allergens = input<Allergen[]>(undefined);
  readonly hasModuleAllergens = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly menu = input<OverviewMenu>(undefined);
  readonly cancelAction = output<void>();
  readonly copyMenuWithOptions = output<CopyMenuOptions>();

  copyWithOptions = {
    date_from: null,
    date_to: null,
    replace_with_empty: false,
  };
  filteredAllergens: Allergen[];
  highlights = new FormControl<string[]>([]);
  startFromDate: Date;
  startToDate: Date;
  tranWithout = 'menus.linked_templates.highlights.without';

  constructor() {
    this.translate
      .selectTranslate(this.tranWithout, { name: 'XXX' })
      .subscribe((t) => (this.tranWithout = t));
  }

  dayFilter = (d: Date): boolean => {
    this.startFromDate = new Date(this.menu().date_range_min);
    this.startToDate = new Date(this.menu().date_range_max);
    return (
      compareAsc(new Date(this.menu().date_range_min), new Date(d)) <= 0 &&
      compareDesc(new Date(this.menu().date_range_max), new Date(d)) <= 0
    );
  };

  dateChange(event: MatDatepickerInputEvent<Date>, field) {
    this.copyWithOptions[field] = event.value;
    if (
      field === 'date_to' &&
      this.copyWithOptions.date_from &&
      this.copyWithOptions.date_to < this.copyWithOptions.date_from
    )
      this.copyWithOptions.date_from = this.copyWithOptions.date_to;
    if (
      field === 'date_from' &&
      this.copyWithOptions.date_to &&
      this.copyWithOptions.date_from > this.copyWithOptions.date_to
    )
      this.copyWithOptions.date_to = this.copyWithOptions.date_from;
  }

  getHighlighted(): string {
    if (this.highlights.value && this.highlights.value.length)
      return this.allergens()
        .filter((all) => this.highlights.value.includes(all.code))
        .map((v) => this.tranWithout.replace('XXX', v[this.lang()]))
        .join('; ');
    return null;
  }

  getSymbols(): string {
    if (!this.highlights.value) return undefined;
    return this.highlights.value
      .slice(0, 2)
      .map((code) => this.utils.getAllergenSymbol(code))
      .join('');
  }

  isAllergenSelected = (code: string): boolean =>
    this.highlights.value && this.highlights.value.includes(code);

  formatDate = (date: Date): string =>
    this.dateAdapter.format(date, 'yyyy-MM-dd');

  triggerCopyWithOptions(): void {
    this.copyMenuWithOptions.emit({
      date_from: this.copyWithOptions.date_from
        ? this.formatDate(this.copyWithOptions.date_from)
        : null,
      date_to: this.copyWithOptions.date_to
        ? this.formatDate(this.copyWithOptions.date_to)
        : null,
      exclude_declarations: this.highlights.value,
      replace_with_empty: this.copyWithOptions.replace_with_empty,
    });
  }
}
