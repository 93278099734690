import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { ResultWithCategories } from 'src/app/shared/Models/diet';
import { Results } from 'src/app/shared/Models/generics';
import {
  Menu,
  Style,
  StyleCategories,
  Template,
} from 'src/app/shared/Models/menu';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { Rule } from 'src/app/shared/Models/ruleset';
import { Separator } from 'src/app/shared/Models/separator';
import { Layout } from 'src/app/shared/Models/template';
import {
  addTemplate,
  handleHttpError,
  removeTemplate,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  catchError,
  concatMap,
  finalize,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  deleteOnboardingTemplate,
  setCurrentMenu,
  setOnboardingTemplateDetail,
  setOriginalStyle,
  setStyleModified,
} from '../../ngrx/menu-edit.actions';
import { selectCurrentMenu } from '../../ngrx/menu-edit.selectors';
import * as MenuStyleActions from './menu-style.actions';
import { selectUserStyles } from './menu-style.selectors';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';

@Injectable()
export class MenuStyleEffects {
  private actions$ = inject(Actions);
  private configService = inject(ConfigService);
  private genericsService = inject(GenericsService);
  private store = inject<Store<State>>(Store);
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);

  templateCreated: string;

  fetchStyles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.fetchStyles),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ params }, location]) =>
        this.genericsService
          .get<
            ResultWithCategories<Style, StyleCategories>
          >(this.configService.styles, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((styles) => [
              MenuStyleActions.setStyleCategories({
                categories: styles.categories,
              }),
              MenuStyleActions.setStyles({ styles: styles.results }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  setStyles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.setStyles),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(([{ styles }, currentMenu]) => {
        const userStyles = styles.filter((style) => style.category === 'user');
        const actions = [
          MenuStyleActions.setUserStyles({
            styles: userStyles,
          }),
          MenuStyleActions.setPublicStyles({
            styles: styles.filter((style) => style.category === 'public'),
          }),
          MenuStyleActions.setSpecialStyles({
            styles: styles.filter((style) => style.category === 'special'),
          }),
        ];
        const currentStyle = styles.find(
          (el) => el.id === currentMenu?.style_original,
        );
        const tabs: Record<StyleCategories[number], number> = {
          user: 0,
          public: 1,
          special: 2,
        };
        if (currentStyle && tabs[currentStyle.category] !== undefined) {
          const tab = tabs[currentStyle.category];
          return [
            ...actions,
            MenuStyleActions.moveStyleToFront({
              styleId: currentMenu?.style_original,
              list: currentStyle.category,
            }),
            MenuStyleActions.switchStyleTab({
              index: tab,
            }),
          ];
        }
        const tab = userStyles.length ? 0 : 1;
        return [
          ...actions,
          MenuStyleActions.switchStyleTab({
            index: tab,
          }),
        ];
      }),
    ),
  );

  saveStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.saveStyle),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ currentMenu }, location]) =>
        this.genericsService
          .post<
            Partial<Style>,
            Style
          >(`${this.configService.menus}${currentMenu}/save_style/`, {}, UtilsService.addLocationParam(location, null))
          .pipe(
            mergeMap((style) => [
              setOriginalStyle({ id: style.id }),
              MenuStyleActions.addCreatedStyle({ style: style }),
            ]),
            tap(() => {
              setTimeout(() =>
                this.store.dispatch(
                  MenuStyleActions.switchStyleTab({ index: 0 }),
                ),
              );
            }),
            catchError((error) => [
              MenuStyleActions.showStyleSpinner({ spinnerState: null }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  deleteStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.deleteStyle),
      withLatestFrom(
        this.store.select(sharedFeature.selectCurrentLocation),
        this.store.select(selectUserStyles),
      ),
      switchMap(([{ url, id, params }, location, userStyles]) =>
        this.genericsService
          .delete(url, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap(() => [
              MenuStyleActions.removeStyle({ styleId: id }),
              ...(userStyles.length === 1
                ? [MenuStyleActions.switchStyleTab({ index: 1 })]
                : []),
            ]),
            catchError((error) => [
              MenuStyleActions.showStyleSpinner({ spinnerState: null }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  patchStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.patchStyle),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ url, data, params }, location]) =>
        this.genericsService
          .patch<
            Partial<Style>,
            Style
          >(url, data, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((style) => [
              MenuStyleActions.changeStyle({ newStyle: style }),
            ]),
            catchError((error) => [
              MenuStyleActions.showStyleSpinner({ spinnerState: null }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  fetchCondensedTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.fetchLayouts),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ params }, currentMenu]) =>
        this.genericsService
          .get<ResultWithCategories<Layout, StyleCategories>>(
            this.configService.layouts,
            {
              ...(currentMenu ? { current_menu: currentMenu.id } : {}),
              ...params,
            },
          )
          .pipe(
            mergeMap(({ results, categories }) => [
              MenuStyleActions.setLayoutCategories({ payload: categories }),
              MenuStyleActions.setLayouts({ payload: results }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  setLayouts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.setLayouts),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      mergeMap(([{ payload: condensedLayouts }, currentMenu]) => {
        const userLayouts = condensedLayouts?.filter(
          (layout) => layout.category === 'user',
        );
        const actions = [
          MenuStyleActions.setUserLayouts({
            payload: userLayouts,
          }),
          MenuStyleActions.setPublicLayouts({
            payload: condensedLayouts?.filter(
              (layout) => layout.category === 'public',
            ),
          }),
          MenuStyleActions.setSpecialLayouts({
            payload: condensedLayouts?.filter(
              (layout) => layout.category === 'special',
            ),
          }),
        ];
        if (currentMenu?.template) {
          const currentLayout = condensedLayouts?.find(
            (el) => el.id === currentMenu?.template,
          );
          const tabs: Record<StyleCategories[number], number> = {
            user: 0,
            public: 1,
            special: 2,
          };
          const tab = userLayouts.length
            ? tabs[currentLayout?.category]
            : tabs[currentLayout?.category] - 1;
          if (currentLayout && tabs[currentLayout.category] !== undefined) {
            return [...actions, MenuStyleActions.switchTab({ payload: tab })];
          }
        }
        return actions;
      }),
    ),
  );

  setLayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.setLayout),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ layoutId }, currentMenu]) =>
        this.genericsService
          .patch<
            Partial<Menu>,
            Menu
          >(`${this.configService.menus}${currentMenu.id}/`, { template: layoutId })
          .pipe(
            mergeMap((menu) => [setCurrentMenu({ menu })]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  createTemplateFromMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.createTemplateFromMenu),
      withLatestFrom(
        this.store.select(selectCurrentMenu),
        this.store.select(selectUser),
      ),
      switchMap(([{ template, menu_id }, menu, user]) =>
        this.genericsService
          .post<
            Partial<Template> | FormData,
            OnboardingTemplate
          >(`${this.configService.menus}${menu_id}/create_template/`, template)
          .pipe(
            mergeMap((res) => [
              setOnboardingTemplateDetail({ template: res }),
              showSnackbarMessage({ message: this.templateCreated }),
              ...(menu.location === user.location
                ? [addTemplate({ template: res })]
                : []),
            ]),
            catchError((error) => [
              MenuStyleActions.showStyleSpinner({ spinnerState: null }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  updateTemplateStyleAndLayout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MenuStyleActions.updateTemplateStyleAndLayout),
        switchMap(({ id }) =>
          this.genericsService
            .post<
              {},
              Template
            >(`${this.configService.menus}${id}/update_template/`, {})
            .pipe(catchError((error) => [handleHttpError({ error })])),
        ),
      ),
    { dispatch: false },
  );

  unlinkTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.unlinkTemplate),
      switchMap(({ template, id }) =>
        this.genericsService
          .patch<
            Partial<Menu>,
            Menu
          >(`${this.configService.menus}${id}/`, template)
          .pipe(
            mergeMap((res) => [
              setOnboardingTemplateDetail({ template: null }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateTemplateDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.updateTemplateDetail),
      switchMap(({ url, data, params }) =>
        this.genericsService
          .patch<
            Partial<OnboardingTemplate>,
            OnboardingTemplate
          >(url, data, params)
          .pipe(
            mergeMap((template) => [setOnboardingTemplateDetail({ template })]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  deleteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.deleteTemplate),
      switchMap(({ url, params, id }) =>
        this.genericsService.delete(url, params).pipe(
          mergeMap(() => [deleteOnboardingTemplate(), removeTemplate({ id })]),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  applyStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.applyStyle),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      tap(([{ showSpinner }]) => {
        if (showSpinner) {
          this.store.dispatch(
            MenuStyleActions.showStyleSpinner({ spinnerState: 'select' }),
          );
        }
      }),
      switchMap(([{ showSpinner, style, restore, callback }, currentMenu]) =>
        this.genericsService
          .post<
            { style: number | Style },
            Menu
          >(`${this.configService.menus}${currentMenu.id}/apply_style/`, { style })
          .pipe(
            mergeMap((result) => {
              callback?.();
              return [
                setCurrentMenu({ menu: result }),
                ...(showSpinner
                  ? [MenuStyleActions.showStyleSpinner({ spinnerState: null })]
                  : []),
                ...(restore
                  ? [
                      showSnackbarMessage({
                        message: this.translate.translate(
                          'style.chosen-rule.restored',
                        ),
                        snackClass: 'success',
                      }),
                    ]
                  : []),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateStyle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.updateStyle),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      tap(() => {
        this.store.dispatch(
          MenuStyleActions.showStyleSpinner({ spinnerState: 'update' }),
        );
      }),
      switchMap(([{ callback, payload, params }, currentMenu]) =>
        this.genericsService
          .post<
            {},
            Menu
          >(`${this.configService.menus}${currentMenu.id}/update_style/`, payload, params)
          .pipe(
            mergeMap(() => {
              if (callback) callback();
              const actions: Action[] = [
                setStyleModified({ value: false }),
                MenuStyleActions.showStyleSpinner({ spinnerState: null }),
              ];
              if (payload.target === 'rule') {
                actions.push(
                  showSnackbarMessage({
                    message: this.translate.translate(
                      'style.chosen-rule.updated',
                    ),
                    snackClass: 'success',
                  }),
                );
              }
              return actions;
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  uploadLogoImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.uploadLogoImage),
      switchMap(({ data, url, field, lockedFields }) => {
        const formData = new FormData();
        formData.append(`style.${field}`, data.payload);
        let value = lockedFields;
        value = Object.assign([], value);
        value.push(field);
        for (const item of value) {
          formData.append(`style.locked_fields`, item);
        }
        return this.genericsService.patch<FormData, Menu>(url, formData).pipe(
          finalize(() => data.onFulfilled?.()),
          mergeMap((menu) => [setCurrentMenu({ menu: menu })]),
          catchError((error) => [handleHttpError({ error })]),
        );
      }),
    ),
  );

  fetchSeparators$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.fetchSeparators),
      concatMap(({ category }) =>
        this.genericsService
          .get<Results<Separator>>(this.configService.separators, {
            category,
            condensed: true,
          })
          .pipe(
            mergeMap((data) => {
              const setDataAction =
                category === 'opt'
                  ? MenuStyleActions.setOptions
                  : MenuStyleActions.setCourses;
              return [setDataAction({ separators: data.results })];
            }),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  patchRuleDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuStyleActions.patchRuleDetail),
      switchMap(({ url, data }) =>
        this.genericsService.patch<Partial<Rule>, Rule>(url, data).pipe(
          mergeMap(() => []),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  constructor() {
    this.utils.getTranslation(
      'style.choose-template.template-created',
      (message) => (this.templateCreated = message),
    );
  }
}
