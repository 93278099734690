import {
  Component,
  OnChanges,
  SimpleChanges,
  output,
  viewChild,
  input,
} from '@angular/core';
import {
  MatExpansionPanel,
  MatExpansionModule,
} from '@angular/material/expansion';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerComponent } from '../spinner/spinner.component';

@Component({
  selector: 'magic-stick',
  templateUrl: './magic-stick.component.html',
  styleUrls: ['./magic-stick.component.scss'],
  imports: [
    MatExpansionModule,
    SpinnerComponent,
    MatIconModule,
    MtTooltipDirective,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class MagicStickComponent implements OnChanges {
  readonly ai = input(false);
  readonly aiCredits = input(1);
  readonly aiCreditsRemaining = input<number>(undefined);
  readonly customIcon = input<string>(undefined);
  readonly loading = input(false);
  readonly disabled = input(false);
  readonly title = input<string>(undefined);
  readonly description = input<string>(undefined);
  readonly buttonMsg = input<string>(undefined);
  readonly buttonLoadingMsg = input<string>(undefined);
  readonly tooltip = input<string>(undefined);

  readonly clicked = output();

  readonly panel = viewChild<MatExpansionPanel>(`panel`);

  collapse: () => void;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loading && !changes.loading.firstChange && !this.loading()) {
      this.collapse?.();
      this.collapse = undefined;
    }
  }

  emitAndCollapse = (event: MouseEvent) => {
    event?.stopPropagation();
    event?.stopImmediatePropagation();
    if (!this.loading()) {
      if (!this.disabled()) this.clicked.emit();
      this.panel().expanded = true;
      this.collapse = () => (this.panel().expanded = false);
    }
  };
}
