import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';

import { SimpleLocation } from './../../Models/location';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'view-as-location',
  templateUrl: './view-as-location.component.html',
  styleUrls: ['./view-as-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    TranslocoPipe,
  ],
})
export class ViewAsLocationComponent implements OnDestroy, OnInit {
  private changeDetector = inject(ChangeDetectorRef);
  private overlayService = inject(OverlayService);

  readonly locations = input<SimpleLocation[]>(undefined);
  readonly locationChanged = output<MatSelectChange>();

  readonly selectLocationRef = viewChild<TemplateRef<any>>('selectLocation');

  ngOnInit(): void {
    setTimeout(() => {
      this.overlayService.insertTemplate(
        `view-as-location`,
        this.selectLocationRef(),
      );
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.locationChanged.emit({ source: null, value: null });
    this.overlayService.clear(`view-as-location`);
  }
}
