<div
  [ngClass]="{ master: menu() && menu()?.children?.length > 0 }"
  class="row row-eq-height block"
  [class.menu]="isChild()"
>
  <mat-accordion class="menus-accordion">
    <mat-expansion-panel
      #matExpansionPanel
      [hideToggle]="true"
      class="expansion-panel mat-elevation-z0"
      [class.dashed-border]="
        !isLastChild() && !matExpansionPanel.expanded && !isLastMenu()
      "
    >
      <mat-expansion-panel-header
        (click)="expandPanel(matExpansionPanel, $event)"
        class="expansion-panel-header"
        expandedHeight="48px"
        [class.row-selected]="currentMenu()?.id === menu()?.id"
      >
        @if (menu()?.children?.length > 0) {
          <span class="icon-button-density-3">
            <button
              mat-icon-button
              class="mat-expansion-indicator"
              [class.arrow-up]="matExpansionPanel.expanded"
            ></button>
          </span>
        }
        @if (!isChild()) {
          <div class="wrapper" [class.locked]="!menu()?.privileges?.edit_menu">
            <div
              class="col date"
              [class.take-space]="!menu()?.children?.length"
              [routerLink]="
                menu() && menu()?.privileges?.edit_menu
                  ? ['/menus', menu().id, 4]
                  : null
              "
            >
              @if (!isChild() && menu() && menu().featured) {
                <mat-icon class="icon-star">
                  {{ menu()?.featured ? 'star' : 'star_border' }}</mat-icon
                >
              }
              {{ menu()?.date | date: 'mediumDate' : undefined : lang }}
            </div>
            <div
              class="col name"
              [class.take-space]="!menu()?.children?.length"
              [routerLink]="
                menu() && menu()?.privileges?.edit_menu
                  ? ['/menus', menu().id, 4]
                  : null
              "
            >
              @if (
                user() && user().role !== 3 && menu()?.created_by_role === 3
              ) {
                {{ menu().created_by_name }}&nbsp;&nbsp;
              }
              @if (
                user() &&
                user().role !== 3 &&
                !user()?.location &&
                (menu()?.location_detail || menu()?.location_group_detail)
              ) {
                {{
                  menu().location_detail
                    ? menu().location_detail.acronym
                      ? menu().location_detail.acronym
                      : menu().location_detail.name
                    : menu().location_group_detail.name
                }}&nbsp;-&nbsp;
              }
              {{ menu().name }}
            </div>
          </div>
        } @else {
          <div
            class="col name take-space child"
            [class.row-selected]="currentMenu()?.id === menu()?.id"
            [routerLink]="
              menu() && menu()?.privileges?.edit_menu
                ? ['/menus', menu().id, 4]
                : null
            "
          >
            @if (
              user() &&
              !user()?.location &&
              (menu()?.location_detail || menu()?.location_group_detail)
            ) {
              {{
                menu().location_detail
                  ? menu().location_detail.acronym
                    ? menu().location_detail.acronym
                    : menu().location_detail.name
                  : menu().location_group_detail.name
              }}&nbsp;-&nbsp;
            }
            {{ menu().name }}
            @if (menu()?.type_detail || menu()?.rule_detail) {
              <div class="menu-rule-tag">
                <mat-icon>bolt</mat-icon
                >{{ (menu().type_detail || menu().rule_detail)?.[lang] }}
              </div>
            }
            @if (menu()?.diets_list?.length) {
              <div class="menu-rule-tag">
                <mat-icon>spa</mat-icon>{{ menu().diets_list.join(', ') }}
              </div>
            }
          </div>
        }
        <div
          class="actions right icon-button-density-1"
          [class.child]="isChild()"
        >
          <button
            mat-icon-button
            stop-propagation
            (click)="showPreview.emit(menu())"
            class="button"
          >
            <mat-icon class="icon">visibility</mat-icon>
          </button>
          <button
            mat-icon-button
            stop-propagation
            [matMenuTriggerFor]="options"
            (click)="updateParent.emit()"
            class="button menu-parent"
          >
            <mat-icon class="icon">more_vert</mat-icon>
          </button>

          <mat-menu #options="matMenu" class="options">
            <ng-template matMenuContent>
              <span
                [matTooltip]="
                  !menu()?.privileges?.edit_menu
                    ? ('menus.overview.privileges.edit' | transloco)
                    : ''
                "
                matTooltipClass="top-navbar-tooltip"
              >
                @if (!menu()?.master_menu) {
                  <button
                    mat-menu-item
                    [disabled]="
                      !privileges().edit_menu || !menu()?.privileges?.edit_menu
                    "
                    (click)="featureThisMenu()"
                  >
                    <mat-icon>
                      {{ menu()?.featured ? 'star' : 'star_border' }}
                    </mat-icon>
                    {{
                      (menu()?.featured
                        ? 'menus.overview.unfeature'
                        : 'menus.overview.feature'
                      ) | transloco
                    }}
                  </button>
                }
              </span>
              @if (menu()?.children?.length > 0) {
                <button
                  mat-menu-item
                  (click)="regenerateMenus.emit()"
                  class="option-button"
                >
                  <mat-icon class="icon">cached</mat-icon>
                  <span>{{
                    'menus.overview.regenerate.title' | transloco
                  }}</span>
                </button>
              }
              @if (menu()?.children?.length > 0) {
                <button
                  mat-menu-item
                  (click)="deleteChildren.emit(menu())"
                  class="option-button"
                  color="warn"
                >
                  <mat-icon class="icon">delete_seep</mat-icon>
                  <span>{{
                    'menus.overview.delete_children' | transloco
                  }}</span>
                </button>
              }
              @if (rulesets()?.length) {
                <hr />
                @for (ruleset of rulesets(); track ruleset) {
                  @if (
                    ruleset.rules_detail?.length > 0 &&
                    (!ruleset.location ||
                      !menu().location ||
                      ruleset.location === menu().location)
                  ) {
                    <button
                      mat-menu-item
                      [matMenuTriggerFor]="menuOptions.childMenu()"
                    >
                      <span [class.italic]="!ruleset[lang]">{{
                        ruleset[lang]
                          ? ruleset[lang]
                          : getAlternativeLanguage(ruleset)
                      }}</span>
                    </button>
                    <locations-menu-context-item
                      #menuOptions
                      [privileges]="privileges()"
                      [lang]="lang"
                      [items]="ruleset?.rules_detail"
                      (createMenu)="
                        createMenu.emit({ id: $event, entity: 'rule' })
                      "
                      (createArchive)="
                        createArchive.emit({
                          rulesetId: ruleset?.id,
                          taskType: 3,
                        })
                      "
                    >
                    </locations-menu-context-item>
                  }
                }
              }
              @if (
                (hasModule('diet') && dietsLocationsMap()[menu().location]) ||
                (consumerTypesByLocation() &&
                  ((!menu().location && consumerTypesByLocation()[0]?.length) ||
                    consumerTypesByLocation()[menu().location]?.length) &&
                  (menu()?.children?.length > 0 || rulesets()?.length > 0))
              ) {
                <hr />
              }
              @if (hasModule('diet') && dietsLocationsMap()[menu().location]) {
                <button mat-menu-item (click)="copyWithDiets.emit(menu())">
                  <mat-icon class="icon">spa</mat-icon>
                  <span>{{ 'menus.overview.diet_menus' | transloco }}</span>
                </button>
              }
              @if (
                consumerTypesByLocation() &&
                ((!menu().location && consumerTypesByLocation()[0]?.length) ||
                  consumerTypesByLocation()[menu().location]?.length)
              ) {
                <button
                  mat-menu-item
                  [matMenuTriggerFor]="menuTypeOptions.childMenu()"
                >
                  <span>{{ 'menus.overview.consumer_types' | transloco }}</span>
                </button>
                <locations-menu-context-item
                  #menuTypeOptions
                  [privileges]="privileges()"
                  [lang]="lang"
                  [items]="consumerTypesByLocation()[menu().location ?? 0]"
                  (createArchive)="createArchive.emit({ taskType: 5 })"
                  (createMenu)="createMenu.emit({ id: $event, entity: 'type' })"
                >
                </locations-menu-context-item>
              }
              @if (isChild()) {
                <button mat-menu-item (click)="refreshSubMenu.emit(menu())">
                  <mat-icon class="icon">cached</mat-icon>
                  <span>{{
                    'menus.overview.refresh_sub_menu.title' | transloco
                  }}</span>
                </button>
              }
              @if (
                rulesets()?.length > 0 ||
                (hasModule('diet') && dietsLocationsMap()[menu().location])
              ) {
                <hr />
              }
              <span
                [matTooltip]="
                  !privileges()?.create_menu ? userStatusMessage() : ''
                "
                matTooltipClass="top-navbar-tooltip"
              >
                <button
                  mat-menu-item
                  stop-propagation
                  stopPropClassName="cdk-overlay-container"
                  [updateParent]="updateParent"
                  (click)="
                    privileges()?.create_menu
                      ? privileges() && copied.emit()
                      : showSnack()
                  "
                  class="option-button"
                  [class.disable-item]="!privileges()?.create_menu"
                >
                  <mat-icon
                    class="icon"
                    [class.disable-item]="!privileges()?.create_menu"
                    >content_copy</mat-icon
                  >
                  <span>{{
                    'menus.overview.duplicate.button' | transloco
                  }}</span>
                </button>
                @if (hasModule('all') || menu()?.date_range_min) {
                  <button
                    mat-menu-item
                    stopPropClassName="cdk-overlay-container"
                    (click)="
                      privileges()?.create_menu
                        ? copyWithOptions.emit(menu())
                        : showSnack()
                    "
                    class="option-button"
                    [class.disable-item]="!privileges()?.create_menu"
                  >
                    <mat-icon
                      class="icon"
                      [class.disable-item]="!privileges()?.create_menu"
                      >content_copy</mat-icon
                    >
                    <span>{{ 'menus.overview.duplicate_as' | transloco }}</span>
                  </button>
                }
              </span>
              @if (
                hasModule('loca') &&
                ((locations()?.length > 0 && !user().location) ||
                  (user().location && locations()?.length > 1))
              ) {
                @if (showShareMenuOption) {
                  <button mat-menu-item (click)="shareMenu.emit(menu())">
                    <mat-icon class="icon">share</mat-icon>
                    <span>{{ 'menus.overview.share_menu' | transloco }}</span>
                  </button>
                }
              }
              <span
                [matTooltip]="
                  !privileges()?.delete_menu
                    ? ('menus.overview.cant_delete' | transloco)
                    : !menu()?.privileges?.delete_menu
                      ? ('menus.overview.privileges.delete' | transloco)
                      : ''
                "
                matTooltipClass="top-navbar-tooltip"
              >
                <button
                  mat-menu-item
                  [disabled]="
                    !privileges().delete_menu ||
                    !menu()?.privileges?.delete_menu
                  "
                  (click)="privileges() && delete.emit()"
                  class="option-button"
                  color="warn"
                >
                  <mat-icon class="icon">delete</mat-icon>
                  <span>{{ 'menus.overview.delete' | transloco }}</span>
                </button>
              </span>
            </ng-template>
          </mat-menu>

          <span
            [matTooltip]="
              !menu()?.privileges?.edit_menu
                ? ('menus.overview.privileges.edit' | transloco)
                : ''
            "
            matTooltipClass="top-navbar-tooltip"
          >
            <button
              mat-icon-button
              [disabled]="!menu()?.privileges?.edit_menu"
              [routerLink]="menu() && ['/menus', menu().id, 4]"
              class="button"
            >
              @if (menu()?.privileges?.edit_menu) {
                <mat-icon class="icon arrow">keyboard_arrow_right</mat-icon>
              }
              @if (!menu()?.privileges?.edit_menu) {
                <mat-icon class="icon lock">lock</mat-icon>
              }
            </button>
          </span>
        </div>
      </mat-expansion-panel-header>
      @if (menu()?.children?.length > 0) {
        @for (child of menu().children; track child; let isLastChild = $last) {
          <menu-overview-item
            [currentMenu]="currentMenu()"
            [dietsLocationsMap]="dietsLocationsMap()"
            [isChild]="true"
            [locations]="locations()"
            [menu]="child"
            [isLastChild]="isLastChild"
            [privileges]="privileges()"
            [user]="user()"
            (createMenu)="createMenu.emit($event)"
            (deleteChildren)="childDeleteChildren.emit(child)"
            (copied)="childCopied.emit(child)"
            (copyWithDiets)="copyWithDiets.emit(child)"
            (copyWithOptions)="copyWithOptions.emit($event)"
            (delete)="childDelete.emit(child)"
            (shareMenu)="shareMenu.emit(child)"
            (refreshSubMenu)="refreshSubMenu.emit(child)"
            (showPreview)="childShowPreview.emit(child)"
          >
          </menu-overview-item>
        }
      }
    </mat-expansion-panel>
  </mat-accordion>
</div>
