import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  Component,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { LocationGroup, SimpleLocation } from 'src/app/shared/Models/location';
import {
  Menu,
  CreatePresetFromMenu,
  MenuAnalysisType,
  MENU_ANALYSIS,
} from 'src/app/shared/Models/menu';
import { AiCreditsRemaining, User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  MatExpansionPanel,
  MatExpansionModule,
} from '@angular/material/expansion';
import {
  blockGrammarCheckLangs,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { SafePipe } from '../../../../shared/Pipes/safe.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MenuViewSettingsComponent } from './menu-view-settings/menu-view-settings.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CurrencySelectorComponent } from '../../../../shared/Components/currency-selector/currency-selector.component';
import { LanguageSelectorComponent } from '../../../../shared/Components/language-selector/language-selector.component';
import { StopPropagationDirective } from '../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MenuAnalysisItemComponent } from './menu-analysis-item/menu-analysis-item.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MagicStickComponent } from '../../../../shared/Components/magic-stick/magic-stick.component';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'menu-actions',
  templateUrl: './menu-actions.component.html',
  styleUrls: ['./menu-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MagicStickComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MenuAnalysisItemComponent,
    MatButtonModule,
    MatIconModule,
    MatExpansionModule,
    MatSelectModule,
    MatOptionModule,
    StopPropagationDirective,
    LanguageSelectorComponent,
    CurrencySelectorComponent,
    MatDatepickerModule,
    FormsModule,
    MenuViewSettingsComponent,
    MatCheckboxModule,
    TourMatMenuModule,
    TranslocoPipe,
    SafePipe,
  ],
})
export class MenuActionsComponent implements OnChanges {
  private utils = inject(UtilsService);

  readonly aiAnalysisLoading = input<boolean>(undefined);
  readonly aiCreditsRemaining = input<AiCreditsRemaining>(undefined);
  readonly isTrial = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly loading = input<boolean>(undefined);
  readonly locations = input<SimpleLocation[]>(undefined);
  readonly locationGroups = input<LocationGroup[]>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly menuwritingVideo = input<string>(undefined);
  readonly privileges = input<any>(undefined);
  readonly profileComplete = input<boolean>(undefined);
  readonly settingsTour = input(undefined);
  readonly translations = input<any>(undefined);
  readonly user = input<User>(undefined);

  readonly changeDate = output<any>();
  readonly changeLocation = output<any>();
  readonly changeLocationGroup = output<any>();
  readonly changeMenuSettings = output<any>();
  readonly grammarCheck = output();
  readonly bulkPriceChange = output<{
    percentage_increase?: number;
    rounding_base?: number;
  }>();
  readonly refreshMenuContent = output<void>();
  readonly updateMenu = output<
    DeepPartial<Menu> & {
      forceUpdateDishes?: boolean;
    }
  >();
  readonly presetActionEvent = output<{
    preset: CreatePresetFromMenu;
    id: number;
    existing: boolean;
  }>();
  readonly showMenuAnalysis = output<MenuAnalysisType>();
  readonly startTour = output<void>();
  readonly dataExport = output<void>();
  readonly dataImport = output<void>();

  blockGrammarCheckLangs = blockGrammarCheckLangs;
  menuDate: Date;
  menuAnalyses = MENU_ANALYSIS;
  bulkPriceForm = new FormGroup({
    percentage_increase: new FormControl(0, {
      validators: [
        Validators.min(-1000),
        Validators.max(1000),
        Validators.required,
      ],
    }),
    rounding_base: new FormControl(0.01, {
      validators: [
        Validators.min(0.01),
        Validators.max(1000),
        Validators.required,
      ],
    }),
  });

  readonly menuSettings = viewChild<MatExpansionPanel>('menuSettings');

  ngOnChanges(changes: SimpleChanges) {
    const menu = this.menu();
    if ('menu' in changes && menu) {
      this.menuDate = new Date(menu.date);
    }
  }

  changeNumbering({ checked }: { checked: boolean }): void {
    this.updateMenu.emit({
      style: {
        print_customnumbering: checked,
        print_numbering: checked,
      },
    });
  }

  presetAction(hasPreset: boolean): void {
    const menuLanguage = this.menu().base_language;
    const menu = this.menu();
    const menuName = menu.name
      ? menu.name
      : menu.onboarding_template_detail[this.lang()];
    const preset = {
      apply_template: true,
      exact: false,
      [menuLanguage]: menuName,
    };
    this.presetActionEvent.emit({
      preset: preset,
      id: this.menu().id,
      existing: hasPreset,
    });
  }

  hasModules(code: string): boolean {
    return this.utils.hasModules(code);
  }

  showTrialBanner() {
    this.utils.showTrialBlockedBox();
  }
}
