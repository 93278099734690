import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import {
  loginWithMagicLink,
  setMagicLink,
  setNextPage,
} from 'src/app/auth/ngrx/auth.actions';
import { selectLoginState } from 'src/app/auth/ngrx/auth.selectors';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';

export const loginGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const ngrxStore = inject(Store);

  const login$ = ngrxStore.select(selectLoginState);

  if (route.queryParams?.key) {
    ngrxStore.dispatch(setMagicLink({ isMagicLink: true }));
    ngrxStore.dispatch(
      loginWithMagicLink({ token: route.queryParams?.key as string }),
    );
  }

  return login$.pipe(
    map((isLogged) => {
      if (!isLogged && state.url !== '/login') {
        ngrxStore.dispatch(setNextPage({ nextPage: state.url }));
        router.navigate(['/login'], { queryParams: route.queryParams });
      }
      return isLogged;
    }),
  );
};
