import { Component, OnChanges, SimpleChanges, input } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  Status,
  SubscriptionExtension,
  User,
} from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { UserStatusProgressComponent } from '../user-status-progress/user-status-progress.component';
@Component({
  selector: 'plan-limit',
  templateUrl: `plan-limit.component.html`,
  styleUrls: [
    `../status-block/status-block.component.scss`,
    `./plan-limit.component.scss`,
  ],
  imports: [
    UserStatusProgressComponent,
    MtTooltipDirective,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    DatePipe,
    TranslocoPipe,
  ],
})
export class PlanLimitComponent implements OnChanges {
  readonly extensions = input<SubscriptionExtension[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly status = input<Status>(undefined);
  readonly user = input<User>(undefined);

  onInactive: string;

  ngOnChanges(changes: SimpleChanges): void {
    const status = this.status();
    if ('status' in changes && status && status.inactive_on) {
      this.onInactive = formatDate(
        new Date(status.inactive_on),
        'mediumDate',
        this.lang(),
      );
    }
  }
}
