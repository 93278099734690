import {
  Component,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  SubscriptSizing,
  MatFormFieldModule,
} from '@angular/material/form-field';
import {
  ContentLanguage,
  interfaceLangs,
  langs,
} from 'src/app/shared/constants/languages';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-translatable-field',
  templateUrl: './translatable-field.component.html',
  styleUrls: ['./translatable-field.component.scss'],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    MatProgressBarModule,
    MatButtonModule,
    MatMenuModule,
    TranslocoPipe,
  ],
})
export class TranslatableFieldComponent implements OnChanges, OnDestroy {
  private fb = inject(FormBuilder);

  readonly allLangs = input(false);
  readonly field = input<string>(undefined);
  readonly isTextarea = input<boolean>(undefined);
  readonly initialLang = input<ContentLanguage>(undefined);
  readonly item = input<any>(undefined);
  readonly loadingBars = input<Record<string, boolean>>(undefined);
  readonly subscriptSizing = input<SubscriptSizing>('fixed');
  readonly fieldChanged = output<{
    item: any;
    data: any;
  }>();

  existingLangs = new Set<string>();
  interfaceLangs = interfaceLangs;
  contentLangs = langs;
  unusedLangs = new Set<string>();
  form: FormGroup;
  private destroyed$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges) {
    const item = this.item();
    if (
      'item' in changes &&
      item &&
      (item.id !== changes.item.previousValue?.id || !item.id)
    ) {
      this.existingLangs = new Set();
      this.unusedLangs = new Set();
      this.filterUnusedLangs();
      this.buildForm();
    }
    if (!this.form) {
      this.buildForm();
    }
  }

  buildForm(): void {
    this.form = this.fb.group({});
    this.existingLangs.forEach((lang) => {
      const field = this.field();
      this.form.addControl(
        lang,
        new FormControl(this.item()[field ? field + '_' + lang : lang] || ''),
      );
    });
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(400),
        takeUntil(this.destroyed$),
      )
      .subscribe((data) => this.onValueChanged(data));
  }

  filterUnusedLangs() {
    (this.allLangs() ? this.contentLangs : this.interfaceLangs).forEach(
      (lang) => {
        const field = this.field();
        if (!this.item()[field ? `${field}_${lang}` : lang]) {
          this.unusedLangs.add(lang);
        } else {
          this.existingLangs.add(lang);
        }
      },
    );
    const initialLang = this.initialLang();
    if (initialLang && !this.existingLangs.has(initialLang)) {
      this.existingLangs.add(initialLang);
    }
  }

  onValueChanged(data: any): void {
    const newData = {};
    Object.keys(data).forEach((key: string) => {
      const field = this.field();
      newData[field ? field + '_' + key : key] = data[key];
    });
    this.fieldChanged.emit({ item: this.item(), data: newData });
  }

  addLang(lang: string): void {
    this.unusedLangs.delete(lang);
    this.existingLangs.add(lang);
    const field = this.field();
    this.form.addControl(
      lang,
      this.fb.control(this.item()[field ? field + '_' + lang : lang] || ''),
      { emitEvent: false },
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
