<div class="copy-column">
  <div class="allergens-wrapper">
    <h3 class="copy-header">
      {{ 'write.blocks.modal.copy.title-' + type() | transloco }}
    </h3>
    @for (similarDish of similarDishes(); track similarDish.id) {
      <app-similar-dish-declarations
        [dish]="similarDish"
        [lang]="contentLang() || lang()"
        [type]="type()"
        (copyDeclarations)="
          copyDeclarations.emit({
            source: $event,
            target: dish(),
            type: type() === 'allergens' ? 'all' : 'add',
          })
        "
      >
      </app-similar-dish-declarations>
    }
  </div>
  @if (allDishesNumber() - similarDishes().length > 0) {
    <button
      mat-flat-button
      class="more-similar-options"
      color="primary"
      (click)="loadMoreDishes.emit(type())"
    >
      {{
        'write.blocks.modal.copy.button-' + type()
          | transloco
            : {
                rest: allDishesNumber() - similarDishes().length,
              }
      }}
    </button>
  }
</div>

<div class="options-column">
  <options
    [dish]="dish()"
    [lang]="lang()"
    [type]="type()"
    (changed)="addOption.emit($event)"
  >
  </options>
</div>
