export class Partner {
  code: string;
  icon: string;
  title: string;
  class: string;
  topOffset = 0;
  logo?: PartnerLogo;
  hideModulesLink = false;

  constructor(initWith: any) {
    Object.assign(this, initWith);
  }
}
export interface PartnerLogo {
  alt: string;
  src: string;
}
export const NO_PARTNER = new Partner({
  code: null,
  icon: 'favicon.ico',
  title: 'Menutech App',
  class: null,
  logo: {
    alt: 'menutech logo',
    src: 'assets/img/logo_dark.svg',
  },
});
export const PARTNERS: Partner[] = [
  new Partner({
    code: 'msm',
    icon: 'favicon-msm.icon',
    title:
      'Rentabilibar: plataforma online para rentabilizar tu bar o restaurante',
    class: 'msm-theme',
    topOffset: 57,
    logo: {
      alt: 'menutech logo',
      src: 'assets/img/logos/mahou-rentabilibar.svg',
    },
    hideModulesLink: true,
  }),
];
