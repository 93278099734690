import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  Ingredient,
  PreIngredients,
  SimpleIngredient,
} from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { IngredientsTabComponent } from './ingredients-tab/ingredients-tab.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SimpleDialogComponent } from '../../dialogs/simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface RecipeItemForm {
  name: string;
  servings: number;
}

@Component({
  selector: 'recipe-item',
  templateUrl: './recipe-item.component.html',
  styleUrls: ['./recipe-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    IngredientsTabComponent,
    TranslocoPipe,
  ],
})
export class RecipeItemComponent implements OnChanges, OnDestroy, OnInit {
  private dialog = inject(MatDialog);
  private fb = inject(FormBuilder);
  private transloco = inject(TranslocoService);
  private router = inject(Router);
  private utils = inject(UtilsService);

  readonly ingredientsAuto = input<SimpleIngredient[]>(undefined);
  readonly ingredientInfo = input<RecipeIngredient>(undefined);
  readonly recipe = input<Recipe>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly dish = input<Dish>(undefined);
  readonly translations = input<any>(undefined);

  readonly addIngredientToRecipe = output<{
    ingredient: SimpleIngredient;
    recipe: Recipe;
    onFulfilled: () => void;
  }>();
  readonly clearIngredientsAuto = output<void>();
  readonly clearIngredientInfo = output<void>();
  readonly collapsed = output<void>();
  readonly addMultipleIngredientsToRecipeEvent = output<{
    recipe: Recipe;
    data: PreIngredients;
  }>();
  readonly createNewIngredientEvent = output<{
    recipe: Recipe;
    data: Partial<Recipe>;
    onError?: () => void;
    params?: RecipeParams;
  }>();
  readonly deleteDishRecipeIngredientEvent = output<{
    recipe: Recipe;
    recipeIngredient: SimpleRecipeIngredient;
  }>();
  readonly fetchIngredientsAuto = output<{
    search: string;
    recipeId: number;
  }>();
  readonly fetchIngredientsInfo = output<{
    url: string;
    params: RecipeParams;
  }>();
  readonly refreshDish = output<void>();
  readonly removeRecipeEvent = output<Recipe>();
  readonly patchIngredientEvent = output<{
    url: string;
    data: Partial<Ingredient>;
    params: RecipeParams;
  }>();
  readonly patchRecipe = output<{
    url: string;
    payload: Partial<Recipe>;
    onFulfilled: () => void;
  }>();

  delete = false;
  private destroyed$ = new Subject<void>();
  recipeForm: FormGroup<{
    name: FormControl<string>;
    servings: FormControl<number>;
  }>;
  staticName: { name: string; italic: boolean };
  loadingBars: Partial<RecipeItemForm> = {};
  initialFormValue: Partial<Recipe>;
  currentRecipe: Recipe;

  ngOnInit(): void {
    this.buildForm();
    this.recipeForm.valueChanges
      .pipe(
        tap(() => (this.currentRecipe = this.recipe())),
        debounceTime(800),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((data: RecipeItemForm) => this.onValueChanged(data));
    this.initialFormValue = this.recipeForm.value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const recipe = this.recipe();
    if ('recipe' in changes && recipe) {
      this.delete = false;
      this.staticName = this.utils.tryGetLabel(recipe, this.lang());
      if (this.recipeForm) {
        this.recipeForm.patchValue(
          {
            name: this.staticName.name,
            servings: recipe.servings,
          },
          { emitEvent: false },
        );
      }
    }
  }

  blurInput = (target: EventTarget): void => (target as HTMLElement).blur();

  buildForm(): void {
    this.recipeForm = this.fb.group({
      name: [this.recipe()[this.lang()], [Validators.required]],
      servings: [
        this.recipe().servings,
        [Validators.required, Validators.min(1)],
      ],
    });
  }

  navigateToRecipe(): void {
    this.router.navigate(['/recipes', this.recipe().id]);
  }

  removeRecipe(): void {
    this.delete = true;
    this.removeRecipeEvent.emit(this.recipe());
  }

  onValueChanged(data: RecipeItemForm): void {
    const newData = {};
    const loading = [];
    Object.keys(this.recipeForm.controls)
      .filter((key) => key in data && this.initialFormValue[key] !== data[key])
      .forEach((key: string) => {
        newData[key] = data[key];
        loading.push(...this.setLoadingBar(key, true));
      });
    this.initialFormValue = data;
    if (Object.keys(newData).length) this.patchFields(newData, loading);
  }

  patchFields(
    data: DeepPartial<Recipe>,
    clearableLoaders: string[] = [],
  ): void {
    const payload = {};
    if (data.name) payload[this.lang()] = data.name;
    if (data.servings) payload['servings'] = data.servings;

    const recipe = this.recipe();
    if (
      data.servings &&
      recipe.servings !== data.servings &&
      recipe?.ingredients_list?.length > 0
    ) {
      this.recipeForm.controls.servings.disable({ emitEvent: false });
      const dialogRef = this.dialog.open(SimpleDialogComponent, {
        disableClose: true,
        data: {
          title: this.transloco.translate(
            'recipes.content.servings-dialog.title',
          ),
          text: this.transloco.translate(
            'recipes.content.servings-dialog.text',
          ),
          confirmText: 'shared.buttons.update',
          cancelText: 'shared.buttons.dont-update',
          cancelable: true,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.recipeForm.controls.servings.enable({ emitEvent: false });
        if (result === true) {
          payload['update_quantities'] = true;
        }
        this.patchRecipe.emit({
          url: this.recipe().url,
          payload,
          onFulfilled: () => {
            clearableLoaders.forEach((key) => (this.loadingBars[key] = false));
          },
        });
        return [];
      });
    } else {
      this.patchRecipe.emit({
        url: recipe.url,
        payload,
        onFulfilled: () => {
          clearableLoaders.forEach((key) => (this.loadingBars[key] = false));
        },
      });
    }
  }

  setLoadingBar(field: string, value: boolean): string[] {
    const loading = [];
    const loadingField = field;
    const showLoading = this.currentRecipe.id === this.recipe().id;
    if (showLoading) this.loadingBars[loadingField] = value;
    if (showLoading) loading.push(loadingField);
    return loading;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
