<div [formGroup]="formGroup()">
  <mat-form-field>
    @if (translations()) {
      <mat-label>{{
        translations()['write.blocks.dish-overview.information.origin'] ||
          ('write.blocks.dish-overview.information.origin' | transloco)
      }}</mat-label>
    }
    <input matInput type="text" name="origin" formControlName="origin" />
    @if (loadingBars()['origin']) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>

  <mat-form-field [class.hidden]="!formGroup().controls.origin.value" dir="ltr">
    <mat-label>{{
      'write.blocks.dish-overview.information.produce.placeholder' | transloco
    }}</mat-label>
    <mat-select class="capitalize" formControlName="produce_method">
      @for (option of options; track option) {
        <mat-option stop-propagation class="capitalize" [value]="option">{{
          'write.blocks.dish-overview.information.produce.' + (option ?? 'none')
            | transloco
        }}</mat-option>
      }
    </mat-select>
    @if (loadingBars()['produce_method']) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>
</div>
