import { ViewportScroller } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  NgZone,
  OnDestroy,
  Renderer2,
  inject,
  output,
  input,
} from '@angular/core';
import { DnDData } from 'src/app/menus/tree-manager.service';
import { MenuDishNode } from 'src/app/shared/Models/menu-dish-node';
import {
  MenuDish,
  MenuDishLevel,
  SimpleMenuDish,
} from 'src/app/shared/Models/menudish';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { Categories } from '../../constants/categories';

export enum Place {
  ABOVE = 'ABOVE',
  BELOW = 'BELOW',
  INSIDE = 'INSIDE',
  BOTTOM = 'BOTTOM',
}

export enum TypeOfDnD {
  secToSec = 'secToSec',
  secToDow = 'secToDow',
  secToDis = 'secToDis',
  dowToDow = 'dowToDow',
  dowToSec = 'dowToSec',
  dowToDis = 'dowToDis',
  disToDis = 'disToDis',
  disToSec = 'disToSec',
  disToDow = 'disToDow',
  bottom = 'bottom',
}

export class Restrictions {
  static SECTION_INTO_SECTION = [1, 2];
  static SECTION_INTO_DAY = [1];
  static DISH_INTO_SECTION = [1, 2, 3];
  static DISH_INTO_DAY = [1];
}

export interface Placement {
  place: Place;
  numberOfDishes: number;
  newLevel: MenuDishLevel;
  newIndex: number;
  typeOfDnD: TypeOfDnD;
}

@Directive({
  selector: '[DndSort]',
  standalone: true,
})
export class DndSortDirective implements OnDestroy {
  private element = inject(ElementRef);
  private ngZone = inject(NgZone);
  private renderer2 = inject(Renderer2);
  private scroller = inject(ViewportScroller);

  autoScroll: ReturnType<typeof setTimeout>;
  error = new Error(
    'There is no data property: data-index, please check if you added this attribute to the DOM Element',
  );
  draggable: string;
  section = false;
  isChild = false;
  enter = 0;
  leave = 0;
  lastParent: HTMLElement;
  dragElement: HTMLElement;
  classToIgnore = 'bottom-drop';
  bottomDropIndicator = 'bottom-drop-indicator';
  handlerClass = 'drag';
  onDragOverEmitter = new EventEmitter<DragEvent>();
  elementsToClean = new Set<HTMLElement>();

  private destroyed$ = new Subject<void>();

  readonly data = input<(SimpleMenuDish | MenuDish)[]>(undefined);
  readonly nodeData = input<MenuDishNode[]>(undefined);

  readonly changed = output<{
    dishes: (SimpleMenuDish | MenuDish)[];
    sendRequest: boolean;
    updateScroll: boolean;
  }>();
  readonly treeDnD = output<DnDData>();
  readonly updated = output<(SimpleMenuDish | MenuDish)[]>();

  constructor() {
    this.element.nativeElement.addEventListener('dnd-sort-drop', this.onDrop);
    this.onDragOverEmitter
      .pipe(
        distinctUntilChanged((x: any, y: any) => x.clientY === y.clientY),
        switchMap((v) => of(v)),
        takeUntil(this.destroyed$),
      )
      .subscribe((event: DragEvent) => this._onDragOver(event));
    this.ngZone.runOutsideAngular(() => {
      const header = document.querySelector('.header');
      this.renderer2.listen(header, `dragenter`, () =>
        this.onHeaderScrollStart(),
      );
      this.renderer2.listen(header, `dragleave`, () =>
        this.onHeaderScrollStop(),
      );
      this.renderer2.listen(
        this.element.nativeElement,
        `dragover`,
        (event: DragEvent) => this.onDragOver(event),
      );
      this.renderer2.listen(
        this.element.nativeElement,
        `dragstart`,
        (event: DragEvent) => this.onDragStart(event),
      );
      this.renderer2.listen(
        this.element.nativeElement,
        `dragleave`,
        (event: DragEvent) => this.onDragLeave(event),
      );
      this.renderer2.listen(this.element.nativeElement, `dragend`, () =>
        this.onDragEnd(),
      );
    });
  }

  onHeaderScrollStart(): void {
    if (this.autoScroll) return;
    this.autoScroll = setInterval(() => {
      this.scroller.scrollToPosition([
        0,
        document.documentElement.scrollTop - 1,
      ]);
    }, 1);
  }

  onHeaderScrollStop(): void {
    if (this.autoScroll) clearInterval(this.autoScroll);
    this.autoScroll = undefined;
  }

  // @HostListener("dragstart", ["$event"])
  onDragStart(event: DragEvent) {
    const target = event.target as HTMLElement;
    event.stopPropagation();
    window.dispatchEvent(new Event('click'));
    this.dragElement = target.classList?.contains(this.handlerClass)
      ? target.parentElement.parentElement
      : target;
    if (
      this.dragElement.classList &&
      this.dragElement.classList.contains(this.classToIgnore)
    ) {
      this.dragElement = null;
      return undefined;
    }
    const { dataset } = this.dragElement;
    if (!dataset.id) {
      event.preventDefault();
      event.stopPropagation();
      return undefined;
    }
    if (!dataset.index) {
      throw this.error;
    }
    event.dataTransfer.setData('dragElement', dataset.index);
    event.dataTransfer.setData('dragElementIndex', dataset.nodeindex);
    event.dataTransfer.setData('DnDIndex', dataset.dndindex);
    this.draggable = dataset.index;
    this.section = !!dataset.section;
    this.isChild = dataset.child === 'true';
  }

  // @HostListener("dragover", ["$event"])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    if (!this.dragElement) return undefined;
    this.onDragOverEmitter.emit(event);
    event.preventDefault();
  }

  _onDragOver(event: DragEvent) {
    const target = event.target as HTMLElement;
    if (target.classList && target.classList.contains(this.classToIgnore)) {
      target.classList.add(this.bottomDropIndicator);
      event.preventDefault();
    } else {
      const parent =
        target.classList && target.classList.contains('items')
          ? target
          : this.getParent(target, 'items');
      this.lastParent = parent;
      if (!parent || parent.dataset.index === this.dragElement.dataset.index) {
        return undefined;
      }
      const placement = this.calculatePlacement(
        parent,
        this.dragElement,
        event.clientY,
      );
      if (!placement) {
        this.elementsToClean.add(parent);
        parent.classList.add('not-allowed');
        return undefined;
      }
      if (parent.dataset.index !== this.draggable) {
        this.removeClasses(parent);
        switch (placement.place) {
          case Place.BELOW:
            parent.classList.add('drop-below');
            this.elementsToClean.add(parent);
            if (placement.newLevel === 1) parent.classList.add('drop-expand');
            break;
          case Place.ABOVE:
            parent.classList.add('drop-above');
            this.elementsToClean.add(parent);
            if (placement.newLevel === 1) parent.classList.add('drop-expand');
            break;
          case Place.INSIDE:
            parent.classList.add('drop-inside');
            this.elementsToClean.add(parent);
            break;
        }
      }
      event.preventDefault();
    }
  }

  // @HostListener("dragleave", ["$event"])
  onDragLeave(event: DragEvent) {
    const target = event.target as HTMLElement;
    if (target.classList && target.classList.contains(this.classToIgnore)) {
      target.classList.remove(this.bottomDropIndicator);
    } else {
      const parent = this.getParent(target, 'items');
      this.lastParent = parent;
      this.removeClasses(parent || target);
    }
  }

  // @HostListener("dragend")
  onDragEnd() {
    this.onHeaderScrollStop();
    this.elementsToClean.forEach((el) => this.removeClasses(el));
    this.elementsToClean.clear();
  }

  removeClasses(element) {
    if (!element) return undefined;
    element.classList.remove('drop-above');
    element.classList.remove('drop-below');
    element.classList.remove('drop-inside');
    element.classList.remove('not-allowed');
    element.classList.remove(this.bottomDropIndicator);
  }

  onDrop = (event: CustomEvent) => {
    if (!event.detail) return undefined;
    const {
      dragItemIndex,
      event: ev,
      isBottomDrop,
      dragItemDnDIndex,
      dropItemDnDIndex,
    } = event.detail;
    const nodeData = this.nodeData();
    if (isNaN(dragItemIndex) || !nodeData) return undefined;
    const dragNode = nodeData[dragItemDnDIndex];
    const dropNode = nodeData[dropItemDnDIndex];
    if ((!isBottomDrop && (!dropNode || !dropNode.dish.id)) || !dragNode)
      return undefined;
    const parent = this.getParent(ev.target, 'items');
    const placement = isBottomDrop
      ? {
          place: null,
          newIndex: this.data().length,
          newLevel: 1 as const,
          numberOfDishes: dragNode.getChildrenLength() + 1,
          typeOfDnD: TypeOfDnD.bottom,
        }
      : this.calculatePlacement(parent, this.dragElement, ev.clientY);
    if (!placement) return undefined;
    const { newIndex, newLevel, numberOfDishes } = placement;

    let newDishes = this.data().slice(0, this.data().length);
    const levelDiff: -3 | -2 | -1 | 0 | 1 | 2 | 3 = (newDishes[dragItemIndex]
      .level - newLevel) as -3 | -2 | -1 | 0 | 1 | 2 | 3;
    if (![-3, -2, -1, 0, 1, 2, 3].includes(levelDiff)) {
      throw new Error(
        `TREE ERROR: Wrong level difference when sorting onDrop: ${levelDiff}`,
      );
    }
    const itemsToMove = newDishes
      .slice(dragItemIndex, dragItemIndex + numberOfDishes)
      .map((item) => {
        const level = item.level - levelDiff;
        return {
          ...item,
          level,
          ...(item.dish_detail
            ? {
                dish_detail: {
                  ...item.dish_detail,
                  level,
                },
              }
            : {
                separator_detail: {
                  ...item.separator_detail,
                  level,
                },
              }),
        } as MenuDish;
      });
    for (
      let index = dragItemIndex;
      index < dragItemIndex + numberOfDishes;
      index++
    ) {
      newDishes[index] = null;
    }
    newDishes.splice(newIndex, 0, ...itemsToMove);
    newDishes = newDishes.filter((nd) => nd);
    const result = {
      dishes: newDishes,
      sendRequest: true,
      updateScroll: false,
    };
    if (!dragNode.dish.url) {
      result.sendRequest = false;
    }
    if (placement.place === Place.INSIDE) {
      result.updateScroll = true;
    }
    this.treeDnD.emit({
      nodeToMove: dragNode,
      targetNode: dropNode,
      place: isBottomDrop ? Place.BOTTOM : placement.place,
      typeOfDnD: placement.typeOfDnD,
    });
    this.changed.emit(result);
  };

  getParent(element: HTMLElement, className: string): HTMLElement {
    if (!element) return undefined;
    if (element.classList && element.classList.contains(className))
      return element;
    return this.getParent(element.parentElement, className);
  }

  calculatePlacement(
    dropElement: HTMLElement,
    dragElement: HTMLElement,
    mouseY: number,
    onlyPlace = false,
  ): Placement {
    const nodeData = this.nodeData();
    if (!nodeData) return undefined;
    const { dataset: dropData } = dropElement;
    const { dataset: dragData } = dragElement;

    const dragNode: MenuDishNode = nodeData[dragData.dndindex];
    const dropNode: MenuDishNode = nodeData[dropData.dndindex];
    if (!dropNode || !dropNode.dish.id) return undefined;

    const funcName = `${this.getPartialName(
      dragData.category,
    ).toLowerCase()}To${this.getPartialName(dropData.category)}`;

    const place = this[funcName](
      dropElement,
      dragElement,
      mouseY,
      dragData.dndindex,
      dropData.dndindex,
    );

    if (onlyPlace) return place;

    if (
      !place ||
      !dragNode ||
      !dropNode ||
      (place === Place.BELOW && dropNode.isExpanded)
    )
      return undefined;
    switch (place) {
      case Place.BELOW:
        return {
          place,
          newIndex: dropNode.realIndex + dropNode.getChildrenLength() + 1,
          newLevel: dropNode.level,
          numberOfDishes: dragNode ? dragNode.getChildrenLength() + 1 : 1,
          typeOfDnD: TypeOfDnD[funcName],
        };
      case Place.ABOVE:
        return {
          place,
          newIndex: dropNode.realIndex,
          newLevel: dropNode.level,
          numberOfDishes: dragNode.getChildrenLength() + 1,
          typeOfDnD: TypeOfDnD[funcName],
        };
      case Place.INSIDE:
        if (!dropNode.dish.id) return undefined;
        return {
          place,
          newIndex: dropNode.realIndex + dropNode.getChildrenLength() + 1,
          newLevel: (dropNode.level + 1) as MenuDishLevel,
          numberOfDishes: dragNode ? dragNode.getChildrenLength() + 1 : 1,
          typeOfDnD: TypeOfDnD[funcName],
        };
      default:
        return undefined;
    }
  }

  findClosestContainer(
    itemIndex: number,
    itemLevel: number,
  ): { dish: MenuDish; index: number } {
    let mdish;
    const containerLevel = itemLevel - 1;
    if (itemLevel === 1) return undefined;
    while ((mdish = this.data()[--itemIndex])) {
      if (mdish.level === containerLevel) {
        return { dish: mdish, index: itemIndex };
      } else if (mdish.level < containerLevel) {
        return undefined;
      }
    }
    return undefined;
  }

  secToSec(
    dropElement: HTMLElement,
    dragElement: HTMLElement,
    mouseY: number,
    dragDnDIndex: number,
    dropDnDIndex: number,
  ): Place {
    const { dragLevel, dropLevel, dragIndex, dropIndex } = this.extractData(
      dropElement,
      dragElement,
    );

    const { dish, index } = this.findClosestContainer(dragIndex, dragLevel) || {
      dish: null,
      index: null,
    };

    const dragNode = this.nodeData()[dragDnDIndex];
    const dropNode = this.nodeData()[dropDnDIndex];
    if (!dragNode || dropNode.hasParentWithId(dragNode.dish.id)) {
      return undefined;
    }

    const place = Restrictions.SECTION_INTO_SECTION.includes(dropLevel)
      ? this.calculatePosition(dropElement, mouseY, dragIndex, dropIndex, true)
      : this.calculateSecToDis(dropElement, mouseY);
    const maxLevel = dragNode.getMaxChildLevel(dragIndex);
    switch (place) {
      case Place.ABOVE:
      case Place.BELOW:
        if (
          maxLevel.level - dragNode.level + dropLevel > 4 ||
          (dropLevel === 3 && dragNode.hasFoldedChildren())
        ) {
          return undefined;
        }
        break;
      case Place.INSIDE:
        if (
          (dish && index === dropIndex) ||
          (maxLevel.level === 3 && maxLevel.isSection) ||
          maxLevel.level === 4
        ) {
          return undefined;
        }
        if (
          (dropLevel >= 2 && dragNode.hasFoldedChildren()) ||
          (maxLevel.level > 3 && maxLevel.isSection)
        ) {
          return undefined;
        }
        break;
    }

    return place;
  }

  secToDow(
    dropElement: HTMLElement,
    dragElement: HTMLElement,
    mouseY: number,
    dragDnDIndex: number,
  ) {
    const { dragLevel, dropLevel, dragIndex, dropIndex } = this.extractData(
      dropElement,
      dragElement,
    );

    const { dish, index } = this.findClosestContainer(dragIndex, dragLevel) || {
      dish: null,
      index: null,
    };

    const place = Restrictions.SECTION_INTO_DAY.includes(dropLevel)
      ? this.calculatePosition(dropElement, mouseY, dragIndex, dropIndex, true)
      : dragIndex > dropIndex
        ? Place.ABOVE
        : Place.BELOW;

    if (place === Place.INSIDE) {
      const node = this.nodeData()[dragDnDIndex];
      if (!node) return undefined;
      const maxLevel = node.getMaxChildLevel(dragIndex);
      if (
        (dish && index === dropIndex) ||
        (maxLevel.level === 3 && maxLevel.isSection) ||
        maxLevel.level === 4
      )
        return undefined;
    }

    return place;
  }

  secToDis(
    dropElement: HTMLElement,
    dragElement: HTMLElement,
    mouseY: number,
    dragDnDIndex: number,
    dropDnDIndex: number,
  ) {
    const { dragIndex, dropLevel } = this.extractData(dropElement, dragElement);
    if (dropLevel > 1) {
      const dragNode = this.nodeData()[dragDnDIndex];
      const dropNode = this.nodeData()[dropDnDIndex];
      if (!dragNode || dropNode.hasParentWithId(dragNode.dish.id))
        return undefined;
      const maxLevel = dragNode.getMaxChildLevel(dragIndex);
      maxLevel.level += dropLevel - 1;
      if (maxLevel.level > 4 || (maxLevel.level === 4 && maxLevel.isSection))
        return undefined;
    }
    return this.calculateSecToDis(dropElement, mouseY);
  }

  dowToDow(dropElement: HTMLElement, dragElement: HTMLElement) {
    const { dragIndex, dropIndex } = this.extractData(dropElement, dragElement);
    return dragIndex > dropIndex ? Place.ABOVE : Place.BELOW;
  }

  dowToSec(dropElement: HTMLElement, dragElement: HTMLElement) {
    const { dropLevel, dragIndex, dropIndex } = this.extractData(
      dropElement,
      dragElement,
    );

    if (dropLevel > 1) return undefined;
    return dragIndex > dropIndex ? Place.ABOVE : Place.BELOW;
  }

  dowToDis(dropElement: HTMLElement, dragElement: HTMLElement) {
    const { dropLevel, dragIndex, dropIndex } = this.extractData(
      dropElement,
      dragElement,
    );

    if (dropLevel > 1) return undefined;
    return dragIndex > dropIndex ? Place.ABOVE : Place.BELOW;
  }

  disToDis(dropElement: HTMLElement, dragElement: HTMLElement, mouseY: number) {
    const { dragIndex, dropIndex, dragLevel, dropLevel } = this.extractData(
      dropElement,
      dragElement,
    );
    return dragLevel !== dropLevel
      ? this.calculateSecToDis(dropElement, mouseY)
      : dragIndex > dropIndex
        ? Place.ABOVE
        : Place.BELOW;
  }

  disToSec(dropElement: HTMLElement, dragElement: HTMLElement, mouseY: number) {
    const { dragLevel, dropLevel, dragIndex, dropIndex } = this.extractData(
      dropElement,
      dragElement,
    );

    const { dish, index } = this.findClosestContainer(dragIndex, dragLevel) || {
      dish: null,
      index: null,
    };

    const place = Restrictions.DISH_INTO_SECTION.includes(dropLevel)
      ? this.calculatePosition(dropElement, mouseY, dragIndex, dropIndex, true)
      : dragIndex > dropIndex
        ? Place.ABOVE
        : Place.BELOW;

    if (place === Place.INSIDE) {
      if (dish && index === dropIndex) return undefined;
    }

    return place;
  }

  disToDow(dropElement: HTMLElement, dragElement: HTMLElement, mouseY: number) {
    const { dragLevel, dropLevel, dragIndex, dropIndex } = this.extractData(
      dropElement,
      dragElement,
    );

    const { dish, index } = this.findClosestContainer(dragIndex, dragLevel) || {
      dish: null,
      index: null,
    };

    const place = Restrictions.DISH_INTO_DAY.includes(dropLevel)
      ? this.calculatePosition(dropElement, mouseY, dragIndex, dropIndex, true)
      : dragIndex > dropIndex
        ? Place.ABOVE
        : Place.BELOW;

    if (place === Place.INSIDE) {
      if (dish && index === dropIndex) return undefined;
    }

    return place;
  }

  calculatePosition(
    elem,
    mouseY,
    dragIndex,
    dropIndex,
    canGoAbove = false,
  ): Place {
    const { top, height } = elem.getBoundingClientRect();
    const pos = (mouseY - top) / height;
    if (dragIndex > dropIndex && !canGoAbove) {
      return pos <= 0.4 ? Place.ABOVE : Place.INSIDE;
    } else if (canGoAbove) {
      return pos >= 0.66
        ? Place.BELOW
        : pos >= 0.33
          ? Place.INSIDE
          : Place.ABOVE;
    } else {
      return pos >= 0.6 ? Place.BELOW : Place.INSIDE;
    }
  }

  calculateSecToDis(elem, mouseY): Place {
    const { top, height } = elem.getBoundingClientRect();
    const pos = (mouseY - top) / height;
    return pos <= 0.5 ? Place.ABOVE : Place.BELOW;
  }

  getPartialName(category: string) {
    if (category === Categories.SECTION || category === Categories.DAY) {
      return `${category.slice(0, 1).toUpperCase()}${category.slice(
        1,
        category.length,
      )}`;
    }
    return 'Dis';
  }

  extractData(dropElement: HTMLElement, dragElement: HTMLElement) {
    const dropIndex = parseInt(dropElement.dataset.index);
    const dragIndex = parseInt(dragElement.dataset.index);

    const dropLevel = parseInt(dropElement.dataset.level);
    const dragLevel = parseInt(dragElement.dataset.level);

    return {
      dropIndex,
      dragIndex,
      dropLevel,
      dragLevel,
    };
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
