import { Component, output, viewChild, input } from '@angular/core';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { EmailPreferences } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'email-preferences',
  templateUrl: './email-preferences.component.html',
  styleUrls: ['./email-preferences.component.scss'],
  imports: [MatCheckboxModule, MatButtonModule, RouterLink, TranslocoPipe],
})
export class EmailPreferencesComponent {
  readonly email = input<string>(undefined);
  readonly error = input<string>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly updateSettings = output<EmailPreferences>();

  readonly welcome = viewChild<MatCheckbox>('welcome');
  readonly news = viewChild<MatCheckbox>('news');
  readonly all = viewChild<MatCheckbox>('all');

  submit() {
    this.updateSettings.emit(
      this.all().checked
        ? {
            onboarding: false,
            newsletter: false,
          }
        : {
            onboarding: !!this.welcome().checked,
            newsletter: !!this.news().checked,
          },
    );
  }
}
