import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { User } from 'src/app/shared/Models/user';
import {
  EventBusService,
  SharedEventTypes,
} from 'src/app/shared/Services/event-bus/event-bus.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subscription } from 'rxjs';
import { WriteNewMenuComponent } from '../write-new-menu/write-new-menu.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButtonModule,
    MatIconModule,
    WriteNewMenuComponent,
    MtTooltipDirective,
    RouterLink,
    RouterLinkActive,
    MatExpansionModule,
    MatTooltipModule,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class NavigationComponent implements OnDestroy, OnInit, AfterViewInit {
  private changeDetector = inject(ChangeDetectorRef);
  private eventBus = inject(EventBusService);
  private media = inject(MediaMatcher);
  private overlayService = inject(OverlayService);
  private utils = inject(UtilsService);

  readonly createMenu = output<{
    date: Date;
    template: number;
  }>();
  readonly closed = output();
  readonly hide = output<boolean>();
  readonly logout = output();
  readonly makeSmall = output<boolean>();

  readonly defaultTemplates = input<OnboardingTemplate[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly showModulesLink = input(true);
  readonly small = input<boolean>(undefined);
  readonly user = input<User>(undefined);

  readonly buttonContainer = viewChild('createButtonContainer', {
    read: ViewContainerRef,
  });
  readonly writeNewMenu = viewChild<WriteNewMenuComponent>('writeNewMenu');

  hideBottom: boolean;
  mobileQuery: MediaQueryList;
  private mobileQueryListener: (event) => void;
  openCreateMenuSubscription: Subscription;

  toggleSmall = (isBtn = false) => {
    this.makeSmall.emit(isBtn);
  };

  expansionOpened = () => {
    if (this.small()) this.toggleSmall();
  };

  constructor() {
    this.eventBus.on(SharedEventTypes.TriggerCreateMenuButton, () => {
      this.openCreateMenu();
    });
  }

  ngOnInit() {
    this.overlayService.registerComponent(`create-button-container`, this);
    this.overlayService.registerContainer(
      `create-button-container`,
      this.buttonContainer(),
    );
    if (window.innerWidth < 756) {
      this.hide.emit(true);
      this.hideBottom = true;
    } else {
      this.hide.emit(false);
      this.hideBottom = false;
    }
  }

  ngAfterViewInit() {
    this.mobileQuery = this.media.matchMedia('(max-width: 768px)');
    this.mobileQueryListener = ({ matches }) => {
      this.hide.emit(matches);
      this.hideBottom = matches as boolean;
      this.changeDetector.detectChanges();
    };
    if (this.mobileQuery.addEventListener) {
      this.mobileQuery.addEventListener(`change`, this.mobileQueryListener);
    } else if (this.mobileQuery.addListener) {
      this.mobileQuery.addListener(this.mobileQueryListener);
    }
  }

  onLogout = () => {
    this.logout.emit();
    this.closed.emit();
  };

  openCreateMenu() {
    const writeNewMenu = this.writeNewMenu();
    if (!writeNewMenu) return undefined;
    writeNewMenu.triggerMenu();
  }

  hasModule(code: string, all = false): boolean {
    return this.utils.hasModules(code, all);
  }

  hasModuleSetting(code: string, setting: string, value: any): boolean {
    return this.utils.hasModuleSetting(code, setting, value);
  }

  ngOnDestroy() {
    if (this.openCreateMenuSubscription) {
      this.openCreateMenuSubscription.unsubscribe();
    }
    if (this.mobileQuery.removeEventListener) {
      this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    } else if (this.mobileQuery.removeListener) {
      this.mobileQuery.removeListener(this.mobileQueryListener);
    }
  }
}
