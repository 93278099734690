import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
  output,
  input,
} from '@angular/core';
import { Subject } from 'rxjs';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Layout } from 'src/app/shared/Models/template';
import { Menu, StyleCategories } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { LayoutsWrapperComponent } from './layouts-wrapper/layouts-wrapper.component';
import { CategoriesTabsComponent } from '../categories-tabs/categories-tabs.component';

@Component({
  selector: 'select-layouts',
  templateUrl: './select-layouts.component.html',
  styleUrls: ['./select-layouts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CategoriesTabsComponent, LayoutsWrapperComponent],
})
export class SelectLayoutsComponent implements OnDestroy {
  private utils = inject(UtilsService);

  readonly categories = input<StyleCategories>([]);
  readonly eventsLayouts = input<Layout[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly publicLayouts = input<Layout[]>(undefined);
  readonly tabIndex = input<number>(undefined);
  readonly userLayouts = input<Layout[]>(undefined);
  readonly selectLayoutEvent = output<number>();

  private destroyed$ = new Subject<void>();

  sendRequest(): void {
    this.utils.sendLayoutRequest.next(0);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
