import { Component, OnInit, inject } from '@angular/core';
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Menu } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

interface PrintInstructionData {
  menu: Menu;
}

@Component({
  selector: 'print-instruction',
  templateUrl: './print-instruction.component.html',
  styleUrls: ['./print-instruction.component.css'],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    TranslocoPipe,
  ],
})
export class PrintInstructionComponent implements OnInit {
  dialog = inject<MatDialogRef<PrintInstructionComponent>>(MatDialogRef);
  private utils = inject(UtilsService);
  protected data = inject<PrintInstructionData>(MAT_DIALOG_DATA);

  instructions = [];
  currentIns;
  prevValue;

  constructor() {
    this.utils.getTranslationObject(
      'menus.complete.instructions.steps',
      (el) => {
        let inst = [];
        for (const step in el) {
          inst = [...inst, el[step]];
        }
        this.instructions = inst;
        this.currentIns = this.instructions.slice(0, 3);
      },
    );
  }

  ngOnInit() {
    const menu = this.data.menu;
    if (!menu || !menu.style) return undefined;
    if (this.instructions && this.instructions.length > 0) {
      this.currentIns = [
        ...this.currentIns,
        menu.style.paper_width > menu.style.paper_height
          ? this.instructions[3]
          : this.instructions[5],
      ];
    }
  }
}
