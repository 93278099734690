import { DeepPartial } from './../../../Models/generics';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { DeclarationKey } from 'src/app/shared/constants/declarations';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import {
  Additive,
  Allergen,
  Declaration,
  GenericDeclaration,
  Label,
} from 'src/app/shared/Models/declarations';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import * as _ from 'lodash-es';
import { Subject, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { IngredientSelectDialogComponent } from '../ingredient select-dialog/ingredient-select.component';
import { AffectModalComponent } from './affect-modal/affect-modal.component';
import { OptionItemComponent } from './option-item/option-item.component';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';

@Component({
  selector: 'options',
  templateUrl: './options.component.html',
  styles: [
    `
      .dependants {
        padding-left: 35px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OptionItemComponent],
})
export class OptionsComponent implements OnChanges, OnDestroy, OnInit {
  private ngrxStore = inject<Store<State>>(Store);
  private utils = inject(UtilsService);
  private dialog = inject(MatDialog);

  readonly color = input('primary');
  readonly dish = input<Dish | Ingredient>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly type = input<'allergens' | 'additives' | 'labels'>(undefined);
  readonly recipes = input<Recipe[]>(undefined);
  readonly allergensView = input<boolean>(undefined);
  readonly additivesView = input<boolean>(undefined);
  readonly hasRecipes = input<boolean>(undefined);

  readonly changed = output<{
    data: DeepPartial<Dish> | DeepPartial<Ingredient>;
    type: 'allergens' | 'additives' | 'labels';
  }>();
  readonly fetchRecipes = output<void>();
  readonly searchIngredients = output<string>();
  readonly deleteIngredientEvent = output<{
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }>();
  readonly addIngredientEvent = output<Recipe>();
  readonly selectedIngredientEvent = output<{
    ingredient_id: number;
    recipe: Recipe;
  }>();
  readonly createIngredientEvent = output<{
    newIngredient: Partial<Recipe>;
    recipe: Recipe;
  }>();
  readonly updateRecipeIngredientEvent = output<{
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: () => void;
    };
  }>();
  readonly updateIngredientEvent = output<{
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
    };
  }>();

  changeOptions = new EventEmitter();

  private destroyed$ = new Subject<void>();
  options: GenericDeclaration[];
  localOptions: Record<DeclarationKey, boolean>;
  translations: Record<DeclarationKey, boolean>[];
  allergens: Allergen[] = [];
  additives: Additive[] = [];
  labels: Label[] = [];
  countries = ['DE'];
  showConfirmDialog = false;

  affectedOptions: {
    col: DeclarationKey[];
    sweet: DeclarationKey[];
    caf: DeclarationKey[];
  } = {
    col: ['adv'],
    sweet: ['phen', 'lax'],
    caf: ['hcaf'],
  };
  showAfter = Object.keys(this.affectedOptions).reduce(
    (res, el: 'col' | 'sweet' | 'caf') => [...res, ...this.affectedOptions[el]],
    [] as DeclarationKey[],
  );

  allergens$ = this.ngrxStore.select(sharedFeature.selectAllergens);
  additives$ = this.ngrxStore.select(sharedFeature.selectAdditives);
  labels$ = this.ngrxStore.select(sharedFeature.selectLabels);

  ngOnInit() {
    this.allergens$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((all) => (this.allergens = all ? all : this.allergens));
    this.additives$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((add) => (this.additives = add ? add : this.additives));
    this.labels$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((lab) => (this.labels = lab ? lab : this.labels));
    this.utils
      .getTranslationObject(`${this.type()}.elements`, (v) =>
        this.handleTranslation(v as Record<DeclarationKey, boolean>[]),
      )
      .subscribe();
    this.changeOptions
      .pipe(debounceTime(400), takeUntil(this.destroyed$))
      .subscribe((options: Record<DeclarationKey, boolean>) => {
        const dish = this.dish();
        this.changed.emit({
          data: {
            url: dish.url,
            id: dish.id,
            [this.type()]: options,
          },
          type: this.type(),
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.dish()) return undefined;
    const recipes = this.recipes();
    if (recipes) {
      this.showConfirmDialog =
        recipes.length || this.hasRecipes() ? true : false;
    }
    if (changes.translations || changes.dish)
      this.fillOptions(this.translations);
  }

  hasModuleSetting = (code: string, setting: string, value: any): boolean =>
    this.utils.hasModuleSetting(code, setting, value);

  showDialog({
    declaration,
    value,
    onCancel,
  }: {
    declaration: GenericDeclaration;
    value: boolean;
    onCancel: () => void;
  }) {
    const recipes = this.recipes();
    if (this.hasRecipes() && !recipes?.length) {
      this.fetchRecipes.emit();
    }
    const dialogRef = this.dialog.open(IngredientSelectDialogComponent, {
      data: {
        dish: this.dish(),
        lang: this.lang(),
        type: this.type(),
        recipes: recipes,
        option: declaration,
        action: !value,
        allergensView: this.allergensView(),
        additivesView: this.additivesView(),
        save: () => {
          this.changeOption(declaration);
        },
        searchIngredients: (searchIngredient) => {
          this.searchIngredients.emit(searchIngredient);
        },
        chooseIngredient: (data: { ingredient_id: number; recipe: Recipe }) => {
          this.selectedIngredientEvent.emit(data);
        },
        updateIngredient: (data) => {
          this.updateIngredientEvent.emit(data);
        },
        updateRecipeIngredient: (data: {
          recipe: Recipe;
          updatedIngredient: {
            url: string;
            recipeIngredient: Partial<RecipeIngredient>;
            onFulfilled: () => void;
          };
        }) => {
          this.updateRecipeIngredientEvent.emit(data);
        },
        deleteIngredient: (data: {
          deletingIngredient: SimpleRecipeIngredient;
          recipe: Recipe;
        }) => {
          this.deleteIngredientEvent.emit(data);
        },
        addIngredient: (Recipe: Recipe) => {
          this.addIngredientEvent.emit(Recipe);
        },
        createIngredient: (data: {
          newIngredient: Partial<Recipe>;
          recipe: Recipe;
        }) => {
          this.createIngredientEvent.emit(data);
        },
        changeRecipeDeclarativeOption: (data) => {
          this.updateIngredientEvent.emit(data);
        },
      },
      autoFocus: false,
      width: '900px',
      maxWidth: '100vw',
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (!val) onCancel();
    });
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  getParent = (val: DeclarationKey) =>
    Object.keys(this.affectedOptions).find((parent: 'col' | 'sweet' | 'caf') =>
      this.affectedOptions[parent].find((child) => child === val),
    );

  parentEnable = (val: DeclarationKey): boolean => {
    const parent = this.getParent(val);
    return this.getOptionValue(parent);
  };

  hasEnableChild = (parent: DeclarationKey): boolean => {
    if (!this.affectedOptions[parent]) return false;
    return !!this.affectedOptions[parent].filter((el) =>
      this.getOptionValue(el),
    ).length;
  };

  getOptionValue = (val): boolean => {
    const parentObject = this.options.find((el) => el.name === val);
    return parentObject ? parentObject.value : false;
  };

  handleTranslation = (
    translations: Record<DeclarationKey, boolean>[],
  ): void => {
    this.translations = translations;
    this.fillOptions(translations);
  };

  openModalOfAffects = ({ name: parent }: GenericDeclaration): void => {
    const children = this.options.filter(({ name }) =>
      this.affectedOptions[parent].includes(name),
    );
    const itemsToPass = {
      allergens: this.allergens,
      additives: this.additives,
      labels: this.labels,
    };
    this.dialog.open(AffectModalComponent, {
      data: {
        option: parent,
        children,
        items: itemsToPass[this.type()],
        lang: this.lang(),
        changed: (item: GenericDeclaration) => {
          if (item.name !== parent) {
            // Update both the original item and create a new reference
            this.options = this.options.map((opt) =>
              opt.name === item.name ? { ...opt, value: item.value } : opt,
            );
            this.localOptions[item.name] = item.value;
            const updatedOption = this.options.find(
              (opt) => opt.name === item.name,
            );
            if (updatedOption) {
              this.changeOption(updatedOption);
            }
          }
        },
      },
    });
  };

  fillOptions = (translations: Record<DeclarationKey, boolean>[]): void => {
    const dish = this.dish();
    if (
      !translations ||
      !this ||
      !this.allergens ||
      !this.additives ||
      !this.labels ||
      !dish ||
      !dish[this.type()]
    ) {
      return undefined;
    }
    if (!translations) return undefined;
    const newOptions = this.getFilteredOptions(translations);
    if (!_.isEqual(newOptions, this.options)) {
      this.options = newOptions;
      this.localOptions = this.extractValues(
        Object.fromEntries(this.options.map((opt) => [opt.name, opt])),
      );
    }
  };

  getFilteredOptions(
    translations: Record<DeclarationKey, boolean>[],
  ): GenericDeclaration[] {
    return Object.keys(translations)
      .map((el: DeclarationKey) => this.getElement(el))
      .filter((el) => el && !this.getProperty(el, 'depends_on'))
      .map(this.elementMapper)
      .sort((a, b) => a.order - b.order);
  }

  elementMapper = (el: Declaration): GenericDeclaration => {
    return new GenericDeclaration(
      el.code as DeclarationKey,
      this.findElem(el.code),
      this.getProperty(el, 'order', -1),
      this.getDependants(el),
    );
  };

  findElem(code: string): boolean {
    return !!this.dish()[this.type()][code];
  }

  getElement(code: DeclarationKey): Declaration {
    const type = this.type();
    const arr =
      type === 'allergens'
        ? this.allergens
        : type === 'additives'
          ? this.additives
          : this.labels;

    if (!arr) return undefined;
    return arr.find((v) => v.code === code);
  }

  getProperty(item: Declaration, field: string, def?: number): number {
    if (!item) return def;
    return item ? item[field] : def;
  }

  getDependants(elem: Declaration): GenericDeclaration[] {
    const type = this.type();
    const arr: Declaration[] =
      type === 'allergens'
        ? this.allergens
        : type === 'additives'
          ? this.additives
          : this.labels;
    if (!arr) return undefined;
    return arr
      .filter((item) => item.depends_on === elem.id)
      .map((el) => this.elementMapper(el));
  }

  changeOption = (option: GenericDeclaration): void => {
    if (this.affectedOptions[option.name] && option.value) {
      this.openModalOfAffects(option);
    }

    // Update the option in the options array
    this.options = this.options.map((opt) =>
      opt.name === option.name ? { ...opt, value: option.value } : opt,
    );

    this.localOptions[option.name] = option.value;

    // Handle dependants
    if (option.dependants && !option.value) {
      // Update dependants in the options array
      this.options = this.options.map((opt) => {
        const isDependant = option.dependants?.some(
          (dep) => dep.name === opt.name,
        );
        if (isDependant) {
          return { ...opt, value: false };
        }
        return opt;
      });

      // Update localOptions for dependants
      option.dependants.forEach((dep) => {
        this.localOptions[dep.name] = false;
      });
    }

    const dish = this.dish();
    if (dish && !dish[this.type()]) {
      dish[this.type()] = {};
    }

    this.changeOptions.emit(this.localOptions);
  };

  extractValues(
    options: Record<string, GenericDeclaration>,
  ): Record<DeclarationKey, boolean> {
    const values = {};
    Object.entries(options).forEach(([, value]) => {
      if (_.isObject(value)) {
        if (value.dependants) {
          value.dependants.forEach((dep) => (values[dep.name] = dep.value));
        }
        values[value.name] = value.value;
      }
    });
    return values as any;
  }

  trackItemFn = (index: number, item: GenericDeclaration): string =>
    item.name + item.value.toString();

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
