import {
  Component,
  OnInit,
  ViewContainerRef,
  inject,
  viewChild,
} from '@angular/core';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';

@Component({
  selector: 'bottom-block-overlay',
  templateUrl: './bottom-block-overlay.component.html',
  styleUrls: ['./bottom-block-overlay.component.scss'],
  standalone: true,
})
export class BottomBlockOverlayComponent implements OnInit {
  private overlayService = inject(OverlayService);

  readonly container = viewChild(`container`, { read: ViewContainerRef });

  ngOnInit() {
    this.overlayService.registerContainer(`bottom-block`, this.container());
  }
}
