<h3 class="mat-h3">{{ 'menus.overview.share_menu_title' | transloco }}</h3>
<div class="menu-name">
  <strong>{{ menuName() }}</strong> | {{ menuDate() }}
</div>

<hr />

<p>{{ 'menus.overview.share_menu_info' | transloco }}</p>
<form class="location-options" [formGroup]="form">
  @for (
    location of locationsFormArray.controls;
    track location;
    let i = $index
  ) {
    <span formArrayName="locations">
      <mat-checkbox
        color="primary"
        class="duplicate-option-item"
        [formControlName]="i"
      >
        {{ locationData[i].name }}
      </mat-checkbox>
    </span>
  }
</form>

<hr class="information-options" />

<p>{{ 'menus.overview.share_menu_information_options.title' | transloco }}</p>
<form class="location-options" [formGroup]="form">
  <mat-checkbox
    color="primary"
    [checked]="true"
    [disabled]="true"
    class="duplicate-option-item"
  >
    {{
      'menus.overview.share_menu_information_options.menu_details' | transloco
    }}
    <mat-icon
      class="info-icon"
      [mtTooltip]="
        'menus.overview.share_menu_information_options.menu_details_tooltip'
          | transloco
      "
      >info</mat-icon
    >
  </mat-checkbox>
  <mat-checkbox
    color="primary"
    [checked]="true"
    [disabled]="true"
    class="duplicate-option-item"
  >
    {{
      'menus.overview.share_menu_information_options.products_in_menu'
        | transloco
    }}
    <mat-icon
      class="info-icon"
      [mtTooltip]="
        'menus.overview.share_menu_information_options.products_in_menu_tooltip'
          | transloco
      "
      >info</mat-icon
    >
  </mat-checkbox>
  <mat-checkbox
    color="primary"
    formControlName="copy_recipes"
    class="duplicate-option-item"
  >
    {{ 'menus.overview.share_menu_information_options.recipes' | transloco }}
    <mat-icon
      class="info-icon"
      [mtTooltip]="
        'menus.overview.share_menu_information_options.recipes_tooltip'
          | transloco
      "
      >info</mat-icon
    >
  </mat-checkbox>
  <mat-checkbox
    color="primary"
    formControlName="copy_diets"
    class="duplicate-option-item"
  >
    {{
      'menus.overview.share_menu_information_options.dietary_breakdown'
        | transloco
    }}
    <mat-icon
      class="info-icon"
      [mtTooltip]="
        'menus.overview.share_menu_information_options.dietary_breakdown_tooltip'
          | transloco
      "
      >info</mat-icon
    >
  </mat-checkbox>
</form>

<div class="actions">
  <button mat-button class="cancel" (click)="cancelAction.emit()">
    {{ 'menus.overview.duplicate.dialog.actions.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="done"
    (click)="submit()"
    [disabled]="!form.valid"
  >
    {{ 'menus.overview.duplicate.dialog.actions.share_menu' | transloco }}
  </button>
</div>
