import { Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { BackgroundImage } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { SelectImageComponent } from '../../../shared/select-image/select-image.component';

@Component({
  selector: 'backgrounds-library',
  templateUrl: './backgrounds-library.component.html',
  styleUrls: ['./backgrounds-library.component.scss'],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    SelectImageComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    TranslocoPipe,
  ],
})
export class BackgroundsLibraryComponent {
  private dialogRef =
    inject<MatDialogRef<BackgroundsLibraryComponent>>(MatDialogRef);
  data = inject<{
    backgroundImages: BackgroundImage[];
    currentBackground: number;
    deleteBackground: (image: BackgroundImage) => void;
    select: (id: number | null) => void;
  }>(MAT_DIALOG_DATA);

  deleteBgImage(image: BackgroundImage): void {
    if (this.data.currentBackground === image.id) this.chooseBgImage(image);
    this.data.deleteBackground(image);
    setTimeout(() => {
      this.data.backgroundImages = this.data.backgroundImages.filter(
        (bg) => bg.id !== image.id,
      );
    }, 500);
  }

  chooseBgImage(image: BackgroundImage): void {
    const deselect = this.data.currentBackground === image?.id;
    this.data.select(deselect ? null : image.id);
    if (deselect) {
      this.data.currentBackground = null;
    } else {
      this.dialogRef.close();
    }
  }
}
