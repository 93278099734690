import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { SimpleMenu } from 'src/app/shared/Models/menu';
import { LastMenuComponent } from './last-menu/last-menu.component';
import { MenusCounterComponent } from './menus-counter/menus-counter.component';

@Component({
  selector: 'app-user-stats',
  templateUrl: './user-stats.component.html',
  styleUrls: ['./user-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MenusCounterComponent, LastMenuComponent],
})
export class UserStatsComponent {
  readonly lastMenu = input<SimpleMenu>(undefined);
  readonly menusCreated = input<{
    this_month: number;
    all_time: number;
    this_week: number;
    this_year: number;
  }>(undefined);
  readonly previewMenu = output<SimpleMenu>();
}
