import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import {
  ContentLanguage,
  InterfaceLanguage,
  langs,
} from 'src/app/shared/constants/languages';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { User } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ShowAndUploadImageComponent } from '../../show-and-upload-image/show-and-upload-image.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NameDescriptionComponent } from '../../name-description/name-description.component';
import { SelectLocationComponent } from '../../select-location/select-location.component';

interface ItemNameDialogData {
  item: any;
  currentItem?: Subject<any>;
  close: (item) => void;
  uploadImage: (file) => void;
  clearImage?: () => void;
  description?: boolean;
  locations?: Observable<SimpleLocation[]>;
  groupedLocations?: Observable<Record<string, SimpleLocation[]>>;
  user?: Observable<User>;
  image?: string;
  onlyInterfaceLangs?: boolean;
  hideProgress?: boolean;
  showArticleNumber?: boolean;
  title?: string;
  adding?: boolean;
  currentLocation?: number;
}

@Component({
  selector: 'item-name-dialog',
  templateUrl: './item-name-dialog.component.html',
  styleUrls: ['./item-name-dialog.component.scss'],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    SelectLocationComponent,
    NameDescriptionComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    ShowAndUploadImageComponent,
    MatDialogActions,
    MatButtonModule,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class ItemNameDialogComponent implements OnDestroy, OnInit {
  data = inject<ItemNameDialogData>(MAT_DIALOG_DATA);
  dialogRef = inject<MatDialogRef<ItemNameDialogComponent>>(MatDialogRef);
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);

  private destroyed$ = new Subject<void>();
  italic: boolean;
  itemName: string;
  allEmpty = true;
  langs = langs;
  lang: InterfaceLanguage;
  showImageSpinner = false;
  locationControl = new FormControl<number>(null);

  ngOnInit() {
    if (this.data.currentItem) {
      this.data.currentItem
        .pipe(takeUntil(this.destroyed$))
        .subscribe((item) => {
          this.showImageSpinner = false;
          this.allEmpty = this.isAllEmpty();
          if (item) this.data.item = item;
        });
    }
    const { name, italic } = this.utils.tryGetLabel(this.data.item, this.lang);
    this.itemName = name;
    this.italic = italic;
    this.allEmpty = this.isAllEmpty();
    this.translate.langChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((lang) => (this.lang = lang as InterfaceLanguage));
    this.locationControl.valueChanges
      .pipe(takeUntil(this.dialogRef.afterClosed()))
      .subscribe((value) => {
        this.data.item['location'] = value;
      });
    if (this.data.currentLocation)
      this.locationControl.patchValue(this.data.currentLocation);
  }

  isAllEmpty = (): boolean => this.langs.every(this.isEmpty);

  isEmpty = (lang: ContentLanguage): boolean =>
    this.data && this.data.item && !this.data.item[lang];

  patchItem(item) {
    Object.assign(this.data.item, item);
    this.allEmpty = this.langs.every((l) => !item[l]);
  }

  updateAndClose(): void {
    this.dialogRef.close();
    const newObj = {};
    this.langs
      .filter((i) => this.data.item[i] !== undefined)
      .forEach((lang: InterfaceLanguage) => {
        newObj[lang] = this.data.item[lang];
        if (this.data.description)
          newObj['description_' + lang] = this.data.item['description_' + lang];
      });
    if (this.locationControl.value)
      newObj['location'] = this.locationControl.value;
    this.data.close(newObj);
  }

  uploadImage(file: File) {
    this.showImageSpinner = true;
    this.data.uploadImage({
      payload: file,
      onFulfilled: () => (this.showImageSpinner = false),
    });
  }

  clearLocation(): void {
    this.locationControl.setValue(null);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
