@if (
  title() &&
  menu()?.user_details &&
  (!hideEmptyLang() ||
    showVariants() ||
    menu().user_details['title_' + hideEmptyLang()] ||
    menu().user_details['subtitle_' + hideEmptyLang()] ||
    menu().user_details['endtext_' + hideEmptyLang()] ||
    menu().user_details['endtext_secondary_' + hideEmptyLang()] ||
    menu().user_details['footnote_' + hideEmptyLang()])
) {
  <h3>
    {{ title() }}
  </h3>
}
@if (menu()?.user_details && detailsForm) {
  <mat-accordion
    id="menu-tour-details"
    #menuDetails
    class="menu-details"
    tourAnchor="menu-details"
    [attr.dir]="rtl ? 'rtl' : 'ltr'"
  >
    @for (field of ['title', 'subtitle']; track field) {
      @if (
        !hideEmptyLang() || menu().user_details[field + '_' + hideEmptyLang()]
      ) {
        <mat-expansion-panel [disabled]="disabled()">
          <mat-expansion-panel-header
            [class.has-content]="menu().user_details[field + '_' + lang()]"
            >{{
              (translations() && translations()['write.dishes.' + field]) ||
                ('write.dishes.' + field | transloco)
            }}</mat-expansion-panel-header
          >
          @if (hideEmptyLang()) {
            <p class="notes">
              {{ (menu()?.user_details)[field + '_' + hideEmptyLang()] }}
            </p>
          }
          <form [formGroup]="detailsForm">
            <mat-form-field subscriptSizing="dynamic">
              @if (translations()) {
                <mat-label>{{
                  translations()['write.dishes.' + field] ||
                    ('write.dishes.' + field | transloco)
                }}</mat-label>
              }
              <input
                matInput
                maxlength="250"
                [formControlName]="field + '_' + lang()"
              />
            </mat-form-field>
          </form>
          @if (menu().rule || menu().onboarding_template) {
            <save-restore
              [field]="field + '_' + lang()"
              [menu]="menu()"
              (restoreDefault)="
                restoreDefault.emit({
                  target: $event,
                  field: field + '_' + lang(),
                })
              "
              (saveDefault)="
                saveDefault.emit({
                  target: $event,
                  field: field + '_' + lang(),
                })
              "
            ></save-restore>
          }
        </mat-expansion-panel>
      }
    }
    @if (!hideEmptyLang()) {
      <mat-expansion-panel [disabled]="disabled()">
        <mat-expansion-panel-header
          [class.has-content]="menu().user_details?.wine"
          >{{
            (translations() && translations()['write.dishes.wine']) ||
              ('write.dishes.wine' | transloco)
          }}</mat-expansion-panel-header
        >
        <form [formGroup]="detailsForm" class="wine-panel">
          <img
            class="menu-wine"
            src="../../../assets/img/menudish/wine.png"
            alt="Wine icon"
          />
          <mat-form-field subscriptSizing="dynamic">
            @if (translations()) {
              <mat-label>{{
                translations()['write.dishes.wine'] ||
                  ('write.dishes.wine' | transloco)
              }}</mat-label>
            }
            <textarea
              matInput
              matTextareaAutosize
              matAutosizeMinRows="2"
              maxlength="2500"
              formControlName="wine"
            ></textarea>
          </mat-form-field>
        </form>
        @if (menu().rule || menu().onboarding_template) {
          <save-restore
            [field]="'wine'"
            [menu]="menu()"
            (restoreDefault)="
              restoreDefault.emit({ target: $event, field: 'wine' })
            "
            (saveDefault)="saveDefault.emit({ target: $event, field: 'wine' })"
          ></save-restore>
        }
      </mat-expansion-panel>
    }
    @for (field of ['endtext', 'endtext_secondary', 'footnote']; track field) {
      @if (
        !hideEmptyLang() || menu().user_details[field + '_' + hideEmptyLang()]
      ) {
        <mat-expansion-panel [disabled]="disabled()">
          <mat-expansion-panel-header
            [class.has-content]="menu().user_details[field + '_' + lang()]"
            >{{
              (translations() && translations()['write.dishes.' + field]) ||
                ('write.dishes.' + field | transloco)
            }}</mat-expansion-panel-header
          >
          @if (hideEmptyLang()) {
            <p
              class="notes"
              [innerHTML]="
                replaceNewlinesWithBr(
                  (menu()?.user_details)[field + '_' + hideEmptyLang()]
                )
              "
            ></p>
          }
          <form [formGroup]="detailsForm">
            <mat-form-field subscriptSizing="dynamic">
              @if (translations()) {
                <mat-label>{{
                  translations()['write.dishes.' + field] ||
                    ('write.dishes.' + field | transloco)
                }}</mat-label>
              }
              <textarea
                matInput
                matTextareaAutosize
                matAutosizeMinRows="2"
                maxlength="10000"
                [formControlName]="field + '_' + lang()"
              ></textarea>
            </mat-form-field>
          </form>
          @if (menu().rule || menu().onboarding_template) {
            <save-restore
              [field]="field + '_' + lang()"
              [menu]="menu()"
              (restoreDefault)="
                restoreDefault.emit({
                  target: $event,
                  field: field + '_' + lang(),
                })
              "
              (saveDefault)="
                saveDefault.emit({
                  target: $event,
                  field: field + '_' + lang(),
                })
              "
            ></save-restore>
          }
        </mat-expansion-panel>
      }
    }
    @if (showVariants()) {
      <mat-expansion-panel [disabled]="disabled()">
        <mat-expansion-panel-header
          [class.has-content]="menu().user_details.variants?.length"
          >{{
            (translations() && translations()['write.dishes.price']) ||
              ('write.dishes.price' | transloco)
          }}</mat-expansion-panel-header
        >
        @if (menu()) {
          <app-variants
            [currencySymbol]="user()?.settings?.currency_symbol"
            [item]="menu().user_details"
            [lang]="lang()"
            [responseItem]="menu().user_details"
            (changed)="variantsChanged($event)"
          ></app-variants>
        }
        @if (menu().rule || menu().onboarding_template) {
          <save-restore
            [field]="'variants'"
            [menu]="menu()"
            (restoreDefault)="
              restoreDefault.emit({ target: $event, field: 'variants' })
            "
            (saveDefault)="
              saveDefault.emit({ target: $event, field: 'variants' })
            "
          ></save-restore>
        }
      </mat-expansion-panel>
    }
  </mat-accordion>
}
