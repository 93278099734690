import { Component, inject, input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { FeedbackComponent } from '../../../../shared/Components/feedback/feedback.component';

@Component({
  selector: 'delete-feedback',
  templateUrl: './delete-feedback.component.html',
  styleUrls: ['./delete-feedback.component.css'],
  imports: [FeedbackComponent, TranslocoPipe],
})
export class DeleteFeedbackComponent {
  private dialog = inject<MatDialogRef<DeleteFeedbackComponent>>(MatDialogRef);

  readonly showEmail = input(false);

  close = (): void => this.dialog.close();
}
