@if (!create()) {
  <div class="input-wrapper icon-button-density-3">
    @if (canModify()) {
      <mat-form-field
        class="name-field field-density-5"
        (click)="$event.stopPropagation()"
        subscriptSizing="dynamic"
      >
        <input #inputField type="text" matInput [formControl]="nameControl" />
      </mat-form-field>
    } @else {
      <div class="name-text">
        <span class="name">{{ nameControl.value }}</span>
      </div>
    }
    @if (canModify()) {
      <button mat-icon-button (click)="openDialog.emit()">
        <mat-icon>edit</mat-icon>
      </button>
    }
    @if (canModify() && !selected()) {
      <button mat-icon-button [matMenuTriggerFor]="deleteMenu">
        <mat-icon>delete</mat-icon>
      </button>
    }
    <mat-menu #deleteMenu class="delete-style-menu">
      <button class="delete-style-menu-label" mat-menu-item disabled>
        {{ 'style.choose-template.are-you-sure' | transloco }}
      </button>
      <button
        class="delete-style-menu-button"
        mat-menu-item
        (click)="deleteStyle.emit()"
      >
        <mat-icon>delete</mat-icon>
        {{ 'style.choose-template.delete' | transloco }}
      </button>
    </mat-menu>
  </div>
  <div
    class="img-wrapper"
    [class.selected]="selected()"
    [style.background-image]="
      'url(\'' + (style().image || '/assets/img/Empty menu.jpg') + '\')'
    "
  >
    @if (
      loading && (styleSpinner() === 'select' || styleSpinner() === 'update')
    ) {
      <mat-spinner></mat-spinner>
    }
  </div>
  <div class="button-wrapper icon-button-density-3">
    @if (selected() && (!canModify() || !modified())) {
      <button mat-flat-button class="style-button" [disabled]="true">
        {{ 'style.choose-style.selected' | transloco }}
      </button>
    }
    @if ((canModify() && modified()) || !selected()) {
      <button
        mat-flat-button
        class="style-button"
        [class.update]="selected()"
        [class.loading]="
          styleSpinner() === 'select' || styleSpinner() === 'update'
        "
        [disabled]="styleSpinner() === 'select' || styleSpinner() === 'update'"
        (click)="selected() ? emitUpdateStyle() : emitSelectStyle()"
      >
        {{
          selected()
            ? ('style.choose-style.update' | transloco)
            : ('style.choose-style.select' | transloco)
        }}
      </button>
    }
    @if (selected() && modified()) {
      <button
        #tooltip="mtTooltip"
        class="restore"
        mat-icon-button
        [class.loading]="
          styleSpinner() === 'select' || styleSpinner() === 'update'
        "
        [disabled]="styleSpinner() === 'select' || styleSpinner() === 'update'"
        [mtTooltip]="
          selected() && modified()
            ? ('style.choose-style.restore' | transloco)
            : ''
        "
        (click)="emitSelectStyle(); tooltip.hide()"
      >
        <mat-icon>settings_backup_restore</mat-icon>
      </button>
    }
  </div>
} @else {
  <div class="content create">
    @if (styleSpinner() !== 'create') {
      <mat-icon color="primary">add_to_photos</mat-icon>
      {{ 'style.choose-style.save' | transloco }}
    } @else {
      @if (styleSpinner() === 'create') {
        <mat-spinner></mat-spinner>
      }
    }
  </div>
  <button
    mat-flat-button
    class="create-button"
    color="primary"
    [disabled]="styleSpinner()"
    (click)="createStyle.emit()"
  >
    {{
      (styleSpinner() === 'create'
        ? 'style.choose-style.creating'
        : 'style.choose-style.create'
      ) | transloco
    }}
  </button>
}
