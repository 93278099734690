@if (data.title) {
  <h2 mat-dialog-title>
    {{ data.title }}
  </h2>
}

<mat-dialog-content>
  <p>
    {{ data.text }}
    <a [href]="data.link">{{ data.linkText }}</a>
  </p>
</mat-dialog-content>

<mat-dialog-actions class="actions" align="end">
  @if (data.cancelable) {
    <button data-cy="simple-dialog-cancel" mat-button mat-dialog-close>
      {{ data.cancelText ?? 'shared.buttons.cancel' | transloco }}
    </button>
  }
  <button
    data-cy="simple-dialog-confirm"
    mat-button
    cdkFocusInitial
    [mat-dialog-close]="true"
    color="primary"
  >
    {{ data.confirmText ?? 'shared.buttons.done' | transloco }}
  </button>
</mat-dialog-actions>
