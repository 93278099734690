<h2 mat-dialog-title class="header">
  @if (data?.adding) {
    <span class="title">{{ data?.title | transloco }}</span>
  } @else {
    <span class="title"
      >{{ 'style.choose-template.edit-template' | transloco }} </span
    ><span [style.font-style]="italic ? 'italic' : ''">{{ itemName }}</span>
  }
</h2>
<mat-dialog-content class="content">
  <div class="element-group">
    @if (data?.adding) {
      @for (lang of filteredLangs; track lang) {
        <div class="lang-block">
          <mat-form-field class="text">
            <mat-label>{{ 'shared.languages.' + lang | transloco }}</mat-label>
            <input type="text" matInput [(ngModel)]="template[lang]" />
          </mat-form-field>
        </div>
      }
      @if (unusedLangs.length > 0) {
        <button
          mat-button
          class="add-translation"
          [matMenuTriggerFor]="langMenu"
        >
          {{ 'shared.dialogs.item-name.add' | transloco }}
        </button>
      }
      <mat-menu #langMenu="matMenu" [overlapTrigger]="false">
        @for (lang of unusedLangs; track lang) {
          <button mat-menu-item (click)="addLang(lang)">
            {{ 'shared.languages.' + lang | transloco }}
          </button>
        }
      </mat-menu>
    } @else {
      <name-description
        [description]="data?.description"
        [allLanguages]="!data?.onlyInterfaceLangs"
        [hideProgress]="data?.hideProgress"
        [item]="data.item"
        [lang]="lang"
        (update)="patchItem($event)"
      ></name-description>
    }
  </div>
  <div class="element-group">
    <show-and-upload-image
      [imageUrl]="data?.item?.image"
      (deleteImage)="data.clearImage()"
      (fileChoosen)="uploadImage($event)"
    ></show-and-upload-image>
  </div>
  <div class="options">
    <h4>
      {{ 'style.choose-template.create-template-dialog.layout' | transloco }}
    </h4>
    <div class="option-item">
      <mat-radio-group [value]="true">
        <mat-radio-button
          disabled="true"
          [value]="true"
          name="group1"
          color="primary"
        >
          {{
            'style.choose-template.create-template-dialog.use_menu_layout'
              | transloco
          }}
          {{ data.layoutName }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>

  <div class="options">
    <h4>
      {{ 'style.choose-template.create-template-dialog.preset' | transloco }}
    </h4>
    <span class="option-item">
      <mat-radio-group
        color="primary"
        (change)="defaultOptionChanged($event)"
        [value]="choosenDefaultOption"
      >
        <mat-radio-button color="primary" [value]="false" name="group2">
          {{
            'style.choose-template.create-template-dialog.dont_create_preset'
              | transloco
          }}
        </mat-radio-button>
        <mat-radio-button color="primary" [value]="true" name="group2">
          {{
            'style.choose-template.create-template-dialog.create_preset'
              | transloco
          }}
        </mat-radio-button>
      </mat-radio-group>
    </span>
  </div>

  <div class="options">
    <h4>
      {{ 'style.choose-template.create-template-dialog.style' | transloco }}
    </h4>
    <span class="option-item">
      <mat-radio-button
        disabled="true"
        checked="true"
        name="group1"
        color="primary"
      >
        {{
          'style.choose-template.create-template-dialog.create_style'
            | transloco
        }}
      </mat-radio-button>
    </span>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <button mat-button class="cancel" (click)="dialogRef.close()">
    {{ 'shared.buttons.cancel' | transloco }}
  </button>
  <button
    mat-button
    class="done"
    color="primary"
    [disabled]="isAllEmpty() && data.adding"
    (click)="updateItem()"
  >
    {{ 'shared.buttons.done' | transloco }}
  </button>
</mat-dialog-actions>
