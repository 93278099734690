import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { selectTranslationsList } from 'src/app/menus/menu-edit/translate/ngrx/menu-translate.selectors';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { LangButton } from 'src/app/shared/Models/langButton';
import { Menu } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { map } from 'rxjs/operators';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from 'ngx-clipboard';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LanguageButtonsComponent } from '../../../../../shared/Components/language-buttons/language-buttons.component';

@Component({
  selector: 'share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.scss'],
  imports: [
    LanguageButtonsComponent,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    ClipboardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    TranslocoPipe,
  ],
})
export class ShareLinkComponent implements OnChanges, OnInit {
  protected ngrxStore = inject<Store<State>>(Store);
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);

  readonly actionLabel = input<string>(undefined);
  readonly customUrl = input('');
  readonly menu = input<Menu>(undefined);
  readonly params = input<any>(undefined);
  readonly copied = output<void>();
  readonly generateQRCode = output<string>();

  translations$ = this.ngrxStore.select(selectTranslationsList);

  lang: InterfaceLanguage;
  langs: LangButton[] = [];
  link: string;
  linkUpdated = false;
  printVersion = false;
  screenVersion = false;

  constructor() {
    this.lang = this.translate.getActiveLang() as InterfaceLanguage;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('menu' in changes) this.updateLink(this.langs);
  }

  ngOnInit(): void {
    this.translations$
      .pipe(
        map((trans) =>
          trans.map((translation) => {
            const menu = this.menu();
            return {
              lang: translation.language,
              activated: translation.language === menu?.base_language,
              order: translation.language === menu?.base_language ? 0 : null,
            };
          }),
        ),
      )
      .subscribe((v) => {
        this.langs = v;
        this.updateLink(this.langs);
      });
  }

  copy(): void {
    this.copied.emit();
  }

  getLink = (langs: LangButton[]): string => {
    const menu = this.menu();
    const url = menu?.template_detail
      ? this.utils.getSharedLink(
          langs,
          menu.base_language,
          menu.template_detail,
          menu.share_link,
          true,
          this.params(),
        )
      : '';
    return url;
  };

  qrcode(): void {
    const url = this.utils.getSharedLink(
      this.langs,
      this.menu().base_language,
      this.menu().template_detail,
      this.menu().url + 'generate_qrcode/',
      true,
      this.params(),
    );
    this.generateQRCode.emit(url);
  }

  updateLink(langs: LangButton[]): void {
    this.langs = langs;
    this.link = this.getLink(langs);
    if (this.link === undefined && !this.linkUpdated)
      setTimeout(() => {
        this.linkUpdated = true;
        this.updateLink(langs);
      }, 1000);
  }

  changePrintVersion(event): void {
    const params = this.params();
    if (event.checked) {
      this.params()['print_mode'] = true;
    } else if ('print_mode' in params) {
      delete params.print_mode;
    }
    this.updateLink(this.langs);
  }

  changeScreenVersion(event): void {
    const params = this.params();
    if (event.checked) {
      this.params()['screen'] = true;
    } else if ('screen' in params) {
      delete params.screen;
    }
    this.updateLink(this.langs);
  }
}
