import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  ReactiveFormsModule,
} from '@angular/forms';
import { ShareMenuDetail } from 'src/app/shared/Models/menu';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { User } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MtTooltipDirective } from '../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'share-menu',
  templateUrl: './share-menu.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./share-menu.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MatCheckboxModule,
    MatIconModule,
    MtTooltipDirective,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class ShareMenuComponent implements OnChanges {
  private formBuilder = inject(FormBuilder);

  readonly locations = input<SimpleLocation[]>(undefined);
  readonly menuDate = input<string>(undefined);
  readonly menuName = input<string>(undefined);
  readonly user = input<User>(undefined);
  readonly cancelAction = output<void>();
  readonly shareMenuAction = output<ShareMenuDetail>();

  form: FormGroup<{
    locations: FormArray<FormControl<boolean>>;
    copy_recipes: FormControl<boolean>;
    copy_diets: FormControl<boolean>;
  }>;
  locationData: SimpleLocation[] = [];
  locationsExcludingUserLocation: SimpleLocation[] = [];

  ngOnChanges(changes: SimpleChanges) {
    const locations = this.locations();
    if ('locations' in changes && locations) {
      const userLocation = this.user()?.location;
      this.locationsExcludingUserLocation = userLocation
        ? locations?.filter((item) => item.id !== userLocation)
        : locations;
      this.locationData = this.locationsExcludingUserLocation;

      this.form = this.formBuilder.group({
        locations: new FormArray<FormControl<boolean>>(
          [],
          this.minSelectedCheckboxes(1),
        ),
        copy_recipes: new FormControl<boolean>(false),
        copy_diets: new FormControl<boolean>(false),
      });
      this.addCheckboxes();
    }
  }

  private addCheckboxes() {
    this.locationData.forEach(() =>
      this.locationsFormArray.push(new FormControl(false)),
    );
  }

  get locationsFormArray() {
    return this.form.controls.locations;
  }

  minSelectedCheckboxes(min = 1): ValidatorFn {
    const validator: ValidatorFn = (
      formArray: FormArray<FormControl<boolean>>,
    ) => {
      const totalSelected = formArray.controls
        .map((ctr) => +ctr.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0);
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  submit() {
    const selectedLocations = this.form.value.locations
      .map((checked, i) => (checked ? this.locationData[i].id : null))
      .filter((v) => v !== null);

    const shareMenuData: ShareMenuDetail = {
      locations: selectedLocations,
      copy_recipes: this.form.controls.copy_recipes.value,
      copy_diets: this.form.controls.copy_diets.value,
    };
    this.shareMenuAction.emit(shareMenuData);
  }
}
