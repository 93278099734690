import { sharedFeature } from 'src/app/shared/ngrx/shared.state';
import {
  Component,
  DestroyRef,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  input,
  inject,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import printJS from 'print-js';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  PDFDocumentProxy,
  PdfViewerComponent,
  PdfViewerModule,
} from 'ng2-pdf-viewer';
import { LOCAL_STORAGE_KEY } from 'src/app/app.config';
import { State } from 'src/app/reducers';
import { LangButton } from 'src/app/shared/Models/langButton';
import {
  clearPreviewHtml,
  fetchPreviewHtml,
  clearPreviewPdfHtml,
  fetchPreviewPdfHtml,
  handleHttpError,
} from 'src/app/shared/ngrx/shared.actions';
import { FileService } from 'src/app/shared/Services/files/files.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { getFullURL } from 'src/app/shared/utils.functions';
import { AsyncPipe, CommonModule } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialogActions } from '@angular/material/dialog';
import { HtmlPreviewComponent } from './html-preview/html-preview.component';
import { PageSelectorDirective } from './page-selector.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LanguageButtonsComponent } from '../language-buttons/language-buttons.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PdfHtmlPreviewComponent } from './pdf-html-preview/pdf-html-preview.component';
import { ContentLanguage } from '../../constants/languages';

interface PreviewDialogData {
  url: string;
  params: any;
  baseLanguage: string;
  numberLanguages: number;
  langs: LangButton[];
  multiRequired: boolean;
  hideLangs: boolean;
  htmlPreview: boolean;
  action: string;
  blob: Blob;
  blobUrl: string;
}

@Component({
  selector: 'app-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    LanguageButtonsComponent,
    MatButtonToggleModule,
    ReactiveFormsModule,
    MatButtonModule,
    PageSelectorDirective,
    PdfViewerModule,
    HtmlPreviewComponent,
    MatDialogActions,
    MatIconModule,
    SpinnerComponent,
    AsyncPipe,
    TranslocoPipe,
    PdfHtmlPreviewComponent,
  ],
})
export class PreviewComponent implements OnInit, OnDestroy, OnChanges {
  private fileService = inject(FileService);
  private ngrxStore = inject<Store<State>>(Store);
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);
  private destroyRef = inject(DestroyRef);
  protected data = inject<PreviewDialogData>(MAT_DIALOG_DATA, {
    optional: true,
  });

  readonly action = input('');
  readonly blob = input<Blob>(undefined);
  readonly blobUrl = input<string>(undefined);
  readonly hideButtons = input<boolean>(undefined);
  readonly langs = input<LangButton[]>(undefined);
  readonly multiRequired = input<boolean>(undefined);
  readonly nonModal = input<boolean>(undefined);
  readonly numberLanguages = input(1);
  readonly params = input<any>({});
  readonly url = input<string>(undefined);
  readonly baseLanguage = input<string>(undefined);
  readonly showRefresh = input<boolean>(undefined);
  readonly showFormat = input<boolean>(undefined);
  readonly hideLangs = input(false);
  readonly htmlPreview = input<boolean>(true);

  public page = 1;
  public src:
    | string
    | {
        url: string;
        withCredentials: boolean;
        httpHeaders: object;
      } = '';

  cachedBlobUrl: string;
  debouncer$ = new Subject<void>();
  formatControl = new FormControl<'html' | 'pdf'>('pdf');
  holdPage = false;
  isDownloading = false;
  langParams: { base_lang?: ContentLanguage; lang?: ContentLanguage[] } = {};
  show: boolean;

  previewHtml$ = this.ngrxStore.select(sharedFeature.selectPreviewHtml);
  previewPdfHtml$ = this.ngrxStore.select(sharedFeature.selectPreviewPdfHtml);

  readonly pdfComponent = viewChild(PdfViewerComponent);
  readonly print = viewChild<ElementRef>('print');
  private messsageEventListner;

  constructor() {
    this.show = this.data ? !!this.data.url : !!this.url();
  }

  ngOnInit(): void {
    this.debouncer$
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.refresh(false));
    this.formatControl.valueChanges
      .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (value === 'pdf' && (this.data?.htmlPreview ?? this.htmlPreview()))
          this.fetchPdfHtml();
        else if (
          value === 'pdf' &&
          !(this.data?.htmlPreview ?? this.htmlPreview())
        )
          this.fetchPdf(false);
        else this.fetchHtml();
      });
    const url = this.data?.url ?? this.url();
    const langs = this.data?.langs ?? this.langs();
    if (langs && url) this.langsChanged(langs);
    if (!langs && url) this.refresh();
    if (this.data?.blob ?? this.blob()) this.renderBlob();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('langs' in changes && this.langs()) {
      this.clearHtml();
    }
  }

  private clearHtml(): void {
    this.ngrxStore.dispatch(clearPreviewHtml());
  }

  private clearPdfHtml(): void {
    this.ngrxStore.dispatch(clearPreviewPdfHtml());
    if (this.messsageEventListner) {
      window.removeEventListener('message', this.messsageEventListner);
    }
  }

  constructUrl = (): string => {
    const blobUrl = this.data?.blobUrl ?? this.blobUrl();
    if (blobUrl) return blobUrl;
    if (this.data?.action ?? this.action())
      return `${this.data?.url ?? this.url()}${this.data?.action ?? this.action()}/`;
    return this.data?.url ?? this.url();
  };

  fetchHtml(): void {
    this.ngrxStore.dispatch(
      fetchPreviewHtml({ url: this.data?.url ?? this.url() }),
    );
  }

  fetchPdfHtml() {
    const ref = this;
    this.messsageEventListner = window.addEventListener(
      'message',
      function (event) {
        if (event.data.name === 'pagedJsInit') {
          ref.show = true;
        }
      },
    );

    this.ngrxStore.dispatch(
      fetchPreviewPdfHtml({
        url: this.constructUrl(),
        params: {
          ...(this.data?.params ?? this.params()),
          ...this.langParams,
          response: 'html',
          pdf_mode: true,
        },
      }),
    );
  }

  fetchPdf(holdPage: boolean): void {
    this.holdPage = holdPage;
    this.src = {
      url: getFullURL(this.constructUrl(), {
        ...(this.data?.params ?? this.params()),
        ...this.langParams,
      }),
      withCredentials: true,
      httpHeaders: {
        Authorization: 'Token ' + localStorage.getItem(LOCAL_STORAGE_KEY),
      },
    };
  }

  private fetchPdfAndPrint(): void {
    this.fileService
      .fetchRenderedTemplate(this.constructUrl(), {
        ...(this.data?.params ?? this.params()),
        ...this.langParams,
        print: true,
      })
      .subscribe({
        next: (result) => {
          this.printPdf(result);
        },
        error: (error) => {
          this.ngrxStore.dispatch(handleHttpError({ error }));
        },
      });
  }

  downloadPdf = (): void => {
    this.isDownloading = true;
    this.fileService
      .downloadFile(this.constructUrl(), {
        ...(this.data?.params ?? this.params()),
        ...this.langParams,
        download: true,
      })
      .add(() => {
        this.isDownloading = false;
      });
  };

  langsChanged(langs: LangButton[]): void {
    this.langParams = this.utils.getParams(
      langs,
      this.data?.baseLanguage ?? this.baseLanguage(),
      null,
      null,
    ) as { base_lang?: ContentLanguage; lang?: ContentLanguage[] };
    this.debouncer$.next();
  }

  pageRendered(): void {
    this.pdfComponent().pdfViewer.scroll.down = false;
  }

  printDocument(): Promise<void> | void {
    const blob = this.data?.blob ?? this.blob();
    if (blob) {
      return this.printBlob(blob);
    }
    if (this.cachedBlobUrl) {
      return this.printPdf(this.cachedBlobUrl);
    }
    this.fetchPdfAndPrint();
  }

  private async printBlob(blob: Blob): Promise<void> {
    const base64 = await this.utils.blobToBase64(blob);
    printJS({
      printable: base64,
      type: 'image',
      base64: true,
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  }

  private printPdf(url: string): void {
    const modalMessage = this.translate.translate('app.pdf-loading-overlay');
    printJS({
      printable: url,
      type: 'pdf',
      showModal: true,
      modalMessage,
      onError: (error) => {
        Sentry.captureException(error);
      },
    });
  }

  refresh(holdPage = false): void {
    this.show = false;
    if (this.formatControl.value === 'pdf' && this.htmlPreview()) {
      this.ngrxStore.dispatch(clearPreviewPdfHtml());
      this.fetchPdfHtml();
    } else if (this.formatControl.value === 'pdf' && !this.htmlPreview()) {
      this.fetchPdf(holdPage);
    } else {
      this.ngrxStore.dispatch(clearPreviewHtml());
      this.fetchHtml();
    }
  }

  private renderBlob(): void {
    const reader = new FileReader();
    reader.readAsDataURL(this.data?.blob ?? this.blob());
    reader.onloadend = () => {
      this.src = reader.result as string;
    };
  }

  renderPdf = async (event: PDFDocumentProxy): Promise<void> => {
    const data = await event.getData();
    this.cachedBlobUrl = URL.createObjectURL(
      new Blob([data], { type: 'octet/stream' }),
    );
    this.show = true;
    this.pageRendered();
  };

  ngOnDestroy(): void {
    this.clearHtml();
    this.clearPdfHtml();
  }
}
