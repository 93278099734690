<div class="wrapper msm-theme">
  <div class="inner-wrapper">
    <a href="https://www.rentabilibar.es/"
      ><img
        src="assets/img/logos/mahou-logo-white.svg"
        alt="mahou logo white"
        class="logo"
    /></a>
    <div class="right">
      <a href="https://www.rentabilibar.es/ayuda/gestion-de-incidencias"
        ><img src="assets/img/triangulo-naranja.png" alt="" class="triangle"
      /></a>
      <a href=""></a
      ><img
        src="assets/img/rentabilibar_user.svg"
        alt="user icon"
        class="person"
      />

      <ul class="list">
        <li class="list-element">
          <a href="https://www.rentabilibar.es/"
            >Usuario de <strong>{{ user().partner_username }}</strong></a
          >
        </li>
        <li class="list-element">
          <a href="https://www.rentabilibar.es/">... pts</a>
        </li>
        <li class="list-element">
          <a href="https://www.rentabilibar.es/"
            >acceder <span class="red">(0)</span></a
          >
        </li>
        <li class="list-element">
          <a href="https://www.rentabilibar.es/login?logout">desconectar</a>
        </li>
      </ul>

      <div class="search">
        <a href="https://www.rentabilibar.es/"
          ><img
            class="search"
            src="assets/img/rentabilibar_search.svg"
            alt="search field"
            class="search"
        /></a>
      </div>
    </div>
  </div>
</div>
