import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'menu-view-settings-item',
  template: `
    <mat-slide-toggle
      color="primary"
      [checked]="data().value"
      [disabled]="data().disabled"
      (change)="changeHandler($event)"
    >
      <span class="label">{{
        'write.blocks.sidebar.menu-settings.options.' + data().label + '.title'
          | transloco
      }}</span>
      @if (data().label === 'add_ai') {
        <mat-icon class="special-feature">new_releases</mat-icon>
      }
      <p class="mat-caption setting-description">
        {{
          'write.blocks.sidebar.menu-settings.options.' +
            data().label +
            '.description' | transloco
        }}
      </p>
    </mat-slide-toggle>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      .label {
        word-break: break-all;
        font-weight: 400;
      }
      .setting-description {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 17px;
      }
      .special-feature {
        margin: -5px 0 -5px 4px;
        color: var(--app-primary-500);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSlideToggleModule, MatIconModule, TranslocoPipe],
})
export class MenuViewSettingsItemComponent {
  readonly data = input<ToggleItem>(undefined);
  readonly changed = output<any>();

  changeHandler(event) {
    this.changed.emit({
      [this.data().label]: event.checked,
    });
  }
}

interface ToggleItem {
  label: string;
  value: boolean;
  disabled: boolean;
}
