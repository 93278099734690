import { AfterViewInit, Directive, OnDestroy, input } from '@angular/core';

@Directive({
  selector: '[add-class]',
  standalone: true,
})
export class AddClassDirective implements OnDestroy, AfterViewInit {
  readonly className = input<string>(undefined, { alias: 'add-class' });
  // eslint-disable-next-line @angular-eslint/no-input-rename
  readonly selector = input<string>(undefined, { alias: 'to' });

  ngOnDestroy(): void {
    document.querySelector(this.selector()).classList.remove(this.className());
  }

  ngAfterViewInit(): void {
    document.querySelector(this.selector()).classList.add(this.className());
  }
}
