import { Component, inject, input } from '@angular/core';
import { NgModel, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import {
  Feedback,
  RatingChoice,
  RatingChoices,
} from 'src/app/shared/Models/feedback';
import { leaveFeedback } from 'src/app/shared/user/ngrx/user.actions';
import { TranslocoPipe } from '@jsverse/transloco';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgClass,
    MatButtonModule,
    SpinnerComponent,
    TranslocoPipe,
  ],
})
export class FeedbackComponent {
  private ngrxStore = inject<Store<State>>(Store);
  dialog = inject(MatDialog);

  readonly showEmail = input(false);

  feedback: Feedback = new Feedback();
  loading = false;
  rating: RatingChoice;
  ratingChoices = RatingChoices;

  setRating = (choice: RatingChoice) => (this.rating = choice);

  submit(event: Event, model: NgModel) {
    event.preventDefault();
    this.loading = true;
    this.ngrxStore.dispatch(
      leaveFeedback({
        ...(this.showEmail() ? { email: this.feedback.email } : {}),
        message: this.feedback.message,
        rating: this.rating,
      }),
    );
  }
}
