<div class="item-wrapper item-title">
  <div class="element-title">
    {{ 'style.sidebar.numbers.size_' + field() | transloco }}
  </div>
  @if (showLock()) {
    <lock-item
      [field]="[
        'size_' + field(),
        'font_' + field(),
        'capitalisation_' + field(),
        'weight_' + field(),
        'colour_' + field(),
        'letter_spacing_' + field(),
        'italics_' + field(),
      ]"
      [lockedFields]="lockedFields()"
      (updateMenu)="updateMenu.emit($event)"
    ></lock-item>
  }
</div>
<div class="item-wrapper">
  <number-item
    class="size"
    type="value"
    unit="pt"
    [field]="'size_' + field()"
    [hideLabel]="true"
    [lockedFields]="lockedFields()"
    [disabledFields]="disabledFields()"
    [menu]="menu()"
    [showLock]="false"
    (updateMenu)="updateMenu.emit($event)"
  ></number-item>
  <div class="variant-color">
    @if (!hideFonts()) {
      <select-item
        class="font-weight"
        [menu]="menu()"
        [hideLabel]="true"
        [field]="'weight_' + field()"
        [list]="fontWeights"
        [lockedFields]="lockedFields()"
        [disabledFields]="disabledFields()"
        [showLock]="false"
        [translationKey]="'weight'"
        (updateMenu)="updateMenu.emit($event)"
      ></select-item>
    }
    <div class="element-wrapper">
      <mt-color-picker
        class="mt-color-picker"
        [isLocked]="isLocked('colour_' + field())"
        [disabled]="disabledFields().includes('colour_' + field())"
        [class.color-picker-disabled]="isLocked('colour_' + field())"
        [color]="menu()?.style && menu()?.style['colour_' + field()]"
        (colorChange)="colorChanged($event)"
      >
      </mt-color-picker>
    </div>
  </div>
</div>
@if (!hideFonts()) {
  <div class="item-wrapper family-style">
    <div class="element-wrapper-font">
      <mat-form-field
        class="font-picker-wrapper"
        subscriptSizing="dynamic"
        #fontPickerInput="ngxFontPicker"
        [mtTooltip]="
          disabledFields().includes('font_' + field())
            ? ('style.sidebar.restrictions.layout-field-locked' | transloco)
            : ''
        "
        [fpFilterByFamilies]="mtFontList"
        [fpSortByFamilies]="true"
        [fpHeight]="'150px'"
        [fpWidth]="'150px'"
        [fpSizeSelect]="false"
        [fpStyleSelect]="false"
        [fpUseRootViewContainer]="false"
        [(fontPicker)]="font"
        (fontPickerChange)="fontChanged($event, 'font_' + field())"
      >
        <input
          class="font-family"
          autocomplete="off"
          matInput
          type="text"
          readonly
          [disabled]="
            !mtFonts() ||
            !menu() ||
            disabledFields().includes('font_' + field())
          "
          [ngModel]="menu()?.style && menu()?.style['font_' + field()]"
        />
        <mat-icon class="font-picker-icon">arrow_drop_down</mat-icon>
      </mat-form-field>
    </div>
    @if (!hideCapitalisation()) {
      <select-item
        class="font-caps"
        [menu]="menu()"
        [hideLabel]="true"
        [field]="'capitalisation_' + field()"
        [list]="fontCapitals"
        [lockedFields]="lockedFields()"
        [disabledFields]="disabledFields()"
        [showLock]="false"
        [translationKey]="'capitalisation'"
        (updateMenu)="updateMenu.emit($event)"
      ></select-item>
    }
  </div>
}
@if (!hideFonts()) {
  <div class="item-wrapper">
    <number-item
      class="size"
      type="value"
      icon="format_letter_spacing_2"
      unit="pt"
      [field]="'letter_spacing_' + field()"
      [hideLabel]="true"
      [lockedFields]="lockedFields()"
      [disabledFields]="disabledFields()"
      [menu]="menu()"
      [showLock]="false"
      (updateMenu)="updateMenu.emit($event)"
    ></number-item>
    @if (italic) {
      <toggle-item
        class="toggle"
        [checkbox]="true"
        [field]="'italics_' + field()"
        [lockedFields]="lockedFields()"
        [disabledFields]="disabledFields()"
        [menu]="menu()"
        [showLock]="false"
        (updateMenu)="updateMenu.emit($event)"
      ></toggle-item>
    }
  </div>
}
