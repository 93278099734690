<form [formGroup]="filtersForm">
  <h3 class="header">{{ 'menus.overview.filter_menus' | transloco }}</h3>
  <mat-form-field class="menu-filter">
    <mat-label>{{ 'dishes.table.search' | transloco }}</mat-label>
    <input
      #searchInput
      matInput
      formControlName="name"
      name="name"
      type="text"
    />
    <button
      matSuffix
      mat-icon-button
      [attr.aria-label]="searchInput.value ? 'Clear' : 'Search'"
      (click)="searchInput.value ? clearFilter('name', $event) : true"
    >
      <mat-icon>
        @if (searchInput.value) {
          clear
        } @else {
          search
        }
      </mat-icon>
    </button>
  </mat-form-field>

  @if (
    (originalLanguageList && originalLanguageList.length > 1) ||
    params()?.base_language
  ) {
    <language-selector
      #langSelector
      changeValue="base_language"
      [showClear]="!!params()?.base_language"
      [extendLangs]="true"
      [immutable]="false"
      [limitLangs]="originalLanguageList"
      [model]="params()"
      [placeholder]="'menus.filter.by_base_language' | transloco"
      (changed)="chooseFilter.emit({})"
      (clear)="clearFilter('base_language', $event)"
    >
    </language-selector>
  }

  <mat-form-field #dateRange>
    <mat-label>
      {{ 'menus.filter.by_date' | transloco }}
    </mat-label>
    <mat-date-range-input
      [rangePicker]="picker"
      [rangePicker]="daterangePicker"
    >
      <input
        matStartDate
        formControlName="date_from"
        class="date_input"
        matInput
        (click)="daterangePicker.open()"
      />
      <input
        matEndDate
        formControlName="date_to"
        class="date_input"
        [min]="params().date_from"
        matInput
        (click)="daterangePicker.open()"
      />
    </mat-date-range-input>
    <mat-error>{{ filtersForm.controls.date_from.errors | json }}</mat-error>
    <mat-datepicker-toggle
      matSuffix
      [for]="daterangePicker"
    ></mat-datepicker-toggle>
    <mat-date-range-picker #daterangePicker></mat-date-range-picker>
    <mat-date-range-picker #picker></mat-date-range-picker>
    @if (
      filtersForm.controls['date_from'].value ||
      filtersForm.controls['date_to'].value
    ) {
      <button
        mat-icon-button
        matSuffix
        (click)="
          clearFilter('date_from', $event); clearFilter('date_to', $event)
        "
      >
        <mat-icon class="clear">clear</mat-icon>
      </button>
    }
  </mat-form-field>

  @if (templates()?.length) {
    <mat-form-field>
      <mat-label>{{ 'menus.filter.by_menu' | transloco }}</mat-label>
      <mat-select
        class="filter-select"
        formControlName="onboarding_template"
        name="onboarding_template"
      >
        @for (template of templates(); track template) {
          <mat-option [value]="template.id">
            {{ template[lang()] }}
          </mat-option>
        }
      </mat-select>
      @if (filtersForm.controls['onboarding_template'].value) {
        <button
          mat-icon-button
          matSuffix
          (click)="clearFilter('onboarding_template', $event)"
        >
          <mat-icon class="clear">clear</mat-icon>
        </button>
      }
    </mat-form-field>
  }

  @if (!user().location && locationGroups()?.length > 0) {
    <mat-form-field>
      <mat-label>{{ 'menus.filter.by_location_group' | transloco }}</mat-label>
      <mat-select
        class="filter-select"
        formControlName="location_group"
        name="location_group"
      >
        @for (group of locationGroups(); track group) {
          <mat-option [value]="group.id">
            {{ group?.name }}
          </mat-option>
        }
      </mat-select>
      @if (filtersForm.controls['location_group'].value) {
        <button
          mat-icon-button
          matSuffix
          (click)="clearFilter('location_group', $event)"
        >
          <mat-icon class="clear">clear</mat-icon>
        </button>
      }
    </mat-form-field>
  }

  @if (!user().location && locations()?.length > 0) {
    <mat-form-field>
      <mat-label>{{ 'menus.filter.by_location' | transloco }}</mat-label>
      <mat-select
        #locationSelect
        class="filter-select"
        formControlName="location"
        name="location"
      >
        @for (location of locations(); track location) {
          <mat-option [value]="location.id">
            {{ location?.name }}
          </mat-option>
        }
      </mat-select>
      @if (filtersForm.controls['location'].value) {
        <button
          mat-icon-button
          matSuffix
          (click)="
            clearFilter('location', $event);
            locationSelect && (locationSelect['value'] = undefined)
          "
        >
          <mat-icon class="clear">clear</mat-icon>
        </button>
      }
    </mat-form-field>
  }

  @if (organisationUsers()?.length > 0) {
    <mat-form-field>
      <mat-label>{{
        'menus.filter.by_organisation_users' | transloco
      }}</mat-label>
      <mat-select
        #userSelect
        class="filter-select"
        formControlName="created_by"
        name="created_by"
      >
        @for (user of organisationUsers(); track user) {
          <mat-option [value]="user.id">
            {{ user?.last_name }}, {{ user?.first_name }}
          </mat-option>
        }
      </mat-select>
      @if (filtersForm.controls['created_by'].value) {
        <button
          mat-icon-button
          matSuffix
          (click)="
            clearFilter('created_by', $event);
            userSelect && (userSelect['value'] = undefined)
          "
        >
          <mat-icon class="clear">clear</mat-icon>
        </button>
      }
    </mat-form-field>
  }

  @if (locations()?.length > 0) {
    <div class="filters-checkbox">
      <mat-checkbox
        color="primary"
        [checked]="hideLocationMenus"
        (change)="changeCheckbox($event, 'hide_location_menus')"
      >
        @if (!user().location && !currentLocation()) {
          {{ 'menus.filter.hide_created_by_location' | transloco }}
        } @else {
          {{ 'menus.filter.hide_created_by_admin' | transloco }}
        }
      </mat-checkbox>
    </div>
  }

  @if (hasModule('order')) {
    <div class="filters-checkbox">
      <mat-checkbox
        color="primary"
        [checked]="showOrdertakingOnly || showOrdertakingAutomatedOnly"
        [disabled]="showOrdertakingAutomatedOnly"
        (change)="changeCheckbox($event, 'enable_ordertaking')"
      >
        {{ 'menus.filter.show_ordertaking_only' | transloco }}
      </mat-checkbox>
    </div>
  }
  @if (hasModule('order')) {
    <div class="filters-checkbox">
      <mat-checkbox
        color="primary"
        [checked]="showOrdertakingAutomatedOnly"
        (change)="changeCheckbox($event, 'enable_automated_orders')"
      >
        {{ 'menus.filter.show_automated_orders_only' | transloco }}
      </mat-checkbox>
    </div>
  }

  <div class="filters-checkbox">
    <mat-checkbox
      color="primary"
      [checked]="emptyMenus"
      (change)="changeCheckbox($event, 'empty')"
    >
      {{ 'menus.filter.show_empty' | transloco }}
    </mat-checkbox>
  </div>
</form>
