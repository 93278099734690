import { TemplatePortal } from '@angular/cdk/portal';
import { UserCategory } from 'src/app/shared/Models/user';
import { Observable } from 'rxjs';
import { OnboardingTemplate } from './onboarding_template';

export interface RegistrationOption {
  view?: string;
  value?: UserCategory;
  icon?: string;
  active?: boolean;
  other?: boolean;
  payload?: string;
}

export interface ObservablePortal {
  portal: TemplatePortal;
  eventStream: Observable<true | undefined>;
}

export function isRegistrationOption(
  item: RegistrationOption | (OnboardingTemplate & { active: boolean }),
): item is RegistrationOption {
  return 'other' in item && item?.other !== undefined;
}
