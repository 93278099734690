import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  OnDestroy,
  ChangeDetectionStrategy,
  output,
  input,
} from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Style, PatchStyleNameData } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'select-style',
  templateUrl: './select-style.component.html',
  styleUrls: ['./select-style.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MtTooltipDirective,
    TranslocoPipe,
  ],
})
export class SelectStyleComponent implements OnChanges, OnInit, OnDestroy {
  readonly canModify = input<boolean>(undefined);
  readonly styleSpinner = input<string>(undefined);
  readonly create = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly modified = input<boolean>(undefined);
  readonly selected = input<boolean>(undefined);
  readonly style = input<Style>(undefined);

  readonly createStyle = output();
  readonly deleteStyle = output();
  readonly openDialog = output<void>();
  readonly renameStyle = output<PatchStyleNameData>();
  readonly selectStyle = output<void>();
  readonly updateStyle = output<void>();

  nameControl = new FormControl('', {
    updateOn: 'blur',
  });
  private destroyed$ = new Subject<void>();
  loading = false;

  ngOnInit(): void {
    this.nameControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((newName) =>
        this.renameStyle.emit({ [this.lang()]: newName }),
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('style' in changes) {
      this.nameControl.setValue(this.style()[this.lang()], {
        emitEvent: false,
      });
    }
    if ('canModify' in changes) {
      this.canModify() ? this.nameControl.enable() : this.nameControl.disable();
    }
    if ('styleSpinner' in changes && !this.styleSpinner()) {
      this.loading = false;
    }
  }

  emitSelectStyle(): void {
    this.loading = true;
    this.selectStyle.emit();
  }

  emitUpdateStyle(): void {
    this.loading = true;
    this.updateStyle.emit();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
