import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { API_CONFIG, LOCAL_STORAGE_KEY } from 'src/app/app.config';
import { State } from 'src/app/reducers';
import { DownloadReportDetails } from 'src/app/shared/Models/menu';
import { handleHttpError } from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Buffer } from 'buffer';
import { map } from 'rxjs/operators';
import { InlineImage } from '../../Models/models';
import { Observable } from 'rxjs';

export enum FileUploadApi {
  support = 'support',
  menu = 'menu',
  product = 'product',
  customDeclaration = 'custom_declaration',
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private http = inject(HttpClient);
  private utils = inject(UtilsService);
  private ngrxStore = inject<Store<State>>(Store);

  public api = `/support/`;
  private imageApi = '/inline_images/';
  public menuImportApi = `/menu_import/`;
  public productImportApi = `/product_import/`;
  public downloadReportApi = `/dishes/reports/`;
  public customDeclarationApi = `/custom_declaration/`;

  constructor() {
    this.api = API_CONFIG.value + this.api;
    this.imageApi = API_CONFIG.value + this.imageApi;
    this.menuImportApi = API_CONFIG.value + this.menuImportApi;
    this.productImportApi = API_CONFIG.value + this.productImportApi;
    this.downloadReportApi = API_CONFIG.value + this.downloadReportApi;
  }

  parseContentDisposition = (disposition: string) => {
    function b64DecodeUnicode(str: string) {
      return decodeURIComponent(
        Buffer.from(str, 'base64')
          .toString('binary')
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
    }

    function decodeQuotedPrintable(str: string) {
      return decodeURIComponent(
        str.replace(/=[0-9A-F]{2}/gi, (match) => {
          return '%' + match.slice(1);
        }),
      );
    }

    if (disposition.startsWith('=?utf-8?b?')) {
      disposition = disposition.substring(10, disposition.length - 2);
      disposition = b64DecodeUnicode(disposition);
    } else if (disposition.startsWith('=?utf-8?q?')) {
      disposition = disposition.substring(10, disposition.length - 2);
      disposition = decodeQuotedPrintable(disposition);
    }

    if (!disposition || !disposition.includes('"')) return 'download';
    return disposition.split('"')[1].trim();
  };

  downloadFile = (
    url: string,
    params: any,
    postData?: any,
    onFulfilled?: () => void,
    put = false,
    patch = false,
    forceSnackbar = false,
  ) => {
    return this.fetchFile(url, params, postData, put, patch)
      .pipe(
        map((res) => {
          if (res === undefined) throw new Error('Timeout');
          return res;
        }),
      )
      .subscribe({
        next: (res) => {
          const data = res.body;
          const filename = res.headers.get('content-disposition')
            ? this.parseContentDisposition(
                res.headers.get('content-disposition'),
              )
            : 'download.' + res.body.type.split('/')[1];
          const windowUrl = window.URL.createObjectURL(data);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = windowUrl;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(windowUrl);
          a.remove();
          onFulfilled?.();
        },
        error: (error) => {
          this.ngrxStore.dispatch(handleHttpError({ error, forceSnackbar }));
        },
      });
  };

  uploadFile = (
    url: string,
    file: File,
    itemAlias: string,
    method: 'POST' | 'PATCH' = 'POST',
  ) => {
    const data = new FormData();
    data.append(itemAlias, file);
    return method === 'POST'
      ? this.http.post(url, data)
      : this.http.patch(url, data);
  };

  uploadForm = <T>(
    data?: any,
    files?: File[],
    itemAlias?: string,
    api: FileUploadApi = FileUploadApi.support,
    params: any = {},
    url = '',
    patch = false,
  ): Observable<T> => {
    let apiEndpoint = '';
    switch (api) {
      case FileUploadApi.support:
        apiEndpoint = this.api;
        break;
      case FileUploadApi.menu:
        apiEndpoint = this.menuImportApi;
        break;
      case FileUploadApi.product:
        apiEndpoint = this.productImportApi;
        break;
      case FileUploadApi.customDeclaration:
        apiEndpoint = url;
    }
    const newData = new FormData();
    if (data) {
      Object.entries(data).forEach(([key, value]: [string, any]) =>
        newData.append(key, value),
      );
    }
    if (files?.length) {
      files.forEach((file) => newData.append(itemAlias, file));
    }
    return patch
      ? this.http.patch<T>(apiEndpoint, newData, { params })
      : this.http.post<T>(apiEndpoint, newData, { params });
  };

  fetchFile = (
    url: string,
    params: any,
    data: any,
    put = false,
    patch = false,
  ) => {
    if (data && patch) {
      return this.http.patch(url, data, {
        observe: 'response',
        responseType: 'blob',
        ...(params ? { params: this.utils.createQuery(params) } : {}),
        withCredentials: true,
      });
    }
    if (data && put) {
      return this.http.put(url, data, {
        observe: 'response',
        responseType: 'blob',
        ...(params ? { params: this.utils.createQuery(params) } : {}),
        withCredentials: true,
      });
    }
    if (data) {
      return this.http.post(url, data, {
        observe: 'response',
        responseType: 'blob',
        ...(params ? { params: this.utils.createQuery(params) } : {}),
        withCredentials: true,
      });
    }
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
      ...(params ? { params: this.utils.createQuery(params) } : {}),
      withCredentials: true,
    });
  };

  fetchBlob = (url: string, params = { lang: 'any' }) => {
    return this.http.get(url, {
      responseType: 'blob',
      withCredentials: true,
      params: this.utils.createQuery(params),
    });
  };

  fetchBlobPost = (url: string, data: DownloadReportDetails) => {
    return this.http.post(url, data, { responseType: 'blob' });
  };

  fetchRenderedTemplateFromPost = (url: string, data: DownloadReportDetails) =>
    this.fetchBlobPost(url, data).pipe(
      map((response) => window.URL.createObjectURL(response)),
    );

  fetchRenderedTemplate = (url: string, params?: any) =>
    this.fetchBlob(url, params).pipe(
      map((response) => window.URL.createObjectURL(response)),
    );

  uploadPicture = (data: FormData) =>
    this.http.post<InlineImage>(`${this.imageApi}`, data, {
      headers: new HttpHeaders({
        Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_KEY)}`,
      }),
    });
}
