import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@jsverse/transloco';
import {
  addMenuTranslation,
  getCurrentMenu,
} from '../../ngrx/menu-edit.actions';
import {
  catchError,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { Results } from 'src/app/shared/Models/generics';
import {
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { selectCurrentMenu } from '../../ngrx/menu-edit.selectors';
import { State } from 'src/app/reducers';
import { Translation } from 'src/app/shared/Models/translation';
import * as MenuTranslateActions from './menu-translate.actions';
import {
  selectCurrentTranslation,
  selectSimilarTranslationsNextUrl,
} from './menu-translate.selectors';
import { EMPTY } from 'rxjs';
import { setCurrentTranslations } from './menu-translate.actions';
import * as _ from 'lodash-es';
import { setLoadingFull } from '../../write/ngrx/menu-write.actions';

@Injectable()
export class MenusTranslateEffects {
  private actions$ = inject(Actions);
  private configService = inject(ConfigService);
  private genericsService = inject(GenericsService);
  private router = inject(Router);
  private store = inject<Store<State>>(Store);
  private transloco = inject(TranslocoService);

  expressTranslateMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuTranslateActions.expressTranslateMenu),
      tap(() => {
        this.store.dispatch(setLoadingFull({ loading: true }));
      }),
      switchMap(({ menuId, menuTranslationId, params }) =>
        this.genericsService
          .post<
            {},
            { message: string }
          >(`${this.configService.menus}${menuId}/translations/${menuTranslationId}/auto_translate/`, {})
          .pipe(
            mergeMap(() => [
              getCurrentMenu({ id: menuId, hard: true, condensed: false }),
            ]),
            catchError((error) => [
              setLoadingFull({ loading: false }),
              handleHttpError({ error }),
            ]),
          ),
      ),
    ),
  );

  fetchMenuTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuTranslateActions.fetchMenuTranslations),
      tap(() =>
        this.store.dispatch(
          MenuTranslateActions.setMenuTranslationLoading({ loading: true }),
        ),
      ),
      switchMap(({ menu }) =>
        this.genericsService.get<Translation[]>(`${menu.translations}`).pipe(
          mergeMap((translations) => [
            MenuTranslateActions.setMenuTranslations({ translations }),
            MenuTranslateActions.setMenuTranslationLoading({ loading: false }),
          ]),
          catchError((error) => [
            MenuTranslateActions.setMenuTranslationLoading({ loading: false }),
            handleHttpError({ error }),
          ]),
        ),
      ),
    ),
  );

  deleteMenuTranslation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuTranslateActions.deleteMenuTranslation),
      switchMap(({ url }) =>
        this.genericsService.delete(url).pipe(
          mergeMap(() => [MenuTranslateActions.removeMenuTranslation({ url })]),
          catchError((error) => [handleHttpError({ error })]),
        ),
      ),
    ),
  );

  createMenuTranslation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuTranslateActions.createMenuTranslation),
      withLatestFrom(this.store.select(selectCurrentMenu)),
      switchMap(([{ lang }, menu]) => {
        const data = {
          language: lang,
          printed_on: new Date(),
        };
        return this.genericsService
          .post<Partial<Translation>, Translation>(menu.translations, data)
          .pipe(
            mergeMap((translation) => {
              this.router.navigate([`/menus`, menu.id, 3], {
                queryParams: { lang },
              });
              return [
                addMenuTranslation({ lang: translation.language }),
                MenuTranslateActions.addTranslationForLanguage({ translation }),
              ];
            }),
            catchError((error) => [handleHttpError({ error })]),
          );
      }),
    ),
  );

  fetchSimilarTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuTranslateActions.fetchSimilarTranslations),
      tap(() =>
        this.store.dispatch(
          MenuTranslateActions.setCurrentTranslationLoading({ loading: true }),
        ),
      ),
      withLatestFrom(this.store.select(selectCurrentTranslation)),
      switchMap(
        ([
          { name, translationType, lang, currentLang },
          currentTranslation,
        ]) => {
          if (!currentTranslation) return undefined;
          return this.genericsService
            .get<Results<BaseNameExtended>>(this.configService.search, {
              ['type']: translationType,
              [`${currentLang}_similar`]: name,
              [`${lang}_isempty`]: false,
            })
            .pipe(
              mergeMap((result) => [
                MenuTranslateActions.setCurrentTranslationLoading({
                  loading: false,
                }),
                MenuTranslateActions.setSimilar({ result }),
              ]),
              catchError((error) => [
                MenuTranslateActions.setMenuTranslationLoading({
                  loading: false,
                }),
                handleHttpError({ error }),
              ]),
            );
        },
      ),
    ),
  );

  fetchMoreSimilarTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuTranslateActions.fetchMoreSimilarTranslations),
      withLatestFrom(this.store.select(selectSimilarTranslationsNextUrl)),
      switchMap(([, url]) =>
        this.genericsService
          .get<Results<BaseNameExtended>>(url)
          .pipe(
            mergeMap((result) => [
              MenuTranslateActions.setMoreSimilar({ result }),
            ]),
          ),
      ),
      catchError((error) => [handleHttpError({ error })]),
    ),
  );

  setTranslationToMenuDish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuTranslateActions.setTranslationToMenuDish),
      withLatestFrom(this.store.select(selectCurrentTranslation)),
      switchMap(
        ([
          { dish, lang, append, isDescription, isOther, isSecondary },
          current,
        ]) => {
          const newDish = _.cloneDeep(current);
          const descriptionField = `description_${
            isSecondary ? 'secondary_' : ''
          }${lang}`;
          if (isDescription && isOther) {
            if (append && current.user_details[descriptionField]) {
              newDish.user_details[descriptionField] =
                `${newDish.user_details[descriptionField]}, ${dish[lang]}`;
            } else {
              newDish.user_details[descriptionField] = dish[lang];
            }
          } else if (newDish.dish_detail) {
            if (isDescription) {
              if (
                append &&
                current.dish_detail.user_details[descriptionField]
              ) {
                newDish.dish_detail.user_details[descriptionField] =
                  `${newDish.dish_detail.user_details[descriptionField]}, ${dish[lang]}`;
              } else {
                newDish.dish_detail.user_details[descriptionField] = dish[lang];
              }
            } else {
              if (append && current.dish_detail[lang]) {
                newDish.dish_detail[lang] =
                  `${newDish.dish_detail[lang]}, ${dish[lang]}`;
              } else {
                newDish.dish_detail[lang] = dish[lang];
              }
            }
          } else if (newDish.separator_detail) {
            if (isDescription) {
              if (
                append &&
                current.separator_detail.user_details[descriptionField]
              ) {
                newDish.separator_detail.user_details[descriptionField] =
                  `${newDish.separator_detail.user_details[descriptionField]}, ${dish[lang]}`;
              } else {
                newDish.separator_detail.user_details[descriptionField] =
                  dish[lang];
              }
            } else {
              if (append && current.separator_detail[lang]) {
                newDish.separator_detail[lang] =
                  `${newDish.separator_detail[lang]}, ${dish[lang]}`;
              } else {
                newDish.separator_detail[lang] = dish[lang];
              }
            }
          }
          const message = this.transloco.translate(
            'write.dishes.allergens.use_same.actions.copied',
          );
          return [
            setCurrentTranslations({ menuDish: newDish }),
            showSnackbarMessage({ message }),
          ];
        },
      ),
      catchError(() => EMPTY),
    ),
  );
}
