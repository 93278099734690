import {
  Component,
  OnChanges,
  SimpleChanges,
  output,
  input,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { Diet } from 'src/app/shared/Models/diet';
import { SimpleLocation } from 'src/app/shared/Models/location';
import {
  TaskType,
  MenusOverviewParams,
  MenusResults,
  OverviewMenu,
} from 'src/app/shared/Models/menu';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { Ruleset } from 'src/app/shared/Models/ruleset';
import { Type } from 'src/app/shared/Models/type';
import { User, UserStatusPrivileges } from 'src/app/shared/Models/user';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatDividerModule } from '@angular/material/divider';
import { MenuOverviewItemComponent } from './menu-overview-item/menu-overview-item.component';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'menu-overview-table',
  templateUrl: './menu-overview-table.component.html',
  styleUrls: ['./menu-overview-table.component.scss'],
  imports: [
    MatCardModule,
    SpinnerComponent,
    MenuOverviewItemComponent,
    MatTooltipModule,
    MatButtonModule,
    MatDividerModule,
    MatPaginatorModule,
    TranslocoPipe,
  ],
})
export class MenuOverviewTableComponent implements OnChanges {
  readonly consumerTypes = input<Type[]>(undefined);
  readonly currentMenu = input<OverviewMenu>(undefined);
  readonly diets = input<Diet[]>(undefined);
  readonly loadingSpinner = input<boolean>(undefined);
  readonly locations = input<SimpleLocation[]>(undefined);
  readonly menus = input<MenusResults>(undefined);
  readonly params = input<Partial<MenusOverviewParams>>(undefined);
  readonly privileges = input<UserStatusPrivileges>(undefined);
  readonly rulesets = input<Ruleset[]>(undefined);
  readonly user = input<User>(undefined);
  readonly userStatus = input<AccountStatusMessage>(undefined);
  readonly userStatusBlockMessage = input<string>(undefined);

  readonly changeMenu = output<{
    menu: OverviewMenu;
    featured: boolean;
  }>();
  readonly changePagination = output<{
    page: number;
    pageSize: number;
  }>();
  readonly copyWithDiets = output<OverviewMenu>();
  readonly copyWithOptions = output<OverviewMenu>();
  readonly createBtnClicked = output<{
    btn: MatButton;
    tooltip: MatTooltip;
  }>();
  readonly deleteChildren = output<OverviewMenu>();
  readonly deleteMenu = output<OverviewMenu>();
  readonly generateArchive = output<{
    menu: OverviewMenu;
    rulesetId?: number;
    taskType: TaskType;
  }>();
  readonly createMenu = output<{
    menu: OverviewMenu;
    data: {
      id: number;
      entity: 'rule' | 'type';
    };
  }>();
  readonly openCreateMenu = output<void>();
  readonly previewMenu = output<OverviewMenu>();
  readonly refreshSubMenu = output<OverviewMenu>();
  readonly regenerateMenus = output<OverviewMenu>();
  readonly shareMenu = output<OverviewMenu>();
  readonly showCopyMenu = output<OverviewMenu>();
  readonly showSnackbarMessageEvent = output<{
    message: string;
  }>();

  consumerTypesByLocation: Record<number, Type[]> = {};
  dietsLocationsMap: Record<number, boolean> = {};

  ngOnChanges(changes: SimpleChanges): void {
    const diets = this.diets();
    if ('diets' in changes && diets) {
      diets.forEach((d) => {
        this.dietsLocationsMap[d.location] = true;
      });
    }
    const consumerTypes = this.consumerTypes();
    if ('consumerTypes' in changes && consumerTypes) {
      this.consumerTypesByLocation = {};
      consumerTypes.forEach((ct) => {
        if (!this.consumerTypesByLocation[ct.location ?? 0]) {
          this.consumerTypesByLocation[ct.location ?? 0] = [];
        }
        this.consumerTypesByLocation[ct.location ?? 0].push(ct);
      });
    }
  }

  handlePaginator(event: PageEvent): void {
    this.changePagination.emit({
      page: event.pageIndex > 0 ? event.pageIndex + 1 : null,
      pageSize: event.pageSize,
    });
  }
}
