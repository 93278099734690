import {
  interfaceLangs,
  interfaceLangsDescription,
} from '../constants/languages';
import { billingCurrencies } from './user';
export type InterfaceLangsObject = Record<
  (typeof interfaceLangs)[number],
  string
>;
export type InterfaceDescriptionsObject = Record<
  (typeof interfaceLangsDescription)[number],
  string
>;

export type BillingCurrenciesObject = Record<
  Exclude<(typeof billingCurrencies)[number], 'eur'>,
  number
>;

export function arrayToObject(
  arr: string[],
  prefix = '',
): Record<string, string> {
  return arr.reduce(
    (acc, curr) => ({
      ...acc,
      [`${prefix}${curr}`]: '',
    }),
    {},
  );
}

export type Writable<T> = {
  -readonly [Key in keyof T]: T[Key];
};

export type DeepWritable<T> = {
  -readonly [Key in keyof T]: T[Key] extends object
    ? DeepWritable<T[Key]>
    : T[Key];
};

export interface PaginatedResult<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}
