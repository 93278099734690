import { DeclarationKey } from '../constants/declarations';
import { BaseNameExtended } from './models';

export class GenericDeclaration {
  name: DeclarationKey;
  value: boolean;
  order: number;
  dependants: GenericDeclaration[];

  constructor(
    name: DeclarationKey,
    value: boolean,
    order: number,
    dependants: GenericDeclaration[],
  ) {
    this.name = name;
    this.value = value;
    this.order = order;
    this.dependants = dependants;
  }
}

export class DeclarationBase extends BaseNameExtended {
  id: string;
  url: string;
  code: string;
  order: number;
  symbol: string;
  pictogram: any;
  depends_on: string;
  legend_symbol: number;
  app_symbol: string;
  colour: string;

  legend_de: string;
  legend_it: string;
  legend_en: string;
  legend_fr: string;
  legend_es: string;
  legend_ca: string;
  legend_hr: string;
  legend_pl: string;
  legend_pt: string;
  legend_nl: string;
  legend_ru: string;
  legend_el: string;
  legend_da: string;
  legend_sv: string;
  legend_ar: string;
  legend_eu: string;
  legend_gl: string;
  legend_et: string;
  legend_fi: string;
  legend_zh: string;
  legend_th: string;
  legend_vi: string;
  legend_uk: string;

  constructor(obj: any) {
    super();
    Object.assign(this, obj);
  }
}

export class Additive extends DeclarationBase {}

export class Allergen extends DeclarationBase {}

export class Label extends DeclarationBase {}

export type Declaration = Additive | Allergen | Label;

export class CustomDeclaration extends BaseNameExtended {
  id: string;
  url: string;
  location: number;
  organisation: number;
  app_symbol: string;
  code: string;
  order: number;
  symbol: string;
  pictogram: string;
  colour: string;
  data_import_code: string;

  legend_de: string;
  legend_it: string;
  legend_en: string;
  legend_fr: string;
  legend_es: string;
  legend_ca: string;
  legend_hr: string;
  legend_pl: string;
  legend_pt: string;
  legend_nl: string;
  legend_ru: string;
  legend_el: string;
  legend_da: string;
  legend_sv: string;
  legend_ar: string;
  legend_eu: string;
  legend_gl: string;
  legend_et: string;
  legend_fi: string;
  legend_zh: string;
  legend_th: string;
  legend_vi: string;
  legend_uk: string;

  constructor(obj: Partial<CustomDeclaration>) {
    super();
    Object.assign(this, obj || {});
  }
}
