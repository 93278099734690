<div
  class="wrapper"
  [class.otherForm]="isOther"
  [class.active]="option()?.active"
  [class.custom]="isOther"
  [class.isSafari]="isSafari()"
>
  <mat-icon>{{ option()?.icon }}</mat-icon>
  <span [class.other]="isOther">{{
    isOther
      ? ('auth.registration.steps.other.title' | transloco)
      : view ||
        ('auth.registration.steps.first.options.' + option()['value']
          | transloco)
  }}</span>
  @if (isOther) {
    <mat-form-field
      stop-propagation
      class="form-field field-density-5"
      [class.isSafari]="isSafari()"
      subscriptSizing="dynamic"
    >
      <input
        type="text"
        matInput
        [formControl]="inputCtrl"
        [placeholder]="'auth.registration.steps.other.placeholder' | transloco"
      />
    </mat-form-field>
  }
</div>
