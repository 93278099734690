import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import {
  Ingredient,
  PreIngredients,
  SimpleIngredient,
} from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeParams,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { AiCreditsRemaining } from 'src/app/shared/Models/user';
import {
  addIngredientToRecipe,
  addMultipleIngredientsToRecipe,
  createNewIngredient,
  deleteDishRecipeIngredient,
  fetchIngredientInfo,
  setIngredientInfo,
  uncollapseRecipe,
  updateIngredientInfo,
} from 'src/app/shared/ngrx/dishes-menu/dishes-menu.actions';
import {
  clearRecipesAutocomplete,
  fetchIngredientsAutocomplete,
  setIngredientsAutocomplete,
} from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { MagicStickComponent } from '../magic-stick/magic-stick.component';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RecipeItemComponent } from './recipe-item/recipe-item.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { sharedFeature } from '../../ngrx/shared.state';
import { dishesMenuFeature } from '../../ngrx/dishes-menu/dishes-menu.state';

@Component({
  selector: 'app-recipes-tab',
  templateUrl: './recipes-tab.component.html',
  styleUrls: ['./recipes-tab.component.scss'],
  imports: [
    SpinnerComponent,
    RecipeItemComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressBarModule,
    MatOptionModule,
    StopPropagationDirective,
    MagicStickComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class RecipesTabComponent implements OnChanges, OnDestroy, OnInit {
  private ngrxStore = inject<Store<State>>(Store);
  private utils = inject(UtilsService);

  readonly aiCreditsRemaining = input.required<AiCreditsRemaining>();
  readonly aiRecipesLoading = input.required<boolean>();
  readonly autoRecipes = input.required<Recipe[]>();
  readonly currentLocation = input.required<number | null>();
  readonly dish = input.required<Dish>();
  readonly isTrial = input.required<boolean>();
  readonly lang = input.required<ContentLanguage>();
  readonly profileComplete = input.required<boolean>();
  readonly translations = input<object>();

  readonly addRecipe = output<{
    dish: Dish;
    recipeId: number;
  }>();
  readonly createRecipe = output<{
    data: Partial<Recipe>;
    url: string;
  }>();
  readonly fetchDishRecipes = output<string>();
  readonly refreshDish = output<void>();
  readonly removeRecipe = output<{
    dish: Dish;
    recipeId: number;
  }>();
  readonly searchRecipe = output<Partial<RecipeParams>>();
  readonly patchRecipe = output<{
    url: string;
    payload: Partial<Recipe>;
    onFulfilled: () => void;
  }>();
  readonly generateAiRecipes = output<void>();

  ingredientsAuto$ = this.ngrxStore.select(
    sharedFeature.selectIngredientsAutocomplete,
  );
  isLoading$ = this.ngrxStore.select(dishesMenuFeature.selectAiRecipesLoading);
  recipes$ = this.ngrxStore.select(dishesMenuFeature.selectRecipes);
  ingredientInfo$ = this.ngrxStore.select(
    dishesMenuFeature.selectIngredientInfo,
  );

  private destroyed$ = new Subject<void>();
  loading = false;
  recipeControl = new FormControl();
  recipeName = '';
  shouldRefreshDish = false;

  readonly recipeInput = viewChild<ElementRef>('recipeInput');

  ngOnChanges(changes: SimpleChanges): void {
    const dish = this.dish();
    if (
      'dish' in changes &&
      dish &&
      !(changes.dish.previousValue && changes.dish.previousValue.id === dish.id)
    ) {
      this.shouldRefreshDish = false;
      this.fetchDishRecipes.emit(dish.recipes_list);
    }
  }

  ngOnInit(): void {
    this.recipes$.pipe(skip(1), takeUntil(this.destroyed$)).subscribe(() => {
      this.loading = false;
      this.recipeControl.enable({ emitEvent: false });
      if (this.shouldRefreshDish) this.refreshDish.emit();
      this.shouldRefreshDish = false;
    });
    this.recipeControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((val) => typeof val === 'string'),
        withLatestFrom(this.recipes$),
        switchMap(([value, currentRecipes]: [string, Recipe[]]) => {
          this.recipeName = value;
          if (value && value !== '') {
            this.searchRecipe.emit({
              [this.lang()]: value,
              condensed: true,
              exclude: currentRecipes.map((recipe) => recipe.id),
            });
          }
          return [];
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  addIngredientToRecipeEvent({
    ingredient,
    recipe,
    onFulfilled,
  }: {
    ingredient: SimpleIngredient;
    recipe: Recipe;
    onFulfilled: () => void;
  }): void {
    this.ngrxStore.dispatch(
      addIngredientToRecipe({
        ingredientId: ingredient.id,
        recipe,
        onFulfilled,
      }),
    );
  }

  collapsed(recipe: Recipe): void {
    this.ngrxStore.dispatch(uncollapseRecipe({ recipe }));
  }

  displayWith = (value: Recipe): string => {
    if (!value) return undefined;
    const lang = this.lang();
    return value[lang];
  };

  clearIngredientsAuto(): void {
    this.ngrxStore.dispatch(
      setIngredientsAutocomplete({
        payload: { count: 0, next: null, previous: null, results: [] },
      }),
    );
  }

  clearIngredientInfo(): void {
    this.ngrxStore.dispatch(setIngredientInfo({ info: null }));
  }

  fetchIngredientsAuto({
    search,
    recipeId,
  }: {
    search: string;
    recipeId: number;
  }): void {
    const params = {
      [this.lang()]: search,
      condensed: true,
      current_recipe: recipeId,
    };
    this.ngrxStore.dispatch(fetchIngredientsAutocomplete({ params }));
  }

  fetchIngredientsInfo({
    url,
    params,
  }: {
    url: string;
    params: RecipeParams;
  }): void {
    this.ngrxStore.dispatch(fetchIngredientInfo({ url, params }));
  }

  patchIngredient({
    url,
    data,
    params,
  }: {
    url: string;
    data: Partial<Ingredient>;
    params: RecipeParams;
  }): void {
    this.ngrxStore.dispatch(updateIngredientInfo({ url, data, params }));
  }

  deleteDishRecipeIngredient({
    recipe,
    recipeIngredient,
  }: {
    recipe: Recipe;
    recipeIngredient: SimpleRecipeIngredient;
  }): void {
    this.ngrxStore.dispatch(
      deleteDishRecipeIngredient({
        recipe: recipe,
        recipeIngredient,
      }),
    );
  }

  createNewIngredient({
    recipe,
    data,
    onError,
    params,
  }: {
    recipe: Recipe;
    data: Partial<Recipe>;
    onError?: () => void;
    params?: RecipeParams;
  }): void {
    this.ngrxStore.dispatch(
      createNewIngredient({
        recipe,
        data,
        onError,
        params,
      }),
    );
  }

  addMultipleIngredientsToRecipe({
    recipe,
    data,
  }: {
    recipe: Recipe;
    data: PreIngredients;
  }): void {
    this.ngrxStore.dispatch(
      addMultipleIngredientsToRecipe({
        recipe,
        data,
      }),
    );
  }

  handleCreateRecipe(): void {
    this.ngrxStore.dispatch(clearRecipesAutocomplete());
    this.loading = true;
    (this.recipeInput().nativeElement as HTMLInputElement).blur();
    this.recipeControl.disable({ emitEvent: false });
    this.recipeControl.setValue('', { emitEvent: false });
  }

  recipeSelected(event: MatAutocompleteSelectedEvent): void {
    const recipe = event.option.value as Recipe | null;
    if (recipe) {
      this.handleCreateRecipe();
      this.addRecipe.emit({
        dish: this.dish(),
        recipeId: recipe.id,
      });
    } else if (this.recipeName && this.recipeName !== '') {
      this.handleCreateRecipe();
      this.createRecipe.emit({
        url: this.dish().url,
        data: { [this.lang()]: this.recipeName },
      });
    }
    this.recipeName = '';
  }

  removeSelectedRecipe(recipe: Recipe): void {
    this.removeRecipe.emit({
      dish: this.dish(),
      recipeId: recipe.id,
    });
  }

  setShouldRefresh(value: boolean): void {
    this.shouldRefreshDish = value;
  }

  trackByName(_, item: Recipe): number {
    return item.id;
  }

  showTrialBanner() {
    this.utils.showTrialBlockedBox();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
