import { Component, input } from '@angular/core';
import { FileUploadControl } from '@iplab/ngx-file-upload';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'file-upload-placeholder',
  templateUrl: './file-upload-placeholder.component.html',
  styleUrls: ['./file-upload-placeholder.component.scss'],
  imports: [MatIconModule, MatProgressSpinnerModule],
})
export class FileUploadPlaceholderComponent {
  readonly control = input<FileUploadControl>(undefined);
  readonly formats = input<string>(undefined);
  readonly multiple = input<boolean>(undefined);
  readonly showSpinner = input<boolean>(undefined);
  readonly labelClass = input<string>(undefined);
  readonly placeholder = input<string>(undefined);
}
