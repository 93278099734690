import { Component, HostListener, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoginState } from 'src/app/auth/ngrx/auth.selectors';
import { State } from 'src/app/reducers';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.css'],
  imports: [MatButtonModule, RouterLink, TranslocoPipe],
})
export class AuthErrorComponent implements OnInit {
  private store = inject<Store<State>>(Store);

  login = this.store.select(selectLoginState);
  loggedIn = false;
  isMobile = false;

  constructor() {
    this.login.subscribe((el) => (this.loggedIn = el));
  }

  ngOnInit() {
    this.checkIfMobile();
  }

  @HostListener('window:resize')
  onResize() {
    this.checkIfMobile();
  }

  checkIfMobile() {
    this.isMobile = window.innerWidth <= 768;
  }
}
