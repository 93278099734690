import { dashboardFeature } from './../ngrx/dashboard.reducer';
import { Component, OnDestroy, OnInit, inject, viewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { HelpScoutBeaconsService } from 'src/app/help-scout-beacons.service';
import { createMenuFromOnboardingTemplate } from 'src/app/menus/menu-edit/ngrx/menu-edit.actions';
import { State } from 'src/app/reducers';
import { SendMessageModalComponent } from 'src/app/shared/Components/menutech-navbar/send-message-modal/send-message-modal.component';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { SimpleMenu } from 'src/app/shared/Models/menu';
import { Status, User } from 'src/app/shared/Models/user';
import {
  showPreviewDialog,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { patchUser } from 'src/app/shared/user/ngrx/user.actions';
import {
  selectLimitMenus,
  selectLimitTemplates,
  selectMainStatus,
  selectMenuStatus,
  selectTemplateStatus,
  selectTemplatesUsed,
  selectUser,
  selectUserPrivileges,
} from 'src/app/shared/user/ngrx/user.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  fetchCourses,
  fetchDashboardData,
  showBouncedEmailModal,
} from '../ngrx/dashboard.actions';
import { AsyncPipe } from '@angular/common';
import { CustomerSupportComponent } from '../customer-support/customer-support.component';
import { CoursesComponent } from '../courses/courses.component';
import { QuickOverviewComponent } from '../quick-overview/quick-overview.component';
import { UserStatsComponent } from '../user-stats/user-stats.component';
import { TemplateItemComponent } from '../../shared/Components/template-item/template-item.component';
import { SpinnerComponent } from '../../shared/Components/spinner/spinner.component';
import { UserStatusComponent } from '../user-status/user-status.component';

@Component({
  selector: 'dashboard-container',
  templateUrl: './dashboard.container.html',
  styleUrls: ['./dashboard.container.scss'],
  imports: [
    UserStatusComponent,
    SpinnerComponent,
    TemplateItemComponent,
    MatTooltipModule,
    UserStatsComponent,
    QuickOverviewComponent,
    CoursesComponent,
    CustomerSupportComponent,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class DashboardContainer implements OnInit, OnDestroy {
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private dialog = inject(MatDialog);
  private helpScoutBeacon = inject(HelpScoutBeaconsService);
  private ngrxStore = inject<Store<State>>(Store);
  private utils = inject(UtilsService);
  translate = inject(TranslocoService);

  complete = false;
  currentTemplate: number;
  currentUser: User;
  private destroyed$ = new Subject<void>();
  lang: InterfaceLanguage;
  showSpinner = false;
  menuErrorMsg: string;
  templateErrorMsg: string;

  courses$ = this.ngrxStore.select(dashboardFeature.selectCoursesItems);
  coursesLoading$ = this.ngrxStore.select(
    dashboardFeature.selectCoursesLoading,
  );
  isLoading$ = this.ngrxStore.select(dashboardFeature.selectDishesLoading);
  lastMenu$ = this.ngrxStore.select(dashboardFeature.selectLastMenu);
  lastTemplates$ = this.ngrxStore.select(
    dashboardFeature.selectLatestTemplates,
  );
  menusByDate$ = this.ngrxStore.select(dashboardFeature.selectMenusByDate);
  menusCreated$ = this.ngrxStore.select(dashboardFeature.selectMenusCreated);
  menusStatus$ = this.ngrxStore.select(selectMenuStatus);
  popularDish$ = this.ngrxStore.select(dashboardFeature.selectMostPopularDish);
  privileges$ = this.ngrxStore.select(selectUserPrivileges);
  limitMenus$ = this.ngrxStore.select(selectLimitMenus);
  limitTemplates$ = this.ngrxStore.select(selectLimitTemplates);
  statusMessages$ = this.ngrxStore.select(selectMainStatus);
  templateStatus$ = this.ngrxStore.select(selectTemplateStatus);
  templatesUsed$ = this.ngrxStore.select(selectTemplatesUsed);
  user$ = this.ngrxStore.select(selectUser);
  userOnboardingTemplates$ = this.ngrxStore.select(
    dashboardFeature.selectUserOnboardingTemplates,
  );

  readonly picker = viewChild<MatDatepicker<Date>>('picker');

  constructor() {
    this.utils.getLang((lang) => (this.lang = lang));
  }

  ngOnInit(): void {
    this.ngrxStore.dispatch(fetchDashboardData());
    this.ngrxStore.dispatch(
      fetchCourses({
        params: {
          pagination: 'off',
        },
      }),
    );

    this.menusStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((v) => (this.menuErrorMsg = v?.message || this.menuErrorMsg));
    this.templateStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (v) => (this.templateErrorMsg = v?.message || this.templateErrorMsg),
      );
    this.user$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
      this.currentUser = user;
      const status = user.status;
      if (!status.email_verified && status.confirmation_failure) {
        this.ngrxStore.dispatch(showBouncedEmailModal());
      } else if (status.trial_active && !user.tutorials.dashboard_firstvisit) {
        this.utils.showHelpTooltip.next(true);
      }
      this.complete = this.isStatusComplete(status);
    });
  }

  createBtnClicked(btn: MatButton, tooltip: MatTooltip) {
    this.utils.createBtnMessage(btn, tooltip);
  }

  createMenuEvent = (date, template: number) => {
    this.showSpinner = true;
    date = this.dateAdapter.format(date || new Date(), 'yyyy-MM-dd');
    this.ngrxStore.dispatch(
      createMenuFromOnboardingTemplate({ date: date, id: template }),
    );
  };

  createMenuFromOnboardingTemplate = ({ date, template }) => {
    this.showSpinner = true;
    if (date) date = this.dateAdapter.format(date, 'yyyy-MM-dd');
    this.ngrxStore.dispatch(
      createMenuFromOnboardingTemplate({ date: date, id: template }),
    );
  };

  isStatusComplete = (status: Status): boolean =>
    status.email_verified &&
    status.completed_profile &&
    (status.subscription_active || status.subscription_type === 'ent');

  previewMenu(menu: SimpleMenu): void {
    const previewData = this.utils.menuToPreviewData(menu, {
      lang: 'any',
      print_mode: true,
    });
    this.ngrxStore.dispatch(showPreviewDialog(previewData));
  }

  showDatepicker(template: number) {
    this.currentTemplate = template;
    this.picker().open();
  }

  showError() {
    this.ngrxStore.dispatch(
      showSnackbarMessage({
        message: this.menuErrorMsg || this.templateErrorMsg,
      }),
    );
  }

  showMessageOverlay(chat = true): void {
    if (chat) {
      this.helpScoutBeacon.open();
      this.helpScoutBeacon.navigateSupport();
    } else {
      this.dialog.open(SendMessageModalComponent, {
        data: {
          subject: 0,
          user: this.currentUser,
        },
        width: '750px',
        height: this.currentUser.email.includes('@partners.menutech.com')
          ? '650px'
          : '610px',
        panelClass: 'send-message-panel',
        autoFocus: false,
      });
    }
  }

  showTooltip = (tooltip) => setTimeout(() => tooltip.show(), 200);

  updateUser(userDetail: DeepPartial<User>): void {
    this.ngrxStore.dispatch(
      patchUser({ user: userDetail, cid: UtilsService.GEN_CID }),
    );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
