import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { QuillEditorComponent } from 'src/app/shared/Components/quill-editor/quill-editor.component';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Menu } from 'src/app/shared/Models/menu';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private http = inject(HttpClient);

  processMenuOnQuillChanged(
    menu: Menu,
    lang: ContentLanguage,
    data: string,
    cb: (coverpage: any) => void,
  ): void {
    const promises: Promise<any>[] = [];
    menu.user_details.inline_images.forEach((el) => {
      if (!menu.user_details[`coverpage_${lang}`].includes(el.image)) {
        this.deletePicture(el.url).subscribe({
          next: (res) => promises.push(res),
        });
      }
    });
    if (promises?.length) {
      Promise.all(promises).finally(() => {
        cb(this.updateMenuQuill(lang, data));
      });
    } else {
      cb(this.updateMenuQuill(lang, data));
    }
  }

  private updateMenuQuill(lang: ContentLanguage, data?: string): any {
    return {
      ['coverpage_' + lang]: QuillEditorComponent.replaceContent(data),
    };
  }

  deletePicture = (url: string) => this.http.delete<any>(url);
}
