import { ChangeDetectionStrategy, Component, output } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'refresh-sub-menu',
  templateUrl: './refresh-submenu.component.html',
  styleUrls: [`./refresh-submenu.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, TranslocoPipe],
})
export class RefreshSubMenuComponent {
  readonly cancelAction = output<void>();
  readonly refreshSubMenuActions = output<void>();
}
