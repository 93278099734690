import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { Variant } from 'src/app/shared/Models/dish';
import { Detail, ItemType } from 'src/app/shared/Models/menu';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import * as _ from 'lodash-es';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';

@Component({
  selector: 'app-variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    MtTooltipDirective,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    TranslocoPipe,
  ],
})
export class VariantsComponent implements OnChanges, OnDestroy {
  private fb = inject(UntypedFormBuilder);
  private utils = inject(UtilsService);

  readonly currencySymbol = input<string>(undefined);
  readonly initialValue = input<number>(undefined);
  readonly item = input<ItemType>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly overwritten = input(false);
  readonly responseItem = input<MenuDish | Detail>(undefined);
  readonly changed = output<{
    item: ItemType;
    variants: Partial<Variant>[];
  }>();
  readonly cleared = output<ItemType>();

  currentItem: ItemType;
  private destroyed$ = new Subject<void>();
  variantsForm: UntypedFormGroup;
  rtl = false;
  showLoading = false;
  variants: UntypedFormArray;

  ngOnChanges(changes: SimpleChanges): void {
    const lang = this.lang();
    if (lang && !this.variantsForm) this.buildForm();
    if ('lang' in changes && lang) {
      this.rtl = rtlLangs.includes(lang);
    }
    if ('item' in changes) {
      if (
        !changes.item.previousValue ||
        changes.item.previousValue.id !== changes.item.currentValue.id
      ) {
        this.showLoading = false;
        if (this.item() && lang && this.variantsForm)
          this.setVariantsOnChange();
      } else {
        this.variants ? this.checkLoading() : (this.showLoading = false);
      }
    }
  }

  addVariant(): void {
    this.variants = this.variantsForm.get('variants') as UntypedFormArray;
    this.variants.push(this.createVariant());
  }

  blurInput = (event: Event): void => (event.target as HTMLElement).blur();

  buildForm(): void {
    const variants = [];
    Array.from({ length: this.item().variants?.length || 1 }).forEach(() =>
      variants.push(this.createVariant()),
    );

    this.variantsForm = this.fb.group({
      variants: this.fb.array(variants),
    });
    this.variantsForm.valueChanges
      .pipe(
        tap(() => (this.currentItem = this.responseItem() ?? this.item())),
        distinctUntilChanged(),
        debounceTime(400),
        takeUntil(this.destroyed$),
      )
      .subscribe((data) => this.onValueChanged(data));
  }

  checkLoading(): void {
    const actualVariants = this.item().variants.map((variant) =>
      _.pick(variant, [this.lang(), 'price']),
    );
    if (!actualVariants.length)
      actualVariants.push({ [this.lang()]: '', price: null });
    const currentVariants = this.mapVariants(this.variants.value);
    if (
      this.utils.isArrayEqual(currentVariants, actualVariants) ||
      this.utils.isArrayEqual(
        this.filterEmptyVariants(currentVariants),
        actualVariants,
      )
    ) {
      this.showLoading = false;
    }
  }

  createVariant = (name = '', price: number = null): UntypedFormGroup =>
    this.fb.group({
      [this.lang()]: name,
      price: price,
    });

  filterEmptyVariants = (variants: Partial<Variant>[]): Partial<Variant>[] =>
    variants.filter((v) => !(v[this.lang()] === '' && v.price === null));

  mapVariants = (variants: Partial<Variant>[]): Partial<Variant>[] =>
    variants.map((variant) => {
      return {
        [this.lang()]: variant[this.lang()],
        price: this.parseTextPrice(variant.price),
      };
    });

  onValueChanged(data: { variants: Partial<Variant>[] }): void {
    const item = this.item();
    if (
      item.variants === undefined ||
      item.variants === null ||
      !this.variantsForm.valid
    )
      return undefined;
    const existingVariants = item.variants.map((variant) =>
      _.pick(variant, [this.lang(), 'price']),
    );
    const filteredVariants = this.mapVariants(
      data.variants.filter(
        (variable) => !!variable.price || !!variable[this.lang()],
      ),
    );
    if (
      this.showLoading ||
      !this.utils.isArrayEqual(filteredVariants, existingVariants)
    ) {
      if (this.currentItem === (this.responseItem() || item))
        this.showLoading = true;
      this.changed.emit({ variants: filteredVariants, item: this.currentItem });
    }
  }

  parseTextPrice = (price: number | string): number => {
    let parsedPrice = this.utils.validatePrice(price);
    if (parsedPrice < 0) parsedPrice = null;
    return parsedPrice;
  };

  removeVariant(index: number): void {
    this.variants = this.variantsForm.get('variants') as UntypedFormArray;
    this.variants.removeAt(index);
    if (!this.variants.length) {
      this.cleared.emit(this.currentItem ?? this.responseItem() ?? this.item());
      this.variants.push(this.createVariant());
    }
  }

  setVariantsOnChange(): void {
    const item = this.item();
    if (item.variants === undefined) return undefined;
    const mappedVariants = item.variants?.length
      ? item.variants.map((variant: Variant) =>
          this.createVariant(variant[this.lang()], variant.price),
        )
      : [this.createVariant('', this.initialValue())];
    this.variantsForm.controls.variants = this.fb.array(mappedVariants);
    this.variantsForm.controls.variants.valueChanges
      .pipe(
        tap(() => (this.currentItem = this.responseItem() ?? this.item())),
        distinctUntilChanged(),
        debounceTime(400),
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (value) =>
          this.variantsForm.controls.variants.valid &&
          this.onValueChanged({ variants: value }),
      );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
