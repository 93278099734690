import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  inject,
  input,
} from '@angular/core';

@Directive({
  selector: '[stop-propagation]',
  standalone: true,
})
export class StopPropagationDirective implements OnInit, OnChanges {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  readonly stopPropClassName = input<string>(undefined);
  readonly lazyPropagation = input(false);
  readonly updateParent = input<EventEmitter<boolean>>(undefined);

  ngOnInit() {
    if (this.stopPropClassName() && !this.lazyPropagation()) {
      this.setListener();
    }
  }

  setListener() {
    let native = this.el.nativeElement;
    const stopPropClassName = this.stopPropClassName();
    while (
      !native.classList ||
      (!native.classList.contains(stopPropClassName) && native.parentElement)
    ) {
      native = native.parentElement;
    }
    if (!native.classList?.contains(stopPropClassName)) {
      return undefined;
    }
    this.renderer.listen(native, 'click', (event: any) => {
      event.stopPropagation();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('updateParent' in changes) {
      this.updateParent().subscribe((v) => this.setListener());
    }
  }

  @HostListener('click', ['$event'])
  public onClick(event: any): void | boolean {
    event.stopPropagation();
  }
}
