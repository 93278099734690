@if (login()) {
  <div class="side">
    <a routerLink="/" class="title-link">
      <img
        class="logo"
        src="assets/img/small-logo.svg"
        alt="Menutech icon"
        width="42"
        height="43"
      />
    </a>
    <h1 class="light-title">{{ 'auth.login.side.title' | transloco }}</h1>
    <h2 class="description-title">
      {{ 'auth.login.side.description-title' | transloco }}
    </h2>
    <p class="description">{{ 'auth.login.side.description' | transloco }}</p>
    <section class="request-links">
      <ul class="links-list">
        <li>
          <a
            [href]="
              'https://menutech.com/' +
              lang() +
              '/' +
              ('auth.login.side.links-urls.design' | transloco)
            "
            target="_blank"
            >{{ 'auth.login.side.links-titles.design' | transloco }}</a
          >
        </li>
        <li>
          <a
            [href]="
              'https://menutech.com/' +
              lang() +
              '/' +
              ('auth.login.side.links-urls.integration' | transloco)
            "
            target="_blank"
            >{{ 'auth.login.side.links-titles.integration' | transloco }}</a
          >
        </li>
        <li>
          <a
            [href]="'https://menutech.com/' + lang() + '/FAQ'"
            target="_blank"
            >{{ 'auth.login.side.links-titles.faq' | transloco }}</a
          >
        </li>
      </ul>
    </section>
    <div class="filler"></div>
    <section class="trusted">
      <h4 class="trusted-title">
        {{ 'auth.login.side.trusted' | transloco }}:
      </h4>
      <div class="logos">
        <a
          href="https://www.swissmedical.net"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            class="trusted-logo smn"
            src="assets/img/logos/logo_smn.svg"
            alt="Swiss Medical Network"
            width="55"
            height="69"
          />
        </a>
        <a
          href="https://www.belmond.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            class="trusted-logo belmond"
            src="assets/img/logos/logo-belmond.svg"
            alt="Belmond"
            width="100"
            height="40"
          />
        </a>
        <a
          href="https://www.compass-usa.com/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            class="trusted-logo compass"
            src="assets/img/logos/logo-compass.svg"
            alt="Compass Group USA"
            width="100"
            height="38"
          />
        </a>
      </div>
    </section>
  </div>
} @else {
  <div class="side reg">
    <a routerLink="/" class="title-link">
      <img
        class="logo"
        src="assets/img/small-logo.svg"
        alt="Menutech icon"
        width="42"
        height="43"
      />
    </a>
    <h1 class="light-title">
      {{ 'auth.registration.description.side.title' | transloco }}
    </h1>
    <h2 class="description-title">
      {{ 'auth.registration.description.side.description-title' | transloco }}
    </h2>
    <h3 class="description-subtitle">
      <mat-icon class="icon">done</mat-icon
      >{{
        'auth.registration.description.side.description-1-title' | transloco
      }}
    </h3>
    <p class="description">
      {{ 'auth.registration.description.side.description-1' | transloco }}
    </p>
    <h3 class="description-subtitle">
      <mat-icon class="icon">done</mat-icon
      >{{
        'auth.registration.description.side.description-2-title' | transloco
      }}
    </h3>
    <p class="description">
      {{ 'auth.registration.description.side.description-2' | transloco }}
    </p>
    <h3 class="description-subtitle">
      <mat-icon class="icon">done</mat-icon
      >{{
        'auth.registration.description.side.description-3-title' | transloco
      }}
    </h3>
    <p class="description">
      {{ 'auth.registration.description.side.description-3' | transloco }}
      <a
        href="{{
          'auth.registration.description.side.links-urls.desc3' | transloco
        }}"
      >
        {{
          'auth.registration.description.side.links-titles.desc3' | transloco
        }}
      </a>
    </p>
    <div class="filler"></div>
    <section class="trusted">
      <h4 class="trusted-title">
        {{ 'auth.registration.description.side.trusted' | transloco }}:
      </h4>
      <div class="logos">
        <a
          href="https://swissmedical.net"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            class="trusted-logo smn"
            src="assets/img/logos/logo_smn.svg"
            alt="Swiss medical"
            width="55"
            height="69"
          />
        </a>
        <a
          href="https://novotel.accorhotels.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            class="trusted-logo novotel"
            src="assets/img/logos/logo-novotel.png"
            alt="Novotel"
            width="100"
            height="46"
          />
        </a>
        <a
          href="https://mercure.accorhotels.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            class="trusted-logo mercure"
            src="assets/img/logos/logo-mercure.png"
            alt="Mercure"
            width="90"
            height="42"
          />
        </a>
      </div>
    </section>
  </div>
}
