import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  output,
  input,
} from '@angular/core';
import { GenerateArchiveOptions } from 'src/app/shared/Models/menu';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'generate-archive',
  templateUrl: './generate-archive.component.html',
  styleUrls: [
    `../regenerate-menu/regenerate-menu.component.scss`,
    `./generate-archive.component.scss`,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class GenerateArchiveComponent implements OnInit {
  private utils = inject(UtilsService);

  readonly showOrdertaking = input(false);
  readonly cancelAction = output<void>();
  readonly createArchive = output<GenerateArchiveOptions>();

  hasOrderModule = false;
  options = {
    enable_ordertaking: false,
    create_archive: false,
  };

  ngOnInit() {
    this.hasOrderModule = this.hasModule('order');
    this.options.enable_ordertaking = this.hasOrderModule;
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);
}
