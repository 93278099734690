import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  output,
  input,
  linkedSignal,
} from '@angular/core';
import {
  MatCheckbox,
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import {
  Additive,
  Allergen,
  GenericDeclaration,
  Label,
} from 'src/app/shared/Models/declarations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SafePipe } from 'src/app/shared/Pipes/safe.pipe';
import { processPictogram } from 'src/app/shared/utils.functions';

@Component({
  selector: 'app-option-item',
  templateUrl: './option-item.component.html',
  styles: [
    `
      :host {
        display: block;
      }
      .allergen-symbol {
        display: inline-block;
        position: relative;
        top: 2px;
      }
      .pictogram {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        height: 20px;
        width: 20px;
      }
      .pictogram svg {
        height: 100%;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCheckboxModule, ReactiveFormsModule, FormsModule, SafePipe],
})
export class OptionItemComponent implements OnChanges {
  readonly color = input('primary');
  readonly disabled = input<boolean>(undefined);
  readonly items = input<Allergen[] | Additive[] | Label[]>(undefined);
  readonly lang = input.required<ContentLanguage>();
  readonly option = input<GenericDeclaration>();
  readonly type = input<string>(undefined);
  readonly showConfirmDialog = input<boolean>(undefined);

  readonly triggerOption = output<GenericDeclaration>();
  readonly showDialog = output<{
    declaration: GenericDeclaration;
    value: boolean;
    onCancel: () => void;
  }>();

  protected isChecked = linkedSignal(() => this.option()?.value);

  classForSymbols: string;
  symbolsData: Record<
    string,
    {
      app_symbol: string;
      pictogram: string;
      symbol: string;
      legend_symbol: number;
      name: string;
    }
  > = {};

  ngOnChanges(changes: SimpleChanges): void {
    const type = this.type();
    if ('type' in changes && type) {
      this.classForSymbols = `${
        type !== 'additives' ? 'allergen' : type
      }-symbol`;
    }
    const items = this.items();
    if ('items' in changes && items) {
      items.forEach((el) => {
        this.symbolsData[el.code] = {
          app_symbol: el.app_symbol,
          pictogram: processPictogram(el.pictogram),
          symbol: el.symbol,
          legend_symbol: el.legend_symbol,
          name: el[this.lang()],
        };
      });
    }
  }

  onCheckboxChange(event: MatCheckboxChange): void {
    const option = this.option();
    if (option) {
      const checked = event.checked;
      if (this.showConfirmDialog()) {
        this.confirmAssign(
          {
            ...option,
            value: checked,
          },
          event.source,
        );
      } else {
        const updatedOption = new GenericDeclaration(
          option.name,
          checked,
          option.order,
          option.dependants,
        );
        this.triggerOption.emit(updatedOption);
      }
    }
  }

  confirmAssign(option: GenericDeclaration, checkbox: MatCheckbox): void {
    const onCancel = () => {
      checkbox.checked = !checkbox.checked;
    };

    this.showDialog.emit({
      declaration: option,
      value: checkbox.checked,
      onCancel,
    });
  }
}
