<ng-template #selectLocation>
  <div class="select-location-wrapper field-density-5">
    <span class="select-location-label"
      >{{ 'dishes.table.view-as' | transloco }}:</span
    >
    <mat-form-field class="select-field" subscriptSizing="dynamic">
      <mat-select
        #viewAs
        [placeholder]="'dishes.table.administrator' | transloco"
        (selectionChange)="locationChanged.emit($event)"
      >
        <mat-option [value]="null">{{
          'dishes.table.administrator' | transloco
        }}</mat-option>
        @for (location of locations(); track location) {
          <mat-option [value]="location?.id">
            {{ location?.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</ng-template>
