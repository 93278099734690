import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { Rule } from 'src/app/shared/Models/ruleset';
import { SimpleLayout } from 'src/app/shared/Models/template';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { distinctUntilChanged, filter, Subject, takeUntil } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { NgxMatTimepickerComponent } from 'ngx-mat-timepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const TIME_PATTERN = '[0-9]{2}:[0-9]{2}';

@Component({
  selector: 'app-ordertaking',
  templateUrl: './ordertaking.component.html',
  styleUrls: ['./ordertaking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    NgxMatTimepickerComponent,
    TranslocoPipe,
  ],
})
export class OrdertakingComponent implements OnChanges, OnDestroy, OnInit {
  private utils = inject(UtilsService);

  readonly lang = input<InterfaceLanguage>(undefined);
  readonly item = input<Menu | Rule>(undefined);
  readonly layouts = input<SimpleLayout[]>(undefined);
  readonly showCheckboxes = input(false);
  readonly enableOrdertaking = output<boolean>();
  readonly patchMenu = output<DeepPartial<Menu> | Partial<Rule>>();

  destroyed$ = new Subject<void>();
  enableOrdertakingControl = new FormControl(false);
  enableAutomatedOrdersControl = new FormControl(false);
  checkinDayAutomatedOrdersControl = new FormControl(false);
  checkoutDayAutomatedOrdersControl = new FormControl(false);
  layoutControl = new FormControl<number>(null);
  ordertakingCutoffControl = new FormControl(false);
  ordertakingCancelControl = new FormControl(false);
  ordertakingLastminuteControl = new FormControl(false);
  ordertakingDaysUntilControl = new FormControl(false);
  maximumComponentsControl = new FormControl(false);
  mealSortingControl = new FormControl(false);

  ordertakingOptions = new FormGroup(
    {
      order_by_time: new FormControl<string | null>(
        { value: null, disabled: false },
        [Validators.pattern(TIME_PATTERN)],
      ),
      order_by_days_before: new FormControl<number | null>(
        { value: null, disabled: false },
        [Validators.min(0)],
      ),
      cancel_by_time: new FormControl<string | null>(
        { value: null, disabled: false },
        [Validators.pattern(TIME_PATTERN)],
      ),
      cancel_by_days_before: new FormControl<number | null>(
        { value: null, disabled: false },
        [Validators.min(0)],
      ),
      automated_orders_time: new FormControl<string | null>(
        { value: null, disabled: false },
        [Validators.pattern(TIME_PATTERN)],
      ),
      automated_orders_days_before: new FormControl<number | null>(
        { value: null, disabled: false },
        [Validators.min(0)],
      ),
      automated_orders_checkin_time: new FormControl<string | null>(
        { value: null, disabled: false },
        [Validators.pattern(TIME_PATTERN)],
      ),
      automated_orders_checkout_time: new FormControl<string | null>(
        { value: null, disabled: false },
        [Validators.pattern(TIME_PATTERN)],
      ),
      warning_lastminute_time: new FormControl<string | null>(
        { value: null, disabled: false },
        [Validators.pattern(TIME_PATTERN)],
      ),
      warning_lastminute_days_before: new FormControl<number | null>(
        { value: null, disabled: false },
        [Validators.min(0)],
      ),
      order_days_until: new FormControl<number | null>(null),
      maximum_components: new FormControl<number | null>(null),
      meal_sorting: new FormControl<number | null>(null),
      repeated_orders_consumers: new FormControl<boolean>(false),
    },
    { updateOn: 'blur' },
  );

  ngOnChanges(changes: SimpleChanges): void {
    const item = this.item();
    if (
      'item' in changes &&
      item &&
      changes.item.previousValue?.id !== item.id
    ) {
      this.initForm();
      if (item.enable_ordertaking) {
        this.enableOrdertakingControl.setValue(true);
      }
      if (item.automated_orders_checkin_time) {
        this.checkinDayAutomatedOrdersControl.setValue(true);
      }
      if (item.automated_orders_checkout_time) {
        this.checkoutDayAutomatedOrdersControl.setValue(true);
      }
      if (item.enable_automated_orders) {
        this.enableAutomatedOrdersControl.setValue(true);
      }
      if (item.ordertaking_layout) {
        this.layoutControl.setValue(item.ordertaking_layout);
      }
      if (item.order_by_time) {
        this.ordertakingCutoffControl.setValue(true);
      }
      if (item.cancel_by_time) {
        this.ordertakingCancelControl.setValue(true);
      }
      if (item.warning_lastminute_time) {
        this.ordertakingLastminuteControl.setValue(true);
      }
      if (item.order_days_until || item.order_days_until === 0) {
        this.ordertakingDaysUntilControl.setValue(true);
      }
      if (item.maximum_components || item.maximum_components === 0) {
        this.maximumComponentsControl.setValue(true);
      }
      if (item.meal_sorting) {
        this.mealSortingControl.setValue(true);
      }
    }
  }

  ngOnInit(): void {
    this.initOptionToggles();
    this.enableOrdertakingControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((v) => {
        this.enableOrdertaking.emit(v);
      });
    this.enableAutomatedOrdersControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((v) => {
        this.patchMenu.emit({ enable_automated_orders: v });
      });
    this.layoutControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((v) => {
        this.patchMenu.emit({ ordertaking_layout: v });
      });
    this.ordertakingOptions.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((v) => {
        if (v['order_by_time'] === '') v['order_by_time'] = null;
        if (v['cancel_by_time'] === '') v['cancel_by_time'] = null;
        if (
          !this.isFieldsSetOrEmpty(v, 'order_by_time', 'order_by_days_before')
        )
          return;
        if (
          !this.isFieldsSetOrEmpty(v, 'cancel_by_time', 'cancel_by_days_before')
        )
          return;
        if (v['automated_orders_time'] === '')
          v['automated_orders_time'] = null;
        if (
          !this.isFieldsSetOrEmpty(
            v,
            'automated_orders_time',
            'automated_orders_days_before',
          )
        )
          return;
        if (v['automated_orders_checkin_time'] === '')
          v['automated_orders_checkin_time'] = null;
        if (v['automated_orders_checkout_time'] === '')
          v['automated_orders_checkout_time'] = null;
        if (v['warning_lastminute_time'] === '')
          v['warning_lastminute_time'] = null;
        if (
          !this.isFieldsSetOrEmpty(
            v,
            'warning_lastminute_time',
            'warning_lastminute_days_before',
          )
        )
          return;
        if ('meal_sorting' in v && !v['meal_sorting']) v['meal_sorting'] = 0;
        if (this.ordertakingOptions.valid) this.patchMenu.emit(v);
      });
  }

  isFieldsSetOrEmpty = (obj: object, field1: string, field2: string): boolean =>
    (obj[field1] !== null && obj[field2] !== null) ||
    (obj[field1] === null && obj[field2] === null);

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  initForm(): void {
    this.ordertakingOptions.setValue(
      {
        order_by_time: this.item().order_by_time ?? null,
        order_by_days_before: this.item().order_by_days_before ?? null,
        cancel_by_time: this.item().cancel_by_time ?? null,
        cancel_by_days_before: this.item().cancel_by_days_before ?? null,
        automated_orders_time: this.item().automated_orders_time ?? null,
        automated_orders_checkin_time:
          this.item().automated_orders_checkin_time ?? null,
        automated_orders_checkout_time:
          this.item().automated_orders_checkout_time ?? null,
        automated_orders_days_before:
          this.item().automated_orders_days_before ?? null,
        warning_lastminute_time: this.item().warning_lastminute_time ?? null,
        warning_lastminute_days_before:
          this.item().warning_lastminute_days_before ?? null,
        order_days_until: this.item().order_days_until ?? null,
        maximum_components: this.item().maximum_components ?? null,
        meal_sorting: this.item().meal_sorting ?? 0,
        repeated_orders_consumers:
          this.item().repeated_orders_consumers ?? false,
      },
      { emitEvent: false },
    );
  }

  initOptionToggles(): void {
    this.ordertakingCutoffControl.valueChanges
      .pipe(filter((v) => !v))
      .subscribe(() => {
        const emitEvent = !!(
          this.ordertakingOptions.value.order_by_time ||
          this.ordertakingOptions.value.order_by_days_before
        );
        this.ordertakingOptions.patchValue(
          {
            order_by_time: null,
            order_by_days_before: null,
          },
          { emitEvent },
        );
      });
    this.ordertakingCancelControl.valueChanges
      .pipe(filter((v) => !v))
      .subscribe(() => {
        const emitEvent = !!(
          this.ordertakingOptions.value.cancel_by_time ||
          this.ordertakingOptions.value.cancel_by_days_before
        );
        this.ordertakingOptions.patchValue(
          {
            cancel_by_time: null,
            cancel_by_days_before: null,
          },
          { emitEvent },
        );
      });
    this.enableAutomatedOrdersControl.valueChanges
      .pipe(filter((v) => !v))
      .subscribe(() => {
        const emitEvent = !!(
          this.ordertakingOptions.value.automated_orders_time ||
          this.ordertakingOptions.value.automated_orders_days_before
        );
        this.ordertakingOptions.patchValue(
          {
            automated_orders_time: null,
            automated_orders_days_before: null,
            automated_orders_checkin_time: null,
            automated_orders_checkout_time: null,
          },
          { emitEvent },
        );
      });
    this.ordertakingLastminuteControl.valueChanges
      .pipe(filter((v) => !v))
      .subscribe(() => {
        const emitEvent = !!(
          this.ordertakingOptions.value.warning_lastminute_time ||
          this.ordertakingOptions.value.warning_lastminute_days_before
        );
        this.ordertakingOptions.patchValue(
          {
            warning_lastminute_time: null,
            warning_lastminute_days_before: null,
          },
          { emitEvent },
        );
      });
    this.ordertakingDaysUntilControl.valueChanges
      .pipe(filter((v) => !v))
      .subscribe(() => {
        const emitEvent = !!this.ordertakingOptions.value.order_days_until;
        this.ordertakingOptions.patchValue(
          {
            order_days_until: null,
          },
          { emitEvent },
        );
      });
    this.maximumComponentsControl.valueChanges
      .pipe(filter((v) => !v))
      .subscribe(() => {
        const emitEvent = !!this.ordertakingOptions.value.maximum_components;
        this.ordertakingOptions.patchValue(
          {
            maximum_components: null,
          },
          { emitEvent },
        );
      });
    this.mealSortingControl.valueChanges
      .pipe(filter((v) => !v))
      .subscribe(() => {
        const emitEvent = !!this.ordertakingOptions.value.meal_sorting;
        this.ordertakingOptions.patchValue(
          {
            meal_sorting: 0,
          },
          { emitEvent },
        );
      });
  }

  updateTime(value: string, field: string): void {
    this.ordertakingOptions.patchValue({ [field]: value });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
