import { Component, output, input } from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { TranslocoPipe } from '@jsverse/transloco';
import { KeyValuePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatButtonModule,
    StopPropagationDirective,
    MatIconModule,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class SelectLocationComponent {
  readonly groupedLocations =
    input<Record<string, SimpleLocation[]>>(undefined);
  readonly locations = input<SimpleLocation[]>(undefined);
  readonly locationControl = input<UntypedFormControl>(undefined);

  readonly clearLocation = output();
}
