import { createAction, props } from '@ngrx/store';
import {
  Menu,
  Style,
  StyleCategories,
  StyleParams,
  Template,
  UpdateStyleData,
  UpdateStyleParam,
} from 'src/app/shared/Models/menu';
import { Fulfillable } from 'src/app/shared/Models/models';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { Rule } from 'src/app/shared/Models/ruleset';
import { Separator } from 'src/app/shared/Models/separator';
import { Layout, LayoutParams } from 'src/app/shared/Models/template';

export const fetchStyles = createAction(
  `[Menus container] Fetch styles`,
  props<{ params: Partial<StyleParams> }>(),
);

export const setStyleCategories = createAction(
  `[Menus container] Set styles categories locally`,
  props<{ categories: StyleCategories }>(),
);

export const setStyles = createAction(
  `[Menus container] Set styles locally`,
  props<{ styles: Style[] }>(),
);

export const setUserStyles = createAction(
  `[Menus container] Set user style`,
  props<{ styles: Style[] }>(),
);

export const setPublicStyles = createAction(
  `[Menus container] Set public style`,
  props<{ styles: Style[] }>(),
);

export const setSpecialStyles = createAction(
  `[Menus container] Set special style`,
  props<{ styles: Style[] }>(),
);

export const moveStyleToFront = createAction(
  `[Menus container] Move style to front`,
  props<{ styleId: number; list: string }>(),
);

export const switchStyleTab = createAction(
  `[Menus container] Switch tab`,
  props<{ index: number }>(),
);

export const showStyleSpinner = createAction(
  `[Menus container] Show style spinner`,
  props<{ spinnerState: 'create' | 'select' | 'update' | null }>(),
);

export const saveStyle = createAction(
  `[Menus container] Save style`,
  props<{ currentMenu: number }>(),
);

export const addCreatedStyle = createAction(
  `[Menus container] Add newly created style`,
  props<{ style: Style }>(),
);

export const deleteStyle = createAction(
  `[Menus container] Delete style`,
  props<{ url: string; id: number; params?: Partial<StyleParams> }>(),
);

export const removeStyle = createAction(
  `[Menus container] Remove style`,
  props<{ styleId: number }>(),
);

export const patchStyle = createAction(
  `[Menus container] Patch style`,
  props<{ url: string; data: Partial<Style>; params?: Partial<StyleParams> }>(),
);

export const changeStyle = createAction(
  `[Menus container] Change style`,
  props<{ newStyle: Style }>(),
);

export const fetchLayouts = createAction(
  `[Menus style container] Fetch condensed templates`,
  props<{ params: Partial<LayoutParams> }>(),
);

export const setLayouts = createAction(
  `[Menus style container] Set condensed templates locally`,
  props<{ payload: Layout[] }>(),
);

export const setLayoutCategories = createAction(
  `[Menus container] Set templates categories locally`,
  props<{ payload: StyleCategories }>(),
);

export const setUserLayouts = createAction(
  `[Menus container] Set users layout locally`,
  props<{ payload: Layout[] }>(),
);

export const setPublicLayouts = createAction(
  `[Menus container] Set public layout locally`,
  props<{ payload: Layout[] }>(),
);

export const setSpecialLayouts = createAction(
  `[Menus container] Set special layout locally`,
  props<{ payload: Layout[] }>(),
);

export const switchTab = createAction(
  `[Menus container] Set switch layouts tab`,
  props<{ payload: number }>(),
);

export const setLayout = createAction(
  `[Menus style container] Set current layout`,
  props<{ layoutId: number }>(),
);

export const createTemplateFromMenu = createAction(
  `[Menus style container] Create template`,
  props<{ template: Partial<Template> | FormData; menu_id: number }>(),
);

export const updateTemplateStyleAndLayout = createAction(
  `[Menus style container] Update template style and layout`,
  props<{ id: number }>(),
);

export const unlinkTemplate = createAction(
  `[Menus style container] Unlink template`,
  props<{ template: Partial<Menu>; id: number }>(),
);

export const updateTemplateDetail = createAction(
  `[Menus style container] Update template detail`,
  props<{
    url: string;
    data: Partial<OnboardingTemplate>;
    params?: { current_menu: number };
  }>(),
);

export const deleteTemplate = createAction(
  `[Menus style container] Delete template`,
  props<{ url: string; id: number; params?: { current_menu: number } }>(),
);

export const applyStyle = createAction(
  `[Menus style container] Apply style`,
  props<{
    showSpinner: boolean;
    style: number;
    restore?: boolean;
    callback?: () => void;
  }>(),
);

export const updateStyle = createAction(
  `[Menus style container] Update style`,
  props<{
    payload: UpdateStyleData;
    params?: UpdateStyleParam;
    callback?: () => void;
  }>(),
);

export const uploadLogoImage = createAction(
  `[Menus style container] Upload image style`,
  props<{
    data: Fulfillable<File>;
    url: string;
    field: string;
    lockedFields: string[];
  }>(),
);

export const fetchSeparators = createAction(
  `[Menus container] Fetch separators`,
  props<{ category: string; current_menu: number }>(),
);

export const setOptions = createAction(
  `[Menus container] Set options locally`,
  props<{ separators: Separator[] }>(),
);

export const setCourses = createAction(
  `[Menus container] Set courses locally`,
  props<{ separators: Separator[] }>(),
);

export const patchRuleDetail = createAction(
  `[Menus container] Patch rule detail`,
  props<{ url: string; data: Partial<Rule> }>(),
);
