import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import {
  FileUploadControl,
  FileUploadValidators,
  FileUploadModule,
} from '@iplab/ngx-file-upload';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { User } from 'src/app/shared/Models/user';
import { sendSupportForm } from 'src/app/shared/ngrx/shared.actions';
import { EmailValidators } from 'ngx-validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SentSuccessComponent } from '../../sent-success/sent-success.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FileUploadItemComponent } from '../../file-upload-item/file-upload-item.component';
import { FileUploadPlaceholderComponent } from '../../file-upload-placeholder/file-upload-placeholder.component';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface SendMessageModalData {
  subject: number | undefined;
  user: User;
}

@Component({
  selector: 'send-message-modal',
  templateUrl: './send-message-modal.component.html',
  styleUrls: ['./send-message-modal.component.scss'],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatInputModule,
    FileUploadModule,
    FileUploadPlaceholderComponent,
    FileUploadItemComponent,
    MatCheckboxModule,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    MatTooltipModule,
    MatIconModule,
    SentSuccessComponent,
    TranslocoPipe,
  ],
})
export class SendMessageModalComponent implements OnDestroy, OnInit {
  data = inject<SendMessageModalData>(MAT_DIALOG_DATA);
  private fb = inject(FormBuilder);
  private ngrxStore = inject<Store<State>>(Store);
  private translate = inject(TranslocoService);

  checked = false;
  private destroyed$ = new Subject<void>();
  error: string;
  public fileUploadControl = new FileUploadControl(null, [
    FileUploadValidators.fileSize(100000000),
    FileUploadValidators.accept([
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'image/jpeg',
      'image/png',
    ]),
  ]);
  forbidSending = false;
  messageGroup: FormGroup;
  messageSent = false;
  showEmail = false;
  subjectOptions = Array(5).fill(0);

  ngOnInit() {
    this.getTranslations();
    this.translate.langChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.getTranslations());
    this.showEmail = this.showEmailField(this.data.user.email);
    this.buildForm();
  }

  buildForm(): void {
    this.messageGroup = this.fb.group({
      subject:
        this.data.subject !== undefined
          ? this.subjectOptions[this.data.subject]
          : null,
      email: [
        this.showEmail ? '' : this.data.user.email,
        [Validators.required, EmailValidators.normal],
      ],
      message: '',
      phone: [{ value: '', disabled: true }],
    });
  }

  canSend = (): boolean =>
    !this.forbidSending &&
    (this.messageGroup.value.message ||
      this.fileUploadControl?.value?.length ||
      (this.checked && this.messageGroup.value.phone));

  checkboxChanged({ checked }): void {
    this.checked = checked;
    if (checked) {
      this.messageGroup.controls.phone.enable();
    } else {
      this.messageGroup.controls.phone.disable();
    }
  }

  getTranslations(): void {
    this.translate
      .selectTranslate(
        this.subjectOptions.map(
          (_, index: number) =>
            `app.navigation.send-message-dialog.subject.options.${index}`,
        ),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (translations: object) =>
          (this.subjectOptions = Object.values(translations)),
      );
  }

  sendMessage(): void {
    if (!this.canSend()) return;
    this.error = undefined;
    const ctr = this.fileUploadControl;
    if (ctr?.value?.length && !ctr.valid) {
      const errorKey =
        Object.keys(ctr.getError()[0])[0] === 'fileSize'
          ? 'file-size'
          : 'unsupported-file';
      this.error = this.translate.translate(`shared.errors.${errorKey}`);
      return;
    }
    this.forbidSending = true;
    const callback = () => {
      this.messageSent = true;
    };
    const data = this.messageGroup.value;
    this.ngrxStore.dispatch(
      sendSupportForm({
        callback,
        ...data,
        files: this.fileUploadControl.value,
      }),
    );
  }

  showEmailField = (emailAddress: string): boolean =>
    emailAddress.includes('@partners.menutech.com');

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
