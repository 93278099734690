<write-new-menu
  #writeNewMenu
  [defaultTemplates]="defaultTemplates()"
  [lang]="lang()"
  [mtTooltip]="small() && ('menus.overview.actions.create' | transloco)"
  [mtTooltipHoriziontal]="true"
  [small]="small()"
  (createMenu)="createMenu.emit($event)"
></write-new-menu>

<ng-container #createButtonContainer></ng-container>

<div class="scroll-nav" [class.small]="small()">
  <nav>
    <a
      mat-fab
      extended
      [disableRipple]="true"
      class="navigation__link"
      routerLink="/dashboard"
      [mtTooltip]="small() && ('sidebar.navigation.dashboard.text' | transloco)"
      [mtTooltipHoriziontal]="true"
      [routerLinkActive]="['active']"
      (click)="closed.emit()"
    >
      <mat-icon aria-hidden="true" class="icons">dashboard</mat-icon
      ><span class="link">{{
        'sidebar.navigation.dashboard.text' | transloco
      }}</span>
    </a>
    <a
      mat-fab
      extended
      [disableRipple]="true"
      class="navigation__link"
      routerLink="/menus"
      [mtTooltip]="small() && ('sidebar.navigation.menus.text' | transloco)"
      [mtTooltipHoriziontal]="true"
      [routerLinkActive]="['active']"
      (click)="closed.emit()"
    >
      <mat-icon aria-hidden="true" class="icons">import_contacts</mat-icon>
      <span class="link">
        {{ 'sidebar.navigation.menus.text' | transloco }}
      </span>
    </a>
    <a
      mat-fab
      extended
      [disableRipple]="true"
      class="navigation__link"
      routerLink="/templates"
      [mtTooltip]="small() && ('sidebar.navigation.templates.text' | transloco)"
      [mtTooltipHoriziontal]="true"
      [routerLinkActive]="['active']"
      (click)="closed.emit()"
    >
      <mat-icon aria-hidden="true" class="icons">color_lens</mat-icon
      ><span class="link">{{
        'sidebar.navigation.templates.text' | transloco
      }}</span>
    </a>
    @if (hasModule('man', true)) {
      <div class="line">
        <hr />
      </div>
    }
    @if (hasModule('man', true) || hasModule('loca', true)) {
      <mat-expansion-panel
        #managePanel
        class="exp-panel mat-elevation-z0"
        [@.disabled]="true"
        [expanded]="!small()"
        [hideToggle]="true"
        [mtTooltip]="small() && ('sidebar.navigation.manage' | transloco)"
        [mtTooltipHoriziontal]="true"
        (opened)="expansionOpened()"
      >
        <mat-expansion-panel-header
          class="header"
          collapsedHeight="55px"
          expandedHeight="55px"
        >
          <mat-panel-title class="navigation__link exp-title">
            <mat-icon aria-hidden="true" class="icons">assignment</mat-icon
            ><span class="link">{{
              'sidebar.navigation.manage' | transloco
            }}</span>
            @if (!small()) {
              <div
                class="toggle-indicator"
                [class.opened]="managePanel.expanded"
              ></div>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        @if (hasModule('man', true)) {
          <a
            mat-fab
            extended
            [disableRipple]="true"
            class="navigation__link sub-link"
            (click)="closed.emit()"
            [class.expired]="hasModule('man', true) && !hasModule('man')"
            [routerLinkActive]="hasModule('man') ? ['active'] : []"
            [routerLink]="hasModule('man') ? '/dishes' : ''"
            [matTooltip]="
              hasModule('man', true) && !hasModule('man')
                ? ('sidebar.navigation.shared.trial_expired' | transloco)
                : ''
            "
            matTooltipClass="top-navbar-tooltip"
          >
            <span class="link">{{
              'sidebar.navigation.dishes.text' | transloco
            }}</span>
          </a>
        }
        @if (hasModule('man') && hasModule('recip')) {
          <a
            mat-fab
            extended
            [disableRipple]="true"
            class="navigation__link sub-link"
            (click)="closed.emit()"
            [routerLinkActive]="['active']"
            routerLink="/ingredients"
          >
            <span class="link">{{
              'sidebar.navigation.ingredients.text' | transloco
            }}</span>
          </a>
        }
        @if (hasModule('loca', true) && user()?.role !== 3) {
          <a
            mat-fab
            extended
            [disableRipple]="true"
            class="navigation__link sub-link"
            (click)="closed.emit()"
            [class.expired]="hasModule('loca', true) && !hasModule('loca')"
            [routerLinkActive]="hasModule('loca') ? ['active'] : []"
            [routerLink]="hasModule('loca') ? '/locations' : ''"
            [matTooltip]="
              hasModule('loca', true) && !hasModule('loca')
                ? ('sidebar.navigation.shared.trial_expired' | transloco)
                : ''
            "
            matTooltipClass="top-navbar-tooltip"
          >
            <span class="link">{{
              'sidebar.navigation.locations.text' | transloco
            }}</span>
          </a>
        }
        @if (hasModule('order', true) && user()?.role !== 3) {
          <a
            mat-fab
            extended
            [disableRipple]="true"
            class="navigation__link sub-link"
            (click)="closed.emit()"
            [class.expired]="hasModule('order', true) && !hasModule('order')"
            [routerLinkActive]="hasModule('order') ? ['active'] : []"
            [routerLink]="hasModule('order') ? '/types' : ''"
            [matTooltip]="
              hasModule('order', true) && !hasModule('order')
                ? ('sidebar.navigation.shared.trial_expired' | transloco)
                : ''
            "
            matTooltipClass="top-navbar-tooltip"
          >
            <span class="link">{{
              'sidebar.navigation.types.text' | transloco
            }}</span>
          </a>
        }
        @if (hasModule('auto', true) && user()?.role !== 3) {
          <a
            mat-fab
            extended
            [disableRipple]="true"
            class="navigation__link sub-link"
            (click)="closed.emit()"
            [class.expired]="hasModule('auto', true) && !hasModule('auto')"
            [routerLinkActive]="hasModule('auto') ? ['active'] : []"
            [routerLink]="hasModule('auto') ? '/rules' : ''"
            [matTooltip]="
              hasModule('auto', true) && !hasModule('auto')
                ? ('sidebar.navigation.shared.trial_expired' | transloco)
                : ''
            "
            matTooltipClass="top-navbar-tooltip"
          >
            <span class="link">{{
              'sidebar.navigation.rules.text' | transloco
            }}</span>
          </a>
        }
        @if (
          hasModule('man', true) &&
          (hasModule('all') || hasModule('add') || hasModule('label')) &&
          user()?.role !== 3 &&
          user()?.organisation
        ) {
          <a
            mat-fab
            extended
            [disableRipple]="true"
            class="navigation__link sub-link"
            (click)="closed.emit()"
            [class.expired]="hasModule('man', true) && !hasModule('man')"
            [routerLinkActive]="hasModule('man') ? ['active'] : []"
            [routerLink]="hasModule('man') ? '/declarations' : ''"
            [matTooltip]="
              hasModule('man', true) && !hasModule('man')
                ? ('sidebar.navigation.shared.trial_expired' | transloco)
                : ''
            "
            matTooltipClass="top-navbar-tooltip"
          >
            <span class="link">{{
              'sidebar.navigation.declarations.text' | transloco
            }}</span>
          </a>
        }
      </mat-expansion-panel>
    }

    @if (hasModule('procu', true)) {
      <div class="line">
        <hr />
      </div>
    }
    @if (hasModule('procu', true)) {
      <mat-expansion-panel
        #managePanel
        class="exp-panel mat-elevation-z0"
        [@.disabled]="true"
        [expanded]="!small()"
        [hideToggle]="true"
        [mtTooltip]="small() && ('sidebar.navigation.procurement' | transloco)"
        [mtTooltipHoriziontal]="true"
        (opened)="expansionOpened()"
      >
        <mat-expansion-panel-header
          class="header"
          collapsedHeight="55px"
          expandedHeight="55px"
        >
          <mat-panel-title class="navigation__link exp-title">
            <mat-icon aria-hidden="true" class="icons">shopping_basket</mat-icon
            ><span class="link">{{
              'sidebar.navigation.procurement' | transloco
            }}</span>
            @if (!small()) {
              <div
                class="toggle-indicator"
                [class.opened]="managePanel.expanded"
              ></div>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a
          mat-fab
          extended
          [disableRipple]="true"
          class="navigation__link sub-link"
          (click)="closed.emit()"
          [class.expired]="hasModule('procu', true) && !hasModule('procu')"
          [routerLinkActive]="hasModule('procu') ? ['active'] : []"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="hasModule('procu') ? '/inventory' : ''"
          [matTooltip]="
            hasModule('procu', true) && !hasModule('procu')
              ? ('sidebar.navigation.shared.trial_expired' | transloco)
              : ''
          "
          matTooltipClass="top-navbar-tooltip"
        >
          <span class="link">{{
            'sidebar.navigation.inventory.text' | transloco
          }}</span>
        </a>
        @if (hasModule('procu', true)) {
          <a
            mat-fab
            extended
            [disableRipple]="true"
            class="navigation__link sub-link"
            (click)="closed.emit()"
            [class.expired]="hasModule('procu', true) && !hasModule('procu')"
            [routerLinkActive]="hasModule('procu') ? ['active'] : []"
            [routerLink]="hasModule('procu') ? ['/inventory', 'in'] : ''"
            [matTooltip]="
              hasModule('procu', true) && !hasModule('procu')
                ? ('sidebar.navigation.shared.trial_expired' | transloco)
                : ''
            "
            matTooltipClass="top-navbar-tooltip"
          >
            <span class="link">{{
              'sidebar.navigation.inventory_in.text' | transloco
            }}</span>
          </a>
        }
        <a
          mat-fab
          extended
          [disableRipple]="true"
          class="navigation__link sub-link"
          (click)="closed.emit()"
          [class.expired]="hasModule('procu', true) && !hasModule('procu')"
          [routerLinkActive]="hasModule('procu') ? ['active'] : []"
          [routerLink]="hasModule('procu') ? ['/inventory', 'out'] : ''"
          [matTooltip]="
            hasModule('procu', true) && !hasModule('procu')
              ? ('sidebar.navigation.shared.trial_expired' | transloco)
              : ''
          "
          matTooltipClass="top-navbar-tooltip"
        >
          <span class="link">{{
            'sidebar.navigation.inventory_out.text' | transloco
          }}</span>
        </a>
      </mat-expansion-panel>
    }

    @if (hasModule('procu', true)) {
      <div class="line">
        <hr />
      </div>
    }
    @if (hasModule('integ', true)) {
      <mat-expansion-panel
        #managePanel
        class="exp-panel mat-elevation-z0"
        [@.disabled]="true"
        [expanded]="!small()"
        [hideToggle]="true"
        [mtTooltip]="
          small() && ('sidebar.navigation.integrations.text' | transloco)
        "
        [mtTooltipHoriziontal]="true"
        (opened)="expansionOpened()"
      >
        <mat-expansion-panel-header
          class="header"
          collapsedHeight="55px"
          expandedHeight="55px"
        >
          <mat-panel-title class="navigation__link exp-title">
            <mat-icon aria-hidden="true" class="icons autorenew"
              >autorenew</mat-icon
            ><span class="link">{{
              'sidebar.navigation.integrations.text' | transloco
            }}</span>
            @if (!small()) {
              <div
                class="toggle-indicator"
                [class.opened]="managePanel.expanded"
              ></div>
            }
          </mat-panel-title>
        </mat-expansion-panel-header>
        <a
          mat-fab
          extended
          [disableRipple]="true"
          class="navigation__link sub-link"
          (click)="closed.emit()"
          [class.expired]="hasModule('integ', true) && !hasModule('integ')"
          [routerLinkActive]="hasModule('integ') ? ['active'] : []"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="hasModule('integ') ? '/integrations/links' : ''"
          [matTooltip]="
            hasModule('integ', true) && !hasModule('integ')
              ? ('sidebar.navigation.shared.trial_expired' | transloco)
              : ''
          "
          matTooltipClass="top-navbar-tooltip"
        >
          <span class="link">{{
            'sidebar.navigation.integrations.links' | transloco
          }}</span>
        </a>
        <a
          mat-fab
          extended
          [disableRipple]="true"
          class="navigation__link sub-link"
          (click)="closed.emit()"
          [class.expired]="hasModule('integ', true) && !hasModule('integ')"
          [routerLinkActive]="hasModule('integ') ? ['active'] : []"
          [routerLinkActiveOptions]="{ exact: true }"
          [routerLink]="
            hasModule('integ') ? ['/integrations', 'collections'] : ''
          "
          [matTooltip]="
            hasModule('integ', true) && !hasModule('integ')
              ? ('sidebar.navigation.shared.trial_expired' | transloco)
              : ''
          "
          matTooltipClass="top-navbar-tooltip"
        >
          <span class="link">{{
            'sidebar.navigation.integrations.collections' | transloco
          }}</span>
        </a>
      </mat-expansion-panel>
    }

    @if (hasModule('integ', true)) {
      <div class="line">
        <hr />
      </div>
    }

    @if (hasModule('diet', true)) {
      <a
        mat-fab
        extended
        [disableRipple]="true"
        class="navigation__link"
        routerLink="/diets"
        [class.expired]="hasModule('diet', true) && !hasModule('diet')"
        [mtTooltip]="
          hasModule('diet', true) && !hasModule('diet')
            ? ('sidebar.navigation.shared.trial_expired' | transloco)
            : small() && ('sidebar.navigation.diets.text' | transloco)
        "
        [mtTooltipHoriziontal]="true"
        [routerLinkActive]="['active']"
        (click)="closed.emit()"
      >
        <mat-icon aria-hidden="true" class="icons restaurant_menu"
          >spa</mat-icon
        >
        <span class="link">{{
          'sidebar.navigation.diets.text' | transloco
        }}</span>
      </a>
    }
    @if (hasModule('recip', true)) {
      <a
        mat-fab
        extended
        [disableRipple]="true"
        class="navigation__link"
        [class.expired]="hasModule('recip', true) && !hasModule('recip')"
        [mtTooltip]="
          hasModule('recip', true) && !hasModule('recip')
            ? ('sidebar.navigation.shared.trial_expired' | transloco)
            : small() && ('sidebar.navigation.recipes.text' | transloco)
        "
        [mtTooltipHoriziontal]="true"
        [routerLinkActive]="hasModule('recip') ? ['active'] : []"
        [routerLink]="hasModule('recip') ? '/recipes' : ''"
        (click)="closed.emit()"
      >
        <mat-icon aria-hidden="true" class="icons list_alt">list_alt</mat-icon>
        <span class="link">{{
          'sidebar.navigation.recipes.text' | transloco
        }}</span>
      </a>
    }
  </nav>
  <div [hidden]="hideBottom" class="bottom">
    @if (showModulesLink()) {
      <a
        mat-fab
        extended
        [disableRipple]="true"
        class="navigation__option modules"
        [mtTooltip]="small() && ('sidebar.navigation.modules' | transloco)"
        [mtTooltipHoriziontal]="true"
        [routerLink]="['/settings', 'modules']"
      >
        <mat-icon aria-hidden="true" class="icons">explore</mat-icon>
        <span class="link">{{ 'sidebar.navigation.modules' | transloco }}</span>
      </a>
    }
    <div class="navigation__option make-small" (mousedown)="toggleSmall(true)">
      @if (small()) {
        <mat-icon class="arrow-right">keyboard_arrow_left</mat-icon>
      }
      @if (!small()) {
        <mat-icon class="arrow-left">keyboard_arrow_left</mat-icon>
      }
    </div>
  </div>
</div>
