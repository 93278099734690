import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  inject,
  output,
  viewChildren,
  input,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';

import { RecipeIngredientsLineComponent } from './recipe-ingredients-line/recipe-ingredients-line.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { SpinnerComponent } from '../../shared/Components/spinner/spinner.component';
import { MatButtonModule } from '@angular/material/button';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'recipe-ingredients',
  templateUrl: './recipe-ingredients.component.html',
  styleUrls: ['./recipe-ingredients.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    RecipeIngredientsLineComponent,
    MatButtonModule,
    MatIconModule,
    SpinnerComponent,
    TranslocoPipe,
  ],
})
export class RecipeIngredientsComponent {
  private ngZone = inject(NgZone);

  readonly calcBasis = input<'gross' | 'net'>('gross');
  readonly ingredients =
    input<(RecipeIngredient | SimpleRecipeIngredient)[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly ingredientsAuto = input<Ingredient[]>(undefined);
  readonly showNetQuantity = input<boolean>(undefined);
  readonly isLocked = input<boolean>(undefined);
  readonly showIcons = input<boolean>(undefined);
  readonly allergensView = input<boolean>(undefined);
  readonly additivesView = input<boolean>(undefined);

  readonly updateIngredient = output<{
    url: string;
    id: number;
    ingredient: Partial<Ingredient>;
    recipeIngredient: RecipeIngredient;
  }>();
  readonly updateRecipeIngredient = output<{
    url: string;
    id: number;
    recipeIngredient: Partial<RecipeIngredient>;
    onFulfilled: () => void;
  }>();
  readonly deleteIngredientEvent = output<RecipeIngredient>();
  readonly addIngredientEvent = output();
  readonly searchIngredients = output<string>();
  readonly chooseIngredientEvent = output<{
    id: number;
    ingredient_id: number;
    order: number;
  }>();
  readonly createIngredientEvent = output<{
    ingredient: Partial<Ingredient>;
    id: number;
    order: number;
  }>();
  readonly changed = output<{
    data: {
      data: Ingredient;
      type: string;
    };
    recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
  }>();
  readonly sortIngredients = output<
    {
      id: number;
      order: number;
    }[]
  >();

  readonly nodeInputs = viewChildren(RecipeIngredientsLineComponent, {
    read: RecipeIngredientsLineComponent,
  });

  setFocus = () => {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        const nodeInputs = this.nodeInputs();
        if (!nodeInputs.at(-1)) return undefined;
        const last = nodeInputs.at(-1);
        last.focusNameOrQuantity();
      }, 500);
    });
  };

  deleteIngredient(event: any): void {
    this.deleteIngredientEvent.emit(event);
    this.setFocus();
  }

  chooseIngredient(
    data: {
      id: number;
      ingredient_id: number;
    },
    order: number,
  ): void {
    this.chooseIngredientEvent.emit({ ...data, order });
    this.setFocus();
  }

  createIngredient(
    data: {
      ingredient: Partial<Ingredient>;
      id: number;
    },
    order: number,
  ): void {
    this.createIngredientEvent.emit({ ...data, order });
    this.setFocus();
  }

  addIngredient(item?: RecipeIngredientsLineComponent): void {
    const nodeInputs = this.nodeInputs();
    if (
      !nodeInputs.at(-1) ||
      (!nodeInputs.at(-1) && !nodeInputs.at(-1).recipeIngredient()) ||
      (!item && nodeInputs.at(-1).recipeIngredient().url) ||
      item === nodeInputs.at(-1)
    ) {
      this.addIngredientEvent.emit();
    }
    this.setFocus();
  }

  public trackByIngredient(
    index,
    item: RecipeIngredient | SimpleRecipeIngredient,
  ): void | number {
    return item.id;
  }

  sortRecipeIngredients(): void {
    const data: { id: number; order: number }[] = this.ingredients().map(
      (ingredient, index) => ({
        id: ingredient.id,
        order: index + 1,
      }),
    );
    this.sortIngredients.emit(data);
  }

  drop(event: CdkDragDrop<{ id: number; name: string }[]>) {
    moveItemInArray(
      this.ingredients(),
      event.previousIndex,
      event.currentIndex,
    );
    this.sortRecipeIngredients();
  }
}
