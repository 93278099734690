import {
  Component,
  OnInit,
  OnDestroy,
  inject,
  output,
  input,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { currencyList } from 'src/app/shared/Models/user';
import { selectUser } from 'src/app/shared/user/ngrx/user.selectors';
import { Subject } from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

export interface TrialBlockBoxDeps {
  currencySymbol?: string;
  minPrice?: number;
  currency?: string;
}

@Component({
  selector: 'trial-block-box',
  templateUrl: './trial-block-box.component.html',
  styleUrls: ['./trial-block-box.component.scss'],
  imports: [MatButtonModule, MatIconModule, TranslocoPipe],
})
export class TrialBlockBoxComponent implements OnInit, OnDestroy {
  private ngrxStore = inject<Store<State>>(Store);

  readonly deps = input<TrialBlockBoxDeps>({});
  readonly actionClicked = output<void>();
  readonly closePanel = output<void>();
  readonly completeProfile = output<void>();

  private destroyed$ = new Subject<void>();
  user$ = this.ngrxStore.select(selectUser);

  depFiltered: TrialBlockBoxDeps = {};

  ngOnInit(): void {
    this.user$.subscribe((userDetail) => {
      this.depFiltered = currencyList.find(
        (currencyDetail) =>
          currencyDetail.currency === userDetail?.status?.subscription_currency,
      );
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
