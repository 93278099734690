import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { Allergen } from 'src/app/shared/Models/declarations';
import {
  ContentLanguage,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';
import { Diet } from 'src/app/shared/Models/diet';
import {
  CopyAsOptions,
  CopyMenuOptions,
  GenerateArchiveOptions,
  MenusOverviewParams,
  OverviewMenu,
  ShareMenuDetail,
} from 'src/app/shared/Models/menu';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { SimpleUser, User } from 'src/app/shared/Models/user';
import { ShareMenuComponent } from './share-menu/share-menu.component';
import { RefreshSubMenuComponent } from './refresh-submenu/refresh-submenu.component';
import { RegenerateMenuComponent } from './regenerate-menu/regenerate-menu.component';
import { CopyWithOptionsComponent } from './copy-with-options/copy-with-options.component';
import { DeleteMenuComponent } from './delete-menu/delete-menu.component';
import { DeleteChildrenComponent } from './delete-children/delete-children.component';
import { GenerateArchiveComponent } from './generate-archive/generate-archive.component';
import { CreateDietComponent } from './create-diet/create-diet.component';
import { MenuFiltersComponent } from './menu-filters/menu-filters.component';

@Component({
  selector: 'menu-overview-sidebar',
  templateUrl: './menu-overview-sidebar.component.html',
  styleUrls: ['./menu-overview-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MenuFiltersComponent,
    CreateDietComponent,
    GenerateArchiveComponent,
    DeleteChildrenComponent,
    DeleteMenuComponent,
    CopyWithOptionsComponent,
    RegenerateMenuComponent,
    RefreshSubMenuComponent,
    ShareMenuComponent,
  ],
})
export class MenuOverviewSidebarComponent {
  readonly actionName = input<string>(undefined);
  readonly allergens = input<Allergen[]>(undefined);
  readonly currentLocation = input<number>(undefined);
  readonly deleteChildrenList = input<string[]>(undefined);
  readonly deleteMenuWarning = input<string>(undefined);
  readonly diets = input<Diet[]>(undefined);
  readonly hasModuleAllergens = input<boolean>(undefined);
  readonly hasModuleOrders = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly languages = input<ContentLanguage[]>(undefined);
  readonly locations = input<SimpleLocation[]>(undefined);
  readonly menu = input<OverviewMenu>(undefined);
  readonly menuDate = input<string>(undefined);
  readonly menuName = input<string>(undefined);
  readonly organisationUsers = input<SimpleUser[]>(undefined);
  readonly params = input<Partial<MenusOverviewParams>>({});
  readonly showMenuFilter = input<boolean>(undefined);
  readonly templates = input<OnboardingTemplate[]>(undefined);
  readonly user = input<User>(undefined);

  readonly cancelAction = output<void>();
  readonly chooseFilter = output<Partial<MenusOverviewParams>>();
  readonly copyAsAction = output<CopyAsOptions>();
  readonly copyMenuWithOptions = output<CopyMenuOptions>();
  readonly createArchive = output<GenerateArchiveOptions>();
  readonly createNewDiet = output<number[]>();
  readonly deleteChildrenMenu = output<void>();
  readonly deleteSelectedMenu = output<void>();
  readonly refreshSubMenuActions = output<void>();
  readonly regenerateMenusActions = output<void>();
  readonly shareMenuAction = output<ShareMenuDetail>();
}
