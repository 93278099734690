<div class="filters-wrapper">
  <express-translation
    class="express-translate menu-action"
    [isTrial]="isTrial()"
    [profileComplete]="profileComplete()"
    [loading]="menuDishesLoading()"
    [disabled]="blockExpressTranslationLangs().includes(lang())"
    (expressTranslate)="expressTranslate.emit()"
  ></express-translation>
  <magic-stick
    class="grammar-check menu-action"
    [loading]="menuDishesLoading()"
    [disabled]="blockGrammarCheckLangs().includes(lang())"
    [title]="'dishes.translation.grammar-check.title' | transloco"
    [description]="'dishes.translation.grammar-check.description' | transloco"
    [buttonMsg]="'dishes.translation.grammar-check.button' | transloco"
    [buttonLoadingMsg]="
      'dishes.translation.grammar-check.button-loading' | transloco
    "
    (clicked)="
      isTrial() && !profileComplete() ? showTrialBanner() : grammarCheck.emit()
    "
  ></magic-stick>
  <mat-expansion-panel
    [class.faded]="menuDishesLoading()"
    class="filters"
    [expanded]="true"
  >
    <mat-expansion-panel-header class="filters-header">
      <mat-panel-title>
        <span class="title">
          {{ 'dishes.translation.filter.title' | transloco }}
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="filter-items">
      <div class="title-filter">
        <div class="filter">
          <mat-form-field>
            <mat-select
              #matSelect
              (selectionChange)="filterChanged.emit($event)"
              [ngModel]="filter()"
            >
              @for (filter of dishFilters; track filter) {
                <mat-option [value]="filter.id">
                  {{ filter.value | transloco }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <form class="search-form" [formGroup]="searchForm">
        <div class="type">
          <div class="title type-title">
            {{ 'dishes.translation.search-type' | transloco }}
          </div>
          <mat-form-field [color]="'primary'" class="type-field">
            <mat-select formControlName="filter">
              <mat-option [value]="'default'">
                {{ 'dishes.translation.all' | transloco }}
              </mat-option>
              @for (filter of filters; track filter) {
                <mat-option [value]="filter">
                  {{ 'dishes.' + filter + '.placeholder' | transloco }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </form>
      <div class="title-filter">
        <div class="filter">
          <div class="translations-count-detail">
            <div class="label">
              {{ 'dishes.translation.filter.missing-translations' | transloco }}
            </div>
            <div
              class="translation-count missing-count"
              [class.missing]="misssingTranslationsCount()"
            >
              {{ misssingTranslationsCount() }}
            </div>
          </div>
        </div>
      </div>
      <div class="title-filter">
        <div class="filter">
          <div class="translations-count-detail">
            <div class="label">
              {{
                'dishes.translation.filter.unverified-express-translations'
                  | transloco
              }}
            </div>
            <div
              class="translation-count unverified-count"
              [class.missing]="unverifiedTranslationsCount()"
            >
              {{ unverifiedTranslationsCount() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</div>
