import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { State } from 'src/app/reducers';
import { ContentLanguage, langs } from 'src/app/shared/constants/languages';
import { Menu, MenuPreviewData } from 'src/app/shared/Models/menu';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { SimpleLayout } from 'src/app/shared/Models/template';
import { Translation } from 'src/app/shared/Models/translation';
import { UserStatusPrivileges } from 'src/app/shared/Models/user';
import { handleHttpError } from 'src/app/shared/ngrx/shared.actions';
import { FileService } from 'src/app/shared/Services/files/files.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import printJS from 'print-js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  createMenuTranslation,
  deleteMenuTranslation,
  fetchMenuTranslations,
} from '../../translate/ngrx/menu-translate.actions';
import {
  selectIsMenuTranslationLoading,
  selectTranslationsList,
} from '../../translate/ngrx/menu-translate.selectors';
import { PrintInstructionComponent } from './print-instruction/print-instruction.component';
import { RouterLink } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SpinnerComponent } from '../../../../shared/Components/spinner/spinner.component';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'completed-menu',
  templateUrl: './completed-menu.component.html',
  styleUrls: ['./completed-menu.component.scss'],
  imports: [
    MatCardModule,
    SpinnerComponent,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NgTemplateOutlet,
    MatTabsModule,
    MatProgressSpinnerModule,
    RouterLink,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class CompletedMenuComponent
  implements OnDestroy, OnChanges, AfterViewInit
{
  dialog = inject(MatDialog);
  protected ngrxStore = inject<Store<State>>(Store);
  private translate = inject(TranslocoService);
  private fileService = inject(FileService);
  private utils = inject(UtilsService);
  private media = inject(MediaMatcher);

  readonly menu = input<Menu>(undefined);
  readonly privileges = input<UserStatusPrivileges>(undefined);
  readonly userStatus = input<AccountStatusMessage>(undefined);
  readonly preview = output<MenuPreviewData>();

  private destroyed$ = new Subject<void>();
  downloadingPdf = false;
  languages = langs;
  multilingual = false;
  multilingualRequired = false;
  languagesNumber = 0;
  currentLangNumber = 0;
  languagesTitle = '';
  params = {};
  printLoading = false;
  newLanguages: any;
  checkedTranslations: { translation: Translation; date: number }[] = [];
  currentTranslations = [];
  isMobile = false;
  menuEditDisableMessage: string;

  translations$ = this.ngrxStore.select(selectTranslationsList);
  translationsLoading$ = this.ngrxStore.select(selectIsMenuTranslationLoading);

  private statusBarQuery: MediaQueryList;
  private mobileQuery = (data) => {
    const { matches } = data;
    this.isMobile = matches;
  };

  constructor() {
    this.translate.langChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.handleLang);
    this.translate.langChanges$.subscribe(this.handleLang);
    this.handleLang();
    this.translations$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.handleTranslation);
    this.utils.getTranslation(
      'menus.complete.edit_disable',
      (msg) => (this.menuEditDisableMessage = msg),
    );
  }

  ngAfterViewInit(): void {
    this.statusBarQuery = this.media.matchMedia(`(max-width: 768px)`);
    // Safari fix
    if (this.statusBarQuery.addEventListener) {
      this.statusBarQuery.addEventListener(`change`, this.mobileQuery);
    } else if (this.statusBarQuery.addListener) {
      this.statusBarQuery.addListener((event) => {
        if (event.type === `change`) this.mobileQuery(event);
      });
    }

    this.dispatchStatusBarEvent();
  }

  dispatchStatusBarEvent(): void {
    this.mobileQuery({
      type: `change`,
      matches: window.innerWidth < 768,
    });
  }

  handleLanguageRestrictions = (template: SimpleLayout) => {
    if (!template) return undefined;
    this.multilingual = template.multilingual;
    this.multilingualRequired = template.multilingual_required;
    this.languagesNumber = template.number_languages;
    this.currentLangNumber = this.getCurrentLangNumber();
  };

  handleCheckLang = (
    translation: Translation,
    { checked }: { checked: boolean },
  ) => {
    if (!this.multilingual) {
      Object.keys(this.params).forEach((key) => {
        if (key !== translation.language) {
          this.params[key] = false;
        }
      });
    }
    this.currentLangNumber = this.getCurrentLangNumber();
    if (checked) {
      if (!this.multilingual) {
        this.checkedTranslations = [{ translation, date: Date.now() }];
      } else {
        this.checkedTranslations.push({ translation, date: Date.now() });
      }
    } else {
      this.checkedTranslations = this.checkedTranslations.filter(
        (item) => item.translation.id !== translation.id,
      );
    }
    this.checkedTranslations.sort((a, b) => a.date - b.date);
  };

  getCurrentLangNumber = () =>
    Object.keys(this.params).filter((el) => this.params[el]).length;

  handleLang = () => {
    this.translate
      .selectTranslateObject('shared.languages')
      .pipe(takeUntil(this.destroyed$))
      .subscribe((el) => {
        this.languagesTitle = el;
      });
  };

  ngOnChanges(changes: SimpleChanges) {
    const menu = this.menu();
    if (
      'menu' in changes &&
      menu &&
      changes.menu.currentValue.id !== changes.menu?.previousValue?.id
    ) {
      this.ngrxStore.dispatch(fetchMenuTranslations({ menu: menu }));
      this.handleLanguageRestrictions(menu.template_detail);
    }
  }

  handleTranslation = (translation: Translation[]) => {
    if (!translation) return undefined;
    this.currentTranslations = translation;
    const currentLanguages = translation.map((item) => item.language);
    this.newLanguages = this.languages.filter(
      (item) => !currentLanguages.includes(item),
    );
  };

  addTranslation(lang: ContentLanguage) {
    this.ngrxStore.dispatch(createMenuTranslation({ lang }));
  }

  showTranslatedMenu() {
    const translate =
      this.checkedTranslations[0]?.translation || this.currentTranslations[0];
    if (!translate) return undefined;
    if (
      this.currentLangNumber < this.languagesNumber &&
      this.multilingual &&
      this.multilingualRequired
    )
      return undefined;

    const baseLang = translate.language;
    const menu = this.menu();
    this.preview.emit({
      url: translate.preview,
      params: { print_mode: true },
      baseLanguage: baseLang,
      numberLanguages: menu.template_detail?.number_languages,
      langs: menu.translations_list
        .map((lang) => ({
          lang,
          activated: this.checkedTranslations.length
            ? this.checkedTranslations
                .map((t) => t.translation.language)
                .includes(lang)
            : lang === baseLang,
          order: lang === baseLang ? 0 : 1,
        }))
        .sort((a, b) => a.order - b.order)
        .map((l, index) => ({
          ...l,
          order: index,
        })),
      multiRequired: menu.template_detail?.multilingual_required,
      htmlPreview:
        menu?.style?.print_reverse === false &&
        menu?.style?.print_mirror < 1 &&
        menu?.style?.print_folding === null,
    });
  }

  getParams = (params) => ({
    lang: Object.keys(params).filter((el) => this.params[el]),
  });

  deleteTranslation(url: string) {
    if (!url) return undefined;
    this.ngrxStore.dispatch(deleteMenuTranslation({ url }));
  }

  downloadPdf() {
    const translate =
      this.checkedTranslations[0]?.translation || this.currentTranslations[0];
    const pdfLangs = this.checkedTranslations.map(
      (t) => t.translation.language,
    );
    this.downloadingPdf = true;
    this.fileService
      .downloadFile(translate.preview, {
        ...this.getParams({ ...this.params, lang: pdfLangs }),
        base_lang: translate.language,
        download: true,
        print_mode: true,
      })
      .add(() => {
        this.downloadingPdf = false;
      });
  }

  printTranslatedMenu() {
    this.printLoading = true;
    const translate =
      this.checkedTranslations[0]?.translation || this.currentTranslations[0];
    if (!translate) return undefined;
    if (
      this.currentLangNumber < this.languagesNumber &&
      this.multilingual &&
      this.multilingualRequired
    )
      return undefined;
    const menuLangs = this.checkedTranslations.map(
      (t) => t.translation.language,
    );
    const modalMessage = this.translate.translate('app.pdf-loading-overlay');
    this.fileService
      .fetchRenderedTemplate(translate.preview, {
        ...this.getParams({ ...this.params, lang: menuLangs }),
        base_lang: translate.language,
        print: true,
        print_mode: true,
      })
      .subscribe({
        next: (result) => {
          this.printLoading = false;
          printJS({
            printable: result,
            type: 'pdf',
            showModal: true,
            modalMessage,
            onError: (error) => {
              Sentry.captureException(error);
            },
          });
        },
        error: (error) => {
          this.printLoading = false;
          this.ngrxStore.dispatch(handleHttpError({ error }));
        },
      });
  }

  createClicked(btn: MatButton, tooltip: MatTooltip) {
    this.utils.createBtnMessage(btn, tooltip);
  }

  showTooltip = (tooltip) => setTimeout(() => tooltip.show(), 200);

  showEditSnackMessage(btn: MatButton, tooltip: MatTooltip) {
    this.utils.createBtnMessage(btn, tooltip);
  }

  openPrintInstructions() {
    const dialog = this.dialog.open(PrintInstructionComponent, {
      autoFocus: false,
      data: {
        menu: this.menu(),
      },
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
