import { AfterViewInit, Component, OnDestroy, inject } from '@angular/core';
import {
  MatDialogRef,
  MatDialogContent,
  MatDialogActions,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { OptionItemComponent } from '../option-item/option-item.component';
import {
  Additive,
  Allergen,
  GenericDeclaration,
  Label,
} from 'src/app/shared/Models/declarations';
import { ContentLanguage } from 'src/app/shared/constants/languages';

interface AffectModalData {
  option: Allergen | Additive | Label;
  children: GenericDeclaration[];
  items: (Allergen | Additive | Label)[];
  lang: ContentLanguage;
  changed: (item: GenericDeclaration) => void;
}

@Component({
  selector: 'app-affect-modal',
  templateUrl: './affect-modal.component.html',
  styles: [
    `
      .continue {
        margin: 10px 0;
        text-transform: uppercase;
      }
      .options {
        display: block;
      }
      .actions {
        justify-content: flex-end;
      }
    `,
  ],
  imports: [
    MatDialogContent,
    OptionItemComponent,
    MatDialogActions,
    MatButtonModule,
    TranslocoPipe,
  ],
})
export class AffectModalComponent implements OnDestroy, AfterViewInit {
  public dialog = inject<MatDialogRef<AffectModalComponent>>(MatDialogRef);
  protected data = inject<AffectModalData>(MAT_DIALOG_DATA);

  private overlay: Element;

  ngAfterViewInit() {
    this.overlay = document.querySelector('.cdk-overlay-container');
    this.overlay.addEventListener('click', this.stopPropagation);
  }

  stopPropagation = (e: Event) => e.stopPropagation && e.stopPropagation();

  ngOnDestroy() {
    if (this.overlay)
      this.overlay.removeEventListener('click', this.stopPropagation);
  }
}
