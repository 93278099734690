import { AfterViewInit, Component, viewChild } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { SpinnerComponent } from '../../../shared/Components/spinner/spinner.component';

@Component({
  selector: 'confirmed-email',
  template: `
    <div class="confirm">
      <img
        #successImage
        [hidden]="spinner"
        src="../../../assets/img/confirm/confirmed.gif"
      />
      @if (spinner) {
        <spinner></spinner>
      }
      <span class="confirmed">{{
        'auth.email_confirmation.success.title' | transloco
      }}</span>
      <p>
        {{ 'auth.email_confirmation.success.description' | transloco }}
        <a routerLink="/dashboard">{{
          'auth.email_confirmation.success.link' | transloco
        }}</a
        >.
      </p>
    </div>
  `,
  styles: [
    `
      img {
        width: 100%;
        margin-top: -100px;
        margin-bottom: 15px;
      }
      .confirm {
        text-align: center;
      }
      .confirmed {
        margin: 10px 0;
        font-size: 20px;
        font-weight: 300;
      }
    `,
  ],
  imports: [SpinnerComponent, RouterLink, TranslocoPipe],
})
export class ConfirmedEmailComponent implements AfterViewInit {
  readonly image = viewChild<HTMLImageElement>('successImage');
  spinner = true;

  ngAfterViewInit() {
    this.image().onload = () => {
      this.spinner = false;
    };
  }
}
