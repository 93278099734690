import { Component, OnDestroy, OnInit, output, input } from '@angular/core';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { StopPropagationDirective } from '../../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'number-item',
  templateUrl: './number-item.component.html',
  styleUrls: ['./number-item.component.css'],
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MtTooltipDirective,
    FormsModule,
    StopPropagationDirective,
    LockItemComponent,
    TranslocoPipe,
  ],
})
export class NumberItemComponent implements OnInit, OnDestroy {
  readonly disabled = input(false);
  readonly disabledFields = input<string[]>([]);
  readonly field = input<string>(undefined);
  readonly hideLabel = input<boolean>(undefined);
  readonly lockedFields = input<string[]>(undefined);
  readonly icon = input<string>(undefined);
  readonly narrow = input(false);
  readonly type = input<'number' | 'perc' | 'value'>('number');
  readonly unit = input('');
  readonly step = input(1);
  readonly min = input<number>(null);
  readonly max = input<number>(null);
  readonly menu = input<Menu>(undefined);
  readonly placeholder = input<string>(undefined);
  readonly showLock = input<boolean>(undefined);
  readonly showNull = input(true);

  readonly updateMenu = output<DeepPartial<Menu>>();
  debouncer = new Subject<DeepPartial<Menu>>();
  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe((value: DeepPartial<Menu>) => {
        this.updateMenu.emit(value);
      });
  }

  decValue(target) {
    let value = parseFloat(target.value);
    const min = this.min();
    if (min && value <= min) return undefined;
    value =
      this.type() === 'perc'
        ? this.parsePercDec(value)
        : this.parseValueDec(value, this.step());
    const type = this.type();
    target.value =
      type === 'perc'
        ? value + '?'
        : type === 'value'
          ? value + this.unit()
          : value;
    this.debouncer.next({
      style: {
        [this.field()]: value,
      },
    });
  }

  incValue(target) {
    let value = parseFloat(target.value);
    const max = this.max();
    if (max && value >= max) return undefined;
    value =
      this.type() === 'perc'
        ? this.parsePercInc(value)
        : this.parseValueInc(value, this.step());
    const type = this.type();
    target.value =
      type === 'perc'
        ? value + '?'
        : type === 'value'
          ? value + this.unit()
          : value;
    this.debouncer.next({
      style: {
        [this.field()]: value,
      },
    });
  }

  /* FIXME: This does not work when step is a decimat, e.g. 0.1 => throws a console exception */
  parseValueInc = (value, step) =>
    parseFloat((isNaN(value) || value < 0 ? step : value + step).toFixed(2));

  parseValueDec = (value, step) =>
    parseFloat(
      (isNaN(value) || value < 0 ? step : Math.max(0, value - step)).toFixed(2),
    );

  parsePercInc = (value) => Math.min(100, value + 1);

  parsePercDec = (value) => Math.max(0, value - 1);

  valueChanged(event: FocusEvent, fieldName: string, preferedUnits = 'mm') {
    const target = event.target as HTMLInputElement;
    let value = parseFloat(target.value);
    value = parseFloat((isNaN(value) || value < 0 ? 0 : value).toFixed(2));
    target.value = value + preferedUnits;
    this.updateMenu.emit({
      style: {
        [fieldName]: value,
      },
    });
  }

  numberChanged(event: FocusEvent, fieldName: string) {
    const target = event.target as HTMLInputElement;
    let value = parseInt(target.value);
    value = Math.max(Math.min(100, value), 0);
    target.value = value.toString();
    this.updateMenu.emit({
      style: {
        [fieldName]: value,
      },
    });
  }

  percChanged(event: FocusEvent, fieldName: string) {
    const target = event.target as HTMLInputElement;
    let value = parseInt(target.value);
    value = Math.max(Math.min(100, value), 0);
    target.value = value + '%';
    this.updateMenu.emit({
      style: {
        [fieldName]: value,
      },
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
