import {
  ChangeDetectionStrategy,
  Component,
  output,
  contentChildren,
  input,
} from '@angular/core';
import { StyleCategories } from 'src/app/shared/Models/menu';

import { Updatable } from '../select-styles/styles-carousel/styles-carousel.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatTabsModule } from '@angular/material/tabs';

@Component({
  selector: 'categories-tabs',
  templateUrl: './categories-tabs.component.html',
  styleUrls: ['./categories-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTabsModule, TranslocoPipe],
})
export class CategoriesTabsComponent {
  readonly categories = input<StyleCategories>([]);
  readonly tabIndex = input<number>(undefined);
  readonly translationKey = input<string>(undefined);
  readonly tabChange = output<number>();

  readonly carousels = contentChildren(Updatable);
}
