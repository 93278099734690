import {
  Component,
  DestroyRef,
  HostBinding,
  HostListener,
  inject,
  input,
  OnChanges,
  OnInit,
  output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import {
  ColorPickerControl,
  Color,
  ColorPickerModule,
} from '@iplab/ngx-color-picker';
import { TranslocoPipe } from '@jsverse/transloco';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-color-picker',
  imports: [ColorPickerModule, MatButtonModule, TranslocoPipe],
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss',
})
export class ColorPickerComponent implements OnChanges, OnInit {
  readonly destroyRef = inject(DestroyRef);

  readonly className = input<string>(undefined);
  readonly color = input.required<string>();
  readonly disabled = input<boolean>(undefined);
  readonly isLocked = input<boolean>(undefined);
  readonly showClear = input<boolean>(undefined);

  readonly colorChange = output<string>();

  colorControl = new ColorPickerControl();
  private _color: Color;
  public isVisible = false;

  @HostBinding('style.background-color')
  public get background(): string {
    return this._color ? this._color.toHexString() : null;
  }

  @HostListener('click', ['$event'])
  public showColorPicker(_event: MouseEvent) {
    if (this.isVisible === true) return;
    this.isVisible = !this.isVisible;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const color = this.color();
    if ('color' in changes) {
      this._color = color ? Color.from(color) : undefined;
      if (color) {
        this.colorControl.setValueFrom(color);
      } else {
        this.colorControl.reset();
      }
    }
  }

  ngOnInit(): void {
    this.colorControl.hidePresets();
    this.colorControl.hideAlphaChannel();
    this.colorControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((color) => {
        if (this._color === undefined) return;
        this.colorChange.emit(color.toHexString());
      });
  }

  public applyClick(event: MouseEvent): void {
    event?.stopPropagation();
    this.isVisible = true;
  }

  public discardClick(event: MouseEvent): void {
    event?.stopPropagation();
    this.isVisible = false;
  }
}
