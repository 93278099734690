@if (categories() && categories().length) {
  <h3>
    {{ 'style.choose-' + translationKey() + '.title' | transloco }}
  </h3>
}
<mat-tab-group
  class="tabs mat-elevation-z1"
  [selectedIndex]="tabIndex()"
  (selectedIndexChange)="tabChange.emit($event)"
>
  @if (categories()?.includes('user')) {
    <mat-tab
      [label]="'style.choose-' + translationKey() + '.tabs.user' | transloco"
    >
      <ng-template matTabContent>
        <ng-content select=".tabUser"></ng-content>
      </ng-template>
    </mat-tab>
  }
  @if (categories()?.includes('public')) {
    <mat-tab
      [label]="'style.choose-' + translationKey() + '.tabs.public' | transloco"
    >
      <ng-template matTabContent>
        <ng-content select=".tabPublic"></ng-content>
      </ng-template>
    </mat-tab>
  }
  @if (categories()?.includes('special')) {
    <mat-tab
      [label]="'style.choose-' + translationKey() + '.tabs.special' | transloco"
    >
      <ng-template matTabContent>
        <ng-content select=".tabSpecial"></ng-content>
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>
