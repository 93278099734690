import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Separator } from 'src/app/shared/Models/separator';

@Component({
  selector: 'separator-item',
  template: `
    <div class="separator">
      {{ (separator() && separator()[lang()]) || '***' }}
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        margin: 4px 0;
      }
      .separator {
        display: flex;
        align-items: center;
        padding: 3px 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SeparatorItemComponent {
  readonly separator = input<Separator>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
}
