import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  Renderer2,
  inject,
  output,
} from '@angular/core';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[imageZoom]',
  standalone: true,
})
export class ImageZoomDirective implements AfterViewInit, OnDestroy {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private utils = inject(UtilsService);

  private destroyed$ = new Subject<void>();
  image: HTMLImageElement;
  source: string;
  zoomed: HTMLDivElement;

  readonly clicked = output<any>();

  @HostListener('window:resize') checkSize = () => this.imageLoad(true);

  ngAfterViewInit() {
    this.source = this.el.nativeElement.src;
    this.image = this.el.nativeElement;
    const imageLoadFn = () => this.imageLoad(false);
    this.image.onload = imageLoadFn;
    this.utils.navigationCollapsed
      .pipe(skip(1), takeUntil(this.destroyed$))
      .subscribe(() => setTimeout(() => this.imageLoad(), 200));
  }

  imageLoad = (immediate = false) => {
    setTimeout(
      () => {
        if (this.zoomed)
          __ngRendererDetachViewHelper(this.renderer, [this.zoomed]);
        this.zoomed = document.createElement('div');
        this.zoomed.className = 'ml-zoom-image';
        this.zoomed.style.width = `${this.image.width}px`;
        this.zoomed.style.height = `${this.image.height}px`;
        this.zoomed.style.maxWidth = '100%';
        this.zoomed.style.position = 'absolute';
        this.zoomed.style.top = '0px';
        this.zoomed.style.left = '0px';
        this.zoomed.style.backgroundImage = `url(${this.source})`;
        this.zoomed.style.backgroundSize = 'contain';
        this.zoomed.style.cursor = 'zoom-in';
        this.zoomed.addEventListener('mousemove', this.onmousemove);
        this.zoomed.addEventListener('mouseenter', this.onMouseEnter);
        this.zoomed.addEventListener('mouseleave', this.onMouseLeave);
        this.zoomed.addEventListener('click', (event) =>
          this.clicked.emit(event),
        );
        __ngRendererAttachViewAfterHelper(this.renderer, this.image, [
          this.zoomed,
        ]);
      },
      immediate ? 0 : 500,
    );
  };

  onMouseEnter = (event) =>
    this.renderer.setStyle(
      event.target,
      'backgroundSize',
      `${this.image.width * 3}px auto`,
    );

  onMouseLeave = (event) => {
    this.renderer.setStyle(event.target, 'backgroundSize', 'contain');
    this.renderer.setStyle(event.target, 'backgroundPosition', '0px 0px');
  };

  onmousemove = (event) => {
    const params = `-${event.offsetX * 2}px -${event.offsetY * 2}px`;
    this.renderer.setStyle(event.target, 'backgroundPosition', params);
  };

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererDetachViewHelper(
  renderer: AnyDuringRendererMigration,
  rootNodes: AnyDuringRendererMigration,
) {
  for (const value of rootNodes) {
    const node = value;
    renderer.removeChild(renderer.parentNode(node), node);
  }
}

function __ngRendererAttachViewAfterHelper(
  renderer: AnyDuringRendererMigration,
  node: AnyDuringRendererMigration,
  rootNodes: AnyDuringRendererMigration,
) {
  const parent = renderer.parentNode(node);
  const nextSibling = renderer.nextSibling(node);
  for (const value of rootNodes) {
    renderer.insertBefore(parent, value, nextSibling);
  }
}
