import { Component, input } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { TranslocoPipe } from '@jsverse/transloco';
import { StopPropagationDirective } from '../../../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatOptionModule,
    StopPropagationDirective,
    TranslocoPipe,
  ],
})
export class SelectFieldComponent {
  readonly field = input<string>(undefined);
  readonly data = input<any[]>(undefined);
  readonly dish = input<MenuDish>(undefined);
  readonly form = input<UntypedFormGroup>(undefined);
}
