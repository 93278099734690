import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { Supplier, SupplierItem } from 'src/app/shared/Models/procurements';
import * as SuppliersActions from './suppliers.actions';

export const adapter: EntityAdapter<SupplierItem> =
  createEntityAdapter<SupplierItem>();

interface SuppliersState {
  suppliersAutocomplete: Supplier[];
  supplierItems: EntityState<SupplierItem>;
}

const initialState: SuppliersState = {
  suppliersAutocomplete: [],
  supplierItems: adapter.getInitialState(),
};

const reducer = createReducer(
  initialState,
  on(SuppliersActions.setSuppliersAutocomplete, (state, { payload }) => {
    return {
      ...state,
      suppliersAutocomplete: payload.results,
    };
  }),
  on(SuppliersActions.addEmptySupplierItem, (state) => {
    return {
      ...state,
      supplierItems: adapter.addOne(
        new SupplierItem(null, true),
        state.supplierItems,
      ),
    };
  }),
  on(SuppliersActions.changeSupplierItem, (state, { id, supplierItem }) => {
    return {
      ...state,
      supplierItems: adapter.updateOne(
        { id: id, changes: supplierItem },
        state?.supplierItems,
      ),
    };
  }),
  on(SuppliersActions.removeDeletedSupplierItem, (state, { supplierItem }) => {
    return {
      ...state,
      supplierItems: adapter.removeOne(supplierItem?.id, state?.supplierItems),
    };
  }),
  on(SuppliersActions.setSupplierItems, (state, { data }) => {
    return {
      ...state,
      supplierItems: adapter.setAll(data, state.supplierItems),
    };
  }),
);

export const suppliersFeature = createFeature({
  name: 'suppliers',
  reducer,
  extraSelectors: ({ selectSupplierItems }) => ({
    selectSupplierItemsAll: createSelector(
      selectSupplierItems,
      adapter.getSelectors().selectAll,
    ),
  }),
});
