<h3 class="mat-h3">
  {{
    ('shared.dialogs.confirm-delete-dialog.title' | transloco) +
      ' ' +
      deleteMenuWarning()
  }}
</h3>
<div>
  {{ 'shared.dialogs.confirm-delete-dialog.content' | transloco }}
</div>

<div class="actions">
  <button mat-button class="cancel" (click)="cancelAction.emit()">
    {{ 'menus.overview.duplicate.dialog.actions.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    color="warn"
    class="warn"
    (click)="deleteSelectedMenu.emit()"
  >
    {{ 'shared.buttons.confirm' | transloco }}
  </button>
</div>
