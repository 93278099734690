import { AdditiveKey, AllergenKey, LabelKey } from '../constants/declarations';
import { Categories } from './../constants/categories';
import {
  ContentLanguage,
  interfaceLangs,
  langs,
} from './../constants/languages';
import { Additive, Allergen, Label } from './../Models/declarations';
import { DeepPartial } from './generics';
import { BaseDescriptionExtended, BaseNameExtended } from './models';
import { Recipe } from './recipe';
import { Separator } from './separator';

export const viewFilters = [
  {
    label: 'chef',
    icon: 'restaurant',
    headers: [
      'name_en',
      'reference',
      'declaration',
      'recipes',
      'info',
      'variants',
      'last_modified_at',
      'actions',
    ],
  },
  {
    label: 'diet',
    icon: 'spa',
    headers: [
      'name_en',
      'declaration',
      'recipes',
      'info',
      'diets',
      'last_modified_at',
      'actions',
    ],
  },
  {
    label: 'translator',
    icon: 'translate',
    headers: [
      ...interfaceLangs.map((lang) => `name_${lang}`),
      'times_shown',
      'last_modified_at',
      'actions',
    ],
  },
  {
    label: 'business',
    icon: 'account_balance',
    headers: [
      'name_en',
      'reference',
      'price',
      'product_cost',
      'info',
      'last_modified_at',
      'actions',
    ],
  },
] as const;

type LengthAsUnion<
  T extends readonly any[],
  S extends any[] = [],
> = S['length'] extends T['length']
  ? S[number]
  : LengthAsUnion<T, [...S, S['length']]>;

export type AiCategory = 'ai_dis';

export type DishCategory =
  | Categories.DISH
  | Categories.WINE
  | Categories.BEVERAGE;

export type SeparatorCategory =
  | Categories.DAY
  | Categories.SECTION
  | Categories.OPTION
  | Categories.COURSE;

export type ItemCategory = DishCategory | SeparatorCategory | AiCategory;

export type ViewFiltersIndex = LengthAsUnion<typeof viewFilters>;

export type ViewFilterHeaders = (typeof viewFilters)[number]['headers'];

export interface SpellcheckMatch {
  length: number;
  message: string;
  offset: number;
  replacements: string[];
  short_message: string;
}

export interface Spellcheck {
  matches: SpellcheckMatch[];
  original: string;
  original_lang: ContentLanguage;
}

export class UserDetails extends BaseDescriptionExtended {
  alcohol?: number;
  frozen_product?: null | 1 | 2;
  carbohydrate_units?: number;
  enhancement_declaration?: null | 1 | 2;
  margin?: number;
  origin?: string;
  price?: number;
  procurement_cost: number;
  produce_method?: string;
  recipes_allergens_discordant?: boolean;
  recipes_additives_discordant?: boolean;
  auto_translated?: string[];
  image?: string;
  image_small?: string;
  translation_disabled?: boolean;
  translation_disabled_description?: boolean;
  nutrition_rating?: number;
  price_vat_perc?: number;
  info_url?: string;
}

export class OnMenusInfo {
  date: string;
  id: number;
  name: string;
  preview: string;
  url: string;
}

export enum DISHES_VIEW_VILTERS {
  CHEF = 1,
  DIETICIAN = 2,
  TRANSLATOR = 3,
  BUSINESS = 4,
}

export class SimpleDish extends BaseNameExtended {
  url: string;
  id: number;
  category: DishCategory;
  vintage: number;
  winery: string;
  partner: number;
  article_number: string;
}

export class SimpleDishAllergens extends SimpleDish {
  allergens: Record<AllergenKey, boolean>;
  allergens_contained: Allergen[];
}

export class SimpleDishAdditives extends SimpleDish {
  additives: Record<AdditiveKey, boolean>;
  additives_contained: Additive[];
}

export class CondensedDish extends BaseNameExtended {
  url: string;
  id: number;
  category: DishCategory;
  vintage: null | number;
  winery: string;
  partner: null | number;
  article_number: string;
  last_on_menus: OnMenusInfo[];
  next_on_menus: OnMenusInfo[];
  ai_generated: boolean;
}

export class Dish extends SimpleDish {
  additives: Record<AdditiveKey, boolean>;
  allergens: Record<AllergenKey, boolean>;
  labels: Record<LabelKey, boolean>;
  menu?: number;
  created_at?: Date;
  last_modified_at?: Date;
  last_modified_by?: number;
  location?: number;
  original?: any;
  original_detail?: any;
  labels_contained: Label[];
  allergens_contained: Allergen[];
  additives_contained: Additive[];
  allergens_count?: number;
  additives_count?: number;
  user_details?: UserDetails;
  created_by?: number;
  variants?: Variant[];
  menus_list?: string;
  preview?: string;
  level?: number;
  last_on_menus?: OnMenusInfo[];
  next_on_menus?: OnMenusInfo[];
  number_on_menus?: number;
  spellcheck_ignore?: boolean;

  recipes?: Recipe[];
  recipes_list?: string;
  dish_recipes?: {
    ingredients: string[];
    id: number;
    de?: string;
    en?: string;
    fr?: string;
    es?: string;
    it?: string;
  }[];

  manually_created?: boolean;
  ai_generated?: boolean;
  dish_detail?: any;
  has_recipes?: boolean;
  has_diets?: boolean;
  tempId?: number;
  separator_detail?: Separator;
  removeLevel?: boolean;

  constructor(dish?: DeepPartial<Dish>, category?: DishCategory) {
    super();
    this.category = null;
    this.allergens_contained = [];
    this.additives_contained = [];
    this.dish_detail = {};

    if (dish) Object.assign(this, dish);
    if (category) this.category = category;
  }
}

export class Variant extends BaseNameExtended {
  id?: number;
  price: number;

  constructor(obj?: Partial<Variant>) {
    super();
    this.price = null;
    if (obj) Object.assign(this, obj);
  }
}
export class DishNutrition {
  nutrition?: any;
  missing_data?: any;
}

export class DishCost {
  cost: number;
  cost_missing: number;
  quantity_missing: number;
  unit_missing: number;
}

const ORDERING = [
  ...langs,
  ...langs.map((l) => `${l}_similar`),
  'created_at',
  'last_modified_at',
  '_number_on_menus',
  'article_number',
  'winery',
  'vintage',
  ...langs.map((l) => `-${l}`),
  ...langs.map((l) => `-${l}_similar`),
  '-created_at',
  '-last_modified_at',
  '-_number_on_menus',
  '-article_number',
  '-winery',
  '-vintage',
] as const;

const ORDERING_SEPARATOR = [
  ...langs,
  ...langs.map((l) => `${l}_similar`),
  'created_at',
  'last_modified_at',
  '_number_on_menus',
  ...langs.map((l) => `-${l}`),
  ...langs.map((l) => `-${l}_similar`),
  '-created_at',
  '-last_modified_at',
] as const;

export type DISH_ORDERING = (typeof ORDERING)[number];

export type SEPARATOR_ORDERING = (typeof ORDERING_SEPARATOR)[number];

export class DishParams extends BaseNameExtended {
  category: DishCategory;
  condensed: boolean;
  page_size: number;
  ordering: DISH_ORDERING;
  has_allergens: boolean;
  has_additives: boolean;
  menu: number;
  search: string;
  used_on_menu: boolean;
  user_dishes: boolean;
  has_recipes: boolean;
  has_diets: boolean;
  dietary_alternatives: boolean;
  injectable: boolean;
  exclude: number | number[];
  current_menu: number;
  edit: true;
  ingredients: true;
}

export class SeparatorParams extends BaseNameExtended {
  category: 'sec';
  condensed: boolean;
  ordering: SEPARATOR_ORDERING;
  search: string;
  exclude: number;
  current_menu: number;
  edit: true;
}
