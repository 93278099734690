import { Component, input } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'no-translations-screen',
  templateUrl: './no-translations-screen.component.html',
  styleUrls: ['./no-translations-screen.component.scss'],
  imports: [MatIconModule, TranslocoPipe],
})
export class NoTranslationsScreenComponent {
  readonly isOtherLang = input<boolean>(undefined);
  readonly selectedQuote = input<string>(undefined);
}
