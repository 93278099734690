import { createFeature, createReducer, on } from '@ngrx/store';
import {
  AIRecipe,
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { cloneDeep } from 'lodash-es';
import * as DishesMenuActions from './dishes-menu.actions';

interface DishesMenuState {
  aiAllergensLoading: boolean;
  aiDescriptionLoading: boolean;
  aiRecipes: AIRecipe[];
  aiRecipesLoading: boolean;
  ingredientInfo: RecipeIngredient;
  loading: boolean;
  recipes: Recipe[];
}

const initialState: DishesMenuState = {
  recipes: [],
  loading: false,
  ingredientInfo: null,
  aiRecipesLoading: false,
  aiRecipes: [],
  aiAllergensLoading: false,
  aiDescriptionLoading: false,
};

const reducer = createReducer(
  initialState,
  on(DishesMenuActions.setRecipes, (state, { recipes }) => {
    return {
      ...state,
      recipes: recipes,
    };
  }),
  on(DishesMenuActions.updateRecipesAfterRemoval, (state, { id }) => {
    return {
      ...state,
      recipes: state.recipes.filter((recipe: Recipe) => recipe.id !== id),
    };
  }),
  on(
    DishesMenuActions.replaceRecipeIngredients,
    (state, { updatedRecipe, ingredients }) => {
      return {
        ...state,
        recipes: state.recipes.map((recipe) => {
          if (recipe.id === updatedRecipe.id) {
            return {
              ...recipe,
              ingredients_list: ingredients,
            };
          }
          return recipe;
        }),
      };
    },
  ),
  on(DishesMenuActions.clearRecipes, (state) => {
    return {
      ...state,
      recipes: [],
    };
  }),
  on(
    DishesMenuActions.setIngredientToRecipe,
    (state, { recipe, ingredient }) => {
      return {
        ...state,
        recipes: state.recipes?.map((rec: Recipe) => {
          if (rec?.id === recipe.id) {
            const newRecipe = { ...rec };
            newRecipe.ingredients_list = [
              ...rec.ingredients_list,
              ingredient,
            ].filter((ingredient) => ingredient.id);
            return newRecipe;
          }
          return rec;
        }),
      };
    },
  ),
  on(DishesMenuActions.uncollapseRecipe, (state, { recipe }) => {
    return {
      ...state,
      recipes: state.recipes?.map((rec: Recipe) => {
        const newRecipe = { ...rec };
        if (newRecipe.id === recipe.id) {
          newRecipe.uncollapse = !newRecipe.uncollapse;
        } else {
          newRecipe.uncollapse = false;
        }
        return newRecipe;
      }),
    };
  }),
  on(
    DishesMenuActions.removeDeletedDishRecipeIngredient,
    (state, { recipe, ingredientId }) => {
      return {
        ...state,
        recipes: state.recipes?.map((rec: Recipe) => {
          if (rec?.id === recipe.id) {
            const newRecipe = { ...rec };
            newRecipe.ingredients_list = rec.ingredients_list.filter(
              (ing) => ing.id !== ingredientId,
            );
            return newRecipe;
          }
          return rec;
        }),
      };
    },
  ),
  on(
    DishesMenuActions.addIngredientToDishRecipe,
    (state, { recipe, ingredient }) => {
      return {
        ...state,
        recipes: state.recipes?.map((rec: Recipe) => {
          if (rec?.id === recipe.id) {
            const newRecipe = { ...rec };
            newRecipe.ingredients_list = [
              ...newRecipe.ingredients_list,
              ingredient,
            ] as SimpleRecipeIngredient[];
            return newRecipe;
          }
          return rec;
        }),
      };
    },
  ),
  on(
    DishesMenuActions.updateIngredientOfRecipe,
    (state, { recipe_id, recipe_ingredient }) => {
      return {
        ...state,
        recipes: state.recipes?.map((rec: Recipe) => {
          if (rec?.id === recipe_id) {
            const newRecipe = cloneDeep(rec);
            newRecipe.ingredients_list = newRecipe.ingredients_list.map(
              (item) => {
                return item.id === recipe_ingredient.id
                  ? new RecipeIngredient(recipe_ingredient)
                  : item;
              },
            );
            return newRecipe;
          }
          return rec;
        }),
      };
    },
  ),
  on(DishesMenuActions.removeEmptyIngredientFromDish, (state, { recipe }) => {
    return {
      ...state,
      recipes: state.recipes?.map((rec: Recipe) => {
        if (rec?.id === recipe.id) {
          const newRecipe = { ...rec };
          newRecipe.ingredients_list = newRecipe.ingredients_list.filter(
            (ingredient) => ingredient.id,
          );
          return newRecipe;
        }
        return rec;
      }),
    };
  }),
  on(DishesMenuActions.setNewRecipes, (state, { recipes }) => {
    const recipesCloned = cloneDeep(recipes);
    recipesCloned[recipesCloned.length - 1].uncollapse = true;
    return {
      ...state,
      recipes: recipesCloned,
    };
  }),
  on(DishesMenuActions.addRecipe, (state, { recipe }) => {
    const newRecipes = [
      ...state.recipes.map((r) => ({ ...r, uncollapse: false })),
      { ...recipe, uncollapse: true },
    ];
    return {
      ...state,
      recipes: newRecipes,
    };
  }),
  on(DishesMenuActions.setUpdatedRecipe, (state, { recipe }) => {
    const newRecipes = state.recipes.map((r) => {
      return r.id === recipe.id
        ? { ...recipe, uncollapse: true }
        : { ...r, uncollapse: false };
    });
    return {
      ...state,
      recipes: newRecipes,
    };
  }),
  on(DishesMenuActions.setLoading, (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  }),
  on(DishesMenuActions.setIngredientInfo, (state, { info }) => {
    return {
      ...state,
      ingredientInfo: info,
    };
  }),
  on(DishesMenuActions.setAiRecipes, (state, { recipes }) => {
    return {
      ...state,
      aiRecipes: recipes,
    };
  }),
  on(DishesMenuActions.setAiRecipesLoading, (state, { loading }) => {
    return {
      ...state,
      aiRecipesLoading: loading,
    };
  }),
  on(DishesMenuActions.setAiAllergensLoading, (state, { loading }) => {
    return {
      ...state,
      aiAllergensLoading: loading,
    };
  }),
  on(DishesMenuActions.setAiDescriptionLoading, (state, { loading }) => {
    return {
      ...state,
      aiDescriptionLoading: loading,
    };
  }),
);

export const dishesMenuFeature = createFeature({
  name: 'dishesMenu',
  reducer,
});
