@if (selectedMenu && currentMenu) {
  <h2>
    {{ 'dashboard.quick_overview.title' | transloco }}
  </h2>
}
@if (selectedMenu && currentMenu) {
  <div class="overview">
    <p class="current-month">{{ currentMonth }}</p>
    <div class="days">
      @for (item of currentMenus; track item) {
        <div
          class="day"
          (click)="selectDate(item)"
          [ngClass]="{
            'current shadow': item === currentMenu,
            empty: item.empty,
          }"
        >
          @if (item === currentMenu) {
            <p class="month">
              {{ getMonth(item.day) }}
            </p>
          }
          {{ getDate(item.day) }}
        </div>
      }
    </div>
    <div class="menus">
      @if (currentMenu) {
        <div>
          @for (menu of currentMenu.menus; track menu) {
            <quick-overview-item
              [ngClass]="{ select: menu === selectedMenu }"
              [menu]="menu"
              [lang]="menu.base_language"
              (click)="setCurrentMenu(menu)"
            >
            </quick-overview-item>
          }
          <div
            class="dishes"
            [class.two]="(dishes | async)?.length > 1"
            [class.many]="(dishes | async)?.length > 2"
          >
            @for (dish of dishes | async; track dish) {
              <div>
                @if ((dishesCurrentMenuLoading$ | async) === false) {
                  <quick-overview-dishes
                    [dish]="dish.dish_detail"
                  ></quick-overview-dishes>
                }
              </div>
            }
            @if (
              (dishesCurrentMenuLoading$ | async) === false &&
              (dishes | async)?.length < (dishesCurrentMenu$ | async)?.length
            ) {
              <div class="add-more" (click)="showMoreDishes()">
                {{ 'dashboard.quick_overview.more' | transloco }}
              </div>
            }
            @if (
              (dishesCurrentMenuLoading$ | async) === false && !currentMenu
            ) {
              <div class="menu_empty">
                {{ 'dashboard.quick_overview.not_today' | transloco }}
              </div>
            }
            @if (
              (dishesCurrentMenuLoading$ | async) === false &&
              selectedMenu &&
              !(dishes | async)?.length
            ) {
              <div class="menu_empty">
                {{ 'dashboard.quick_overview.empty' | transloco }}
              </div>
            }
          </div>
          @if (dishesCurrentMenuLoading$ | async) {
            <spinner></spinner>
          }
          @if (currentMenu) {
            <div class="options">
              <button
                mat-button
                color="primary"
                class="action-button show-preview-button"
                (click)="showPreview()"
              >
                {{ 'dashboard.quick_overview.preview' | transloco }}
              </button>
              <button
                mat-button
                class="action-button go-to-menu"
                color="primary"
                [routerLink]="['/menus', selectedMenu.id, 4]"
              >
                {{ 'dashboard.quick_overview.open_menu' | transloco }}
              </button>
            </div>
          }
        </div>
      }
    </div>
  </div>
}
@if (false && !(selectedMenu && currentMenu)) {
  <div class="nodata">
    {{ 'dashboard.quick_overview.not_data' | transloco }}
  </div>
}
