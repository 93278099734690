<div class="simple-theme">
  <h2 matDialogTitle>{{ data.title }}</h2>
  @if (loading) {
    <spinner></spinner>
  }
  @if (errorMessage && !loading) {
    <div class="spacer">
      <div>{{ errorMessage }}</div>
    </div>
  }
  @if (!loading && !errorMessage) {
    <div mat-dialog-content>
      @if (confirmed) {
        <div [innerHTML]="data.additionalContent | async"></div>
      }
      @if (!confirmed && isList) {
        <ul>
          @for (item of data.content; track item) {
            <li>{{ item }}</li>
          }
        </ul>
      }
      @if (!confirmed && !isList) {
        <div class="content" [innerHTML]="data.content"></div>
      }
    </div>
  }
  @if (!confirmed && !loading) {
    <div mat-dialog-actions class="buttons">
      <button mat-button (click)="close()">{{ data.cancelMessage }}</button>
      <button
        mat-button
        [color]="data.warning ? 'warn' : 'primary'"
        (click)="confirm()"
      >
        {{ data.confirmMessage }}
      </button>
    </div>
  }
</div>
