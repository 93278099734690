import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import {
  Component,
  inject,
  output,
  input,
  OnChanges,
  SimpleChanges,
  DestroyRef,
  effect,
  OnInit,
} from '@angular/core';
import {
  FileUploadControl,
  FileUploadValidators,
  FileUploadModule,
} from '@iplab/ngx-file-upload';
import { filter, tap } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { SpinnerComponent } from '../spinner/spinner.component';
import { FileUploadPlaceholderComponent } from '../file-upload-placeholder/file-upload-placeholder.component';
import { MtTooltipDirective } from '../../Directives/mt-tooltip/mt-tooltip.directive';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'show-and-upload-image',
  templateUrl: './show-and-upload-image.component.html',
  styleUrls: ['./show-and-upload-image.component.scss'],
  imports: [
    MtTooltipDirective,
    FileUploadModule,
    FileUploadPlaceholderComponent,
    SpinnerComponent,
    StopPropagationDirective,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class ShowAndUploadImageComponent implements OnChanges, OnInit {
  private destroyRef = inject(DestroyRef);
  private translate = inject(TranslocoService);

  readonly disabled = input<boolean>(undefined);
  readonly showSpinner = input<boolean>(false);
  readonly imageUrl = input<string>(undefined);

  constructor() {
    effect(() => {
      if (!this.showSpinner()) {
        this.fileUploadControl.clear();
      }
    });
  }
  readonly label = input<string>(undefined);
  readonly overwritten = input(false);
  readonly showTitle = input(true);
  readonly fileFormats = input<string[]>(['image/jpeg', 'image/png']);
  readonly fileFormatsString = input<string>('.JPG, .PNG');

  readonly fileChoosen = output<File>();
  readonly deleteImage = output<void>();

  _showSpinner: boolean;
  deleting = false;
  error: string;

  public fileUploadControl = new FileUploadControl(null, [
    FileUploadValidators.fileSize(100000000),
    FileUploadValidators.accept(['image/jpeg', 'image/png']),
  ]);

  ngOnInit(): void {
    this.fileUploadControl.valueChanges
      .pipe(
        filter((files) => !!files.length),
        tap(() => {
          this.error = undefined;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((files) => {
        if (this.fileUploadControl.valid) {
          this.fileChoosen.emit(files[0]);
        } else {
          this.onError();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const fileFormats = this.fileFormats();
    if (changes.fileFormats && fileFormats) {
      this.fileUploadControl.setValidators([
        FileUploadValidators.fileSize(100000000),
        FileUploadValidators.accept(fileFormats),
      ]);
    }
    if (changes.imageUrl) {
      this.deleting = false;
    }
  }

  delete(): void {
    this.deleting = true;
    this.deleteImage.emit();
    this.fileUploadControl.clear();
    this.fileUploadControl.enable();
  }

  onError(): void {
    const errorKey = Object.keys(this.fileUploadControl.getError()[0])[0];
    this.error = this.translate.translate(
      `shared.errors.${
        errorKey === 'fileSize' ? 'file-size' : 'unsupported-file'
      }`,
    );
    // this.fileUploadControl.clear();
  }
}
