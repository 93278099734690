@if (!hideLabel()) {
  <span class="expansion-panel-field" [class.narrow]="narrow()">{{
    'style.sidebar.numbers.' + field() | transloco
  }}</span>
}
<div class="field-wrapper">
  @if (icon()) {
    <mat-icon class="icon">{{ icon() }}</mat-icon>
  }
  <mat-form-field
    class="margin-form-field"
    subscriptSizing="dynamic"
    [class.narrow]="narrow()"
  >
    <input
      #inputField
      matInput
      type="text"
      [disabled]="
        !menu()?.style || disabledFields().includes(field()) || disabled()
      "
      [mtTooltip]="
        disabledFields().includes(field()) || disabled()
          ? ('style.sidebar.restrictions.layout-field-locked' | transloco)
          : ''
      "
      [ngModel]="
        showNull() || menu()?.style?.[field()]
          ? (menu()?.style)[field()] + unit()
          : null
      "
      [placeholder]="placeholder()"
      (focusout)="
        type() === 'value'
          ? valueChanged($event, field(), unit())
          : type() === 'perc'
            ? percChanged($event, field())
            : numberChanged($event, field())
      "
    />
  </mat-form-field>
  <div stop-propagation class="spin-buttons">
    <button
      [disabled]="disabledFields().includes(field()) || disabled()"
      class="spin-button-up"
      (click)="
        disabledFields().includes(field()) || disabled() || incValue(inputField)
      "
    >
      <mat-icon class="spin-icon-up">arrow_drop_up</mat-icon>
    </button>
    <button
      [disabled]="disabledFields().includes(field()) || disabled()"
      class="spin-button-down"
      (click)="
        disabledFields().includes(field()) || disabled() || decValue(inputField)
      "
    >
      <mat-icon class="spin-icon-down">arrow_drop_down</mat-icon>
    </button>
  </div>
  @if (showLock()) {
    <lock-item
      [field]="field()"
      [lockedFields]="lockedFields()"
      (updateMenu)="updateMenu.emit($event)"
    ></lock-item>
  }
</div>
