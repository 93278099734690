import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'side-info',
  templateUrl: './side-info.component.html',
  styleUrls: ['./side-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, MatIconModule, TranslocoPipe],
})
export class SideInfoComponent {
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly login = input(true);
}
