import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  SimpleChanges,
  output,
  input,
} from '@angular/core';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'lock-item',
  templateUrl: './lock-item.component.html',
  styleUrls: ['./lock-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class LockItemComponent implements OnChanges {
  readonly field = input<string | string[]>(undefined);
  readonly lockedFields = input<string[]>(undefined);
  readonly updateMenu = output<DeepPartial<Menu>>();

  isLocked = false;

  ngOnChanges(changes: SimpleChanges) {
    const fieldValue = this.field();
    const lockedFields = this.lockedFields();
    if (
      ('field' in changes || 'lockedFields' in changes) &&
      fieldValue &&
      lockedFields
    ) {
      if (Array.isArray(fieldValue)) {
        this.isLocked = fieldValue.every((field) =>
          this.lockedFields().includes(field),
        );
      } else {
        this.isLocked = lockedFields.includes(fieldValue);
      }
    }
  }

  lockChanged() {
    this.isLocked = !this.isLocked;
    let value = [...this.lockedFields()];
    const fieldValue = this.field();
    if (Array.isArray(fieldValue)) {
      if (this.isLocked) {
        value = [...new Set([...value, ...fieldValue])];
      } else {
        value = value.filter((field) => !this.field().includes(field));
      }
    } else {
      if (this.lockedFields().includes(fieldValue)) {
        value = value.filter((field) => field !== this.field());
      } else {
        value.push(fieldValue);
      }
    }
    this.updateMenu.emit({
      style: {
        locked_fields: value,
      },
    });
  }
}
