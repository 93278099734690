import { Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'return-side-panel-mobile',
  templateUrl: './return-side-panel-mobile.component.html',
  styleUrls: ['./return-side-panel-mobile.component.scss'],
  imports: [MatButtonModule, MatIconModule],
})
export class ReturnSidePanelMobileComponent {
  readonly text = input<string>(undefined);
}
