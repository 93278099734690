import { Component, output, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ClipboardModule } from 'ngx-clipboard';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'copy-data-line',
  templateUrl: './copy-data-line.component.html',
  styleUrls: ['./copy-data-line.component.scss'],
  imports: [MatButtonModule, ClipboardModule, MatIconModule],
})
export class CopyDataLineComponent {
  readonly upperData = input<string>(undefined);
  readonly lowerData = input<string>(undefined);

  readonly triggerCopy = output<string>();

  stopFocusOut(e) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
}
