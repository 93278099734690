import {
  Component,
  DestroyRef,
  ElementRef,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';
import { KeyValuePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ContentChange,
  QuillEditorComponent as QuillEditorComponent_1,
} from 'ngx-quill';
import Quill from 'quill';
import { Delta } from 'quill/core';
import type { Range } from 'quill/core/selection';
import Toolbar from 'quill/modules/toolbar';
import BlotFormatter from 'quill-blot-formatter-mobile';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';

import {
  allergenCodes,
  labelCodes,
} from 'src/app/shared/constants/declarations';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { InlineImage } from 'src/app/shared/Models/models';
import { FileService } from 'src/app/shared/Services/files/files.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';

import { SpinnerComponent } from '../spinner/spinner.component';
import { DividerBlot, AllergenBlot, ImageInline } from './blots';
import { HttpErrorResponse } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
  imports: [
    QuillEditorComponent_1,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    SpinnerComponent,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class QuillEditorComponent implements OnChanges, OnInit {
  private destoryRef = inject(DestroyRef);
  private element = inject(ElementRef);
  private fileService = inject(FileService);
  private renderer2 = inject(Renderer2);
  private utils = inject(UtilsService);

  readonly contentData = input<string>(undefined);
  readonly contentType = input<'menudetail' | 'recipestep' | 'recipediet'>(
    undefined,
  );
  readonly delay = input(3000);
  readonly disabled = input(false);
  readonly objectId = input<number>(undefined); // menu.id or step.id
  readonly lang = input<ContentLanguage>(undefined);
  readonly placeholder = input<string>(undefined);

  readonly contentChanged = output<string>();
  readonly contentChangedTap = output<void>();

  // only regular allergens (none with underscore)
  allergens = Object.keys(allergenCodes)
    .filter((a) => !a.includes('_'))
    .reduce((obj, key) => {
      obj[key] = allergenCodes[key];
      return obj;
    }, {});
  disableChangeDetection = false;
  unsavedContent = false;
  labels = labelCodes;
  highlight = false;
  isLoading = false;
  editor: Quill;
  editorCreated = false;
  editorPreviousContent = '';
  editorChanged: Subject<string> = new Subject<string>();
  selection: Range;
  rtlLangs = rtlLangs;
  rtl = false;

  public editorConfigDefault = {
    imageDropAndPaste: {
      handler: this.imageHandler.bind(this),
    },
    clipboard: {
      matchVisual: false,
    },
    blotFormatter: {},
    history: {
      delay: 1000,
      maxStack: 500,
      userOnly: true,
    },
    toolbar: {},
  };
  public updating = false;

  static replaceContent(content: string): string {
    return content.replace(
      /(<span class="allergen-symbol">)[^<]*<span[^>]*>(\w)<\/span>[^<]*(<\/span>)/g,
      (a, b, c, d) => `${b}${c}${d}`,
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const lang = this.lang();
    if (
      ('lang' in changes || 'contentData' in changes) &&
      lang &&
      this.editor
    ) {
      const contentData = this.contentData();
      if (
        this.editorCreated &&
        changes.contentData.previousValue !== contentData
      ) {
        this.setContent(contentData);
      }

      // set direction
      if ('lang' in changes) {
        if (this.rtlLangs.includes(lang) !== this.rtl) {
          this.editorConfigDefault['direction'] = this.rtlLangs.includes(lang)
            ? 'rtl'
            : 'ltr';
        }
      }
    }
    if ('disabled' in changes && this.editor) {
      this.editor.enable(!this.disabled());
    }
  }

  ngOnInit(): void {
    // setup Quill
    Quill.register(DividerBlot);
    Quill.register(AllergenBlot);
    Quill.register(ImageInline);
    Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
    Quill.register('modules/blotFormatter', BlotFormatter as any);

    // subscribe to editor changes
    this.initEditorSubscribers();
  }

  initDragListeners(): void {
    const elem =
      this.element.nativeElement.getElementsByClassName('ql-container')[0];
    this.renderer2.listen(elem, 'dragover', () => {
      this.highlight = true;
    });
    this.renderer2.listen(elem, 'dragend', () => {
      this.highlight = false;
    });
    this.renderer2.listen(elem, 'dragleave', () => {
      this.highlight = false;
    });
  }

  initEditorSubscribers(): void {
    this.editorChanged
      .pipe(
        map((data) => this.removeBom(data)),
        distinctUntilChanged(),
        tap(() => {
          if (this.disableChangeDetection) return;
          this.contentChangedTap.emit();
          this.updating = true;
        }),
        debounceTime(this.delay()),
        takeUntilDestroyed(this.destoryRef),
      )
      .subscribe((data) => {
        if (this.disableChangeDetection) {
          return;
        }
        this.selection = this.editor.getSelection();
        this.contentChanged.emit(data);
        this.updating = false;
        this.unsavedContent = false;
      });
  }

  imageHandler(
    imageDataUrl: string,
    type: string,
    imageData: typeof QuillImageDropAndPaste.ImageData,
  ): void {
    this.highlight = false;
    this.isLoading = true;
    this.saveToServer(imageData.toFile());
  }

  onEditorCreated(quill: Quill): void {
    // set editor
    this.editor = quill;

    // set allergen and label attributes
    this.addAllergenLabelAttributes();

    // add toolbar handlers
    const toolbar = this.editor.getModule('toolbar') as Toolbar;
    toolbar.addHandler('divider', this.handleDivider.bind(this));
    toolbar.addHandler('allergen', this.handleAllergen.bind(this));
    toolbar.addHandler('image', this.handleSelectLocalImage.bind(this));

    // undo-redo features
    const undoButton = this.element.nativeElement.querySelector('#quill-undo');
    const redoButton = this.element.nativeElement.querySelector('#quill-redo');
    this.renderer2.listen(undoButton, 'click', () => {
      this.editor.history.undo();
    });
    this.renderer2.listen(redoButton, 'click', () => {
      this.editor.history.redo();
    });

    // setup drag and drop
    this.initDragListeners();

    // set observables
    this.editor.on('text-change', () => {
      this.unsavedContent = true;
    });

    const contentData = this.contentData();
    this.setContent(contentData);

    this.editorCreated = true;
  }

  setContent(htmlContent: string): void {
    // set initial content
    if (htmlContent) {
      this.disableChangeDetection = true;
      this.editor.root.innerHTML = QuillEditorComponent.replaceContent('');
      this.editor.clipboard.dangerouslyPasteHTML(
        0,
        htmlContent,
        Quill.sources.API,
      );
      this.unsavedContent = false;
      this.editor.blur();

      setTimeout(() => {
        this.disableChangeDetection = false;
      }, this.delay());
    }
  }

  onSave(): void {
    const data =
      this.editor.root.innerText === '\n' ? '' : this.editor.root.innerHTML;
    this.contentChanged.emit(data);
    this.unsavedContent = false;
  }

  addAllergenLabelAttributes(): void {
    // add class to allergen/label options to render the symbol font
    Array.from(
      document.querySelectorAll(
        '.ql-allergen .ql-picker-options .ql-picker-item',
      ),
    ).forEach((el) => {
      el.classList.add('allergen-symbol');
    });

    // set title attribute for allergens
    document
      .querySelectorAll('span.ql-picker-item.allergen-symbol')
      .forEach((elem) => {
        const value = elem.getAttribute('data-value');
        const optionElement = document.querySelector(
          `select.ql-allergen option.allergen-symbol[value="${value}"]`,
        );
        if (optionElement)
          elem.setAttribute('title', optionElement.getAttribute('title'));
      });
  }

  onContentChanged({ html }: ContentChange): void {
    if (html === null) html = '';
    this.editorChanged.next(html);
  }

  handleAllergen(value: string): void {
    if (!value) return;
    const range = this.editor.getSelection(true);
    this.editor.insertText(
      range.index,
      value,
      { allergen: true },
      Quill.sources.USER,
    );
    // Update the cursor position
    this.editor.setSelection(range.index + 1, Quill.sources.SILENT);
  }

  handleDivider(): void {
    const range = this.editor.getSelection(true);
    this.editor.insertText(range.index, '\n', Quill.sources.USER);
    this.editor.insertEmbed(
      range.index + 1,
      'divider',
      true,
      Quill.sources.USER,
    );
    // Update the cursor position
    this.editor.setSelection(range.index + 2, Quill.sources.SILENT);
  }

  handleSelectLocalImage(): void {
    const idx = this.editor.getSelection().index;

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    this.editor.setSelection(idx + 1, Quill.sources.SILENT);

    // Listen upload local image and save to server
    input.onchange = () => {
      const file = input.files[0];
      // file type is only image.
      if (file.type.startsWith('image/')) {
        this.saveToServer(file, idx);
        this.editor.setSelection(idx + 1, Quill.sources.SILENT);
      } else {
        this.utils.showSnackbarMessage('shared.errors.unsupported-file', true);
      }
    };
  }

  handleImageUploadSuccess = (res: InlineImage, idx?: number): void => {
    this.isLoading = false;
    let index = idx ?? this.editor.getSelection()?.index;
    if (index === undefined || index < 0) index = this.editor.getLength();

    // replace the last image with the new one
    this.editor.updateContents(
      new Delta()
        .retain(index - 1)
        .delete(1)
        .insert({ image: res.image }),
    );
  };

  removeBom = (str: string): string =>
    str.replace(new RegExp(String.fromCharCode(65279), 'g'), '');

  saveToServer(file: File, idx?: number): void {
    // create form data
    const formData = new FormData();
    formData.append('image', file);
    formData.append('content_type', this.contentType());
    formData.append('object_id', this.objectId().toString());

    // upload image
    this.fileService.uploadPicture(formData).subscribe({
      next: (res) => this.handleImageUploadSuccess(res, idx),
      error: (err: unknown) => {
        this.isLoading = false;
        const errorMessage =
          err instanceof HttpErrorResponse ? err.error?.image?.[0] : undefined;
        this.utils.showSnackbarMessage(
          errorMessage ?? 'shared.errors.upload-image',
          !errorMessage,
        );
      },
    });
  }
}
