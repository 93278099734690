<h3 class="mat-h3">
  {{ 'menus.overview.duplicate.dialog.title' | transloco }}
</h3>
<div class="duplicate-options">
  @if (showOrdertaking() && hasOrderModule) {
    <mat-checkbox
      color="primary"
      class="duplicate-option-item"
      [(ngModel)]="options.enable_ordertaking"
    >
      {{
        'menus.overview.duplicate.dialog.options.enable_ordertaking' | transloco
      }}
    </mat-checkbox>
  }
  <mat-checkbox
    color="primary"
    class="duplicate-option-item"
    [(ngModel)]="options.create_archive"
  >
    {{ 'menus.overview.duplicate.dialog.options.create_archive' | transloco }}
  </mat-checkbox>
</div>

<div class="actions">
  <button mat-button class="cancel" (click)="cancelAction.emit()">
    {{ 'menus.overview.duplicate.dialog.actions.cancel' | transloco }}
  </button>
  <button
    mat-flat-button
    color="primary"
    class="done"
    (click)="createArchive.emit(options)"
  >
    {{ 'menus.overview.duplicate.dialog.actions.confirm' | transloco }}
  </button>
</div>
