import { Component, OnChanges, SimpleChanges, input } from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { UserOnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'user-onboarding-template',
  templateUrl: './user-onboarding-template.component.html',
  styleUrls: ['./user-onboarding-template.component.scss'],
  imports: [MatTooltipModule, MatIconModule],
})
export class UserOnboardingTemplateComponent implements OnChanges {
  readonly disabled = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly template = input<UserOnboardingTemplate>(undefined);
  readonly tooltip = input<string>(undefined);
  tooltipMessage = '';

  ngOnChanges(changes: SimpleChanges) {
    if ('tooltip' in changes) this.tooltipMessage = this.tooltip();
  }
}
