<mat-card class="card">
  <img
    mat-card-image
    class="image-card"
    imageZoom
    [src]="
      (data() && data().image && (data().image | safe)) ||
      ('/assets/img/Empty menu.jpg' | safe)
    "
    (clicked)="disabled() ? showError.emit() : chooseTemplate()"
    [alt]="data()[lang]"
  />
  <mat-card-header class="card-header">
    <mat-card-title class="title"
      >{{ data() && data()[lang] }}
      @if (editPermissionField() && data()?.[editPermissionField()]) {
        <span class="action-icons">
          <button
            mat-icon-button
            class="action-button"
            (click)="editAction($event)"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            class="action-button"
            [matMenuTriggerFor]="deleteMenu"
            (click)="preventDefault($event)"
          >
            <mat-icon>delete</mat-icon>
          </button>
          <mat-menu #deleteMenu class="delete-style-menu">
            <span class="delete-style-menu-label" mat-menu-item>
              {{ 'style.choose-template.are-you-sure' | transloco }}</span
            >
            <button
              mat-menu-item
              class="delete-style-menu-button"
              color="warn"
              (click)="deleteTemplate.emit(data().id)"
            >
              <mat-icon>delete</mat-icon>
              {{ 'style.choose-template.delete' | transloco }}
            </button>
          </mat-menu>
        </span>
      }
    </mat-card-title>
  </mat-card-header>
  <mat-card-content
    ><p></p>
    <mat-divider></mat-divider
  ></mat-card-content>
  <mat-card-actions class="actions">
    @if (!change() && data().data_import?.length) {
      <button
        [matMenuTriggerFor]="menu"
        mat-button
        color="primary"
        class="action"
      >
        {{ 'templates.card.write' | transloco }}...
      </button>
      <mat-menu #menu>
        <button
          class="create-menu-item"
          mat-menu-item
          (click)="disabled() ? showError.emit() : chooseTemplate()"
        >
          <span class="item-button-text">{{
            'templates.card.write' | transloco
          }}</span>
          <div class="analysis-button custom">
            <mat-icon class="arrow-icon">create</mat-icon>
          </div>
        </button>
        <mat-divider></mat-divider>
        @for (item of data().data_import; track item; let i = $index) {
          <div
            mat-menu-item
            class="create-menu-item upload-menu"
            (click)="preventDefault($event)"
          >
            <file-upload class="file-upload" [control]="fileUploadControls[i]">
              <ng-template #placeholder>
                <span class="item-button-text">{{
                  'templates.upload.providers.' + item | transloco
                }}</span>
                <div class="analysis-button custom">
                  @if (importLoading) {
                    <spinner
                      class="express-filter-spinner"
                      [config]="{ radius: 7 }"
                    ></spinner>
                  } @else {
                    <mat-icon class="arrow-icon"> arrow_forward </mat-icon>
                  }
                </div>
              </ng-template>
            </file-upload>
          </div>
          <div mat-menu-item class="info-item">
            <span class="info-item-text" (click)="downloadTemplate(item)"
              >Click <u>here</u> to download the .xlsx import template for this
              menu template.</span
            >
          </div>
        }
      </mat-menu>
    } @else {
      <button
        mat-button
        color="primary"
        class="action"
        (click)="disabled() ? showError.emit() : chooseTemplate()"
      >
        {{
          change()
            ? ('templates.card.choose' | transloco)
            : ('templates.card.write' | transloco)
        }}
      </button>
    }
  </mat-card-actions>
</mat-card>

@if (latest()) {
  <input
    class="hide"
    type="hidden"
    [disabled]="false"
    [matDatepicker]="picker"
    (dateChange)="triggerTemplate($event)"
  />
  <mat-datepicker touchUi="true" #picker></mat-datepicker>
}
