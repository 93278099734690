<div class="menu-analysis-item">
  <div class="menu-analysis-item-title">
    <span class="ai-credits">
      1<mat-icon class="ai-credits-icon">auto_awesome</mat-icon>
    </span>
    <span class="description">
      {{ 'write.ai.analysis-types.' + analysisType() + '.title' | transloco }}
      <mat-icon
        [mtTooltip]="
          'write.ai.analysis-types.' + analysisType() + '.tooltip' | transloco
        "
        class="info-tooltip"
        >info</mat-icon
      >
    </span>
  </div>
  <div class="menu-analysis-item-action button-density-3">
    <button
      mat-flat-button
      color="primary"
      class="analysis-button"
      [disabled]="aiAnalysisLoading() || disabled()"
      (click)="runAnalysis.emit()"
    >
      @if (aiAnalysisLoading()) {
        <spinner
          class="express-filter-spinner"
          [config]="{ radius: 7 }"
        ></spinner>
      } @else {
        <mat-icon class="arrow-icon">arrow_forward</mat-icon>
      }
    </button>
  </div>
</div>
