import { Component, EventEmitter, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';

import { TwoColumnsModalConfig } from './two-columns-dialog.model';
import { AsyncPipe } from '@angular/common';
import { StopPropagationDirective } from '../../../Directives/stop-propagation/stop-propagation.directive';
import { SameDishesComponent } from './similar-dishes/similar-dishes.component';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-two-columns-dialog',
  templateUrl: './two-columns-dialog.component.html',
  styleUrls: ['./two-columns-dialog.component.scss'],
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    SameDishesComponent,
    StopPropagationDirective,
    TranslocoPipe,
  ],
})
export class TwoColumnsDialogComponent {
  data = inject<TwoColumnsModalConfig>(MAT_DIALOG_DATA);

  public loadMoreDishes = new EventEmitter<string>();
}
