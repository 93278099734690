<form [formGroup]="itemTabForm">
  <p class="dish-information-description">
    {{
      dish()?.dish_detail
        ? ('write.blocks.dish-overview.information.title.dish' | transloco)
        : ('write.blocks.dish-overview.information.title.section' | transloco)
    }}
  </p>
  <div class="price-wrapper" [class.hidden]="dish().separator || showVariants">
    <div class="simple-price">
      @if (dish().user_details?.price || dish().variants?.length) {
        <span
          [mtTooltip]="
            'write.blocks.dish-overview.information.overwritten' | transloco
          "
          class="overwritten-info"
        ></span>
      }
      <mat-form-field class="price-input bar-wrapper">
        <span matPrefix>{{ currencySymbol() }}&nbsp;</span>
        <mat-label>{{
          'write.blocks.dish-overview.information.prices.variant-price.price.placeholder'
            | transloco
        }}</mat-label>
        <input
          matInput
          autocomplete="off"
          class="price"
          formControlName="price"
          name="price"
          max="999999"
          maxlength="6"
          min="0"
          pattern="[0-9]*(\.|,){0,1}[0-9]+"
          (keydown.enter)="blurInput($event.target)"
        />
        @if (loadingBars.price) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
      </mat-form-field>
      @if (!(dish().separator || showVariants)) {
        <button
          mat-button
          class="add-variant-button blue"
          (click)="addVariant()"
        >
          <mat-icon class="plus-icon">add_circle</mat-icon>
          {{
            'write.blocks.dish-overview.information.prices.actions.add-variant'
              | transloco
          }}
        </button>
      }
    </div>
  </div>
  @if (dish().separator || showVariants) {
    <app-variants
      [currencySymbol]="currencySymbol()"
      [initialValue]="initialVariantValue"
      [item]="dish()?.separator_detail ?? dish()?.dish_detail"
      [lang]="lang()"
      [overwritten]="!!dish().user_details?.price || !!dish().variants?.length"
      [responseItem]="dish()"
      (changed)="variantsChanged($event)"
      (cleared)="variantsCleared($event)"
    ></app-variants>
  }
  <div class="field-wrapper">
    @if (dish().user_details?.['description_' + lang()]) {
      <span
        [mtTooltip]="
          'write.blocks.dish-overview.information.overwritten' | transloco
        "
        class="overwritten-info"
      ></span>
    }
    <mat-form-field class="bar-wrapper" [attr.dir]="rtl ? 'rtl' : 'ltr'">
      <mat-label>{{
        translations()[
          'write.blocks.dish-overview.other-tab.information.description'
        ] ||
          ('write.blocks.dish-overview.other-tab.information.description'
            | transloco)
      }}</mat-label>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMinRows="2"
        maxlength="2000"
        type="text"
        class="popover-textarea"
        [name]="'description'"
        [formControlName]="'description'"
      >
      </textarea>
      @if (loadingBars.description) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </mat-form-field>
  </div>
  @if (
    !dish()?.dish_detail?.user_details?.['description_' + lang()] &&
    dish()?.dish_detail?.category === 'dis'
  ) {
    <magic-stick
      class="menu-action"
      [ai]="true"
      [aiCredits]="1"
      [aiCreditsRemaining]="aiCreditsRemaining()?.dishes"
      [loading]="aiDescriptionLoading()"
      [title]="
        'write.blocks.dish-overview.information.generate-description.title'
          | transloco
      "
      [description]="
        'write.blocks.dish-overview.information.generate-description.description'
          | transloco
      "
      [buttonMsg]="
        'write.blocks.dish-overview.information.generate-description.button'
          | transloco
      "
      [buttonLoadingMsg]="
        'write.blocks.dish-overview.information.generate-description.loading'
          | transloco
      "
      (clicked)="
        isTrial() && !profileComplete()
          ? showTrialBanner()
          : generateAiDescription.emit()
      "
    ></magic-stick>
  }
  @if (
    dish()?.separator_detail &&
    ['dow', 'sec'].includes(dish()?.separator_detail?.category)
  ) {
    <mat-form-field class="bar-wrapper" [attr.dir]="rtl ? 'rtl' : 'ltr'">
      <mat-label>{{
        translations()[
          'write.blocks.dish-overview.information.description_secondary'
        ] ||
          ('write.blocks.dish-overview.information.description_secondary'
            | transloco)
      }}</mat-label>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMinRows="2"
        maxlength="2000"
        type="text"
        class="popover-textarea"
        [name]="'description_secondary'"
        [formControlName]="'description_secondary'"
      >
      </textarea>
      @if (loadingBars.description_secondary) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </mat-form-field>
  }
  @if (dish()?.dish_detail) {
    <declarations
      [item]="dish().dish_detail"
      [hasRecipes]="dish().has_recipes"
      [lang]="interfaceLang()"
      [contentLang]="lang()"
      [recipes]="recipes()"
      (fetchRecipes)="fetchDishRecipes.emit(dish().dish_detail.recipes_list)"
      (showModal)="modalOpened.emit($event)"
      (synchroniseRecipeDeclarations)="synchroniseDeclarations($event)"
      (changeOption)="changeOption.emit($event)"
      (addIngredientEvent)="addIngredientEvent.emit($event)"
      (deleteIngredientEvent)="deleteIngredientEvent.emit($event)"
      (searchIngredients)="searchIngredients.emit($event)"
      (selectedIngredientEvent)="selectedIngredientEvent.emit($event)"
      (createIngredientEvent)="createIngredientEvent.emit($event)"
      (updateRecipeIngredientEvent)="updateRecipeIngredientEvent.emit($event)"
      (updateIngredientEvent)="updateIngredientEvent.emit($event)"
    ></declarations>
  }
  @if (dish()?.dish_detail?.category === 'dis' && hasModule('all')) {
    <magic-stick
      class="menu-action"
      [ai]="true"
      [aiCredits]="1"
      [aiCreditsRemaining]="aiCreditsRemaining()?.allergens"
      [loading]="aiAllergensLoading()"
      [title]="
        'write.blocks.dish-overview.information.generate-allergens.title'
          | transloco
      "
      [description]="
        'write.blocks.dish-overview.information.generate-allergens.description'
          | transloco
      "
      [buttonMsg]="
        'write.blocks.dish-overview.information.generate-allergens.button'
          | transloco
      "
      [buttonLoadingMsg]="
        'write.blocks.dish-overview.information.generate-allergens.loading'
          | transloco
      "
      (clicked)="
        isTrial() && !profileComplete()
          ? showTrialBanner()
          : generateAiAllergens.emit()
      "
    ></magic-stick>
  }
  @if (dish()?.dish_detail?.category === 'dis') {
    <frozen-product
      [dish]="dish()"
      (changeField)="changeField.emit($event)"
      [translations]="translations()"
    >
    </frozen-product>
  }
  <mat-form-field class="bar-wrapper" [attr.dir]="rtl ? 'rtl' : 'ltr'">
    @if (translations()) {
      <mat-label>{{
        translations()['write.blocks.dish-overview.information.carbs-units'] ||
          ('write.blocks.dish-overview.information.carbs-units' | transloco)
      }}</mat-label>
    }
    <input
      matInput
      type="number"
      name="carbohydrate_units"
      formControlName="carbohydrate_units"
    />
    @if (loadingBars['carbohydrate_units']) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>
  @if (['bev', 'win'].includes(dish()?.dish_detail?.category)) {
    <mat-form-field class="bar-wrapper" [attr.dir]="rtl ? 'rtl' : 'ltr'">
      @if (translations()) {
        <mat-label>{{
          translations()['write.blocks.dish-overview.information.alcohol'] ||
            ('write.blocks.dish-overview.information.alcohol' | transloco)
        }}</mat-label>
      }
      <input
        matInput
        type="number"
        lang="en-150"
        min="0"
        max="100"
        name="alcohol"
        formControlName="alcohol"
      />
      @if (loadingBars['alcohol']) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
    </mat-form-field>
  }
  @if (dish()?.dish_detail?.category === 'win') {
    <wine-information
      [attr.dir]="rtl ? 'rtl' : 'ltr'"
      [translations]="translations()"
      [dish]="dish()"
      [lang]="lang()"
      (changeDish)="changeField.emit($event)"
    ></wine-information>
  }
  @if (dish()?.dish_detail) {
    <origin-field
      [attr.dir]="rtl ? 'rtl' : 'ltr'"
      [formGroup]="itemTabForm"
      [loadingBars]="loadingBars"
      [translations]="translations()"
    ></origin-field>
  }
  @if (dish()?.dish_detail) {
    <show-and-upload-image
      class="image-field"
      [imageUrl]="
        (dish()?.dish_detail || dish()?.separator_detail || dish())
          ?.user_details?.image_small
      "
      [label]="
        translations()['write.blocks.dish-overview.information.image'] ||
        ('write.blocks.dish-overview.information.image' | transloco)
      "
      [overwritten]="!!dish().user_details?.background"
      [showSpinner]="showImageSpinner"
      (fileChoosen)="uploadImage($event)"
      (deleteImage)="patchFields({ user_details: { image: null } })"
    ></show-and-upload-image>
  }
  @if (dish()?.separator_detail) {
    <div class="bg-images">
      <!-- Temporary deprecated background -->
      @if (dish()?.separator_detail?.user_details?.image) {
        <p>
          {{
            'write.blocks.dish-overview.uploads.image.deprecated-field'
              | transloco
          }}:
        </p>
      }
      @if (dish()?.separator_detail?.user_details?.image) {
        <show-and-upload-image
          [label]="
            'write.blocks.dish-overview.uploads.image.deprecated-field'
              | transloco
          "
          [imageUrl]="dish()?.separator_detail?.user_details.image_small"
          (deleteImage)="
            patchFields({
              user_details: { background: null },
            })
          "
        ></show-and-upload-image>
      }
      @if (dish()?.separator_detail?.user_details?.image) {
        <p></p>
      }
      @if (backgroundImages()?.length) {
        <p>
          {{ 'write.blocks.dish-overview.uploads.image.choose' | transloco }}:
        </p>
      }
      <div class="bg-images-wrapper">
        @if (dish()?.separator_detail?.user_details?.background) {
          <select-image
            [imageUrl]="
              dish()?.separator_detail?.user_details?.background_detail
                ?.background_image_small
            "
            [selected]="true"
            (imgSelected)="
              patchFields({
                user_details: { background: null, image: null },
              })
            "
          ></select-image>
        }
        @for (bgImage of backgroundImages(); track bgImage; let last = $last) {
          @if (
            bgImage.id !== dish()?.separator_detail?.user_details?.background &&
            !(hideLastImage && last)
          ) {
            <select-image
              [imageUrl]="bgImage.background_image_small"
              (imgSelected)="selectImage(bgImage.id)"
            ></select-image>
          }
        }
      </div>
      @if (backgroundImages()?.length) {
        <button
          class="show-library"
          mat-button
          color="primary"
          (click)="showAllBackgrounds.emit()"
        >
          {{ 'shared.buttons.see-all' | transloco }}
        </button>
      }
    </div>
    <show-and-upload-image
      [label]="'write.blocks.dish-overview.uploads.image.add' | transloco"
      [showSpinner]="showImageSpinner"
      (fileChoosen)="uploadBackground($event)"
      (deleteImage)="patchFields({ user_details: { background: null } })"
    ></show-and-upload-image>
  }
  @if (
    dish()?.dish_detail && hasModuleSetting('man', 'article_numbers', true)
  ) {
    <article-number
      [attr.dir]="rtl ? 'rtl' : 'ltr'"
      class="article-number"
      [translations]="translations()"
      [dish]="dish()"
      [lang]="lang()"
      (changeDish)="changeField.emit($event)"
    ></article-number>
  }
  <mat-form-field class="bar-wrapper" [attr.dir]="rtl ? 'rtl' : 'ltr'">
    @if (translations()) {
      <mat-label>{{
        translations()['write.blocks.dish-overview.information.info-url'] ||
          ('write.blocks.dish-overview.information.info-url' | transloco)
      }}</mat-label>
    }
    <input
      matInput
      name="info_url"
      formControlName="info_url"
      placeholder="https://..."
    />
    @if (loadingBars['info_url']) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>
  @if (dish()?.dish_detail && hasModule('man') && hasModule('recip')) {
    <fiche-technique [dish]="dish()?.dish_detail" [lang]="lang()">
    </fiche-technique>
  }
  @if (dish()?.dish_detail && showRating()) {
    <nutrition-rating [dish]="dish()" (updateDish)="changeField.emit($event)">
    </nutrition-rating>
  }
</form>
