@if (!user()?.status?.trial_period) {
  <div class="line line-subscription">
    <span class="label"
      >{{ 'settings.billing.plan-dialog.subscription' | transloco }}:</span
    >
    <div class="content">
      <span class="bold">
        @if (user()?.status?.subscription_active) {
          {{ status().subscription_name }}
        } @else {
          {{ 'plan_statistics.no-subscription' | transloco }}
        }
      </span>
      @if (
        status()?.next_reset_date &&
        !user()?.status?.trial_period &&
        user()?.status?.subscription_active &&
        (user()?.status?.usage_limits?.limit_menus_per_month ||
          user()?.status?.usage_limits?.limit_templates_per_month)
      ) {
        <div>
          {{
            'plans.user_plan.reset_text'
              | transloco
                : {
                    date:
                      status().next_reset_date
                      | date: 'mediumDate' : '' : lang(),
                  }
          }}.
        </div>
      }
    </div>
  </div>
}

<user-status-progress
  class="status-progress"
  [extra]="extensions()"
  [lang]="lang()"
  [status]="status()"
></user-status-progress>

@if (status()?.trial_period) {
  @if (status()?.trial_active) {
    <hr />
  }
  @if (status()?.trial_active) {
    <p
      class="text"
      [innerHTML]="
        ('plan_statistics.limit-text1' | transloco) +
        '<br />' +
        ('plan_statistics.limit-text2'
          | transloco
            : {
                onInactive:
                  status().inactive_on | date: 'mediumDate' : '' : lang(),
              }) +
        '.'
      "
    ></p>
  } @else {
    {{ 'user.expired_trial.recent' | transloco }}:
  }
}

@if (
  !(
    user().status.usage_limits.limit_menus_per_month === null &&
    user().status.usage_limits.limit_templates_per_month === null
  )
) {
  <div
    class="actions"
    [mtTooltip]="
      !status()?.email_verified
        ? ('dashboard.status_block.verify_email' | transloco)
        : ''
    "
  >
    <button
      mat-flat-button
      class="button"
      color="primary"
      [disabled]="!status()?.email_verified"
      [queryParams]="{ change: true }"
      [routerLink]="['/settings', 'billing']"
    >
      <mat-icon class="arrow">arrow_right</mat-icon>
      @if (status()?.trial_period || !status()?.subscription_active) {
        {{ 'plan_statistics.actions.choose_subscription' | transloco }}
      } @else {
        {{ 'plan_statistics.actions.upgrade' | transloco }}
      }
    </button>
  </div>
}
