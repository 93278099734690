import { ContentLanguage, langs } from '../constants/languages';
import { Additive, Allergen, Label } from './declarations';
import { BaseNameExtended } from './models';

export class Ingredients {
  count: number;
  next: string;
  previous: string;
  results: Ingredient[];
}

export interface PreIngredients {
  ingredients: Record<string, string>[];
}

export class SimpleIngredient extends BaseNameExtended {
  id: number;
  url: string;
}

const ORDERING = [
  ...langs,
  'category',
  'last_modified_at',
  ...langs.map((l) => `-${l}`),
  '-category',
  '-last_modified_at',
] as const;

export type INGREDIENTS_ORDERING = (typeof ORDERING)[number];

export interface IngredientsParams {
  ordering: INGREDIENTS_ORDERING;
  page_size: number;
  search: string;
  current_location: number;
  user_ingredients: boolean;
  recipe: number;
  current_recipe: number;
  current_menu: number;
  condensed: boolean;
  exclude: number;
}

export const INGREDIENT_REPORTS = ['declarations', 'nutrition'];

export type IngredientReport = (typeof INGREDIENT_REPORTS)[number];

export interface IngredientReportParams {
  lang: ContentLanguage;
  last_modified_at: string;
  last_modified_at_from: string;
  last_modified_at_to: string;
  response: 'html' | 'json' | 'pdf';
  search: string;
  report: IngredientReport;
  has_recipes: boolean;
  ordering: INGREDIENTS_ORDERING;
  location: number;
  current_location: number;
  current_menu: number;
  current_recipe: number;
  user_ingredients: boolean;
}

export class Ingredient extends SimpleIngredient {
  category_detail: any;
  original_detail: any;
  allergens: Record<string, boolean>;
  additives: Record<string, boolean>;
  labels: Record<string, boolean>;
  allergens_contained: Allergen[];
  additives_contained: Additive[];
  labels_contained: Label[];
  user_details: any; // FIXME: add ingredient detail class
  dishes_list: string;
  created_at: string;
  last_modified_at: string;
  is_composite: boolean;
  created_by: number;
  last_modified_by: number;
  category: number;
  number_on_recipes: number;
  recipes_list?: string;
  ingredient_detail?: any;
  tempId?: number;
  ingId?: number;
  cost_vat?: number | string;
  portion_cost?: number;
  nutrition?: any;
  refetchRecipe?: any;
  recipe?: number;
  article_number?: string;

  constructor() {
    super();
    this.ingredient_detail = {};
    this.user_details = {};
  }
}

export class IngredientCategories {
  count: number;
  next: string;
  previous: string;
  results: IngredientCategory[];
}

export class IngredientCategory extends BaseNameExtended {
  id: number;
  url: string;
  created_at: string;
  last_modified_at: string;
  colour: string;
  colour_r: number;
  colour_g: number;
  colour_b: number;
  created_by: number;
  last_modified_by: number;
}

export class InventorySupplier {
  id: number;
  url: string;
  created_at: string;
  last_modified_at: string;
  migrated: boolean;
  name: string;
  organisation: number;
  created_by: number;
  location: number;
  last_modified_by: number;
}
