<mat-menu
  #restoreMenu="matMenu"
  class="save-restore-menu"
  [overlapTrigger]="false"
>
  @if (
    menu()?.has_template_default &&
    menu()?.has_template_default.indexOf(field()) > -1
  ) {
    <button
      mat-menu-item
      [disabled]="disabled()"
      (click)="restoreDefault.emit('template')"
    >
      {{ 'default_buttons.restore_from_temp_def' | transloco }}
    </button>
  }
  @if (
    (menu()?.rule || menu()?.location) &&
    menu()?.has_location_default &&
    menu()?.has_location_default.indexOf(field()) > -1
  ) {
    <button mat-menu-item (click)="restoreDefault.emit('rule')">
      @if (menu()?.rule) {
        {{ 'default_buttons.restore_from_lt_def' | transloco }}
      } @else {
        {{ 'default_buttons.restore_from_loc_def' | transloco }}
      }
    </button>
  }
</mat-menu>

<mat-menu
  #saveMenu="matMenu"
  class="save-restore-menu"
  [overlapTrigger]="false"
>
  @if (menu()?.onboarding_template) {
    <button
      mat-menu-item
      [disabled]="disabled()"
      (click)="saveDefault.emit('template')"
    >
      {{ 'default_buttons.save_as_temp_def' | transloco }}
    </button>
  }
  @if (menu()?.rule || menu()?.location) {
    <button
      mat-menu-item
      [disabled]="disabled()"
      (click)="saveDefault.emit('rule')"
    >
      @if (menu()?.rule) {
        {{ 'default_buttons.save_as_lt_def' | transloco }}
      } @else {
        {{ 'default_buttons.save_as_loc_def' | transloco }}
      }
    </button>
  }
</mat-menu>

<div class="restore-save-buttons">
  @if (
    (menu()?.has_template_default &&
      menu()?.has_template_default.indexOf(field()) > -1) ||
    (menu()?.has_location_default &&
      menu()?.has_location_default.indexOf(field()) > -1)
  ) {
    <a
      mat-button
      class="restore-save-button"
      [matMenuTriggerFor]="restoreMenu"
      >{{ 'default_buttons.restore' | transloco }}</a
    >
  }
  <a class="restore-save-button" mat-button [matMenuTriggerFor]="saveMenu">{{
    'default_buttons.save' | transloco
  }}</a>
</div>
