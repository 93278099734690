import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  output,
  viewChild,
  input,
  inject,
  DestroyRef,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { ContentLanguage, rtlLangs } from 'src/app/shared/constants/languages';
import { Dish } from 'src/app/shared/Models/dish';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { Separator } from 'src/app/shared/Models/separator';
import * as _ from 'lodash-es';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { KeyValuePipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { StopPropagationDirective } from '../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { GrammarCheckButtonComponent } from '../../../../shared/Components/grammar-check-button/grammar-check-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface FieldItem {
  field: string;
  name: string;
  object: 'data' | 'menudish';
  isEmpty: boolean;
  isAutotranslated: boolean;
  fieldTranslationDisabled: string;
}

@Component({
  selector: 'app-translation-item',
  templateUrl: './translation-item.component.html',
  styleUrls: ['./translation-item.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    GrammarCheckButtonComponent,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    StopPropagationDirective,
    MatOptionModule,
    KeyValuePipe,
    TranslocoPipe,
  ],
})
export class TranslationItemComponent implements OnChanges, OnInit {
  private destroyRef = inject(DestroyRef);

  readonly baseLanguage = input<ContentLanguage>(undefined);
  readonly data = input<Dish | Separator>(undefined);
  readonly language = input<ContentLanguage>(undefined);
  readonly menudish = input<MenuDish>(undefined);
  readonly similarTranslations = input<BaseNameExtended[]>(undefined);
  readonly showLoader = input<'item' | 'description' | 'other'>(undefined);
  readonly showRequired = input<boolean>(undefined);
  readonly currentMenuDish = input<MenuDish>(undefined);

  readonly setElement = output<boolean>();
  readonly changeTranslation = output<{
    item: MenuDish;
    translation: boolean;
  }>();
  readonly changeOtherTranslation = output<MenuDish>();
  readonly similarTranslation = output<void>();
  readonly similarTranslationDesc = output<{
    menudish: boolean;
    secondary: boolean;
  }>();
  readonly getSame = output<{
    dish: string;
    lang: ContentLanguage;
  }>();

  dishInputFocused = false;
  fields: Record<string, FieldItem> = {
    item: {
      field: '',
      name: 'translation',
      object: 'data',
      isEmpty: false,
      isAutotranslated: false,
      fieldTranslationDisabled: 'translation_disabled',
    },
    description: {
      field: 'description_',
      name: 'description',
      object: 'data',
      isEmpty: false,
      isAutotranslated: false,
      fieldTranslationDisabled: 'translation_disabled_description',
    },
    descriptionSecondary: {
      field: 'description_secondary_',
      name: 'description_secondary',
      object: 'data',
      isEmpty: false,
      isAutotranslated: false,
      fieldTranslationDisabled: 'translation_disabled_description_secondary',
    },
    menudishDescription: {
      field: 'description_',
      name: 'description',
      object: 'menudish',
      isEmpty: false,
      isAutotranslated: false,
      fieldTranslationDisabled: 'translation_disabled_description',
    },
    menudishDescriptionSecondary: {
      field: 'description_secondary_',
      name: 'description_secondary',
      object: 'menudish',
      isEmpty: false,
      isAutotranslated: false,
      fieldTranslationDisabled: 'translation_disabled_description_secondary',
    },
  };
  rtlLangs = rtlLangs;
  selectStatus = false;
  showGrammarIcon: boolean;
  transControl = new FormControl<string>('');

  readonly dishInput = viewChild<ElementRef<HTMLInputElement>>('dishInput');

  ngOnInit(): void {
    this.transControl.valueChanges
      .pipe(
        debounceTime(400),
        filter((v) => !!v),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((el) => {
        this.transControl.setValue(el, {
          emitEvent: false,
        });
        this.getSame.emit({
          dish: el || this.data()[this.baseLanguage()],
          lang: el ? this.language() : this.baseLanguage(),
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const data = this.data();
    const menudish = this.menudish();
    if (`menudish` in changes && menudish && data) {
      Object.keys(this.fields).forEach((key: string) => {
        const obj = this.fields[key];
        obj.isEmpty =
          key === 'item'
            ? !this[obj.object]()?.[`${obj.field}${this.language()}`]
            : !this[obj.object]().user_details?.[
                `${obj.field}${this.language()}`
              ];
        obj.isAutotranslated = this[
          obj.object
        ]().user_details?.auto_translated?.includes(
          `${obj.field}${this.language()}`,
        );
      });
    }
    if ('data' in changes && data) {
      if (data.user_details?.translation_disabled) {
        this.transControl.disable({ emitEvent: false });
      } else {
        this.transControl.enable({ emitEvent: false });
      }
    }
    const language = this.language();
    if (
      (`data` in changes || `language` in changes) &&
      data &&
      language &&
      !this.dishInputFocused
    ) {
      this.transControl.setValue(data[language], {
        emitEvent: false,
      });

      this.showGrammarIcon = !!(
        data[`spellcheck_${language}`] && data[language]
      );
    }
    const currentMenuDish = this.currentMenuDish();
    if (`currentMenuDish` in changes && currentMenuDish) {
      const details =
        currentMenuDish.dish_detail || currentMenuDish.separator_detail;
      const copiedText = details[language];
      if (
        menudish?.id === currentMenuDish?.id &&
        data[language] !== copiedText
      ) {
        this.transControl.setValue(copiedText, { emitEvent: false });
        this.sendCopiedValue(copiedText);
      }
    }
  }

  sendCopiedValue(value: string): void {
    const newMenuDish = _.cloneDeep(this.menudish());
    const detail = newMenuDish.dish_detail || newMenuDish.separator_detail;
    detail[this.language()] = value;
    this.changeTranslation.emit({ item: newMenuDish, translation: true });
  }

  submitDish(
    { target }: FocusEvent,
    control: UntypedFormControl,
    isMenudish = false,
  ): void {
    const { value, name } = target as HTMLInputElement;
    this.dishInputFocused = false;
    const newMenudish = _.cloneDeep(this.menudish());
    const detail = newMenudish.dish_detail || newMenudish.separator_detail;
    const field = `${name}_${this.language()}`;
    let previous: string;
    if (isMenudish) {
      previous = this.menudish().user_details?.[field] as string;
      newMenudish.user_details[field] = value;
    } else if (name === 'translation') {
      previous = detail[this.language()];
      detail[this.language()] = value;
    } else {
      previous = detail.user_details[field] as string;
      detail.user_details[field] = value;
    }

    if (this.selectStatus || value == null || value === previous)
      return undefined;
    if (!(control.errors && (control.dirty || control.touched))) {
      if (isMenudish) {
        this.changeOtherTranslation.emit(newMenudish);
      } else {
        this.changeTranslation.emit({
          item: newMenudish,
          translation: name === 'translation',
        });
      }
    }
  }

  blurItemInput = (element: HTMLInputElement) => element.blur();

  wtf(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.selectStatus = true;
  }

  selectDish(dish: BaseNameExtended): void {
    this.data()[this.language()] = dish[this.language()];
    const newMenudish = _.cloneDeep(this.menudish());
    if (newMenudish.dish_detail) {
      newMenudish.dish_detail = this.data() as Dish;
    } else {
      newMenudish.separator_detail = this.data() as Separator;
    }
    this.changeTranslation.emit({ item: newMenudish, translation: true });
    setTimeout(() => (this.selectStatus = false), 1000);
    this.dishInput().nativeElement.blur();
  }

  showSimilarTranslations(
    desc = false,
    menudish = false,
    secondary = false,
    inputClick = false,
  ): void {
    if (desc) {
      this.similarTranslationDesc.emit({ menudish, secondary });
    } else {
      this.similarTranslation.emit();
    }
    this.setElement.emit(inputClick);
  }
}
