import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Menu } from 'src/app/shared/Models/menu';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { GroupByPipe } from '../../../../shared/Pipes/group-by.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MtTooltipDirective } from '../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss'],
  imports: [
    MatIconModule,
    MtTooltipDirective,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    MatOptionModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslocoPipe,
    GroupByPipe,
  ],
})
export class SelectTemplateComponent implements OnChanges, OnInit, OnDestroy {
  private utils = inject(UtilsService);

  readonly isTrial = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly onboardingTemplates = input<OnboardingTemplate[]>(undefined);
  readonly profileComplete = input<boolean>(undefined);

  readonly createNewTemplateDialog = output();
  readonly deleteTemplate = output<{
    url: string;
    id: number;
  }>();
  readonly editTemplateDialog = output<{
    templateDetail: OnboardingTemplate;
  }>();
  readonly linkMenuToTemplate = output<number>();
  readonly unlink = output();
  readonly updateStyleAndLayout = output();
  readonly updateTemplateDetail = output<Partial<OnboardingTemplate>>();

  nameControl = new UntypedFormControl('', {
    updateOn: 'blur',
  });
  private destroyed$ = new Subject<void>();

  ngOnChanges(changes: SimpleChanges): void {
    const menu = this.menu();
    if ('menu' in changes && menu && menu.onboarding_template_detail) {
      this.nameControl.setValue(menu.onboarding_template_detail[this.lang()], {
        emitEvent: false,
      });
    }
  }

  ngOnInit(): void {
    this.nameControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((newName) => this.updateOnboardingTemplateDetail(newName));
  }

  updateOnboardingTemplateDetail(updatedTemplateData) {
    this.updateTemplateDetail.emit({ [this.lang()]: updatedTemplateData });
  }

  openEditTemplateDialog() {
    this.editTemplateDialog.emit({
      templateDetail: this.menu().onboarding_template_detail,
    });
  }

  createTemplate(): void {
    if (this.isTrial() && !this.profileComplete()) {
      this.utils.showTrialBlockedBox();
    } else {
      this.createNewTemplateDialog.emit();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
