<form [formGroup]="form">
  <mat-form-field>
    @if (translations()) {
      <mat-label>{{
        translations()['write.blocks.dish-overview.information.wine.winery'] ||
          ('write.blocks.dish-overview.information.wine.winery' | transloco)
      }}</mat-label>
    }
    <input matInput type="text" name="winery" formControlName="winery" />
    @if (loading.winery) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>

  <mat-form-field hintLabel="{{ minYear }} - {{ maxYear }}">
    @if (translations()) {
      <mat-label>{{
        translations()['write.blocks.dish-overview.information.wine.vintage'] ||
          ('write.blocks.dish-overview.information.wine.vintage' | transloco)
      }}</mat-label>
    }
    <input
      matInput
      type="number"
      lang="en-150"
      [min]="minYear"
      [max]="maxYear"
      name="vintage"
      formControlName="vintage"
    />
    @if (loading.vintage) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    }
  </mat-form-field>
</form>
