import { Component, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Dish } from 'src/app/shared/Models/dish';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import {
  Recipe,
  RecipeIngredient,
  SimpleRecipeIngredient,
} from 'src/app/shared/Models/recipe';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Subject,
  takeUntil,
} from 'rxjs';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RecipeIngredientsComponent } from '../../../../recipes/recipe-ingredients/recipe-ingredients.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { sharedFeature } from 'src/app/shared/ngrx/shared.state';
import { dishesMenuFeature } from 'src/app/shared/ngrx/dishes-menu/dishes-menu.state';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { GenericDeclaration } from 'src/app/shared/Models/declarations';

export interface IngredientSelectDialogData {
  dish: Dish;
  lang: InterfaceLanguage;
  type: 'allergens' | 'additives' | 'labels';
  recipes: Recipe[];
  option: GenericDeclaration;
  action: boolean;
  allergensView: boolean;
  additivesView: boolean;
  save: (checkBoxValue: boolean) => void;
  searchIngredients: (searchIngredient: string) => void;
  chooseIngredient: (data: { ingredient_id: number; recipe: Recipe }) => void;
  updateIngredient: (data: {
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: RecipeIngredient;
    };
  }) => void;
  updateRecipeIngredient: (data: {
    recipe: Recipe;
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: () => void;
    };
  }) => void;
  deleteIngredient: (data: {
    deletingIngredient: SimpleRecipeIngredient;
    recipe: Recipe;
  }) => void;
  addIngredient: (recipe: Recipe) => void;
  createIngredient: (data: {
    newIngredient: Partial<Ingredient>;
    recipe: Recipe;
  }) => void;
  changeRecipeDeclarativeOption: (data: {
    recipe: Recipe;
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
    };
  }) => void;
}

@Component({
  selector: 'app-ingredient-select',
  templateUrl: './ingredient-select.component.html',
  styleUrls: ['./ingredient-select.component.scss'],
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatCheckboxModule,
    RecipeIngredientsComponent,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    AsyncPipe,
    TranslocoPipe,
  ],
})
export class IngredientSelectDialogComponent implements OnInit {
  data = inject<IngredientSelectDialogData>(MAT_DIALOG_DATA);
  private ngrxStore = inject<Store<State>>(Store);
  private utils = inject(UtilsService);

  private destroyed$ = new Subject<void>();
  translatedName: string;
  autoControl = new FormControl('');
  lastNewIngredientName: string;
  ingredientsAuto: Ingredient[] = [];
  enableSaveButton = false;
  checkBoxValue: boolean;

  ingredientsAuto$ = this.ngrxStore.select(
    sharedFeature.selectIngredientsAutocomplete,
  );
  recipes$ = this.ngrxStore.select(dishesMenuFeature.selectRecipes);

  ngOnInit() {
    this.utils
      .getTranslationObject(
        `${this.data.type}.elements.${this.data.option.name}`,
        (v: string) => (this.translatedName = v),
      )
      .subscribe();
    this.autoControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((search: string) => search?.length >= 2),
        takeUntil(this.destroyed$),
      )
      .subscribe((v: string) => {
        this.lastNewIngredientName = v;
        this.data.searchIngredients(v);
      });

    this.ingredientsAuto$.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.ingredientsAuto = data;
    });
  }

  getRecipeName = (recipe: Recipe): { name; italic } =>
    this.utils.tryGetLabel(recipe, this.data.lang);

  updateIngredient(
    recipe: Recipe,
    updatedIngredient: {
      ingredient: Partial<Ingredient>;
      recipeIngredient: RecipeIngredient;
    },
  ) {
    this.data.updateIngredient({
      recipe: recipe,
      updatedIngredient,
    });
  }

  updateRecipeIngredient(
    recipe: Recipe,
    updatedIngredient: {
      url: string;
      recipeIngredient: Partial<RecipeIngredient>;
      onFulfilled: () => void;
    },
  ) {
    this.data.updateRecipeIngredient({
      recipe,
      updatedIngredient,
    });
  }

  deleteIngredient(recipe: Recipe, ingredient: RecipeIngredient) {
    this.enableSaveButton = true;
    this.data.deleteIngredient({
      recipe: recipe,
      deletingIngredient: ingredient,
    });
  }

  chooseIngredient(
    recipe: Recipe,
    chooseIngredient: { id: number; ingredient_id: number },
  ) {
    this.enableSaveButton = true;
    this.data.chooseIngredient({
      recipe: recipe,
      ingredient_id: chooseIngredient.ingredient_id,
    });
  }

  searchIngredients(searchTerm: string) {
    this.data.searchIngredients(searchTerm);
  }

  addIngredientToRecipe(recipe: Recipe) {
    this.data.addIngredient(recipe);
  }

  createIngredient(
    recipe: Recipe,
    { ingredient }: { ingredient: Partial<Ingredient>; id: number },
  ) {
    this.enableSaveButton = true;
    this.data.createIngredient({ recipe: recipe, newIngredient: ingredient });
  }

  changeRecipeDeclarativeOption(
    recipe: Recipe,
    data: {
      data: {
        data: Ingredient;
        type: string;
      };
      recipeIngredient: SimpleRecipeIngredient | RecipeIngredient;
    },
  ) {
    this.enableSaveButton = true;
    this.data.changeRecipeDeclarativeOption({
      recipe: recipe,
      updatedIngredient: {
        ingredient: data.data.data,
        recipeIngredient: data.recipeIngredient,
      },
    });
  }

  toggle(value: boolean) {
    this.checkBoxValue = value;
  }

  save() {
    this.data.save(!this.data.action);
  }
}
