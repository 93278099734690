<div class="trial-plan">
  @if (status().trial_period && status().trial_active) {
    <status-block
      class="onboarding"
      [complete]="complete()"
      [menusCreated]="menusCreated()?.all_time"
      [status]="status()"
      [steps]="statusMessages()"
      [user]="user()"
      [userOnboardingTemplates]="userOnboardingTemplates()"
      (createMenuFromOnboardingTemplate)="
        createMenuFromOnboardingTemplate.emit($event)
      "
    >
    </status-block>
  }
  <mat-card class="status mat-elevation-z3">
    <mat-card-title class="card-title mat-h3">
      @if (!status()?.trial_period) {
        {{ 'plan_statistics.title' | transloco }}
      } @else {
        {{ 'plan_statistics.title-trial' | transloco }}
      }
    </mat-card-title>
    <plan-limit
      class="plan-limit"
      [extensions]="user()?.subscription_extensions"
      [lang]="lang()"
      [status]="status()"
      [user]="user()"
    >
    </plan-limit>
  </mat-card>
</div>
