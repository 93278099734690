import {
  Component,
  OnChanges,
  OnInit,
  inject,
  output,
  input,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgClass } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
  imports: [
    MatFormFieldModule,
    NgClass,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatOptionModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class LanguageSelectorComponent implements OnChanges, OnInit {
  private translate = inject(TranslocoService);

  readonly appearance = input<string>(undefined);
  readonly changeValue = input<string>(undefined);
  readonly color = input<string>(undefined);
  readonly extendLangs = input<boolean>(undefined);
  readonly immutable = input(false);
  readonly limitLangs = input<any[]>(undefined);
  readonly left = input<boolean>(undefined);
  readonly longLang = input<boolean>(undefined);
  readonly placeholder = input('');
  readonly model = input<any>(undefined);
  readonly simple = input(false);
  readonly disabled = input<boolean>(undefined);
  readonly showClear = input<boolean>(undefined);

  readonly changed = output<any>();
  readonly clear = output<MouseEvent>();

  emptyModel = {};
  languages: { value: ContentLanguage; viewValue: string }[] = [
    {
      value: 'en',
      viewValue: 'English',
    },
    {
      value: 'it',
      viewValue: 'Italian',
    },
    {
      value: 'fr',
      viewValue: 'French',
    },
    {
      value: 'de',
      viewValue: 'German',
    },
    {
      value: 'es',
      viewValue: 'Spanish',
    },
  ];
  languagesExtended: { value: ContentLanguage; viewValue: string }[] = [
    {
      value: 'ca',
      viewValue: 'Catalan',
    },
    {
      value: 'hr',
      viewValue: 'Croatian',
    },
    {
      value: 'pl',
      viewValue: 'Polish',
    },
    {
      value: 'pt',
      viewValue: 'Portugese',
    },
    {
      value: 'nl',
      viewValue: 'Dutch',
    },
    {
      value: 'uk',
      viewValue: 'Ukrainian',
    },
    {
      value: 'ru',
      viewValue: 'Russian',
    },
    {
      value: 'el',
      viewValue: 'Greek',
    },
    {
      value: 'da',
      viewValue: 'Danish',
    },
    {
      value: 'sv',
      viewValue: 'Swedish',
    },
    {
      value: 'ar',
      viewValue: 'Arabic',
    },
    {
      value: 'eu',
      viewValue: 'Basque',
    },
    {
      value: 'gl',
      viewValue: 'Galician',
    },
    {
      value: 'et',
      viewValue: 'Estonian',
    },
    {
      value: 'fi',
      viewValue: 'Finnish',
    },
    {
      value: 'zh',
      viewValue: 'Chinese (Simplified)',
    },
    {
      value: 'th',
      viewValue: 'Thai',
    },
    {
      value: 'vi',
      viewValue: 'Vietnamese',
    },
  ];

  constructor() {
    this.translate.langChanges$.subscribe((el) => {
      this.handleAllLangs();
    });
  }

  ngOnInit(): void {
    this.handleAllLangs();
  }

  ngOnChanges(changes: any) {
    this.handleAllLangs();
    const changeValue = this.changeValue();
    const model = this.model();
    if (changeValue && model) {
      this.emptyModel[changeValue] = model[changeValue];
    }
  }

  handleChange({ value }) {
    if (this.immutable()) {
      this.changed.emit({
        ...this.model(),
        [this.changeValue()]: value,
      });
    } else {
      this.changed.emit({});
    }
  }

  handleAllLangs(): void {
    this.handleLang(this.languages).subscribe(
      (translatedLangs) => (this.languages = translatedLangs),
    );
    this.handleLang(this.languagesExtended).subscribe(
      (translatedLangs) => (this.languagesExtended = translatedLangs),
    );
  }

  handleLang(
    languages: { value: string; viewValue: string }[],
  ): Observable<{ value: ContentLanguage; viewValue: string }[]> {
    const keys = languages.map(
      (el) =>
        `shared.${
          this.simple() && !this.longLang() ? 'short_languages' : 'languages'
        }.${el.value}`,
    );
    return this.translate.selectTranslate(keys).pipe(
      map((el) => {
        const newLang = [];
        keys.forEach((key: string, index: number) => {
          const splittedKeys = key.split('.');
          newLang.push({ value: splittedKeys[2], viewValue: el[index] });
        });
        return newLang;
      }),
    );
  }
}
