import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { Menu } from 'src/app/shared/Models/menu';
import { Layout } from 'src/app/shared/Models/template';
import { SelectLayoutComponent } from '../select-layout/select-layout.component';

@Component({
  selector: 'layouts-wrapper',
  templateUrl: './layouts-wrapper.component.html',
  styleUrls: ['./layouts-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SelectLayoutComponent],
})
export class LayoutsWrapperComponent {
  readonly canModify = input<boolean>(undefined);
  readonly layouts = input<Layout[]>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);

  readonly selectLayout = output<number>();
  readonly sendRequest = output();
}
