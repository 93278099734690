@if (
  menu()?.template_detail?.style_variants &&
  menu()?.template_detail?.style_variants.length > 0
) {
  <label class="style-label">{{
    'style.sidebar.variants.label' | transloco
  }}</label>
  <div class="style-variants">
    @for (variant of menu()?.template_detail?.style_variants; track variant) {
      <select-image
        [selected]="variant.order === menu()?.style?.style_variant"
        [imageUrl]="variant?.image"
        [label]="variant[lang()]"
        (imgSelected)="
          variant.order !== menu()?.style?.style_variant &&
            selectedChange(variant)
        "
      ></select-image>
    }
    <!-- <div
    *ngFor="let variant of menu?.template_detail?.style_variants"
    class="style-variant"
    [class.selected]="variant.order === menu?.style?.style_variant"
      (click)="
        variant.order !== menu?.style?.style_variant && selectedChange(variant)
      "
    >
    <img
      *ngIf="variant?.image; else variantName"
      [alt]="variant[lang]"
      [src]="variant?.image"
      />
    <ng-template #variantName>
      {{ variant[lang] }}
    </ng-template>
  </div> -->
  </div>
}

<mat-accordion displayMode="flat">
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>palette</mat-icon>
      {{ 'style.sidebar.panels.primary.general.colours_text' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.font' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          @for (
            field of [
              'title',
              'subtitle',
              'section',
              'subsection',
              'text',
              'text_secondary',
              'price',
              'footnote',
            ];
            track field
          ) {
            <div class="element-group">
              <font-item
                [field]="field"
                [menu]="menu()"
                [mtFonts]="mtFonts()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></font-item>
            </div>
          }
          <div class="element-group">
            <font-item
              field="icons"
              [hideFonts]="true"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></font-item>
          </div>
          @if (
            (menu()?.style?.print_labels &&
              menu()?.style?.label_format === 5) ||
            (menu()?.style?.print_allergens &&
              menu()?.style?.allergen_format === 6) ||
            (menu()?.style?.print_additives &&
              menu()?.style.additive_format === 5)
          ) {
            <div class="element-group">
              <font-item
                field="declarations"
                [hideCapitalisation]="true"
                [menu]="menu()"
                [mtFonts]="mtFonts()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></font-item>
            </div>
          }
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.general.colours_other' | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            @for (field of styleConstants.coloursOtherFields; track field) {
              <div
                class="color-field"
                [class.color-disabled]="isLockedMap[field]"
              >
                <span class="expansion-panel-field">{{
                  'style.sidebar.items.' + field | transloco
                }}</span>
                <div class="element-wrapper">
                  <mt-color-picker
                    [isLocked]="isLockedMap[field]"
                    [disabled]="isLockedMap[field]"
                    class="mt-color-picker"
                    [class.color-picker-disabled]="isLockedMap[field]"
                    [color]="menu()?.style['colour_' + field]"
                    [showClear]="true"
                    (colorChange)="colorChanged('colour_' + field, $event)"
                    (clearColor)="clearColor('colour_' + field)"
                  ></mt-color-picker>
                  @if (showLocks()) {
                    <lock-item
                      [field]="'colour_' + field"
                      [lockedFields]="lockedFields()"
                      (updateMenu)="updateMenu.emit($event)"
                    ></lock-item>
                  }
                </div>
              </div>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.hyphens' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            @for (
              field of [
                'title',
                'subtitle',
                'section',
                'subsection',
                'text',
                'text_secondary',
                'footnote',
              ];
              track field
            ) {
              <toggle-item
                [field]="'hyphens_' + field"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>dashboard</mat-icon>
      {{ 'style.sidebar.panels.primary.general.layout' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          <mat-panel-title>{{
            'style.sidebar.panels.secondary.general.columns' | transloco
          }}</mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <number-item
              class="number-columns"
              field="number_columns"
              [max]="5"
              [min]="1"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></number-item>
            @if (menu()?.style?.number_columns > 1) {
              <select-item
                type="value"
                unit="mm"
                [field]="'columns_type'"
                [hideLabel]="true"
                [list]="styleConstants.columnOptions"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
            @if (menu()?.style?.number_columns > 1) {
              <number-item
                type="value"
                unit="mm"
                [field]="'columns_gap'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
                [min]="0"
              ></number-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.align' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            @for (
              field of [
                'pagetop',
                'section',
                'subsection',
                'text',
                'enddescription',
                'detail',
                'footnote',
                'logo',
                'legend',
              ];
              track field
            ) {
              <select-item
                class="element-narrow"
                type="value"
                unit="mm"
                [field]="'align_' + field"
                [list]="styleConstants.alignmentOptions"
                [menu]="menu()"
                [translationKey]="'align'"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.indent' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            @for (
              field of [
                'title',
                'subtitle',
                'section',
                'subsection',
                'text',
                'text_secondary',
                'footnote',
                'logo',
                'legend',
              ];
              track field
            ) {
              <number-item
                class="element-narrow"
                type="value"
                unit="mm"
                [field]="'indent_' + field"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>menu_book</mat-icon>
      {{ 'style.sidebar.panels.primary.general.menu' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.general.courses_options' | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <select-item
              [menu]="menu()"
              [field]="'course_separator'"
              [lang]="lang()"
              [list]="courses()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
            <select-item
              [menu]="menu()"
              [field]="'option_separator'"
              [lang]="lang()"
              [list]="options()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
          </div>
          <div class="element-group">
            <toggle-item
              [field]="'aggregate_courses'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.logo' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [field]="'print_logo'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (
              menu()?.style?.print_logo &&
              (getShowLogoOptions().length > 1 ||
                menu()?.style?.print_logo_type !== 0)
            ) {
              <select-item
                [menu]="menu()"
                [field]="'print_logo_type'"
                [hideLabel]="true"
                [list]="getShowLogoOptions()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
            <p class="field-info">
              {{ 'style.sidebar.toggles.print_logo_info.info' | transloco }}
              <a stop-propagation routerLink="/settings/profile">{{
                'style.sidebar.toggles.print_logo_info.info_link' | transloco
              }}</a
              >.
            </p>
          </div>
          <div class="element-group">
            <show-and-upload-image
              [disabled]="disabledFields().includes('logo')"
              [imageUrl]="menu()?.style?.logo_small"
              [label]="'style.sidebar.uploads.image.add' | transloco"
              [showSpinner]="showImageSpinner"
              (deleteImage)="clearLogoImage()"
              (fileChoosen)="uploadLogo($event)"
            ></show-and-upload-image>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.general.date_numbering' | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [field]="'print_date'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            <p class="field-info">
              {{ 'style.sidebar.toggles.print_date_info.info' | transloco }}
              <a stop-propagation [routerLink]="['/menus', menu().id, 1]">{{
                'style.sidebar.toggles.print_date_info.info_link' | transloco
              }}</a>
            </p>
            <toggle-item
              [field]="'print_pagenumbers'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (menu()?.style?.print_pagenumbers) {
              <number-item
                [field]="'pagenumbers_start'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></number-item>
              <select-item
                [menu]="menu()"
                [field]="'pagenumbers_format'"
                [list]="styleConstants.pageNumbersFormat"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.general.descriptions' | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <mat-slide-toggle
              color="primary"
              (change)="updateDescriptionDisplay($event)"
              [(ngModel)]="printDescriptions"
            >
              <div>
                {{ 'style.sidebar.toggles.print_description' | transloco }}
              </div>
            </mat-slide-toggle>
            @if (printDescriptions) {
              @for (
                field of [
                  'print_description_dishes',
                  'print_description_sections',
                ];
                track field
              ) {
                <toggle-item
                  class="checkbox-item"
                  [checkbox]="true"
                  [field]="field"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
              }
            }
            @if (menu()?.style.print_description_dishes) {
              <toggle-item
                [field]="'description_dishes_linebreak'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.images' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <mat-slide-toggle
              color="primary"
              (change)="updateImagesDisplay($event)"
              [(ngModel)]="printImages"
            >
              <div>
                {{ 'style.sidebar.toggles.print_images' | transloco }}
              </div>
            </mat-slide-toggle>
            @if (printImages) {
              @for (
                field of ['print_images_dishes', 'print_images_sections'];
                track field
              ) {
                <toggle-item
                  class="checkbox-item"
                  [checkbox]="true"
                  [field]="field"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
              }
              <select-item
                [menu]="menu()"
                [field]="'print_images_format'"
                [list]="styleConstants.imagesFormatOptions"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
              <div class="element-group">
                <span class="element-title">
                  {{
                    'style.sidebar.panels.subheaders.dish-image-sizes'
                      | transloco
                  }}
                </span>
                <number-item
                  [menu]="menu()"
                  [field]="'images_dishes_max_height'"
                  [placeholder]="'style.sidebar.placeholders.auto' | transloco"
                  [showLock]="showLocks()"
                  [showNull]="false"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></number-item>
                <number-item
                  [menu]="menu()"
                  [field]="'images_dishes_max_width'"
                  [placeholder]="'style.sidebar.placeholders.auto' | transloco"
                  [showLock]="showLocks()"
                  [showNull]="false"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></number-item>
              </div>
              <div class="element-group">
                <span class="element-title">
                  {{
                    'style.sidebar.panels.subheaders.section-image-sizes'
                      | transloco
                  }}
                </span>
                <number-item
                  [menu]="menu()"
                  [field]="'images_sections_max_height'"
                  [placeholder]="'style.sidebar.placeholders.auto' | transloco"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  [showNull]="false"
                  (updateMenu)="updateMenu.emit($event)"
                ></number-item>
                <number-item
                  [menu]="menu()"
                  [field]="'images_sections_max_width'"
                  [placeholder]="'style.sidebar.placeholders.auto' | transloco"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  [showNull]="false"
                  (updateMenu)="updateMenu.emit($event)"
                ></number-item>
              </div>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>restaurant</mat-icon>
      {{ 'style.sidebar.panels.primary.general.dish_bev_win' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.information' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [field]="'print_origin'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            <toggle-item
              [field]="'print_winery'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            <toggle-item
              [field]="'print_vintage'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (menu().style.print_vintage) {
              <toggle-item
                [field]="'vintage_show_nv'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
            <toggle-item
              [field]="'show_info_url'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            <toggle-item
              [field]="'origin_linebreak'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            <toggle-item
              [field]="'print_numbering'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (hasModuleSetting('man', 'article_numbers', true)) {
              <toggle-item
                [field]="'print_article_number'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
            <toggle-item
              [field]="'print_nutrition_rating'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (hasModule('recip')) {
              <toggle-item
                [field]="'print_nutrition_values'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
            @if (menu()?.style?.print_nutrition_values) {
              <select-item
                [menu]="menu()"
                [field]="'aggregate_nutrition_values'"
                [list]="styleConstants.aggregateNutritionValues"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
            <toggle-item
              [field]="'print_carbohydrate_units'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.prices' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <span class="element-title">{{
              'style.sidebar.panels.subheaders.price-dishes' | transloco
            }}</span>
            <toggle-item
              [field]="'print_price_dishes'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (menu()?.style?.print_price_dishes) {
              <toggle-item
                [field]="'price_variant_text_secondary'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
              <toggle-item
                [field]="'print_price_dishes_inline'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
              @if (!menu()?.style?.print_price_dishes_inline) {
                <toggle-item
                  [field]="'variant_linebreak'"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
              }
              @if (
                !menu()?.style?.variant_linebreak &&
                !menu()?.style?.print_price_dishes_inline
              ) {
                <number-item
                  [field]="'price_column_width'"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                  [min]="0"
                  type="value"
                  unit="mm"
                ></number-item>
              }
              @if (!menu()?.style?.print_price_dishes_inline) {
                <select-item
                  [menu]="menu()"
                  [field]="'print_leader'"
                  [list]="styleConstants.leaderOptions"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></select-item>
                @if (menu()?.style?.print_price_dishes) {
                  <p class="field-info">
                    {{ 'style.sidebar.selects.print_leader_info' | transloco }}
                  </p>
                }
              }
            }
          </div>

          <div class="element-group">
            <span class="element-title">{{
              'style.sidebar.panels.subheaders.price-sections' | transloco
            }}</span>
            <toggle-item
              [field]="'print_price_sections'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (menu()?.style?.print_price_sections) {
              <toggle-item
                [field]="'print_price_sections_inline'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
          </div>

          @if (
            menu()?.style?.print_price_dishes_inline ||
            menu()?.style?.print_price_sections_inline
          ) {
            <div class="element-group">
              <span class="element-title">{{
                'style.sidebar.panels.subheaders.price-settings' | transloco
              }}</span>
              <select-item
                [menu]="menu()"
                [field]="'price_format'"
                [list]="styleConstants.priceFormats"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
              <select-item
                [menu]="menu()"
                [field]="'print_price_currency'"
                [list]="styleConstants.priceShowCurrencyOptions"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
              @if (menu()?.style?.print_price_currency !== 0) {
                <toggle-item
                  [field]="'price_format_after'"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
              }
            </div>
            <div class="element-group">
              <span class="element-title">{{
                'style.sidebar.panels.subheaders.price-separators' | transloco
              }}</span>
              @if (
                menu()?.style.print_price_dishes_inline ||
                menu()?.style.print_price_sections_inline
              ) {
                <select-item
                  class="narrow"
                  [menu]="menu()"
                  [field]="'price_inline_separator'"
                  [list]="styleConstants.priceSeparatorOptions"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  [translate]="false"
                  (updateMenu)="updateMenu.emit($event)"
                ></select-item>
              }
              <select-item
                class="narrow"
                [menu]="menu()"
                [field]="'price_variant_separator'"
                [list]="styleConstants.priceSeparatorOptions"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [translate]="false"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
              <select-item
                class="narrow"
                [menu]="menu()"
                [field]="'price_variant_between_separator'"
                [list]="styleConstants.priceSeparatorOptions"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [translate]="false"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            </div>
          }
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.checkboxes' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <toggle-item
              [field]="'print_checkbox'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
            @if (menu()?.style?.print_checkbox) {
              <select-item
                [menu]="menu()"
                [field]="'print_checkbox_format'"
                [list]="styleConstants.checkboxPositionOptions"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
            @if (menu()?.style?.print_checkbox) {
              <select-item
                [menu]="menu()"
                [field]="'print_checkbox_type'"
                [list]="styleConstants.checkboxTypeOptions"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></select-item>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>dashboard</mat-icon>
      {{
        'style.sidebar.panels.primary.general.legend_declarations' | transloco
      }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.general.declarations' | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            @if (
              (hasModule('all') || hasModule('add') || hasModule('label')) &&
              (!menu()?.style?.aggregate_courses ||
                menu()?.style?.aggregate_courses_declarations) &&
              (menu()?.style?.print_allergens ||
                menu()?.style?.print_additives ||
                (menu()?.style?.print_labels &&
                  menu()?.style?.label_position === 2))
            ) {
              <toggle-item
                [field]="'declarations_linebreak'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
            @if (
              menu()?.style?.aggregate_courses &&
              (menu()?.style?.print_allergens ||
                menu()?.style?.print_additives ||
                menu()?.style?.print_labels)
            ) {
              <toggle-item
                class="checkbox-item"
                [checkbox]="true"
                [field]="'aggregate_courses_declarations'"
                [menu]="menu()"
                [showLock]="showLocks()"
                [lockedFields]="lockedFields()"
                [disabledFields]="disabledFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></toggle-item>
            }
            <hr />
            @if (hasModule('all')) {
              <div class="element-group element-toggle-select">
                <span class="element-title">{{
                  'style.sidebar.panels.subheaders.allergens' | transloco
                }}</span>
                <toggle-item
                  [field]="'print_allergens'"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
                @if (menu()?.style?.print_allergens) {
                  <select-item
                    [menu]="menu()"
                    [hideLabel]="true"
                    [field]="'allergen_format'"
                    [list]="allergensFormatsView"
                    [showLock]="showLocks()"
                    [lockedFields]="lockedFields()"
                    [disabledFields]="disabledFields()"
                    (updateMenu)="updateMenu.emit($event)"
                  ></select-item>
                }
                @if (
                  hasModule('all') &&
                  menu()?.style?.print_allergens &&
                  [0, 1, 2, 3].includes(menu()?.style?.allergen_format)
                ) {
                  <toggle-item
                    [field]="'allergen_coloured'"
                    [menu]="menu()"
                    [showLock]="showLocks()"
                    [lockedFields]="lockedFields()"
                    [disabledFields]="disabledFields()"
                    (updateMenu)="updateMenu.emit($event)"
                  ></toggle-item>
                }
              </div>
            }
            <hr />
            @if (hasModule('add')) {
              <div class="element-group element-toggle-select">
                <span class="element-title">{{
                  'style.sidebar.panels.subheaders.additives' | transloco
                }}</span>
                <toggle-item
                  [field]="'print_additives'"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
                @if (menu()?.style?.print_additives) {
                  <select-item
                    [menu]="menu()"
                    [hideLabel]="true"
                    [field]="'additive_format'"
                    [list]="additiveFormatsView"
                    [showLock]="showLocks()"
                    [lockedFields]="lockedFields()"
                    [disabledFields]="disabledFields()"
                    (updateMenu)="updateMenu.emit($event)"
                  ></select-item>
                }
              </div>
            }
            <hr />
            @if (hasModule('label')) {
              <div class="element-group element-toggle-select">
                <span class="element-title">{{
                  'style.sidebar.panels.subheaders.labels' | transloco
                }}</span>
                <toggle-item
                  [field]="'print_labels'"
                  [menu]="menu()"
                  [showLock]="showLocks()"
                  [lockedFields]="lockedFields()"
                  [disabledFields]="disabledFields()"
                  (updateMenu)="updateMenu.emit($event)"
                ></toggle-item>
                @if (menu()?.style?.print_labels) {
                  <select-item
                    [menu]="menu()"
                    [hideLabel]="true"
                    [field]="'label_format'"
                    [list]="labelsFormatsView"
                    [showLock]="showLocks()"
                    [lockedFields]="lockedFields()"
                    [disabledFields]="disabledFields()"
                    (updateMenu)="updateMenu.emit($event)"
                  ></select-item>
                }
                @if (menu()?.style?.print_labels) {
                  <select-item
                    [menu]="menu()"
                    [field]="'label_position'"
                    [hideLabel]="true"
                    [list]="styleConstants.labelPositionOptions"
                    [showLock]="showLocks()"
                    [lockedFields]="lockedFields()"
                    [disabledFields]="disabledFields()"
                    (updateMenu)="updateMenu.emit($event)"
                  ></select-item>
                }
              </div>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.legend' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div
            class="element-group"
            [class.disabled-toggle]="!menu()?.style?.print_legend"
          >
            <toggle-item
              [field]="'print_legend'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            >
            </toggle-item>
            <select-item
              [menu]="menu()"
              [field]="'print_legend_type'"
              [hideLabel]="true"
              [list]="styleConstants.showLegendOptions"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
          </div>
          @if (menu()?.style?.print_legend) {
            <toggle-item
              [field]="'print_legend_page'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            >
            </toggle-item>
          }
          @if (hasModule('all') && menu()?.style?.print_legend) {
            <toggle-item
              [field]="'print_legend_all'"
              [menu]="menu()"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              (updateMenu)="updateMenu.emit($event)"
            ></toggle-item>
          }
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header class="primary-panel">
      <mat-icon>local_florist</mat-icon>
      {{ 'style.sidebar.panels.primary.general.decorators' | transloco }}
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{
            'style.sidebar.panels.secondary.general.background_text' | transloco
          }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            @for (field of styleConstants.backgroundTextFields; track field) {
              <div
                class="color-field"
                [class.color-disabled]="isLockedMap[field]"
              >
                <span class="expansion-panel-field">{{
                  'style.sidebar.fields.' + field | transloco
                }}</span>
                <div class="element-wrapper">
                  <mt-color-picker
                    [isLocked]="isLockedMap[field]"
                    [disabled]="isLockedMap[field]"
                    class="mt-color-picker"
                    [class.color-picker-disabled]="isLockedMap[field]"
                    [color]="menu()?.style['background_' + field]"
                    [showClear]="true"
                    (colorChange)="colorChanged('background_' + field, $event)"
                    (clearColor)="clearColor('background_' + field)"
                  >
                  </mt-color-picker>
                  @if (showLocks()) {
                    <lock-item
                      [field]="'background_' + field"
                      [lockedFields]="lockedFields()"
                      (updateMenu)="updateMenu.emit($event)"
                    ></lock-item>
                  }
                </div>
              </div>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.divider' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          @for (
            field of [
              'title',
              'subtitle',
              'section',
              'subsection',
              'text',
              'footnote',
            ];
            track field
          ) {
            <div class="element-group special-line-decorator">
              <span class="element-title"
                >{{ 'style.sidebar.fields.' + field | transloco }}:</span
              >
              @if (field !== 'text') {
                <div class="element-wrapper">
                  <span class="field-with-lock">
                    <span class="expansion-panel-field">{{
                      'style.sidebar.numbers.line_above_' + field | transloco
                    }}</span>
                    @if (showLocks()) {
                      <lock-item
                        [field]="[
                          'line_above_' + field,
                          'line_above_' + field + '_width',
                          'line_above_' + field + '_style',
                        ]"
                        [lockedFields]="lockedFields()"
                        (updateMenu)="updateMenu.emit($event)"
                      ></lock-item>
                    }
                  </span>
                  <number-item
                    type="value"
                    unit="pt"
                    class="element-narrow"
                    [hideLabel]="true"
                    [field]="'line_above_' + field"
                    [menu]="menu()"
                    [showLock]="false"
                    (updateMenu)="updateMenu.emit($event)"
                  ></number-item>
                  @if (menu()?.style && menu()?.style['line_above_' + field]) {
                    <number-item
                      type="value"
                      unit="%"
                      class="element-narrow"
                      [min]="0"
                      [max]="100"
                      [hideLabel]="true"
                      [field]="'line_above_' + field + '_width'"
                      [menu]="menu()"
                      [showLock]="false"
                      (updateMenu)="updateMenu.emit($event)"
                    ></number-item>
                  }
                </div>
                @if (menu().style['line_above_' + field]) {
                  <div class="element-wrapper">
                    <select-item
                      [menu]="menu()"
                      [field]="'line_above_' + field + '_style'"
                      [label]="'line_style_above'"
                      [list]="styleConstants.lineStyleOptions"
                      [showLock]="showLocks()"
                      [lockedFields]="lockedFields()"
                      [disabledFields]="disabledFields()"
                      [translationKey]="'line_style'"
                      (updateMenu)="updateMenu.emit($event)"
                    ></select-item>
                  </div>
                }
              }
              @if (field !== 'footnote') {
                <div class="element-wrapper">
                  <span class="field-with-lock">
                    <span class="expansion-panel-field">{{
                      'style.sidebar.numbers.line_below_' + field | transloco
                    }}</span>
                    @if (showLocks()) {
                      <lock-item
                        [field]="[
                          'line_below_' + field,
                          'line_below_' + field + '_width',
                          'line_below_' + field + '_style',
                        ]"
                        [lockedFields]="lockedFields()"
                        (updateMenu)="updateMenu.emit($event)"
                      ></lock-item>
                    }
                  </span>
                  <number-item
                    type="value"
                    unit="pt"
                    class="element-narrow"
                    [hideLabel]="true"
                    [field]="'line_below_' + field"
                    [menu]="menu()"
                    [showLock]="false"
                    (updateMenu)="updateMenu.emit($event)"
                  ></number-item>
                  @if (menu()?.style && menu()?.style['line_below_' + field]) {
                    <number-item
                      type="value"
                      unit="%"
                      class="element-narrow"
                      [min]="0"
                      [max]="100"
                      [hideLabel]="true"
                      [field]="'line_below_' + field + '_width'"
                      [menu]="menu()"
                      [showLock]="false"
                      (updateMenu)="updateMenu.emit($event)"
                    ></number-item>
                  }
                </div>
                @if (menu().style['line_below_' + field]) {
                  <div class="element-wrapper">
                    <select-item
                      [menu]="menu()"
                      [field]="'line_below_' + field + '_style'"
                      [label]="'line_style_below'"
                      [list]="styleConstants.lineStyleOptions"
                      [showLock]="showLocks()"
                      [lockedFields]="lockedFields()"
                      [disabledFields]="disabledFields()"
                      [translationKey]="'line_style'"
                      (updateMenu)="updateMenu.emit($event)"
                    ></select-item>
                  </div>
                }
              }
            </div>
          }
          @if (menu().style.number_columns > 1) {
            <div class="element-group special-line-decorator">
              <span class="element-title"
                >{{ 'style.sidebar.fields.columns' | transloco }}:</span
              >
              <div class="element-wrapper">
                <span class="field-with-lock">
                  <span class="expansion-panel-field">{{
                    'style.sidebar.numbers.line_between_columns' | transloco
                  }}</span>
                  @if (showLocks()) {
                    <lock-item
                      [field]="['line_between_columns']"
                      [lockedFields]="lockedFields()"
                      (updateMenu)="updateMenu.emit($event)"
                    ></lock-item>
                  }
                </span>
                <number-item
                  type="value"
                  unit="pt"
                  class="element-narrow"
                  [hideLabel]="true"
                  [field]="'line_between_columns'"
                  [menu]="menu()"
                  [showLock]="false"
                  (updateMenu)="updateMenu.emit($event)"
                ></number-item>
              </div>
              @if (menu().style.line_between_columns) {
                <div class="element-wrapper">
                  <select-item
                    [menu]="menu()"
                    [field]="'line_between_columns_style'"
                    [list]="styleConstants.lineStyleOptions"
                    [showLock]="showLocks()"
                    [lockedFields]="lockedFields()"
                    [disabledFields]="disabledFields()"
                    [translationKey]="'line_style'"
                    (updateMenu)="updateMenu.emit($event)"
                  ></select-item>
                </div>
              }
            </div>
          }
        </ng-template>
      </mat-expansion-panel>
      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header expandedHeight="48px">
          {{ 'style.sidebar.panels.secondary.general.highlights' | transloco }}
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="element-group">
            <select-item
              [menu]="menu()"
              [field]="'highlight_sections_type'"
              [list]="styleConstants.highlightSectionsTypeOptions"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              [translationKey]="'highlight_sections'"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
            <select-item
              [menu]="menu()"
              [field]="'highlight_dishes_type'"
              [list]="styleConstants.highlightTypeOptions"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              [translationKey]="'highlight'"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
            <select-item
              [menu]="menu()"
              [field]="'highlight_enddescription_type'"
              [list]="styleConstants.highlightTypeOptions"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              [translationKey]="'highlight'"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
            <select-item
              [menu]="menu()"
              [field]="'highlight_endtext_type'"
              [list]="styleConstants.highlightTypeOptions"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              [translationKey]="'highlight'"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
            <select-item
              [menu]="menu()"
              [field]="'highlight_variant_type'"
              [list]="styleConstants.highlightTypeOptions"
              [showLock]="showLocks()"
              [lockedFields]="lockedFields()"
              [disabledFields]="disabledFields()"
              [translationKey]="'highlight'"
              (updateMenu)="updateMenu.emit($event)"
            ></select-item>
          </div>
        </ng-template>
      </mat-expansion-panel>
    </ng-template>
  </mat-expansion-panel>
  @if (
    menu()?.template_detail?.custom_data &&
    !!getObjectSize(menu()?.template_detail?.custom_data)
  ) {
    <mat-expansion-panel>
      <mat-expansion-panel-header class="primary-panel">
        <mat-icon>palette</mat-icon>
        {{ 'style.sidebar.panels.primary.general.custom' | transloco }}
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="custom-data">
          <p>
            {{ 'style.sidebar.hints.custom_data' | transloco }}
            @if (showLocks()) {
              <lock-item
                class="custom-data-lock"
                field="custom_data"
                [lockedFields]="lockedFields()"
                (updateMenu)="updateMenu.emit($event)"
              ></lock-item>
            }
          </p>
          <app-custom-data-form
            [customData]="menu().style.custom_data"
            [supportedCustomData]="menu()?.template_detail?.custom_data"
            (valueChanged)="patchStyleCustomData($event)"
          ></app-custom-data-form>
        </div>
      </ng-template>
    </mat-expansion-panel>
  }
</mat-accordion>
