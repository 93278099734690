<div class="write-header">
  @if (menu()?.base_language) {
    <h3>
      @if (!searchIsActive) {
        {{
          translations['menus.steps.write.title'] ||
            ('menus.steps.write.title' | transloco)
        }}
        {{ translations['preposition.in'] || ('preposition.in' | transloco) }}
        {{ 'shared.languages.' + menu()?.base_language | transloco }}
      } @else {
        {{
          translations['menus.steps.write.search'] ||
            ('menus.steps.write.search' | transloco)
        }}
      }
    </h3>
  }
  <div class="menu-quick-actions">
    <form class="search-form" [class.hidden]="!menu()">
      <div class="menu-filter icon-button-density-3">
        <input
          #searchInput
          name="dish"
          type="text"
          [formControl]="searchControl"
          [class.safari]="isSafari"
          [placeholder]="
            translations['write.blocks.main.search'] ||
            ('write.blocks.main.search' | transloco)
          "
          (keydown.enter)="(false)"
        />
        @if (searchControl.value) {
          <button
            mat-icon-button
            stop-propagation
            class="search-clear"
            aria-label="Clear"
            (click)="clearSearch()"
          >
            <mat-icon class="blue">clear</mat-icon>
          </button>
        }
        <button
          mat-icon-button
          aria-label="Search"
          class="filter-button"
          (click)="searchInput.focus()"
        >
          <mat-icon class="blue">search</mat-icon>
        </button>
      </div>
    </form>
    @if (showTreeActions) {
      @if (anyExpanded && !searchIsActive) {
        <button
          mat-button
          class="tree-action-button white"
          [disabled]="partentNodesChildUnderEdit.size > 0"
          (click)="collapseAll()"
        >
          <mat-icon class="tree-action-icon">fullscreen_exit</mat-icon>
          <span class="tree-action-text">{{
            'write.blocks.main.collapse' | transloco
          }}</span>
        </button>
      }
      @if (!anyExpanded && !searchIsActive) {
        <button
          mat-button
          class="tree-action-button white"
          [disabled]="partentNodesChildUnderEdit.size > 0"
          (click)="expandAll()"
        >
          <mat-icon class="tree-action-icon">fullscreen</mat-icon>
          <span class="tree-action-text">{{
            'write.blocks.main.uncollapse' | transloco
          }}</span>
        </button>
      }
    }
    @if (menu()?.template_detail) {
      <button
        mat-flat-button
        color="primary"
        class="tree-action-button preview-action"
        (click)="previewMenu()"
      >
        <mat-icon class="tree-action-icon">visibility</mat-icon>
        {{ 'dashboard.quick_overview.preview' | transloco }}
      </button>
    }
  </div>
</div>

<div class="main-column simple-theme">
  @if (isMobileView) {
    <button
      mat-flat-button
      color="primary"
      class="further-actions-button"
      tourAnchor="menu-actions"
      (click)="showSidePanel()"
    >
      {{ 'write.further-actions' | transloco }}
      <mat-icon>arrow_right</mat-icon>
    </button>
  }
  <mat-card class="write-menu" id="menuTourDishes">
    @if (menuDishesLoading() === false) {
      <div class="row">
        <ul
          DndSort
          class="dishes-list"
          [class.item-focused]="isFocused"
          [data]="menuDishes()"
          [nodeData]="treeStructure"
          (changed)="
            onSortChange($event.dishes, $event.sendRequest, $event.updateScroll)
          "
          (treeDnD)="treeDnD($event)"
          (updated)="onSortChange($event)"
        >
          @if (searchIsActive && !menuDishes()?.length) {
            <li class="items" [style.height]="NODE_HEIGHT + 'px'">
              <div class="no-results">
                {{ 'write.blocks.main.no-results' | transloco }}
                <a (click)="clearSearch()">{{
                  'write.blocks.main.return-to-menu' | transloco
                }}</a>
              </div>
            </li>
          }
          <menutech-virtual-scroll
            class="virtual-scroll"
            [childHeight]="NODE_HEIGHT"
            [nodesUnderCreation]="nodesTemporaryFocused"
            (blurInput)="triggerInputBlur()"
            (listReady)="autostartTour && initTour()"
            (update)="updateViewPortMenuDishes($event)"
          >
            @if (viewPortMenuDishes.length) {
              @for (
                node of viewPortMenuDishes;
                track trackMenudishesFn(i, node);
                let i = $index
              ) {
                <li
                  DndSortItem
                  #menudishWrapper
                  class="items"
                  [attr.data-cat]="
                    node.dish?.dish_detail?.category === categories.DISH ||
                    node.dish?.dish_detail?.category === categories.WINE ||
                    node.dish?.dish_detail?.category === categories.BEVERAGE
                  "
                  [attr.data-category]="
                    node.dish?.dish_detail?.category ||
                    node.dish?.separator_detail?.category
                  "
                  [attr.data-child]="node.dish?.level > 1"
                  [attr.data-dndindex]="node.index"
                  [attr.data-id]="node.dish?.id"
                  [attr.data-index]="node.realIndex"
                  [attr.data-focused]="false"
                  [attr.data-level]="node.dish?.level"
                  [attr.data-nodeIndex]="i"
                  [attr.data-section]="
                    node.dish?.separator_detail?.category === categories.DAY ||
                    node.dish?.separator_detail?.category === categories.SECTION
                  "
                  [class.disabled]="node.deleted"
                  [class.section-expanded]="node.isExpanded"
                  [class.selected]="
                    node.dish?.url && node.dish?.id === previousMenudishID
                  "
                  [draggable]="isDragable && (isSorting$ | async) === false"
                  [ngClass]="getChildNodeClass(node.dish.level)"
                  [style.height]="NODE_HEIGHT + 'px'"
                >
                  @if (node.children?.length && node.isExpanded) {
                    <span
                      class="line-vertical"
                      [style.height]="getExpansionLineHeight(node)"
                    ></span>
                  }
                  @if (node.level > 1) {
                    <span
                      class="line-horizontal"
                      [class.section-or-day]="node.children?.length"
                      [ngClass]="'level-' + node.level"
                    ></span>
                  }
                  <app-menu-item
                    class="menu-item field-density-1"
                    [autocompleteDishes]="
                      node.focused && dishesAutocomplete$ | async
                    "
                    [autocompleteSections]="
                      node.focused && sectionsAutocomplete$ | async
                    "
                    [searchIsActive]="searchIsActive"
                    [forbiddenDishes]="forbiddenDishes"
                    [forbiddenSections]="forbiddenSections"
                    [inProgress]="
                      !node.dish?.url
                        ? nodesUnderCreation.has(node.dish?.id)
                        : nodesUnderEdit.has(node.dish?.id)
                    "
                    [insertItemMenu]="insertItemMenu"
                    [isExtendedLang]="isExtendedLang"
                    [isExpanded]="node.isExpanded"
                    [isDragable]="isDragable"
                    [isSorting]="isSorting$ | async"
                    [lang]="lang"
                    [node]="node"
                    [selected]="
                      node.dish?.url && node.dish?.id === previousMenudishID
                    "
                    [showNumbering]="menu()?.style?.print_customnumbering"
                    [translations]="translations"
                    [user]="user()"
                    (autocomplete)="selectAutocompleteOption($event)"
                    (changeDish)="
                      changeDish($event.node, $event.data, $event.options)
                    "
                    (changeDraggable)="changeDraggable(menudishWrapper, $event)"
                    (changeNumbering)="changeNumbering($event, node)"
                    (clearAutocomplete)="clearSame($event)"
                    (delete)="deleteMenuDish(node)"
                    (getSame)="getSame($event)"
                    (getSameSection)="getSameSection($event)"
                    (lineFocused)="lineFocused(node, $event)"
                    (setCurrentElement)="setCurrentElement($event, node)"
                    (toggleCurrentElement)="toggleCurrentElement($event, node)"
                    (toggleSection)="toggleSection(node)"
                  ></app-menu-item>
                </li>
              }
            }
          </menutech-virtual-scroll>
          @if (!searchIsActive) {
            <li
              DndSortItem
              #bottomDrop
              class="bottom-drop"
              draggable="false"
            ></li>
          }
        </ul>
        <mat-menu #insertItemMenu="matMenu" class="plus-btns-menu">
          <ng-template matMenuContent let-node="node">
            @for (cat of enabledItems; track cat) {
              @if (
                cat !== categories.DAY &&
                !(node?.level === 3 && cat === categories.SECTION)
              ) {
                <button
                  mat-menu-item
                  class="add"
                  [disabled]="menuDishesLoading()"
                  (click)="addNewLine(cat, node?.realIndex, node)"
                >
                  <mat-icon>add_circle</mat-icon>
                  <span>{{
                    translations['write.dishes.actions.' + cat] ||
                      ('write.dishes.actions.' + cat | transloco)
                  }}</span>
                </button>
              }
            }
            @if (menu()?.add_dishes && menu()?.add_ai) {
              <button
                mat-menu-item
                class="add"
                [disabled]="
                  menuDishesLoading() || !node?.dish?.separator_detail?.[lang]
                "
                (click)="
                  addAndCreateAiItem(
                    'ai_dis',
                    node?.dish?.separator_detail?.[lang],
                    node?.realIndex,
                    node
                  )
                "
              >
                <mat-icon svgIcon="magic_stick"></mat-icon>
                <span>{{ 'write.dishes.actions.ask_ai' | transloco }}</span>
              </button>
            }
            @if (node.level === 1) {
              <hr />
              <button
                mat-menu-item
                class="add"
                [disabled]="menuDishesLoading()"
                (click)="duplicateSection(node)"
              >
                <mat-icon color="primary">control_point_duplicate</mat-icon>
                <span>{{
                  translations['write.dishes.actions.duplicate'] ||
                    ('write.dishes.actions.duplicate' | transloco)
                }}</span>
              </button>
            }
          </ng-template>
        </mat-menu>
      </div>
    } @else {
      <spinner></spinner>
    }
    @if (searchIsActive) {
      <div class="go-back">
        <button mat-button class="go-back-button" (click)="clearSearch()">
          <mat-icon aria-hidden="true" class="material-icons"
            >keyboard_arrow_left</mat-icon
          >
          {{ 'write.blocks.main.go-back' | transloco }}
        </button>
      </div>
    }
    @if (menu() && !searchIsActive) {
      <div #buttons class="add-buttons">
        <div class="add-button-wrapper" tourAnchor="add-line">
          @for (cat of enabledItems; track cat) {
            <button
              mat-flat-button
              color="primary"
              class="add-entity-button big-button"
              [disabled]="menuDishesLoading() || (isSorting$ | async) === true"
              (click)="addNewLine(cat)"
            >
              + <br />
              {{
                translations['write.dishes.actions.' + cat] ||
                  ('write.dishes.actions.' + cat | transloco)
              }}
            </button>
          }
        </div>
      </div>
    }
  </mat-card>

  <menu-details
    [lang]="lang"
    [menu]="menu()"
    [title]="
      translations['write.dishes.details'] ||
      ('write.dishes.details' | transloco)
    "
    [translations]="translations"
    [user]="user()"
    (changeMenu)="patchMenu.emit($event)"
    (restoreDefault)="restoreDefault($event)"
    (saveDefault)="saveDefault($event)"
  ></menu-details>

  <div class="coverpage-meta">
    <mat-slide-toggle
      #coverpageToggle
      class="coverpage-toggle"
      color="primary"
      [disabled]="!menu()?.id"
      [ngModel]="menu()?.style?.print_coverpage"
      (change)="setCoverpageState($event)"
    >
      {{
        translations['write.dishes.coverpage'] ||
          ('write.dishes.coverpage' | transloco)
      }}
    </mat-slide-toggle>
    @if (menu()?.rule || menu()?.onboarding_template) {
      <save-restore
        [disabled]="editorUpdating"
        [field]="'coverpage_' + menu()?.base_language"
        [menu]="menu()"
        (restoreDefault)="
          restoreDefault($event, 'coverpage_' + menu()?.base_language)
        "
        (saveDefault)="
          saveDefault({
            target: $event,
            field: 'coverpage_' + menu()?.base_language,
          })
        "
      ></save-restore>
    }
  </div>
  <mat-card
    class="details-card editor"
    [class.hide-editor]="!coverpageToggle.checked"
  >
    @if (menu()?.user_details) {
      <app-quill-editor
        #quillEditor
        class="editor-component"
        [contentData]="menu()?.user_details['coverpage_' + lang]"
        [contentType]="'menudetail'"
        [objectId]="menu()?.id"
        [lang]="menu()?.base_language"
        [placeholder]="
          translations['write.blocks.main.editor.placeholder'] ||
          ('write.blocks.main.editor.placeholder' | transloco)
        "
        (contentChangedTap)="onQuillChangedTap()"
        (contentChanged)="onQuillEditorChanged($event)"
      >
      </app-quill-editor>
    }
  </mat-card>
</div>

<ng-template #sidePanel>
  <div class="top-right-corner">
    @if (!isMobileView) {
      <button
        mat-flat-button
        color="primary"
        class="add-entity-button full"
        (click)="nextStep.emit()"
      >
        <mat-icon aria-hidden="true" class="material-icons"
          >arrow_forward</mat-icon
        >{{ 'shared.buttons.next' | transloco }}
      </button>
    }
  </div>
  @if (isMobileView) {
    <return-side-panel-mobile
      [text]="'write.go-back' | transloco"
      (click)="hideSidePanel()"
    ></return-side-panel-mobile>
  }

  @if (currentElement?.show) {
    <write-sidebar
      [aiCreditsRemaining]="aiCreditsRemaining$ | async"
      [aiAllergensLoading]="aiAllergensLoading$ | async"
      [aiDescriptionLoading]="aiDescriptionLoading$ | async"
      [aiRecipesLoading]="aiRecipesLoading$ | async"
      [autoRecipes]="autoRecipes()"
      [class.menudish-overview-mobile]="isMobileView"
      [currencySymbol]="menu()?.currency_symbol"
      [currentLocation]="menu()?.location"
      [isTrial]="isTrial()"
      [dish]="currentElement?.data"
      [interfaceLang]="interfaceLang()"
      [lang]="menu()?.base_language"
      [numberOfDishes]="numberOfDishes$ | async"
      [numberOfDishesAdditives]="numberOfDishesAdditives$ | async"
      [profileComplete]="profileComplete()"
      [recipes]="recipes$ | async | copyDeep"
      [similarDishes]="similarDishesAllergens$ | async"
      [similarDishesAdditives]="similarDishesAdditives$ | async"
      [spellcheckItem]="spellcheckItem()"
      [user]="user()"
      [userSettings]="user()?.settings"
      [backgroundImages]="backgroundImages() | slice: 0 : 3"
      (uploadBackgroundImage)="
        uploadBackgroundImage(currentElement.data, $event)
      "
      (uploadBackgroundImageOtherTab)="
        uploadBackgroundImage(currentElement.data, $event, true)
      "
      (showAllBackgrounds)="showAllBackgrounds($event)"
      (addRecipe)="addRecipe($event)"
      (addWord)="addWordToDictionary($event)"
      (uploadDishImage)="uploadDishImage($event)"
      (changeItemField)="changeItemField($event)"
      (changeMenudishField)="changeMenuDishField($event)"
      (clearSelectedDish)="resetCurrentElement()"
      (createRecipe)="createRecipe($event)"
      (fetchDishRecipes)="fetchDishRecipes($event)"
      (fetchSpellcheck)="fetchSpellcheck.emit($event)"
      (ignoreWord)="ignoreWord()"
      (loadMoreDishes)="fetchNextDishes($event)"
      (modalOpened)="showModal($event)"
      (optionChanged)="addOption($event)"
      (otherChanged)="changeMenuDish($event)"
      (patchRecipe)="patchRecipe($event)"
      (refreshDish)="refreshDish($event)"
      (removeRecipe)="removeRecipe($event)"
      (searchRecipe)="searchRecipe($event)"
      (synchroniseRecipeDeclarations)="synchroniseRecipeDeclarations($event)"
      (addIngredientEvent)="addIngredient($event)"
      (deleteIngredientEvent)="deleteIngredient($event)"
      (searchIngredients)="searchIngredients($event)"
      (selectedIngredientEvent)="selectedIngredient($event)"
      (createIngredientEvent)="createIngredientEvent($event)"
      (updateRecipeIngredientEvent)="updateRecipeIngredientEvent($event)"
      (updateIngredientEvent)="updateIngredientEvent($event)"
      (generateAiAllergens)="generateAiAllergens($event)"
      (generateAiDescription)="generateAiDescription($event)"
      (generateAiRecipes)="generateAiRecipes($event)"
    ></write-sidebar>
  } @else {
    <div class="sidebar-intro">
      {{ 'write.blocks.sidebar.intro' | transloco }}
    </div>
    <menu-actions
      #menuActionComponent
      [aiAnalysisLoading]="aiAnalysisLoading$ | async"
      [aiCreditsRemaining]="aiCreditsRemaining$ | async"
      [isTrial]="isTrial()"
      [profileComplete]="profileComplete()"
      [lang]="interfaceLang()"
      [loading]="menuDishesLoading()"
      [locations]="locations$ | async"
      [locationGroups]="locationGroups$ | async"
      [menu]="menu()"
      [menuwritingVideo]="lang && menuwritingVideo[lang]"
      [privileges]="privileges()"
      [translations]="translations"
      [user]="user()"
      (bulkPriceChange)="bulkPriceChange($event)"
      (changeDate)="changeDate($event)"
      (presetActionEvent)="presetAction.emit($event)"
      (changeMenuSettings)="changeMenuSettings($event)"
      (changeLocation)="changeLocation($event)"
      (changeLocationGroup)="changeLocationGroup($event)"
      (grammarCheck)="grammarCheck()"
      (refreshMenuContent)="openRegenerateDialg()"
      (showMenuAnalysis)="showMenuAnalysis.emit($event)"
      (startTour)="initTour()"
      (updateMenu)="patchMenu.emit($event)"
      (dataExport)="dataExport.emit(menu())"
      (dataImport)="dataImport.emit(menu())"
    ></menu-actions>
  }
</ng-template>

<tour-step-template></tour-step-template>
