import {
  Component,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  input,
} from '@angular/core';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { Subscription } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { StopPropagationDirective } from '../../Directives/stop-propagation/stop-propagation.directive';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'grammar-check-button',
  templateUrl: './grammar-check-button.component.html',
  styleUrls: ['./grammar-check-button.component.scss'],
  imports: [MatButtonModule, StopPropagationDirective, MatIconModule],
})
export class GrammarCheckButtonComponent implements OnChanges {
  private utils = inject(UtilsService);

  readonly fontSize = input<string>(undefined);
  readonly value = input<string>(undefined);
  readonly clicked = output<Event>();

  leftOffset = 0;
  subscription: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      this.calcLeftOffset();
    }
  }

  calcLeftOffset(): void {
    const value = this.value();
    this.leftOffset = value
      ? this.utils.calcWidthOfString(value, this.fontSize())
      : 0;
  }
}
