import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { SimpleMenu } from 'src/app/shared/Models/menu';

@Component({
  selector: 'quick-overview-item',
  template: `{{ menu()?.name }}`,
  styles: [
    `
      :host.select {
        font-weight: 400;
      }

      :host {
        display: inline-flex;
        margin-right: 10px;
        padding: 10px;
        cursor: pointer;
        background-color: none;
        border-bottom: none;
        color: rgb(51, 51, 51);
        font-size: 15px;
        font-weight: 300;
        white-space: nowrap;
      }
      :host::first-letter {
        text-transform: uppercase;
      }
      @media only screen and (max-width: 768px) {
        :host {
          display: block;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class QuickOverviewItemComponent {
  readonly menu = input<SimpleMenu>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
}
