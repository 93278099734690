<mat-form-field
  class="item-input"
  [class.opacity]="opacity()"
  [color]="
    control.errors && (control.dirty || control.touched) ? 'warn' : 'primary'
  "
  floatLabel="always"
  subscriptSizing="dynamic"
>
  @if (showGrammar) {
    <grammar-check-button
      [value]="control.value"
      fontSize="18px"
      (clicked)="setCurrent.emit($event)"
    ></grammar-check-button>
  }
  <mat-label
    >{{
      item()?.id && item().category !== 'dow' && item().category !== 'sec'
        ? placeholderName
        : item()?.category
          ? translations()['dishes.' + item()?.category + '.placeholder'] ||
            ('dishes.' + item()?.category + '.placeholder' | transloco)
          : ''
    }}
    @if (placeholderName) {
      : {{ placeholderName }}
    }
    @if (isExtendedLang()) {
      ({{ lang() | uppercase }})
    }
  </mat-label>
  <input
    matInput
    #autoTrigger="matAutocompleteTrigger"
    #inputRef
    class="input"
    name="section"
    type="text"
    [formControl]="control"
    [matAutocomplete]="auto"
    (focusin)="focusIn()"
    (focusout)="focusOut()"
    (keydown.enter)="inputRef.blur()"
  />
  <button
    matSuffix
    mat-icon-button
    class="confirm-button"
    (click)="inputRef.blur()"
  >
    <mat-icon>check</mat-icon>
  </button>
</mat-form-field>
@if (item()?.category === 'dow' && date()) {
  <span class="date">{{
    date() | date: 'mediumDate' : undefined : lang()
  }}</span>
}

<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="selectOption($event)"
>
  @for (option of autocompleteOptions; track option) {
    <mat-option
      (mousedown)="wtf($event)"
      [value]="isDay ? option[lang()] : option"
      >{{ option[lang()] }}</mat-option
    >
  }
</mat-autocomplete>

@if (control.errors && (control.dirty || control.touched)) {
  <mat-error class="hints">
    @for (e of ['required', 'minlength', 'maxlength']; track e) {
      @if (control.errors[e]) {
        <span
          >{{ 'dishes.' + item()?.category + '.validation.' + e | transloco }}.
        </span>
      }
    }
  </mat-error>
}
