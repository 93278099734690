import {
  Component,
  EventEmitter,
  OnChanges,
  inject,
  output,
  input,
} from '@angular/core';
import {
  MatExpansionPanel,
  MatExpansionModule,
} from '@angular/material/expansion';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { SimpleLocation } from 'src/app/shared/Models/location';
import { TaskType, OverviewMenu } from 'src/app/shared/Models/menu';
import { Ruleset } from 'src/app/shared/Models/ruleset';
import { Type } from 'src/app/shared/Models/type';
import { User, UserStatusPrivileges } from 'src/app/shared/Models/user';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { LocationsMenuContextItemComponent } from '../../../shared/locations-menu-context-item/locations-menu-context-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { StopPropagationDirective } from '../../../../shared/Directives/stop-propagation/stop-propagation.directive';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, DatePipe } from '@angular/common';

@Component({
  selector: 'menu-overview-item',
  templateUrl: './menu-overview-item.component.html',
  styleUrls: ['./menu-overview-item.component.scss'],
  imports: [
    NgClass,
    MatExpansionModule,
    MatButtonModule,
    RouterLink,
    MatIconModule,
    StopPropagationDirective,
    MatMenuModule,
    MatTooltipModule,
    LocationsMenuContextItemComponent,
    DatePipe,
    TranslocoPipe,
  ],
})
export class MenuOverviewItemComponent implements OnChanges {
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);

  lang: InterfaceLanguage;
  updateParent = new EventEmitter<boolean>();

  readonly consumerTypesByLocation = input<Record<number, Type[]>>(undefined);
  readonly currentMenu = input<OverviewMenu>(undefined);
  readonly dietsLocationsMap = input<Record<number, boolean>>(undefined);
  readonly isChild = input(false);
  readonly isLastChild = input<boolean>(undefined);
  readonly isLastMenu = input<boolean>(undefined);
  readonly locations = input<SimpleLocation[]>(undefined);
  readonly menu = input<OverviewMenu>(undefined);
  readonly privileges = input<UserStatusPrivileges>(undefined);
  readonly rulesets = input<Ruleset[]>(undefined);
  readonly user = input<User>(undefined);
  readonly userStatusMessage = input<string>(undefined);
  readonly changeMenu = output<{
    menu: OverviewMenu;
    featured: boolean;
  }>();
  readonly childCopied = output<OverviewMenu>();
  readonly childCopyAs = output<{
    event: {
      rule: number;
      enable_ordertaking?: boolean;
    };
    menu: OverviewMenu;
  }>();
  readonly childDelete = output<OverviewMenu>();
  readonly childDeleteChildren = output<OverviewMenu>();
  readonly childShowPreview = output<OverviewMenu>();
  readonly copied = output<void>();
  readonly copyWithDiets = output<OverviewMenu>();
  readonly copyWithOptions = output<OverviewMenu>();
  readonly createArchive = output<{
    rulesetId?: number;
    taskType: TaskType;
  }>();
  readonly createMenu = output<{
    id: number;
    entity: 'rule' | 'type';
  }>();
  readonly delete = output<void>();
  readonly deleteChildren = output<OverviewMenu>();
  readonly refreshSubMenu = output<OverviewMenu>();
  readonly regenerateMenus = output<void>();
  readonly shareMenu = output<OverviewMenu>();
  readonly showPreview = output<OverviewMenu>();
  readonly showSnackbarMessageEvent = output<{
    message: string;
  }>();

  showShareMenuOption = false;

  constructor() {
    this.translate.langChanges$.subscribe(this.handleLang);
    this.handleLang();
  }

  ngOnChanges() {
    const locations = this.locations();
    if (locations) {
      const user = this.user();
      const userLocation = user?.location;
      const userRole = user?.role;
      const locationsExcludingUserLocation = userLocation
        ? locations?.filter((item) => item.id !== userLocation)
        : locations;
      if (
        locationsExcludingUserLocation.length > 0 &&
        [0, 1].includes(userRole)
      ) {
        this.showShareMenuOption = true;
      }
    }
  }

  expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
    event.stopPropagation(); // Preventing event bubbling

    if (!this.isExpansionIndicator(event.target as HTMLElement)) {
      // matExpansionPanel.close(); // Here's the magic
    }
  }

  private isExpansionIndicator(target: HTMLElement): boolean {
    const expansionIndicatorClass = 'mat-mdc-expansion-indicator';
    return (
      target.classList && target.classList.contains(expansionIndicatorClass)
    );
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  handleLang = () =>
    (this.lang = this.translate.getActiveLang() as InterfaceLanguage);

  featureThisMenu() {
    const featured = !this.menu().featured;
    this.changeMenu.emit({ menu: this.menu(), featured });
  }

  getAlternativeLanguage = (item: Ruleset) =>
    this.utils.tryGetLabel(item, this.lang).name;

  showSnack() {
    this.showSnackbarMessageEvent.emit({ message: this.userStatusMessage() });
  }
}
