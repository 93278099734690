import { Injectable, isDevMode } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { LOCAL_STORAGE_KEY } from 'src/app/app.config';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  getLoggedIn = (): Observable<boolean> =>
    of(!!localStorage.getItem(LOCAL_STORAGE_KEY));

  setLogin = (token = '') => {
    localStorage.setItem(LOCAL_STORAGE_KEY, token);
  };

  getToken = (): string | undefined => localStorage.getItem(LOCAL_STORAGE_KEY);

  setLogout = (): void => {
    localStorage.clear();
    if (!isDevMode()) Sentry.setUser(null);
  };
}
