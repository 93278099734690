import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { InterfaceLanguage } from '../constants/languages';
import { GeneralFormErrors } from '../Models/authentication';
import { Additive, Allergen, Label } from '../Models/declarations';
import { Diet } from '../Models/diet';
import {
  CondensedDish,
  SimpleDishAdditives,
  SimpleDishAllergens,
} from '../Models/dish';
import { Ingredient } from '../Models/ingredients';
import { LocationGroup, SimpleLocation } from '../Models/location';
import { MTFont } from '../Models/mtfont';
import { OnboardingTemplate } from '../Models/onboarding_template';
import { NO_PARTNER, Partner } from '../Models/partners';
import { Recipe } from '../Models/recipe';
import { Ruleset } from '../Models/ruleset';
import { CondensedSeparator } from '../Models/separator';
import { Layout } from '../Models/template';
import { Type } from '../Models/type';
import { Task, UserModule } from '../Models/user';
import * as SharedActions from './shared.actions';
import { SimpleCollection, SimpleIntegration } from '../Models/integration';

const adapter: EntityAdapter<OnboardingTemplate> =
  createEntityAdapter<OnboardingTemplate>();

const rulesAdapter: EntityAdapter<Ruleset> = createEntityAdapter<Ruleset>();

const userModuleAdapter: EntityAdapter<UserModule> =
  createEntityAdapter<UserModule>({
    selectId: (userModule) => userModule.module,
  });

interface SharedState {
  allLocations: SimpleLocation[];
  allLocationsGroups: LocationGroup[];
  allLocationsGrouped: Record<string, SimpleLocation[]>;
  currentLocation: number;
  formErrors: GeneralFormErrors;
  interfaceLanguage: InterfaceLanguage;
  sidePanelOpen: boolean;
  templates: EntityState<OnboardingTemplate>;
  templatesLocation: OnboardingTemplate[];
  templatesLoading: boolean;
  templatesDefault: OnboardingTemplate[];
  layoutsOrdertaking: Layout[];
  templatesUsed: OnboardingTemplate[];
  partner: Partner;
  rulesets: EntityState<Ruleset>;
  recipesSidenavTopOffset: number;
  showGlobalSpinner: boolean;
  fonts: MTFont[];
  dishesAutocomplete: CondensedDish[];
  userModules: EntityState<UserModule>;
  userModulesLoaded: boolean;
  ingredientsAutocomplete: Ingredient[];
  allergens: Allergen[];
  additives: Additive[];
  labels: Label[];
  tasks: Task[];
  sectionsAutocomplete: CondensedSeparator[];
  previewHtml: string;
  previewPdfHtml: string;
  diets: Diet[];
  types: Type[];
  recipesAutocomplete: Recipe[];
  dishesSimilar: {
    similar_allergens: SimpleDishAllergens[];
    similar_additives: SimpleDishAdditives[];
    similar_allergens_url_next: string | null;
    similar_additives_url_next: string | null;
    similar_allergens_count: number;
    similar_additives_count: number;
  };
  initialMenuDataLoaded: boolean;
  collections: SimpleCollection[];
  links: SimpleIntegration[];
}

const initialState: SharedState = {
  allLocations: null,
  allLocationsGroups: null,
  allLocationsGrouped: null,
  currentLocation: null,
  formErrors: null,
  interfaceLanguage: null,
  sidePanelOpen: false,
  templates: adapter.getInitialState(),
  templatesLocation: null,
  templatesLoading: false,
  templatesDefault: null,
  layoutsOrdertaking: [],
  templatesUsed: [],
  partner: NO_PARTNER,
  rulesets: rulesAdapter.getInitialState(),
  recipesSidenavTopOffset: 0,
  showGlobalSpinner: false,
  fonts: null,
  dishesAutocomplete: [],
  userModules: userModuleAdapter.getInitialState(),
  userModulesLoaded: false,
  ingredientsAutocomplete: [],
  allergens: [],
  additives: [],
  labels: [],
  tasks: [],
  sectionsAutocomplete: [],
  previewHtml: null,
  previewPdfHtml: null,
  diets: [],
  types: [],
  recipesAutocomplete: [],
  dishesSimilar: {
    similar_allergens: [],
    similar_additives: [],
    similar_allergens_url_next: '',
    similar_additives_url_next: '',
    similar_allergens_count: 0,
    similar_additives_count: 0,
  },
  initialMenuDataLoaded: false,
  collections: [],
  links: [],
};

const reducer = createReducer(
  initialState,
  on(SharedActions.setCurrentLocation, (state, { currentLocation }) => ({
    ...state,
    currentLocation: currentLocation,
  })),
  on(SharedActions.setInterfaceLang, (state, { lang }) => ({
    ...state,
    interfaceLanguage: lang,
  })),
  on(SharedActions.setSidePanelOpen, (state, { open }) => ({
    ...state,
    sidePanelOpen: open,
  })),
  on(SharedActions.setAllLocations, (state, { payload }) => {
    let groupedLocations: Record<string, SimpleLocation[]> = null;
    if (
      state.allLocations &&
      state.allLocationsGroups?.length &&
      payload?.length
    ) {
      const mapping: Record<number, string> = {
        [null as number]: '-',
      };
      state.allLocationsGroups.forEach((g) => (mapping[g.id] = g.name));
      groupedLocations = state.allLocations.reduce(
        (r, a) => {
          const groupName = mapping[a.location_group] || null;
          if (!r[groupName]) r[groupName] = [];
          r[mapping[a.location_group]].push(a);
          return r;
        },
        Object.create(null) as Record<string, SimpleLocation[]>,
      );
    }
    return {
      ...state,
      allLocations: payload,
      allLocationsGrouped: groupedLocations,
    };
  }),
  on(SharedActions.setAllLocationGroups, (state, { payload }) => {
    let groupedLocations: Record<string, SimpleLocation[]> = null;
    if (state.allLocations?.length && payload?.length) {
      const mapping: Record<number, string> = {
        [null as number]: '-',
      };
      payload.forEach((g) => (mapping[g.id] = g.name));
      groupedLocations = state.allLocations.reduce(
        (r, a) => {
          const groupName = mapping[a.location_group] || null;
          if (!r[groupName]) r[groupName] = [];
          r[mapping[a.location_group]].push(a);
          return r;
        },
        Object.create(null) as Record<string, SimpleLocation[]>,
      );
    }
    return {
      ...state,
      allLocationsGroups: payload,
      allLocationsGrouped: groupedLocations,
    };
  }),
  on(SharedActions.setTemplates, (state, { data }) => ({
    ...state,
    templates: adapter.setAll(data, state.templates),
    templatesDefault: data.filter((ot) => ot.is_default),
  })),
  on(SharedActions.setTemplatesLocation, (state, { data }) => ({
    ...state,
    templatesLocation: data,
  })),
  on(SharedActions.setTemplatesState, (state, { loading }) => ({
    ...state,
    templatesLoading: loading,
  })),
  on(SharedActions.addTemplate, (state, { template }) => ({
    ...state,
    templates: adapter.addOne(template, state.templates),
    templatesDefault: [template, ...state.templatesDefault],
  })),
  on(SharedActions.removeTemplate, (state, { id }) => ({
    ...state,
    templates: adapter.removeOne(id, state.templates),
    templatesDefault: state.templatesDefault.filter((ot) => ot.id !== id),
  })),
  on(SharedActions.setOrderTakingLayouts, (state, { payload }) => {
    return {
      ...state,
      layoutsOrdertaking: payload,
    };
  }),
  on(SharedActions.setUsedTemplates, (state, { payload }) => {
    return {
      ...state,
      templatesUsed: payload,
    };
  }),
  on(SharedActions.setFieldErrors, (state, { payload }) => ({
    ...state,
    formErrors: { ...state.formErrors, ...payload },
  })),
  on(SharedActions.setFormErrors, (state, { error }) => {
    const formErrors = state?.formErrors;
    const nonFieldErrors = formErrors?.non_field_errors;
    let newAuthErrors: GeneralFormErrors;

    if (!formErrors) newAuthErrors = {};
    else newAuthErrors = { ...formErrors };

    if (!nonFieldErrors) newAuthErrors.non_field_errors = [error];
    else newAuthErrors.non_field_errors = [...nonFieldErrors, error];

    return {
      ...state,
      formErrors: newAuthErrors,
    };
  }),
  on(SharedActions.setPartner, (state, { partner }) => ({
    ...state,
    partner,
  })),
  on(SharedActions.setRules, (state, { payload }) => {
    return {
      ...state,
      rulesets: rulesAdapter.setAll(payload.results, state.rulesets),
    };
  }),
  on(SharedActions.setRecipesSidenavTopOffset, (state, { offset }) => {
    return {
      ...state,
      recipesSidenavTopOffset: offset,
    };
  }),
  on(SharedActions.setGlobalSpinner, (state, { value }) => {
    return {
      ...state,
      showGlobalSpinner: value,
    };
  }),
  on(SharedActions.setFontsAsFilter, (state, { fonts }) => {
    return {
      ...state,
      fonts: fonts,
    };
  }),
  on(SharedActions.setDishesAutocomplete, (state, { payload }) => {
    return {
      ...state,
      dishesAutocomplete: payload?.results,
    };
  }),
  on(SharedActions.setAutocompleteSeparator, (state, { payload }) => {
    return {
      ...state,
      sectionsAutocomplete: payload,
    };
  }),
  on(SharedActions.setIngredientsAutocomplete, (state, { payload }) => {
    return {
      ...state,
      ingredientsAutocomplete: payload.results,
    };
  }),
  on(SharedActions.setUserModules, (state, { userModules }) => ({
    ...state,
    userModules: userModuleAdapter.setAll(userModules, state.userModules),
  })),
  on(SharedActions.setUserModulesLoaded, (state) => ({
    ...state,
    userModulesLoaded: true,
  })),
  on(SharedActions.userModuleDisabled, (state, { module }) => ({
    ...state,
    userModules: userModuleAdapter.removeOne(module.module, state.userModules),
  })),
  on(SharedActions.userModuleEnabled, (state, { module }) => ({
    ...state,
    userModules: userModuleAdapter.addOne(module, state.userModules),
  })),
  on(SharedActions.userModuleUpdated, (state, { module }) => ({
    ...state,
    userModules: userModuleAdapter.updateOne(
      {
        id: module.module,
        changes: module,
      },
      state.userModules,
    ),
  })),
  on(SharedActions.setAllergens, (state, { result }) => {
    return {
      ...state,
      allergens: result,
    };
  }),
  on(SharedActions.setAdditives, (state, { result }) => {
    return {
      ...state,
      additives: result,
    };
  }),
  on(SharedActions.setLabels, (state, { result }) => {
    return {
      ...state,
      labels: result,
    };
  }),
  on(SharedActions.setTasks, (state, { tasks }) => {
    return {
      ...state,
      tasks: tasks,
    };
  }),
  on(SharedActions.setPreviewHtml, (state, { html }) => {
    return {
      ...state,
      previewHtml: html,
    };
  }),
  on(SharedActions.clearPreviewHtml, (state) => {
    return {
      ...state,
      previewHtml: null,
    };
  }),

  on(SharedActions.setPreviewPdfHtml, (state, { html }) => {
    return {
      ...state,
      previewPdfHtml: html,
    };
  }),
  on(SharedActions.clearPreviewPdfHtml, (state) => {
    return {
      ...state,
      previewPdfHtml: null,
    };
  }),

  on(SharedActions.setDiets, (state, { diets }) => {
    return {
      ...state,
      diets,
    };
  }),
  on(SharedActions.setTypes, (state, { data }) => {
    return {
      ...state,
      types: data,
    };
  }),
  on(SharedActions.setRecipes, (state, { recipes }) => {
    return {
      ...state,
      recipesAutocomplete: recipes,
    };
  }),
  on(SharedActions.setSimilarDishesAdditives, (state, { result }) => {
    return {
      ...state,
      dishesSimilar: {
        ...state.dishesSimilar,
        similar_additives: result.results,
        similar_additives_url_next: result.next,
        similar_additives_count: result.count,
      },
    };
  }),
  on(SharedActions.setSimilarDishesAllergens, (state, { result }) => {
    return {
      ...state,
      dishesSimilar: {
        ...state.dishesSimilar,
        similar_allergens: result.results,
        similar_allergens_url_next: result.next,
        similar_allergens_count: result.count,
      },
    };
  }),

  on(SharedActions.addMoreDishes, (state, { dishes, nextUrl }) => {
    return {
      ...state,
      dishesSimilar: {
        ...state.dishesSimilar,
        similar_allergens: state.dishesSimilar.similar_allergens.concat(dishes),
        similar_allergens_url_next: nextUrl,
      },
    };
  }),
  on(SharedActions.addMoreDishesAdditives, (state, { dishes, nextUrl }) => {
    return {
      ...state,
      dishesSimilar: {
        ...state.dishesSimilar,
        similar_additives: state.dishesSimilar.similar_additives.concat(dishes),
        similar_additives_url_next: nextUrl,
      },
    };
  }),
  on(SharedActions.clearSimilarDishes, (state) => {
    return {
      ...state,
      dishesSimilar: initialState.dishesSimilar,
    };
  }),
  on(SharedActions.clearRecipesAutocomplete, (state) => {
    return {
      ...state,
      recipesAutocomplete: [],
    };
  }),
  on(SharedActions.setInitialMenuDataLoaded, (state, { value }) => {
    return {
      ...state,
      initialMenuDataLoaded: value,
    };
  }),
  on(SharedActions.clearData, () => {
    return {
      ...initialState,
    };
  }),
  on(SharedActions.setCollections, (state, { collections }) => {
    return {
      ...state,
      collections,
    };
  }),
  on(SharedActions.setLinks, (state, { links }) => {
    return {
      ...state,
      links,
    };
  }),
);

export const sharedFeature = createFeature({
  name: 'shared',
  reducer,
  extraSelectors: ({
    selectDishesSimilar,
    selectRulesets,
    selectTemplates,
    selectTemplatesLocation,
    selectUserModules,
  }) => ({
    selectSharedRulesets: createSelector(
      selectRulesets,
      rulesAdapter.getSelectors().selectAll,
    ),
    selectCurrentTemplates: createSelector(
      selectTemplates,
      selectTemplatesLocation,
      (allTemplates, locationTemplates) =>
        locationTemplates === null
          ? adapter.getSelectors().selectAll(allTemplates)
          : locationTemplates,
    ),
    selectTemplateAll: createSelector(
      selectTemplates,
      adapter.getSelectors().selectAll,
    ),
    selectUserModulesAll: createSelector(
      selectUserModules,
      userModuleAdapter.getSelectors().selectAll,
    ),
    selectUserModulesActive: createSelector(selectUserModules, (userModules) =>
      userModuleAdapter
        .getSelectors()
        .selectAll(userModules)
        .filter((m) => m.active),
    ),
    selectUserModulesActiveCodes: createSelector(
      selectUserModules,
      (userModules) =>
        userModuleAdapter
          .getSelectors()
          .selectAll(userModules)
          .filter((m) => m.active)
          .map((m) => m.module_detail.code),
    ),
    selectUserModulesHasActiveModule: (code: string) =>
      createSelector(selectUserModules, (userModules) =>
        userModuleAdapter
          .getSelectors()
          .selectAll(userModules)
          .filter((m) => m.active)
          .some((m) => m.module_detail.code === code),
      ),
    selectSimilarDishesAllergens: createSelector(
      selectDishesSimilar,
      (dishesSimilar) => dishesSimilar?.similar_allergens,
    ),
    selectSimilarDishesAdditives: createSelector(
      selectDishesSimilar,
      (dishesSimilar) => dishesSimilar?.similar_additives,
    ),
    selectSimilarDishesAllergensCount: createSelector(
      selectDishesSimilar,
      (dishesSimilar) => dishesSimilar?.similar_allergens_count,
    ),
    selectSimilarDishesAdditivesCount: createSelector(
      selectDishesSimilar,
      (dishesSimilar) => dishesSimilar?.similar_additives_count,
    ),
    selectSimilarDishesAllergensNextPageUrl: createSelector(
      selectDishesSimilar,
      (dishesSimilar) => dishesSimilar?.similar_allergens_url_next,
    ),
    selectSimilarDishesAdditivesNextPageUrl: createSelector(
      selectDishesSimilar,
      (dishesSimilar) => dishesSimilar?.similar_additives_url_next,
    ),
  }),
});
