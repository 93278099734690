import { Component } from '@angular/core';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'slidedown-bar',
  templateUrl: './slidedown-bar.component.html',
  styleUrls: ['./slidedown-bar.component.css'],
  imports: [TranslocoPipe],
})
export class SlidedownBarComponent {}
