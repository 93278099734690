@if (node().children && node().children.length) {
  <span class="icon-button-density-2">
    <button
      mat-icon-button
      class="action-button expand"
      [disabled]="node().childInCreation"
      [disableRipple]="true"
      (click)="toggleSectionCollapse()"
    >
      <mat-icon class="expand-icon" [class.expanded]="isExpanded()"
        >chevron_right</mat-icon
      >
    </button>
  </span>
}
@if (!searchIsActive()) {
  @if (isSorting() || !isDragable()) {
    <span class="spinner-container dnd-disabled">
      <mat-spinner class="spinner" diameter="20"></mat-spinner>
    </span>
  } @else {
    <span class="icon-button-density-2">
      <button
        mat-icon-button
        class="action-button drag"
        [disabled]="!node().dish?.url"
        [disableRipple]="true"
      >
        <mat-icon aria-hidden="true">open_with </mat-icon>
      </button>
    </span>
  }
}
@if (node().dish?.dish_detail && showNumbering()) {
  <div class="numbering-block simple" [class.product-hidden]="false">
    <mat-form-field
      class="numbering-input"
      floatLabel="always"
      subscriptSizing="dynamic"
    >
      <mat-label>{{
        translations()['dishes.numbering.placeholder'] ||
          ('dishes.numbering.placeholder' | transloco)
      }}</mat-label>
      <input
        matInput
        type="number"
        lang="en-150"
        maxlength="4"
        min="0"
        max="9999"
        class="input"
        autocomplete="off"
        name="numbering"
        #numberingModel="ngModel"
        [disabled]="!node().dish.url"
        [(ngModel)]="node().dish.numbering"
        (focusout)="changeNumbering.emit(numberingModel)"
      />
    </mat-form-field>
  </div>
}
<div class="menu-dish-item" [attr.dir]="rtl ? 'rtl' : 'ltr'">
  @if (node()?.dish?.dish_detail) {
    <dish-item
      [item]="node().dish.dish_detail"
      [isExtendedLang]="isExtendedLang()"
      [focused]="node().focused"
      [lang]="lang()"
      [node]="node()"
      [opacity]="false"
      [same]="autocompleteDishes()"
      [subscriptionType]="user()?.status?.subscription_type"
      [translations]="translations()"
      (autoSelect)="autocomplete.emit($event)"
      (changeItem)="changeDishItem($event)"
      (clearAutocomplete)="clearAutocomplete.emit('dish')"
      (fieldFocusChanged)="changeDraggable.emit($event)"
      (getSame)="getSame.emit($event)"
      (focusedEvent)="lineFocused.emit($event)"
      (setCurrent)="setCurrentElement.emit($event)"
    >
    </dish-item>
  }
  @if (
    node()?.dish?.separator_detail &&
    (node().dish?.separator_detail.category === 'opt' ||
      node().dish?.separator_detail.category === 'cou')
  ) {
    <separator-item [lang]="lang()" [separator]="node().dish.separator_detail">
    </separator-item>
  }
  @if (
    node().dish?.separator_detail &&
    node().dish?.separator_detail.category !== 'opt' &&
    node().dish?.separator_detail.category !== 'cou'
  ) {
    <section-item
      [date]="node().dish.date"
      [item]="node().dish.separator_detail"
      [isExtendedLang]="isExtendedLang()"
      [focused]="node().focused"
      [lang]="lang()"
      [node]="node()"
      [opacity]="false"
      [same]="autocompleteSections()"
      [translations]="translations()"
      (autoSelect)="autocomplete.emit($event)"
      (changeItem)="changeSectionItem($event)"
      (clearAutocomplete)="clearAutocomplete.emit('separator')"
      (fieldFocusChanged)="changeDraggable.emit($event)"
      (getSame)="getSameSection.emit($event)"
      (focusedEvent)="lineFocused.emit($event)"
      (setCurrent)="setCurrentElement.emit($event)"
    >
    </section-item>
  }
  @if (
    inProgress() &&
    !(
      node()?.dish?.separator_detail &&
      (node().dish?.separator_detail.category === 'opt' ||
        node().dish?.separator_detail.category === 'cou')
    )
  ) {
    <mat-progress-bar
      class="progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
  }
</div>
<div class="item-actions icon-button-density-2">
  @if (
    !searchIsActive() &&
    node().dish?.separator_detail &&
    node().dish?.separator_detail.category !== 'opt' &&
    node().dish?.separator_detail.category !== 'cou'
  ) {
    <button
      mat-icon-button
      [matMenuTriggerFor]="insertItemMenu()"
      [matMenuTriggerData]="{ node: node() }"
      class="action-button add"
      [disabled]="!node().dish?.url || isSorting() === true"
    >
      <mat-icon>add_circle</mat-icon>
    </button>
  }
  @if (node().dish?.url && !forbidden) {
    <button
      mat-icon-button
      class="action-button detail"
      [disabled]="node().deleted"
      (click)="toggleCurrentElement.emit($event)"
      [tourAnchor]="node().dish?.order === 0 ? 'first-dish' : ''"
    >
      <mat-icon #list [class.row-active]="selected()">list</mat-icon>
    </button>
  }
  <button
    #deleteButton
    mat-icon-button
    [disabled]="
      node().deleted ||
      isSorting() ||
      !isDragable() ||
      (inProgress() &&
        !(
          node()?.dish?.separator_detail &&
          (node().dish?.separator_detail.category === 'opt' ||
            node().dish?.separator_detail.category === 'cou')
        ))
    "
    (click)="delete.emit()"
    class="action-button delete"
  >
    <mat-icon>delete</mat-icon>
  </button>
</div>
