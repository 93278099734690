import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteTrigger,
  MatAutocompleteModule,
} from '@angular/material/autocomplete';
import { days } from 'src/app/shared/constants/days';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { ChangeDishOptions } from 'src/app/shared/Models/menudish';
import { BaseNameExtended } from 'src/app/shared/Models/models';
import { CondensedSeparator, Separator } from 'src/app/shared/Models/separator';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { isString } from 'lodash-es';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  tap,
} from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { UpperCasePipe, DatePipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { GrammarCheckButtonComponent } from '../../../../../shared/Components/grammar-check-button/grammar-check-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Categories } from 'src/app/shared/constants/categories';
import { MenuDishNode } from 'src/app/shared/Models/menu-dish-node';

@Component({
  selector: 'section-item',
  templateUrl: './section-item.component.html',
  styleUrls: ['./section-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    GrammarCheckButtonComponent,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatOptionModule,
    UpperCasePipe,
    DatePipe,
    TranslocoPipe,
  ],
})
export class SectionItemComponent implements OnChanges, OnInit {
  element = inject(ElementRef);
  private destroyRef = inject(DestroyRef);
  private utils = inject(UtilsService);

  readonly date = input<string>(undefined);
  readonly focused = input<boolean>(undefined);
  readonly isExtendedLang = input<boolean>(undefined);
  readonly item = input<Separator>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly node = input<MenuDishNode>(undefined);
  readonly opacity = input<boolean>(undefined);
  readonly same = input<CondensedSeparator[]>(undefined);
  readonly translations = input<object>(undefined);

  readonly autoSelect = output<{
    item: CondensedSeparator;
    node: MenuDishNode;
    isDish: boolean;
  }>();
  readonly changeItem = output<{
    item: Separator;
    node: MenuDishNode;
    options: ChangeDishOptions;
  }>();
  readonly clearAutocomplete = output<void>();
  readonly fieldFocusChanged = output<boolean>();
  readonly focusedEvent = output<boolean>();
  readonly getSame = output<string>();
  readonly setCurrent = output<Event>();

  autocompleteOptions: BaseNameExtended[] = [];
  control = new FormControl('', {
    nonNullable: true,
    validators: [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(200),
    ],
  });
  currentNode: MenuDishNode;
  filteredDays: BaseNameExtended[] = [];
  inputFocused: boolean;
  isDay = false;
  placeholderName: string;
  showGrammar: boolean;
  skipSave = false;

  readonly auto = viewChild<MatAutocompleteTrigger>('autoTrigger');
  readonly inputRef = viewChild<ElementRef>('inputRef');

  ngOnChanges(changes: SimpleChanges) {
    if ('node' in changes) {
      this.auto()?.closePanel();
    }
    if ('item' in changes) {
      this.skipSave = false;
      if (
        changes.item.previousValue &&
        changes.item.currentValue.category !==
          changes.item.previousValue?.category
      ) {
        this.clearAutocomplete.emit();
      }
      this.isDay = this.item().category === Categories.DAY;
    }
    const same = this.same();
    if ('same' in changes && !this.isDay && same) {
      this.autocompleteOptions = same;
    }
    const item = this.item();
    if (item && !this.isDay) {
      this.showGrammar = !!item?.[`spellcheck_` + this.lang()];
    }
    if (
      this.control &&
      (('item' in changes && !this.inputFocused) ||
        ('lang' in changes && !changes.lang.firstChange))
    ) {
      this.changeInputValue();
    }
    if (this.control && 'item' in changes) this.control.enable();
    if ('focused' in changes) setTimeout(() => this.changeInputFocus());
  }

  ngOnInit() {
    this.utils.blurInput
      .pipe(
        filter((v) => !!v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (!this.inputFocused) return undefined;
        this.inputRef().nativeElement.blur();
        this.utils.blurInput.next(false);
      });
    this.filteredDays = [...days];
    this.control.valueChanges
      .pipe(
        tap(() => (this.currentNode = this.node())),
        debounceTime(300),
        filter((v) => v !== null),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value: string | BaseNameExtended) => {
        if (this.inputFocused && !this.skipSave) {
          if (!isString(value)) value = value[this.lang()];
          if (this.isDay) {
            this.filterDays(value);
          } else if (value.length >= 2) {
            this.getSame.emit(value);
          }
        }
      });
  }

  changeInputFocus(): void {
    const inputRef = this.inputRef();
    if (!inputRef || !inputRef.nativeElement) return undefined;
    const focused = this.focused();
    if (focused && !this.inputFocused) {
      inputRef.nativeElement.focus({ preventScroll: true });
    } else if (!focused && this.inputFocused) {
      inputRef.nativeElement.blur();
    }
  }

  changeInputValue(): void {
    const item = this.item();
    const lang = this.lang();
    if (!this.item()[this.lang()]) {
      this.control.reset(null, { emitEvent: false });
      const item = this.item();
      if (item.id) {
        const { name } = this.utils.tryGetLabel(item, this.lang());
        this.placeholderName = name;
      }
    } else if (item[lang] !== this.control.value) {
      this.control.setValue(item[lang], {
        emitEvent: false,
      });
    }
  }

  displayWith = (value: string | BaseNameExtended | Separator): string => {
    if (isString(value)) return value;
    return value ? value[this.lang()] : '';
  };

  filterDays(value: string): void {
    if (value.length >= 2) {
      this.autocompleteOptions = days.filter((day) =>
        day[this.lang()].includes(value),
      );
      if (this.autocompleteOptions.some((d) => d[this.lang()] === value)) {
        this.inputRef().nativeElement.blur();
        setTimeout(() => this?.auto()?.closePanel(), 0);
      }
    } else {
      this.autocompleteOptions = [...days];
    }
    this.control.updateValueAndValidity();
  }

  focusIn(): void {
    this.focusedEvent.emit(true);
    this.fieldFocusChanged.emit(false);
    this.inputFocused = true;
  }

  focusOut(): void {
    if (this.skipSave) return undefined;
    this.focusedEvent.emit(false);
    this.submit();
    this.fieldFocusChanged.emit(true);
    this.inputFocused = false;
    this.auto()?.closePanel();
  }

  getOptions = (): (BaseNameExtended | Separator)[] =>
    this.isDay ? this.filteredDays : this.same();

  selectOption({
    option: { value },
  }: {
    option: { value: CondensedSeparator | string };
  }): void {
    if (!value) return undefined;
    this.skipSave = true;
    this.auto()?.closePanel();
    const lang = this.lang();
    this.control.setValue(this.isDay ? value : value[lang], {
      emitEvent: false,
    });
    this.inputRef().nativeElement.blur();
    this.control.disable();
    this.focusedEvent.emit(false);
    this.fieldFocusChanged.emit(true);
    if (this.isDay) {
      const newItem = {
        ...this.item(),
        [lang]: this.control.value,
      };
      this.changeItem.emit({
        item: newItem,
        node: this.currentNode ?? this.node(),
        options: { onlyName: true },
      });
    } else {
      this.autoSelect.emit({
        item: value as CondensedSeparator,
        node: this.currentNode ?? this.node(),
        isDish: false,
      });
    }
  }

  submit(): void {
    this.auto()?.closePanel();
    if (!this.control.value) this.control.reset(null, { emitEvent: false });
    if (
      !this.control.value ||
      this.control.value === this.item()[this.lang()]
    ) {
      return undefined;
    }
    this.isDay ? this.submitDay() : this.submitSection();
  }

  submitDay(): void {
    if (this.filteredDays.some((d) => d[this.lang()] === this.control.value)) {
      const newItem = {
        ...this.item(),
        [this.lang()]: this.control.value,
      };
      this.changeItem.emit({
        item: newItem,
        node: this.currentNode ?? this.node(),
        options: { onlyName: true },
      });
    } else {
      this.item()[this.lang()] = '';
      this.control.patchValue('');
    }
  }

  submitSection(): void {
    const newItem = {
      ...this.item(),
      [this.lang()]: this.control.value,
    };
    this.clearAutocomplete.emit();
    if (
      !(this.control.errors && (this.control.dirty || this.control.touched))
    ) {
      this.changeItem.emit({
        item: newItem,
        node: this.currentNode ?? this.node(),
        options: { onlyName: true },
      });
    }
  }

  wtf(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
  }
}
