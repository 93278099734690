import { UserOnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  Component,
  ChangeDetectionStrategy,
  output,
  input,
} from '@angular/core';
import { Status, User } from 'src/app/shared/Models/user';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { TranslocoPipe } from '@jsverse/transloco';
import { PlanLimitComponent } from '../../shared/Components/plan-limit/plan-limit.component';
import { MatCardModule } from '@angular/material/card';
import { StatusBlockComponent } from '../../shared/Components/status-block/status-block.component';

@Component({
  selector: 'app-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    StatusBlockComponent,
    MatCardModule,
    PlanLimitComponent,
    TranslocoPipe,
  ],
})
export class UserStatusComponent {
  readonly complete = input<boolean>(undefined);
  readonly isLoading = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly menusCreated = input<{
    this_month: number;
    all_time: number;
    this_week: number;
    this_year: number;
  }>(undefined);
  readonly status = input<Status>(undefined);
  readonly user = input<User>(undefined);
  readonly userOnboardingTemplates = input<UserOnboardingTemplate[]>(undefined);
  readonly statusMessages = input<AccountStatusMessage>(undefined);

  readonly createMenuFromOnboardingTemplate = output<{
    date: Date;
    template: number;
  }>();
}
