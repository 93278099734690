<div class="button-wrapper" [class.event]="template().show_from">
  <button
    class="create-menu-item"
    mat-menu-item
    [disabled]="disabled()"
    [mtTooltip]="disabled() ? statusMessage() : ''"
    (click)="showDatepicker.emit(template())"
  >
    <mat-icon aria-hidden="true" class="icons start">{{
      template()?.icon
    }}</mat-icon>
    {{ template()['default_' + lang()] || template()[lang()] }}
  </button>
  <mat-icon aria-hidden="true" class="icons end">keyboard_arrow_right</mat-icon>
</div>
