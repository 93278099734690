import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'spinner',
  template: `
    <div
      class="loader"
      [style.width.px]="(config()?.radius && config().radius * 2 + 10) || 100"
    >
      <svg class="circular">
        <circle
          class="pathX"
          [style.animation]="
            config()?.color
              ? 'dash 1.5s ease-in-out infinite'
              : 'dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite'
          "
          [style.stroke]="config()?.color"
          [attr.cx]="(config()?.radius && config()?.radius + 5) || 50"
          [attr.cy]="(config()?.radius && config()?.radius + 5) || 50"
          [attr.r]="config()?.radius || 20"
          fill="none"
          [attr.stroke-width]="config()?.border || 2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>
  `,
  styles: [
    `
      .pathX {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
      }

      @-webkit-keyframes rotate {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes rotate {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @-webkit-keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35px;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124px;
        }
      }
      @keyframes dash {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -35px;
        }
        100% {
          stroke-dasharray: 89, 200;
          stroke-dashoffset: -124px;
        }
      }
      @-webkit-keyframes color {
        100%,
        0% {
          stroke: #d62d20;
        }
        40% {
          stroke: #0057e7;
        }
        66% {
          stroke: #008744;
        }
        80%,
        90% {
          stroke: #ffa700;
        }
      }
      @keyframes color {
        100%,
        0% {
          stroke: #d62d20;
        }
        40% {
          stroke: #0057e7;
        }
        66% {
          stroke: #008744;
        }
        80%,
        90% {
          stroke: #ffa700;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SpinnerComponent {
  readonly config = input<{
    radius: number;
    border?: number;
    color?: string;
  }>(undefined);

  parseInt = parseInt;
}
