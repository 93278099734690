<go-back
  class="sidepanel-go-back"
  [text]="'dishes.translation.go-back' | transloco"
  (clicked)="clearSelectedDish.emit()"
></go-back>
@if (currentMenuDish()) {
  <div class="wrapper">
    <side-panel-header
      [lang]="baseLanguage()"
      [menuDish]="currentMenuDish()"
    ></side-panel-header>
    @if (details || (isDescription() && isOther() && isSecondary())) {
      <div class="slider-wrapper">
        <mat-slide-toggle
          [formControl]="translationDisabledControl"
          class="translation-disable"
          color="primary"
        >
          <span
            [class.warn]="details && details?.user_details.translation_disabled"
            >{{ 'dishes.translation.similar.toggle' | transloco }}</span
          >
        </mat-slide-toggle>
      </div>
    }
    @if (showGrammar && spellcheckItem()) {
      <grammar-corrections
        [attr.dir]="rtl ? 'rtl' : 'ltr'"
        [spellcheck]="spellcheckItem()"
        [addBottomSpace]="true"
        (ignoreSpellcheck)="ignoreSpellcheck.emit()"
        (addWord)="addWord($event)"
      ></grammar-corrections>
    }
    @if (
      details &&
      (!details?.user_details.translation_disabled || isDescription())
    ) {
      <div class="info-wrapper">
        <p class="title">
          {{ 'dishes.translation.similar.title' | transloco }}
        </p>
        <div class="tags-wrapper icon-button-density-2">
          @for (tag of getTags(); track tag) {
            <span
              class="simple-tag"
              [class.selected]="tag.selected"
              (click)="changeTag(tag)"
              >{{ tag.value }}</span
            >
          }
          @if (!showMore && tags() && tags().length > 15) {
            <button
              mat-button
              class="more-button tags-button"
              (click)="showMore = true"
            >
              {{ 'translations.show-more' | transloco }}
            </button>
          }
          <button
            mat-icon-button
            class="warn select-all"
            [class.selected-all]="isSelectedAll"
            (click)="selectAll()"
          >
            <mat-icon class="select-all-icon">select_all</mat-icon>
          </button>
        </div>
        @if (!showPlaceholder) {
          <div class="dishes-wrapper">
            <p class="dish-name">
              "{{ getTitle() }}" {{ 'dishes.translation.in' | transloco }}
              {{ 'shared.languages.' + lang() | transloco }}
            </p>
            <div [hidden]="isLoading()">
              @for (dish of dishes(); track dish) {
                <copy-data-line
                  [attr.dir]="rtl ? 'rtl' : 'ltr'"
                  [upperData]="dish[baseLanguage()]"
                  [lowerData]="dish[lang()]"
                  (triggerCopy)="chooseSimilar(dish)"
                ></copy-data-line>
              }
              @if (rest > 0) {
                <button
                  mat-button
                  class="more-button"
                  (click)="fetchMoreItems.emit()"
                  transloco="dishes.translation.more-button"
                  [translocoParams]="{ rest: rest }"
                ></button>
              }
              <div class="google-translate">
                <span>
                  {{ 'dishes.translation.google' | transloco }}
                </span>
                <mat-icon
                  class="translate-icon"
                  (click)="openTranslations.emit(currentMenuDish())"
                >
                  g_translate
                </mat-icon>
              </div>
            </div>
          </div>
        }
        @if (showPlaceholder) {
          <div class="placeholder">
            <p>{{ 'dishes.translation.similar.placeholder' | transloco }}</p>
            <p>
              {{ 'dishes.translation.similar.placeholder-1' | transloco }}
              <mat-icon class="select-all-inline">select_all </mat-icon>
              {{ 'dishes.translation.similar.placeholder-2' | transloco }}
            </p>
          </div>
        }
      </div>
      @if (isLoading()) {
        <div class="spinner-wrapper">
          <spinner></spinner>
        </div>
      }
    }
  </div>
} @else {
  <side-panel-placeholder></side-panel-placeholder>
}
