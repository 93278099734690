import { Component, output, input } from '@angular/core';
import {
  MatCheckboxChange,
  MatCheckboxModule,
} from '@angular/material/checkbox';
import {
  MatSlideToggleChange,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';

@Component({
  selector: 'toggle-item',
  templateUrl: './toggle-item.component.html',
  styles: [
    `
      lock-item {
        display: flex;
      }
    `,
  ],
  imports: [
    MatSlideToggleModule,
    MtTooltipDirective,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    LockItemComponent,
    TranslocoPipe,
  ],
})
export class ToggleItemComponent {
  readonly checkbox = input(false);
  readonly disabled = input(false);
  readonly disabledFields = input<string[]>([]);
  readonly field = input<string>(undefined);
  readonly lockedFields = input<string[]>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly showLock = input<boolean>(undefined);
  readonly updateMenu = output<DeepPartial<Menu>>();

  toggleChanged(
    event: MatSlideToggleChange | MatCheckboxChange,
    fieldName: string,
  ) {
    this.updateMenu.emit({
      style: {
        [fieldName]: event.checked,
      },
    });
  }

  lockChanged() {
    if (!this.menu()) return undefined;
    let value = this.lockedFields() ?? [];
    const field = this.field();
    if (this.lockedFields().includes(field)) {
      value = value.filter((f) => f !== this.field());
    } else {
      value.push(field);
    }
    this.updateMenu.emit({
      style: {
        locked_fields: value,
      },
    });
  }
}
