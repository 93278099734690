import { Component, output, input } from '@angular/core';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { LockItemComponent } from '../lock-item/lock-item.component';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'select-item',
  templateUrl: './select-item.component.html',
  styleUrls: ['./select-item.component.css'],
  imports: [
    MatFormFieldModule,
    MtTooltipDirective,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatOptionModule,
    LockItemComponent,
    TranslocoPipe,
  ],
})
export class SelectItemComponent {
  readonly disabled = input(false);
  readonly disabledFields = input<string[]>([]);
  readonly field = input<string>(undefined);
  readonly hideLabel = input<boolean>(undefined);
  readonly label = input<string>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly list = input<any[]>(undefined);
  readonly lockedFields = input<string[]>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly multiple = input(false);
  readonly placeholder = input<string>(undefined);
  readonly showLock = input<boolean>(undefined);
  readonly showDefaultPlaceholder = input<boolean>(undefined);
  readonly translate = input(true);
  readonly translationKey = input<string>(undefined);

  readonly updateMenu = output<DeepPartial<Menu>>();

  selectChanged(event: MatSelectChange, fieldName: string) {
    const value = event.value;
    this.updateMenu.emit({
      style: {
        [fieldName]: value,
      },
    });
  }
}
