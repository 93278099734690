import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { Menu } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'save-restore',
  templateUrl: './save-restore.component.html',
  styleUrls: ['./save-restore.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuModule, MatButtonModule, TranslocoPipe],
})
export class SaveRestoreComponent {
  readonly disabled = input(false);
  readonly field = input<string>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly restoreDefault = output<string>();
  readonly saveDefault = output<string>();
}
