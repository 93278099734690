<p>{{ 'auth.reset_password.send.title' | transloco }}</p>
<form class="input-block simple-theme" (ngSubmit)="submit()">
  <mat-icon class="icon">email</mat-icon>
  <mat-form-field class="full">
    <mat-label>{{
      'auth.reset_password.send.email.placeholder' | transloco
    }}</mat-label>
    <input
      matInput
      [formControl]="email"
      name="email"
      type="email"
      autocomplete="email"
      required
    />
  </mat-form-field>
</form>
<button
  mat-flat-button
  color="primary"
  class="button-spinner"
  type="submit"
  [disabled]="
    (email.errors && (email.dirty || email.touched)) || (spinnerState$ | async)
  "
  (click)="submit()"
>
  <span class="button-text">
    {{ 'auth.reset_password.send.actions.reset' | transloco }}
    @if (spinnerState$ | async) {
      <spinner class="inner-spinner" [config]="{ radius: 7 }"></spinner>
    }
  </span>
</button>
<div class="links">
  <a routerLink="/login" [queryParams]="{ lang: lang() }">
    {{ 'auth.reset_password.send.actions.go_back' | transloco }}
  </a>
  <a
    routerLink="/registration"
    [queryParams]="{ lang: lang() }"
    class="register-link"
  >
    {{ 'auth.shared.register' | transloco }}
  </a>
</div>
