import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoPipe } from '@jsverse/transloco';
import { Subject } from 'rxjs';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { MtTooltipDirective } from 'src/app/shared/Directives/mt-tooltip/mt-tooltip.directive';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import {
  isRegistrationOption,
  ObservablePortal,
  RegistrationOption,
} from 'src/app/shared/Models/registration-steps';
import { User } from 'src/app/shared/Models/user';
import { RegistrationStepOptionComponent } from '../registration-step-option/registration-step-option.component';
import { NavigationButtonsComponent } from '../navigation-buttons/navigation-buttons.component';

@Component({
  selector: 'app-registration-step-two',
  templateUrl: './registration-step-two.component.html',
  styleUrls: [
    '../registration-step-one/registration-step-one.component.scss',
    './registration-step-two.component.scss',
  ],
  imports: [
    NavigationButtonsComponent,
    ReactiveFormsModule,
    FormsModule,
    RegistrationStepOptionComponent,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    TranslocoPipe,
    MtTooltipDirective,
  ],
})
export class RegistrationStepTwoComponent
  implements AfterViewInit, OnChanges, OnInit
{
  private viewContainerRef = inject(ViewContainerRef);

  readonly isSafari = input<boolean>(undefined);
  readonly lang = input.required<InterfaceLanguage>();
  readonly options = input.required<OnboardingTemplate[]>();
  readonly user = input.required<User>();

  readonly buttonPortal = output<ObservablePortal>();
  readonly fetchTemplates = output<void>();
  readonly requestNewContent = output<string>();
  readonly submitTemplates = output<number[]>();

  readonly buttonPortalContent = viewChild<TemplateRef<void>>('button');

  clickEventsStream = new Subject<true | undefined>();
  enableNextStep = false;
  other: RegistrationOption = {
    icon: 'question_answer',
    other: true,
    active: false,
    payload: '',
  };
  templateOptions: (OnboardingTemplate & { active: boolean })[] = [];
  titleParams: { firstName: string };

  ngOnChanges(changes: SimpleChanges): void {
    const currentUser = this.user();
    if ('user' in changes && !changes.user.firstChange && currentUser) {
      this.fetchTemplates.emit();
    }
    const options = this.options();
    if ('options' in changes && options) {
      this.templateOptions = [
        ...options.map((opt) => ({ ...opt, active: false })),
      ];
    }
  }

  ngOnInit(): void {
    const currentUser = this.user();
    if (!currentUser) return;
    this.titleParams = { firstName: currentUser.first_name };
  }

  ngAfterViewInit(): void {
    const buttonContent = this.buttonPortalContent();
    if (!buttonContent) return;

    this.buttonPortal.emit({
      portal: new TemplatePortal(buttonContent, this.viewContainerRef),
      eventStream: this.clickEventsStream.asObservable(),
    });
  }

  chooseOption(
    option: RegistrationOption | (OnboardingTemplate & { active: boolean }),
    forceState?: boolean,
  ): void {
    if (isRegistrationOption(option)) {
      this.templateOptions.forEach((opt) => {
        opt.active = opt.active || false;
      });
    } else {
      this.other.active = false;
    }
    if (forceState !== undefined) {
      option.active = forceState;
    } else {
      option.active = !option.active;
    }
    this.validateStep();
  }

  next(): void {
    if (this.other.active) {
      this.requestNewContent.emit(this.other.payload);
    } else {
      this.submitTemplates.emit(
        this.templateOptions.filter((opt) => opt.active).map((opt) => opt.id),
      );
    }

    this.clickEventsStream.next(undefined);
  }

  prev(): void {
    this.clickEventsStream.next(true);
  }

  validateStep(): void {
    const selected = this.templateOptions.filter((opt) => opt.active);
    this.enableNextStep = !!(
      (selected && selected.length >= 2) ||
      (this.other.active && this.other.payload)
    );
  }
}
