import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  output,
  viewChild,
  input,
} from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { DeepPartial } from 'src/app/shared/Models/generics';
import { Menu } from 'src/app/shared/Models/menu';
import { MTFont } from 'src/app/shared/Models/mtfont';
import { get } from 'lodash-es';
import { Font, FontInterface, FontPickerDirective } from 'ngx-font-picker';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { ToggleItemComponent } from '../toggle-item/toggle-item.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MtTooltipDirective } from '../../../../../shared/Directives/mt-tooltip/mt-tooltip.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MtColorPickerComponent } from '../../../../../shared/Components/mt-color-picker/mt-color-picker.component';
import { SelectItemComponent } from '../select-item/select-item.component';
import { NumberItemComponent } from '../number-item/number-item.component';
import { LockItemComponent } from '../lock-item/lock-item.component';

@Component({
  selector: 'font-item',
  templateUrl: './font-item.component.html',
  styleUrls: ['./font-item.component.scss'],
  imports: [
    LockItemComponent,
    NumberItemComponent,
    SelectItemComponent,
    MtColorPickerComponent,
    MatFormFieldModule,
    MtTooltipDirective,
    FontPickerDirective,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    ToggleItemComponent,
    TranslocoPipe,
  ],
})
export class FontItemComponent implements OnChanges, OnInit, OnDestroy {
  readonly disabledFields = input<string[]>([]);
  readonly field = input<string>(undefined);
  readonly hideFonts = input(false);
  readonly hideCapitalisation = input(false);
  readonly lockedFields = input<string[]>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly mtFonts = input<MTFont[]>(undefined);
  readonly showLock = input<boolean>(undefined);
  readonly updateMenu = output<DeepPartial<Menu>>();

  readonly fontPicker = viewChild<FontPickerDirective>('fontPickerInput');

  debouncer = new Subject<DeepPartial<Menu>>();
  private destroyed$ = new Subject<void>();

  fontCapitals = [
    {
      name: 'none',
      value: 0,
    },
    {
      name: 'uppercase',
      value: 1,
    },
    {
      name: 'smallcaps',
      value: 2,
    },
    {
      name: 'lowercase',
      value: 3,
    },
    {
      name: 'capitalise',
      value: 4,
    },
  ];
  fontWeights: { name: string; value: number }[] = [];
  italic = true; // FIXME: need to lookup if italic is supported
  mtFontList = [];
  private _font: Font | FontInterface;
  get font() {
    return this._font;
  }
  set font(value) {
    const fontPicker = this.fontPicker();
    fontPicker && fontPicker.closeDialog();
    if (value !== this._font) {
      this._font = value;
      this.fontWeights = [];
      value.styles.forEach((weight) => {
        if (weight.length === 3 || weight === 'regular') {
          const currentWeight = weight === 'regular' ? '400' : weight;
          this.fontWeights.push({
            name: currentWeight,
            value: parseInt(currentWeight),
          });
        }
      });
      // this.italic = value.italics;
      const existingWeights = this.fontWeights.map((weight) => weight.value);
      const currentWeight = this.menu().style[
        'weight_' + this.field()
      ] as number;
      const field = this.field();
      if (!existingWeights.includes(this.menu().style['weight_' + field])) {
        const closest = existingWeights.reduce((prev, curr) =>
          Math.abs(curr - currentWeight) < Math.abs(prev - currentWeight)
            ? curr
            : prev,
        );
        this.updateMenu.emit({
          style: {
            ['weight_' + field]: closest,
          },
        });
      }
    }
  }

  ngOnInit(): void {
    this.debouncer
      .pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe((value: DeepPartial<Menu>) => {
        this.updateMenu.emit(value);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const mtFonts = this.mtFonts();
    if ('mtFonts' in changes && mtFonts) {
      this.mtFontList = mtFonts.map((font: MTFont) => font.name);
    }
    if (!this.fontWeights.length && this.menu()?.style && mtFonts) {
      const currentFont = mtFonts.filter(
        (font: MTFont) =>
          font.name === this.menu().style['font_' + this.field()],
      );
      if (currentFont.length) {
        currentFont[0].variants.forEach((variant: string) => {
          this.fontWeights.push({
            name: variant,
            value: parseInt(variant),
          });
        });
      }
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  colorChanged(color: string): void {
    const field = this.field();
    if (this.menu().style['colour_' + field] === color) return undefined;
    this.debouncer.next({
      style: {
        ['colour_' + field]: color,
      },
    });
  }

  fontChanged(font: Font | FontInterface, fieldName: string): void {
    const fontFamily = get(font, 'family');
    if (this.menu().style[fieldName] === fontFamily) return undefined;
    this.debouncer.next({
      style: {
        [fieldName]: fontFamily,
      },
    });
  }

  isLocked(field: string): boolean {
    return !this.menu() || this.lockedFields().indexOf(field) > -1;
  }
}
