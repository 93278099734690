import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
  input,
} from '@angular/core';
import { MenuDish } from 'src/app/shared/Models/menudish';
import { ContentLanguage } from '../../../constants/languages';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'side-panel-header',
  templateUrl: './side-panel-header.component.html',
  styleUrls: ['./side-panel-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SidePanelHeaderComponent {
  private transloco = inject(TranslocoService);

  readonly lang = input<ContentLanguage>(undefined);
  @Input() set menuDish(value: MenuDish) {
    if (!value?.dish && !value?.separator) return;
    this.name = value.dish
      ? value.dish_detail[this.lang()]
      : value.separator_detail[this.lang()];
    const category = value.dish
      ? `dishes.categories.${value.dish_detail.category}`
      : 'dishes.sec.placeholder';
    const translatedCategory = this.transloco.translate(category);
    this.title = this.transloco.translate('write.side-panel-shared.header', {
      category: translatedCategory?.toLowerCase(),
    });
  }

  name: string;
  title: string;
}
