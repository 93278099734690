import {
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import {
  FileUploadControl,
  FileUploadValidators,
  FileUploadModule,
} from '@iplab/ngx-file-upload';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { AccountStatusMessage } from 'src/app/shared/Models/models';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import { UserModule } from 'src/app/shared/Models/user';
import {
  fetchTasks,
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import {
  FileService,
  FileUploadApi,
} from 'src/app/shared/Services/files/files.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  selectLimitTemplates,
  selectMainStatus,
  selectMenuStatus,
  selectTemplateStatus,
  selectTemplatesUsed,
  selectUserPrivileges,
} from 'src/app/shared/user/ngrx/user.selectors';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { GroupByPipe } from '../../Pipes/group-by.pipe';
import { TranslocoPipe } from '@jsverse/transloco';
import { AsyncPipe } from '@angular/common';
import { SpinnerComponent } from '../spinner/spinner.component';
import { WriteNewMenuItemComponent } from './write-new-menu-item/write-new-menu-item.component';
import { MatIconModule } from '@angular/material/icon';
import { sharedFeature } from '../../ngrx/shared.state';

@Component({
  selector: 'write-new-menu',
  templateUrl: './write-new-menu.component.html',
  styleUrls: ['./write-new-menu.component.scss'],
  imports: [
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    WriteNewMenuItemComponent,
    SpinnerComponent,
    FileUploadModule,
    MatInputModule,
    MatDatepickerModule,
    AsyncPipe,
    TranslocoPipe,
    GroupByPipe,
  ],
})
export class WriteNewMenuComponent implements OnChanges, OnDestroy, OnInit {
  private fileService = inject(FileService);
  private ngrxStore = inject<Store<State>>(Store);
  private utils = inject(UtilsService);
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter);

  readonly backdrop = input<string>(undefined);
  readonly defaultTemplates = input<OnboardingTemplate[]>([]);
  readonly hideButton = input<boolean>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly small = input<boolean>(undefined);

  readonly createMenu = output<{
    date: Date;
    template: number;
  }>();

  limitTemplates$ = this.ngrxStore.select(selectLimitTemplates);
  menuStatus$ = this.ngrxStore.select(selectMenuStatus);
  privileges$ = this.ngrxStore.select(selectUserPrivileges);
  templateStatus$ = this.ngrxStore.select(selectTemplateStatus);
  templatesUsed$ = this.ngrxStore.select(selectTemplatesUsed);
  userModules$ = this.ngrxStore.select(sharedFeature.selectUserModulesAll);
  userStatus$ = this.ngrxStore.select(selectMainStatus);

  readonly inputPicker = viewChild('inputPicker', { read: MatInput });
  readonly matMenu = viewChild<MatMenuTrigger>('menuTrigger');
  readonly picker = viewChild<MatDatepicker<Date>>('picker');

  currentTemplate: number;
  currentDataImportTemplate: number;
  dataImportTemplates: OnboardingTemplate[] = [];
  private destroyed$ = new Subject<void>();
  errorMessage: string;
  public fileUploadControl = new FileUploadControl(null);
  provider: string;
  templateErrorMsg: string;
  editMenuPrivilege: boolean;
  userStatusDetail: AccountStatusMessage;

  constructor() {
    this.utils.getTranslation(
      'shared.errors.unsupported-file',
      (el) => (this.errorMessage = el),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const defaultTemplates = this.defaultTemplates();
    if ('defaultTemplates' in changes && defaultTemplates) {
      this.dataImportTemplates = defaultTemplates.filter(
        (t) => t.data_import_template,
      );
    }
  }

  ngOnInit(): void {
    this.privileges$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.editMenuPrivilege = value.edit_menu;
    });
    this.userStatus$.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.userStatusDetail = value;
    });
    this.templateStatus$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (v) =>
          (this.templateErrorMsg = v?.message
            ? v.message
            : this.templateErrorMsg),
      );
    this.userModules$
      .pipe(
        filter((userModules) => !!userModules),
        takeUntil(this.destroyed$),
      )
      .subscribe((userModules) => {
        const dataModule = userModules.find(
          (m: UserModule) => m.module_detail.code === 'data',
        );
        if (dataModule) {
          this.provider = dataModule.options?.default_provider ?? 'default';
          this.fileUploadControl.setValidators([
            FileUploadValidators.accept([
              this.provider === 'orgacard'
                ? 'text/xml'
                : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
            ]),
          ]);
        }
      });
    this.fileUploadControl.valueChanges
      .pipe(
        filter((files) => !!files?.length),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.fileUploadControl.valid
          ? this.dispatchFile()
          : this.dispatchFileError();
      });
  }

  createMenuEvent = (date, template: any) => {
    this.createMenu.emit({ date, template });
    this.inputPicker().value = '';
    this.picker().close();
  };

  dispatchFile(): void {
    const data = {
      provider: this.provider,
      archive: true,
      ...(this.currentDataImportTemplate
        ? { onboarding_template: this.currentDataImportTemplate }
        : {}),
    };
    this.fileService
      .uploadForm(
        data,
        this.fileUploadControl.value,
        'attachment',
        FileUploadApi.menu,
      )
      .subscribe({
        next: () => {
          this.ngrxStore.dispatch(fetchTasks());
          this.fileUploadControl.clear();
        },
        error: (error) => {
          this.ngrxStore.dispatch(handleHttpError({ error }));
          this.fileUploadControl.clear();
        },
      });
  }

  dispatchFileError(): void {
    this.fileUploadControl.clear();
    this.ngrxStore.dispatch(
      showSnackbarMessage({ message: this.errorMessage }),
    );
  }

  createClicked(btn: MatButton, tooltip: MatTooltip): void {
    this.utils.createBtnMessage(btn, tooltip);
  }

  hasModule = (code: string): boolean => this.utils.hasModules(code);

  showDatepicker(template: OnboardingTemplate) {
    this.currentTemplate = template.id;
    if (template?.ask_date) {
      this.picker().open();
    } else {
      this.createMenuEvent(this.dateAdapter.today(), template.id);
    }
  }

  showTooltip = (tooltip) => setTimeout(() => tooltip.show(), 200);

  triggerMenu() {
    this.matMenu().openMenu();
  }

  setCurrentDataImportTemplate(id: number) {
    this.currentDataImportTemplate = id;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
