import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import {
  Menu,
  PatchStyleNameData,
  Style,
  StyleCategories,
} from 'src/app/shared/Models/menu';
import { StylesCarouselComponent } from './styles-carousel/styles-carousel.component';
import { CategoriesTabsComponent } from '../categories-tabs/categories-tabs.component';

@Component({
  selector: 'select-styles',
  templateUrl: './select-styles.component.html',
  styleUrls: ['./select-styles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CategoriesTabsComponent, StylesCarouselComponent],
})
export class SelectStylesComponent {
  readonly categories = input<StyleCategories>(undefined);
  readonly eventStyles = input<Style[]>(undefined);
  readonly lang = input<InterfaceLanguage>(undefined);
  readonly menu = input<Menu>(undefined);
  readonly publicStyles = input<Style[]>(undefined);
  readonly showStyleSpinner = input<string>(undefined);
  readonly styleModified = input<boolean>(undefined);
  readonly tabIndex = input<number>(undefined);
  readonly userStyles = input<Style[]>(undefined);

  readonly createStyle = output<void>();
  readonly deleteStyle = output<Style>();
  readonly openNameDialog = output<Style>();
  readonly renameStyle = output<{
    style: Style;
    value: PatchStyleNameData;
  }>();
  readonly selectStyle = output<Style>();
  readonly tabChange = output<number>();
  readonly updateStyle = output<void>();
}
