import { Component, output, input } from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import { Menu, MenuBackup, MenuPreviewData } from 'src/app/shared/Models/menu';
import { TranslocoPipe } from '@jsverse/transloco';
import { DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'backup-menu',
  templateUrl: './backup-menu.component.html',
  styleUrls: ['../backup-menus.component.scss', './backup-menu.component.scss'],
  imports: [MatButtonModule, MatTooltipModule, DatePipe, TranslocoPipe],
})
export class BackupMenuComponent {
  readonly backup = input<MenuBackup>(undefined);
  readonly blockDelete = input<boolean>(undefined);
  readonly blockRestore = input<boolean>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly menu = input<Menu>(undefined);

  readonly delete = output<MenuBackup>();
  readonly preview = output<MenuPreviewData>();
  readonly restore = output<MenuBackup>();

  showPreview(): void {
    this.preview.emit({
      url: this.backup().preview,
      baseLanguage: this.backup().base_language,
      langs: this.menu().translations_list.map((lang) => ({
        lang,
        activated: lang === this.menu().base_language,
        order: lang === this.menu().base_language ? 0 : null,
      })),
      numberLanguages: this.menu().template_detail.number_languages,
      multiRequired: this.menu().template_detail.multilingual_required,
      params: { base_lang: this.backup().base_language },
    });
  }
}
