import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import {
  authFeatureKey,
  authReducer,
  AuthState,
} from 'src/app/auth/ngrx/auth.reducer';
import {
  menusFeatureKey,
  menusReducer,
  MenusState,
} from 'src/app/menus/ngrx/menus.store';
import { eventsMap } from 'src/app/reducers/tagmanager';
import {
  userFeatureKey,
  userReducer,
  UserState,
} from 'src/app/shared/user/ngrx/user.reducer';
import { createMetaReducer } from 'redux-beacon';

import { environment } from '../../environments/environment';

export function getMetaReducers(): MetaReducer<State>[] {
  const gtmMetaReducer = createMetaReducer(eventsMap, GoogleTagManager());
  return [gtmMetaReducer];
}

export interface State {
  [authFeatureKey]: AuthState;
  [menusFeatureKey]: MenusState;
  [userFeatureKey]: UserState;
}

export const reducers: ActionReducerMap<State> = {
  [authFeatureKey]: authReducer,
  [menusFeatureKey]: menusReducer,
  [userFeatureKey]: userReducer,
};

export const metaReducers: MetaReducer<State>[] = environment.production
  ? getMetaReducers()
  : [];
