import { Component, inject, output, input } from '@angular/core';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { TranslocoPipe } from '@jsverse/transloco';
import { MagicStickComponent } from '../magic-stick/magic-stick.component';

@Component({
  selector: 'express-translation',
  templateUrl: './express-translation.component.html',
  styleUrls: ['./express-translation.component.scss'],
  imports: [MagicStickComponent, TranslocoPipe],
})
export class ExpressTranslationComponent {
  private utils = inject(UtilsService);

  readonly loading = input(false);
  readonly disabled = input(false);
  readonly isTrial = input(false);
  readonly profileComplete = input(false);

  readonly expressTranslate = output();

  handleClick(): void {
    if (this.isTrial() && !this.profileComplete()) {
      this.utils.showTrialBlockedBox();
    } else {
      this.expressTranslate.emit();
    }
  }
}
