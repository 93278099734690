import { Directive, ElementRef, OnInit, inject } from '@angular/core';

const PROGRESS_BAR_NAME = 'MAT-PROGRESS-BAR';

@Directive({
  selector: '[inputProgressBar]',
  standalone: true,
})
export class InputProgressBarDirective implements OnInit {
  private el = inject(ElementRef);

  progressBarElement: HTMLElement;
  nameIdentifier: string;

  constructor() {
    const el = this.el;

    const attr = (el.nativeElement as HTMLInputElement).attributes.getNamedItem(
      `formcontrolname`,
    );
    this.nameIdentifier = attr && attr.value;
  }

  ngOnInit(): void {
    const parent = this.el.nativeElement.parentElement as HTMLInputElement;
    if (
      parent &&
      parent.classList &&
      parent.classList.contains(`mat-mdc-form-field-infix`)
    ) {
      parent.childNodes.forEach((node: ChildNode) => {
        if (node.nodeName === PROGRESS_BAR_NAME) {
          this.progressBarElement = node as HTMLElement;
        }
      });
      this.changeBarVisibility();
    } else {
      console.error(`Couldn't find progress bar`);
    }
  }

  changeBarVisibility(show = false): void {
    this.progressBarElement &&
      (this.progressBarElement.style.display = show ? `block` : `none`);
  }
}
