import { Component, input } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'boolean-field',
  templateUrl: './boolean-field.component.html',
  styleUrls: ['./boolean-field.component.scss'],
  imports: [ReactiveFormsModule, MatCheckboxModule, TranslocoPipe],
})
export class BooleanFieldComponent {
  readonly field = input<string>(undefined);
  readonly form = input<UntypedFormGroup>(undefined);
}
