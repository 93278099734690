<mat-form-field
  [ngClass]="{ simple: simple(), left: left(), longLang: longLang() }"
  class="select"
  subscriptSizing="dynamic"
>
  @if (placeholder()) {
    <mat-label>{{ placeholder() }}</mat-label>
  }
  <mat-select
    (selectionChange)="handleChange($event)"
    [ngModel]="immutable() ? emptyModel[changeValue()] : model()[changeValue()]"
    (ngModelChange)="
      immutable()
        ? emptyModel[changeValue()]
        : (model()[changeValue()] = $event)
    "
    [disabled]="disabled()"
    name="language"
  >
    @for (language of languages; track language) {
      @if (
        limitLangs() === undefined || limitLangs()?.includes(language.value)
      ) {
        <mat-option [attr.value]="language.value" [value]="language.value">
          {{ language.viewValue }}
        </mat-option>
      }
    }
    @if (extendLangs()) {
      @for (language of languagesExtended; track language) {
        @if (
          limitLangs() === undefined || limitLangs()?.includes(language.value)
        ) {
          <mat-option [value]="language.value">
            {{ language.viewValue }}
          </mat-option>
        }
      }
    }
  </mat-select>
  @if (showClear()) {
    <button mat-icon-button matSuffix (click)="clear.emit($event)">
      <mat-icon class="clear">clear</mat-icon>
    </button>
  }
</mat-form-field>
