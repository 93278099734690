<form
  [formGroup]="resetForm"
  class="simple-theme"
  (ngSubmit)="onSubmit($event)"
>
  <div class="label">
    <mat-icon class="icon">lock</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.new_password1"
      [color]="formErrors.new_password1 ? 'warn' : 'primary'"
      class="full"
    >
      <mat-label>{{
        'auth.reset_password.confirm.fields.new_password1.placeholder'
          | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="new_password1"
        autocomplete="new-password"
        minlength="8"
        required
        type="password"
      />
    </mat-form-field>
  </div>
  <div class="label">
    <mat-icon class="icon">lock</mat-icon>
    <mat-form-field
      [hintLabel]="formErrors.new_password2"
      [color]="formErrors.new_password2 ? 'warn' : 'primary'"
      class="full"
    >
      <mat-label>{{
        'auth.reset_password.confirm.fields.new_password2.placeholder'
          | transloco
      }}</mat-label>
      <input
        matInput
        formControlName="new_password2"
        autocomplete="new-password"
        minlength="8"
        required
        type="password"
      />
    </mat-form-field>
  </div>
</form>
<button
  [disabled]="!resetForm.valid"
  (click)="onSubmit($event)"
  mat-flat-button
  color="primary"
  class="button-primary button"
>
  {{ 'auth.reset_password.confirm.actions.reset' | transloco }}
</button>
<div class="links">
  <a routerLink="/login">
    {{ 'auth.reset_password.confirm.actions.go_back' | transloco }}
  </a>
  <a routerLink="/registration" class="register-link">
    {{ 'auth.shared.register' | transloco }}
  </a>
</div>
