import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatButtonModule } from '@angular/material/button';

interface SimpleDialogData {
  text: string;
  cancelable: boolean;
  title?: string;
  link?: string;
  linkText?: string;
  confirmText?: string;
  cancelText?: string;
}

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
  imports: [MatDialogModule, MatButtonModule, TranslocoPipe],
})
export class SimpleDialogComponent {
  protected data = inject<SimpleDialogData>(MAT_DIALOG_DATA);
}
