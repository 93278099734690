<div class="similar-item">
  <span class="dish-name">
    {{ dish()[lang()] }}
  </span>
  @for (item of getDeclarations(dish()); track item) {
    <span
      title="{{ item[lang()] }}"
      [ngClass]="{
        'additives-symbol': type() === 'additives',
      }"
      [class.allergen-symbol]="type() === 'allergens' && !!item.app_symbol"
      [class.allergen-symbol-clear]="item?.legend_symbol === 4"
      >{{ item.app_symbol || item.symbol }}</span
    >
  }
</div>
<button
  mat-icon-button
  tabindex="-1"
  color="primary"
  class="apply-button"
  (click)="apply(dish())"
>
  <mat-icon class="icon">content_copy</mat-icon>
</button>
