import { DeepPartial } from './../../../../Models/generics';
import { Component, output, input } from '@angular/core';
import {
  Dish,
  SimpleDishAdditives,
  SimpleDishAllergens,
} from 'src/app/shared/Models/dish';
import {
  InterfaceLanguage,
  ContentLanguage,
} from 'src/app/shared/constants/languages';
import { Ingredient } from 'src/app/shared/Models/ingredients';
import { OptionsComponent } from '../../options/options.component';
import { MatButtonModule } from '@angular/material/button';
import { SimilarDishDeclarationsComponent } from './similar-dish-declarations/similar-dish-declarations.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-similar-dishes',
  templateUrl: './similar-dishes.component.html',
  styleUrls: ['./similar-dishes.component.scss'],
  imports: [
    SimilarDishDeclarationsComponent,
    MatButtonModule,
    OptionsComponent,
    TranslocoPipe,
  ],
})
export class SameDishesComponent {
  readonly allDishesNumber = input.required<number>();
  readonly contentLang = input<ContentLanguage>(undefined);
  readonly dish = input.required<Dish>();
  readonly lang = input.required<InterfaceLanguage>();
  readonly similarDishes =
    input.required<(SimpleDishAllergens | SimpleDishAdditives)[]>();
  readonly type = input.required<'allergens' | 'additives' | 'labels'>();

  readonly addOption = output<{
    data: DeepPartial<Dish> | DeepPartial<Ingredient>;
    type: string;
  }>();
  readonly copyDeclarations = output<{
    source: SimpleDishAllergens | SimpleDishAdditives;
    target: Dish;
    type: 'add' | 'all';
  }>();
  readonly loadMoreDishes = output<string>();
}
