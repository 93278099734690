<h2 class="sent-header">
  {{ 'app.navigation.send-message-dialog.thanks' | transloco }} <br />
  {{ 'app.navigation.send-message-dialog.thanks-description' | transloco }}
</h2>
<div class="circle">
  <div>
    <mat-icon>check</mat-icon>
    <div>{{ 'app.navigation.send-message-dialog.sent' | transloco }}</div>
  </div>
</div>
