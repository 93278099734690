import {
  Component,
  DestroyRef,
  OnChanges,
  SimpleChanges,
  inject,
  output,
  viewChild,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateAdapter } from '@angular/material/core';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import {
  FileUploadControl,
  FileUploadValidators,
  FileUploadModule,
} from '@iplab/ngx-file-upload';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { InterfaceLanguage } from 'src/app/shared/constants/languages';
import { OnboardingTemplate } from 'src/app/shared/Models/onboarding_template';
import {
  FileService,
  FileUploadApi,
} from 'src/app/shared/Services/files/files.service';
import {
  handleHttpError,
  showSnackbarMessage,
} from 'src/app/shared/ngrx/shared.actions';
import { State } from 'src/app/reducers';
import { Store } from '@ngrx/store';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { filter } from 'rxjs/operators';
import { Menu } from 'src/app/shared/Models/menu';
import { setUserOnboardingTemplateUsed } from 'src/app/dashboard/ngrx/dashboard.actions';
import { afterMenuCreated } from 'src/app/menus/menu-edit/ngrx/menu-edit.actions';
import { SafePipe } from '../../Pipes/safe.pipe';
import { SpinnerComponent } from '../spinner/spinner.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ImageZoomDirective } from './image-zoom/image-zoom.directive';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'template-item',
  templateUrl: './template-item.component.html',
  styleUrls: [`./template-item.component.scss`],
  imports: [
    MatCardModule,
    ImageZoomDirective,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    FileUploadModule,
    SpinnerComponent,
    MatDatepickerModule,
    TranslocoPipe,
    SafePipe,
  ],
})
export class TemplateItemComponent implements OnChanges {
  private translate = inject(TranslocoService);
  private dateAdapter = inject<DateAdapter<Date>>(DateAdapter);
  private ngrxStore = inject<Store<State>>(Store);
  private fileService = inject(FileService);
  private utils = inject(UtilsService);
  private destroyRef = inject(DestroyRef);

  readonly currentLocation = input<number>(undefined);
  readonly editPermissionField = input<string>(undefined);
  readonly latest = input<boolean>(undefined);
  readonly data = input<OnboardingTemplate>(undefined);
  readonly disabled = input(false);
  readonly change = input(false);

  readonly choose = output<Date>();
  readonly changeTemp = output();
  readonly showError = output();
  readonly deleteTemplate = output<number>();
  readonly openNameDialog = output<OnboardingTemplate>();

  readonly picker = viewChild<MatDatepicker<Date>>('picker');

  errorMessage: string;
  downloadLoading = false;
  importLoading = false;
  public fileUploadControls: FileUploadControl[] = [];
  lang: InterfaceLanguage;

  constructor() {
    this.translate.langChanges$.subscribe(this.handleLang);
    this.handleLang();
    this.utils.getTranslation(
      'shared.errors.unsupported-file',
      (el) => (this.errorMessage = el),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    const data = this.data();
    if ('data' in changes && data) {
      this.fileUploadControls = data.data_import.map((provider) => {
        const control = new FileUploadControl(
          {
            listVisible: false,
            discardInvalid: true,
            multiple: false,
          },
          [
            FileUploadValidators.accept([
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
            ]),
          ],
        );
        control.valueChanges
          .pipe(
            filter((files) => !!files?.length),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe(() => {
            control.valid
              ? this.dispatchFile(control, provider)
              : this.dispatchFileError(control);
          });
        return control;
      });
    }
  }

  editAction(event: Event) {
    event.stopPropagation();
    this.openNameDialog.emit(this.data());
  }

  chooseTemplate() {
    if (!this.latest()) {
      this.choose.emit(this.dateAdapter.today());
      return undefined;
    }
    if (this.data()?.ask_date) {
      this.picker().open();
    } else {
      this.triggerTemplate(this.dateAdapter.today());
    }
  }

  preventDefault(event: Event) {
    event.stopPropagation();
  }

  triggerTemplate(date?) {
    this.picker().close();
    setTimeout(() => {
      this.change()
        ? this.changeTemp.emit()
        : this.choose.emit(date ? date.value : this.dateAdapter.today());
    }, 10);
  }

  handleLang = () => {
    if (!this.translate) return undefined;
    this.lang = this.translate.getActiveLang() as InterfaceLanguage;
  };

  dispatchFile(control: FileUploadControl, provider: string): void {
    this.importLoading = true;
    const data = {
      provider,
      onboarding_template: this.data().id,
    };
    const api = FileUploadApi.menu;
    const params = {};
    const currentLocation = this.currentLocation();
    if (currentLocation) {
      params['current_location'] = currentLocation;
    }
    this.fileService
      .uploadForm<Menu>(data, control.value, 'attachment', api, params)
      .subscribe({
        next: (menu) => {
          control.clear();
          this.ngrxStore.dispatch(
            setUserOnboardingTemplateUsed({ id: this.data().id, menu }),
          );
          this.ngrxStore.dispatch(afterMenuCreated({ menu }));
        },
        error: (error) => {
          this.ngrxStore.dispatch(handleHttpError({ error }));
          control.clear();
          this.importLoading = false;
        },
      });
  }

  dispatchFileError(control: FileUploadControl): void {
    control.clear();
    this.importLoading = false;
    this.ngrxStore.dispatch(
      showSnackbarMessage({ message: this.errorMessage }),
    );
  }

  downloadTemplate(provider: string) {
    this.downloadLoading = true;
    const onFulfilled = () => (this.downloadLoading = false);
    this.fileService.downloadFile(
      this.fileService.menuImportApi,
      {},
      { provider },
      onFulfilled,
      true,
    );
  }
}
