import { Action, createReducer, on } from '@ngrx/store';
import { Spellcheck } from 'src/app/shared/Models/dish';
import { BackgroundImage, Menu, MenuBackup } from 'src/app/shared/Models/menu';

import * as MenuActions from './menu-edit.actions';

export const menuEditFeatureKey = 'menu_edit';

export interface MenuEditState {
  current_spellcheck_item: Spellcheck;
  current_menu: Menu;
  background_images: BackgroundImage[];
  ai_analysis_loading?: boolean;
}

const initialState: MenuEditState = {
  current_spellcheck_item: null,
  current_menu: null,
  background_images: [],
  ai_analysis_loading: false,
};

const _menuEditReducer = createReducer(
  initialState,
  on(MenuActions.clearCurrentMenu, (state) => {
    return {
      ...initialState,
      background_images: state.background_images,
    };
  }),
  on(MenuActions.setCurrentSpellcheckItem, (state, { payload }) => {
    return {
      ...state,
      current_spellcheck_item: payload,
    };
  }),
  on(MenuActions.setCurrentMenu, (state, { menu }) => {
    return {
      ...state,
      current_menu: menu,
    };
  }),
  on(MenuActions.removeBackupMenu, (state, { backupId }) => {
    const backups = state.current_menu.backups.filter(
      (backup: MenuBackup) => backup.id !== backupId,
    );
    return {
      ...state,
      current_menu: { ...state.current_menu, backups },
    };
  }),
  on(MenuActions.addCreatedBackup, (state, { backup }) => {
    if (!state.current_menu) return { ...state };
    const backups = state.current_menu.backups.slice();
    backups.unshift(backup);
    return {
      ...state,
      current_menu: { ...state.current_menu, backups },
    };
  }),
  on(MenuActions.setTemplate, (state, { template }) => {
    return {
      ...state,
      current_menu: { ...state.current_menu, template: template },
    };
  }),
  on(MenuActions.setLanguage, (state, { language }) => {
    return {
      ...state,
      current_menu: { ...state.current_menu, base_language: language },
    };
  }),
  on(MenuActions.setPreset, (state, { data }) => {
    const updatedState = Object.assign({}, data, {
      state: {
        ...state,
        current_menu: {
          ...state.current_menu,
          onboarding_template_detail: {
            ...state.current_menu.onboarding_template_detail,
            has_preset: data,
          },
        },
      },
    });
    return {
      ...updatedState.state,
    };
  }),
  on(MenuActions.setOnboardingTemplateDetail, (state, { template }) => {
    if (!template) {
      return {
        ...state,
        current_menu: {
          ...state.current_menu,
          onboarding_template: null,
          onboarding_template_detail: null,
        },
      };
    }
    return {
      ...state,
      current_menu: {
        ...state.current_menu,
        onboarding_template: template.id,
        onboarding_template_detail: template,
      },
    };
  }),
  on(MenuActions.deleteOnboardingTemplate, (state) => {
    const updatedTemplateDetail = Object.assign(
      {},
      {
        onboarding_template: null,
        onboarding_template_detail: null,
      },
    );
    return {
      ...state,
      current_menu: { ...state.current_menu, ...updatedTemplateDetail },
    };
  }),
  on(MenuActions.setOriginalStyle, (state, { id }) => {
    return {
      ...state,
      current_menu: {
        ...state.current_menu,
        style_original: id,
        style_modified: false,
      },
    };
  }),
  on(MenuActions.addMenuTranslation, (state, { lang }) => {
    return {
      ...state,
      current_menu: {
        ...state.current_menu,
        translations_list: [...state.current_menu.translations_list, lang],
      },
    };
  }),
  on(MenuActions.setStyleModified, (state, { value }) => {
    return {
      ...state,
      current_menu: {
        ...state.current_menu,
        style_modified: value,
      },
    };
  }),
  on(MenuActions.addBackgroundImage, (state, { image }) => {
    return {
      ...state,
      background_images: [...state.background_images, image],
    };
  }),
  on(MenuActions.setBackgroundImages, (state, { images }) => {
    return {
      ...state,
      background_images: images,
    };
  }),
  on(MenuActions.removeBackgroundImage, (state, { imageId }) => {
    return {
      ...state,
      background_images: state.background_images.filter(
        (image) => image.id !== imageId,
      ),
    };
  }),
  on(MenuActions.setOrderTaking, (state, { value }) => {
    return {
      ...state,
      current_menu: {
        ...state.current_menu,
        enable_ordertaking: value,
      },
    };
  }),
  on(MenuActions.setAiAnalysisLoading, (state, { value }) => ({
    ...state,
    ai_analysis_loading: value,
  })),
);

export function menuEditReducer(state: MenuEditState, action: Action) {
  return _menuEditReducer(state, action);
}
