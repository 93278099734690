import { createAction, props } from '@ngrx/store';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import {
  Dish,
  SimpleDishAdditives,
  SimpleDishAllergens,
} from 'src/app/shared/Models/dish';
import { DeepPartial } from 'src/app/shared/Models/generics';
import {
  ItemRequestParams,
  MenuDish,
  MenuDishParams,
  SimpleMenuDish,
} from 'src/app/shared/Models/menudish';
import { Separator } from 'src/app/shared/Models/separator';

export const clearCurrentMenuDishes = createAction(
  `[Menu Write container] Clear current menudishes`,
);

export const fetchMenuDishes = createAction(
  `[Menu Write container] Fetch menu dishes, if params is set, it is a search`,
  props<{ params: Partial<MenuDishParams> }>(),
);

export const fetchMenuDishesFull = createAction(
  `[Menu Write container] Fetch menu dishes full`,
  props<{ params?: Partial<Omit<MenuDishParams, 'condensed'>> }>(),
);

export const fetchMenuDish = createAction(
  `[Menu Write container] Fetch menu dish`,
  props<{ url: string; onFulfilled: (item: MenuDish) => void }>(),
);

export const setLoading = createAction(
  `[Menu Write container] Set loading`,
  props<{ loading: boolean }>(),
);

export const setLoadingFull = createAction(
  `[Menu Write container] Set full loading`,
  props<{ loading: boolean }>(),
);

export const setSearch = createAction(
  `[Menu Write container] Set search`,
  props<{ search: boolean }>(),
);

export const setMenuDishes = createAction(
  `[Menu Write container] Set menu dishes`,
  props<{ menuDishes: (SimpleMenuDish | MenuDish)[] }>(),
);

export const setMenuDishesFull = createAction(
  `[Menu Write container] Set full menu dishes`,
  props<{ menuDishes: MenuDish[] }>(),
);

export const changeMenuDish = createAction(
  `[Menu Write container] Set updated menu dish`,
  props<{ id: number; menuDish: MenuDish }>(),
);

export const addMenuDishAtIndex = createAction(
  `[Menu Write container] Add dish at index`,
  props<{ menuDish: SimpleMenuDish | MenuDish; index: number }>(),
);

export const setDishLoading = createAction(
  `[Menu Write container] Set dish loading`,
  props<{ isLoading: boolean }>(),
);

export const deleteMenuDish = createAction(
  `[Menu Write container] Delete menudish`,
  props<{
    menudish: SimpleMenuDish | MenuDish;
    onFulfilled?: () => void;
    onError?: () => void;
  }>(),
);

export const deleteMenuDishes = createAction(
  `[Menu Write container] Delete menudishes`,
  props<{ ids: number[]; onFulfilled?: () => void }>(),
);

export const addSeparator = createAction(
  '[Menu Dish] Add Separator',
  props<{ separator: Separator; category: any; level: any; tempId?: number }>(),
);

export const addMenuDish = createAction(
  '[Menu Dish] Add Menu Dish',
  props<{ menuDish: MenuDish }>(),
);

export const refreshMenuDish = createAction(
  '[Menu Dish] Refresh MenuDish',
  props<{ url: string; onFulfilled?: (md: MenuDish) => void }>(),
);

export const updateMenuDish = createAction(
  '[Menu Dish] Update Menu Dish',
  props<{
    url: string;
    id: number;
    menuDish: DeepPartial<MenuDish>;
    onFulfilled?: (md: MenuDish) => void;
    onError?: () => void;
  }>(),
);

export const sortMenuDishes = createAction(
  '[Menu Dish] Sort Dishes',
  props<{
    menuDishes?: (SimpleMenuDish | MenuDish)[];
    onFulfilled?: () => void;
  }>(),
);

export const setIsSorting = createAction(
  '[Menu Dish] Set Is Sorting',
  props<{ value: boolean }>(),
);

export const regenerateMenu = createAction('[Menu Dish] Regenerate Menu');

export const applyPreset = createAction(
  '[Menu Dish] Apply Preset',
  props<{ preset: number }>(),
);

export const duplicateMenuDish = createAction(
  '[Menu Dish] Duplicate Menu Dish',
  props<{ url: string }>(),
);

export const removeMenuDish = createAction(
  '[Menu Dish] Remove Menu Dish',
  props<{ id: number }>(),
);

export const removeMenuDishes = createAction(
  '[Menu Dish] Remove Menu Dishes',
  props<{ ids: number[] }>(),
);

export const createMenuDish = createAction(
  '[Menu Dish] Create Menu Dish',
  props<{
    menuDish: SimpleMenuDish | MenuDish;
    sendSortRequest?: boolean;
    onFulfilled?: (md: MenuDish) => void;
    onError?: () => void;
  }>(),
);

export const createMenuDishWithAi = createAction(
  '[Menu Dish] Create Menu Dish with AI',
  props<{
    menuDish: SimpleMenuDish | MenuDish;
    section: string;
    sendSortRequest?: boolean;
    onFulfilled?: (md: MenuDish) => void;
    onError?: () => void;
  }>(),
);

export const createDish = createAction(
  '[Menu Dish] Create Dish',
  props<{
    dish: DeepPartial<Dish>;
    menuDish: SimpleMenuDish | MenuDish;
    sendSortRequest?: boolean;
    onFulfilled?: (md: MenuDish) => void;
    onError?: () => void;
  }>(),
);

export const createSeparator = createAction(
  '[Menu Dish] Create Separator',
  props<{
    separator: DeepPartial<Separator>;
    menuDish: SimpleMenuDish | MenuDish;
    sendSortRequest?: boolean;
    onFulfilled?: (md: MenuDish) => void;
    onError?: () => void;
  }>(),
);

export const updateDish = createAction(
  '[Menu Dish] Update Dish',
  props<{
    url: string;
    id: number;
    dish: DeepPartial<Dish>;
    menuDish: SimpleMenuDish | MenuDish;
    searchIsActive?: boolean;
    params?: Partial<ItemRequestParams>;
    onFulfilled?: (md: MenuDish) => void;
    onError?: () => void;
  }>(),
);

export const updateSeparator = createAction(
  '[Menu Dish] Update Separator',
  props<{
    url: string;
    id: number;
    separator: DeepPartial<Separator>;
    menuDish: SimpleMenuDish | MenuDish;
    searchIsActive?: boolean;
    params?: Partial<ItemRequestParams>;
    onFulfilled?: (md: MenuDish) => void;
    onError?: () => void;
  }>(),
);

export const addWordToUserDictionary = createAction(
  '[Menu Dish] Add Word To User Dictionary',
  props<{
    word: string;
    text: string;
    lang: ContentLanguage;
    menuDish: SimpleMenuDish | MenuDish;
    onFulfilled?: (md: MenuDish) => void;
  }>(),
);

export const addOption = createAction(
  '[MenuDish] Add Option',
  props<{
    dish: Partial<Dish>;
    declaration: 'allergens' | 'additives' | 'labels';
    menuDish: SimpleMenuDish | MenuDish;
    onFulfilled: (md: MenuDish) => void;
  }>(),
);

export const copyDeclaration = createAction(
  '[Menu Dish] Copy declarations from another dish',
  props<{
    target: SimpleMenuDish | MenuDish;
    declaration: 'all' | 'add';
    source: SimpleDishAllergens | SimpleDishAdditives;
    onFulfilled: (md: MenuDish) => void;
  }>(),
);

export const synchroniseRecipeDeclarations = createAction(
  '[MenuDish] Synchronise Recipe Declarations',
  props<{
    menuDish: MenuDish;
    declaration: 'add' | 'all';
    onFulfilled: (md: MenuDish) => void;
  }>(),
);

export const bulkPriceChange = createAction(
  '[Menu Dish] Bulk Price Change',
  props<{
    percentage_increase?: number;
    rounding_base?: number;
  }>(),
);
