import { Component, output, input } from '@angular/core';
import { ContentLanguage } from 'src/app/shared/constants/languages';
import {
  SimpleDishAdditives,
  SimpleDishAllergens,
} from 'src/app/shared/Models/dish';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { DeclarationBase } from 'src/app/shared/Models/declarations';

@Component({
  selector: 'app-similar-dish-declarations',
  templateUrl: './similar-dish-declarations.component.html',
  styleUrls: ['./similar-dish-declarations.component.scss'],
  imports: [NgClass, MatButtonModule, MatIconModule],
})
export class SimilarDishDeclarationsComponent {
  readonly dish = input<SimpleDishAllergens | SimpleDishAdditives>(undefined);
  readonly lang = input<ContentLanguage>(undefined);
  readonly type = input<string>(undefined);

  readonly copyDeclarations = output<
    SimpleDishAllergens | SimpleDishAdditives
  >();

  apply = (dish: SimpleDishAllergens | SimpleDishAdditives): void =>
    this.copyDeclarations.emit(dish);

  getDeclarations = (
    dish: SimpleDishAllergens | SimpleDishAdditives,
  ): DeclarationBase[] =>
    this.type() === 'allergens'
      ? ((dish as SimpleDishAllergens).allergens_contained as DeclarationBase[])
      : ((dish as SimpleDishAdditives)
          .additives_contained as DeclarationBase[]);
}
