import { Component, inject, input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService, TranslocoPipe } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { PreviewComponent } from 'src/app/shared/Components/pdf-preview/pdf-preview.component';
import { Menu } from 'src/app/shared/Models/menu';
import { showSnackbarMessage } from 'src/app/shared/ngrx/shared.actions';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import { ShareLinkComponent } from './share-link/share-link.component';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'contactless',
  templateUrl: './contactless.component.html',
  styleUrls: ['./contactless.component.css'],
  imports: [
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    ShareLinkComponent,
    TranslocoPipe,
  ],
})
export class ContactlessComponent {
  private dialog = inject(MatDialog);
  private translate = inject(TranslocoService);
  private utils = inject(UtilsService);
  private ngrxStore = inject<Store<State>>(Store);

  readonly customUrl = input<string>(undefined);
  readonly menu = input<Menu>(undefined);
  copyMessage = '';

  constructor() {
    this.translate
      .selectTranslate(`menus.complete.copy`)
      .subscribe((val) => (this.copyMessage = val));
  }

  copied(): void {
    this.ngrxStore.dispatch(showSnackbarMessage({ message: this.copyMessage }));
  }

  generateQRCode(url: string): void {
    const menuName = this.getMenuName();
    this.utils.fetchQRCode(url).subscribe((blob) => {
      this.openPreviewDialog(blob, url, menuName);
    });
  }

  private getMenuName(): string {
    const menu = this.menu();
    if (menu?.name) return menu.name;

    const locationName = menu?.location_detail?.name || '';
    const templateDetail = menu?.template_detail?.[menu?.base_language] || '';

    return locationName ? `${locationName}, ${templateDetail}` : templateDetail;
  }

  private openPreviewDialog(blob: Blob, url: string, menuName: string): void {
    this.dialog.open(PreviewComponent, {
      autoFocus: false,
      width: '400px',
      height: '470px',
      maxWidth: '90vw',
      maxHeight: '95vh',
      data: {
        blob,
        blobUrl: url,
      },
    });
  }
}
