import { Params } from '@angular/router';
import {
  interfaceLangs,
  langsExtended,
  ContentLanguage,
  InterfaceLanguage,
} from 'src/app/shared/constants/languages';

export interface Fulfillable<T> {
  payload?: T;
  onFulfilled?: (res?: any) => void;
}

export enum ButtonActions {
  showTemplatesMenu,
}

export function Language(lang: string): InterfaceLanguage {
  switch (lang) {
    case 'en':
      return 'en';
    case 'it':
      return 'it';
    case 'de':
      return 'de';
    case 'fr':
      return 'fr';
    case 'es':
      return 'es';
    default:
      return 'en';
  }
}

export class SimpleItem {
  id: number;
  url: string;
}

export class BaseItem {
  id: number;
  location?: number;
  organisation?: number;
}

export class BaseName {
  de?: string;
  it?: string;
  en?: string;
  fr?: string;
  es?: string;

  constructor() {
    interfaceLangs.forEach((lang: InterfaceLanguage) => {
      this[lang] = '';
    });
  }
}

export class BaseNameExtended extends BaseName {
  ca?: string;
  hr?: string;
  pl?: string;
  pt?: string;
  nl?: string;
  ru?: string;
  el?: string;
  da?: string;
  sv?: string;
  ar?: string;
  eu?: string;
  gl?: string;
  et?: string;
  fi?: string;
  zh?: string;
  th?: string;
  vi?: string;
  uk?: string;

  constructor() {
    super();
    langsExtended.forEach((lang: ContentLanguage) => {
      this[lang] = '';
    });
  }
}

export class BaseDescription {
  description_de?: string;
  description_it?: string;
  description_en?: string;
  description_fr?: string;
  description_es?: string;
}

export class BaseDescriptionExtended extends BaseDescription {
  description_ca?: string;
  description_hr?: string;
  description_pl?: string;
  description_pt?: string;
  description_nl?: string;
  description_ru?: string;
  description_el?: string;
  description_da?: string;
  description_sv?: string;
  description_ar?: string;
  description_eu?: string;
  description_gl?: string;
  description_et?: string;
  description_fi?: string;
  description_zh?: string;
  description_th?: string;
  description_vi?: string;
  description_uk?: string;
}

export class BaseDescriptionExtendedSecondary extends BaseDescriptionExtended {
  description_secondary_de?: string;
  description_secondary_it?: string;
  description_secondary_en?: string;
  description_secondary_fr?: string;
  description_secondary_es?: string;

  description_secondary_ca?: string;
  description_secondary_hr?: string;
  description_secondary_pl?: string;
  description_secondary_pt?: string;
  description_secondary_nl?: string;
  description_secondary_ru?: string;
  description_secondary_el?: string;
  description_secondary_da?: string;
  description_secondary_sv?: string;
  description_secondary_ar?: string;
  description_secondary_eu?: string;
  description_secondary_gl?: string;
  description_secondary_et?: string;
  description_secondary_fi?: string;
  description_secondary_zh?: string;
  description_secondary_th?: string;
  description_secondary_vi?: string;
  description_secondary_uk?: string;
}

export class AccountStatusMessage {
  ready: boolean;
  description?: string; // simple
  message?: string; // detailed
  link?: any;

  limits?: any;
  icon?: string;
  email?: any;
  buttonAction?: ButtonActions = ButtonActions.showTemplatesMenu;
  barBg?: 'success' | 'error' = 'success';

  constructor(
    ready: boolean,
    message?: string,
    description?: string,
    link?: {
      route: string[];
      query?: Params;
    },
    limits?: any,
    email?: any,
  ) {
    this.ready = ready;
    this.description = description;
    this.link = link;
    this.message = message;
    this.limits = limits;
    this.email = email;
  }

  setBgColor(color: 'success' | 'error'): AccountStatusMessage {
    this.barBg = color;
    return this;
  }
}

export class ResetPassword {
  new_password1: string;
  new_password2: string;
  old_password?: string;
  uid: string;
  token: string;

  constructor(
    new_password1?: string,
    new_password2?: string,
    uid?: string,
    token?: string,
  ) {
    if (new_password1) this.new_password1 = new_password1;
    if (new_password2) this.new_password2 = new_password2;
    if (uid) this.uid = uid;
    if (token) this.token = token;
  }
}

export class EmailConfirmation {
  key: string;
}

export class TemplateRequest {
  message: string;
  template: string;
}

export interface ItemsResponse {
  results: string[];
}

export interface InlineImage {
  content_type: number;
  created_at: string;
  created_by: number;
  id: number;
  creator: boolean;
  image: string;
  last_modified_at: string;
  last_modified_by: number;
  location: number;
  object_id: number;
  organisation: number;
  url: string;
}
