"use strict";

var __assign = this && this.__assign || Object.assign || function (t) {
  for (var s, i = 1, n = arguments.length; i < n; i++) {
    s = arguments[i];
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
  }
  return t;
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertFromGoogleAnalyticsEventIfNeeded = function (event) {
  if (!event.event && event.hitType) {
    var hitType = event.hitType,
      rest = __rest(event, ["hitType"]);
    return __assign({}, rest, {
      event: hitType
    });
  }
  return event;
};