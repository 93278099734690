import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { BouncedEmailModalData } from 'src/app/shared/Models/dashboard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslocoPipe } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';

@Component({
  selector: 'bounced-email-modal',
  templateUrl: './bounced-email-modal.component.html',
  styleUrls: ['./bounced-email-modal.component.scss'],
  imports: [
    MatDialogContent,
    NgClass,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose,
    MatIconModule,
    TranslocoPipe,
  ],
})
export class BouncedEmailModalComponent implements OnDestroy {
  data = inject<BouncedEmailModalData>(MAT_DIALOG_DATA);

  emailClassesLarge = 'mat-mdc-display-2';
  emailClassesSmall = 'mat-mdc-display-1 small';
  emailClasses = this.emailClassesSmall;
  textClassesLarge = 'mat-mdc-h2';
  textClassesSmall = 'mat-mdc-body-1 small';
  textClasses = this.textClassesSmall;
  private destroyed$ = new Subject<void>();

  constructor() {
    const breakpointObserver = inject(BreakpointObserver);

    breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        ({
          breakpoints: {
            [Breakpoints.Small]: small,
            [Breakpoints.Medium]: medium,
            [Breakpoints.Large]: large,
          },
        }) => {
          if (small) {
            this.textClasses = this.textClassesSmall;
            this.emailClasses = this.emailClassesSmall;
          } else if (medium || large) {
            this.textClasses = this.textClassesLarge;
            this.emailClasses = this.emailClassesLarge;
          }
        },
      );
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
