<div class="layouts-wrapper">
  @for (layout of layouts(); track layout) {
    <select-layout
      [layout]="layout"
      [lang]="lang()"
      [selected]="menu()?.template === layout.id"
      (selectItem)="selectLayout.emit(layout.id)"
    ></select-layout>
  }
  <select-layout
    [create]="true"
    (sendRequest)="sendRequest.emit()"
  ></select-layout>
</div>
