<div
  class="style-variant icon-button-density-3"
  [class.disabled]="disabled()"
  [class.selected]="selected()"
  (click)="imgSelected.emit()"
>
  @if (imageUrl()) {
    <img [alt]="label()" [src]="imageUrl()" />
  } @else {
    {{ label() }}
  }
  @if (canDelete()) {
    <button
      mat-icon-button
      stop-propagation
      class="delete-button"
      [disabled]="disabled() || deleteButtonDisabled"
      (click)="delete.emit(); deleteButtonDisabled = true"
    >
      <mat-icon>delete</mat-icon>
    </button>
  }
</div>
