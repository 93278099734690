<div class="options">
  <language-buttons
    class="lang-buttons"
    [langs]="langs"
    [hideLabel]="true"
    [langsAllowed]="
      params()?.response === 'html'
        ? 1
        : menu()?.template_detail?.number_languages
    "
    [multiRequired]="menu()?.template_detail?.multilingual_required"
    (langsChange)="updateLink($event)"
  >
  </language-buttons>
  @if (params()?.response !== 'html' && menu() && menu().url) {
    <mat-checkbox
      class="print-version"
      color="primary"
      (change)="changePrintVersion($event)"
      [(ngModel)]="printVersion"
    >
      {{ 'menus.complete.contactless.print_version' | transloco }}
    </mat-checkbox>
  }
  @if (params()?.response === 'html' && menu() && menu().url) {
    <mat-checkbox
      class="print-version"
      color="primary"
      (change)="changeScreenVersion($event)"
      [(ngModel)]="screenVersion"
    >
      {{ 'menus.complete.contactless.screen_version' | transloco }}
    </mat-checkbox>
  }
</div>

<div class="element-wrapper">
  <div class="menu link-field icon-button-density-3">
    <textarea
      matInput
      #shareLink
      readonly
      class="copy-input"
      [disabled]="!(menu() && menu().url)"
      [ngModel]="link || ''"
      [ngxClipboard]="shareLink"
      [readonly]="true"
      (cbOnSuccess)="copy()"
    ></textarea>
    <button
      mat-icon-button
      matSuffix
      class="copy-link"
      [ngxClipboard]="shareLink"
      (cbOnSuccess)="copy()"
    >
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
  @if (menu() && menu().url) {
    <div class="mobile-menu" (click)="qrcode()">
      <button matSuffix mat-icon-button class="qr-code">
        <mat-icon>qr_code_2</mat-icon>
      </button>
    </div>
  }
</div>
<button
  mat-button
  class="action"
  [disabled]="!(menu() && menu().url)"
  [matMenuTriggerFor]="mobileMenu"
>
  {{ actionLabel() }}
</button>
<mat-menu #mobileMenu="matMenu">
  <button mat-menu-item [ngxClipboard]="shareLink" (cbOnSuccess)="copy()">
    {{ 'menus.complete.contactless.copy_sharelink' | transloco }}
  </button>
  <button mat-menu-item (click)="qrcode()">
    {{ 'menus.complete.contactless.show_qrcode' | transloco }}
  </button>
</mat-menu>
