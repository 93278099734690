import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from 'src/app/reducers';
import { Results } from 'src/app/shared/Models/generics';
import { InventorySupplier } from 'src/app/shared/Models/ingredients';
import { Supplier, SupplierItem } from 'src/app/shared/Models/procurements';
import { ConfigService } from 'src/app/shared/Services/config/config.service';
import { GenericsService } from 'src/app/shared/Services/generics/generics.service';
import { UtilsService } from 'src/app/shared/Services/utils/utils.service';
import {
  catchError,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { handleHttpError } from '../shared.actions';
import * as SuppliersActions from './suppliers.actions';
import { sharedFeature } from '../shared.state';

@Injectable()
export class SuppliersEffects {
  private actions$ = inject(Actions);
  private configService = inject(ConfigService);
  private genericsService = inject(GenericsService);
  private store = inject<Store<State>>(Store);

  createSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.createSupplier),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ sup }, location]) =>
        this.genericsService
          .post<
            Partial<InventorySupplier>,
            InventorySupplier
          >(this.configService.suppliers, sup, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap(() => [SuppliersActions.fetchSuppliersAutocomplete({})]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  deleteSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.deleteSupplier),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ sup }, location]) =>
        this.genericsService
          .delete<InventorySupplier>(
            sup.url,
            UtilsService.addLocationParam(location),
          )
          .pipe(
            mergeMap(() => [SuppliersActions.fetchSuppliersAutocomplete({})]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateSupplier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.updateSupplier),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ sup }, location]) =>
        this.genericsService
          .patch<
            Partial<InventorySupplier>,
            InventorySupplier
          >(sup.url, sup, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap(() => [SuppliersActions.fetchSuppliersAutocomplete({})]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  fetchSuppliersAutocomplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.fetchSuppliersAutocomplete),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ name }, location]) => {
        const params = {};
        if (name) params['name'] = name;
        return this.genericsService
          .get<
            Results<Supplier>
          >(this.configService.suppliers, UtilsService.addLocationParam(location, params))
          .pipe(
            mergeMap((suppliers) => [
              SuppliersActions.setSuppliersAutocomplete({
                payload: suppliers,
              }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          );
      }),
    ),
  );

  fetchSupplierItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.fetchSupplierItems),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ ingredient, dishSupplier }, location]) =>
        this.genericsService
          .get<
            Results<SupplierItem>
          >(this.configService.supplierItems, UtilsService.addLocationParam(location, dishSupplier ? { dish: ingredient.id } : { ingredient: ingredient.id }))
          .pipe(
            mergeMap((result) => [
              SuppliersActions.setSupplierItems({ data: result.results }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  createSupplierItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.createSupplierItem),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ id, supplierItem }, location]) =>
        this.genericsService
          .post<
            Partial<SupplierItem>,
            SupplierItem
          >(this.configService.supplierItems, supplierItem, UtilsService.addLocationParam(location, null))
          .pipe(
            mergeMap((res) => [
              SuppliersActions.changeSupplierItem({ id, supplierItem: res }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  deleteSupplierItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.deleteSupplierItem),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ supplierItem }, location]) =>
        this.genericsService
          .delete(
            supplierItem.url,
            UtilsService.addLocationParam(location, null),
          )
          .pipe(
            mergeMap(() => [
              SuppliersActions.removeDeletedSupplierItem({
                supplierItem: supplierItem,
              }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updateSupplierItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.updateSupplierItem),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ supplierItem }, location]) =>
        this.genericsService
          .patch<
            Partial<SupplierItem>,
            SupplierItem
          >(supplierItem.url, supplierItem, UtilsService.addLocationParam(location))
          .pipe(
            mergeMap((res) => [
              SuppliersActions.changeSupplierItem({
                id: res.id,
                supplierItem: res,
              }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );

  updatePreferredSupplierItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SuppliersActions.updatePreferredSupplierItem),
      withLatestFrom(this.store.select(sharedFeature.selectCurrentLocation)),
      switchMap(([{ supplierUrl }, location]) =>
        this.genericsService
          .post<
            null,
            Results<SupplierItem>
          >(`${supplierUrl}make_preferred/`, UtilsService.addLocationParam(location, null))
          .pipe(
            mergeMap((supplierItem) => [
              SuppliersActions.setSupplierItems({ data: supplierItem.results }),
            ]),
            catchError((error) => [handleHttpError({ error })]),
          ),
      ),
    ),
  );
}
