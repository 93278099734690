import { sharedFeature } from 'src/app/shared/ngrx/shared.state';
import {
  Component,
  OnInit,
  TemplateRef,
  OnDestroy,
  inject,
  viewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { OverlayService } from 'src/app/shared/Services/overlay-service/overlay.service';
import { State } from 'src/app/reducers';
import { setSidePanelOpen } from './../ngrx/shared.actions';
import { SidePanelComponent } from '../Components/side-panel/side-panel.component';

@Component({
  selector: 'side-panel-controller',
  template: ``,
  standalone: true,
})
export class SidePanelControllerBase implements OnInit, OnDestroy {
  protected ngrxStore = inject<Store<State>>(Store);
  protected overlayService = inject(OverlayService);

  sidePanel: SidePanelComponent;
  sidePanelOpened$ = this.ngrxStore.select(sharedFeature.selectSidePanelOpen);

  readonly sidePanelRef = viewChild(`sidePanel`, { read: TemplateRef });

  ngOnInit() {
    this.ngrxStore.dispatch(setSidePanelOpen({ open: true }));
    this.overlayService.insertTemplate(`side-panel`, this.sidePanelRef());
    this.sidePanel =
      this.overlayService.getComponent<SidePanelComponent>(`side-panel`);
    if (!this.sidePanel.isMobile) this.showSidePanel();
    else this.hideSidePanel();
  }

  showSidePanel(): void {
    this.sidePanel.contentSet = true;
  }

  hideSidePanel(): void {
    this.sidePanel.tryHidePanel();
  }

  ngOnDestroy(): void {
    this.ngrxStore.dispatch(setSidePanelOpen({ open: false }));
    this.overlayService.clear(`side-panel`);
    this.sidePanel.contentSet = undefined;
  }
}
