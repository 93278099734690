<div
  class="wrapper icon-button-density-3"
  [mtTooltip]="
    disabled()
      ? ('style.sidebar.restrictions.layout-field-locked' | transloco)
      : ''
  "
>
  @if (currentColor()) {
    <app-color-picker
      #colorPicker
      [color]="currentColor()"
      [disabled]="disabled()"
      (colorChange)="colorChange.emit($event)"
      (clearColor)="clearColor.emit()"
    ></app-color-picker>
    @if (!isLocked() && showClear()) {
      <button
        aria-label="Clear"
        class="clear-color"
        mat-icon-button
        (click)="clearColor.emit(); colorPicker.discardClick($event)"
      >
        <mat-icon>close</mat-icon>
      </button>
    }
  } @else if (!disabled()) {
    <div class="null-wrapper" (mousedown)="setCurrentColor($event)">
      <span class="null"></span>
    </div>
  }
</div>
