import { DOCUMENT } from '@angular/common';
import {
  Injectable,
  NgZone,
  OnDestroy,
  Renderer2,
  RendererFactory2,
  inject,
} from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';
import { combineLatest, BehaviorSubject, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  take,
  takeUntil,
  distinctUntilChanged,
} from 'rxjs/operators';
import { getThemeColour } from './app.config';
import { State } from './reducers';
import { InterfaceLanguage } from './shared/constants/languages';
import {
  selectUserEmail,
  selectUserFullName,
  selectUserPartnerCode,
} from './shared/user/ngrx/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class HelpScoutBeaconsService implements OnDestroy {
  private _document = inject<Document>(DOCUMENT);
  private ngZone = inject(NgZone);
  private ngrxStore = inject<Store<State>>(Store);
  private rendererFactory = inject(RendererFactory2);
  private translateService = inject(TranslocoService);

  private destroyed$ = new Subject<void>();
  firstScript: HTMLScriptElement;
  head: HTMLHeadElement;
  hideBeacon = true;
  isReady$ = new BehaviorSubject(false);
  lang: InterfaceLanguage;
  partnerCode: string;
  renderer2: Renderer2;
  secondScriptText: Record<InterfaceLanguage, string> = {
    en: `daffeec0-30df-41aa-aa14-dc0cfd204101`,
    es: `21a965b3-ab88-4668-a518-40dc21d51e10`,
    fr: `23c28188-d657-42a7-9c24-d26288cd6043`,
    it: `7e1798e5-705d-416f-97b9-439c31592bc2`,
    de: `0a1d6d25-57ce-4006-a4c7-d0422eda0c69`,
  };

  userEmail$ = this.ngrxStore.select(selectUserEmail);
  userFullName$ = this.ngrxStore.select(selectUserFullName);
  userPartnerCode$ = this.ngrxStore.select(selectUserPartnerCode);

  createBeacon(lang: InterfaceLanguage): void {
    this.setHtmlHeadScript();
    setTimeout(() => {
      const beacon = window[`Beacon`];
      if (!beacon) return;
      if (Object.getOwnPropertyNames(beacon).includes(`readyQueue`)) {
        beacon(`config`, {
          color: getThemeColour(this.partnerCode),
        });
        beacon(`init`, this.secondScriptText[lang]);
        this.hideOrShowBeacon();
        this.isReady$.next(true);
      } else {
        beacon(`destroy`);
        setTimeout(() => this.createBeacon(lang));
      }
    });
  }

  hide(): void {
    this.hideBeacon = true;
    this.hideOrShowBeacon();
  }

  init(): void {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
    this.head = this._document.head;
    this.subscribeSetupData();
    this.subscribePrefillData();
  }

  navigateSupport(): void {
    if (window[`Beacon`]) window[`Beacon`]('navigate', '/ask/');
  }

  open(): void {
    const beacon = window['Beacon'];
    if (!beacon || !this.isReady$.value) {
      return void this.isReady$
        .pipe(
          filter((v) => !!v),
          take(1),
          takeUntil(this.destroyed$),
        )
        .subscribe(() => {
          this.open();
        });
    }
    beacon('open');
  }

  show(): void {
    this.hideBeacon = false;
    this.hideOrShowBeacon();
  }

  private getBeaconContainer = (): HTMLElement =>
    document.getElementById(`beacon-container`);

  private hideOrShowBeacon(): void {
    const beaconContainer = this.getBeaconContainer();
    if (beaconContainer) {
      beaconContainer.style.display = this.hideBeacon ? `none` : `inherit`;
    }
  }

  private setHtmlHeadScript(): void {
    if ('Cypress' in window) return undefined;
    if (!this.firstScript) {
      this.firstScript = this.renderer2.createElement(`script`);
      this.firstScript.type = `text/javascript`;
      this.firstScript.text = `
        !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],
        n=t.createElement("script");n.type="text/javascript",
        n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}
        if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},
        n.readyQueue=[],"complete"===t.readyState)return a();
        e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}
        (window,document,window.Beacon||function(){});
      `;
    }
    this.renderer2.appendChild(this.head, this.firstScript);
  }

  private subscribePrefillData(): void {
    combineLatest([this.isReady$, this.userEmail$, this.userFullName$])
      .pipe(
        distinctUntilChanged(),
        filter((vals) => !!vals[0]),
        takeUntil(this.destroyed$),
      )
      .subscribe(([, email, name]) => {
        if (!email || !name) return;
        if (window[`Beacon`]) {
          window[`Beacon`](`prefill`, {
            name,
            ...(email.indexOf('@partners.menutech.com') === -1
              ? { email }
              : {}),
          });
        }
      });
  }

  private subscribeSetupData(): void {
    combineLatest([this.translateService.langChanges$, this.userPartnerCode$])
      .pipe(debounceTime(1500), takeUntil(this.destroyed$))
      .subscribe(([lang, partner]) => {
        this.lang = lang as InterfaceLanguage;
        this.partnerCode = partner;
        if (!this.hideBeacon)
          this.ngZone.runOutsideAngular(() => this.createBeacon(this.lang));
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
